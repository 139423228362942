import { PictureOutlined, ContainerOutlined } from "@ant-design/icons";

import { STRINGS, Svgs } from "../../../constants";
import CancelBooking from "../../cancel-booking/CancelBooking";
import ComboTab from "../../combo/ComboTab";
import Gacha from "../../gacha/Gacha";
import ManagerCM from "../../manager-cm/ManagerCM";
import HomagePage from "../../manager-homepage/HomePage";
import ManagerMail from "../../manager-mail/ManagerMail";
import Notification from "../../manager-notification/Notification";
import Profile from "../../manager-profile/Profile";
import ReviewPhase2 from "../../manager-review-phase2/ReviewPhase2";
import Review from "../../manager-review/Review";
import StaffManagement from "../../manager-staff-management/StaffManagement";
import NotificationStaff from "../../manager-staff-notification/NotificationStaff";
import Store from "../../manager-store/Store";
import ManagerUser from "../../manager-user/ManagerUser";
import Coupon from "../../manger-coupon/Coupon";
import PointHistory from "../../point-history/PointHistory";
import SlideSetting from "../../slide-setting/SlideSetting";
import HistoryStore from "../../store-history/HistoryStore";
import HistoryPointStore from "../../store-point-history/HistoryPointStore";
import StoreProvidePoint from "../../store-provide-point/StoreProvidePoint";

export const systemAdmin = [
  {
    component: ManagerUser,
    url: "/",
  },
  {
    component: Store,
    url: "/store",
  },
  {
    component: HomagePage,
    url: "/home-page",
  },
  {
    component: Notification,
    url: "/notification",
  },
  {
    component: NotificationStaff,
    url: "/notification-staff",
  },
  {
    component: Review,
    url: "/review",
  },
  {
    component: ReviewPhase2,
    url: "/review2",
  },
  {
    component: Coupon,
    url: "/coupon",
  },

  {
    component: Profile,
    url: "/profile",
  },
  {
    component: StaffManagement,
    url: "/staff-management",
  },
  {
    component: ManagerCM,
    url: "/manager-cm",
  },
  {
    component: SlideSetting,
    url: "/slide-setting",
  },
  {
    component: PointHistory,
    url: "/point-history",
  },
  {
    component: HistoryStore,
    url: "/history-store/:id",
  },
  {
    component: HistoryPointStore,
    url: "/history-point-store/:id",
  },
  {
    component: Gacha,
    url: "/gacha",
  },
  {
    component: ComboTab,
    url: "/combo",
  },
  {
    component: CancelBooking,
    url: "/cancel-booking",
  },
  {
    component: StoreProvidePoint,
    url: "/store-provide-point",
  },
  {
    component: ManagerMail,
    url: "/manager-mail",
  },
];

export const menuItems = {
  customer_manager_menu: [
    {
      key: "user",
      icon: Svgs.user,
      title: STRINGS.user_management,
      linkTo: "/",
    },
    {
      key: "notification",
      icon: Svgs.push(),
      title: STRINGS.notification_management,
      linkTo: "/notification",
    },
    {
      key: "homePage",
      icon: Svgs.homePage(),
      title: STRINGS.homepage_management,
      linkTo: "/home-page",
    },
    {
      key: "storeProvidePoint",
      icon: <ContainerOutlined style={{ fontSize: 22, color: "white" }} />,
      title: STRINGS.store_provide_point,
      linkTo: "/store-provide-point",
    },
    {
      key: "cancelBooking",
      icon: Svgs.cancelBooking(),
      title: STRINGS.cancel_booking,
      linkTo: "/cancel-booking",
    },
  ],
  group_manager_menu: [
    {
      key: "store",
      icon: Svgs.store(),
      title: STRINGS.store_management,
      linkTo: "/store",
    },
    {
      key: "pointHistory",
      icon: Svgs.pointHistory(),
      title: STRINGS.monthly_point_history,
      linkTo: "/point-history",
    },
  ],
  staff_manager_menu: [
    {
      key: "notificationStaff",
      icon: Svgs.notificationStaff,
      title: STRINGS.push_to_staff,
      linkTo: "/notification-staff",
    },
    {
      key: "review",
      icon: Svgs.review(),
      title: STRINGS.review_management_1,
      linkTo: "/review",
    },
    {
      key: "review2",
      icon: Svgs.review(),
      title: STRINGS.review_management,
      linkTo: "/review2",
    },
    {
      key: "staff",
      icon: Svgs.staff(),
      title: STRINGS.mng_staff_store_2,
      linkTo: "/staff-management",
    },
  ],
  slide_manager_menu: [
    {
      key: "slideSetting",
      icon: Svgs.slideSetting(),
      title: STRINGS.slide_setting,
      linkTo: "/slide-setting",
    },
  ],
  course_manager_menu: [
    {
      key: "combo",
      icon: Svgs.combo(),
      title: STRINGS.course_setting,
      linkTo: "/combo",
    },
  ],
  relations_manager_menu: [
    {
      key: "coupon",
      icon: Svgs.coupon(),
      title: STRINGS.coupon_management,
      linkTo: "/coupon",
    },
    {
      key: "managerCM",
      icon: <PictureOutlined style={{ color: "white" }} />,
      title: STRINGS.manager_cm,
      linkTo: "/manager-cm",
    },
    {
      key: "gacha",
      icon: Svgs.gacha(),
      title: STRINGS.gacha,
      linkTo: "/gacha",
    },
    {
      key: "managerMail",
      icon: Svgs.mail(),
      title: STRINGS.manager_mail,
      linkTo: "/manager-mail",
    },
  ],
};
