/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo, useEffect } from "react";
import { Select } from "antd";

import { SuffixIconDown } from "../../../elements";
import ".././StoreProvidePoint.scss";

import { FetchApi } from "../../../api";
import Auth from "../../../store/Authentication";

const { Option } = Select;

const StoreProvidePointListStaff = memo(({ style, onChange }) => {
  const [loading] = useState(false);
  const [data, setData] = useState({ data: [] });
  const [pagination, setPagination] = useState({
    page: 1,
    size: 20,
    last: false,
    refetch: false,
  });
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const handleChange = async (e, f) => {
    onChange && onChange({ staffName: f.children, staffId: e });
  };
  const onScroll = (event) => {
    var target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      if (!pagination.last) {
        setPagination((prev) => {
          return { ...prev, page: prev.page + 1 };
        });
      }
    }
  };

  const fetch = async () => {
    const result = await FetchApi.getListStaff(
      userAdmin.storeId,
      pagination.page,
      pagination.size
    );
    if (result.success) {
      setData((prev) => {
        if (pagination.page === 1) {
          return { ...result.data, data: [...result.data] };
        }
        return { ...result.data, data: [...prev.data, ...result.data] };
      });
      setPagination((prev) => {
        return { ...prev, last: result.pageDto.last };
      });
    }
  };

  useEffect(() => {
    fetch();
  }, [pagination.page, pagination.refetch]);

  useEffect(() => {
    setData({ data: [] });
    setPagination({
      page: 1,
      size: 20,
      last: true,
      refetch: !pagination.refetch,
    });
  }, []);

  let dataCheckLoading = [];

  if (!pagination.last && data?.data) {
    dataCheckLoading = [...data.data, { id: "more", name: "..." }];
  }
  if (data?.data && pagination.last) {
    dataCheckLoading = data?.data;
  }

  return (
    <Select
      onChange={handleChange}
      onPopupScroll={onScroll}
      suffixIcon={<SuffixIconDown />}
      style={{ width: "100%", alignSelf: "flex-end", ...style }}>
      {dataCheckLoading.map((item, index) => (
        <Option key={item.id} disabled={item.id === "more"}>
          {item.name}
        </Option>
      ))}
    </Select>
  );
});
export { StoreProvidePointListStaff };
