import React from "react";
import { Form, Row, InputNumber, Select } from "antd";
import { useQueryClient } from "react-query";

import { COLORS, STRINGS } from "../../../../../constants";
import { SuffixIconDown } from "../../../../../elements";

function ConfigPriceBonus() {
  const cache = useQueryClient();
  const dataCombo = cache.getQueryData("allCombo");
  return (
    <Row style={{ paddingTop: 30 }}>
      <div style={{ fontWeight: "bold", fontSize: 18, minWidth: 200 }}>
        {STRINGS.price_bonus}
      </div>
      <Form.Item
        name={"surchargeForMen"}
        //  rules={[Validate.isNumberPointPrice]}
      >
        <InputNumber
          style={{ fontWeight: "bold", fontSize: 15 }}
          formatter={(value) =>
            `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 円`
          }
          parser={(value) =>
            value
              .replace("円", "")
              .replace(" ", "")
              .replace(/\$\s?|(,*)/g, "")
          }
        />
      </Form.Item>
      <Form.Item name={"courseIds"}>
        <Select
          style={{
            textAlign: "left",
            width: "fit-content",
            minWidth: 210,
            whiteSpace: "pre-line",
            borderRadius: 5,
            color: COLORS.sub_accent,
            fontWeight: "900",
            marginLeft: 50,
          }}
          placeholder={STRINGS.please_choose_combo}
          allowClear
          mode="multiple"
          suffixIcon={<SuffixIconDown />}
          onChange={(val, otherProps) => {}}>
          {dataCombo?.comboDTOS?.map?.((item) => {
            return (
              <Select.Option
                key={item.id}
                value={item.id}
                style={{
                  height: 60,
                  color: COLORS.sub_accent,
                  fontWeight: "bold",
                }}>
                <Row style={{ flexWrap: "wrap", whiteSpace: "pre-line" }}>
                  {item.name}
                </Row>
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </Row>
  );
}
export { ConfigPriceBonus };
