/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Button, Col, Form, Input, InputNumber, message, Row } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";

import { COLORS, LOGIC, STRINGS, Svgs } from "../../../constants";
import { LoadingView, SvgView } from "../../../elements";
import { FetchApi } from "../../../api";
import { useGetDetailCourseBooking } from "../modules";
import DropdownCourse from "./DropdownCourse";

// extra course
function useGetProlongedCourse(bookingId) {
  const { data } = useQuery("prolongedCourse", () =>
    FetchApi.getProlongedCourse(bookingId)
  );
  return {
    dataProlonged: (data?.prolongedCourses || []).map((i) => {
      return {
        ...i,
        taxProlonged: i.tax,
      };
    }),
  };
}

// course can selected with this booking
function useGetCourseAvailableWithBooking(bookingId) {
  const { data } = useQuery("courseAvailableBooking", () =>
    FetchApi.getCourseAvailableWithBooking(bookingId)
  );
  return {
    dataCombo: data,
  };
}

function ModalBookingDetailTableCombo({ data, disabledForm, setTotal, form }) {
  const { dataBooking, detailCourseBooking, isLoading } =
    useGetDetailCourseBooking(data.id);

  const [combosPicked, setCombosPicked] = useState(detailCourseBooking);
  const { dataProlonged } = useGetProlongedCourse(data.id);

  const { dataCombo } = useGetCourseAvailableWithBooking(data.id);

  const onValuesChange = () => {
    const { comboDTOS, couponSelected } = form.getFieldValue();
    const combosPickedObj = {};
    const combosExtraObj = {};
    (dataCombo?.comboDTOS || []).map((i) => {
      return (combosPickedObj[i.id] = i);
    });
    dataProlonged.map((i) => {
      return (combosExtraObj[i.id] = i);
    });
    const comboSelectedConvert = comboDTOS.map((i) => {
      let comboObj = {};
      if (i?.comboPackId) {
        comboObj = {
          ...combosPickedObj[i?.comboPackId],
          priceComboOnly: combosPickedObj[i?.comboPackId]?.price,
        };
      }
      if (i?.comboExtraId) {
        const comboExtraObjConvert = combosExtraObj[i.comboExtraId];
        if (comboExtraObjConvert.comboId === comboObj?.comboId) {
          comboObj = {
            ...comboObj,
            comboExtraId: i?.comboExtraId,
            taxProlonged: comboExtraObjConvert.taxProlonged,
            minuteExtra: comboExtraObjConvert.minutes,
            priceExtra: comboExtraObjConvert.price,
            priceComboOnly: combosPickedObj[i?.comboPackId]?.price,
            price: comboObj.price + comboExtraObjConvert.price,
          };
        } else {
          comboObj = {
            ...comboObj,
            comboExtraId: null,
            minuteExtra: null,
            priceExtra: 0,
            priceComboOnly: combosPickedObj[i?.comboPackId].price,
          };
        }
      }
      return comboObj;
    });

    setCombosPicked(comboSelectedConvert);

    const clone = comboSelectedConvert.map((item) => {
      return {
        ...item,
        comboPackId: item?.id,
      };
    });
    form.setFieldsValue({
      comboDTOS: [...clone],
      modifiedCourse: true,
    });

    let totalPrice = 0;
    let couponDiscount = 0;
    const couponTax = couponSelected?.find?.(
      (i) => i?.type === LOGIC.coupon_time.tax
    );
    // couponSelected?.forEach?.((i) => (couponDiscount += i.discount || 0));
    couponSelected?.forEach?.((i) => {
      const coursesUseThisCoupon = comboSelectedConvert?.filter((item) =>
        i?.courseIds.includes(item?.courseId)
      );
      const ratio = comboSelectedConvert?.length - coursesUseThisCoupon?.length;
      if (ratio >= 0) couponDiscount += i?.discount || 0;
    });
    if (couponTax?.id) {
      comboSelectedConvert.forEach((i) => {
        if (i?.price) {
          if (couponTax?.courseIds?.includes?.(i.comboId)) {
            totalPrice += i.price - (i.tax || 0);
          } else {
            totalPrice += i.price - 0;
          }
        }
        // if (i?.priceExtra) {
        //   totalPrice += i.priceExtra;
        // }
      });
    } else {
      comboSelectedConvert.forEach((i) => {
        if (i?.price) {
          totalPrice += i.price;
        }
      });
    }

    setTotal(
      totalPrice -
        (couponDiscount || 0) -
        (data.pointUse || 0) +
        (data.lateBookingFee || 0) +
        (data.addtionalManFee || 0) +
        (data.designationFee || 0)
    );
  };

  if (isLoading) {
    return <LoadingView />;
  }
  return (
    <Form
      form={form}
      initialValues={{
        comboDTOS: detailCourseBooking || [undefined],
        couponSelected: dataBooking?.coupons || [],
        comment: data.commentOfStaff,
        couponIds: (dataBooking?.coupons || []).map((i) => i.title),
      }}
      onValuesChange={onValuesChange}
    >
      <Row>
        <Col span={24}>
          <Row
            className="theader"
            style={{
              width: "100%",
              color: COLORS.sub_accent,
            }}
          >
            <Col span={3}></Col>
            <Col span={11} style={{ fontSize: 22 }}>
              {STRINGS.course}
            </Col>

            <Col span={4} style={{ fontSize: 22 }}>
              {STRINGS.extension}
            </Col>
            <Col span={4} style={{ fontSize: 22 }}>
              {STRINGS.amount_price}
            </Col>
          </Row>

          <Form.List name="comboDTOS">
            {(fields, { add, remove }) => {
              return (
                <Col>
                  {fields.map((field, index, arr) => (
                    <Col
                      key={field.key}
                      span={24}
                      className={field.key === 0 ? "col-wrapper-row-input" : 0}
                    >
                      <Row
                        className="row-table-custom"
                        style={{ width: "100%", color: COLORS.sub_accent }}
                      >
                        <Col span={3}>
                          {index === 0 && (
                            <Input
                              style={{
                                borderWidth: 0,
                                backgroundColor: "white",
                                color: COLORS.sub_accent,
                                fontWeight: "bold",
                                fontSize: 18,
                              }}
                              disabled
                              value={`${data.minutes}${STRINGS.minute}`}
                            />
                          )}
                        </Col>

                        <Col span={11}>
                          <Form.Item
                            {...field}
                            name={[field.name, "comboPackId"]}
                            fieldKey={[field.fieldKey, "comboPackId"]}
                          >
                            <DropdownCourse
                              comboDTOS={
                                dataCombo?.comboDTOS || []
                                //   .filter(
                                //   (i) => i?.minutes <= data.minutes
                                // )
                              }
                              disabled={disabledForm}
                              combosPicked={combosPicked}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={4}>
                          <Form.Item
                            {...field}
                            name={[field.name, "comboExtraId"]}
                            fieldKey={[field.fieldKey, "comboExtraId"]}
                            // rules={[Validate.emptyContent]}
                          >
                            <DropdownCourse
                              comboDTOS={(dataProlonged || []).filter(
                                (i) =>
                                  i.comboId ===
                                  (combosPicked[index]?.comboId ||
                                    detailCourseBooking[index]?.comboId)
                              )}
                              disabled={
                                disabledForm ||
                                (!combosPicked[index]?.id &&
                                  !detailCourseBooking[index]?.comboId)
                              }
                              dataRender={"minutes"}
                              combosPicked={[]}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            {...field}
                            name={[field.name, "price"]}
                            fieldKey={[field.fieldKey, "price"]}
                          >
                            <InputNumber
                              disabled
                              formatter={(value) =>
                                `${LOGIC.currencyYenFormatter(value)}`
                              }
                            />
                          </Form.Item>
                        </Col>
                        {/* <Col span={4}>
                          <Form.Item
                            {...field}
                            name={[field.name, "priceExtra"]}
                            fieldKey={[field.fieldKey, "priceExtra"]}>
                            <InputNumber
                              disabled
                              formatter={(value) =>
                                `${LOGIC.currencyYenFormatter(value)}`
                              }
                            />
                          </Form.Item>
                        </Col> */}
                        {!disabledForm && index !== 0 && (
                          <Col style={{ marginTop: 1 }}>
                            <Button
                              onClick={() => remove(field.name)}
                              style={{
                                borderWidth: 0,
                                backgroundColor: "transparent",
                                borderColor: "transparent",
                              }}
                            >
                              <SvgView svgData={Svgs.delete} />
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  ))}
                  {!disabledForm && (
                    <Row
                      onClick={() => {
                        const { comboDTOS } = form.getFieldValue();
                        if (
                          (comboDTOS || []).every(
                            (i) => !!i?.id
                            // && !!i?.comboExtraId
                          )
                        ) {
                          add();
                          return;
                        }
                        message.warning(STRINGS.this_field_is_required);
                      }}
                      className="btn-add-combo"
                      style={{
                        marginTop: 20,
                        marginLeft: 190,
                        width: "fit-content",
                      }}
                    >
                      <PlusCircleOutlined style={{ fontSize: 26 }} />
                      <Row
                        style={{
                          color: COLORS.primary,
                          fontSize: 26,
                          fontWeight: "bold",
                        }}
                      >
                        {STRINGS.add_math}
                      </Row>
                    </Row>
                  )}
                </Col>
              );
            }}
          </Form.List>
        </Col>
      </Row>
    </Form>
  );
}

export default ModalBookingDetailTableCombo;
