import React from "react";
import { Col, Row, TimePicker } from "antd";
import { ClockCircleFilled } from "@ant-design/icons";
import moment from "moment";

import { COLORS, STRINGS } from "../../../constants";
import { ButtonCustom, Convert } from "../../../elements";
import Auth from "../../../store/Authentication";

export function StaffBookingStep2NoSuggest({
  selectedTime,
  form,
  refetchListStaff,
  refetch,
}) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const infoStore = userAdmin.storeDTO;
  const openTime = moment(
    moment().format("MM/DD/YYYY") + `${infoStore.openTime}`,
    "MM/DD/YYYY HH:mm"
  );
  const selectedDay = moment(selectedTime).format("MM/DD/YYYY");
  const closeTime = Convert.convertTime(
    infoStore.openTime,
    infoStore.closeTime
  );
  return (
    <Row style={{ alignItems: "center" }}>
      <Col style={{ border: "3px solid red", padding: 5 }}>
        <TimePicker
          defaultValue={selectedTime}
          suffixIcon={
            <ClockCircleFilled
              style={{ fontSize: 20, color: COLORS.sub_disabled }}
            />
          }
          style={{
            borderRadius: 5,
            padding: "6px 10px",
            height: 40,
            width: 130,
          }}
          disabledDate={(e) => {
            let customDate = moment().format("MM/DD/YYYY");
            return e && e.isBefore(moment(customDate, "MM/DD/YYYY"));
          }}
          allowClear={false}
          showNow={false}
          onSelect={(e) => {
            form.setFieldsValue({
              bookingDate: moment(e),
            });
            //   refetchListStaff();
          }}
          format={"HH:mm"}
          picker={"date"}
          showTime={{
            format: "HH:mm",
            minuteStep: 10,
            disabledHours: () => {
              const hours = [];
              // check if moment is today and is after opening time
              if (
                moment().isAfter(openTime) &&
                moment().format("MM/DD/YYYY") === selectedDay
              ) {
                if (moment().minute() >= 50) {
                  for (let i = 0; i < moment().hour() + 1; i++) {
                    hours.push(i);
                  }
                } else {
                  for (let i = 0; i < moment().hour(); i++) {
                    hours.push(i);
                  }
                }
              } else {
                if (closeTime.hour() < openTime.hour()) {
                  for (let i = closeTime.hour(); i < openTime.hour(); i++) {
                    hours.push(i);
                  }
                } else {
                  for (let i = 0; i < openTime.hour(); i++) {
                    hours.push(i);
                  }
                }
              }

              if (closeTime.hour() > openTime.hour()) {
                for (let i = closeTime.hour() + 1; i <= 23; i++) {
                  hours.push(i);
                }
              }

              return hours;
            },
            disabledMinutes: (hourSelected) => {
              const minutes = [];
              const minuteNow = moment().minute() % 10;
              const minMinute = moment()
                .add(20 - minuteNow, "minutes")
                .minute();
              if (
                moment().format("MM/DD/YYYY") === selectedDay &&
                hourSelected === moment().hour()
              ) {
                for (let i = 0; i <= minMinute; i++) {
                  minutes.push(i);
                }
              }
              return minutes;
            },
          }}
        />
      </Col>
      <ButtonCustom
        onClick={async () => {
          await refetchListStaff();
          refetch();
        }}
        style={{
          marginLeft: 15,
        }}>
        {STRINGS.sure}
      </ButtonCustom>
    </Row>
  );
}
