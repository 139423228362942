const useGetCouponsSelected = (form, couponsDefault) => {
  const { couponIds, listCoupon } = form.getFieldValue();
  let couponsSelected = [];
  let checkCouponIsChanged;
  if (listCoupon?.length > 0) {
    couponIds?.forEach((item) => {
      if (typeof item === "string") {
        const coupon = listCoupon.find((v) => v.title === item);
        couponsSelected.push(coupon);
      } else {
        const coupon = listCoupon.find((v) => v.id === item);
        couponsSelected.push(coupon);
      }
    });
  }

  if (JSON.stringify(couponsSelected) !== JSON.stringify(couponsDefault)) {
    checkCouponIsChanged = true;
  } else checkCouponIsChanged = false;

  return { couponsSelected, checkCouponIsChanged };
};

export default useGetCouponsSelected;
