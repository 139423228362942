import React, { useRef } from "react";
import { PlusCircleOutlined, MenuOutlined } from "@ant-design/icons";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { useQuery, useQueryClient } from "react-query";
import { Space, Table, message } from "antd";
import arrayMove from "array-move";
import moment from "moment";

import {
  Container,
  LoadingView,
  ErrorView,
  AlertService,
  SvgView,
  ButtonCustom,
} from "../../elements";
import { STRINGS, LOGIC, Svgs } from "../../constants";
import { FetchApi } from "../../api";
import { ModalCustomService } from "../../elements/ModalCustom/ModalCustomService";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import { CommonFunction } from "../../util/CommonFunction";
import Add from "./items/Add";
import Edit from "./items/Edit";

import "../../util/variables.scss";
import "./SlideSetting.scss";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const SlideSetting = () => {
  const cache = useQueryClient();
  const arrayPosition = useRef();
  const { data, isLoading, refetch } = useQuery(["SlideSetting"], () =>
    FetchApi.getAllSlides()
  );
  useScrollByMouse(data);

  const columns = [
    {
      title: STRINGS.sort,
      dataIndex: "id",
      key: "id",
      render: () => <DragHandle />,
      width: 60,
      fixed: "left",
      align: "center",
    },
    {
      title: STRINGS.image,
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl) => (
        <img src={imageUrl} style={{ width: 264, height: 176 }} alt="" />
      ),
      width: 280,
    },
    {
      title: STRINGS.start_date,
      key: "startTime",
      dataIndex: "startTime",
      render: (e) => (e ? moment(e).format(LOGIC.timeFormat) : ""),
      width: 200,
    },
    {
      title: STRINGS.end_date,
      key: "endTime",
      dataIndex: "endTime",
      render: (e) => (e ? moment(e).format(LOGIC.timeFormat) : ""),
      width: 200,
    },
    {
      key: "action",
      render: (text, record) => {
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onEdit(record)}>
              <SvgView svgData={Svgs.edit} />
            </button>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={removeItem(record)}>
              <SvgView svgData={Svgs.delete} />
            </button>
          </Space>
        );
      },
      fixed: "right",
      width: 90,
    },
  ];

  const setData = () => {
    cache.refetchQueries(`SlideSetting`);
  };

  const removeItem = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${STRINGS.confirm_delete_common}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteSlide(item.id);
        if (result.success) {
          refetch();
          message.success(STRINGS.deleted_common);
        } else {
          CommonFunction.logError(result);
        }
      },
    });
  };

  const onCreate = () => {
    ModalCustomService.set({
      title: STRINGS.add_new,
      wrapperStyle: { maxWidth: 720 },
      content: <Add data={data} setData={refetch} />,
    });
  };
  const onEdit = (record) => () => {
    ModalCustomService.set({
      title: STRINGS.update,
      wrapperStyle: { maxWidth: 720 },
      content: <Edit data={data} setData={setData} item={record} />,
    });
  };
  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    if (data) {
      const index = data.slides?.findIndex(
        (x) => x.id === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
    } else {
      return <></>;
    }
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(data.slides),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      cache.setQueryData("SlideSetting", (oldData) => {
        return { ...oldData, slides: newData };
      });
      arrayPosition.current = [];
      for (let index = 0; index < newData.length; index++) {
        let array = [];
        array.push(newData[index].id);
        array.push(newData.length - index);
        arrayPosition.current.push(array);
      }
      const result = await FetchApi.updatePositionSlide(arrayPosition.current);

      if (result.success) {
        message.success(STRINGS.change_position_success);
      } else {
        message.error(result.message);
      }
    }
  };

  if (isLoading) {
    return <LoadingView />;
  }

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Container
      className="slide-setting-wrapper"
      title={STRINGS.slide_setting}
      renderRight={() => (
        <ButtonCustom onClick={onCreate} icon={<PlusCircleOutlined />}>
          {STRINGS.add_new}
        </ButtonCustom>
      )}>
      <Table
        rowKey={"id"}
        loading={isLoading}
        columns={columns}
        dataSource={data ? data.slides : []}
        pagination={false}
        scroll={{ x: 1000, y: "calc(100vh - 250px)" }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </Container>
  );
};

export default SlideSetting;
