import React, { useState } from "react";
import { Space, Table } from "antd";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

import { FetchApi } from "../../api";
import {
  Container,
  ModalCustomService,
  LoadingView,
  ErrorView,
  SvgView,
} from "../../elements";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import { Svgs, STRINGS } from "../../constants";
import Edit from "./items/Edit";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";

function ManagerMail() {
  const [pagination, setPagination] = useState({ size: 10, page: 1 });
  const cache = useQueryClient();
  const { data, isLoading, refetch } = useQuery(
    [`EmailManageList-${pagination.size}-${pagination.page}`],
    () =>
      FetchApi.getEmailSetting({ page: pagination.page, size: pagination.size })
  );
  useScrollByMouse(data);

  const columns = [
    {
      title: STRINGS.manager_mail,
      dataIndex: "type",
      key: "type",
      align: "center",
      width: 400,
    },
    {
      title: STRINGS.date_created_mail,
      dataIndex: "updatedTime",
      key: "updatedTime",
      align: "center",
      width: 350,
      render: (e) => {
        return moment(e).format("YYYY年MM月DD日 HH:mm");
      },
    },

    {
      key: "action",
      render: (text, record) => {
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onEdit(record)}>
              <SvgView svgData={Svgs.edit} />
            </button>
          </Space>
        );
      },
    },
  ];

  const onEdit = (record) => () => {
    ModalCustomService.set({
      title: STRINGS.update,
      wrapperStyle: { maxWidth: 600 },
      content: (
        <Edit item={record} data={data} setData={setData} refetch={refetch} />
      ),
    });
  };

  const setData = () => {
    cache.refetchQueries(`GachaList-${pagination.size}-${pagination.page}`);
  };

  if (isLoading) {
    return <LoadingView />;
  }

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Container title={STRINGS.manager_mail}>
      <Table
        loading={isLoading}
        rowKey={"id"}
        columns={columns}
        dataSource={data?.emailSettingDTOS || []}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
        scroll={{ x: "auto", y: "calc(100vh - 350px)" }}
      />
      <PaginationWithSizeChange
        size={pagination.size}
        total={data?.pageDto?.totalElements || 0}
        onChange={(pageNumber) => {
          setPagination({ size: pagination.size, page: pageNumber });
        }}
        onShowSizeChange={(e) => {
          setPagination({ size: e.size, page: 1 });
        }}
        current={pagination.page}
      />
    </Container>
  );
}

export default ManagerMail;
