/* eslint-disable array-callback-return */
import React, { useRef, useState } from "react";
import { Col, message, Radio, Row } from "antd";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import moment from "moment";

import {
  AlertService,
  ButtonCustom,
  LoadingAbsolute,
  ModalCustomService,
  SvgView,
} from "../../../elements";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util/CommonFunction";
import { TimePickerAutoAccept } from "../../../elements/TimePickerAutoAccept";
import { STRINGS, Svgs } from "../../../constants";
import Auth from "../../../store/Authentication";

import "../StoreFixedSchedule.scss";

function HoursPicker({ onChange, defaultValue }) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const [timeRangeList, setTimeRangeList] = useState(() => {
    const clone = defaultValue.map((item) => {
      return {
        ...item,
        startTime: moment(item.startTime, "HH:mm"),
        endTime: moment(item.endTime, "HH:mm"),
        type: "update_schedule",
      };
    });
    onChange(clone);
    return clone;
  });

  const range = (a, b = 23) => {
    const arr = [];
    while (a < b + 1) {
      arr.push(a++);
    }
    return arr;
  };

  const disabledMinutes = (selectedHour) => {
    let minutes = [];
    const closeHour = userAdmin.storeDTO.closeTime
      ? parseInt(userAdmin.storeDTO.closeTime.slice(0, 2))
      : 24;
    const closeMinute = userAdmin.storeDTO.closeTime
      ? parseInt(userAdmin.storeDTO.closeTime.slice(3, 5))
      : [];
    if (selectedHour == closeHour) {
      for (let i = 0; i < 60; i++) {
        if (i > closeMinute) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };

  const openHour = userAdmin.storeDTO?.openTime
    ? parseInt(userAdmin.storeDTO.openTime.slice(0, 2))
    : [];
  const closeHour = userAdmin.storeDTO?.closeTime
    ? parseInt(userAdmin.storeDTO.closeTime.slice(0, 2))
    : 24;
  let openHours = [];
  let closeHours = [];

  if (closeHour > openHour) {
    openHours = Array.from(Array(openHour).keys());
    closeHours = range(closeHour + 1);
  } else {
    openHours = range(closeHour + 1, openHour - 1);
  }

  const addTimeRangeList = () => {
    const newStartTime = moment(userAdmin.storeDTO?.openTime, "HH:mm");
    const newEndTime = moment(userAdmin.storeDTO?.closeTime, "HH:mm");
    const clone = JSON.parse(
      JSON.stringify([
        ...timeRangeList,
        {
          startTime: newStartTime,
          endTime: newEndTime,
          session: "早",
          type: "",
        },
      ])
    );
    setTimeRangeList(clone);
    onChange(clone);
  };
  const removeTimeRange = (index) => () => {
    const clone = JSON.parse(JSON.stringify([...timeRangeList]));
    if (clone[index].id) {
      clone[index].type = "delete_schedule";
    } else {
      clone.splice(index, 1);
    }
    setTimeRangeList(clone);
    onChange(clone);
  };
  const onChangeHour = (index, type) => async (e) => {
    const clone = await JSON.parse(JSON.stringify(timeRangeList));
    clone[index][type] = moment(e);
    if (clone[index].id) {
      clone[index].type = "update_schedule";
    }
    if (
      moment(userAdmin.storeDTO?.closeTime).isAfter(
        userAdmin.storeDTO?.openTime
      ) &&
      clone[index].startTime &&
      clone[index].endTime &&
      moment(clone[index].startTime).isAfter(moment(clone[index].endTime))
    ) {
      const temp = clone[index].startTime;
      clone[index].startTime = clone[index].endTime;
      clone[index].endTime = temp;
    }
    setTimeRangeList(clone);
    onChange(clone);
  };
  const onChangeSession = (index) => async (e) => {
    const clone = await JSON.parse(JSON.stringify(timeRangeList));
    if (clone[index].id) {
      clone[index].type = "update_schedule";
    }
    clone[index].session = e.target.value;
    setTimeRangeList(clone);
    onChange(clone);
  };

  return (
    <Col style={{ paddingTop: 14, width: "90%" }}>
      <Row style={{ textAlign: "left" }}>
        <Col span={8} style={{ fontWeight: "bold" }}>
          {STRINGS.start_time}
        </Col>
        <Col span={8} style={{ fontWeight: "bold" }}>
          {STRINGS.end_time}
        </Col>
        <Col span={4} style={{ fontWeight: "bold" }}>
          {STRINGS.shift}
        </Col>
      </Row>
      {timeRangeList.map((item, index) => {
        if (item.type == "delete_schedule") {
          return;
        }
        return (
          <Row
            key={`${timeRangeList}-${index}`}
            style={{
              textAlign: "left",
              alignItems: "center",
              marginBottom: 6,
            }}>
            <Col span={8}>
              <TimePickerAutoAccept
                minuteStep={10}
                value={moment(item.startTime)}
                onChangeHour={onChangeHour(index, "startTime")}
                format={"HH:mm"}
                disabledHours={() => {
                  return [...openHours, ...closeHours];
                }}
                disabledMinutes={disabledMinutes}
              />
            </Col>
            <Col span={8}>
              <TimePickerAutoAccept
                minuteStep={10}
                value={moment(item.endTime)}
                onChangeHour={onChangeHour(index, "endTime")}
                format={"HH:mm"}
                disabledHours={() => {
                  return [...openHours, ...closeHours];
                }}
                disabledMinutes={disabledMinutes}
              />
            </Col>
            <Col span={4}>
              <Radio.Group
                defaultValue={item.session}
                style={{ width: "90%", display: "grid" }}
                onChange={onChangeSession(index)}>
                <Radio value={"早"}>早</Radio>
                <Radio value={"中"}>中</Radio>
                <Radio value={"遅"}>遅</Radio>
              </Radio.Group>
            </Col>
            <MinusCircleFilled
              style={{ fontSize: 18, marginRight: 6 }}
              span={2}
              onClick={removeTimeRange(index)}
            />
            {timeRangeList.length < 5 && (
              <PlusCircleFilled
                style={{ fontSize: 18 }}
                span={2}
                onClick={addTimeRangeList}
              />
            )}
          </Row>
        );
      })}
    </Col>
  );
}

function Edit({ staffInfo, refetch, firstDayOfMonth }) {
  const shiftRequests = useRef([]);
  const [loading, setLoading] = useState(false);

  const onEdit = async () => {
    try {
      let isSame = false;
      // const isTimeNotValid = shiftRequests.current.some((item, index) => {
      //   if (moment(item.endTime).isSame(moment(item.startTime))) {
      //     isSame = true;
      //     return true;
      //   }
      //   const filterItemCurrent = shiftRequests.current.filter(
      //     (itm, idx) => index !== idx
      //   );
      //   const res = filterItemCurrent.some((itm, idx) => {
      //     if (moment(itm.endTime).isBefore(itm.startTime)) {
      //       return (
      //         moment(item.startTime).isSameOrAfter(moment(itm.startTime)) &&
      //         moment(item.startTime).isBefore(
      //           moment(itm.endTime).add(1, "days")
      //         )
      //       );
      //     }
      //     return (
      //       moment(item.startTime).isSameOrAfter(moment(itm.startTime)) &&
      //       moment(item.startTime).isBefore(moment(itm.endTime))
      //     );
      //   });
      //   return res;
      // });
      if (isSame) {
        message.warning(STRINGS.endtime_greater_than_starttime);
        return;
      }
      // if (isTimeNotValid) {
      //   message.warning(STRINGS.shift_duplicated);
      //   return;
      // }
      if (!shiftRequests.current.length) {
        message.warning(STRINGS.please_select_shift);
        return;
      }
      setLoading(true);
      const result = await FetchApi.updateSchedulePermanent({
        shiftRequests: shiftRequests.current,
        staffId: staffInfo.staffId,
        dayOfWeek: staffInfo.dayOfWeek,
        firstDayOfMonth: firstDayOfMonth,
      });
      if (result.success) {
        refetch();
        ModalCustomService.close();
        if (result.code == 2001) {
          message.warning(STRINGS.bed_full);
          return;
        }

        message.success(STRINGS.saved_common);
      } else {
        if (result.code == 4005) {
          message.warning(STRINGS.shift_duplicated);
        } else if (result.code == 4006) {
          message.warning(STRINGS.shift_existed_another_store);
        } else if (result.code == 4009) {
          message.error(STRINGS.cannot_update_shift);
        } else {
          message.error(result.message);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error(STRINGS.something_wrong);
    }
  };

  const deleteSchedule = async () => {
    ModalCustomService.close();
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${STRINGS.confirm_delete_common}`,
      onConfirm: async () => {
        const shiftRequestClone = [];
        shiftRequests.current.forEach((item) => {
          if (item.id) {
            shiftRequestClone.push({ ...item, type: "delete_schedule" });
          }
        });
        let result = await FetchApi.updateSchedulePermanent({
          shiftRequests: shiftRequestClone,
          staffId: staffInfo.staffId,
          dayOfWeek: staffInfo.dayOfWeek,
          firstDayOfMonth: firstDayOfMonth,
        });
        if (result.success) {
          refetch();
          message.success(STRINGS.deleted_common);
        } else {
          CommonFunction.logError(result);
        }
      },
    });
  };

  return (
    <Col className="checkin-time-wrapper">
      <button
        style={{
          position: "absolute",
          right: 14,
          top: 4,
          border: 0,
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
        onClick={deleteSchedule}>
        <SvgView svgData={Svgs.delete} />
      </button>

      <Row
        style={{
          color: "#4B2A05",
          fontSize: 18,
          paddingTop: 14,
          fontWeight: "bold",
          justifyContent: "center",
        }}>
        {STRINGS.please_select_your_commute_time}
      </Row>

      <HoursPicker
        onChange={(e) => (shiftRequests.current = e)}
        defaultValue={staffInfo.shiftRequests}
      />

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onEdit}>{STRINGS.sure}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}

export default Edit;
