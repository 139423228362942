import { Col, Row } from "antd";
import React, { useState } from "react";

import { COLORS } from "../constants";
import { useListStoreStaffNotWorking } from "../util/hooks";

function RadioAllStaffNotWorking({ defaultValue, onChange, day }) {
  const { listStoreStaffNotWorking } = useListStoreStaffNotWorking(day);
  const [value, setValue] = useState(defaultValue);

  const onChangeData = (id) => {
    setValue(id);
    onChange && onChange(id);
  };

  return (
    <Row style={{ margin: "10px 30px" }}>
      {(listStoreStaffNotWorking?.data || []).map((item) => {
        return (
          <Col
            onClick={() => {
              onChangeData(item.id);
            }}
            style={{ padding: 10, cursor: "pointer" }}
            key={"" + item.id}
            span={12}>
            <Row style={{ alignItems: "center" }}>
              <Row
                style={{
                  border: "1px solid #497D37",
                  width: 30,
                  height: 30,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 15,
                  marginRight: 12,
                }}>
                {value === item.id && (
                  <Row
                    style={{
                      width: 20,
                      height: 20,
                      borderRadius: 10,
                      backgroundColor: "#497D37",
                    }}
                  />
                )}
              </Row>
              {/* <img
                src={item.avatar}
                style={{ width: 36, height: 36, margin: "0px 12px" }}
              /> */}
              <Col style={{ fontSize: 14, color: COLORS.border_7 }}>
                {item.name}
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
}

export { RadioAllStaffNotWorking };
