import { useQuery } from "react-query";
import { FetchApi } from "../../api";

const useGetFixedSchedule = ({ firstDayOfMonth }) => {
  const value = useQuery([`useGetFixedSchedule-${1}-${firstDayOfMonth}`], () =>
    FetchApi.getSchedulePermanent({ firstDayOfMonth })
  );
  return {
    listFixedSchedule: value.data,
    ...value,
  };
};

export { useGetFixedSchedule };
