import React, { memo, useState } from "react";
import { Table, Col } from "antd";
import moment from "moment";

import { ButtonCustom, ErrorView } from "../../elements";
import { useGetFixedSchedule } from "../../util/hooks";
import { COLORS, STRINGS, LOGIC } from "../../constants";
import { CommonFunction } from "../../util/CommonFunction";

import "./StaffFixedSchedule.scss";

const StaffFixedSchedule = memo(() => {
  moment.locale("ja-jp"); // important!
  moment.updateLocale("ja-jp", {
    week: { dow: 1 },
    weekdaysMin: ["日", "月", "火", "水", "木", "金", "土"],
    monthsShort: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
  });

  const range = {
    firstDay: moment().startOf("week").format(LOGIC.dateFormat),
    lastDay: moment().endOf("week").format(LOGIC.dateFormat),
  };
  const [monthSelected, setMonthSelected] = useState(moment());
  const { listFixedSchedule, isLoadingListFixedSchedule } = useGetFixedSchedule(
    {
      range: range,
      firstDayOfMonth: moment(monthSelected)
        .startOf("month")
        .format("MM/DD/YYYY"),
    }
  );

  const renderItem = (e, index) => {
    if (!e[index]?.shiftDTOS) {
      return <div style={{ height: 30 }} />;
    }
    return e[index].shiftDTOS.map((shift, shiftIndex) => {
      return (
        <Col key={`${shiftIndex}`} style={{ textAlign: "-webkit-center" }}>
          {!!shift.session && (
            <Col
              style={{
                backgroundColor: LOGIC.shiftColor[shift.session],
                padding: "4px 24px",
                borderRadius: 6,
                marginBottom: 6,
                width: "fit-content",
              }}>
              {shift.session}
            </Col>
          )}
          <Col>
            {shift.startTime}~{shift.endTime}
          </Col>
        </Col>
      );
    });
  };

  // const onAdd = (e, index) => {
  //   const staffInfo = {
  //     staffId: e.staffId,
  //     day: moment(range.firstDay).add(index, "days").format(LOGIC.dateFormat),
  //     dayOfWeek: index + 1,
  //   };
  //   ModalCustomService.set({
  //     title: STRINGS.add_new,
  //     wrapperStyle: { width: "auto" },
  //     contentWrapperStyle: { width: "fit-content" },
  //     contentStyle: { maxHeight: "80vh", padding: 0 },
  //     content: <Add refetch={refetchListFixedSchedule} staffInfo={staffInfo} />,
  //   });
  // };

  const columns = [
    {
      title: STRINGS.staff_name_2,
      dataIndex: "staffName",
      key: "staffName",
    },
    {
      title: "月",
      key: "monday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) => renderItem({ ...e, staffId: others.staffId }, 0),
      width: "12%",
    },
    {
      title: "火",
      key: "tuesday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) => renderItem({ ...e, staffId: others.staffId }, 1),
      width: "12%",
    },
    {
      title: "水",
      key: "wednesday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) => renderItem({ ...e, staffId: others.staffId }, 2),
      width: "12%",
    },
    {
      title: "木",
      key: "thursday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) => renderItem({ ...e, staffId: others.staffId }, 3),
      width: "12%",
    },
    {
      title: "金",
      key: "friday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) => renderItem({ ...e, staffId: others.staffId }, 4),
      width: "12%",
    },
    {
      title: "土",
      key: "saturday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) => renderItem({ ...e, staffId: others.staffId }, 5),
      width: "12%",
    },
    {
      title: "日",
      key: "sunday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) => renderItem({ ...e, staffId: others.staffId }, 6),
      width: "12%",
    },
  ];

  if (listFixedSchedule && !listFixedSchedule.success) {
    return <ErrorView data={listFixedSchedule} />;
  }
  const listMonth = CommonFunction.generateMonth();
  return (
    <Col className="staff-fixed-schedule">
      {listMonth.map((i, index) => {
        let backgroundColor;
        let color = COLORS.primary;
        if (moment(i).month() === moment(monthSelected).month()) {
          backgroundColor = COLORS.primary;
          color = COLORS.background;
        }
        return (
          <ButtonCustom
            key={`${index}`}
            onClick={() => {
              setMonthSelected(moment(i));
            }}
            style={{
              minWidth: 100,
              width: 100,
              border: `1px solid ${COLORS.primary}`,
              alignItems: "center",
              height: "auto",
              marginLeft: 20,
              marginBottom: 20,
              fontSize: 20,
              boxShadow: "gray 0px 1px 4px 0px",
              backgroundColor,
              color,
            }}>
            {moment(i).month() + 1}
            {STRINGS.month}
          </ButtonCustom>
        );
      })}
      {/* <Row
        justify="center"
        align="center"
        style={{ fontSize: 16, fontWeight: "bold", marginBottom: 10 }}>
        {STRINGS.contact_before_15_days}
      </Row> */}
      <div
        style={{
          textAlign: "center",
          color: COLORS.sub_accent,
          fontSize: 25,
          fontWeight: "900",
        }}>
        {STRINGS.contact_before_15_days}
      </div>
      <Table
        bordered={true}
        loading={isLoadingListFixedSchedule}
        rowKey="staffId"
        columns={columns}
        dataSource={listFixedSchedule?.scheduleDTOS || []}
        pagination={false}
        scroll={{ x: "100%", y: "calc(100vh - 190px)" }}
      />
    </Col>
  );
});

// const Header = ({ setRange }) => {
//   const formatWeek = (value) =>
//     `${moment(value).startOf("week").format(LOGIC.dateFormat)} ~ ${moment(value)
//       .endOf("week")
//       .format(LOGIC.dateFormat)}`;

//   const onChange = (value) => {
//     const x = {
//       firstDay: moment(value).startOf("week").format(LOGIC.dateFormat),
//       lastDay: moment(value).endOf("week").format(LOGIC.dateFormat),
//     };
//     setRange(x);
//     return x;
//   };

//   return (
//     <Row className="header-wrapper">
//       <Col xl={8} xs={24}>
//         <DatePicker
//           suffixIcon={Svgs.datepicker()}
//           style={{
//             width: 230,
//             borderRadius: 4,
//             padding: "6px 10px",
//             marginRight: 20,
//           }}
//           defaultValue={moment().startOf("week")}
//           format={formatWeek}
//           allowClear={false}
//           picker={"week"}
//           onChange={onChange}
//         />
//       </Col>
//       <Col
//         xl={16}
//         xs={24}
//         style={{
//           fontSize: 16,
//           color: "#4B2A05",
//           fontWeight: "bold",
//           alignSelf: "center",
//         }}
//       >
//
//       </Col>
//     </Row>
//   );
// };

export default StaffFixedSchedule;
