const COLORS = {
  primary: "#F19834",
  primary_2: "#FF8000",
  primary_3: "#FFBB00",
  primary_4: "#E9B418",

  secondary: "#497d37",
  background: "#fff",
  text: "#464646",
  sub_primary: "#FFF9D8",
  success: "#00AF23",
  sub_text: "#676767",

  required: "#FF0C0C",
  required_2: "#ff4d4f",

  accent: "#4B2A05",
  sub_accent: "#6b4b3b",
  accent_2: "#bc8f8f",
  accent_3: "#C6E0B4",
  accent_4: "#87d068",
  accent_5: "#1F0427",

  sub_border: "#EFEFEF",
  disabled: "#C5C5C5",
  sub_secondary: "#4F7A28",
  sub_disabled: "#757575",

  border: "#C9C9C9",
  border_2: "#CBCBCB",
  border_3: "#B4BBC4",
  border_4: "#E9E9E9",
  border_5: "#999",
  border_6: "#6E6E6E",
  border_7: "#717171",

  sub_black: "#303030",

  gender_staff: {
    MALE: "#869CFF",
    FEMALE: "#FF94D6",
  },

  manager_schedule_background: "#68e2f2",
};
export { COLORS };
