import React from "react";
import { Form, Input } from "antd";
import { Validate } from "../../../elements";

function Webview({ data }) {
  return (
    <Form.Item
      initialValue={data?.link}
      className="form-action"
      label={"URL"}
      name="link"
      rules={[Validate.isUrlWithRequired]}>
      <Input />
    </Form.Item>
  );
}

export default Webview;
