/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, memo } from "react";
import { Table, Space, message, Switch, Image, Col } from "antd";
import { PlusCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "react-query";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import "antd/dist/antd.min.css";
import moment from "moment";
import arrayMove from "array-move";

import { FetchApi } from "../../api";
import {
  AlertService,
  ErrorView,
  Container,
  FilterByStore,
  SvgView,
  ButtonCustom,
} from "../../elements";
import { COLORS, STRINGS, Svgs } from "../../constants";
import { ModalCustomService } from "../../elements/ModalCustom/ModalCustomService";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import { CommonFunction } from "../../util/CommonFunction";
import Add from "./items/Add";
import Edit from "./items/Edit";

import "./Store.scss";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: COLORS.border_5 }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const Store = memo(() => {
  const cache = useQueryClient();
  const page = useRef(1);
  const [selectedStore, setStoreSelected] = useState("null");

  // const [display, setDisplay] = useState("hidden");
  const arrayPosition = useRef([]);
  const timeout = useRef();

  const columns = [
    {
      title: STRINGS.sort,
      dataIndex: "index",
      key: "index",
      render: () => <DragHandle />,
      width: 80,
      fixed: "left",
    },
    {
      title: STRINGS.store_name,
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      title: STRINGS.QR_list,
      dataIndex: "qrCodeImage",
      key: "qrCodeImage",
      render: (e, o) => {
        return (
          <Col style={{ textAlign: "center" }}>
            <Image
              style={{ width: 150, height: 150 }}
              src={
                e ||
                "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/5f75afb3-6220-413b-9823-56461b42b3873d7b3552-e660-43b2-8e35-6eec209c8543e5f33cf1-c54d-44d9-905a-f42ae506c3184088a280-f631-43c9-ad4c-58dd843cfd75.png"
              }
              alt="img"
            />
            <a
              style={{
                color: "blue",
                cursor: "pointer",
                textDecorationLine: "underline",
              }}
              onClick={() => {
                CommonFunction.setUpDownLoad(e, o.name);
              }}
            >
              {STRINGS.qr_download}
            </a>
          </Col>
        );
      },
      width: 200,
    },
    {
      title: STRINGS.phone_number,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 160,
    },
    {
      title: STRINGS.address,
      key: "address",
      dataIndex: "address",
    },
    {
      title: STRINGS.open_time,
      key: "openTime",
      dataIndex: "openTime",
      render: (item) =>
        item ? moment(item.trim(), "HH:mm").format("H:mm") : "",
      width: 100,
    },
    {
      title: STRINGS.close_time,
      key: "closeTime",
      dataIndex: "closeTime",
      render: (item) =>
        item ? moment(item.trim(), "HH:mm").format("H:mm") : "",
      width: 100,
    },
    {
      title: STRINGS.store_owner,
      key: "userOwner",
      dataIndex: "userOwner",
      render: (item) => (item ? item.name : null),
    },
    {
      title: STRINGS.booking_with_app,
      key: "booking",
      dataIndex: "booking",
      render: (booking, others) => {
        return (
          <Switch
            defaultChecked={booking}
            onChange={(checking) => onOnOffBooking(others, checking)}
          />
        );
      },
      fixed: "right",
      align: "center",
      width: 170,
    },
    {
      key: "action",
      render: (text, record) => {
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onClickEdit(record)}
            >
              <SvgView svgData={Svgs.edit} />
            </button>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onDelete(record)}
            >
              <SvgView svgData={Svgs.delete} />
            </button>
          </Space>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];

  const { data, refetch, isLoading } = useQuery(["all-store"], () => {
    return FetchApi.getAllStore(page.current);
  });
  useScrollByMouse(data);

  const setData = () => {
    cache.refetchQueries("all-store");
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    if (data) {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = data?.data?.findIndex(
        (x) => x.id === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
    } else {
      return <></>;
    }
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(data.data),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      cache.setQueryData("all-store", (oldData) => {
        return { ...oldData, data: newData };
      });
      arrayPosition.current = [];
      for (let index = 0; index < newData.length; index++) {
        let array = [];
        array.push(newData[index].id);
        array.push(newData.length - index);
        arrayPosition.current.push(array);
      }
      const result = await FetchApi.changeStorePosition(arrayPosition.current);
      if (result.success) {
        message.success(STRINGS.change_position_success);
      } else {
        message.error(result.message);
      }
    }
  };

  const onOnOffBooking = (e, checking) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(async () => {
      const action = checking ? "ON" : "OFF";
      const res = await FetchApi.actionBookingStore(action, e.id);
      if (!res.success) {
        message.error(res.message || STRINGS.something_wrong);
        setData();
      } else {
        message.success(STRINGS.done_successful);
      }
    }, 250);
  };

  const onDelete = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${item.name}${STRINGS.confirm_delete_store}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteStore(item.id);
        if (result.success) {
          refetch();
          message.success(STRINGS.deleted_common);
        } else {
          CommonFunction.logError(result);
        }
      },
    });
  };

  const onClickEdit = (item) => () => {
    ModalCustomService.set({
      title: STRINGS.update,
      contentStyle: { maxHeight: "80vh" },
      content: <Edit item={item} data={data} setData={refetch} />,
    });
  };

  const onAdd = () => {
    ModalCustomService.set({
      title: STRINGS.add_new,
      contentStyle: { maxHeight: "80vh" },
      content: <Add data={data} setData={refetch} />,
    });
  };

  // const onChangePosition = async () => {
  //   setDisplay("hidden");
  //   const result = await FetchApi.updatePositionStore(arrayPosition.current);
  //   if (result.success) {
  //     message.success(STRINGS.change_position_success);
  //   }
  // };
  const onSearchByStore = (e) => {
    refetch();
    setStoreSelected(e);
  };

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  let storeFilter = data?.data || [];
  if (selectedStore != "null") {
    storeFilter = data?.data.filter((item) => item.id == selectedStore);
  }

  return (
    <Container
      className="manager-store"
      renderLeft={() => (
        <FilterByStore
          title={STRINGS.store}
          onSearchByStore={onSearchByStore}
        />
      )}
      renderRight={() => (
        <ButtonCustom onClick={onAdd} icon={<PlusCircleOutlined />}>
          {STRINGS.add_new}
        </ButtonCustom>
      )}
    >
      <div className="content-store">
        <Table
          loading={isLoading}
          rowKey={"id"}
          columns={columns}
          dataSource={storeFilter || []}
          locale={{ emptyText: STRINGS.no_data }}
          pagination={false}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
          scroll={{ x: 1500, y: "calc(100vh - 270px)" }}
        />
      </div>
    </Container>
  );
});

export default Store;
