import React, { useState } from "react";
import { Col, DatePicker, TimePicker, Form } from "antd";
import { ClockCircleFilled } from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import { COLORS, Svgs, STRINGS } from "../../../constants";
import { ButtonCustom, Convert } from "../../../elements";
import Auth from "../../../store/Authentication";

import "../StaffBooking.scss";

function StaffBookingDate({ form }) {
  let [searchParams] = useSearchParams();
  const [selectedDay, setSelectedDay] = useState(() => {
    const paramsDate = searchParams.get("date");
    if (paramsDate && moment(paramsDate, "MM/DD/YYYY").isAfter(moment())) {
      return paramsDate;
    }
    if (form.getFieldValue("bookingDate")) {
      const formSelectedDay = form.getFieldValue("bookingDate");
      return moment(formSelectedDay).format("MM/DD/YYYY");
    }
    return moment().format("MM/DD/YYYY");
  });
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const infoStore = userAdmin.storeDTO;
  const openTime = moment(
    moment().format("MM/DD/YYYY") + `${infoStore.openTime}`,
    "MM/DD/YYYY HH:mm"
  );
  const closeTime = Convert.convertTime(
    infoStore.openTime,
    infoStore.closeTime
  );
  const [selectedDate, setSelectedDate] = useState(() => {
    const paramsDate = searchParams.get("date");
    if (paramsDate && moment(paramsDate, "MM/DD/YYYY").isAfter(moment())) {
      return moment(paramsDate, "MM/DD/YYYY");
    }
    if (form.getFieldValue("bookingDate")) {
      return form.getFieldValue("bookingDate");
    }
    if (moment().isBefore(openTime)) {
      return openTime;
    }
    const minuteNow = moment().minute() % 10;
    return moment().add(20 - minuteNow, "minutes");
  });

  const setDateToday = () => {
    if (moment().isBefore(openTime)) {
      setSelectedDate(openTime);
      form.setFieldsValue({
        bookingDate: openTime,
      });
      return;
    }
    const minuteNow = moment().minute() % 10;
    const dateToday = moment().add(20 - minuteNow, "minutes");
    setSelectedDate(dateToday);
    form.setFieldsValue({
      bookingDate: dateToday,
    });
  };
  // const setDateTomorrow = () => {
  //   const dateTomorrow = moment(openTime).add(1, "day");
  //   setSelectedDate(dateTomorrow);
  //   form.setFieldsValue({
  //     bookingDate: dateTomorrow,
  //   });
  // };
  let styleToday = {
    backgroundColor: COLORS.background,
    color: COLORS.text,
    borderColor: COLORS.border,
  };
  // let styleTomorrow = {
  //   backgroundColor: COLORS.background,
  //   color: COLORS.text,
  //   borderColor: COLORS.border,
  // };

  if (
    moment(selectedDate).format("MM/DD/YYYY") === moment().format("MM/DD/YYYY")
  ) {
    styleToday = {
      backgroundColor: COLORS.primary,
      color: COLORS.background,
      borderColor: COLORS.primary,
    };
  }
  // if (
  //   moment(selectedDate).format("MM/DD/YYYY") ===
  //   moment().add(1, "day").format("MM/DD/YYYY")
  // ) {
  //   styleTomorrow = {
  //     backgroundColor: COLORS.primary,
  //     color: COLORS.background,
  //     borderColor: COLORS.primary,
  //   };
  // }
  return (
    <Form.Item name="bookingDate" initialValue={selectedDate}>
      <div>
        <div className="text-booking">{STRINGS.des_booking_1}</div>
        <div className="book-date">
          <ButtonCustom
            onClick={setDateToday}
            style={{
              height: 40,
              minWidth: 100,
              fontSize: 14,
              padding: "4px 14px",
              ...styleToday,
            }}>
            {STRINGS.now}
          </ButtonCustom>
          {/* <ButtonCustom
            onClick={setDateTomorrow}
            style={{
              height: 40,
              minWidth: 100,
              fontSize: 14,
              padding: "4px 14px",
              marginLeft: "10px",
              ...styleTomorrow,
            }}>
            {STRINGS.tomorrow}
          </ButtonCustom> */}
          <Col style={{ marginLeft: 10 }}>
            {/* <Row>
              {STRINGS.day_book}
              <div style={{ color: COLORS.required, marginLeft: "5px" }}>*</div>
            </Row> */}
            <DatePicker
              value={selectedDate}
              suffixIcon={Svgs.datepicker()}
              style={{
                borderRadius: 5,
                padding: "6px 10px",
                height: 40,
                width: 180,
              }}
              disabledDate={(e) => {
                let customDate = moment().format("MM/DD/YYYY");
                return (
                  e &&
                  (e.isBefore(moment(customDate, "MM/DD/YYYY")) ||
                    e.isAfter(moment(customDate, "MM/DD/YYYY").add(1, "month")))
                );
              }}
              showToday={false}
              allowClear={false}
              // onSelect={(e) => {
              //   setSelectedDate(moment(e));
              //   setSelectedDay(moment(e).format("MM/DD/YYYY"));
              //   const hourSelectedDate = moment(selectedDate).hour();
              //   const hourNow = moment().hour();
              //   if (
              //     moment(e).format("MM/DD/YYYY") ===
              //       moment().format("MM/DD/YYYY") &&
              //     hourSelectedDate < hourNow
              //   ) {
              //     const minuteNow = moment().minute() % 10;
              //     const dateToday = moment().add(20 - minuteNow, "minutes");
              //     setSelectedDate(dateToday);
              //     form.setFieldsValue({
              //       bookingDate: dateToday,
              //     });
              //   }
              // }}
              onChange={(e) => {
                setSelectedDate(moment(e));
                setSelectedDay(moment(e).format("MM/DD/YYYY"));
                form.setFieldsValue({
                  bookingDate: moment(e),
                });
                const hourSelectedDate = moment(selectedDate).hour();
                const hourNow = moment().hour();
                if (
                  moment(e).format("MM/DD/YYYY") ===
                    moment().format("MM/DD/YYYY") &&
                  hourSelectedDate < hourNow
                ) {
                  const minuteNow = moment().minute() % 10;
                  const dateToday = moment().add(20 - minuteNow, "minutes");
                  setSelectedDate(dateToday);
                  form.setFieldsValue({
                    bookingDate: dateToday,
                  });
                }
              }}
              format={"MM月DD日"}
              picker={"date"}
            />
          </Col>
          <Col style={{ marginLeft: 10 }}>
            {/* <Row>
              {STRINGS.hour_book}
              <div style={{ color: COLORS.required, marginLeft: "5px" }}>*</div>
            </Row> */}
            <TimePicker
              value={selectedDate}
              suffixIcon={
                <ClockCircleFilled
                  style={{ fontSize: 20, color: COLORS.sub_disabled }}
                />
              }
              style={{
                borderRadius: 5,
                padding: "6px 10px",
                height: 40,
                width: 130,
              }}
              disabledDate={(e) => {
                let customDate = moment().format("MM/DD/YYYY");
                return e && e.isBefore(moment(customDate, "MM/DD/YYYY"));
              }}
              allowClear={false}
              showNow={false}
              onSelect={(e) => {
                setSelectedDate(moment(e));
                form.setFieldsValue({
                  bookingDate: moment(e),
                });
              }}
              format={"HH:mm"}
              picker={"date"}
              showTime={{
                format: "HH:mm",
                minuteStep: 10,
                disabledHours: () => {
                  const hours = [];
                  // check if moment is today and is after opening time
                  if (
                    moment().isAfter(openTime) &&
                    moment().format("MM/DD/YYYY") === selectedDay
                  ) {
                    if (moment().minute() >= 50) {
                      for (let i = 0; i < moment().hour() + 1; i++) {
                        hours.push(i);
                      }
                    } else {
                      for (let i = 0; i < moment().hour(); i++) {
                        hours.push(i);
                      }
                    }
                  } else {
                    if (closeTime.hour() < openTime.hour()) {
                      for (let i = closeTime.hour(); i < openTime.hour(); i++) {
                        hours.push(i);
                      }
                    } else {
                      for (let i = 0; i < openTime.hour(); i++) {
                        hours.push(i);
                      }
                    }
                  }

                  if (closeTime.hour() > openTime.hour()) {
                    for (let i = closeTime.hour() + 1; i <= 23; i++) {
                      hours.push(i);
                    }
                  }

                  return hours;
                },
                disabledMinutes: (hourSelected) => {
                  const minutes = [];
                  const minuteNow = moment().minute() % 10;
                  const minMinute = moment()
                    .add(20 - minuteNow, "minutes")
                    .minute();
                  if (
                    moment().format("MM/DD/YYYY") === selectedDay &&
                    hourSelected === moment().hour()
                  ) {
                    for (let i = 0; i <= minMinute; i++) {
                      minutes.push(i);
                    }
                  }
                  return minutes;
                },
              }}
            />
          </Col>
        </div>
      </div>
    </Form.Item>
  );
}

export { StaffBookingDate };
