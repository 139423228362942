import React, { useRef } from "react";
import { Col, Input, message, Row } from "antd";

import { COLORS, STRINGS } from "../../constants";
import { ButtonCustom, LoadingView, ModalCustomService } from "../../elements";
import { FetchApi } from "../../api";
import { CommonFunction } from "../../util/CommonFunction";
import { NewestConfirmRevenue } from "./items/NewestConfirmRevenue";
import { useGetTodayStoreRevenue } from "./modules/useGetTodayStoreRevenue";
import RadioAllStoreStaff from "../../elements/RadioAllStoreStaff";

import "./ModalConfirmRevenue.scss";

export default function ModalConfirmRevenue() {
  const { data: dataStoreRevenue, isLoading } = useGetTodayStoreRevenue();
  const data = dataStoreRevenue?.storeRevenue || {};
  const staffChangeRequest = useRef({});
  const commentRef = useRef();

  const onConfirm = async () => {
    if (!staffChangeRequest.current.id) {
      message.warning(STRINGS.please_select_a_reception_staff);
      return;
    }
    if (!staffChangeRequest.current.password) {
      message.warning(STRINGS.please_type_password);
      return;
    }
    const result = await FetchApi.confirmStoreRevenue({
      ...data,
      staffIdAndPasswordRequest: {
        staffId: staffChangeRequest.current.id,
        password: staffChangeRequest.current.password,
      },
      commentFromConfirmedStaff: commentRef.current,
    });
    if (result.success) {
      message.success(STRINGS.done_successful);
      ModalCustomService.close();
      return;
    }
    if (result.code === 405) {
      message.warning(STRINGS.password_is_incorrect);
    }
  };
  const columns = [
    // {
    //   label: STRINGS.start_day,
    //   border: true,
    //   colWrapper: 5,
    //   value:
    //     data.date &&
    //     moment(data.date).format("YYYY/MM/DD") +
    //       " " +
    //       LOGIC.day[moment(data.date).isoWeekday() || 7] +
    //       STRINGS.coupon_day,
    // },
    // {
    //   label: STRINGS.total_money2,
    //   border: true,
    //   colWrapper: 5,
    //   value: CommonFunction.currencyFormat(data.totalRevenue) + STRINGS.yen,
    // },
    {
      label: STRINGS.total_working_min2,
      colWrapper: 5,
      value: CommonFunction.minute(data.shiftTimes) + STRINGS.minute,
    },
    {
      label: STRINGS.total_user2,
      colWrapper: 5,
      value: data.userQuantity + STRINGS.people,
    },
    {
      label: STRINGS.total_course_min2,
      colWrapper: 5,
      value: CommonFunction.minute(data.courseTimes) + STRINGS.minute,
    },
    {
      label: STRINGS.total_staff2,
      colWrapper: 5,
      border: true,
      value: data.staffQuantity + STRINGS.people,
    },

    {
      label: STRINGS.total_staff_selected2,
      colWrapper: 5,
      border: true,
      value: data.bookingChooseStaffQuantity + STRINGS.people,
    },
    {
      label: STRINGS.working_percentage2,
      colWrapper: 5,
      border: true,
      value: CommonFunction.roundPercent(data.workingRate || 0) + "%",
    },
    {
      label: STRINGS.total_D_user2,
      colWrapper: 5,
      value: data.userDQuantity + STRINGS.people,
    },
    {
      label: STRINGS.total_A_user2,
      colWrapper: 5,
      value: data.userAQuantity + STRINGS.people,
    },
    {
      label: STRINGS.total_S_user2,
      colWrapper: 5,
      value: data.userSQuantity + STRINGS.people,
    },
    {
      label: STRINGS.total_P_user2,
      colWrapper: 5,
      value: data.userPQuantity + STRINGS.people,
    },
    {
      label: STRINGS.total_R_user2,
      colWrapper: 5,
      value: data.userRQuantity + STRINGS.people,
    },
  ];
  return (
    <Col className="revenue-store">
      {isLoading ? (
        <LoadingView />
      ) : (
        <Col>
          <Col
            style={{
              paddingTop: 20,
              marginBottom: 20,
              borderBottom: `3px solid ${COLORS.accent}`,
              paddingBottom: 20,
            }}
            span={24}>
            <Row align="middle" style={{ justifyContent: "center" }}>
              <Col style={{ width: 166 }} className="label-money">
                {STRINGS.total_money2}
              </Col>
              <Col className="value-money">
                {CommonFunction.currencyFormat(data.totalRevenue) + STRINGS.yen}
              </Col>
            </Row>
            <Row style={{ justifyContent: "center", paddingTop: 20 }}>
              <Row align="middle">
                <Col style={{ width: 120 }} className="label-base">
                  {STRINGS.pay_credit}
                </Col>
                <Col className="value-base">
                  {CommonFunction.currencyFormat(0) + STRINGS.yen}
                </Col>
              </Row>
              <Row align="middle" style={{ paddingLeft: 70 }}>
                <Col style={{ width: 120 }} className="label-base">
                  {STRINGS.pay_cash}
                </Col>
                <Col className="value-base" style={{ color: COLORS.required }}>
                  {CommonFunction.currencyFormat(data.totalRevenue) +
                    STRINGS.yen}
                </Col>
              </Row>
            </Row>
          </Col>
          <Row
            style={{
              paddingTop: 30,
            }}>
            {columns.map((item) => {
              return (
                <Col
                  style={{
                    marginBottom: 20,
                    borderBottom: item.border
                      ? `3px solid ${COLORS.accent}`
                      : null,
                    paddingBottom: 20,
                    paddingLeft: "5%",
                  }}
                  span={8}
                  key={`${item.label}`}>
                  <Row align="middle">
                    <Col style={{ width: 110 }} className="label-base">
                      {item.label}
                    </Col>
                    <Col className="value-base">{item.value}</Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Col>
      )}
      <Row
        style={{
          color: COLORS.accent,
          fontSize: 18,
          paddingTop: 14,
          fontWeight: "bold",
          justifyContent: "center",
        }}>
        {STRINGS.staff_lockup}
      </Row>
      <RadioAllStoreStaff
        onChange={(e) => (staffChangeRequest.current = e)}
        containerStyle={{
          justifyContent: "space-between",
          marginRight: 0,
        }}
        itemStyle={{
          paddingLeft: "12.5%",
        }}
      />
      <Col
        style={{
          paddingLeft: "5%",
          paddingRight: "5%",
          alignSelf: "center",
        }}>
        <Row
          style={{
            fontSize: 19,
            fontWeight: "bold",
            color: COLORS.sub_accent,
          }}>
          {STRINGS.remarks}
        </Row>
        <Input.TextArea
          onChange={(e) => {
            commentRef.current = e.target.value;
          }}
          autoSize
          style={{
            border: `1px solid ${COLORS.border}`,
            borderRadius: 5,
          }}
        />
      </Col>
      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onConfirm}>{STRINGS.confirm}</ButtonCustom>
      </Row>
      {dataStoreRevenue?.confirmedHistory?.length > 0 && (
        <NewestConfirmRevenue data={dataStoreRevenue?.confirmedHistory} />
      )}
    </Col>
  );
}
