import { Row } from "antd";
import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { ModalCustomService } from "../../../../elements";

function Header({ staffInfo }) {
  return (
    <Row className="title-modal">
      <Row style={{ color: "white", fontSize: 25, fontWeight: 700 }}>
        {staffInfo.staffName}
      </Row>
      <button onClick={ModalCustomService.close}>
        <CloseOutlined
          className="close"
          style={{ fontSize: "16px", color: "#fff" }}
        />
      </button>
    </Row>
  );
}

export default Header;
