import React from "react";
import { Col } from "antd";

import { STRINGS } from "../../../constants";
import { ButtonCustom, ModalCustomService } from "../../../elements";

export function BookingManualDetailBottomButton({ onCreateBooking }) {
  return (
    <Col>
      <ButtonCustom
        isCancelButton
        style={{
          fontSize: 19,
          fontWeight: "bold",
          float: "left",
          marginTop: 10,
        }}
        onClick={ModalCustomService.close}>
        {STRINGS.cancel}
      </ButtonCustom>
      <ButtonCustom
        style={{
          fontSize: 19,
          fontWeight: "bold",
          float: "right",
          marginTop: 10,
        }}
        onClick={() => onCreateBooking({})}>
        {STRINGS.sure}
      </ButtonCustom>
    </Col>
  );
}
