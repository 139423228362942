/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Upload,
  Select,
  Input,
  message,
  Spin,
  Typography,
  Button,
  Image,
} from "antd";
import ImgCrop from "antd-img-crop";
import {
  CaretDownOutlined,
  LoadingOutlined,
  PlusOutlined,
  StarFilled,
} from "@ant-design/icons";

import "../StoreStaff.scss";
import { STRINGS } from "../../../constants/Strings";
import { useForceUpdate } from "../../../elements/ForceUpdate";
import { CommonFunction } from "../../../util/CommonFunction";
import { FetchApi } from "../../../api";
import { Validate } from "../../../elements/Validate";
import {
  ButtonCustom,
  ModalCustomService,
  LoadingAbsolute,
  SvgView,
} from "../../../elements";
import Auth from "../../../store/Authentication";
import { useGetComboList } from "../../../util/hooks/useGetComboList";
import { COLORS, Svgs } from "../../../constants";
import { useQuery } from "react-query";
import Bronze from "../../../assets/img/bronze.png";
import Silver from "../../../assets/img/sliver.png";
import Gold from "../../../assets/img/gold.png";
import Platinum from "../../../assets/img/platinum.png";
import Diamond from "../../../assets/img/diamond.png";
function Edit({ setData, data, listStore, item, setNewData, defaultPassword }) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const storePicked = listStore.find((item) => item.id == userAdmin.storeId);
  const [password, setPassword] = useState(defaultPassword);
  const [form] = Form.useForm();
  // const { listSkill } = useGetListSkill();
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const forceUpdate = useForceUpdate();
  const avatarUrl = useRef(item.avatarUrl);
  const skillRequests = useRef(item.skills || []);
  const rank = Form.useWatch("rank", form);
  const rankImage = useRef();
  const images = {
    ブロンズ級: Bronze,
    シルバー級: Silver,
    ゴールド級: Gold,
    プラチナ級: Platinum,
    ダイヤモンド級: Diamond,
  };

  const rankPost = {
    ブロンズ級: "BRONZE",
    シルバー級: "SILVER",
    ゴールド級: "GOLD",
    プラチナ級: "PLATINUM",
    ダイヤモンド級: "DIAMOND",
  };
  const { data: dataRating, isFetching: isFetchingRating } = useQuery(
    [`data-rating-${item?.id}`],
    () => FetchApi.getRatingStaff(item?.id)
  );
  const {
    data: dataPacks,
    isFetching,
  } = useQuery([`combo-pack-${item?.id}`], () =>
    FetchApi.getPacksStaff(item?.id)
  );

  const removeImg = () => {
    avatarUrl.current = "";
    forceUpdate();
  };
  const handleUpImage = async (file) => {
    setLoadingImg(true);
    let result = await FetchApi.uploadFile(file);
    if (result?.fileUrl) {
      avatarUrl.current = result.fileUrl;
      setLoadingImg(false);
    } else {
      message.warning(STRINGS.upload_img_failed);
      setLoadingImg(false);
    }
  };
  let patternFee = new RegExp(/^[0-9]+$/);
  let staffFee = item?.designationFee;
  rankImage.current = images[rank];

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const uploadButton = (
    <div>
      {loadingImg ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined style={{ color: COLORS.primary_3, fontSize: 64 }} />
      )}
    </div>
  );

  // const range = (a, b = 23) => {
  //   const arr = [];
  //   while (a < b + 1) {
  //     arr.push(a++);
  //   }
  //   return arr;
  // };

  // const disabledMinutes = (selectedHour) => {
  //   let minutes = [];
  //   const openHour =
  //     storePicked && storePicked.openTime
  //       ? parseInt(storePicked.openTime.slice(0, 2))
  //       : [];
  //   const closeHour =
  //     storePicked && storePicked.closeTime
  //       ? parseInt(storePicked.closeTime.slice(0, 2))
  //       : 24;
  //   const openMinute =
  //     storePicked && storePicked.openTime
  //       ? parseInt(storePicked.openTime.slice(3, 5))
  //       : [];
  //   const closeMinute =
  //     storePicked && storePicked.closeTime
  //       ? parseInt(storePicked.closeTime.slice(3, 5))
  //       : [];
  //   if (selectedHour == closeHour) {
  //     for (let i = 0; i < 60; i++) {
  //       if (i > closeMinute) {
  //         minutes.push(i);
  //       }
  //     }
  //   }
  //   return minutes;
  // };

  const onAdd = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      const updateRank = rankPost[rank];
      fieldData.id = item.id;
      fieldData.avatarUrl = avatarUrl.current;
      fieldData.storeIds = [userAdmin.storeId];
      fieldData.skillRequests = skillRequests.current;
      fieldData.skills = skillRequests.current;
      fieldData.rank = updateRank;
      setLoading(true);
      const result = await FetchApi.updateStaff(fieldData);
      if (result.success) {
        ModalCustomService.close();
        setData([result.staffDTO, ...(data?.data || [])]);
        // setNewData({ packs: [], rating: [] });
        message.success(STRINGS.add_staff_successful);
      } else if (result.message === "This staff phone already exist") {
        setLoading(false);
        message.error(STRINGS.phone_is_existed);
      } else if (result.message === "This staff email already exist") {
        setLoading(false);
        message.error(STRINGS.email_is_existed);
      } else {
        setLoading(false);
        message.error(result.message || STRINGS.add_staff_failed);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  // const openHour = storePicked?.openTime
  //   ? parseInt(storePicked.openTime.slice(0, 2))
  //   : [];
  // const closeHour = storePicked?.closeTime
  //   ? parseInt(storePicked.closeTime.slice(0, 2))
  //   : 24;
  // // let openHours = [];
  // // let closeHours = [];

  // if (closeHour > openHour) {
  //   // openHours = Array.from(Array(openHour).keys());
  //   // closeHours = range(closeHour + 1);
  // } else {
  //   // openHours = range(closeHour + 1, openHour - 1);
  // }

  const handleResetPassword = async () => {
    if (password) {
      await FetchApi.resetStaffPassword({
        staffId: item?.id,
        password: password,
      });
      message.success("パスワードリセットが成功しました。");
    } else message.warning(STRINGS.please_fill_required_fields);
  };
  return (
    <Col>
      <Form layout="vertical" form={form}>
        <Row gutter={20}>
          <Col className="staff-avatar">
            <div className="name">{STRINGS.avatar}</div>
            {avatarUrl.current && (
              <button
                style={{
                  position: "absolute",
                  alignSelf: "flex-end",
                  top: 30,
                  right: 10,
                  width: "32px",
                  height: "32px",
                  borderWidth: 0,
                  backgroundColor: "white",
                  borderRadius: 8,
                  overflow: "hidden",
                  paddingTop: 5,
                  cursor: "pointer",
                }}
                onClick={removeImg}
              >
                <SvgView svgData={Svgs.delete} />
              </button>
            )}
            <ImgCrop
              modalTitle={STRINGS.editImageCrop}
              modalOk={STRINGS.ok}
              modalCancel={STRINGS.cancel}
              rotationSlider
              cropShape={"round"}
              aspect={1}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={CommonFunction.beforeUpload}
                customRequest={handleUpImage}
                onPreview={onPreview}
              >
                {avatarUrl?.current ? (
                  <img
                    style={{ width: 120, borderRadius: 60 }}
                    src={avatarUrl.current}
                    alt="img"
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
            <div
              style={{
                top: 30,
                position: "absolute",
                right: 15,
                backgroundColor: "white",
                borderRadius: "100%",
                overflow: "hidden",
                cursor: "pointer",
              }}
            >
              {rankImage.current && (
                <Image
                  preview={!!rankImage.current}
                  src={rankImage.current}
                  width={32}
                  height={32}
                />
              )}
            </div>
          </Col>
          <Col style={{ flex: 1 }}>
            <Row gutter={20}>
              <Col xl={8} md={8} xs={8}>
                <Form.Item
                  label={STRINGS.staff_name_2}
                  name="name"
                  required
                  rules={[Validate.emptyContent]}
                  initialValue={item.name}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xl={8} md={8} xs={8}>
                <Form.Item
                  label={STRINGS.gender}
                  name="sex"
                  initialValue={item.sex}
                  required
                  rules={[Validate.emptyContent]}
                >
                  <Select
                    suffixIcon={
                      <CaretDownOutlined style={{ color: COLORS.border_6 }} />
                    }
                  >
                    {[
                      { name: STRINGS.male, id: "male" },
                      { name: STRINGS.female, id: "female" },
                    ].map((item, index) => (
                      <Select.Option key={"" + item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} md={8} xs={8}>
                <Form.Item
                  label={STRINGS.email}
                  name="email"
                  rules={[Validate.email]}
                  initialValue={item.email}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xl={8} md={8} xs={8}>
                <Form.Item
                  label={STRINGS.phone_number}
                  name="phoneNumber"
                  required
                  rules={[Validate.phoneNumberJp]}
                  initialValue={item.phoneNumber}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xl={8} md={8} xs={8}>
                <Form.Item
                  label={STRINGS.interval_time}
                  name="restMinute"
                  required
                  rules={[Validate.emptyContent]}
                  initialValue={item.restMinute}
                >
                  <Select
                    suffixIcon={
                      <CaretDownOutlined style={{ color: COLORS.border_6 }} />
                    }
                  >
                    {[
                      { name: `30 ${STRINGS.minute}`, id: "30" },
                      { name: `20 ${STRINGS.minute}`, id: "20" },
                      { name: `10 ${STRINGS.minute}`, id: "10" },
                    ].map((item, index) => (
                      <Select.Option key={"" + item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} md={8} xs={8}>
                <Form.Item
                  label={STRINGS.rank.title}
                  name="rank"
                  initialValue={STRINGS.rank[item.rank?.rank.toLowerCase()]}
                >
                  <Select
                    disabled
                    options={[
                      {
                        value: STRINGS.rank.bronze,
                        label: STRINGS.rank.bronze,
                      },

                      {
                        value: STRINGS.rank.silver,
                        label: STRINGS.rank.silver,
                      },
                      {
                        value: STRINGS.rank.gold,
                        label: STRINGS.rank.gold,
                      },
                      {
                        value: STRINGS.rank.platinum,
                        label: STRINGS.rank.platinum,
                      },
                      {
                        value: STRINGS.rank.diamond,
                        label: STRINGS.rank.diamond,
                      },
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Col xl={8} md={8} xs={8}>
                <Row>
                  <Form.Item
                    label={<span>{STRINGS.designation_fee}</span>}
                    name="designationFee"
                    rules={[
                      {
                        pattern: patternFee,
                        message: STRINGS.number_please,
                      },
                    ]}
                    initialValue={staffFee || 0}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                  <Col style={{ marginTop: 26, fontSize: 16, marginLeft: 10 }}>
                    {STRINGS.yen}
                  </Col>
                </Row>
              </Col>
              {/* <Col xl={8} md={12} xs={12}>
                <Form.Item
                  label={STRINGS.procedure_range}
                  name="skill"
                  initialValue={skillRequests.current.map((itm) => itm.id)}>
                  <Select
                    mode="multiple"
                    suffixIcon={
                      <CaretDownOutlined style={{ color:  }} />
                    }
                    onChange={(e, f) => {
                      skillRequests.current = [];
                      e.forEach((element) => {
                        const it = listSkill.find((itm) => itm.id == element);
                        skillRequests.current.push(it);
                      });
                    }}>
                    {listSkill.map((item, index) => (
                      <Select.Option key={"" + item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
            {!!defaultPassword && (
              <Row>
                <Form.Item label={STRINGS.reset_password_staff}>
                  <div style={{ display: "flex", marginBottom: 10 }}>
                    <Input
                      style={{ borderRadius: 3 }}
                      placeholder={STRINGS.password}
                      defaultValue={defaultPassword}
                      // iconRender={(visible) =>
                      //   visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      // }
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                      style={{
                        marginLeft: 5,
                        backgroundColor: COLORS.primary,
                        color: COLORS.background,
                      }}
                      onClick={handleResetPassword}
                    >
                      {STRINGS.confirm}
                    </Button>
                  </div>
                </Form.Item>
              </Row>
            )}
            {((!isFetchingRating && !!dataRating) ||
              (!isFetchingRating && dataRating === 0)) && (
              <Row>
                <Typography>
                  <span style={{ color: COLORS.sub_text }}>
                    {STRINGS.average_score}
                  </span>{" "}
                  &ensp; {dataRating}
                  <StarFilled
                    style={{ color: COLORS.primary_4, marginLeft: 3 }}
                  />
                </Typography>
              </Row>
            )}
            {((!isFetchingRating && !!dataRating) ||
              (!isFetchingRating && dataRating === 0)) && <br />}
            <Row style={{ width: "100%", padding: 0 }}>
              <Form.Item
                style={{ width: "100%" }}
                label={STRINGS.self_introduction}
                name="selfIntroduction"
                initialValue={item.selfIntroduction}
              >
                <Input />
              </Form.Item>
            </Row>
            <Row style={{ width: "100%" }}>
              {!isFetching && (
                <Form.Item
                  name="comboPackIds"
                  style={{ width: "100%" }}
                  initialValue={dataPacks || []}
                >
                  <WorkableCombo />
                </Form.Item>
              )}
            </Row>
          </Col>
        </Row>
        {/* <Form.Item name="staffScheduleRequests" initialValue={item.scheduleDTO}>
          <ListCalendar
            disabledMinutes={disabledMinutes}
            openHours={openHours}
            closeHours={closeHours}
          />
        </Form.Item> */}
      </Form>

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}
        >
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onAdd}>{STRINGS.save}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}
function WorkableCombo({ value, onChange }) {
  const list = useRef({});
  const { listCombo, isLoadingGetComboList, isFetchingGetComboList } =
    useGetComboList();

  useEffect(() => {
    if (value?.length > 0) {
      const arrCombo = [];
      const cloneList = {};
      value.forEach((item) => {
        if (Array.isArray(cloneList[item?.comboId])) {
          cloneList[item.comboId].push(item.id);
        } else {
          cloneList[item.comboId] = [];
          cloneList[item.comboId].push(item.id);
        }
        arrCombo.push(item.id);
      });
      onChange(arrCombo);
      list.current = cloneList;
    }
  }, []);

  if (isLoadingGetComboList || isFetchingGetComboList) {
    return <Spin style={{ position: "absolute", left: `calc(100% / 2)` }} />;
  }
  return (
    <Col>
      {listCombo.map((item) => {
        return (
          <Row
            key={item.id}
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 6,
            }}
          >
            <Col span={5} style={{ color: "#757575" }}>
              {item.name}
            </Col>
            <Col span={18}>
              <Select
                defaultValue={list.current[item.id]}
                onChange={(e) => {
                  let apiData = [];
                  list.current = { ...list.current, [item.id]: e };
                  Object.values(list.current).forEach((item) => {
                    apiData = [...apiData, ...item];
                    onChange(apiData);
                  });
                }}
                mode="multiple"
                suffixIcon={
                  <CaretDownOutlined style={{ color: COLORS.border_6 }} />
                }
              >
                {!isFetchingGetComboList &&
                  item?.comboPackDTOS.map((item, index) => (
                    <Select.Option key={`${item.id}`} value={item.id}>
                      {item.comboName}({item.minutes}分)
                    </Select.Option>
                  ))}
              </Select>
            </Col>
          </Row>
        );
      })}
    </Col>
  );
}
// function ListCalendar({
//   onChange,
//   disabledMinutes,
//   openHours,
//   closeHours,
//   value,
// }) {
//   const [list, setList] = useState([]);

//   React.useEffect(() => {
//     const listClone = [];
//     for (let i = 1; i < 8; i++) {
//       listClone.push({
//         dayOfWeek: i,
//         shiftRequests: [
//           { startTime: "", endTime: "" },
//           { startTime: "", endTime: "" },
//           { startTime: "", endTime: "" },
//           { startTime: "", endTime: "" },
//           { startTime: "", endTime: "" },
//         ],
//       });
//       if (Array.isArray(value?.dayOfWeekDTOS)) {
//         value.dayOfWeekDTOS.forEach((day, dayIndex) => {
//           if (day.dayOfWeek == i) {
//             if (Array.isArray(day.shiftDTOS)) {
//               day.shiftDTOS.forEach((shift, shiftIndex) => {
//                 listClone[dayIndex].shiftRequests[shiftIndex].startTime =
//                   shift.startTime;
//                 listClone[dayIndex].shiftRequests[shiftIndex].endTime =
//                   shift.endTime;
//                 listClone[dayIndex].shiftRequests[shiftIndex].id = shift.id;
//               });
//             }
//           }
//         });
//       }
//     }
//     setList(listClone);
//     onChange(listClone);
//   }, []);

//   const onChangeCalendar = (item, e, shiftIndex) => {
//     const listClone = [...list];
//     const shiftItem = listClone[item.dayOfWeek - 1].shiftRequests[shiftIndex];
//     if (shiftItem.id && e?.[0]) {
//       listClone[item.dayOfWeek - 1].shiftRequests[shiftIndex].type = "update";
//     }
//     if (shiftItem.id && !e?.[0]) {
//       listClone[item.dayOfWeek - 1].shiftRequests[shiftIndex].type = "delete";
//     }
//     listClone[item.dayOfWeek - 1].shiftRequests[shiftIndex].startTime = e
//       ? moment(e[0]).format("HH:mm")
//       : "";
//     listClone[item.dayOfWeek - 1].shiftRequests[shiftIndex].endTime = e
//       ? moment(e[1]).format("HH:mm")
//       : "";
//     setList(listClone);
//     onChange(listClone);
//   };

//   return (
//     <div className="calendar">
//       <div className="row-title">{STRINGS.config_schedule}</div>
//       <div className="calendar-content">
//         {list.map((day, dayIndex) => {
//           return (
//             <div key={`${dayIndex}`} className="item-calendar">
//               <div className="item-day">{LOGIC.day[day.dayOfWeek]}</div>
//               <Col>
//                 {day.shiftRequests.map((shift, shiftIndex) => {
//                   let defaultVal = [null, null];
//                   if (shift.startTime && shift.endTime) {
//                     defaultVal = [
//                       moment(shift.startTime, "HH:mm"),
//                       moment(shift.endTime, "HH:mm"),
//                     ];
//                   }
//                   return (
//                     <TimePicker.RangePicker
//                       defaultValue={defaultVal}
//                       key={`${shiftIndex}`}
//                       className="item-button"
//                       format="HH:mm"
//                       suffixIcon={false}
//                       placeholder={[STRINGS.start_time, STRINGS.end_time]}
//                       bordered={false}
//                       onChange={(e) => onChangeCalendar(day, e, shiftIndex)}
//                       disabledHours={() => {
//                         return [...openHours, ...closeHours];
//                       }}
//                       disabledMinutes={disabledMinutes}
//                       order={true}
//                     />
//                   );
//                 })}
//               </Col>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

export default Edit;
