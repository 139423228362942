import { Button, Col, Row, Spin, message } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { COLORS } from "../../../constants";
import { STRINGS } from "../../../constants/Strings";
import { ModalCustomService } from "../../../elements/ModalCustom/ModalCustomService";
// import ModalRestTime from "./ModalRestTime";
import StaffConfigModal from "./StaffConfigModal/StaffConfigModal";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util";
import { useGetDataHeader } from "../modules/useGetDataHeader";

function ResourceHeaderTemplate(props) {
  const { dataStaff, refetchCalendarOrder } = props.resourceData;
  const [shiftStatus, setShiftStatus] = useState(dataStaff?.shiftStatus);
  const { isFetchingHeader, dataFiltered } = useGetDataHeader();

  const onGoDetailStaffSchedule = () => {
    let dataStaffs = props.calendarOrder?.staffOrderDTOS?.filter(
      (staff) => staff.shiftStatus === "working"
    );
    let lastTime = dataStaffs[0]?.workShiftDTOS[0]?.endTime;

    for (let time of dataStaffs) {
      if (moment(time.workShiftDTOS[0]?.endTime).isAfter(moment(lastTime))) {
        lastTime = time.workShiftDTOS[0]?.endTime;
      }
    }
    ModalCustomService.set({
      wrapperStyle: { width: "auto", maxWidth: 1200 },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <StaffConfigModal
          data={dataStaff}
          refetchCalendarOrder={refetchCalendarOrder}
          setShiftStatus={setShiftStatus}
          shiftStatus={shiftStatus}
          selectedDate={props.selectedDate}
          lastTime={lastTime}
        />
      ),
    });
  };
  const onCheckIn = async () => {
    if (shiftStatus === "waiting_checkin") {
      // ModalCustomService.set({
      //   title:
      //     STRINGS.if_you_press_the_attendance_button_you_will_not_be_able_to_change_it,
      //   wrapperStyle: { width: "auto" },
      //   contentWrapperStyle: { width: "fit-content" },
      //   contentStyle: { maxHeight: "80vh", padding: 0 },
      //   content: (
      //     <ModalRestTime
      //       staffInfo={dataStaff}
      //       refetchCalendarOrder={refetchCalendarOrder}
      //     />
      //   ),
      // });

      try {
        const result = await FetchApi.timekeepingStaff({
          restMinute: dataStaff.restMinute || 30,
          type: "check_in",
          staffId: dataStaff.staffId,
        });

        if (result.success) {
          refetchCalendarOrder();
          ModalCustomService.close();
          message.success(STRINGS.checkin_success);
        } else if (result.code == 4101 || result.code == 4102) {
          message.warning(result.message);
        } else if (result.code == 4004) {
          message.error(STRINGS.cannot_checkin_because_error);
        } else {
          CommonFunction.logError(result);
        }
      } catch (error) {
        message.error(STRINGS.something_wrong);
      }

      return;
    }
    onGoDetailStaffSchedule();
  };

  const getPerformance = () => {
    if (isFetchingHeader) return <Spin size="small" />;
    if (!isFetchingHeader && dataFiltered) {
      const data = dataFiltered.find(
        (item) => item.staffId === dataStaff.staffId
      );
      if (data?.performance) return `(${data.performance})`;
      else return "";
    }
    return "";
  };
  return (
    <Row
      style={{
        padding: 0,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Col span={14}>
        <Col
          className="number-of-lines-2"
          onClick={onGoDetailStaffSchedule}
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            fontSize: 16,
            color: COLORS.sub_accent,
          }}
        >
          {dataStaff.staffName}
        </Col>
        <Row
          className="working-time"
          style={{ maxHeight: 60, overflow: "scroll" }}
        >
          {(dataStaff?.workShiftDTOS || []).map((item, index) => {
            return (
              <Col
                key={`${index}`}
                style={{
                  marginRight: 6,
                  fontWeight: "bold",
                  fontSize: 17,
                  color: COLORS.sub_accent,
                  whiteSpace: "pre-line",
                }}
              >
                {moment(item.startTime).format("HH")}～
                {moment(item.endTime).format("HH")}
              </Col>
            );
          })}
        </Row>
      </Col>
      <Col
        span={10}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Row>
          {shiftStatus === "waiting_checkin" && (
            <Button //checkin
              onClick={onCheckIn}
              className="btn-resourceHeaderTemplate"
              style={{
                padding: "4px 16px",
                borderRadius: 6,
                backgroundColor: "#FF8000",
                border: `1px solid #FF8000`,
                fontSize: 16,
                margin: "0 auto",
              }}
            >
              {STRINGS["checkin"]}
            </Button>
          )}

          {shiftStatus === "working" && (
            <Button //checkout
              onClick={onCheckIn}
              className="btn-resourceHeaderTemplate"
              style={{
                padding: "4px 16px",
                borderRadius: 6,
                backgroundColor: COLORS.secondary,
                border: `1px solid ${COLORS.secondary}}`,
                fontSize: 16,
                margin: "0 auto",
              }}
            >
              {STRINGS["checkout"]}
            </Button>
          )}

          {shiftStatus === "unavailable" && (
            <Button //unavailable
              className="btn-resourceHeaderTemplate"
              style={{
                padding: "4px 16px",
                borderRadius: 6,
                backgroundColor: "grey",
                border: `1px solid grey`,
                fontSize: 16,
                margin: "0 auto",
              }}
            >
              {STRINGS["done"]}
            </Button>
          )}
        </Row>

        <Row>
          <div
            style={{
              color: COLORS.sub_accent,
              fontWeight: "500",
              textAlign: "center",
              fontSize: 15,
              minHeight: 27,
            }}
          >
            {getPerformance()}
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export default ResourceHeaderTemplate;
