import React, { useRef, useState, memo } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Table, Space, message, Row } from "antd";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

import {
  AlertService,
  ButtonCustom,
  LoadingView,
  SvgView,
} from "../../elements";
import { FetchApi } from "../../api";
import { STRINGS, LOGIC, Svgs } from "../../constants";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import NewsForm from "./components/NewsForm";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";

import "./HomePage.scss";

const HomagePage = memo(() => {
  const cache = useQueryClient();
  const formRef = useRef();
  const page = useRef(1);
  const size = useRef(10);
  const [newsEditing, setNewsEditing] = useState();
  const columns = [
    {
      title: STRINGS.information_image,
      dataIndex: "imageUrl",
      key: "imageUrl",
      width: 280,
      render: (url) => (
        <div className="img-wrapper">
          <img
            style={{ width: 260 }}
            className="img-detail"
            src={
              url ||
              "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/5f75afb3-6220-413b-9823-56461b42b3873d7b3552-e660-43b2-8e35-6eec209c8543e5f33cf1-c54d-44d9-905a-f42ae506c3184088a280-f631-43c9-ad4c-58dd843cfd75.png"
            }
            alt="img-detail"
          />
        </div>
      ),
    },
    {
      title: STRINGS.title,
      dataIndex: "title",
      key: "title",
      width: "20%",
    },
    {
      title: STRINGS.short_description,
      dataIndex: "description",
      key: "description",
      width: "30%",
    },
    {
      title: STRINGS.start_date,
      key: "time",
      dataIndex: "time",
      render: (createdTime) => moment(createdTime).format(LOGIC.timeFormat),
    },
    {
      key: "action",
      render: (text, record) => {
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onClickEdit(record)}>
              <SvgView svgData={Svgs.edit} />
            </button>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={deleteNews(record)}>
              <SvgView svgData={Svgs.delete} />
            </button>
          </Space>
        );
      },
    },
  ];

  const { data, refetch, status } = useQuery(["all-news"], () => {
    return FetchApi.getAllNews(page.current, size.current);
  });

  useScrollByMouse(data);

  const deleteNews = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${item.title}${STRINGS.confirm_delete_news}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteNews(item.id);
        if (result.success) {
          cache.refetchQueries("all-news");
          message.success(STRINGS.delete_success);
        } else {
          message.warning(result.message || STRINGS.delete_failed);
        }
      },
    });
  };

  const onClickEdit = (item) => () => {
    setNewsEditing({ ...item });
  };

  const onCreateNews = () => {
    setNewsEditing({});
  };

  const disableModal = () => {
    setNewsEditing(null);
  };

  const onConfirm = (item) => {
    cache.refetchQueries("all-news");
    disableModal();
  };

  // const renderSlide = useMemo(() => {
  //   if (!newsEditing) {
  //     return <Slide />;
  //   }
  //   return null;
  // });

  return (
    <div className="manager-home-page">
      <div className="content-home-page">
        <div className="setting-news">
          <Row className="title-news">
            <div className="title-name">{STRINGS.config_news}</div>
            <ButtonCustom onClick={onCreateNews} icon={<PlusCircleOutlined />}>
              {STRINGS.add_new}
            </ButtonCustom>
          </Row>
          <div className="list-news">
            <div className="table-news">
              {status === "loading" ? (
                <LoadingView />
              ) : (
                <>
                  <Table
                    rowKey={"id"}
                    columns={columns}
                    dataSource={data ? data.data : []}
                    locale={{ emptyText: STRINGS.no_data }}
                    pagination={false}
                    scroll={{ x: "auto", y: "calc(100vh - 320px)" }}
                  />
                  <PaginationWithSizeChange
                    size={size.current}
                    total={data ? data.total : 0}
                    onChange={(pageNumber) => {
                      page.current = pageNumber;
                      refetch();
                    }}
                    onShowSizeChange={(e) => {
                      size.current = Number(e.size);
                      page.current = 1;
                      refetch();
                    }}
                    current={page.current}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {!!newsEditing && (
        <NewsForm
          ref={formRef}
          data={newsEditing}
          onConfirm={onConfirm}
          disableModal={disableModal}
        />
      )}
    </div>
  );
});

export default HomagePage;
