import { useEffect } from "react";
import moment from "moment";
import { useQuery } from "react-query";

import { FetchApi } from "../../api";
import Auth from "../../store/Authentication";
import { AccountService } from "../../services/AccountService";
import { staffScheduleKey } from "../../components/staff-schedule/modules/staffScheduleKey";

const useGetCalendarOrders = (selectedDate) => {
  const checkCouponIsChanged = false;
  let lastTimeCallApi = new Date().getTime();
  const value = useQuery({
    queryFn: () =>
      FetchApi.getCalendarOrderForAdmin(
        selectedDate,
        checkCouponIsChanged,
        lastTimeCallApi,
      ),
    queryKey: [
      staffScheduleKey.paidBooking,
      staffScheduleKey.useGetCalendarOrders,
      selectedDate,
    ]
  });
  const { data, isLoading, refetch, isFetching } = value;

  useEffect(() => {
    if (data?.closeTimeStore && data?.openTimeStore) {
      const userAdminClone = AccountService.get();

      if (userAdminClone?.storeDTO?.id) {
        userAdminClone.storeDTO.openTime = moment(data.openTimeStore).format(
          "HH:mm"
        );
        userAdminClone.storeDTO.closeTime = moment(data.closeTimeStore).format(
          "HH:mm"
        );

        Auth.setCurrentUser(userAdminClone);
      }
    }
  }, [data]);
  return {
    ...value,
    calendarOrder: data,
    refetchCalendarOrder: refetch,
    isLoadingCalendarOrder: isLoading,
    isFetchingCalendarOrder: isFetching,
    getKey: `useGetCalendarOrders-${selectedDate}`,
  };
};

const useGetCalendarOrdersManual = (selectedDate) => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    [`useGetCalendarOrdersManual-${selectedDate}`],
    () =>
      FetchApi.bookingHistoryManualTimeline(
        moment(selectedDate).format("YYYY-MM-DD 00:00:00")
      )
  );
  useEffect(() => {
    if (data?.closeTimeStore && data?.openTimeStore) {
      const userAdminClone = AccountService.get();

      if (userAdminClone?.storeDTO?.id) {
        userAdminClone.storeDTO.openTime = moment(data.openTimeStore).format(
          "HH:mm"
        );
        userAdminClone.storeDTO.closeTime = moment(data.closeTimeStore).format(
          "HH:mm"
        );

        Auth.setCurrentUser(userAdminClone);
      }
    }
  }, [data]);
  return {
    calendarOrder: data,
    refetchCalendarOrder: refetch,
    isLoadingCalendarOrder: isLoading,
    isFetchingCalendarOrder: isFetching,
    getKey: `useGetCalendarOrdersManual`,
  };
};

const useGetNotiSound = () => {
  const value = useQuery(["getNotiSound"], FetchApi.getNotiSound);
  return { ...value, notiSound: value.data?.notiSound?.notiSoundOneNewBooking };
};

export { useGetCalendarOrders, useGetCalendarOrdersManual, useGetNotiSound };
