const changeObject = {};
let data = "";

const ScheduleBookingService = {
  get: () => data,
  getObj: () => changeObject,
  set: async (newData) => {
    data = { ...newData };
    Object.keys(changeObject).forEach((k) => changeObject[k]());
  },
  remove: () => {
    data = "";
    Object.keys(changeObject).forEach((k) => changeObject[k]());
  },
  onChange: (key, cb) => {
    changeObject[key] = () => cb(data);
  },

  deleteKey: (key) => {
    if (changeObject[key]) {
      delete changeObject[key];
    }
  },
};

export { ScheduleBookingService };
