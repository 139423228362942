import React from "react";
import { Row, Select } from "antd";

import { COLORS, STRINGS } from "../../../constants";

function DropdownCourse({
  comboDTOS,
  value,
  onChange,
  disabled,
  combosPicked,
  dataRender,
}) {
  const clone = [...combosPicked];
  return (
    <Select
      key={`${value}`}
      disabled={disabled}
      defaultValue={value}
      onChange={onChange}
      allowClear
      style={{ border: `1px solid ${COLORS.sub_text}`, borderRadius: 5 }}>
      {comboDTOS.map((item) => {
        const disabledItem = clone.findIndex((i) => i?.id === item.id) > -1;
        let title = `${item.comboName}
        ${"\xa0"} ${item.minutes}
        ${STRINGS.minute}`;
        if (dataRender && !!item[dataRender]) {
          title = item[dataRender] + `${STRINGS.minute}`;
        }
        return (
          <Select.Option
            key={`${item.id}`}
            disabled={disabledItem}
            value={item.id}
            label={item.id}>
            <Row
              align="center"
              style={{
                fontWeight: "bold",
                color: COLORS.sub_accent,
                fontSize: 15,
              }}>
              {title}
            </Row>
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default DropdownCourse;
