import React, { useRef, useState, useEffect, memo } from "react";
import { Table, Space, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

import {
  AlertService,
  ButtonCustom,
  LoadingView,
  SvgView,
} from "../../elements";
import { FetchApi } from "../../api";
import { STRINGS, LOGIC, Svgs } from "../../constants";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";
import NotificationStaffForm from "./items/NotificationStaffForm";

import "./NotificationStaff.scss";

const NotificationStaff = memo(() => {
  const cache = useQueryClient();
  const page = useRef(1);
  const size = useRef(10);
  const [notiEditing, setNotiEditing] = useState();
  const [listStore, setListStore] = useState([]);

  const columns = [
    {
      title: STRINGS.notification_image,
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (url) => (
        <div className="img-wrapper">
          <img
            style={{ width: 260 }}
            className="img-detail"
            src={
              url ||
              "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/5f75afb3-6220-413b-9823-56461b42b3873d7b3552-e660-43b2-8e35-6eec209c8543e5f33cf1-c54d-44d9-905a-f42ae506c3184088a280-f631-43c9-ad4c-58dd843cfd75.png"
            }
            alt="img-detail"
          />
        </div>
      ),
      width: 280,
    },
    {
      title: STRINGS.title,
      dataIndex: "title",
      key: "title",
      width: 200,
    },
    {
      title: STRINGS.short_description,
      dataIndex: "description",
      key: "description",
      width: 400,
    },
    {
      title: STRINGS.start_date,
      key: "time",
      dataIndex: "time",
      render: (createdTime) =>
        createdTime ? (
          <div>{moment(createdTime).utc(true).format(LOGIC.timeFormat)}</div>
        ) : (
          ""
        ),
      width: 200,
    },
    {
      title: STRINGS.received_people,
      dataIndex: "staffs",
      key: "staffs",
      render: (staffs, row) => {
        if (row.all) {
          return STRINGS.all;
        } else if (Array.isArray(staffs) && staffs.length > 0) {
          return staffs.map(
            (item, index) =>
              (item ? item.name : "") +
              (index !== staffs.length - 1 ? ", " : "")
          );
        }
        let storePush = listStore.find((item) => item.id == row.storeId)
          ? `${STRINGS.store_receive_notifications}: ` +
            listStore.find((item) => item.id == row.storeId).name
          : "";
        return row.store
          ? `${STRINGS.store_receive_notifications}: ` + row.store.name
          : storePush;
      },
      width: 300,
    },
    {
      key: "action",
      width: 100,
      fixed: "right",
      render: (text, record) => {
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onClickEdit(record)}>
              <SvgView svgData={Svgs.edit} />
            </button>
            {record.all ? null : (
              <button
                style={{
                  border: 0,
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
                onClick={removeNoti(record)}>
                <SvgView svgData={Svgs.delete} />
              </button>
            )}
          </Space>
        );
      },
    },
  ];

  const { data, refetch, status } = useQuery(
    ["all-notifications-staff"],
    () => {
      return FetchApi.getListNotificationStaff(page.current, size.current);
    }
  );
  useScrollByMouse(data);

  useEffect(() => {
    const fetchStore = async () => {
      let result = await FetchApi.getAllStoreFilter(1, 50);
      if (result.total) {
        setListStore(result.data);
      }
    };
    fetchStore();
  }, []);

  const removeNoti = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${item.title}${STRINGS.confirm_delete_notification}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteNotificationStaff(item.id);
        if (result.success) {
          cache.refetchQueries("all-notifications-staff");

          message.success(STRINGS.delete_success);
        } else {
          message.warning(result.message || STRINGS.delete_failed);
        }
      },
    });
  };

  const onClickEdit = (item) => () => {
    setNotiEditing({ ...item });
  };

  const onCreateNoti = () => {
    setNotiEditing({});
  };

  const disableModal = () => {
    setNotiEditing(null);
  };

  const onConfirm = (item) => {
    cache.refetchQueries("all-notifications-staff");
    disableModal();
  };

  return (
    <div className="manager-notification">
      <div className="header-notification">
        <div className="title">{STRINGS.push_to_staff}</div>
        <div className="button-add-noti">
          <ButtonCustom onClick={onCreateNoti} icon={<PlusCircleOutlined />}>
            {STRINGS.add_new}
          </ButtonCustom>
        </div>
      </div>
      <div className="content-user">
        {status === "loading" ? (
          <LoadingView />
        ) : (
          <>
            <Table
              scroll={{ x: 1500, y: "calc(100vh - 320px)" }}
              rowKey={"id"}
              columns={columns}
              dataSource={data ? data.data : []}
              locale={{ emptyText: STRINGS.no_data }}
              pagination={false}
            />
            <PaginationWithSizeChange
              size={size.current}
              total={data ? data.total : 0}
              onChange={(pageNumber) => {
                page.current = pageNumber;
                refetch();
              }}
              onShowSizeChange={(e) => {
                size.current = Number(e.size);
                page.current = 1;
                refetch();
              }}
              current={page.current}
            />
          </>
        )}
      </div>
      {!!notiEditing && (
        <NotificationStaffForm
          data={notiEditing}
          onConfirm={onConfirm}
          listStore={listStore}
          disableModal={disableModal}
        />
      )}
    </div>
  );
});

export default NotificationStaff;
