import { useQuery } from "react-query";

import Auth from "../../../store/Authentication";
import { FetchApi } from "../../../api";

export function useCheckStoreHaveBooking() {
  const accessToken = Auth.getAccessToken();
  const value = useQuery([`checkStoreHaveBooking-${accessToken}`], () =>
    FetchApi.checkStoreHaveBooking()
  );

  return {
    ...value,
    isBookingEnable: value?.data?.storeDTO?.booking,
    isEnableViewRevenue: value?.data?.storeDTO?.enableViewRevenue,
    numberOfExtraRestTimeMinutes:
      value?.data?.storeDTO?.numberOfExtraRestTimeMinutes,
  };
}
