import { useQuery } from "react-query";
import moment from "moment";

import { FetchApi } from "../../../api";
import Auth from "../../../store/Authentication";
import { Convert } from "../../../elements";
import { useEffect, useState } from "react";
import { staffScheduleKey } from "./staffScheduleKey";
// get detail booking order
export function useGetDetailCourseBooking(id) {
  const { data, isLoading, refetch } = useQuery({
    queryFn: () => FetchApi.getDetailCourseBooking({ id: id }),
    queryKey: [staffScheduleKey.paidBooking,staffScheduleKey.getDetailCourseBooking, id],
  });
  let detailCourseBooking = [{}];
  if (data?.calendarOrder?.comboOrders?.length > 0) {
    detailCourseBooking = data?.calendarOrder?.comboOrders?.sort(
      (a, b) => a.categoryPosition - b.categoryPosition
    );
  }
  return {
    detailCourseBooking: detailCourseBooking.map((i) => {
      return {
        ...i,
        id: i?.comboPackId,
        comboExtraId: i?.prolongedCourseId,
        priceExtra: i?.prolongedPackDTO?.price,
        minuteExtra: i?.prolongedPackDTO?.minutes,
        price: i.price + (i?.prolongedPackDTO?.price || 0),
        tax: i?.packTax,
      };
    }),
    dataBooking: data?.calendarOrder,
    isLoading,
    refetch,
  };
}

export function checkAddtionalFee({
  data,
  storeSurcharge,
  comboOrders,
  dataBooking,
}) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const storeDTO = userAdmin.storeDTO;

  let lateBookingFee;
  let addtionalManFee;
  const splitStart = storeDTO.openTime?.split?.(":");
  // const splitClose = storeDTO.closeTime?.split?.(":");
  const startHour = Number(splitStart[0]);
  // const endHour = Number(splitClose[0]);
  const hourBooking = moment(data?.startTime).hour();
  const minuteBooking = moment(data?.startTime).minute();

  if ((hourBooking >= 23 && minuteBooking >= 10) || hourBooking < startHour) {
    lateBookingFee = storeSurcharge?.lateFee;
  }

  const arrComboSelectedList = comboOrders?.map((i) => i.comboId) || [];
  const objConvert = {};
  arrComboSelectedList.filter((i) => !!i).map((i) => (objConvert[i] = i));

  const courseAdditionalFee = Convert.utilData(
    storeSurcharge?.courseDTOs,
    "id"
  );
  const newArr = courseAdditionalFee.filter?.((i) => objConvert[i]);
  if (newArr?.length > 0 && data?.customerGender === "male") {
    addtionalManFee = newArr.length * storeSurcharge?.surchargeForMen;
  }
  data.lateBookingFee = lateBookingFee;
  data.addtionalManFee = addtionalManFee;
  data.designationFee = dataBooking?.designationFee;
  return { lateBookingFee, addtionalManFee };
}

export function useGetCouponBooking({ birthday, listId, userId, bookingId }) {
  const value = useQuery("couponAll", () =>
    FetchApi.getCouponBooking(birthday, userId, bookingId)
  );
  const listIdConvert = listId?.map?.((i) => i.comboId) || [];
  return {
    ...value,
    listCoupon: value.data?.couponDTOList?.filter?.((i) =>
      i.courseIds?.some((r) => listIdConvert.indexOf(r) >= 0)
    ),
  };
}

export function useCalStaffCheckoutTime({
  workShiftDTOS = [],
  lastTime,
  staffId,
}) {
  function storeAboutToClose() {
    const userAdmin = JSON.parse(Auth.getCurrentUser());
    let closeTimeStr = userAdmin.storeDTO.closeTime;
    if (closeTimeStr === "00:00") closeTimeStr = "24:00";

    let closeTime = new Date();

    let [hours, minutes] = closeTimeStr.split(":");
    if (hours < 5 && moment().get("hours") > 5) {
      hours = +hours + 24;
    }

    closeTime.setHours(+hours);
    closeTime.setMinutes(+minutes);

    return moment().add(60, "minutes").isAfter(moment(closeTime));
  }

  const [disableButtonCheckout, setDisableButtonCheckout] = useState(() => {
    let lessThan20MinsToLeave = moment()
      .add(20, "minutes")
      .isAfter(moment(workShiftDTOS[0]?.endTime));
    let checkLastTime = workShiftDTOS[0]?.endTime === lastTime;

    for (let i = 0; i < workShiftDTOS.length; i++) {
      if (
        i === 0 &&
        checkLastTime &&
        !lessThan20MinsToLeave &&
        !storeAboutToClose()
      ) {
        return true; // disable checkout button
      }
      if (i === 0 && !checkLastTime && !lessThan20MinsToLeave) {
        return true;
      }
      if (
        moment()
          .add(20, "minutes")
          .isBefore(moment(workShiftDTOS[i]?.endTime)) &&
        moment().isAfter(workShiftDTOS[i - 1]?.endTime)
      ) {
        return true;
      }
      return false;
    }
  });

  useEffect(() => {
    const checkInterval = setInterval(() => {
      let lessThan20MinsToLeave = moment()
        .add(20, "minutes")
        .isAfter(moment(workShiftDTOS[0]?.endTime));
      let checkLastTime = workShiftDTOS[0]?.endTime === lastTime;
      for (let i = 0; i < workShiftDTOS.length; i++) {
        if (
          i === 0 &&
          checkLastTime &&
          !lessThan20MinsToLeave &&
          !storeAboutToClose()
        ) {
          setDisableButtonCheckout(true);
          return;
        }
        if (i === 0 && !checkLastTime && !lessThan20MinsToLeave) {
          setDisableButtonCheckout(true);
          return;
        }
        if (
          i > 0 &&
          moment()
            .add(20, "minutes")
            .isBefore(moment(workShiftDTOS[i]?.endTime)) &&
          moment().isAfter(workShiftDTOS[i - 1]?.endTime)
        ) {
          setDisableButtonCheckout(true);
          return;
        }
        setDisableButtonCheckout(false);
      }
    }, 6000);

    return () => {
      checkInterval && clearInterval(checkInterval);
    };
  }, []);
  return disableButtonCheckout;
}
