import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import moment from "moment";

import { STRINGS } from "../../../constants/Strings";
import { ModalCustomService } from "../../../elements/ModalCustom/ModalCustomService";
import ModalRequestAbsent from "./ModalRequestAbsent";
import ModalChangeWorkingTime from "./ModalChangeWorkingTime";
import { COLORS } from "../../../constants";
import StaffConfigModal from "../../staff-schedule/items/StaffConfigModal/StaffConfigModal";

function ResourceHeaderTemplate(props) {
  const { staffName, workShiftDTOS, dataStaff, refetchCalendarOrder } =
    props.resourceData;
  const [shiftStatus, setShiftStatus] = useState(dataStaff?.shiftStatus);

  const shiftColor = {
    早: "#FFE1CD",
    中: "#DCE4D4",
    遅: "#CDDDFE",
  };
  const onGoDetailStaffSchedule = () => {
    ModalCustomService.set({
      wrapperStyle: { width: "auto", maxWidth: 1200 },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <StaffConfigModal
          data={dataStaff}
          refetchCalendarOrder={refetchCalendarOrder}
          setShiftStatus={setShiftStatus}
          shiftStatus={shiftStatus}
          selectedDate={props.selectedDate}
          disabled={moment(props.selectedDate).isBefore(
            moment().subtract(1, "day")
          )}
        />
      ),
    });
  };
  const onAbleModalRequestAbsent = () => {
    ModalCustomService.set({
      title: staffName,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <ModalRequestAbsent
          staffInfo={props.resourceData}
          workingDay={props.selectedDate}
          refetchCalendarOrder={props.refetchCalendarOrder}
        />
      ),
    });
  };

  const onAbleModalChangeWorkingTime = () => {
    ModalCustomService.set({
      title: staffName,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <ModalChangeWorkingTime
          staffInfo={props.resourceData}
          refetchCalendarOrder={props.refetchCalendarOrder}
          workingDay={props.selectedDate}
        />
      ),
    });
  };
  const isDisabled = moment(props.selectedDate).isBefore(
    moment().subtract(1, "day")
  );
  let backgroundColorButtonHoliday = "#497D37";
  let backgroundColorButtonTimeChange = "#FF8000";
  if (isDisabled || shiftStatus !== "waiting_checkin") {
    backgroundColorButtonHoliday = COLORS.disabled;
    backgroundColorButtonTimeChange = COLORS.disabled;
  }
  return (
    <Col style={{ padding: "0px 0px" }}>
      <Row
        justify="space-between"
        style={{ alignItems: "center", marginBottom: 4 }}>
        <Col
          span={11}
          onClick={onGoDetailStaffSchedule}
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color: COLORS.accent,
            whiteSpace: "pre-line",
            overflowWrap: "break-word",
          }}>
          {staffName}
        </Col>
        <Col span={13} className="working-time">
          {workShiftDTOS.map((item, index) => {
            return (
              <Row
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                key={`${index}`}>
                <Col
                  style={{
                    fontWeight: "bold",
                    fontSize: 15,
                    color: COLORS.sub_accent,
                    whiteSpace: "pre-line",
                  }}>
                  {moment(item.startTime).format("HH")}～
                  {moment(item.endTime).format("HH")}
                </Col>
                <Button
                  key={`${index}`}
                  aria-activedescendant="none"
                  className="btn-resourceHeaderTemplate-text-black"
                  style={{
                    marginRight: 12,
                    backgroundColor: shiftColor[item.session],
                    borderColor: shiftColor[item.session],
                  }}>
                  {item.session}
                </Button>
              </Row>
            );
          })}
        </Col>
      </Row>

      <Row>
        <Button
          disabled={isDisabled || shiftStatus !== "waiting_checkin"}
          onClick={onAbleModalRequestAbsent}
          className="btn-resourceHeaderTemplate"
          style={{
            width: "43%",
            backgroundColor: backgroundColorButtonHoliday,
          }}>
          {STRINGS.holiday}
        </Button>
        <Button
          disabled={isDisabled || shiftStatus !== "waiting_checkin"}
          onClick={onAbleModalChangeWorkingTime}
          className="btn-resourceHeaderTemplate"
          style={{
            width: "43%",
            backgroundColor: backgroundColorButtonTimeChange,
          }}>
          {STRINGS.time_change}
        </Button>
      </Row>
    </Col>
  );
}

export default ResourceHeaderTemplate;
