/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";

import { FetchApi } from "../api";
import { COLORS, STRINGS } from "../constants";
import Auth from "../store/Authentication";

const { Option } = Select;

const FilterByStore = ({
  style,
  storeId,
  title,
  listStore,
  onSearchByStore,
  className,
}) => {
  const [stores, setStores] = useState(listStore || []);
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  useEffect(() => {
    if (stores.length > 0) {
      return;
    }
    const fetchStore = async () => {
      let result = await FetchApi.getAllStoreFilter(1, 50);
      if (result.total) {
        setStores([{ name: STRINGS.all, id: null }, ...result.data]);
      }
    };
    fetchStore();
  }, []);

  const onSelect = (e) => {
    onSearchByStore && onSearchByStore(e);
  };

  const sortPosition = (a, b) => {
    if (a.position < b.position) {
      return 1;
    }
    if (a.position > b.position) {
      return -1;
    }
    return 0;
  };

  if (userAdmin && userAdmin.type !== "SYSTEM_ADMIN") {
    return null;
  }

  const storesSorted = stores.sort(sortPosition);

  return (
    <div style={{ float: "left", marginRight: 10 }}>
      <div>{title || STRINGS.search_by_store}</div>
      <Select
        className={className}
        defaultValue={STRINGS.all}
        onSelect={onSelect}
        style={{ width: 300, ...style }}
        suffixIcon={<CaretDownOutlined style={{ color: COLORS.border_6 }} />}>
        {storesSorted.map((itm) => (
          <Option key={"" + itm.id}>{itm.name}</Option>
        ))}
      </Select>
    </div>
  );
};

export { FilterByStore };
