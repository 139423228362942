const key = "menuCollapsed";
const keyStaffManualBooking = "staffManualBooking";

function useMenuCollapsed() {
  const collapsedLocal = localStorage.getItem(key);
  const setCollapsedLocal = (status) =>
    localStorage.setItem(key, status.toString());

  return { collapsedLocal, setCollapsedLocal };
}

function useStaffManualBooking() {
  let staffInfoLocal;
  try {
    staffInfoLocal = JSON.parse(localStorage.getItem(keyStaffManualBooking));
  } catch (error) {}

  const setStaffInfoLocal = (staffInfo) =>
    localStorage.setItem(keyStaffManualBooking, JSON.stringify(staffInfo));

  return { staffInfoLocal, setStaffInfoLocal };
}

export { useMenuCollapsed, useStaffManualBooking };
