import React from "react";
import { Row, Select, Form } from "antd";

import { COLORS, STRINGS } from "../../../constants";
import { SuffixIconDown } from "../../../elements";
import { checkCouponSpecial } from "../modules/useGetPackStore";

export function BookingManualDetailCoupon({
  data,
  listCoupon,
  value = [],
  onChange,
  setTotal,
}) {
  const form = Form.useFormInstance();
  const arrListCoupon = listCoupon?.couponDTOList || [];
  return (
    <Row className={"coupon-booking"}>
      <Select
        style={{
          textAlign: "left",
          width: "90%",
          whiteSpace: "pre-line",
          border: `1px solid ${COLORS.sub_text}`,
          borderRadius: 5,
          color: COLORS.sub_accent,
          fontWeight: "900",
        }}
        allowClear
        labelInValue
        mode="multiple"
        disabled={(data.user?.customerType || data.user?.type) === "P"}
        suffixIcon={<SuffixIconDown />}
        defaultValue={value?.map?.((i) => ({
          label: i?.label,
          value: i?.id,
        }))}
        onChange={(val, otherProps) => {
          onChange(otherProps);

          let totalDiscount = 0;
          otherProps.forEach((e) => {
            totalDiscount = totalDiscount + (e?.discount || 0);
          });
          const checkIsCouponSpecial = checkCouponSpecial({
            arrCoupon: otherProps,
          });
          const { comboDTOS, numberOfPointsUsed, assigned } =
            form.getFieldValue();
          let totalPrice = 0;
          if (checkIsCouponSpecial) {
            comboDTOS?.forEach?.((i) => {
              if (i?.priceForCurrentUser) {
                totalPrice += i.priceForCurrentUser - (i.tax || 0);
              }
            });
          } else {
            comboDTOS?.forEach?.((i) => {
              if (i?.priceForCurrentUser) {
                totalPrice += i.priceForCurrentUser;
              }
            });
          }
          let designationFeeBooking = 0;
          if (assigned) {
            designationFeeBooking = data.designationFee;
          }
          setTotal(
            totalPrice -
              totalDiscount -
              (numberOfPointsUsed || 0) +
              (designationFeeBooking || 0)
          );
        }}>
        {arrListCoupon.map((item) => {
          return (
            <Select.Option
              key={item.id}
              value={item.id}
              style={{
                height: 60,
                color: COLORS.sub_accent,
                fontWeight: "bold",
              }}
              discount={item.discount}
              type={item.type}>
              <Row style={{ flexWrap: "wrap", whiteSpace: "pre-line" }}>
                {item.title}
                {"\n"}-{item.discount}
                {STRINGS.yen}
              </Row>
            </Select.Option>
          );
        })}
      </Select>
    </Row>
  );
}
