/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, message, Row, Select } from "antd";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

import { COLORS, LOGIC, STRINGS } from "../../../constants";
import {
  ButtonCustom,
  // Convert,
  ModalCustomService,
  SuffixIconDown,
} from "../../../elements";
import { TimePickerAutoAccept } from "../../../elements/TimePickerAutoAccept";
import { ScheduleBookingService } from "../../../services";
import { FetchApi } from "../../../api";
import ModalChangeStaffBooking from "../../modal-change-staff-booking/ModalChangeStaffBooking";
import ModalBookingDetail from "../items/ModalBookingDetail";
import Auth from "../../../store/Authentication";

import "../StaffSchedule.scss";
import { useGetCouponBooking } from "../modules";

function ModalBookingDetailInfoCustomer({
  label,
  value,
  valueAssign,
  styleValue,
  styleWrapper,
  data,
  setTotal,
  form,
  isCheckInBooking,
  statusBooking,
  disabled,
  refetch,
  onChangeVlaue,
  bookingPointManualId,
}) {
  const { listCoupon, refetch: refetchCoupon } = useGetCouponBooking({
    birthday: data.birthday,
    listId: form.getFieldValue("comboDTOS"),
    userId: data.userId,
    bookingId: data.id,
  });
  const newStartTime = useRef(moment(data.startTime));
  const cache = useQueryClient();
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  // const infoStore = userAdmin.storeDTO;
  const [, setCouponSelected] = useState();
  const [currentValue, setCurrentValue] = useState();
  // const openTime = moment(
  //   moment().format("MM/DD/YYYY") + `${infoStore.openTime}`,
  //   "MM/DD/YYYY HH:mm"
  // );

  // const closeTime = Convert.convertTime(
  //   infoStore.openTime,
  //   infoStore.closeTime
  // );
  const { data: listProvidePointManual } = useQuery(
    [`getProvidePointManual`],
    () => {
      return FetchApi.getProvidePointManual(Date.parse(data.startTime));
    }
  );

  useEffect(() => {
    const modifiedCourse = form.getFieldValue("modifiedCourse");
    if (listCoupon?.length === 0 && modifiedCourse) {
      form.setFieldsValue({ couponIds: [] });
      const { comboDTOS } = form.getFieldsValue("comboDTOS");

      let totalPrice = 0;

      comboDTOS?.forEach?.((i) => {
        if (i?.price) {
          totalPrice += i.price || 0;
        }
      });

      setTotal(
        totalPrice -
          (data.pointUse || 0) +
          (data.lateBookingFee || 0) +
          (data.addtionalManFee || 0) +
          (data.designationFee || 0)
      );
    }
    if (listCoupon?.length > 0) {
      form.setFieldValue("listCoupon", listCoupon);
    }
  }, [listCoupon]);

  const setBookingPointManualValue = useCallback(
    (id) => {
      const point = listProvidePointManual?.bookingPoints?.find((item) => {
        return item?.id === id;
      });
      if (point) {
        setCurrentValue(`${point?.title} (${point?.quantity}Pt)`);
      }
    },
    [listProvidePointManual?.bookingPoints]
  );

  useEffect(() => {
    setBookingPointManualValue(bookingPointManualId);
  }, [bookingPointManualId, setBookingPointManualValue]);

  const onAfterCloseModal = () => {
    const { bookingDetail, refetchCalendarOrder } =
      ScheduleBookingService.get();
    ModalCustomService.set({
      title: STRINGS.detail_reservation,
      titleStyle: {
        fontSize: 25,
      },
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },

      content: (
        <ModalBookingDetail
          refetch={refetchCalendarOrder}
          data={bookingDetail}
        />
      ),
    });
  };

  const onShowChangeStaff = () => {
    ModalCustomService.set({
      title: STRINGS.change_staff,
      titleStyle: { fontSize: 25 },
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      onAfterClose: onAfterCloseModal,
      content: <ModalChangeStaffBooking dataBooking={data} refetch={refetch} />,
    });
  };
  // let valueTitle = "";
  // if (coupon?.title) {
  //   if (coupon.type === LOGIC.coupon_time.tax) {
  //     valueTitle = `${coupon?.title}\n-10%`;
  //   } else {
  //     valueTitle = `${coupon?.title}\n-${coupon?.discount}${STRINGS.yen}`;
  //   }
  // }
  const renderValue = () => {
    if (label === STRINGS.date_time_book) {
      return (
        <Row
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "55%",
          }}
        >
          <Col
            span={18}
            style={{
              fontWeight: "bold",
              color: COLORS.sub_accent,
              fontSize: 22,
            }}
          >
            <TimePickerAutoAccept
              minuteStep={10}
              format={"HH:mm"}
              defaultValue={moment(value, "HH:mm")}
              style={{
                borderRadius: 5,
                color: COLORS.sub_accent,
                fontSize: 22,
                fontWeight: "bold",
                border: `1px solid ${COLORS.sub_text}`,
                width: "90%",
              }}
              onChange={(e) => {
                const dayBooking = moment(data.startTime).format("YYYY/MM/DD");
                const timeStartBooking = moment(e).format("HH:mm");
                if (moment(e).hour() < 5) {
                  newStartTime.current = moment(
                    `${dayBooking} ${timeStartBooking}`,
                    "YYYY/MM/DD HH:mm"
                  ).add(1, "day");
                } else {
                  newStartTime.current = moment(
                    `${dayBooking} ${timeStartBooking}`,
                    "YYYY/MM/DD HH:mm"
                  );
                }
              }}
              showTime={{
                format: "HH:mm",
                minuteStep: 10,
              }}
            />
          </Col>
          <Col span={6}>
            <ButtonCustom
              onClick={async () => {
                const result = await FetchApi.changeStartTimeBooking({
                  bookingId: data.id,
                  newStartTime: moment(newStartTime.current).valueOf(),
                });
                if (result.success) {
                  refetch();
                  message.success(STRINGS.done_successful);
                  cache.refetchQueries(
                    `useGetCalendarOrders-${moment().format("MM/DD/YYYY")}`
                  );
                  cache.refetchQueries(`getDetailCourseBooking-${data.id}`);
                  cache.refetchQueries(`bookingComing-${userAdmin.storeId}`);
                  return;
                }
                if (result.code === 1 || result.code === 5) {
                  message.warning(STRINGS.cannot_change_time_booking);
                  return;
                }
                message.error(STRINGS.server_error);
              }}
              disabled={data.paid || statusBooking === "done"}
              style={{
                backgroundColor: COLORS.secondary,
                borderColor: COLORS.secondary,
                minWidth: 80,
                height: "fit-content",
              }}
            >
              {STRINGS.change}
            </ButtonCustom>
          </Col>
        </Row>
      );
    }

    if (label === STRINGS.responsible_person) {
      return (
        <Row
          style={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "55%",
          }}
        >
          <Col
            span={18}
            style={{
              fontWeight: "bold",
              color: COLORS.sub_accent,
              fontSize: 22,
              overflow: "hidden",
              textOverflow: "ellipsis",
              ...styleValue,
            }}
          >
            {value}
          </Col>
          <Col span={6}>
            <ButtonCustom
              onClick={onShowChangeStaff}
              disabled={data.paid || statusBooking === "done"}
              style={{
                backgroundColor: COLORS.secondary,
                borderColor: COLORS.secondary,
                minWidth: 80,
                height: "fit-content",
              }}
            >
              {STRINGS.change}
            </ButtonCustom>
          </Col>
        </Row>
      );
    }
    if (label === STRINGS.coupon_management) {
      let arrListCoupon = listCoupon || [];
      const couponTaxId = arrListCoupon.find(
        (i) => i.type === LOGIC.coupon_time.tax
      )?.id;
      const listCouponConvert = {};
      arrListCoupon.map((i) => (listCouponConvert[i.id] = i));
      return (
        <Row className={"coupon-booking"} style={{ alignItems: "center" }}>
          <Col>
            <Select
              style={{
                textAlign: "left",
                width: 170,
                whiteSpace: "pre-line",
                border: `1px solid ${COLORS.sub_text}`,
                borderRadius: 5,
                color: COLORS.sub_accent,
                fontWeight: "900",
              }}
              mode="multiple"
              allowClear
              disabled={
                // data.customerType === LOGIC.userType.P ||
                // data.customerType === LOGIC.userType.R ||
                data.paid
                // || disabled
              }
              value={form.getFieldValue("couponIds")}
              suffixIcon={<SuffixIconDown />}
              onClick={() => refetchCoupon()}
              onChange={(e) => {
                setCouponSelected(e);
                form.setFieldsValue({ couponIds: e });

                const { comboDTOS } = form.getFieldsValue("comboDTOS");
                let couponDiscount = 0;
                let totalPrice = 0;

                const couponSelectedConvert = {};
                const couponSelected = [];
                e.map((i) => {
                  let id = i;
                  if (typeof i === "string") {
                    id = arrListCoupon?.find((item) => item?.title === i)?.id;
                  }
                  couponSelectedConvert[id] = listCouponConvert[id];
                  couponDiscount += listCouponConvert[id]?.discount || 0;
                  couponSelected.push(listCouponConvert[id]);
                });
                form.setFieldsValue({ couponSelected: couponSelected });

                if (couponSelectedConvert[couponTaxId]) {
                  comboDTOS.forEach((i) => {
                    if (i?.price) {
                      if (
                        listCouponConvert[couponTaxId].courseIds?.includes?.(
                          i.comboId
                        )
                      ) {
                        totalPrice += (i.price || 0) - (i.tax || 0);
                      } else {
                        totalPrice += i.price || 0;
                      }
                    }
                  });
                } else {
                  comboDTOS.forEach((i) => {
                    if (i?.price) {
                      totalPrice += i.price || 0;
                    }
                  });
                }
                setTotal(
                  totalPrice -
                    couponDiscount -
                    (data.pointUse || 0) +
                    (data.lateBookingFee || 0) +
                    (data.addtionalManFee || 0) +
                    (data.designationFee || 0)
                );
              }}
            >
              {arrListCoupon.map((item) => {
                let discount = `${"\n"}-${item.discount}${STRINGS.yen}`;
                if (item.type === LOGIC.coupon_time.tax) {
                  discount = `${"\n"}-10%`;
                }
                return (
                  <Select.Option
                    key={item.id}
                    value={item.id}
                    style={{
                      height: 60,
                      color: COLORS.sub_accent,
                      fontWeight: "bold",
                    }}
                  >
                    <Row style={{ flexWrap: "wrap", whiteSpace: "pre-line" }}>
                      {item.title}
                      {discount}
                    </Row>
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      );
    }
    if (label === STRINGS.is_assign) {
      if (valueAssign.assignedSex) {
        return (
          <Col
            span={16}
            style={{
              fontWeight: "bold",
              color: COLORS.gender_staff[valueAssign.assignedSex],
              fontSize: 22,
            }}
          >
            {STRINGS.staff_gender[valueAssign.assignedSex]}
          </Col>
        );
      }
      if (valueAssign?.chooseStaff) {
        return (
          <Col
            style={{
              width: 14,
              height: 14,
              borderRadius: 7,
              borderWidth: 1,
              borderColor: COLORS.primary,
              borderStyle: "solid",
            }}
          />
        );
      }
    }
    if (label === STRINGS.time_connect) {
      const validTime = moment(data.userCheckInTime).isValid();
      return (
        <TimePickerAutoAccept
          minuteStep={10}
          format={"HH:mm"}
          defaultValue={validTime ? moment(data.userCheckInTime) : ""}
          value={
            validTime
              ? moment(data.userCheckInTime)
              : isCheckInBooking
              ? moment()
              : null
          }
          placeholder={""}
          style={{
            borderRadius: 5,
            color: COLORS.sub_accent,
            fontSize: 22,
            fontWeight: "bold",
            border: `1px solid ${COLORS.sub_text}`,
          }}
          onChange={(e) => {
            form.setFieldsValue({ userCheckInTime: e });
          }}
        />
      );
    }
    if (label === STRINGS.point_provide) {
      return (
        <Row className={"point-booking"} style={{ alignItems: "center" ,marginTop:7}}>
          <Col>
            <Select
              style={{
                textAlign: "left",
                width: 170,
                whiteSpace: "pre-line",
                border: `1px solid ${COLORS.sub_text}`,
                borderRadius: 5,
                color: COLORS.sub_accent,
                fontSize: 30,
              }}
              disabled={
                // data.customerType === LOGIC.userType.P ||
                // data.customerType === LOGIC.userType.R ||
                data.paid
                // || disabled
              }
              onClear={(e) => {
                setBookingPointManualValue(null);
                setCurrentValue(null);
                onChangeVlaue(null);
              }}
              //onDeselect={()=> console.log(456)}
              allowClear
              suffixIcon={null}
              value={currentValue}
              onChange={(e) => {
                setBookingPointManualValue(e);
                onChangeVlaue(e);
              }}
            >
              {listProvidePointManual?.bookingPoints?.map((item, index) => {
                return (
                  <Select.Option
                    key={item.id}
                    value={item.id}
                    style={{
                      height: 30,
                      color: COLORS.sub_accent,
                      fontWeight: "bold",
                    }}
                  >
                    <Row style={{ flexWrap: "wrap", whiteSpace: "pre-line" }}>
                      {item.title} {`(${item.quantity}Pt)`}
                    </Row>
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
        </Row>
      );
    }
    if (Array.isArray(value)) {
      return value.map((item, index) => (
        <span
          key={`${index}`}
          style={{
            fontWeight: "bold",
            color: COLORS.sub_accent,
            ...styleValue,
          }}
        >
          {item}
          {index !== value.length - 1 ? ",\xa0" : ""}
        </span>
      ));
    }

    return (
      <Col
        span={16}
        style={{
          fontWeight: "bold",
          color: COLORS.sub_accent,
          fontSize: 22,
          ...styleValue,
        }}
      >
        {value}
      </Col>
    );
  };
  return (
    <Row
      style={{
        textAlign: "left",
        marginTop: 4,
        alignItems: "center",
        ...styleWrapper,
      }}
    >
      <Col
        span={8}
        style={{
          fontWeight: "bold",
          color: COLORS.sub_accent,
          fontSize: 22,
        }}
      >
        {label}
      </Col>
      {renderValue()}
    </Row>
  );
}
export { ModalBookingDetailInfoCustomer };
