import moment from "moment";
const URL_API = process.env.REACT_APP_API;

function getCoupon({ page, size, storeId }) {
  return `${URL_API}api/v1/admin/coupon?page=${page}&size=${size}${
    storeId != null ? "&storeId=" + storeId : ""
  }`;
}

function getAllReview({ name, storeId, page, size, staffId }) {
  if (name && storeId) {
    return `${URL_API}api/v1/admin/getAllReview?page=${page}&size=${size}&storeId=${storeId}&name=${name}${
      staffId != null && staffId != "null" ? "&staffId=" + staffId : ""
    }`;
  }
  if (name) {
    return `${URL_API}api/v1/admin/getAllReview?page=${page}&size=${size}&name=${name}${
      staffId != null && staffId != "null" ? "&staffId=" + staffId : ""
    }`;
  }
  if (storeId) {
    return `${URL_API}api/v1/admin/getAllReview?page=${page}&size=${size}&storeId=${storeId}${
      staffId != null && staffId != "null" ? "&staffId=" + staffId : ""
    }`;
  }
  return `${URL_API}api/v1/admin/getAllReview?page=${page}&size=${size}${
    staffId != null && staffId != "null" ? "&staffId=" + staffId : ""
  }`;
}
function getAllReviewForAdmin({ name, storeId, page, size, staffId }) {
  if (name && storeId) {
    return `${URL_API}api/v2/admin/review-booking?page=${page}&size=${size}&storeId=${storeId}&name=${name}${
      staffId != null && staffId != "null" ? "&staffId=" + staffId : ""
    }`;
  }
  if (name) {
    return `${URL_API}api/v2/admin/review-booking?page=${page}&size=${size}&name=${name}${
      staffId != null && staffId != "null" ? "&staffId=" + staffId : ""
    }`;
  }
  if (storeId) {
    return `${URL_API}api/v2/admin/review-booking?page=${page}&size=${size}&storeId=${storeId}${
      staffId != null && staffId != "null" ? "&staffId=" + staffId : ""
    }`;
  }
  return `${URL_API}api/v2/admin/review-booking?page=${page}&size=${size}${
    staffId != null && staffId != "null" ? "&staffId=" + staffId : ""
  }`;
}
function getAllNotification(page, size) {
  return `${URL_API}api/v1/admin/getAllNotification?page=${page}&size=${size}`;
}

const Api = {
  login: URL_API + "v1/admin/sign-in",
  refreshToken: (refreshToken) =>
    `${URL_API}v1/app/refreshAccessToken?refresh_token=${refreshToken}`,
  getCoupon: getCoupon,
  deleteCoupon: (id) => `${URL_API}api/v1/admin/coupon?couponId=${id}`,
  getUserApp: ({
    page,
    size,
    search,
    sex,
    storeId,
    sort,
    customerType,
    includeDeleted,
  }) =>
    `${URL_API}api/v1/admin/getUserApp?page=${page}&size=${size}${
      search != null ? "&search=" + search : ""
    }${sex != null ? "&sex=" + sex : ""}${
      storeId != null ? "&storeId=" + storeId : ""
    }${sort != null ? "&sort=" + sort : ""}${
      customerType != null ? "&customerType=" + customerType : ""
    }&includeDeleted=${includeDeleted}`,
  getBookingManualHistory: ({
    page = 1,
    size = 10,
    searchText,
    staffId,
    from,
    to,
    sort,
    bookingId,
  }) =>
    `${URL_API}api/v2/admin/booking-history?page=${page}&size=${size}${
      searchText != null ? "&searchText=" + searchText : ""
    }${staffId != null ? "&staffId=" + staffId : ""}${
      from != null ? "&from=" + from : ""
    }${to != null ? "&to=" + to : ""}${
      bookingId != null ? "&bookingId=" + bookingId : ""
    }`,
  getAllReview: getAllReview,
  getAllReviewForAdmin: getAllReviewForAdmin,
  getAllNotification: getAllNotification,
  deleteNotification: (id) =>
    `${URL_API}api/v1/admin/pushNotification?id=${id}`,
  uploadFile: `${URL_API}v1/admin/uploadFile`,
  uploadFileSound: `${URL_API}api/v3/admin/upload-sound-file`,
  getAllSlides: `${URL_API}api/v1/admin/getAllSlides`,
  slide: `${URL_API}api/v1/admin/slide`,
  deleteSlide: (id) => `${URL_API}api/v1/admin/slide?id=${id}`,
  pushNotification: `${URL_API}api/v1/admin/pushNotification`,
  getAllStore: ({ page, size }) =>
    `${URL_API}api/v1/admin/getAllStoreForAdmin?page=${page}&size=${size}`,
  getAllStoreFilter: ({ page, size }) =>
    `${URL_API}api/v1/admin/getAllStoreFilterForAdmin?page=${page}&size=${size}`,
  store: `${URL_API}api/v1/admin/store`,
  updatePositionStore: `${URL_API}api/v1/admin/changePosition`,
  coupon: `${URL_API}api/v1/admin/coupon`,
  deleteStore: (id) => `${URL_API}api/v1/admin/store/${id}`,
  getAllNews: ({ page, size }) =>
    `${URL_API}api/v1/admin/getAllNews?page=${page}&size=${size}`,
  createNews: `${URL_API}api/v1/admin/createNews`,
  updateNews: `${URL_API}api/v1/admin/updateNews`,
  deleteNews: (newsId) => `${URL_API}api/v1/admin/deleteNews/${newsId}`,

  updateProfile: `${URL_API}api/v1/admin/updateProfileUser`,
  changePassword: `${URL_API}api/v1/admin/changePassword`,
  deActiveUserApp: (id, email) =>
    `${URL_API}api/v1/admin/deActiveUserApp?id=${id}&email=${email}`,
  getListStaff: (storeId, page, size, staffId, staffName) =>
    `${URL_API}api/v1/admin/staff?page=${page}&size=${size}${
      storeId != null ? "&storeId=" + storeId : ""
    }${staffId != null && staffId != "null" ? "&staffId=" + staffId : ""}${
      staffName != null && staffName != "null" ? "&staffName=" + staffName : ""
    }`,
  getRatingStaff: (staffId) =>
    `${URL_API}api/v3/admin/staff-rating?staffId=${staffId}`,
  getPacksStaff: (staffId) =>
    `${URL_API}api/v3/admin/packs-of-staff?staffId=${staffId}`,
  getListStaffCancel: (storeId, page, size, staffId, staffName) =>
    `${URL_API}api/v3/admin/staff?page=${page}&size=${size}${
      storeId != null ? "&storeId=" + storeId : ""
    }${staffId != null && staffId != "null" ? "&staffId=" + staffId : ""}${
      staffName != null && staffName != "null" ? "&staffName=" + staffName : ""
    }`,
  addStaff: `${URL_API}api/v1/admin/staff`,
  updateStaff: `${URL_API}api/v1/admin/staff`,
  deleteStaff: (staffId) => `${URL_API}api/v1/admin/staff?staffId=${staffId}`,

  createBooking: `${URL_API}api/v1/admin/order`,
  updateBooking: `${URL_API}api/v1/admin/order`,

  actionBookingStore: (action, storeId) =>
    `${URL_API}api/v1/app/actionBookingStore?action=${action}&storeId=${storeId}`,

  orderInfo: ({ userId }) => {
    if (!!userId) {
      return `${URL_API}api/v2/booking-info?userId=${userId}`;
    }
    return `${URL_API}api/v2/booking-info`;
  },
  getStaffByComboPacks: `${URL_API}api/v2/getStaffByComboPacks`,
  getStaffWorkingToday: ({ storeId }) =>
    `${URL_API}api/v3/admin/staff-working?storeId=${storeId}`,
  suggestBooking: `${URL_API}api/v2/suggest-bookings`,
  createOrderRoleAdmin: `${URL_API}api/v2/admin/booking`,

  cancelBookingTerm: `${URL_API}api/v2/admin/termsCancel`,
  getStaffStatusFlow: `${URL_API}api/v3/admin/staff-status-flow`,
  getNotiSound: `${URL_API}api/v3/admin/get-noti-sound`,
  createNotiSound: `${URL_API}api/v3/admin/create-noti-sound`,
  updateNotiSound: `${URL_API}api/v3/admin/update-noti-sound`,

  cancelBooking: `${URL_API}api/v2/admin/cancel-booking`,
  genShiftSchedule: (firstDayOfMonth) =>
    `${URL_API}api/v2/admin/gen-shift-schedule?firstDayOfMonth=${firstDayOfMonth}`,
  getCouponBooking: (birthday, userId, bookingId) => {
    if (birthday && moment(birthday).isValid()) {
      return `${URL_API}api/v2/admin/coupon-available?birthday=${birthday}&userId=${userId}&bookingId=${bookingId}`;
    }
    return `${URL_API}api/v2/admin/coupon-available?userId=${userId}&bookingId=${bookingId}`;
  },
  emailSetting: `${URL_API}api/v2/admin/emailSetting`,
  chargeCancelFee: `${URL_API}api/v2/admin/write-off-cancel-fee`,
  getHistoryCancelBooking: `${URL_API}api/v3/admin/booking-cancellation-history`,

  staffResting: `${URL_API}api/v2/admin/staffResting`,
  getProlongedCourse: (bookingId) =>
    `${URL_API}api/v2/admin/prolonged-course?bookingId=${bookingId}`,
  getCourseAvailableWithBooking: (bookingId) =>
    `${URL_API}api/v2/admin/get-course-available-with-booking?bookingId=${bookingId}`,
  getStatusBooking: (bookingId) =>
    `${URL_API}api/v2/admin/booking-status?bookingId=${bookingId}`,
  getStaffWorkStatistics: ({ day, staffId }) =>
    `${URL_API}api/v2/admin/staff-work-statistics?day=${day}&staffId=${staffId}`,
  getDetailCourseBooking: ({ id }) =>
    `${URL_API}api/v2/admin/detail-booking?id=${id}`,
  updateInfomationBooking: `${URL_API}api/v2/update-booking`,
  createUserNonApp: `${URL_API}api/v2/admin/create-user-non-app`,
  bookingManual: `${URL_API}api/v2/admin/booking-history`,
  deleteBookingManual: ({ bookingHistoryId }) =>
    `${URL_API}api/v2/admin/booking-history?bookingHistoryId=${bookingHistoryId}`,
  validateStaffPassword: `${URL_API}api/v2/admin/validate-staff-password-with-id`,
  getPackOfStore: ({ userId }) => {
    if (!!userId) {
      return `${URL_API}api/v2/admin/get-packs-of-store?userId=${userId}`;
    }
    return `${URL_API}api/v2/admin/get-packs-of-store`;
  },
  checkStoreHaveBooking: `${URL_API}api/v2/admin/store-admin-check-booking-feature-status`,
  bookingHistoryManualTimeline: (date) =>
    `${URL_API}api/v2/admin/booking-history-timeline?date=${date}`,
  listBookingPoint: ({ page, size }) =>
    `${URL_API}api/v2/admin/booking-point?page=${page}&size=${size}`,
  bookingPoint: (id) => {
    if (id) {
      return `${URL_API}api/v2/admin/booking-point?id=${id}`;
    }
    return `${URL_API}api/v2/admin/booking-point`;
  },
  changePositionBookingPoint: `${URL_API}api/v2/admin/change-position-booking-point-store`,
  storeSurcharge: `${URL_API}api/v2/admin/store-surcharge`,
  getAllCourseOfCoupon: ({ couponId }) => {
    if (!!couponId) {
      return `${URL_API}api/v2/admin/get-sys-course-and-courses-of-coupon?couponId=${couponId}`;
    }
    return `${URL_API}api/v2/admin/get-sys-course-and-courses-of-coupon`;
  },
  requestChangeStaffBooking: ({ bookingId }) =>
    `${URL_API}api/v3/admin/request-change-staff-for-booking?bookingId=${bookingId}`,
  acceptChangeStaffBooking: `${URL_API}api/v3/admin/accept-change-staff-for-booking`,
  copyBookingManualData: ({ from, to, storeId }) =>
    `${URL_API}api/v2/admin/copy-booking-manual-to-booking-data?from=${from}&to=${to}&storeId=${storeId}`,
  changeUserBooking: ({ bookingId, userId }) =>
    `${URL_API}api/v3/admin/change-customer-of-booking?bookingId=${bookingId}&userId=${userId}`,
  checkBatchBooking: ({ bookingId }) =>
    `${URL_API}api/v3/admin/is-batch-booking?bookingId=${bookingId}`,
  cancelMultipleBooking: `${URL_API}api/v3/admin/cancel-multiple-booking`,
  getStoreRevenueInRange: ({ from, to, page, size }) =>
    `${URL_API}api/v3/admin/store-revenue-in-range?from=${from}&to=${to}&page=${page}&size=${size}`,
  getTodayStoreRevenue: `${URL_API}api/v3/admin/today-store-revenue`,
  confirmStoreRevenue: `${URL_API}api/v3/admin/confirm-store-revenue`,
  downloadStoreRevenueInRange: ({ from, to }) =>
    `${URL_API}api/v3/admin/download-store-revenue-in-range?from=${from}&to=${to}`,
  changeStartTimeBooking: ({ bookingId, newStartTime }) =>
    `${URL_API}api/v3/admin/change-start-time-of-booking?bookingId=${bookingId}&newStartTime=${newStartTime}`,
  requestExtraBreakSpecificTime: `${URL_API}api/v2/admin/request-extra-break-with-specific-time`,
  requestChangeEndTimeShift: `${URL_API}api/v3/admin/request-change-end-time-of-shift`,
  genShiftSpecificMonth: (firstDateOfMonth) =>
    `${URL_API}api/v3/generate-shift?firstDateOfMonth=${firstDateOfMonth}`,
  copySchedule: (firstDateOfBeGenMonth) =>
    `${URL_API}api/v3/admin/copy-schedule?firstDateOfBeGenMonth=${firstDateOfBeGenMonth}`,
  deleteRestTimeExtra: ({ shiftId, startTime }) =>
    `${URL_API}api/v2/admin/delete-rest-time-extra?startTime=${startTime}&shiftId=${shiftId}`,
  paidBooking: (bookingId) =>
    `${URL_API}api/v3/admin/paid-booking?bookingId=${bookingId}`,
};

export { Api };
