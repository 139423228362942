// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".staff-fixed-schedule {\n  margin-top: -10px;\n}\n.staff-fixed-schedule .header-wrapper {\n  background-color: white;\n  padding: 18px;\n  justify-content: space-between;\n  align-items: center;\n}\n.staff-fixed-schedule .ant-table-thead > tr > th {\n  border-width: 1px 1px 1px 1px !important;\n  border-color: #4b2a05 !important;\n}\n.staff-fixed-schedule .ant-table-thead .ant-table-cell {\n  border-top: 1px solid #4b2a05 !important;\n}\n.staff-fixed-schedule .ant-table-thead > tr :first-child {\n  border-left: 1px solid #4b2a05 !important;\n}", "",{"version":3,"sources":["webpack://./src/components/staff-fixed-schedule/StaffFixedSchedule.scss"],"names":[],"mappings":"AAGA;EACE,iBAAA;AAQF;AAPE;EACE,uBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AASJ;AAPE;EACE,wCAAA;EACA,gCAAA;AASJ;AAPE;EACE,wCAAA;AASJ;AANI;EACE,yCAAA;AAQN","sourcesContent":["@import \"../../util/variables.scss\";\n\n\n.staff-fixed-schedule {\n  margin-top: -10px;\n  .header-wrapper {\n    background-color: white;\n    padding: 18px;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .ant-table-thead > tr > th {\n    border-width: 1px 1px 1px 1px !important;\n    border-color: $main-color !important;\n  }\n  .ant-table-thead .ant-table-cell {\n    border-top: 1px solid $main-color !important;\n  }\n  .ant-table-thead > tr {\n    :first-child {\n      border-left: 1px solid $main-color !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainColor": "#4b2a05",
	"textColor": "#f19834",
	"redColor": "#FF0C0C",
	"grey1": "#efefef",
	"grey2": "#757575",
	"black1": "#303030",
	"borderColor": "#cbcbcb"
};
export default ___CSS_LOADER_EXPORT___;
