import React from "react";
import { Row } from "antd";
import moment from "moment";

import { STRINGS } from "../../constants";
import { StaffBookingStep3Item } from "./items";

import "./StaffBookingStep3.scss";

function StaffBookingStep3({ form }) {
  const dataBooking = form.getFieldValue();
  const numberPeople = dataBooking.bookingNum;
  const dateFormat = moment(dataBooking.bookingSuggestTime?.startTime).format(
    "MM月DD日YYYY年 HH:mm"
  );

  return (
    <div>
      <div className="booking-step-3">
        <Row style={{ alignItems: "center", paddingBottom: "30px" }}>
          <div className="text-date">{STRINGS.time}:</div>
          <div className="text-time" style={{ paddingLeft: "10px" }}>
            {dateFormat}
          </div>

          <div className="text-date" style={{ paddingLeft: "40px" }}>
            {STRINGS.number_people}:
          </div>
          <div className="text-time" style={{ paddingLeft: "10px" }}>
            {numberPeople}
          </div>
        </Row>
      </div>
      {[...Array(numberPeople)].map((_, index) => {
        return (
          <div key={`${index}`} className="booking-step-3-user">
            <StaffBookingStep3Item
              form={form}
              numberOrdinal={index + 1}
              totalUser={numberPeople}
            />
          </div>
        );
      })}
    </div>
  );
}

export { StaffBookingStep3 };
