import React, { useState } from "react";
import { Col, Form, Row } from "antd";

import { COLORS, STRINGS } from "../../../constants";

import "../StaffBooking.scss";

function StaffBookingNote({ data = [], form, numberOrdinal }) {
  const [selectedNote, setSelectedNote] = useState();
  const renderSelect = (item) => {
    if (item === selectedNote) {
      return <div className="radio-button-select" />;
    }
  };
  return (
    <Form.Item name={`bookingNote${numberOrdinal}`}>
      <Col
        style={{
          borderTop: `1px solid ${COLORS.border}`,
          marginTop: "20px",
          padding: "20px 0px",
        }}>
        <Row className="text-booking" style={{ paddingBottom: "15px" }}>
          {STRINGS.des_booking_6}
        </Row>
        <Row>
          {data.map((item) => {
            return (
              <Col
                key={`${item}`}
                style={{
                  width: "fit-content",
                  marginRight: "25px",
                  marginBottom: "10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (item === selectedNote) {
                    setSelectedNote();
                    form.setFieldsValue({
                      [`bookingNote${numberOrdinal}`]: null,
                    });
                    return;
                  }
                  form.setFieldsValue({
                    [`bookingNote${numberOrdinal}`]: item,
                  });
                  setSelectedNote(item);
                }}>
                <Row>
                  <div className="radio-button">{renderSelect(item)}</div>
                  <div style={{ paddingLeft: "10px", color: COLORS.sub_text }}>
                    {item}
                  </div>
                </Row>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Form.Item>
  );
}

export { StaffBookingNote };
