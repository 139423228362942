/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Col, Form } from "antd";

import { LOGIC } from "../../../constants";
import { MultipleDateSelect, Validate } from "../../../elements";

import "../ManagerPointDay.scss";

export function PointDayConfigDate({ form, pointType }) {
  const [rules, setRules] = useState([]);
  useEffect(() => {
    if (pointType === LOGIC.booking_point.date) {
      setRules([Validate.emptyContent]);
    } else {
      setRules([]);
      form.resetFields(["specificDates"]);
    }
  }, [pointType]);
  return (
    <Col style={{ paddingTop: 14 }}>
      <Form.Item name="specificDates" rules={rules}>
        <MultipleDateSelect
          disabled={pointType !== LOGIC.booking_point.date}
          isReset={pointType !== LOGIC.booking_point.date}
        />
      </Form.Item>
    </Col>
  );
}
