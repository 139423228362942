import React, { useState } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  message,
  InputNumber,
  Checkbox,
  Select,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import {
  ButtonCustom,
  ModalCustomService,
  LoadingAbsolute,
  ImageUpload,
  SuffixIconDown,
  Validate,
  SvgView,
} from "../../../../../elements";
import { CommonFunction, useGetComboCategories } from "../../../../../util/";
import { FetchApi } from "../../../../../api";
import { COLORS, STRINGS, Svgs } from "../../../../../constants";
import Auth from "../../../../../store/Authentication";

import "../../../Combo.scss";

const Add = ({ data, setData }) => {
  const [form] = Form.useForm();
  const { comboCategories } = useGetComboCategories();
  const [loading, setLoading] = useState(false);
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const [isFavourite, setIsFavourite] = useState(false);

  const onAdd = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      fieldData.canCombineWithAnotherCourse =
        !fieldData.canNotCombineWithAnotherCourse;
      setLoading(true);
      const result = await FetchApi.createCombo(fieldData);
      setLoading(false);
      if (result.success) {
        ModalCustomService.close();
        setData();
        message.success(STRINGS.registered_common);
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return (
    <Col className="combo-form" style={{ maxWidth: 1000 }}>
      <Form
        layout="vertical"
        form={form}
        onValuesChange={(e) => {
          if ("isFavourite" in e) {
            setIsFavourite(e.isFavourite);
          }
        }}>
        <Form.Item
          style={{ marginBottom: 10 }}
          required
          name="name"
          label={STRINGS.category_title}
          rules={[Validate.emptyContent]}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 10 }}
          name="shortName"
          label={STRINGS.category_title_short}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 10 }}
          label={STRINGS.description_2}
          name="description">
          <Input.TextArea />
        </Form.Item>

        {/* <Form.Item
          required
          name="color"
          rules={[Validate.isHexColor]}
          style={{ marginBottom: 10 }}
          label={STRINGS.combo_color}>
          <AppPicker />
        </Form.Item> */}
        <Row style={{ alignItems: "baseline" }}>
          {userAdmin.type === "STORE_ADMIN" && (
            <Row style={{ alignItems: "center" }}>
              <Form.Item
                style={{ marginBottom: 0, marginRight: 10 }}
                valuePropName="checked"
                name="isFavourite"
                initialValue={false}>
                <Checkbox />
              </Form.Item>
              <Col style={{ color: COLORS.sub_disabled, fontSize: 14 }}>
                {STRINGS.is_favourite}
              </Col>
              <Col style={{ marginLeft: 10 }}>
                <Form.Item
                  style={{ marginBottom: 0, marginRight: 10 }}
                  valuePropName="checked"
                  name="canNotCombineWithAnotherCourse"
                  initialValue={false}>
                  <Checkbox />
                </Form.Item>
              </Col>
              <Col style={{ color: COLORS.sub_disabled, fontSize: 14 }}>
                {STRINGS.can_combine_with_another_course}
              </Col>
            </Row>
          )}
          {/* <Col style={{ color: COLORS.sub_disabled, fontSize: 14, marginRight: 10 }}>
            {STRINGS.is_prolonged}
          </Col>
          <Form.Item
            style={{ marginBottom: 0 }}
            valuePropName="checked"
            name="isProlonged"
            initialValue={false}>
            <Checkbox />
          </Form.Item> */}
        </Row>
        {userAdmin.type === "STORE_ADMIN" && !isFavourite && (
          <Form.Item
            style={{
              margin: "10px 0px",
              flexDirection: "row",
              alignItems: "baseline",
            }}
            label={STRINGS.combo_category}
            required
            rules={[Validate.emptyContent]}
            name="categoryId">
            <Select
              style={{ textAlign: "left", marginLeft: 10, width: 240 }}
              suffixIcon={<SuffixIconDown />}>
              {comboCategories.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <Row style={{ alignItems: "center" }}>
          <Form.Item
            style={{ marginBottom: 0, marginRight: 10 }}
            valuePropName="checked"
            name="applyDMember"
            initialValue={false}>
            <Checkbox />
          </Form.Item>
          <Col style={{ color: COLORS.sub_disabled, fontSize: 14 }}>
            {STRINGS.create_D_combo}
          </Col>
        </Row>
        <Form.Item name="imageUrl" label={STRINGS.image} style={{ width: 375 }}>
          <ImageUpload aspect={3 / 2} />
        </Form.Item>

        <Row className="theader">
          <Col span={3}>{STRINGS.time}</Col>
          <Col span={3}>{STRINGS.app_member}</Col>
          <Col span={3}>{STRINGS.app_non_member}</Col>
          <Col span={3}>{STRINGS.list_price}</Col>
          <Col span={3}>{STRINGS.price_job}</Col>
          <Col span={3}>{STRINGS.price_tax}</Col>
          <Col span={3}>{STRINGS.grant_points}</Col>
          <Col span={3}>{STRINGS.hidden_in_suggest_booking}</Col>
          {/* <Col span={2}>{STRINGS.apply_coupon}</Col> */}
          <Col span={1} />
        </Row>

        <Form.List name="comboPackRequests">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Col
                    key={field.key}
                    className={index === 0 ? "col-wrapper-row-input" : 0}>
                    <Row className="row-table-custom">
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "minutes"]}
                          fieldKey={[field.fieldKey, "minutes"]}
                          rules={[Validate.isPositiveNumber]}>
                          <InputNumber
                            style={{
                              color: COLORS.primary,
                              fontWeight: "bold",
                            }}
                            formatter={(value) => `${value} 分`}
                            parser={(value) =>
                              value.replace("分", "").replace(" ", "")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "priceMemberApp"]}
                          fieldKey={[field.fieldKey, "priceMemberApp"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) =>
                              `${value.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} 円`
                            }
                            parser={(value) =>
                              value
                                .replace("円", "")
                                .replace(" ", "")
                                .replace(/\$\s?|(,*)/g, "")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "priceNonApp"]}
                          fieldKey={[field.fieldKey, "priceNonApp"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) =>
                              `${value.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} 円`
                            }
                            parser={(value) =>
                              value
                                .replace("円", "")
                                .replace(" ", "")
                                .replace(/\$\s?|(,*)/g, "")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "priceDefault"]}
                          fieldKey={[field.fieldKey, "priceDefault"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) =>
                              `${value.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} 円`
                            }
                            parser={(value) =>
                              value
                                .replace("円", "")
                                .replace(" ", "")
                                .replace(/\$\s?|(,*)/g, "")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "staffWage"]}
                          fieldKey={[field.fieldKey, "staffWage"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) =>
                              `${value.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} 円`
                            }
                            parser={(value) =>
                              value
                                .replace("円", "")
                                .replace(" ", "")
                                .replace(/\$\s?|(,*)/g, "")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "tax"]}
                          fieldKey={[field.fieldKey, "tax"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) =>
                              `${value.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} 円`
                            }
                            parser={(value) =>
                              value
                                .replace("円", "")
                                .replace(" ", "")
                                .replace(/\$\s?|(,*)/g, "")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "point"]}
                          fieldKey={[field.fieldKey, "point"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) => `${value} pt`}
                            parser={(value) =>
                              value.replace("pt", "").replace(" ", "")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          valuePropName="checked"
                          name={[field.name, "hiddenInSuggestBooking"]}>
                          <Checkbox defaultChecked={false} />
                        </Form.Item>
                      </Col>
                      {/* <Col span={2}>
                        <Form.Item
                          {...field}
                          style={{ marginBottom: 0 }}
                          valuePropName="checked"
                          name={[field.name, "applyCoupon"]}>
                          <Checkbox />
                        </Form.Item>
                      </Col> */}
                      <Col span={1}>
                        <button
                          style={{
                            borderWidth: 0,
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            cursor: "pointer",
                            marginTop: 5,
                          }}
                          onClick={() => remove(field.name)}>
                          <SvgView svgData={Svgs.delete} />
                        </button>
                      </Col>
                    </Row>
                  </Col>
                ))}
                <Row style={{ alignItems: "flex-start", marginTop: 20 }}>
                  <ButtonCustom
                    onClick={() => add()}
                    icon={<PlusCircleOutlined />}>
                    {STRINGS.add_more_course}
                  </ButtonCustom>
                </Row>
              </>
            );
          }}
        </Form.List>
      </Form>
      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onAdd}>{STRINGS.save}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
};

export default Add;
