/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Col, Row } from "antd";
import moment from "moment";

import {
  useAudio,
  useGetCalendarOrders,
  // useGetNotiSound,
} from "../../util/hooks/";
import { ErrorView, LoadingAbsolute } from "../../elements";
import { COLORS, STRINGS } from "../../constants";
import Schedule from "./elements/Schedule";
import HeaderSchedule from "./items/HeaderSchedule";

import "./StaffSchedule.scss";

let selectedDate;
if (moment().hour() < 5) {
  selectedDate = moment().subtract(1, "day");
} else selectedDate = moment();

export default function StaffSchedule() {
  const { startAudio, pauseAudio } = useAudio();

  const {
    calendarOrder,
    isLoadingCalendarOrder,
    refetchCalendarOrder,
    isFetchingCalendarOrder,
    status
  } = useGetCalendarOrders(selectedDate.format("MM/DD/YYYY"));

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      refetchCalendarOrder();
    }, 30000);

    return () => {
      refetchInterval && clearInterval(refetchInterval);
    };
  }, []);

  useEffect(() => {
    let timeout;

    if (
      (calendarOrder?.notiNewBookingEnum === "EXIST_ONE_NEW_BOOKING" ||
        calendarOrder?.notiNewBookingEnum === "EXIST_MULTI_NEW_BOOKINGS") &&
      status === "success" &&
      !isFetchingCalendarOrder
    ) {
      startAudio();
      timeout = setTimeout(() => {
        pauseAudio();
      }, 5000);
    } else {
      pauseAudio();
    }
    return () => {
      timeout && clearTimeout(timeout);
    };
  }, [calendarOrder, isFetchingCalendarOrder]);

  if (isLoadingCalendarOrder) {
    return <LoadingAbsolute style={{ top: "50%", left: "50%" }} />;
  }

  if (calendarOrder && !calendarOrder.success) {
    return <ErrorView data={calendarOrder} />;
  }

  return (
    <div className="staff-schedule">
      <HeaderSchedule
        selectedDate={selectedDate}
        comboColors={calendarOrder.comboColors}
        totalMoney={calendarOrder.totalMoney}
        staffOrderDTOS={calendarOrder.staffOrderDTOS}
        refetchCalendarOrder={refetchCalendarOrder}
      />
      <Schedule
        calendarOrder={calendarOrder ?? {}}
        refetchCalendarOrder={refetchCalendarOrder}
        selectedDate={selectedDate}
      />
      <Row style={{ paddingBottom: 80 }}>
        {STRINGS.category_course.map((i) => {
          return (
            <Col
              key={i.name}
              style={{
                backgroundColor: i.color,
                color: COLORS.sub_accent,
                fontWeight: "bold",
                fontSize: 20,
                padding: "10px 15px 10px 15px",
                marginTop: 20,
                marginRight: 40,
              }}
            >
              {i.name}
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
