/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, memo } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  TimePicker,
  message,
  Select,
  Switch,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import moment from "moment";

import { STRINGS, LOGIC, COLORS } from "../../constants";
import {
  Container,
  ButtonCustom,
  ImageListUpload,
  ErrorView,
  LoadingView,
  ImageUpload,
  AlertService,
} from "../../elements";
import { Validate } from "../../elements/Validate";
import { FetchApi } from "../../api";
import { CommonFunction } from "../../util/CommonFunction";
import { AccountService } from "../../services/AccountService";
import Auth from "../../store/Authentication";
import Quill from "../../elements/Quill";

import "../../util/variables.scss";
import "./StoreInfo.scss";

const { Option } = Select;
const { TextArea } = Input;

const StoreInfo = memo(() => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const { data, refetch, isLoading, isFetching } = useQuery(
    [`StoreInfo1`],
    () => FetchApi.findStoreByOwner()
  );

  const onEdit = async () => {
    try {
      await form.validateFields();
      AlertService.set({
        textConfirm: STRINGS.update,
        title: STRINGS.confirm,
        content: STRINGS.confirm_save_common,
        onConfirm: async () => {
          const fieldData = form.getFieldValue();
          fieldData.id = data?.storeDTO.id;
          setLoading(true);
          const result = await FetchApi.updateStore(fieldData);
          setLoading(false);
          if (result.success) {
            refetch();
            data.storeDTO = result.storeDTO;
            message.success(STRINGS.registered_common);
            AccountService.set({
              ...AccountService.get(),
              name: fieldData.nameOwner,
              storeDTO: result.storeDTO,
            });
            Auth.setCurrentUser(AccountService.get());
          } else if (result.code == 0) {
            message.warning(STRINGS.email_existed);
          } else {
            message.error(result.message || STRINGS.something_wrong);
          }
        },
      });
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  const item = data?.storeDTO;

  const renderHeaderRight = () => (
    <Row>
      <ButtonCustom
        onClick={form.resetFields}
        isCancelButton
        style={{
          marginRight: 10,
        }}>
        {STRINGS.cancel}
      </ButtonCustom>
      <ButtonCustom onClick={onEdit}>{STRINGS.save}</ButtonCustom>
    </Row>
  );

  if (isLoading || loading) {
    return <LoadingView />;
  }

  if (data && !data.success && !Array.isArray(data?.data)) {
    return <ErrorView data={data} />;
  }

  return (
    <Container
      className="store-info-wrapper"
      hasBorderBottom
      styleHeader={{ padding: "15px" }}
      style={{ paddingBottom: 300 }}
      renderLeft={() => (
        <div
          style={{
            fontWeight: "bold",
            fontSize: 22,
            color: COLORS.accent,
          }}>
          {STRINGS.mypage_management}
        </div>
      )}
      renderRight={renderHeaderRight}>
      {!isFetching && (
        <Form
          form={form}
          layout="vertical"
          style={{ padding: "0px 15px 55px 15px" }}>
          <Form.Item
            name="images"
            rules={[Validate.emptyContent]}
            initialValue={item.images}>
            <ImageListUpload aspect={3 / 2} />
          </Form.Item>
          <Row gutter={20}>
            <Col xl={8}>
              <Form.Item
                required
                name="name"
                label={STRINGS.store_name}
                rules={[Validate.emptyContent]}
                initialValue={item.name}>
                <Input.TextArea
                  autoSize={{ minRows: 1, maxRows: 6 }}
                  placeholder={STRINGS.store_name}
                />
              </Form.Item>
              <Form.Item
                required
                name="phoneNumber"
                label={STRINGS.phone_number}
                rules={[Validate.phoneNumber]}
                initialValue={item.phoneNumber}>
                <Input placeholder={STRINGS.phone_number} />
              </Form.Item>
              <Row gutter={20}>
                <Col xl={12}>
                  <Form.Item
                    label={STRINGS.longitude}
                    name="longitude"
                    rules={[Validate.latlong]}
                    initialValue={item.longitude}>
                    <Input placeholder={STRINGS.longitude} />
                  </Form.Item>
                </Col>
                <Col xl={12}>
                  <Form.Item
                    name="latitude"
                    label={STRINGS.latitude}
                    rules={[Validate.latlong]}
                    initialValue={item.latitude}>
                    <Input placeholder={STRINGS.latitude} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="address"
                label={STRINGS.address}
                required
                rules={[Validate.emptyContent]}
                initialValue={item.address}>
                <Input placeholder={STRINGS.address} />
              </Form.Item>
              <Form.Item
                name="enableViewRevenue"
                label={STRINGS.show_revenue}
                initialValue={item.enableViewRevenue}>
                <Switch defaultChecked={item.enableViewRevenue} />
              </Form.Item>

              <Form.Item
                name="numberOfExtraRestTime"
                label={STRINGS.break_time}
                initialValue={item.numberOfExtraRestTime}
                rules={[Validate.isPositiveNumMessage]}>
                <Input placeholder={STRINGS.break_time} />
              </Form.Item>
              <Row
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <Col span={22}>
                  <Form.Item
                    name="numberOfExtraRestTimeMinutes"
                    label={STRINGS.max_break_time}
                    initialValue={item.numberOfExtraRestTimeMinutes}
                    rules={[Validate.isPositiveNumMessage]}>
                    <Input placeholder={STRINGS.max_break_time} />
                  </Form.Item>
                </Col>
                <Col span={1}>{STRINGS.minute}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="note"
                    label={STRINGS.note}
                    initialValue={item.note}>
                    <TextArea
                      style={{ borderRadius: 5 }}
                      autoSize={{
                        minRows: 3,
                        maxRows: 5,
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col xl={8}>
              <Row gutter={20}>
                <Col xl={12}>
                  <Form.Item
                    label={STRINGS.open_time}
                    name="openTime"
                    initialValue={
                      item.openTime ? moment(item.openTime, "HH:mm") : ""
                    }>
                    <TimePicker
                      style={{ width: "100%" }}
                      placeholder={"HH:mm"}
                      format={"HH:mm"}
                      clearIcon={false}
                    />
                  </Form.Item>
                </Col>
                <Col xl={12}>
                  <Form.Item
                    name="closeTime"
                    label={STRINGS.close_time}
                    initialValue={
                      item.closeTime ? moment(item.closeTime, "HH:mm") : ""
                    }>
                    <TimePicker
                      style={{ width: "100%" }}
                      placeholder={"HH:mm"}
                      format={"HH:mm"}
                      clearIcon={false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="nameOwner"
                label={STRINGS.store_owner}
                required
                rules={[Validate.emptyContent]}
                initialValue={item.userOwner?.name}>
                <Input placeholder={STRINGS.store_owner} />
              </Form.Item>
              <Form.Item
                name="email"
                label={STRINGS.email}
                required
                rules={[Validate.emptyContent]}
                initialValue={item.userOwner?.email}>
                <Input placeholder={STRINGS.email} disabled />
              </Form.Item>
              <Form.Item
                name="password"
                label={STRINGS.password}
                required
                rules={[Validate.emptyContent]}
                initialValue={item.userOwner?.passwordOwner}>
                <Input.Password placeholder={STRINGS.password} />
              </Form.Item>
              <Form.Item
                name="storeUrl"
                label={STRINGS.available_link}
                initialValue={item.storeUrl}
                rules={[Validate.isUrl]}>
                <Input placeholder={STRINGS.available_link} />
              </Form.Item>
              <Form.Item
                name="pointCheckIn"
                label={STRINGS.grant_points}
                initialValue={!!item.pointCheckIn ? item.pointCheckIn : ""}
                rules={[Validate.isPositiveNumMessage]}>
                <Input placeholder={STRINGS.grant_points} />
              </Form.Item>
              <Form.Item
                name="pointReview"
                label={STRINGS.review_point}
                initialValue={!!item.pointReview ? item.pointReview : ""}
                rules={[Validate.isPositiveNumMessage]}>
                <Input placeholder={STRINGS.review_point} />
              </Form.Item>
              <Form.Item
                name="bed"
                label={STRINGS.bed}
                initialValue={item.bed}
                rules={[Validate.isPositiveNumMessage]}>
                <Input placeholder={STRINGS.bed} />
              </Form.Item>
              <Form.Item
                name="storeCode"
                label={STRINGS.store_code}
                initialValue={item.storeCode}>
                <Select
                  disabled={userAdmin.type !== "SYSTEM_ADMIN"}
                  suffixIcon={
                    <CaretDownOutlined style={{ color: COLORS.border_6 }} />
                  }>
                  {LOGIC.storeCode.map((itm) => (
                    <Option key={"" + itm}>{itm}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xl={8} style={{ textAlign: "start" }}>
              <Row justify="space-between">
                <Col style={{ color: "#757575" }}>{STRINGS.QRCode}</Col>
                <a
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecorationLine: "underline",
                  }}
                  onClick={() => {
                    CommonFunction.setUpDownLoad(item.qrCodeImage, item.name);
                  }}>
                  {STRINGS.qr_download}
                </a>
              </Row>
              <div
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: 3,
                  padding: "20px 30px",
                  marginBottom: 25,
                }}>
                <Form.Item
                  name="QRImage"
                  style={{
                    width: "100%",
                    height: 232,
                    marginBottom: 0,
                    alignItems: "center",
                  }}
                  initialValue={item.qrCodeImage}>
                  <Row justify="center">
                    <img
                      style={{ width: "232px", height: "232px" }}
                      src={item.qrCodeImage}
                      alt="qr"
                    />
                  </Row>
                </Form.Item>
              </div>
              <Form.Item
                className="img-map"
                name="imageMapUrl"
                label={STRINGS.store_map}
                initialValue={item.imageMapUrl}>
                <ImageUpload aspect={1 / 0.67} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="description"
            label={STRINGS.description}
            style={{ marginBottom: 80 }}
            initialValue={item.description}>
            <Quill style={{ height: 350 }} />
          </Form.Item>
        </Form>
      )}
    </Container>
  );
});

export default StoreInfo;
