import React, { useState } from "react";
import { Col, Input, Row } from "antd";

import { COLORS } from "../constants";
import { STRINGS } from "../constants/Strings";
import { useListStoreStaff } from "../util/hooks";

function RadioAllStoreStaff({
  defaultValue,
  onChange,
  containerStyle,
  itemStyle,
}) {
  const { listStoreStaff } = useListStoreStaff();

  const [value, setValue] = useState({
    id: defaultValue,
    phone: "",
    password: "",
    name: "",
  });

  const onChangeData = (obj) => {
    setValue({ ...value, ...obj });
    onChange?.({ ...value, ...obj });
  };

  let listStaff = listStoreStaff;

  return (
    <Row style={{ margin: "10px 30px", ...containerStyle }}>
      {(listStaff?.data || [])
        .filter((i) => !i.deleted)
        .map((item) => {
          return (
            <Col
              onClick={() => {
                onChangeData({
                  id: item.id,
                  phone: item.phoneNumber,
                  name: item.name,
                });
              }}
              style={{ padding: 10, cursor: "pointer", ...itemStyle }}
              key={"" + item.id}
              span={12}
            >
              <Row style={{ alignItems: "center" }}>
                <Row
                  style={{
                    border: "1px solid #497D37",
                    width: 30,
                    height: 30,
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 15,
                    marginRight: 12,
                  }}
                >
                  {value.id === item.id && (
                    <Row
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: 10,
                        backgroundColor: "#497D37",
                      }}
                    />
                  )}
                </Row>
                {/* <img
                src={item.avatar}
                style={{ width: 36, height: 36, margin: "0px 12px" }}
              /> */}
                <Col style={{ fontSize: 14, color: COLORS.border_7 }}>
                  {item.name}
                </Col>
              </Row>
              {value.id === item.id && (
                <>
                  <Row>{STRINGS.password_title}</Row>
                  <Input.Password
                    onChange={(e) => {
                      onChangeData({ password: e.target.value });
                    }}
                  />
                </>
              )}
            </Col>
          );
        })}
    </Row>
  );
}

export default RadioAllStoreStaff;
