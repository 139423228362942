import React, { useState, useRef } from "react";
import { Button, DatePicker, Form, Input, message, Row, Select } from "antd";
import moment from "moment";
import { useQueryClient } from "react-query";

import { FetchApi } from "../../../api";
import {
  City,
  COLORS,
  LOGIC,
  Province,
  STRINGS,
  Svgs,
} from "../../../constants";
import {
  ModalCustomService,
  SuffixIconDown,
  Validate,
} from "../../../elements";
import RadioCustom from "../elements/RadioCustom";

function ModalInfoCustomer({ data, refetchCalendarOrder }) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [provinceFilter, setProvinceFilter] = useState(
    Province.filter((_item, _index) => {
      return _item.cityId == data.cityId || _item.cityId == 40;
    })
  );
  const oldCustomerType = useRef(data.customerType);
  const renderLabel = (label) => (
    <Row style={{ color: "black", fontWeight: "bold" }}>{label}</Row>
  );

  const onConfirm = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      let nameCity = "";
      let nameDistrict = "";
      fieldData.banned = false;
      if (!data.id) {
        delete fieldData.bookingId;
      }
      if (fieldData.cityId) {
        nameCity = City.find((i) => i.id === fieldData.cityId)?.name1;
      }
      if (fieldData.districtId) {
        nameDistrict = provinceFilter.find(
          (i) => i.id === fieldData.districtId
        )?.name1;
      }
      if (fieldData.customerType === "X") {
        fieldData.banned = true;
        fieldData.customerType = oldCustomerType.current;
        // delete fieldData.customerType;
      }
      let birthday;
      if (fieldData.birthday) {
        birthday = moment(fieldData.birthday).format("MM/DD/YYYY");
      }
      const result = await FetchApi.updateInfoBooking({
        ...fieldData,
        birthday: birthday,
        address: `${nameCity} ${nameDistrict}`,
      });
      if (result.success) {
        queryClient.refetchQueries("getHistoryBookingManual");
        message.success(STRINGS.saved_common);
        refetchCalendarOrder();
        ModalCustomService.close();
        return;
      }
      if (result.code === 4007) {
        message.error(STRINGS.cannot_change_type);
        return;
      }
    } catch (error) {
      message.warning(STRINGS.please_fill_required_fields);
    }
  };
  let enabledItem = [
    { label: STRINGS.customer_type_new.D, value: "D" },
    { label: STRINGS.customer_type_new.A, value: "A" },
    { label: STRINGS.customer_type_new.S, value: "S" },
    { label: STRINGS.customer_type_new.P, value: "P" },
    { label: STRINGS.customer_type_new.R, value: "R" },
    { label: STRINGS.customer_type_new.X, value: "X" },
  ];
  const rulesPhoneAndName = [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        const regex = /^\s+$/;
        if (
          getFieldValue("customerType") === "P" ||
          data.customerType !== "R"
        ) {
          if (
            value === undefined ||
            value === null ||
            value.length === 0 ||
            regex.test(value)
          ) {
            return Promise.reject(STRINGS.this_field_is_required);
          } else {
            return Promise.resolve();
          }
        }
        return Promise.resolve();
      },
    }),
  ];
  const rulesFurigana = [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        const regex = /^\s+$/;
        const re = /^[ぁ-ん]+$/;
        if (
          getFieldValue("customerType") === "P" ||
          data.customerType !== "R"
        ) {
          if (
            value === undefined ||
            value === null ||
            value.length === 0 ||
            regex.test(value)
          ) {
            return Promise.reject(STRINGS.this_field_is_required);
          } else if (!re.test(value)) {
            return Promise.reject(STRINGS.please_no_space);
          } else {
            return Promise.resolve();
          }
        }
        return Promise.resolve();
      },
    }),
  ];

  if (data.customerType === "S" || data.customerType === "P") {
    enabledItem = [
      { label: STRINGS.customer_type_new.X, value: "X" },
      { label: STRINGS.customer_type_new.S, value: "S" },
      { label: STRINGS.customer_type_new.P, value: "P" },
    ];
  }
  if (data.customerType === "D" || data.customerType === "A") {
    enabledItem = [
      { label: STRINGS.customer_type_new.X, value: "X" },
      { label: STRINGS.customer_type_new.D, value: "D" },
      { label: STRINGS.customer_type_new.A, value: "A" },
    ];
  }
  if (data.customerType === "R") {
    enabledItem = [
      { label: STRINGS.customer_type_new.X, value: "S" },
      { label: STRINGS.customer_type_new.D, value: "P" },
      { label: STRINGS.customer_type_new.A, value: "R" },
      { label: STRINGS.customer_type_new.X, value: "X" },
    ];
  }

  return (
    <Form
      className="modal-infor-customer"
      layout="vertical"
      form={form}
      onValuesChange={(e) => {
        if (e.cityId) {
          const a = Province.filter((_item, _index) => {
            return _item.cityId == e.cityId;
          });
          setProvinceFilter(a);
          form.setFieldsValue({ districtId: null });
        }
      }}
      initialValues={{
        customerName: data.customerName,
        customerPhone: data.customerPhone,
        customerGender: data.customerGender,
        userId: data.userId,
        backgroundDisease: data.backgroundDisease,
        bookingId: data.id,
        customerType: data.banned ? "X" : data.customerType,
        furiganaName: data.furiganaName,
        cancelFee: data.cancelFee,
        birthday: moment(data.birthday, "MM/DD/YYYY").isValid()
          ? moment(data.birthday, "MM/DD/YYYY")
          : undefined,
      }}>
      <Form.Item
        name="customerName"
        label={renderLabel(STRINGS.name_user_booking)}
        rules={rulesPhoneAndName}>
        <Input
          disabled={
            data.customerType === LOGIC.userType.D ||
            data.customerType === LOGIC.userType.A
          }
        />
      </Form.Item>
      <Form.Item
        name="furiganaName"
        label={renderLabel(STRINGS.furigana_name)}
        rules={rulesFurigana}
        required>
        <Input
          disabled={
            (data.customerType === LOGIC.userType.D ||
              data.customerType === LOGIC.userType.A) &&
            !!data.furiganaName
          }
        />
      </Form.Item>
      <Form.Item
        name="customerPhone"
        label={renderLabel(STRINGS.phone_number)}
        rules={rulesPhoneAndName}
        required>
        <Input
          disabled={
            data.customerType === LOGIC.userType.D ||
            data.customerType === LOGIC.userType.A
          }
        />
      </Form.Item>
      <Form.Item
        name="customerGender"
        label={renderLabel(STRINGS.gender)}
        required
        rules={[Validate.emptyContent]}>
        <RadioCustom
          disabled={
            data.customerType === LOGIC.userType.D ||
            data.customerType === LOGIC.userType.A
          }
          list={[
            { label: STRINGS.male, value: "male" },
            { label: STRINGS.female, value: "female" },
          ]}
        />
      </Form.Item>
      <Form.Item name="birthday" label={renderLabel(STRINGS.birthday)}>
        <DatePicker
          value={data.birthday}
          placeholder={STRINGS.birthday}
          suffixIcon={Svgs.datepicker()}
          style={{
            borderRadius: 5,
            padding: "6px 10px",
            height: 40,
            width: 180,
          }}
          disabledDate={(e) => {
            let customDate = moment().format("MM/DD/YYYY");
            return e && e.isAfter(moment(customDate, "MM/DD/YYYY"));
          }}
          showToday={false}
          allowClear={false}
          format={"MM/DD/YYYY"}
          picker={"date"}
          disabled={
            data.customerType === LOGIC.userType.D ||
            data.customerType === LOGIC.userType.A
          }
        />
      </Form.Item>
      <Form.Item name="customerType" label={renderLabel(STRINGS.member)}>
        <RadioCustom
          disabled={data.customerType !== "X"}
          enabledItem={enabledItem}
          list={[
            { label: STRINGS.customer_type_new.D, value: "D" },
            { label: STRINGS.customer_type_new.A, value: "A" },
            { label: STRINGS.customer_type_new.S, value: "S" },
            { label: STRINGS.customer_type_new.P, value: "P" },
            { label: STRINGS.customer_type_new.R, value: "R" },
            { label: STRINGS.customer_type_new.X, value: "X" },
          ]}
          style={{ marginBottom: 10 }}
        />
      </Form.Item>
      <Form.Item
        name="cityId"
        label={renderLabel(STRINGS.city)}
        required
        initialValue={data.cityId}
        rules={[Validate.emptyContent]}>
        <Select
          suffixIcon={<SuffixIconDown />}
          placeholder={STRINGS.city}
          disabled={
            (data.customerType === LOGIC.userType.D ||
              data.customerType === LOGIC.userType.A) &&
            !!data.cityId
          }>
          {City.map((itm, idx) => {
            return (
              <Select.Option key={itm.id} value={itm.id}>
                {itm.name1}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name="districtId"
        label={renderLabel(STRINGS.province)}
        initialValue={data.districtId}>
        <Select
          suffixIcon={<SuffixIconDown />}
          placeholder={STRINGS.province}
          disabled={
            (data.customerType === LOGIC.userType.D ||
              data.customerType === LOGIC.userType.A) &&
            !!data.districtId
          }>
          {provinceFilter.map((itm, idx) => {
            return (
              <Select.Option key={itm.id} value={itm.id}>
                {itm.name1}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      {/* <Form.Item
        name="cancelFee"
        label={renderLabel(STRINGS.cancellation_charge)}
        rules={[Validate.isPositiveNumberWithoutRequired]}
      >
        <Input />
      </Form.Item> */}
      <Form.Item
        name="backgroundDisease"
        label={renderLabel(STRINGS.past_medical_history)}>
        <Input.TextArea rows={8} />
      </Form.Item>

      <Button
        onClick={() => ModalCustomService.close()}
        style={{
          borderRadius: 4,
          marginRight: 10,
          width: 120,
          borderColor: "grey",
          backgroundColor: "#E9E9E9",
        }}>
        {STRINGS.cancel}
      </Button>
      <Button
        onClick={onConfirm}
        style={{
          width: 120,
          borderRadius: 4,
          backgroundColor: COLORS.primary,
          borderColor: COLORS.primary,
          color: "white",
        }}>
        {STRINGS.sure}
      </Button>
    </Form>
  );
}

export default ModalInfoCustomer;
