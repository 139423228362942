import {
  UsergroupAddOutlined,
  ContainerOutlined,
  MoneyCollectOutlined,
  ContactsOutlined,
  // HistoryOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";

import Notification from "../../manager-notification/Notification";
import NotificationStaff from "../../manager-staff-notification/NotificationStaff";
import ManagerUser from "../../manager-user/ManagerUser";
import Coupon from "../../manger-coupon/Coupon";
import StoreActualSchedule from "../../store-actual-schedule/StoreActualSchedule";
import StoreFixedSchedule from "../../store-fixed-schedule/StoreFixedSchedule";
import HistoryStore from "../../store-history/HistoryStore";
import HistoryPointStore from "../../store-point-history/HistoryPointStore";
import PointReception from "../../store-point-reception/PointReception";
import StoreStaff from "../../store-staff/StoreStaff";
import StoreProvidePoint from "../../store-provide-point/StoreProvidePoint";
import StoreInfo from "../../store-store-info/StoreInfo";
import ComboTab from "../../combo/ComboTab";
import StaffChangeHistory from "../../staff-change-history/StaffChangeHistory";
import StoreStaffAccount from "../../store-staff-account/StoreStaffAccount";
// import ManagerMail from "../../manager-mail/ManagerMail";
import ManagerPointDay from "../../manager-point-day/ManagerPointDay";
import HistoryProfitStore from "../../store-profit-history/HistoryProfitStore";
import StaffNotGood from "../../staff-not-good/StaffNotGood";
import StaffCancelHistory from "../../staff-cancel-history/StaffCancelHistory";
import ChangeStaffBookingHistory from "../../change-staff-booking-history/ChangeStaffBookingHistory";

import { STRINGS, Svgs, COLORS } from "../../../constants";
import SetTheWorkOrder from "../../set-the-work-order/SetTheWorkOrder";

export const storeAdmin = [
  {
    component: ManagerUser,
    url: "/user",
  },
  {
    component: Coupon,
    url: "/coupon",
  },
  {
    component: Notification,
    url: "/notification",
  },
  {
    component: NotificationStaff,
    url: "/notification-staff",
  },
  { component: StoreActualSchedule, url: "/store-actual-schedule" },
  {
    component: StoreStaff,
    url: "/store-staff",
  },
  {
    component: StoreFixedSchedule,
    url: "/store-fixed-schedule",
  },
  {
    component: HistoryStore,
    url: "/history-store",
  },
  {
    component: HistoryPointStore,
    url: "/history-point-store",
  },
  {
    component: PointReception,
    url: "/",
  },
  {
    component: StoreProvidePoint,
    url: "/store-provide-point",
  },
  {
    component: StoreInfo,
    url: "/store",
  },
  {
    component: ComboTab,
    url: "/combo",
  },
  {
    component: StoreStaffAccount,
    url: "/store-staff-account",
  },
  {
    component: StaffChangeHistory,
    url: "/change-history",
  },
  // {
  //   component: ManagerMail,
  //   url: "/manager-mail",
  // },
  {
    component: ManagerPointDay,
    url: "/manager-point-date",
  },
  {
    component: HistoryProfitStore,
    url: "/history-profit-store",
  },
  { component: StaffNotGood, url: "/staff-not-good" },
  {
    component: StaffCancelHistory,
    url: "/cancel-booking-history",
  },
  {
    component: ChangeStaffBookingHistory,
    url: "/change-staff-booking-history",
  },
  {
    component: SetTheWorkOrder,
    url: "/set-the-work-order",
  },
];

export const menuStore = {
  point_reception: [
    {
      key: "pointReception",
      icon: Svgs.pointReception(),
      title: STRINGS.point_reception,
      linkTo: "/",
    },
  ],
  operational_menu_store: [
    {
      key: "storeInfo",
      icon: Svgs.store(),
      title: STRINGS.mypage_management,
      linkTo: "/store",
    },
    {
      key: "storeStaffAccount",
      icon: (
        <UsergroupAddOutlined
          style={{ fontSize: 22, color: COLORS.background }}
        />
      ),
      title: STRINGS.store_staff_account,
      linkTo: "/store-staff-account",
    },
    {
      key: "storeProfitHistory",
      icon: (
        <MoneyCollectOutlined
          style={{ fontSize: 22, color: COLORS.background }}
        />
      ),
      title: STRINGS.profit,
      linkTo: "/history-profit-store",
    },
  ],
  work_manager_menu_store: [
    {
      key: "storeFixedSchedule",
      icon: Svgs.registrationBlack,
      title: STRINGS.shift_list,
      linkTo: "/store-fixed-schedule",
    },
    {
      key: "storeActualSchedule",
      icon: Svgs.calendarTime,
      title: STRINGS.schedule_adjustment,
      linkTo: "/store-actual-schedule",
    },
  ],
  staff_manager_menu_store: [
    {
      key: "notificationStaff",
      icon: Svgs.notificationStaff,
      title: STRINGS.push_to_staff,
      linkTo: "/notification-staff",
    },
    {
      key: "staff",
      icon: Svgs.staff(),
      title: STRINGS.mng_staff_store_2,
      linkTo: "/store-staff",
    },
    {
      key: "staffNotGood",
      icon: Svgs.slideSetting(),
      title: STRINGS.NG_list,
      linkTo: "/staff-not-good",
    },
  ],
  customer_manager_menu_store: [
    {
      key: "user",
      icon: Svgs.user,
      title: STRINGS.user_management,
      linkTo: "/user",
    },
    {
      key: "notification",
      icon: Svgs.push(),
      title: STRINGS.notification_management,
      linkTo: "/notification",
    },
    {
      key: "storeProvidePoint",
      icon: <ContainerOutlined style={{ color: COLORS.background }} />,
      title: STRINGS.store_provide_point,
      linkTo: "/store-provide-point",
    },
    {
      key: "managerPointDay",
      icon: Svgs.pointReception(),
      title: STRINGS.config_the_point,
      linkTo: "/manager-point-date",
    },
  ],
  history_manager_menu_store: [
    {
      key: "historyStore",
      icon: Svgs.visitHistory(),
      title: STRINGS.history_go_store,
      linkTo: "/history-store",
    },
    {
      key: "historyPoint",
      icon: Svgs.visitHistory(),
      title: STRINGS.visit_history,
      linkTo: "/history-point-store",
    },
    {
      key: "changeWorkingHourHistory",
      icon: (
        <FileSyncOutlined style={{ fontSize: 20, color: COLORS.background }} />
      ),
      title: STRINGS.change_working_hour_history,
      linkTo: "/change-history",
    },
    // comment when deploy product
    // {
    //   key: "changeStaffHistory",
    //   icon: (
    //     <HistoryOutlined style={{ fontSize: 20, color: COLORS.background }} />
    //   ),
    //   title: STRINGS.change_staff_history,
    //   linkTo: "/change-staff-booking-history",
    // },
    {
      key: "cancelBookingHistory",
      icon: (
        <ContactsOutlined style={{ fontSize: 20, color: COLORS.background }} />
      ),
      title: STRINGS.cancel_booking_history,
      linkTo: "/cancel-booking-history",
    },
  ],
  course_manager_menu_store: [
    {
      key: "combo",
      icon: Svgs.combo(),
      title: STRINGS.course_setting,
      linkTo: "/combo",
    },
  ],
  relations_manager_menu_store: [
    {
      key: "coupon",
      icon: Svgs.coupon(),
      title: STRINGS.coupon_management,
      linkTo: "/coupon",
    },
    // {
    //   key: "managerMail",
    //   icon: Svgs.mail(),
    //   title: STRINGS.manager_mail,
    //   linkTo: "/manager-mail",
    // },
  ],
  set_the_work_order: [
    {
      key: "setTheWorkOrder",
      icon: Svgs.heart(),
      title: STRINGS.set_the_work_order,
      linkTo: "/set-the-work-order",
    },
  ],
};
