/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { Col, Form, Row, TimePicker } from "antd";
import { ClockCircleFilled } from "@ant-design/icons";
import moment from "moment";

import { STRINGS, LOGIC } from "../../../constants";
import { Validate } from "../../../elements";

import "./CouponForm.scss";

function CouponTime({ form, couponType, initialValue }) {
  const [rules, setRules] = useState([]);

  // useEffect(() => {
  //   if (couponType !== LOGIC.coupon_time.time) {
  //     form.setFieldsValue({ couponTypeStartTime: "" });
  //     form.setFieldsValue({ couponTypeEndTime: "" });
  //     setRules([]);
  //   } else {
  //     setRules([Validate.emptyContent]);
  //     form.validateFields(["couponTypeStartTime", "couponTypeEndTime"]);
  //   }
  // }, [couponType]);

  useCallback(() => {
    if (couponType === LOGIC.coupon_time.time) {
      setRules([Validate.emptyContent]);
      form.validateFields(["couponTypeStartTime", "couponTypeEndTime"]);
    } else {
      form.setFieldsValue({ couponTypeStartTime: "" });
      form.setFieldsValue({ couponTypeEndTime: "" });
      form.validateFields(["couponTypeStartTime", "couponTypeEndTime"]);

      setRules([]);
      form.resetFields();
    }
  }, [couponType]);

  const time = {
    startTime: initialValue?.split(";")[0]
      ? moment(initialValue?.split(";")[0], "HH:mm")
      : "",
    endTime: initialValue?.split(";")[1]
      ? moment(initialValue?.split(";")[1], "HH:mm")
      : "",
  };
  return (
    <Col style={{ paddingBottom: 10 }}>
      <Row
        style={{
          textAlign: "left",
          paddingTop: 14,
          fontSize: 15,
        }}
      >
        <Col span={12}>{STRINGS.coupon_time_start}</Col>
        <Col span={12}>{STRINGS.coupon_time_end}</Col>
      </Row>
      <Col>
        <Row
          style={{
            textAlign: "left",
            alignItems: "center",
            marginBottom: 6,
          }}
        >
          <Col span={12}>
            <Form.Item
              name="couponTypeStartTime"
              required
              initialValue={time.startTime ? moment(time.startTime) : ""}
              rules={rules}
            >
              <TimePicker
                suffixIcon={
                  <ClockCircleFilled
                    style={{ fontSize: 18, color: "#757575" }}
                  />
                }
                style={{ borderRadius: 4, padding: "6px 10px", width: "90%" }}
                allowClear={false}
                format={"HH:mm"}
                disabled={couponType !== LOGIC.coupon_time.time}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="couponTypeEndTime"
              required
              initialValue={time.endTime ? moment(time.endTime) : ""}
              rules={rules}
            >
              <TimePicker
                suffixIcon={
                  <ClockCircleFilled
                    style={{ fontSize: 18, color: "#757575" }}
                  />
                }
                style={{ borderRadius: 4, padding: "6px 10px", width: "90%" }}
                allowClear={false}
                format={"HH:mm"}
                disabled={couponType !== LOGIC.coupon_time.time}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Col>
  );
}

export { CouponTime };
