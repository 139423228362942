import React, { useRef, useState, useEffect, memo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Table, Space, message, Row, Image, Avatar } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import {
  AlertService,
  Container,
  ErrorView,
  FilterByStore,
  LoadingView,
  ListStaff,
  SvgView,
  ButtonCustom,
} from "../../elements";
import { FetchApi } from "../../api";
import { STRINGS, Svgs } from "../../constants";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";
import StaffManagementForm from "./items/StaffManagementForm";

import "./StaffManagement.scss";

const StaffManagement = memo(() => {
  const cache = useQueryClient();
  const formRef = useRef();
  const page = useRef(1);
  const size = useRef(10);
  const staffId = useRef();
  const [itemEditing, setItemEditing] = useState();
  const [listStore, setListStore] = useState([]);
  const storeSelected = useRef(null);
  const firstRunUseEffect = useRef(true);
  const [checkAdd, setcheckAdd] = useState(false);
  // const { listSkill } = useGetListSkill();

  const columns = [
    {
      title: STRINGS.avatar,
      dataIndex: "avatarUrl",
      key: "avatarUrl",
      render: (avatarUrl, data) => {
        return (
          <div style={{ position: "relative" }}>
            <Avatar
              size={60}
              src={
                avatarUrl ||
                "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/a52b7629-5ce3-466c-a369-2d20989c2b8b909e75ab-1350-4ab3-8f79-8f08d91af2ba27aaf8bd-7d13-428f-861a-b9bc2fc39d453fa70bf0-af81-4d9b-83df-874ae1ef8b8c.png"
              }
            />

            <div
              style={{
                top: 0,
                position: "absolute",
                right: 15,
                backgroundColor: "white",
                borderRadius: "100%",
                overflow: "hidden",
                cursor: "pointer",
              }}
            >
              <Image src={data?.rank?.image} width={25} height={25} />
            </div>
          </div>
        );
      },
      width: 120,
      fixed: "left",
    },
    {
      title: STRINGS.staff_id,
      dataIndex: "id",
      key: "id",
      render: (id) => id,
      width: 120,
    },
    {
      title: STRINGS.staff_name_2,
      dataIndex: "name",
      key: "name",
    },
    {
      title: STRINGS.gender,
      dataIndex: "sex",
      key: "sex",
      render: (e) => (e === "male" ? STRINGS.male : !e ? "" : STRINGS.female),
      width: 80,
    },
    {
      title: STRINGS.phone_number_2,
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      width: 160,
    },
    // {
    //   title: STRINGS.birthday,
    //   key: "birthday",
    //   dataIndex: "birthday",
    // },
    {
      title: STRINGS.email,
      key: "email",
      dataIndex: "email",
    },
    {
      title: STRINGS.store_1,
      dataIndex: "storeIds",
      key: "storeIds",
      render: (storeIds) => {
        const newList = [...listStore];
        newList.shift();
        const index = newList.findIndex((item) => item.id == storeIds?.[0]);
        return newList[index]?.name;
        // return (
        //   (listStore.find((item) => {
        //     if (storeId == null) {
        //       return 0;
        //     }
        //     return item.id == storeId;
        //   }) &&
        //     listStore.find((item) => {
        //       return item.id == storeId;
        //     }).name) ||
        //   ""
        // );
      },
    },
    {
      title: STRINGS.store_2,
      dataIndex: "secondStoreId",
      key: "secondStoreId",
      render: (a, e) => {
        const newList = [...listStore];
        newList.shift();
        const index = newList.findIndex((item) => item.id == e.storeIds?.[1]);
        return newList[index]?.name;
      },
    },
    {
      title: STRINGS.store_3,
      dataIndex: "thirdStoreId",
      key: "thirdStoreId",
      render: (a, e) => {
        const newList = [...listStore];
        newList.shift();
        const index = newList.findIndex((item) => item.id == e.storeIds?.[2]);
        return newList[index]?.name;
      },
    },
    {
      title: STRINGS.store_4,
      dataIndex: "fourthStoreId",
      key: "fourthStoreId",
      render: (a, e) => {
        const newList = [...listStore];
        newList.shift();
        const index = newList.findIndex((item) => item.id == e.storeIds?.[3]);
        return newList[index]?.name;
      },
    },
    // {
    //   title: STRINGS.average_score,
    //   key: "ratingAverage",
    //   dataIndex: "ratingAverage",
    //   render: (number, i) => {
    //     if (newData?.rating) {
    //       const dataRate = newData?.rating?.find((item) => {
    //         return Object.keys(item)[0] == i.id;
    //       });
    //       if (dataRate)
    //         return (
    //           <div>
    //             {Object.values(dataRate)[0]}{" "}
    //             {<StarFilled style={{ color: COLORS.primary_4 }} />}
    //           </div>
    //         );
    //       else return;
    //     }
    //     // if (number == null || number == undefined) {
    //     //   return "";
    //     // }
    //     // return (
    //     //   <div>
    //     //     {number} {<StarFilled style={{ color: COLORS.primary_4 }} />}
    //     //   </div>
    //     // );
    //   },
    // },
    // {
    //   title: STRINGS.procedure_range,
    //   key: "comboPacks",
    //   dataIndex: "comboPacks",
    //   render: (e, i) => {
    //     // if (Array.isArray(e) && e.length > 0) {
    //     //   return e.map(
    //     //     (item, index) =>
    //     //       `${item.comboName} (${item.minutes})` +
    //     //       (index !== e.length - 1 ? ", " : "")
    //     //   );
    //     // }
    //     // return "";
    //     if (newData?.packs) {
    //       const data = newData?.packs?.find((item, idx) => {
    //         return Object.keys(item)[0] == i.id;
    //       });
    //       if (data) {
    //         return Object.values(data)[0]?.map((item, index) => {
    //           return (
    //             <Typography key={item.id}>
    //               {`${item.comboName} (${item.minutes})` +
    //                 (index !== Object.values(data)[0]?.length - 1 ? ", " : "")}
    //             </Typography>
    //           );
    //         });
    //       } else return;
    //     }
    //   },
    // },
    // {
    //   title: STRINGS.procedure_range,
    //   key: "skills",
    //   dataIndex: "skills",
    //   render: (e) => {
    //     if (Array.isArray(e) && e.length > 0) {
    //       return e.map(
    //         (item, index) =>
    //           (item ? item.name : "") + (index !== e.length - 1 ? ", " : "")
    //       );
    //     }
    //     return "";
    //   },
    // },
    {
      title: STRINGS.self_introduction,
      key: "selfIntroduction",
      dataIndex: "selfIntroduction",
    },
    {
      key: "action",
      render: (text, record) => {
        if (record.deleted) {
          return null;
        }
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onClickEdit(record)}
            >
              <SvgView svgData={Svgs.edit} />
            </button>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onRemove(record)}
            >
              <SvgView svgData={Svgs.delete} />
            </button>
          </Space>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];

  const { data, refetch, status } = useQuery(["all-staff-management"], () => {
    return FetchApi.getListStaff(
      storeSelected.current,
      page.current,
      size.current,
      staffId.current
    );
  });

  useScrollByMouse(data);

  // const getRatingandComboPacksStaff = async (staffId) => {
  //   const dataRating = await FetchApi.getRatingStaff(staffId);
  //   const dataPacks = await FetchApi.getPacksStaff(staffId);
  //   if ((dataRating && dataPacks) || (dataRating === 0 && dataPacks)) {
  //     setNewData({
  //       packs: [...newData.packs, { [staffId]: dataPacks }],
  //       rating: [...newData.rating, { [staffId]: dataRating }],
  //     });
  //   }
  // };

  useEffect(() => {
    if (firstRunUseEffect.current) {
      const fetchStore = async () => {
        let result = await FetchApi.getAllStoreFilter(1, 50);
        if (result.total) {
          setListStore([{ name: STRINGS.all, id: null }, ...result.data]);
        }
      };
      fetchStore();
      firstRunUseEffect.current = false;
      return;
    }
    formRef.current.show();
  }, [itemEditing]);

  const onRemove = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${item.name}${STRINGS.confirm_delete_staff}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteStaff(item.id);
        if (result.success) {
          cache.refetchQueries("all-staff-management");
          message.success(STRINGS.delete_success);
          return;
        }
        message.warning(STRINGS.delete_failed);
      },
    });
  };

  const onClickEdit = (item) => () => {
    setItemEditing({ ...item });
    setcheckAdd(false);
  };

  const onCreate = () => {
    setItemEditing({});
    setcheckAdd(true);
  };

  const onConfirm = (item) => {
    cache.refetchQueries("all-staff-management");
  };

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Container
      className="manager-staff"
      renderLeft={() => (
        <Row>
          <FilterByStore
            onSearchByStore={(e) => {
              storeSelected.current = e === "null" ? null : e;
              page.current = 1;
              refetch();
            }}
            listStore={listStore}
          />
          <ListStaff
            storeSelected={storeSelected.current}
            onChange={(e) => {
              staffId.current = e;
              page.current = 1;
              refetch();
            }}
          />
        </Row>
      )}
      renderRight={() => (
        <div className="button-add-staff">
          <ButtonCustom onClick={onCreate} icon={<PlusCircleOutlined />}>
            {STRINGS.add_new}
          </ButtonCustom>
        </div>
      )}
    >
      <div className="content-staff">
        {status === "loading" ? (
          <LoadingView />
        ) : (
          <>
            <Table
              rowKey={"id"}
              columns={columns}
              dataSource={data ? data.data : []}
              locale={{ emptyText: STRINGS.no_data }}
              pagination={false}
              scroll={{ x: "110%", y: "calc(100vh - 320px)" }}
            />
            <PaginationWithSizeChange
              size={size.current}
              total={data ? data.total : 0}
              onChange={(pageNumber) => {
                page.current = pageNumber;
                refetch();
              }}
              onShowSizeChange={(e) => {
                size.current = Number(e.size);
                page.current = 1;
                refetch();
              }}
              current={page.current}
            />
          </>
        )}
      </div>
      <StaffManagementForm
        ref={formRef}
        data={itemEditing}
        onConfirm={onConfirm}
        listStore={listStore}
        refetch={refetch}
        checkAdd={checkAdd}
      />
    </Container>
  );
});

export default StaffManagement;
