import React from "react";
import { CaretDownOutlined } from "@ant-design/icons";

import { COLORS } from "../constants";

const SuffixIconDown = () => {
  return <CaretDownOutlined style={{ color: COLORS.border_6 }} />;
};

export { SuffixIconDown };
