// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".point-day-master {\n  padding-bottom: 40px;\n  padding-top: 10px;\n  padding-left: 0px !important;\n}\n\n.modal-add-point-day .title-time {\n  font-weight: bold;\n  margin-top: 26px;\n  font-size: 16px;\n}\n.modal-add-point-day .start-time {\n  margin-top: 15px;\n}\n.modal-add-point-day .start-time .title {\n  margin-bottom: 5px;\n}\n.modal-add-point-day .start-time .ant-picker {\n  width: 100%;\n}\n.modal-add-point-day .end-time {\n  margin-top: 15px;\n}\n.modal-add-point-day .end-time .title {\n  margin-bottom: 5px;\n}\n.modal-add-point-day .end-time .ant-picker {\n  width: 100%;\n}\n.modal-add-point-day .end-time .ant-tag {\n  margin: 0px 4px 0px 0px;\n}", "",{"version":3,"sources":["webpack://./src/components/manager-point-day/ManagerPointDay.scss"],"names":[],"mappings":"AAGA;EACE,oBAAA;EACA,iBAAA;EACA,4BAAA;AAQF;;AAJE;EACE,iBAAA;EACA,gBAAA;EACA,eAAA;AAOJ;AALE;EACE,gBAAA;AAOJ;AANI;EACE,kBAAA;AAQN;AANI;EACE,WAAA;AAQN;AALE;EACE,gBAAA;AAOJ;AANI;EACE,kBAAA;AAQN;AANI;EACE,WAAA;AAQN;AANI;EACE,uBAAA;AAQN","sourcesContent":["@import \"../../util/variables.scss\";\n@import \"../../util/mixins.scss\";\n\n.point-day-master {\n  padding-bottom: 40px;\n  padding-top: 10px;\n  padding-left: 0px !important;\n}\n\n.modal-add-point-day {\n  .title-time {\n    font-weight: bold;\n    margin-top: 26px;\n    font-size: 16px;\n  }\n  .start-time {\n    margin-top: 15px;\n    .title {\n      margin-bottom: 5px;\n    }\n    .ant-picker {\n      width: 100%;\n    }\n  }\n  .end-time {\n    margin-top: 15px;\n    .title {\n      margin-bottom: 5px;\n    }\n    .ant-picker {\n      width: 100%;\n    }\n    .ant-tag {\n      margin: 0px 4px 0px 0px\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainColor": "#4b2a05",
	"textColor": "#f19834",
	"redColor": "#FF0C0C",
	"grey1": "#efefef",
	"grey2": "#757575",
	"black1": "#303030",
	"borderColor": "#cbcbcb"
};
export default ___CSS_LOADER_EXPORT___;
