/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { Col, DatePicker, Row } from "antd";
import moment from "moment";

import { FetchApi } from "../../../api";
import { LOGIC } from "../../../constants/Strings";
import Auth from "../../../store/Authentication";
import { Svgs } from "../../../constants";

function HeaderSchedule({ selectedDate, setSelectedDate }) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const { data, refetch } = useQuery(`bookingComing-${userAdmin.storeId}`, () =>
    FetchApi.getBookingComing({
      storeId: userAdmin.storeId,
    })
  );

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      const now = moment();
      const minute = now.minutes();
      const second = now.seconds();
      if (!(minute % 10) && second == 30) {
        refetch();
      }
    }, 1000);

    return () => {
      refetchInterval && clearInterval(refetchInterval);
    };
  }, []);

  let dataPositionNull = [];
  let dataFiltered = (data?.staffStatuses || []).filter((item) => {
    if (!item.position) {
      dataPositionNull.push(item);
    }
    if (item.status && item.position) {
      return true;
    }
  });

  dataFiltered.sort((a, b) => a.position - b.position);
  dataFiltered = [...dataFiltered, ...dataPositionNull];

  if (dataFiltered.length < 6) {
    for (let i = dataFiltered.length; i < 6; i++) {
      dataFiltered.push({});
    }
  }

  return (
    <Row style={{ backgroundColor: "white", alignItems: "center" }}>
      <Col
        style={{
          padding: "14px 18px",
          fontSize: 26,
          fontWeight: "bold",
          color: "#4b2a05",
        }}>
        {/* {`${time.format("MM月DD日")} (${
          LOGIC.day[time.isoWeekday() || 7]
        }) ${time.format("HH:mm")}`} */}
        <DatePicker
          defaultValue={selectedDate}
          suffixIcon={Svgs.datepicker({ width: 19, height: 21 })}
          style={{
            width: 250,
            borderRadius: 4,
            padding: "6px 10px",
            marginRight: 20,
          }}
          allowClear={false}
          onChange={(e) => setSelectedDate(moment(e))}
          format={(e) => {
            return `${moment(e).format(LOGIC.dateFormat)} (${
              LOGIC.day[moment(e).isoWeekday() || 7]
            })`;
          }}
          picker={"date"}
        />
      </Col>
    </Row>
  );
}

export default HeaderSchedule;
