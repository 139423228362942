/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "antd";
import { SwapOutlined, EditOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import moment from "moment";

import { FetchApi } from "../../../api";
import { COLORS, LOGIC, STRINGS } from "../../../constants";
import { ScheduleBookingService } from "../../../services";
import { ModalCustomService, Validate } from "../../../elements";
import PaginationWithSizeChange from "../../../elements/PaginationWithSizeChange";
import ModalInfoCustomer from "../items/ModalInfoCustomer";
import ModalBookingDetail from "../items/ModalBookingDetail";
import ModalChangeUserBooking from "../../modal-change-user-booking/ModalChangeUserBooking";

function InfoCustomer({ label, value, styleValue, styleWrapper }) {
  const renderValue = () => {
    if (Array.isArray(value)) {
      return value.map((item, index) => (
        <span
          key={`${index}`}
          style={{
            fontWeight: "bold",
            color: COLORS.accent,
            fontSize: 20,
            ...styleValue,
          }}>
          {item}
          {index !== value.length - 1 ? ",\xa0" : ""}
        </span>
      ));
    }
    return (
      <Col
        style={{
          fontWeight: "bold",
          color: COLORS.accent,
          fontSize: 20,
          ...styleValue,
        }}>
        {value}
      </Col>
    );
  };
  return (
    <Row
      style={{
        textAlign: "left",
        marginTop: 4,
        alignItems: "center",
        ...styleWrapper,
      }}>
      <Col style={{ width: 100, color: COLORS.accent, fontSize: 20 }}>
        {label}
      </Col>
      {renderValue()}
    </Row>
  );
}

export default function ModalBookingDetailTableRight({
  userId,
  setNG,
  data,
  NG,
  refetch,
  disabled,
  customerId,
}) {
  const [pagination, setPagination] = useState({ size: 10, page: 1 });
  const { data: dataNG } = useQuery(
    [
      `TableRight-ModalBookingDetail-${pagination.size}-${pagination.page}-${userId}`,
    ],
    () =>
      FetchApi.historyUserBooking({
        page: pagination.page,
        size: pagination.size,
        userId: userId,
      }),
    {
      enabled: !!userId,
    }
  );

  useEffect(() => {
    if (dataNG?.staffNotGood?.length > 0) {
      setNG(dataNG?.staffNotGood);
    }
  }, [dataNG]);

  const renderFee = (item) => {
    const status = item.status;
    let cancelFeeDes = STRINGS.cancel_fee[status];
    if (!item.cancelFee) {
      cancelFeeDes = STRINGS.cancel_fee.cancel_accompany(0);
    }
    if (status.toString().includes("cancel")) {
      if (status === "cancel_no_contact") {
        return (
          <Col style={{ color: COLORS.required }}>
            {STRINGS.cancel_no_contact_fee(item.cancelFee || 0)}
          </Col>
        );
      }
      return <Col style={{ color: COLORS.required }}>{cancelFeeDes}</Col>;
    }
  };
  const renderComment = (item) => {
    if (
      item.commentOfStaff ||
      item.status.toString().includes("cancel") ||
      item.status.toString().includes("done")
    ) {
      return (
        <Row style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Col
            style={{
              color: COLORS.required,
              textAlign: "center",
              marginRight: 14,
              fontWeight: "bold",
            }}
            span={3}>
            {item.status.toString().includes("cancel") ? "X" : ""}
          </Col>
          <Col span={20} style={{ marginTop: 10 }}>
            <Row style={{ width: "100%" }}>
              <Col span={4} style={{ fontWeight: "900", fontSize: 15 }}>
                {STRINGS.remarks} :
              </Col>
              <Col span={20}>
                {!!item.commentOfStaff && item.commentOfStaff}
              </Col>
            </Row>
            {renderFee(item)}
          </Col>
        </Row>
      );
    }
  };
  const renderStaffSelected = (item) => {
    if (item.chooseStaff) {
      return (
        <Col
          style={{
            marginTop: 5,
            width: 14,
            height: 14,
            left: "40%",
            borderRadius: 7,
            borderWidth: 1,
            borderColor: COLORS.primary,
            borderStyle: "solid",
          }}
        />
      );
    }
    if (item.assignedSex) {
      return (
        <Col
          style={{
            fontWeight: "bold",
            color: COLORS.gender_staff[item.assignedSex],
            fontSize: 18,
          }}>
          {STRINGS.staff_gender[item.assignedSex]}
        </Col>
      );
    }
    return null;
  };
  const onAfterCloseModal = () => {
    const { bookingDetail, refetchCalendarOrder } =
      ScheduleBookingService.get();
    ModalCustomService.set({
      title: STRINGS.detail_reservation,
      titleStyle: {
        fontSize: 25,
      },
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },

      content: (
        <ModalBookingDetail
          refetch={refetchCalendarOrder}
          data={bookingDetail}
        />
      ),
    });
  };
  return (
    <Col className="table-right">
      <Row>
        <Col span={12} style={{ fontWeight: "bold", color: COLORS.sub_accent }}>
          {[
            { label: STRINGS.id_user, value: customerId },
            { label: STRINGS.name_user_booking, value: data.customerName },
            {
              label: STRINGS.furigana_name,
              value: data.furiganaName,
            },
            { label: STRINGS.gender, value: STRINGS[data.customerGender] },
            {
              label: STRINGS.member,
              value: data.banned
                ? STRINGS.customer_type_new.X
                : STRINGS.customer_type_new[data.customerType] || "ー",
              styleValue: {
                color: data.banned
                  ? LOGIC.colorCustomerType.X
                  : LOGIC.colorCustomerType[data.customerType],
              },
            },
            {
              label: STRINGS.registration_date,
              value: data.registrationDate
                ? moment(data.registrationDate).format("YYYY.MM.DD")
                : "",
            },
            {
              label: STRINGS.favourite_store,
              value: data.storeFollow,
            },
            {
              label: STRINGS.NG_designation,
              value: NG.length > 0 ? NG : "",
            },
            {
              label: STRINGS.phone_number,
              value: data.customerPhone
                ? Validate.convertPhoneJp(data.customerPhone)
                : "",
            },
            {
              label: STRINGS.birthday,
              value: moment(data.birthday).isValid()
                ? `${moment(data.birthday).format(
                    "YYYY.MM.DD"
                  )}(${moment().diff(moment(data.birthday), "years")})`
                : "",
            },
            {
              label: STRINGS.address,
              value: data.address,
            },
          ].map((item) => (
            <InfoCustomer
              key={item.label}
              label={item.label}
              value={item.value || "ー"}
              styleValue={item.styleValue}
              styleWrapper={item.styleWrapper}
            />
          ))}
        </Col>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <Button
                className="bottom-btn"
                style={{
                  width: "100%",
                  height: "fit-content",
                  justifyContent: "center",
                  backgroundColor: "#4f7a28",
                }}
                onClick={() => {
                  ModalCustomService.set({
                    title: STRINGS.edit_information_customer,
                    wrapperStyle: { width: "auto" },
                    contentWrapperStyle: { width: "fit-content" },
                    contentStyle: { maxHeight: "80vh", padding: 0 },
                    content: (
                      <ModalInfoCustomer
                        data={data}
                        refetchCalendarOrder={refetch}
                      />
                    ),
                  });
                }}>
                <EditOutlined className="icon" style={{ fontSize: 30 }} />
                <Row
                  style={{
                    fontSize: 23,
                    fontWeight: "bold",
                    letterSpacing: 3,
                  }}>
                  {data.customerType === "R" && !data.banned
                    ? STRINGS.registration
                    : STRINGS.edit}
                </Row>
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="bottom-btn"
                style={{
                  height: "fit-content",
                  justifyContent: "center",
                  backgroundColor: disabled ? COLORS.disabled : "#FE6C06",
                  width: "100%",
                }}
                disabled={disabled}
                onClick={() => {
                  ModalCustomService.set({
                    title: STRINGS.detail_reservation,
                    titleStyle: { fontSize: 25 },
                    wrapperStyle: { width: "auto" },
                    contentWrapperStyle: { width: "fit-content" },
                    contentStyle: { maxHeight: "80vh", padding: 0 },
                    onAfterClose: onAfterCloseModal,
                    content: (
                      <ModalChangeUserBooking
                        refetch={refetch}
                        bookingId={data.id}
                      />
                    ),
                  });
                }}>
                <SwapOutlined className="icon" style={{ fontSize: 30 }} />
                <Row
                  style={{
                    fontSize: 23,
                    fontWeight: "bold",
                    letterSpacing: 3,
                  }}>
                  {STRINGS.change}
                </Row>
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: 18,
              fontSize: 18,
              fontWeight: "bold",
              whiteSpace: "pre-line",
              justifyContent: "center",
              color: COLORS.sub_accent,
            }}>
            {STRINGS.info_background}
          </Row>
          <Col
            style={{
              paddingBottom: 20,
              border: `1px solid ${COLORS.sub_text}`,
              borderRadius: 5,
              minHeight: 150,
              paddingTop: 15,
            }}>
            <div
              style={{
                whiteSpace: "pre-line",
                fontWeight: "bold",
                color: COLORS.sub_accent,
              }}>
              {data.backgroundDisease}
            </div>
          </Col>
        </Col>
      </Row>

      <Row
        style={{
          fontSize: 40,
          letterSpacing: 8,
          fontWeight: "bold",
          justifyContent: "center",
          marginTop: 10,
          color: COLORS.sub_accent,
        }}>
        {STRINGS.history_go_store}
      </Row>
      <Row
        className="theader"
        style={{ marginTop: 20, color: COLORS.sub_accent }}>
        <Col span={3} style={{ fontSize: 18 }}>
          {STRINGS.name2}
        </Col>
        <Col span={6} style={{ fontSize: 18 }}>
          {STRINGS.date2}
        </Col>
        <Col span={5} style={{ fontSize: 18 }}>
          {STRINGS.store_management}
        </Col>
        <Col span={5} style={{ fontSize: 18 }}>
          {STRINGS.responsible_person}
        </Col>
        <Col span={5} style={{ fontSize: 18 }}>
          {STRINGS.course}
        </Col>
      </Row>
      {(dataNG?.orderHistoryDTOS || []).map((item, index) => {
        return (
          <Col
            key={`${index}`}
            className="row-table-custom"
            style={{
              paddingTop: 10,
              borderBottom: "1px solid #b4bbc4",
              color: COLORS.sub_accent,
              fontWeight: "bold",
            }}>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <Col span={3}>{renderStaffSelected(item)}</Col>
              <Col span={6} style={{ alignSelf: "center" }}>
                {moment(item.startTime).format(LOGIC.dateFormat)}
              </Col>
              <Col span={5}>{item.storeName}</Col>
              <Col span={5}>{item.staffName}</Col>
              <Col span={5}>
                {Array.isArray(item.comboOrders) && item.comboOrders.length > 0
                  ? item.comboOrders.map(
                      (itm, idx, arr) =>
                        `${itm.comboName || "none"} (${
                          itm.minutes || "none"
                        })` + (idx !== arr.length - 1 ? ", " : "")
                    )
                  : ""}
              </Col>
            </Row>
            <Row style={{ paddingBottom: 10, textAlign: "left" }}>
              {renderComment(item)}
            </Row>
          </Col>
        );
      })}

      <PaginationWithSizeChange
        styles={{ color: COLORS.sub_accent }}
        size={pagination.size}
        total={dataNG?.pageDto?.totalElements || 0}
        onChange={(pageNumber) => {
          setPagination({ size: pagination.size, page: pageNumber });
        }}
        onShowSizeChange={(e) => {
          setPagination({ size: e.size, page: 1 });
        }}
        current={pagination.page}
      />
    </Col>
  );
}
