/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Table } from "antd";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useQuery } from "react-query";

import { FetchApi } from "../../../api";
import { STRINGS, LOGIC } from "../../../constants/Strings";
import { BlockUI, Convert, ErrorView, LoadingView } from "../../../elements";
import PaginationWithSizeChange from "../../../elements/PaginationWithSizeChange";
import "../../../util/variables.scss";
import { PointReceptionService } from "../services/PointReceptionService";
import { useScrollByMouse } from "../../../util/hooks/useScrollByMouse";
import { COLORS } from "../../../constants";

const HistoryPoints = () => {
  const blockUI = useRef();
  const page = useRef(1);
  const size = useRef(10);

  const { data, refetch, isLoading } = useQuery(["HistoryPoints"], () =>
    FetchApi.getHistoryPointByStore({
      size: size.current,
      page: page.current,
    })
  );
  useScrollByMouse(data);

  useEffect(() => {
    PointReceptionService.onChange("getHistoryPointByStore", () => refetch());
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "customerId",
      key: "customerId",
      align: "center",
    },
    {
      title: STRINGS.user_name,
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      render: (e) => Convert.removeSpaceName(e),
    },
    {
      title: STRINGS.staff_in_charge,
      dataIndex: "staffName",
      key: "staffName",
      align: "center",
    },
    {
      title: STRINGS.time_of_visit,
      dataIndex: "timeVisit",
      key: "timeVisit",
      render: (timeVisit) => moment(timeVisit).format(LOGIC.timeFormat),
      align: "center",
    },
    {
      title: STRINGS.type,
      dataIndex: "pointType",
      key: "pointType",
      render: (pointType) => {
        if (pointType === "check_in_point") {
          return (
            <div style={{ color: COLORS.accent }}>{STRINGS.grant_points}</div>
          );
        }
        return (
          <div style={{ color: COLORS.primary }}>{STRINGS.use_points}</div>
        );
      },
      align: "center",
    },
    {
      title: STRINGS.points,
      dataIndex: "numberOfPoints",
      key: "numberOfPoints",
      align: "center",
    },
    {
      title: STRINGS.allocation_date,
      dataIndex: "timeConfirm",
      key: "timeConfirm",
      align: "center",
      render: (timeConfirm) => moment(timeConfirm).format(LOGIC.timeFormat),
    },
    {
      dataIndex: "pointStatus",
      key: "pointStatus",
      align: "center",
      render: (pointStatus) => (pointStatus === "cancel" ? STRINGS.reject : ""),
    },
  ];

  if (isLoading) {
    return <LoadingView />;
  }

  if (!data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Col>
      <BlockUI ref={blockUI} />
      <Table
        rowKey={(record) => record.id + record.pointType}
        columns={columns}
        dataSource={data?.pointRequestDTOS}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
        scroll={{ x: 1200, y: "calc(100vh - 350px)" }}
      />
      <PaginationWithSizeChange
        size={size.current}
        total={data?.pageDto?.totalElements || 0}
        onChange={(pageNumber) => {
          page.current = pageNumber;
          refetch();
        }}
        onShowSizeChange={(e) => {
          size.current = e.size;
          page.current = 1;
          refetch();
        }}
        current={page.current}
      />
    </Col>
  );
};

export default HistoryPoints;
