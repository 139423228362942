import React, { useState } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  message,
  DatePicker,
  Radio,
  Checkbox,
} from "antd";
import { useQueryClient } from "react-query";
import moment from "moment";

import { STRINGS, LOGIC, Svgs, COLORS } from "../../../constants";
import {
  ButtonCustom,
  ModalCustomService,
  LoadingAbsolute,
  Validate,
} from "../../../elements";
import { CommonFunction } from "../../../util/";
import { FetchApi } from "../../../api";
import { PointDayConfig } from "./PointDayConfig";
import { CouponApplyCombo } from "../../manger-coupon/items/CouponApplyCombo";

import "../ManagerPointDay.scss";

export function AddPointDay({ setData }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const cache = useQueryClient();

  const validateEndTime = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (getFieldValue("type") !== LOGIC.booking_point.date) {
        if (!value) {
          return Promise.reject(STRINGS.please_select_end_date);
        }
        if (moment(value, "DD/MM/YYYY HH:mm").isBefore(moment())) {
          return Promise.reject(STRINGS.end_later_start);
        }
        if (
          moment(getFieldValue("start")).isBefore(
            moment(value, "DD/MM/YYYY HH:mm")
          )
        ) {
          return Promise.resolve();
        }
        return Promise.reject(STRINGS.end_later_start2);
      }
      return Promise.resolve();
    },
  });
  const validateStartTime = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (getFieldValue("type") !== LOGIC.booking_point.date) {
        if (!value) {
          return Promise.reject(STRINGS.please_select_end_date);
        }
        return Promise.resolve();
      }
      return Promise.resolve();
    },
  });
  const onAdd = async () => {
    try {
      await form.validateFields();

      const fieldData = form.getFieldValue();
      console.log("fieldData", fieldData);

      if (fieldData.type === LOGIC.booking_point.rangeWithTime) {
        let isSame = false;
        // validate time duplicate or time inside another time
        const isTimeNotValid = fieldData.timeFrames.some((item, index) => {
          if (moment(item.endTime).isSame(moment(item.startTime))) {
            isSame = true;
            return true;
          }
          const filterItemCurrent = fieldData.timeFrames.filter(
            (itm, idx) => index !== idx
          );
          const res = filterItemCurrent.some((itm, idx) => {
            if (moment(itm.endTime).isBefore(itm.startTime)) {
              return (
                moment(item.startTime).isSameOrAfter(moment(itm.startTime)) &&
                moment(item.startTime).isBefore(
                  moment(itm.endTime).add(1, "days")
                )
              );
            }
            return (
              moment(item.startTime).isSameOrAfter(moment(itm.startTime)) &&
              moment(item.startTime).isBefore(moment(itm.endTime))
            );
          });
          return res;
        });
        if (isSame) {
          message.warning(STRINGS.endtime_greater_than_starttime);
          return;
        }
        if (isTimeNotValid) {
          message.warning(STRINGS.shift_duplicated);
          return;
        }
      }
      let timeFrames = [];
      if (fieldData.type === LOGIC.booking_point.rangeWithTime) {
        timeFrames = fieldData.timeFrames?.map?.((i) => {
          return {
            startHour: moment(i.startTime).format("HH:mm"),
            endHour: moment(i.endTime).format("HH:mm"),
          };
        });
      }
      setLoading(true);
      const result = await FetchApi.addBookingPoint({
        ...fieldData,
        timeFrames: timeFrames,
        type: fieldData.type || LOGIC.booking_point.range,
        courseIds: fieldData.courseIdApply,
      });
      setLoading(false);
      if (result.success) {
        ModalCustomService.close();
        cache.refetchQueries("bookingPointList");
        message.success(STRINGS.registered_common);
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return (
    <Col className="modal-add-point-day">
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          timeFrames: [
            {
              startTime: moment(),
              endTime: moment().add(1, "hours"),
            },
          ],
          courseIdApply: [],
        }}>
        <Form.Item
          style={{ marginBottom: 10 }}
          required
          name="title"
          label={STRINGS.title}
          rules={[Validate.emptyContent]}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 10 }}
          required
          name="quantity"
          label={STRINGS.point_have_provided}
          rules={[Validate.isNumberPointPrice]}>
          <Input />
        </Form.Item>
        <CouponApplyCombo form={form} />
        <Form.Item
          label={STRINGS.type_grant}
          style={{
            marginBottom: 10,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          // required
          name="provideType"
          // rules={[Validate.emptyContent]}
        >
          <Radio.Group size="large" style={{ marginBottom: 8 }}>
            <Radio value="AUTO"> 自動 </Radio>
            <Radio value="MANUAL"> 手動 </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="nonAddWhenUsingPoint" valuePropName="checked">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Checkbox />
            <div style={{ marginLeft: 10, color: COLORS.border_7 }}>
              利用時除外
            </div>
          </div>
        </Form.Item>

        <div>
          <div className="title-time">{STRINGS.config_start_time}</div>
          <div className="start-time">
            <Form.Item
              name="start"
              required
              rules={[validateStartTime]}
              label={STRINGS.start_date}>
              <DatePicker
                style={{ width: "100%" }}
                placeholder={STRINGS.start_date}
                suffixIcon={Svgs.datepicker()}
                format={LOGIC.timeFormat}
                showTime
              />
            </Form.Item>
          </div>
          <div className="end-time">
            <Form.Item
              name="end"
              required
              rules={[validateEndTime]}
              label={STRINGS.end_date}
              initialValue={null}>
              <DatePicker
                style={{ width: "100%" }}
                placeholder={STRINGS.end_date}
                suffixIcon={Svgs.datepicker()}
                format={LOGIC.timeFormat}
                showTime
              />
            </Form.Item>
          </div>
        </div>
        <PointDayConfig form={form} />
      </Form>
      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onAdd}>{STRINGS.save}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}
