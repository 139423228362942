import React, { useState } from "react";
import { Form, Row } from "antd";
import { useNavigate } from "react-router-dom";

import { ButtonCustom } from "../../../elements";
import { COLORS, STRINGS } from "../../../constants";

import "../StaffBooking.scss";

function StaffBookingBottomSubmit({ form, onPressNextStep }) {
  const [isEnabled, setIsEnabled] = useState(true);
  const [currentStep, setCurrentStep] = useState(
    form.getFieldValue("bookingStep")
  );
  const navigate = useNavigate();
  let backgroundColor = COLORS.primary;
  if (!isEnabled) {
    backgroundColor = COLORS.disabled;
  }
  return (
    <Form.Item
      name={"bottomArea"}
      shouldUpdate={(prevValues, curValues) => {
        setCurrentStep(curValues.bookingStep);
        if (
          curValues.bookingStep === 1 &&
          !curValues.bookingSuggestTime
          // ||
          // !re.test(curValues.bookingPhoneNumber1)
        ) {
          setIsEnabled(false);
          return;
        }
        setIsEnabled(true);
      }}>
      <Row style={{ justifyContent: "space-between" }}>
        <ButtonCustom
          onClick={() => {
            navigate(-1);
          }}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <Row style={{ justifyContent: "flex-end" }}>
          <ButtonCustom
            onClick={() => {
              const currentStep = form.getFieldValue("bookingStep");
              if (currentStep === 1) {
                form.setFieldsValue({
                  bookingStep: currentStep - 1,
                  bookingPhoneNumber1: null,
                  bookingUserName1: "",
                  bookingInfoUser: "",
                  bookingSuggestTime: null,
                });
                return;
              }
              if (currentStep === 2) {
                form.setFieldsValue({
                  bookingPhoneNumber1: null,
                  bookingUserName1: "",
                  bookingUserName2: "",
                  bookingUserName3: "",
                  bookingUserName4: "",
                  bookingInfoUser: "",
                  bookingStep: currentStep - 1,
                });
                return;
              }
              if (currentStep > 0) {
                form.setFieldsValue({ bookingStep: currentStep - 1 });
                return;
              }

              navigate(-1);
            }}
            style={{
              backgroundColor: COLORS.sub_secondary,
              borderColor: COLORS.secondary,
              marginRight: 10,
            }}>
            {STRINGS.back}
          </ButtonCustom>
          <ButtonCustom
            disabled={!isEnabled}
            onClick={onPressNextStep}
            style={{
              backgroundColor,
              borderStyle: "none",
            }}>
            {currentStep === 2 ? STRINGS.sure : STRINGS.next}
          </ButtonCustom>
        </Row>
      </Row>
    </Form.Item>
  );
}

export { StaffBookingBottomSubmit };
