import React from "react";
import { Button } from "antd";

import { COLORS } from "../constants";

const ButtonCustom = ({
  className,
  style,
  onClick,
  disabled,
  children,
  isCancelButton,
  ...props
}) => {
  let disabledStyle;
  let commonStyle = {
    backgroundColor: COLORS.primary,
    borderColor: COLORS.primary,
    color: COLORS.background,
  };
  if (disabled) {
    disabledStyle = {
      backgroundColor: COLORS.disabled,
      borderColor: COLORS.disabled,
    };
  }
  if (isCancelButton) {
    commonStyle = {
      backgroundColor: COLORS.sub_border,
      borderColor: COLORS.sub_disabled,
      color: COLORS.text,
    };
  }
  return (
    <Button
      className={className}
      size={"large"}
      style={{
        minWidth: 120,
        borderRadius: 5,
        fontSize: 18,
        ...commonStyle,
        ...style,
        ...disabledStyle,
      }}
      disabled={disabled}
      onClick={() => {
        onClick && onClick();
      }}
      {...props}>
      {children}
    </Button>
  );
};

export { ButtonCustom };
