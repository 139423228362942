// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".histore-store .ant-table-cell {\n  text-align: -webkit-center;\n}", "",{"version":3,"sources":["webpack://./src/components/store-history/HistoryStore.scss"],"names":[],"mappings":"AAGE;EACE,0BAAA;AAQJ","sourcesContent":["@import \"../../util/variables.scss\";\n\n.histore-store {\n  .ant-table-cell {\n    text-align: -webkit-center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainColor": "#4b2a05",
	"textColor": "#f19834",
	"redColor": "#FF0C0C",
	"grey1": "#efefef",
	"grey2": "#757575",
	"black1": "#303030",
	"borderColor": "#cbcbcb"
};
export default ___CSS_LOADER_EXPORT___;
