import React from "react";
import { Col } from "antd";
import { useQuery } from "react-query";

import { FetchApi } from "../../../api";
import { COLORS } from "../../../constants";
import { ErrorView, LoadingView } from "../../../elements";
import {
  StaffBookingSelectWorker,
  StaffBookingTotalPrice,
  // StaffBookingNote,
} from ".";
import { StaffBookingComboFav } from "./StaffBookingComboFav";
import { StaffBookingComboAll } from "./StaffBookingComboAll";
import { StaffBookingComboStore } from "./StaffBookingComboStore";
import Auth from "../../../store/Authentication";

import "../StaffBooking.scss";

// function StaffBookingComboMinute({ form, data = [], numberOrdinal }) {
//   const [selectedMin, setSelectedMin] = useState(
//     form.getFieldValue(`bookingMinute${numberOrdinal}`)
//   );
//   return (
//     <Form.Item
//       name={`bookingMinute${numberOrdinal}`}
//       shouldUpdate={(prevValues, curValues) => {
//         const comboStore = curValues[`bookingComboStore${numberOrdinal}`];
//         if (
//           curValues[`bookingComboFav${numberOrdinal}`] ||
//           curValues[`bookingComboAll${numberOrdinal}`] ||
//           (comboStore && Object.keys(comboStore).length !== 0)
//         ) {
//           setSelectedMin();
//           return;
//         }
//         setSelectedMin(curValues[`bookingMinute${numberOrdinal}`]);
//       }}>
//       <Row>
//         {data.map((item) => {
//           let backgroundColor = COLORS.background;
//           let color = COLORS.border;
//           if (item === selectedMin) {
//             color = COLORS.background;
//             backgroundColor = COLORS.primary;
//           }
//           return (
//             <Row
//               key={`${item}`}
//               onClick={() => {
//                 if (item === selectedMin) {
//                   setSelectedMin();
//                   form.setFieldsValue({
//                     [`bookingMinute${numberOrdinal}`]: null,
//                   });
//                   return;
//                 }
//                 setSelectedMin(item);

//                 form.setFieldsValue({
//                   [`bookingComboFav${numberOrdinal}`]: null,
//                   [`bookingComboAll${numberOrdinal}`]: null,
//                   [`bookingComboStore${numberOrdinal}`]: null,
//                   [`bookingMinute${numberOrdinal}`]: item,
//                 });
//               }}
//               style={{
//                 border: `1px solid ${color}`,
//                 borderRadius: "5px",
//                 marginRight: "10px",
//                 marginBottom: "10px",
//                 placeContent: "center",
//                 padding: 5,
//                 width: 100,
//                 cursor: "pointer",
//                 color,
//                 backgroundColor,
//               }}>
//               {item}
//               {STRINGS.minute}
//             </Row>
//           );
//         })}
//       </Row>
//     </Form.Item>
//   );
// }

function StaffBookingCourse({ form, numberOrdinal, style }) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const infoStore = userAdmin.storeDTO;
  const userInfo = form.getFieldValue("userInfo");

  const { data, isLoading } = useQuery(
    `info-order-${infoStore.id}-${userInfo?.id}`,
    () => FetchApi.orderInfo({ userId: userInfo?.id })
  );
  if (isLoading) {
    return <LoadingView />;
  }

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }
  return (
    <Col style={{ paddingTop: "20px", ...style }}>
      <Col
        style={{
          borderBottom: `1px solid ${COLORS.border}`,
          paddingBottom: "20px",
        }}>
        {/* <Row className="text-booking">{STRINGS.combo_minute}</Row> */}

        {/* <StaffBookingComboMinute
          form={form}
          data={data?.orderInfoDTO?.comboMinutes}
          numberOrdinal={numberOrdinal}
        /> */}

        <StaffBookingComboFav
          form={form}
          data={data?.orderInfoDTO?.comboFavourite}
          numberOrdinal={numberOrdinal}
        />

        <StaffBookingComboAll
          form={form}
          data={data?.orderInfoDTO?.comboAll}
          numberOrdinal={numberOrdinal}
        />

        <StaffBookingComboStore
          form={form}
          data={data?.orderInfoDTO?.comboStore}
          numberOrdinal={numberOrdinal}
        />

        <StaffBookingTotalPrice form={form} numberOrdinal={numberOrdinal} />

        {/* <StaffBookingNote
          form={form}
          numberOrdinal={numberOrdinal}
          data={data?.orderInfoDTO?.note}
        /> */}

        <StaffBookingSelectWorker form={form} numberOrdinal={numberOrdinal} />
      </Col>
    </Col>
  );
}

export { StaffBookingCourse };
