import React, { useState } from "react";
import { Tabs } from "antd";

import { COLORS, STRINGS } from "../../constants";
import { Container } from "../../elements/Container";
import Combo from "./tabs/combo/Combo";
import ConfigPrice from "./tabs/price/ConfigPrice";

import "../../util/variables.scss";

const ComboTab = () => {
  const [tab, setTab] = useState("combo");

  const statMenu = [
    {
      key: "combo",
      title: STRINGS.course_setting,
      component: <Combo />,
      display: true,
    },
    {
      key: "price",
      title: STRINGS.course_price_setting,
      component: <ConfigPrice />,
      display: true,
    },
  ];

  return (
    <Container>
      <div className="btn-choose-type">
        <Tabs activeKey={tab} onChange={setTab} type="card">
          {statMenu.map((item, index) => {
            if (!item.display) {
              return null;
            }
            return (
              <Tabs.TabPane
                tabKey={"" + item.key}
                key={"" + item.key}
                style={{ width: "100%" }}
                tab={
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "initial",
                      backgroundColor:
                        item.key === tab ? COLORS.accent : "white",
                      padding: "14px 0px",
                      textAlign: "center",
                    }}>
                    {item.title}
                  </div>
                }>
                {item.component}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
    </Container>
  );
};

export default ComboTab;
