/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Form, message } from "antd";

import { FetchApi } from "../../../api";
import { ModalCustomService } from "../../../elements";
import { STRINGS } from "../../../constants";
import { CommonFunction } from "../../../util/CommonFunction";

import SlideOption from "./SlideOption";

import "../SlideSetting.scss";

const Add = ({ data, setData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onAdd = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      if (new Date(fieldData.startTime) >= new Date(fieldData.endTime)) {
        message.warning(STRINGS.endtime_greater_than_starttime);
        return;
      }
      setLoading(true);
      const result = await FetchApi.createSlide(fieldData);
      setLoading(false);
      if (result.success) {
        setData();
        message.success(STRINGS.registered_common);
        ModalCustomService.close();
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return <SlideOption onClick={onAdd} loading={loading} form={form} />;
};

export default Add;
