import Auth from "../../../store/Authentication";
import { useQuery } from "react-query";
import { FetchApi } from "../../../api";
import { staffScheduleKey } from "./staffScheduleKey";

export const useGetDataHeader = () => {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const {
    data: dataHeader,
    refetch: refetchHeader,
    isFetching: isFetchingHeader,
  } = useQuery({
    queryFn: () =>
      FetchApi.getBookingComing({
        storeId: userAdmin.storeId,
      }),
    queryKey: [
      staffScheduleKey.paidBooking,
      staffScheduleKey.bookingComing,
      userAdmin.storeId,
    ],
  });
  let dataPositionNull = [];
  let dataFiltered = (dataHeader?.staffStatuses || []).filter((item) => {
    if (!item.position) {
      dataPositionNull.push(item);
    }
    if (item.status && item.position) {
      return true;
    }
  });

  dataFiltered.sort((a, b) => a.position - b.position);
  dataFiltered = [...dataFiltered, ...dataPositionNull];

  if (dataFiltered.length < 6) {
    for (let i = dataFiltered.length; i < 6; i++) {
      dataFiltered.push({});
    }
  }
  return { refetchHeader, isFetchingHeader, dataFiltered, dataHeader };
};
