// import { useQuery } from "react-query";

// import { FetchApi } from "../../../api";

// function useQueryListAllStaff({ storeId }) {
//   console.log(storeId);
//   const value = useQuery(
//     `all-staff-list-booking-${storeId}`,
//     () =>
//       FetchApi.getStaffByNotComboPacks({
//         storeId: storeId,
//       }),
//     {
//       enabled: !!storeId,
//     }
//   );
//   return value;
// }
// export { useQueryListAllStaff };
