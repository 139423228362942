/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { Row, Table, Tooltip } from "antd";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { FetchApi } from "../../api";
import { COLORS, STRINGS } from "../../constants";
import {
  ErrorView,
  Container,
  FilterByStore,
  FilterByTime,
} from "../../elements";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";

import "./PointHistory.scss";

const { Column, ColumnGroup } = Table;

const PointHistory = () => {
  const navigate = useNavigate();
  const pagination = { size: 100, page: 1 };
  const [storeId, setStoreId] = useState(null);
  const [year, setYear] = useState(new Date().getFullYear());
  const { data, isLoading } = useQuery(
    [`PointHistory-${year}-${pagination.size}-${pagination.page}`],
    () =>
      FetchApi.getHistoryPointStore({
        year: year,
        page: pagination.page,
        size: pagination.size,
      })
  );
  useScrollByMouse(data);

  let convertedData = [];

  if (data?.pointStoreDTOList) {
    data.pointStoreDTOList.map((item, index) => {
      const newItem = {};
      newItem.storeId = item.storeId;
      newItem.storeName = item.storeName;
      item.pointStoreMonths.forEach((itm, idx) => {
        newItem["totalPointCheckIn" + itm.month] = itm.totalPointCheckIn;
        newItem["totalPointGacha" + itm.month] = itm.totalPointGacha;
        newItem["totalPointUse" + itm.month] = itm.totalPointUse;
        newItem["totalPointReview" + itm.month] = itm.totalPointReview;
      });
      convertedData.push(newItem);
    });
  }

  // const mapPointStoreMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
  //   (item, index) => {
  //     return {
  //       title: item + "/" + year,
  //       dataIndex: `pointStoreMonths${item}`,
  //       key: `pointStoreMonths${item}`,
  //       children: [
  //         {
  //           title: STRINGS.grant,
  //           dataIndex: `totalPointCheckIn${item}`,
  //           key: "totalPointCheckIn${item}",
  //         },
  //         {
  //           title: STRINGS.point_use,
  //           dataIndex: `totalPointUse${item}`,
  //           key: `totalPointUse${item}`,
  //         },
  //         {
  //           title: STRINGS.lottery,
  //           dataIndex: `totalPointGacha${item}`,
  //           key: `totalPointGacha${item}`,
  //         },
  //       ],
  //     };
  //   }
  // );

  // const columns = [
  //   {
  //     title: STRINGS.store_management,
  //     dataIndex: "storeName",
  //     key: "storeName",
  //     width: 200,
  //   },
  //   ...mapPointStoreMonths,
  // ];

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  if (storeId && storeId !== "null") {
    convertedData = convertedData.filter(
      (item, index) => item.storeId == storeId
    );
  }

  return (
    <Container
      className="point-history-wrapper"
      childrenStyle={{ paddingBottom: 30 }}
      renderLeft={() => (
        <Row>
          <FilterByStore
            className="filter-store"
            style={{ width: 200 }}
            onSearchByStore={setStoreId}
          />
          <FilterByTime
            onChange={(e) => setYear(moment(e).year())}
            allowClear={false}
            picker={"year"}
          />
        </Row>
      )}>
      <Table
        loading={isLoading}
        dataSource={convertedData || []}
        rowKey={"storeId"}
        bordered
        size="large"
        locale={{ emptyText: STRINGS.no_data }}
        scroll={{ x: 3400, y: "calc(100vh - 320px)" }}
        pagination={false}>
        <Column
          fixed="left"
          title={
            <div style={{ color: COLORS.sub_black, fontSize: 24 }}>
              {STRINGS.store_management}
            </div>
          }
          dataIndex="storeName"
          key="storeName"
          width={200}
          render={(e, o) => (
            <div
              style={{ color: "#4B2A05", fontSize: 15, fontWeight: "bold" }}
              onClick={() => navigate(`/history-store/${o.storeId}`)}>
              {e}
            </div>
          )}
        />
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item, index) => (
          <ColumnGroup title={item + "/" + year} dataIndex key={`${index}`}>
            <Column
              title={STRINGS.grant}
              dataIndex={`totalPointCheckIn${item}`}
              align="center"
              key={`totalPointCheckIn${item}`}
              render={(e) => (
                <Tooltip title={e || 0}>
                  <div className="number-of-lines-2">{e || 0}</div>
                </Tooltip>
              )}
            />
            <Column
              title={STRINGS.point_use}
              dataIndex={`totalPointUse${item}`}
              align="center"
              key={`totalPointUse${item}`}
              render={(e) => (
                <Tooltip title={e || 0}>
                  <div className="number-of-lines-2">{e || 0}</div>
                </Tooltip>
              )}
            />
            <Column
              title={STRINGS.lottery}
              dataIndex={`totalPointGacha${item}`}
              align="center"
              key={`totalPointGacha${item}`}
              render={(e) => (
                <Tooltip title={e || 0}>
                  <div className="number-of-lines-2">{e || 0}</div>
                </Tooltip>
              )}
            />
          </ColumnGroup>
        ))}
      </Table>
    </Container>
  );
};

export default PointHistory;
