import React, { useState, useRef, useEffect, memo } from "react";
import { Table, Input, AutoComplete, Row, message } from "antd";
import { SearchOutlined, StarFilled } from "@ant-design/icons";
import { useQuery } from "react-query";
import moment from "moment";

import { FetchApi } from "../../api";
import { COLORS, STRINGS, Svgs } from "../../constants";
import {
  Container,
  FilterByStore,
  LoadingView,
  ListStaff,
  AlertService,
  ButtonCustom,
  Convert,
  Validate,
  SvgView,
} from "../../elements";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import { CommonFunction } from "../../util/CommonFunction";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";

import "../manager-review/Review.scss";

const ReviewPhase2 = memo(() => {
  const [options] = useState([]);
  const [storeOptions, setStoreOptions] = useState([]);
  const storeSelected = useRef(null);
  const staffId = useRef(null);
  const dataFilter = useRef("");
  const page = useRef(1);
  const size = useRef(10);
  const timeout = useRef();
  useScrollByMouse(storeOptions);

  useEffect(() => {
    const fetchStore = async () => {
      let result = await FetchApi.getAllStoreFilter(1, 50);
      if (result.total) {
        setStoreOptions([{ name: STRINGS.all, id: null }, ...result.data]);
      }
    };
    fetchStore();
  }, []);

  const columns = [
    {
      title: STRINGS.member_id,
      width: 150,
      dataIndex: "customerId",
      key: "customerId",
      fixed: "left",
    },
    {
      title: STRINGS.staff_name_2,
      width: 160,
      dataIndex: "staffName",
      key: "staffName",
    },
    {
      title: STRINGS.customer_name,
      width: 160,
      dataIndex: "customerName",
      key: "customerName",
      render: (e) => Convert.removeSpaceName(e),
    },
    {
      title: STRINGS.store,
      width: 160,
      dataIndex: "storeId",
      key: "storeId",
      render: (storeId) => {
        return (
          <div>
            {storeId !== null
              ? storeOptions.filter((store) => store.id == storeId)[0]
                ? storeOptions.filter((store) => store.id == storeId)[0].name
                : ""
              : STRINGS.all}
          </div>
        );
      },
    },
    {
      title: STRINGS.date_remarks,
      width: 170,
      dataIndex: "dateReview",
      key: "dateReview",
      render: (dateReview) =>
        dateReview ? (
          <div>{moment(dateReview).format(CommonFunction.dateFormat)}</div>
        ) : null,
    },
    {
      title: STRINGS.date_service,
      width: 170,
      dataIndex: "dateVisit",
      key: "dateVisit",
      render: (dateVisit) =>
        dateVisit ? (
          <div>{moment(dateVisit).format(CommonFunction.dateFormat)}</div>
        ) : null,
    },
    {
      title: STRINGS.remarks,
      dataIndex: "comment",
      key: "comment",
      render: (e) => e?.trim(),
      width: 500,
    },
    {
      title: STRINGS.rating1,
      dataIndex: "rating1",
      key: "rating1",
      width: 150,
      render: (rating) => itemStar(rating),
    },
    {
      title: STRINGS.rating2,
      dataIndex: "rating2",
      key: "rating2",
      width: 150,
      render: (rating) => itemStar(rating),
    },
    {
      title: STRINGS.rating3,
      dataIndex: "rating3",
      key: "rating3",
      width: 150,
      render: (rating) => itemStar(rating),
    },
    {
      title: STRINGS.rating4,
      dataIndex: "rating4",
      key: "rating4",
      width: 150,
      render: (rating) => itemStar(rating),
    },
    {
      title: STRINGS.rating5,
      dataIndex: "rating5",
      key: "rating5",
      width: 150,
      render: (rating) => itemStar(rating),
    },
    {
      title: STRINGS.rating6,
      width: 150,
      dataIndex: "rating6",
      key: "rating6",
      render: (rating) => itemStar(rating),
    },
    {
      title: STRINGS.average_score,
      dataIndex: "ratingAverage",
      key: "ratingAverage",
      render: (rating) => itemStar(rating),
      width: 100,
    },
    {
      key: "action",
      render: (text, record) => {
        return (
          <button
            style={{
              border: 0,
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            onClick={removeItem(record)}>
            <SvgView svgData={Svgs.delete} />
          </button>
        );
      },
      fixed: "right",
      width: 60,
    },
  ];

  const { data, refetch, status, isFetching } = useQuery(
    [`all-reviews`],
    () => {
      return FetchApi.getAllReview({
        page: page.current,
        size: size.current,
        name: dataFilter.current,
        storeId: storeSelected.current,
        staffId: staffId.current,
      });
    }
  );

  const removeItem = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${STRINGS.confirm_delete_common}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteReview(item.id);
        if (result.success) {
          refetch();
          message.success(STRINGS.deleted_common);
        } else {
          CommonFunction.logError(result);
        }
      },
    });
  };

  const onSearch = (searchText) => {
    const converted = Validate.removeSpecialCharactors(searchText);
    dataFilter.current = converted;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      page.current = 1;
      refetch();
    }, 800);
  };

  const onSearchByStore = (e) => {
    if (e == "null") {
      storeSelected.current = null;
    } else {
      storeSelected.current = e;
    }
    page.current = 1;
    refetch();
  };

  const itemStar = (number) => {
    if (number == null || number == undefined) {
      return "";
    }
    return (
      <div>
        {number || 0} {<StarFilled style={{ color: COLORS.primary_4 }} />}
      </div>
    );
  };

  return (
    <Container
      className="manager-review"
      renderLeft={() => (
        <Row style={{ alignItems: "flex-end" }}>
          <FilterByStore
            listStore={storeOptions}
            onSearchByStore={onSearchByStore}
          />
          <ListStaff
            style={{ marginRight: 10 }}
            storeSelected={storeSelected.current}
            onChange={(e) => {
              staffId.current = e;
              page.current = 1;
              refetch();
            }}
          />
          <ButtonCustom
            onClick={() =>
              FetchApi.downloadReviews({
                nameSearch: dataFilter.current,
                storeId: storeSelected.current,
                staffId: staffId.current,
              })
            }>
            {STRINGS.download}
          </ButtonCustom>
        </Row>
      )}
      renderRight={() => (
        <AutoComplete options={options} onSearch={onSearch}>
          <Input
            placeholder={STRINGS.search_by_staff}
            style={{ width: 300, marginTop: 10 }}
            prefix={<SearchOutlined />}
            onPressEnter={(e) => onSearch(e.target.value)}
          />
        </AutoComplete>
      )}
      styleHeader={{ alignItems: "flex-end" }}>
      <div className="content-review">
        {status === "loading" ? (
          <LoadingView />
        ) : (
          <>
            <Table
              loading={isFetching}
              rowKey="id"
              columns={columns}
              dataSource={data ? data.data : []}
              locale={{ emptyText: STRINGS.no_data }}
              scroll={{ x: 2150, y: "calc(100vh - 320px)" }}
              pagination={false}
            />
            <PaginationWithSizeChange
              size={size.current}
              total={data ? data.total : 0}
              onChange={(pageNumber) => {
                page.current = pageNumber;
                refetch();
              }}
              onShowSizeChange={(e) => {
                size.current = Number(e.size);
                page.current = 1;
                refetch();
              }}
              current={page.current}
            />
          </>
        )}
      </div>
    </Container>
  );
});

export default ReviewPhase2;
