/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, memo } from "react";
import { Upload, message, Input, Form } from "antd";
import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";

import {
  ButtonCustom,
  FormInput,
  SvgView,
  Validate,
  useForceUpdate,
} from "../../../elements";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util/CommonFunction";
import { COLORS, STRINGS, Svgs } from "../../../constants";
import Quill from "../../../elements/Quill";

import "./NewsForm.scss";

const NewsForm = memo((props) => {
  const [loadingImg, setLoadingImg] = useState(false);
  const form = useRef();
  const [formContent] = Form.useForm();

  const imageUrl = useRef("");
  const time = useRef("");
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    if (props.data) {
      imageUrl.current = props.data.imageUrl;
      time.current = props.data.time;
      formContent.resetFields();

      forceUpdate();
    }
  }, [props.data]);
  const handleUpImage = async (file) => {
    setLoadingImg(true);
    let result = await FetchApi.uploadFile(file);
    if (result?.fileUrl) {
      imageUrl.current = result.fileUrl;
      setLoadingImg(false);
    } else {
      message.warning(STRINGS.upload_img_failed);
      setLoadingImg(false);
    }
  };

  const uploadButton = (
    <div>
      {loadingImg ? (
        <LoadingOutlined />
      ) : (
        <div>
          <PlusCircleOutlined
            style={{ color: COLORS.primary_3, fontSize: 32 }}
          />
          <div>{STRINGS.upload}</div>
        </div>
      )}
    </div>
  );

  const removeImg = () => {
    imageUrl.current = "";
    forceUpdate();
  };

  const onConfirm = async () => {
    try {
      await formContent.validateFields();
      const dataToServer = formContent.getFieldValue();
      let item = { ...props.data, ...dataToServer };
      if (Object.keys(props.data).length < 1) {
        item.imageUrl = imageUrl.current;
        item.time = new Date();
        form.current.setLoading(true);
        const result = await FetchApi.createNews(item);
        if (result.success) {
          item.id = result.news.id;
          props.onConfirm && props.onConfirm(item);
          message.success(STRINGS.create_news_success);
        } else {
          message.warning(result.message || STRINGS.something_wrong);
          form.current.setLoading(false);
        }
      } else {
        item.imageUrl = imageUrl.current;
        item.id = props.data.id;
        form.current.setLoading(true);
        const result = await FetchApi.updateNews(item);
        if (result.success) {
          props.onConfirm && props.onConfirm(item);
          message.success(STRINGS.update_news_success);
        } else {
          message.warning(result.message || STRINGS.something_wrong);
          form.current.setLoading(false);
        }
      }
    } catch (e) {
      message.warning(STRINGS.please_fill_required_fields);
    }
  };
  const dataForm = { ...props.data };
  return (
    <FormInput
      title={props.data && props.data.title ? STRINGS.update : STRINGS.add_new}
      ref={form}
      renderButton={false}
      width={"72%"}
      isOpen={true}
      onClose={props.disableModal}>
      <Form
        form={formContent}
        labelCol={{ span: 100 }}
        wrapperCol={{ span: 100 }}
        layout="vertical">
        <div className="news-wrapper-container">
          <div className="content">
            <div className="upload-image">
              <div className="title">{STRINGS.image}</div>
              {imageUrl.current && (
                <button
                  className="btn-remove"
                  onClick={removeImg}
                  style={{ paddingTop: 5, cursor: "pointer" }}>
                  <SvgView svgData={Svgs.delete} />
                </button>
              )}
              {/* <ImgCrop
                modalTitle={STRINGS.editImageCrop}
                modalOk={STRINGS.ok}
                modalCancel={STRINGS.cancel}
                aspect={3 / 2}> */}
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={CommonFunction.beforeUpload}
                customRequest={handleUpImage}>
                {imageUrl.current ? (
                  <img
                    src={imageUrl.current}
                    alt="img"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {/* </ImgCrop> */}
            </div>

            <div className="inputRight">
              <Form.Item
                label={STRINGS.title}
                name="title"
                required
                rules={[Validate.emptyContent]}
                initialValue={dataForm.title}>
                <Input />
              </Form.Item>
              <Form.Item
                label={STRINGS.short_description}
                name="description"
                required
                rules={[Validate.emptyContent]}
                initialValue={dataForm.description}>
                <Input.TextArea />
              </Form.Item>
              <div>
                <Form.Item
                  label={STRINGS.content}
                  name="content"
                  initialValue={dataForm.content}>
                  <Quill />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="button-wrapper-news">
            <ButtonCustom
              isCancelButton
              style={{
                marginRight: 10,
              }}
              onClick={() => form.current.hide()}>
              {STRINGS.cancel}
            </ButtonCustom>
            <Form.Item style={{ margin: 0 }}>
              <ButtonCustom onClick={onConfirm}>
                {props.data?.title ? STRINGS.update : STRINGS.save}
              </ButtonCustom>
            </Form.Item>
          </div>
        </div>
      </Form>
    </FormInput>
  );
});

export default NewsForm;
