import React from "react";
import { Col, Row } from "antd";
import moment from "moment";

import { COLORS, STRINGS } from "../../../../constants";
import { CommonFunction } from "../../../../util/CommonFunction";

export default function BodyTopCheckedOut({ data }) {
  const convertData = (data, addon) => {
    if (data) {
      if (addon) {
        return addon + data + `\xa0${STRINGS.minute}`;
      }
      return data + `\xa0${STRINGS.minute}`;
    }
    return "ー";
  };
  const columns1 = [
    {
      label: STRINGS.working_hours,
      value: (
        <>
          {data?.workShiftDTOS?.map?.((item) => {
            return (
              <Col
                className="value"
                style={{ marginBottom: 0 }}
                key={`${item.scheduleId}`}>
                {moment(item.startTime).format("HH:mm")}~
                {moment(item.endTime).format("HH:mm")}
              </Col>
            );
          })}
        </>
      ),
    },
    {
      label: STRINGS.attendance_time,
      value: (
        <>
          {data?.workShiftDTOS?.map?.((item) => {
            return (
              <Col
                className="value"
                style={{ marginBottom: 0 }}
                key={`${item.scheduleId}`}>
                {item.checkInTime
                  ? moment(item.checkInTime).format("HH:mm:ss")
                  : "ー"}
              </Col>
            );
          })}
        </>
      ),
    },
    {
      label: STRINGS.leave_time,
      value: (
        <>
          {data?.workShiftDTOS?.map?.((item) => {
            return (
              <Col
                className="value"
                style={{ marginBottom: 0 }}
                key={`${item.scheduleId}`}>
                {item.checkOutTime
                  ? moment(item.checkOutTime).format("HH:mm:ss")
                  : "ー"}
              </Col>
            );
          })}
        </>
      ),
    },
  ];
  const columns = [
    {
      label: STRINGS.work_performance,
      labelStyle: { width: 100 },
      value: data?.performance
        ? CommonFunction.roundPercent(data.performance) + "%"
        : "ー",
      colWrapper: 5,
      valueStyles: {
        color: data?.performance ? COLORS.primary : COLORS.accent,
      },
    },
    {
      label: STRINGS.acutal_work_time,
      colWrapper: 5,
      labelStyle: { width: 100 },
      value: convertData(data?.actualWorkingMinutes),
    },
    {
      label: STRINGS.minutes_late_and_rest_early,
      colWrapper: 8,
      labelStyle: { width: 220 },
      value: convertData(data?.arriveLateLeaveEarlyMinutes),
    },
    {
      label: STRINGS.not_enough_time,
      colWrapper: 6,
      value: convertData(data?.missingMinutes),
      labelStyle: { width: 100 },
      valueStyles: { color: "red" },
    },
    {
      label: STRINGS.bonus_minute,
      value: convertData(data?.bonusMinutesDayOff, "+"),
      labelStyle: { width: 100 },
      colWrapper: 5,
      valueStyles: {
        color: data?.bonusMinutesDayOff ? COLORS.primary : COLORS.accent,
      },
    },
    {
      label: STRINGS.rank_up_bonus,
      value: convertData(data?.bonusMinutesRankUp, "+"),
      labelStyle: { width: 100 },
      colWrapper: 5,
      valueStyles: {
        color: data?.bonusMinutesRankUp ? COLORS.primary : COLORS.accent,
      },
    },
    {
      label: STRINGS.time_rest_extra,
      labelStyle: { width: 220 },
      colWrapper: 8,
      value: convertData(data?.totalRestMinutesExtra),
    },
    {
      label: STRINGS.actual_wait_time,
      labelStyle: { width: 100 },
      colWrapper: 6,
      value: convertData(data?.actualMinutesWorking),
    },
  ];

  return (
    <Col className="body-top">
      <Row>
        {columns1.map((item) => {
          return (
            <Col xl={8} key={`${item.label}`} style={{ marginBottom: 7 }}>
              <Row align="middle">
                <Col className="label-base">{item.label}</Col>
                <Col className="value-base" style={item.valueStyles}>
                  {item.value}
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
      <Row>
        {columns.map((item) => {
          return (
            <Col
              xl={item.colWrapper || 6}
              style={{ marginBottom: 7 }}
              key={`${item.label}`}>
              <Row align="middle">
                <Col style={item.labelStyle} className="label-base">
                  {item.label}
                </Col>
                <Col className="value-base" style={item.valueStyles}>
                  {item.value}
                </Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Col>
  );
}
