/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { Col, Form } from "antd";
import moment from "moment";

import { LOGIC } from "../../../constants";
import { MultipleDateSelect, Validate } from "../../../elements";

import "./CouponForm.scss";

function CouponDate({ form, couponType, initialValue }) {
  const initialValueConvert = Array.isArray(initialValue.exactDays)
    ? initialValue.exactDays.map((i) => moment(i).valueOf())
    : [];
  const [rules, setRules] = useState([]);

  useCallback(() => {
    if (couponType === LOGIC.coupon_time.date) {
      form.validateFields(["exactDays"]);
      setRules([Validate.emptyContent]);
    } else {
      setRules([]);
      form.resetFields();
    }
  }, [couponType]);
  return (
    <Col style={{ paddingTop: 14 }}>
      <Form.Item
        name="exactDays"
        initialValue={initialValueConvert}
        rules={rules}>
        <MultipleDateSelect
          disabled={couponType !== LOGIC.coupon_time.date}
          selectProps={{ style: { width: "360px" } }}
          isReset={couponType !== LOGIC.coupon_time.date}
        />
      </Form.Item>
    </Col>
  );
}

export { CouponDate };
