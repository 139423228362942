/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "antd";
import { CheckSquareFilled, BorderOutlined } from "@ant-design/icons";

import { COLORS, LOGIC } from "../../../constants";
import { Validate } from "../../../elements";

import "../ManagerPointDay.scss";

function DayItem({ item, isDisabled, onChange, value = [] }) {
  const [isSelect, setIsSelect] = useState(() => {
    const indexNote = value?.findIndex?.((i) => i === item);
    if (indexNote > -1) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    const indexNote = value?.findIndex?.((i) => i === item);
    if (indexNote > -1) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [value]);

  const renderIcon = () => {
    let backgroundColor;
    if (isDisabled) {
      backgroundColor = COLORS.disabled;
    }
    if (isSelect) {
      return (
        <CheckSquareFilled
          style={{ fontSize: 28, color: COLORS.primary, backgroundColor }}
        />
      );
    }
    return (
      <BorderOutlined
        style={{ fontSize: 28, color: COLORS.border, backgroundColor }}
      />
    );
  };

  return (
    <Col
      xl={8}
      xs={24}
      style={{ marginTop: 5, marginBottom: 5 }}
      onClick={() => {
        if (isDisabled) {
          return;
        }
        const indexNote = value?.findIndex?.((i) => i === item);
        setIsSelect(!isSelect);
        if (indexNote > -1) {
          const arrClone = [...value];
          arrClone.splice(indexNote, 1);
          onChange(arrClone);
          return;
        }
        onChange([...value, item]);
      }}>
      <Row style={{ alignItems: "center" }}>
        {renderIcon()}
        {"\xa0" + LOGIC.dayOfWeek[item]}
      </Row>
    </Col>
  );
}

function PointDayConfigDayContent({ value, onChange, isDisabled, pointType }) {
  useEffect(() => {
    if (pointType !== LOGIC.booking_point.dayOfWeek) {
      onChange([]);
    }
  }, [pointType]);
  return (
    <Row style={{ paddingTop: 15, flexWrap: "wrap" }}>
      {Object.keys(LOGIC.dayOfWeek).map((item) => {
        return (
          <Col key={`${item}`} span={8}>
            <DayItem
              item={item}
              value={value}
              isDisabled={isDisabled}
              onChange={onChange}
            />
          </Col>
        );
      })}
    </Row>
  );
}

export function PointDayConfigDay({ form, pointType }) {
  const [rules, setRules] = useState([]);
  useEffect(() => {
    if (pointType === LOGIC.booking_point.dayOfWeek) {
      setRules([Validate.emptyContent]);
    } else {
      setRules([]);
      form.resetFields(["dayOfWeeks"]);
    }
  }, [pointType]);
  return (
    <Form.Item rules={rules} name={"dayOfWeeks"}>
      <PointDayConfigDayContent
        isDisabled={pointType !== LOGIC.booking_point.dayOfWeek}
        pointType={pointType}
      />
    </Form.Item>
  );
}
