import React, { useRef } from "react";
import { Col, Row, Table } from "antd";
import { useQuery } from "react-query";
import moment from "moment";

import { FetchApi } from "../../api";
import {
  BlockUI,
  LoadingView,
  Container,
  ErrorView,
  ButtonCustom,
} from "../../elements";
import { Convert, FilterByTime } from "../../elements";
import { STRINGS, LOGIC } from "../../constants";
import { CommonFunction } from "../../util/CommonFunction";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";

import "../../util/variables.scss";

const formatsu = "YYYY-MM-DD";

export default function HistoryProfitStore() {
  const blockUI = useRef();
  const page = useRef(1);
  const size = useRef(10);
  const startTimeRef = useRef(moment().subtract(1, "days").format(formatsu));
  const endTimeRef = useRef(moment().format(formatsu));

  const { data, refetch, isLoading } = useQuery([`HistoryStore`], () =>
    FetchApi.getStoreRevenueInRange({
      size: size.current,
      page: page.current,
      from: moment(startTimeRef.current).valueOf(),
      to: moment(endTimeRef.current).valueOf(),
    })
  );

  const columns = [
    {
      title: STRINGS.start_day,
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (e, dataRevenue) => {
        if (dataRevenue.confirmTime) {
          return Convert.convertDateTime(dataRevenue.confirmTime);
        }
        return Convert.convertDate(e);
      },
      width: 150,
    },
    {
      title: STRINGS.operate,
      dataIndex: "lastUpdate",
      key: "lastUpdate",
      align: "center",
      render: (e) => STRINGS.confirm_revenue_newest[e],
    },

    {
      title: STRINGS.total_money,
      dataIndex: "totalRevenue",
      key: "totalRevenue",
      align: "center",
      render: (e) => CommonFunction.currencyFormat(e),
    },
    {
      title: STRINGS.pay_credit + `(${STRINGS.yen})`,
      dataIndex: "totalRevenue",
      key: "totalRevenue",
      align: "center",
      render: (e) => CommonFunction.currencyFormat(0),
    },
    {
      title: STRINGS.pay_cash + `(${STRINGS.yen})`,
      dataIndex: "totalRevenue",
      key: "totalRevenue",
      align: "center",
      render: (e) => CommonFunction.currencyFormat(e),
    },
    {
      title: STRINGS.total_user,
      dataIndex: "userQuantity",
      key: "userQuantity",
      align: "center",
    },
    {
      title: STRINGS.total_working_min,
      dataIndex: "shiftTimes",
      key: "shiftTimes",
      align: "center",
      render: (e) => CommonFunction.minute(e),
    },
    {
      title: STRINGS.total_course_min,
      dataIndex: "courseTimes",
      key: "courseTimes",
      align: "center",
      render: (e) => CommonFunction.minute(e),
    },
    {
      title: STRINGS.working_percentage,
      dataIndex: "workingRate",
      key: "workingRate",
      align: "center",
      render: (e) => CommonFunction.roundPercent(e || 0),
    },
    {
      title: STRINGS.total_staff,
      dataIndex: "staffQuantity",
      key: "staffQuantity",
      align: "center",
    },
    {
      title: STRINGS.total_staff_selected,
      dataIndex: "bookingChooseStaffQuantity",
      key: "bookingChooseStaffQuantity",
      align: "center",
    },
    {
      title: STRINGS.total_D_user,
      dataIndex: "userDQuantity",
      key: "userDQuantity",
      align: "center",
    },
    {
      title: STRINGS.total_A_user,
      dataIndex: "userAQuantity",
      key: "userAQuantity",
      align: "center",
    },
    {
      title: STRINGS.total_S_user,
      dataIndex: "userSQuantity",
      key: "userSQuantity",
      align: "center",
    },
    {
      title: STRINGS.total_P_user,
      dataIndex: "userPQuantity",
      key: "userPQuantity",
      align: "center",
    },
    {
      title: STRINGS.total_R_user,
      dataIndex: "userRQuantity",
      key: "userRQuantity",
      align: "center",
    },
    {
      title: STRINGS.staff_in_charge,
      dataIndex: "staffName",
      key: "staffName",
      align: "center",
      width: 120,
    },
    {
      title: STRINGS.remarks,
      dataIndex: "commentFromConfirmedStaff",
      key: "commentFromConfirmedStaff",
      align: "center",
    },
  ];

  const renderLeft = () => {
    return (
      <Row align="bottom" style={{ marginBottom: 4 }}>
        <Col>
          <FilterByTime
            key={endTimeRef.current + startTimeRef.current}
            label={STRINGS.start_time}
            onChange={(e) => (startTimeRef.current = e)}
            picker="date"
            format={LOGIC.dateFormat}
            value={startTimeRef.current}
            formatOnChange={formatsu}
            allowClear={false}
          />
        </Col>
        <Col style={{ margin: "0px 10px" }}>
          <FilterByTime
            key={endTimeRef.current + startTimeRef.current}
            picker="date"
            label={STRINGS.end_time}
            onChange={(e) => (endTimeRef.current = e)}
            format={LOGIC.dateFormat}
            formatOnChange={formatsu}
            value={endTimeRef.current}
            allowClear={false}
          />
        </Col>
        <ButtonCustom
          onClick={() => {
            if (
              moment(endTimeRef.current).isBefore(moment(startTimeRef.current))
            ) {
              const end = endTimeRef.current;
              endTimeRef.current = startTimeRef.current;
              startTimeRef.current = end;
            }
            refetch();
          }}>
          {STRINGS.filter}
        </ButtonCustom>

        <ButtonCustom
          style={{
            marginLeft: 10,
          }}
          onClick={() => {
            FetchApi.downloadStoreRevenueInRange({
              from: moment(startTimeRef.current).valueOf(),
              to: moment(endTimeRef.current).valueOf(),
            });
          }}>
          {STRINGS.download}
        </ButtonCustom>
      </Row>
    );
  };

  if (isLoading) {
    return <LoadingView />;
  }

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Container renderLeft={renderLeft} childrenStyle={{ marginTop: 10 }}>
      <BlockUI ref={blockUI} />
      <Table
        loading={isLoading}
        rowKey={(record) => record.id + record.storeId}
        columns={columns}
        dataSource={data?.storeRevenues}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
        scroll={{ x: 1500, y: "calc(100vh - 320px)" }}
      />
      <PaginationWithSizeChange
        size={size.current}
        total={data?.page?.totalElements || 0}
        onChange={(pageNumber) => {
          page.current = pageNumber;
          refetch();
        }}
        onShowSizeChange={(e) => {
          size.current = e.size;
          page.current = 1;
          refetch();
        }}
        current={page.current}
      />
    </Container>
  );
}
