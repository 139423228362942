import React, { useState } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  message,
  InputNumber,
  Checkbox,
  Select,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import {
  ButtonCustom,
  ModalCustomService,
  LoadingAbsolute,
  ImageUpload,
  SuffixIconDown,
  SvgView,
  Validate,
  useForceUpdate,
} from "../../../../../elements";
import { CommonFunction } from "../../../../../util/CommonFunction";
import { FetchApi } from "../../../../../api";
import { useGetComboCategories } from "../../../../../util/hooks/";
import { COLORS, Svgs, STRINGS } from "../../../../../constants";
import Auth from "../../../../../store/Authentication";

import "../../../Combo.scss";

const Edit = ({ item, data, setData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const disabled = item.storeId == 0 && userAdmin.type === "STORE_ADMIN";
  const { comboCategories } = useGetComboCategories();
  const forceUpdate = useForceUpdate();
  const onEdit = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      fieldData.id = item.id;
      fieldData.canCombineWithAnotherCourse =
        !fieldData.canNotCombineWithAnotherCourse;
      setLoading(true);
      const result = await FetchApi.updateCombo(fieldData);
      setLoading(false);
      if (result.success) {
        const index = data.comboDTOS.findIndex((itm) => itm.id === item.id);
        const clone = JSON.parse(
          JSON.stringify({
            ...data,
          })
        );
        clone.comboDTOS[index] = result.comboDTO;

        setData(clone.comboDTOS);
        message.success(STRINGS.saved_common);
        ModalCustomService.close();
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return (
    <Col className="combo-form" style={{ maxWidth: 1000 }}>
      <Form
        layout="vertical"
        form={form}
        initialValues={{ comboPackRequests: item.comboPackDTOS }}
        onValuesChange={(e) => {
          if ("isFavourite" in e) {
            forceUpdate();
          }
        }}>
        <Form.Item
          style={{ marginBottom: 10 }}
          required
          name="name"
          label={STRINGS.category_title}
          initialValue={item.name}
          rules={[Validate.emptyContent]}>
          <Input disabled={disabled} />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 10 }}
          name="shortName"
          label={STRINGS.category_title_short}
          initialValue={item.shortName}>
          <Input disabled={disabled} />
        </Form.Item>
        <Form.Item
          label={STRINGS.description_2}
          name="description"
          initialValue={item.description}>
          <Input.TextArea disabled={disabled} />
        </Form.Item>

        {/* <Form.Item
          name="color"
          required
          rules={[Validate.isHexColor]}
          style={{ marginBottom: 10 }}
          label={STRINGS.combo_color}
          initialValue={item.color}>
          <AppPicker disabled={disabled} />
        </Form.Item> */}
        <Row style={{ display: "flex" }}>
          {userAdmin.type === "STORE_ADMIN" && (
            <Row style={{ alignItems: "center" }}>
              <Form.Item
                style={{ marginBottom: 0, marginRight: 10 }}
                valuePropName="checked"
                name="isFavourite"
                initialValue={item.isFavourite}>
                <Checkbox disabled={disabled} />
              </Form.Item>
              <Col style={{ color: COLORS.sub_disabled, fontSize: 14 }}>
                {STRINGS.is_favourite}
              </Col>
            </Row>
          )}
          {(userAdmin.type === "STORE_ADMIN" ||
            userAdmin.type === "SYSTEM_ADMIN") && (
            <Row style={{ alignItems: "center" }}>
              <Col
                style={userAdmin.type === "STORE_ADMIN" && { marginLeft: 10 }}>
                <Form.Item
                  style={{ marginBottom: 0, marginRight: 10 }}
                  valuePropName="checked"
                  name="canNotCombineWithAnotherCourse"
                  initialValue={!item?.canCombineWithAnotherCourse}>
                  <Checkbox disabled={disabled} />
                </Form.Item>
              </Col>
              <Col style={{ color: COLORS.sub_disabled, fontSize: 14 }}>
                {STRINGS.can_combine_with_another_course}
              </Col>
            </Row>
          )}
          {/* <Col style={{ color:COLORS.sub_disabled, fontSize: 14, marginRight: 10 }}>
            {STRINGS.is_prolonged}
          </Col>
          <Form.Item
            style={{ marginBottom: 0 }}
            valuePropName="checked"
            name="isProlonged"
            initialValue={item.isProlonged}>
            <Checkbox disabled={disabled} />
          </Form.Item> */}
        </Row>

        {item.storeId !== 0 &&
          userAdmin.type === "STORE_ADMIN" &&
          !form.getFieldValue().isFavourite &&
          comboCategories.length > 0 && (
            <Form.Item
              style={{
                margin: "10px 0px",
                flexDirection: "row",
                alignItems: "baseline",
              }}
              label={STRINGS.combo_category}
              name="categoryId"
              required
              rules={[Validate.emptyContent]}
              initialValue={item.categoryId}>
              <Select
                style={{ textAlign: "left", marginLeft: 10, width: 240 }}
                suffixIcon={<SuffixIconDown />}>
                {comboCategories.map((item) => {
                  return (
                    <Select.Option key={item.id} value={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
        <Row style={{ alignItems: "center" }}>
          <Form.Item
            style={{ marginBottom: 0, marginRight: 10 }}
            valuePropName="checked"
            name="applyDMember"
            initialValue={item.applyDMember}>
            <Checkbox />
          </Form.Item>
          <Col style={{ color: COLORS.sub_disabled, fontSize: 14 }}>
            {STRINGS.create_D_combo}
          </Col>
        </Row>
        <Form.Item
          name="imageUrl"
          label={STRINGS.image}
          style={{ width: 375 }}
          initialValue={item.imageUrl}>
          <ImageUpload aspect={3 / 2} disabled={disabled} />
        </Form.Item>

        <Row className="theader">
          <Col span={3}>{STRINGS.time}</Col>
          <Col span={3}>{STRINGS.app_member}</Col>
          <Col span={3}>{STRINGS.app_non_member}</Col>
          <Col span={3}>{STRINGS.list_price}</Col>
          <Col span={3}>{STRINGS.price_job}</Col>
          <Col span={3}>{STRINGS.price_tax}</Col>
          <Col span={3}>{STRINGS.grant_points}</Col>
          <Col span={3}>{STRINGS.hidden_in_suggest_booking}</Col>
          {/* <Col span={2}>{STRINGS.apply_coupon}</Col> */}
          <Col span={1} />
        </Row>

        <Form.List name="comboPackRequests">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Col
                    key={field.key}
                    className={index === 0 ? "col-wrapper-row-input" : 0}>
                    <Row className="row-table-custom">
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "minutes"]}
                          fieldKey={[field.fieldKey, "minutes"]}
                          rules={[Validate.isPositiveNumber]}>
                          <InputNumber
                            style={{
                              color: COLORS.primary,
                              fontWeight: "bold",
                            }}
                            formatter={(value) => `${value} 分`}
                            parser={(value) =>
                              value.replace("分", "").replace(" ", "")
                            }
                            disabled={disabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "priceMemberApp"]}
                          fieldKey={[field.fieldKey, "priceMemberApp"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) =>
                              `${value.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} 円`
                            }
                            parser={(value) =>
                              value
                                .replace("円", "")
                                .replace(" ", "")
                                .replace(/\$\s?|(,*)/g, "")
                            }
                            disabled={disabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "priceNonApp"]}
                          fieldKey={[field.fieldKey, "priceNonApp"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) =>
                              `${value.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} 円`
                            }
                            parser={(value) =>
                              value
                                .replace("円", "")
                                .replace(" ", "")
                                .replace(/\$\s?|(,*)/g, "")
                            }
                            disabled={disabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "priceDefault"]}
                          fieldKey={[field.fieldKey, "priceDefault"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) =>
                              `${value.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} 円`
                            }
                            parser={(value) =>
                              value
                                .replace("円", "")
                                .replace(" ", "")
                                .replace(/\$\s?|(,*)/g, "")
                            }
                            disabled={disabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "staffWage"]}
                          fieldKey={[field.fieldKey, "staffWage"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) =>
                              `${value.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} 円`
                            }
                            parser={(value) =>
                              value
                                .replace("円", "")
                                .replace(" ", "")
                                .replace(/\$\s?|(,*)/g, "")
                            }
                            disabled={disabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "tax"]}
                          fieldKey={[field.fieldKey, "tax"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) =>
                              `${value.replace(
                                /\B(?=(\d{3})+(?!\d))/g,
                                ","
                              )} 円`
                            }
                            parser={(value) =>
                              value
                                .replace("円", "")
                                .replace(" ", "")
                                .replace(/\$\s?|(,*)/g, "")
                            }
                            disabled={disabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          name={[field.name, "point"]}
                          fieldKey={[field.fieldKey, "point"]}
                          rules={[Validate.isNumberPointPrice]}>
                          <InputNumber
                            style={{ fontWeight: "bold" }}
                            formatter={(value) => `${value} pt`}
                            parser={(value) =>
                              value.replace("pt", "").replace(" ", "")
                            }
                            disabled={disabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          {...field}
                          valuePropName="checked"
                          name={[field.name, "hiddenInSuggestBooking"]}>
                          <Checkbox
                            disabled={disabled}
                            defaultChecked={item.hiddenInSuggestBooking}
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col span={2}>
                        <Form.Item
                          {...field}
                          style={{ marginBottom: 0 }}
                          valuePropName="checked"
                          name={[field.name, "applyCoupon"]}>
                          <Checkbox disabled={disabled} />
                        </Form.Item>
                      </Col> */}
                      {disabled ? null : (
                        <Col span={1}>
                          <button
                            style={{
                              borderWidth: 0,
                              backgroundColor: "transparent",
                              borderColor: "transparent",
                              cursor: "pointer",
                              marginTop: 5,
                            }}
                            onClick={() => remove(field.name)}>
                            <SvgView svgData={Svgs.delete} />
                          </button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                ))}
                {disabled ? null : (
                  <Row style={{ alignItems: "flex-start", marginTop: 20 }}>
                    <ButtonCustom
                      onClick={() => {
                        add();
                      }}
                      icon={<PlusCircleOutlined />}>
                      {STRINGS.add_more_course}
                    </ButtonCustom>
                  </Row>
                )}
              </>
            );
          }}
        </Form.List>
      </Form>
      {disabled ? null : (
        <Row justify="center" style={{ margin: "20px 0px" }}>
          <ButtonCustom
            onClick={ModalCustomService.close}
            isCancelButton
            style={{
              marginRight: 10,
            }}>
            {STRINGS.cancel}
          </ButtonCustom>
          <ButtonCustom onClick={onEdit}>{STRINGS.update}</ButtonCustom>
        </Row>
      )}
      {loading && <LoadingAbsolute />}
    </Col>
  );
};

export default Edit;
