import { Col, Form, Input, Row, message, DatePicker } from "antd";
import React from "react";
import moment from "moment";

import { FetchApi } from "../../../api";
import {
  ButtonCustom,
  ModalCustomService,
  LoadingAbsolute,
} from "../../../elements";
import { Validate } from "../../../elements/Validate";
import { CommonFunction } from "../../../util/CommonFunction";
import { CustomerList } from "./CustomerList";
import { StaffList } from "./StaffList";
import { Svgs, LOGIC, STRINGS } from "../../../constants";
import Auth from "../../../store/Authentication";
import RadioAllStoreStaff from "../../../elements/RadioAllStoreStaff";

import "../StaffNotGood.scss";

const Edit = ({ data, setData, item, refetch }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const onEdit = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      if (userAdmin.type !== "STORE_ADMIN") {
        if (!fieldData.staffChangeRequest.id) {
          message.warning(STRINGS.please_select_a_reception_staff);
          return;
        }
        if (!fieldData.staffChangeRequest.password) {
          message.warning(STRINGS.please_type_password);
          return;
        }
      }

      setLoading(true);
      const result = await FetchApi.updateNG({
        feedback: fieldData.feedback,
        id: item.id,
      });
      setLoading(false);

      if (result.success) {
        // const index = data.notGoodDTOS.findIndex((itm) => itm.id === item.id);
        // const clone = JSON.parse(JSON.stringify({ ...data }));
        // clone.notGoodDTOS[index] = result.notGoodDTO;
        // setData(clone.notGoodDTOS);
        refetch();
        ModalCustomService.close();
        message.success(STRINGS.saved_common);
      } else if (result.code == 4003) {
        message.warning(STRINGS.password_is_incorrect);
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      message.warning(STRINGS.please_fill_required_fields);
    }
  };
  return (
    <Col className="staff-not-good-form">
      <Form layout="vertical" form={form}>
        <Row>
          <Form.Item
            name="userId"
            initialValue={item.customerId}
            rules={[Validate.emptyContent]}
          >
            <CustomerList disabled defaultName={item.customerName} />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            name="staffId"
            initialValue={item.staffName}
            rules={[Validate.emptyContent]}
          >
            <StaffList disabled label={STRINGS.staff_NG_form} />
          </Form.Item>
          <Form.Item
            name="bookedDate"
            label={STRINGS.day_ordered}
            style={{ marginLeft: 30 }}
            required
          >
            <DatePicker
              placeholder={moment(item.bookedDate).format(LOGIC.timeFormat)}
              suffixIcon={Svgs.datepicker()}
              format={LOGIC.timeFormat}
              style={{ width: 220 }}
              showTime
              disabled
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            name="staffIdCreated"
            initialValue={item.nameOfWhoCreatedNG}
            rules={[Validate.emptyContent]}
          >
            <StaffList disabled label={STRINGS.reception_staff} />
          </Form.Item>
          <Form.Item
            name="assignedDate"
            label={STRINGS.day_assigned}
            style={{ marginLeft: 30 }}
            required
          >
            <DatePicker
              placeholder={moment(item.assignedDate).format(LOGIC.timeFormat)}
              suffixIcon={Svgs.datepicker()}
              style={{ width: 220 }}
              showTime
              disabled
            />
          </Form.Item>
        </Row>

        <Form.Item
          style={{ marginBottom: 10 }}
          name="feedback"
          label={STRINGS.reason}
          required
          initialValue={item.feedback}
          rules={[Validate.emptyContent]}
        >
          <Input.TextArea />
        </Form.Item>
        {userAdmin.type !== "STORE_ADMIN" && (
          <>
            <Row
              style={{
                color: "#4B2A05",
                fontSize: 18,
                paddingTop: 14,
                fontWeight: "bold",
                justifyContent: "center",
              }}
            >
              {STRINGS.please_select_a_reception_staff}
            </Row>
            <Form.Item name="staffChangeRequest">
              <RadioAllStoreStaff />
            </Form.Item>
          </>
        )}
      </Form>
      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}
        >
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onEdit}>{STRINGS.save}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
};

export default Edit;
