import React from "react";
import { Form, Col, Row, Input } from "antd";

import { COLORS, STRINGS } from "../../../constants";

function ModalBookingDetailComment() {
  return (
    <Col>
      <Row
        style={{
          fontSize: 22,
          fontWeight: "bold",
          color: COLORS.sub_accent,
        }}>
        {STRINGS.today_comment}
      </Row>

      <Form.Item name="comment">
        <Input.TextArea
          autoSize
          style={{
            width: "100%",
            border: `1px solid ${COLORS.sub_text}`,
            borderRadius: 5,
          }}
        />
      </Form.Item>
    </Col>
  );
}
export { ModalBookingDetailComment };
