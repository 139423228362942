import React from "react";
import { Col, Row } from "antd";

import { COLORS, STRINGS } from "../../../constants";

export function UserTypeExplain() {
  return (
    <Col
      style={{
        border: `1px solid gray`,
        fontWeight: "bold",
        marginBottom: 10,
      }}>
      {STRINGS.userTypeExplain.map((i, index) => {
        let borderTop = `1px solid gray`;
        if (index === 0) {
          borderTop = null;
        }
        return (
          <Row key={`${i.type}`} style={{ borderTop }}>
            <Col
              span={5}
              style={{
                color: i.color,
                backgroundColor: "#c6e0b4",
                textAlign: "left",
                paddingLeft: 10,
              }}>
              <Row style={{ height: "100%", alignContent: "center" }}>
                {i.type}
              </Row>
            </Col>
            <Col
              span={19}
              style={{
                color: COLORS.sub_accent,
                textAlign: "left",
                fontSize: 17,
                paddingLeft: 10,
                paddingTop: 10,
                whiteSpace: "pre-line",
                fontFamily: "Gothic A1, sans-serif",
                fontWeight: "bold",
              }}>
              {i.des}
            </Col>
          </Row>
        );
      })}
    </Col>
  );
}
