import { useQuery } from "react-query";

import { FetchApi } from "../../../api";

function useQueryListStaff({
  desiredTime,
  storeId,
  comboPackIdsRequests = [],
  numberOrdinal,
}) {
  const value = useQuery(
    `staff-list-booking-${numberOrdinal}-${comboPackIdsRequests}-${desiredTime}`,
    () =>
      FetchApi.getStaffByComboPacks({
        desiredTime: desiredTime,
        storeId: storeId,
        comboPackIdsRequests: comboPackIdsRequests,
      }),
    {
      enabled: !!desiredTime,
    }
  );
  return value;
}
export { useQueryListStaff };
