import React, { useState, memo } from "react";
import { Col, Row, Form, message } from "antd";
import { useQuery } from "react-query";

import { FetchApi } from "../../api";
import { LoadingAbsolute, LoadingView, ButtonCustom } from "../../elements";
import { STRINGS } from "../../constants";
import Quill from "../../elements/Quill";

import "./CancelBooking.scss";

const CancelBooking = memo(() => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { data, isLoading } = useQuery(
    ["cancelTerm"],
    FetchApi.getCancelBookingTerm
  );

  const onSaveTerm = async () => {
    const des = form.getFieldsValue().description;
    setLoading(true);
    const result = await FetchApi.setCancelBookingTerm(des);
    setLoading(false);
    if (result.success) {
      message.success(STRINGS.done_successful);
    }
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Col>
      <Form form={form}>
        <Form.Item
          name="description"
          initialValue={data?.termsCancelDTO?.description}>
          <Quill className="quill-slide" />
        </Form.Item>
      </Form>
      <Row justify="end">
        <ButtonCustom onClick={onSaveTerm}>{STRINGS.update}</ButtonCustom>
      </Row>

      {loading && <LoadingAbsolute styleSpin={{ top: "30%", left: "60%" }} />}
    </Col>
  );
});

export default CancelBooking;
