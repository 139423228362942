import moment from "moment";

/* eslint-disable no-useless-escape */
const { STRINGS, LOGIC } = require("../constants");

const Validate = {
  validatePassword: ({ getFieldValue }) => ({
    validator(rule, value) {
      const re = /(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]*$/;
      if (value === undefined || value.length === 0) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.password_has_character_and_number);
      } else if (value.length < 8 || value.length > 32) {
        return Promise.reject(STRINGS.password_8_to_32);
      } else {
        return Promise.resolve();
      }
    },
  }),
  phoneNumberJp: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re =
        /^(?:(0([5,7,9]{1})0([0-9]{8}))|(0([8]{1})0([1-9]{1}[0-9]{7})))$/;
      if (value === undefined || value.length === 0 || regex.test(value)) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.number_please);
      } else {
        return Promise.resolve();
      }
    },
  }),
  phoneNumberNoRequired: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re =
        /^(?:(0([5,7,9]{1})0([0-9]{8}))|(0([8]{1})0([1-9]{1}[0-9]{7})))$/;
      if (value === undefined || value.length === 0 || regex.test(value)) {
        return Promise.resolve();
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.number_please);
      } else {
        return Promise.resolve();
      }
    },
  }),
  emptyContent: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      if (
        value === undefined ||
        value === null ||
        value?.length === 0 ||
        regex.test(value)
      ) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else {
        return Promise.resolve();
      }
    },
  }),
  phoneNumber: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;

      const re = /^[0-9０-９]*$/;
      if (value === undefined || value.length === 0 || regex.test(value)) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.number_please);
      } else {
        return Promise.resolve();
      }
    },
  }),
  latlong: ({ getFieldValue }) => ({
    validator(rule, value) {
      const re = /^[-+]?[0-9０-９]{1,7}(\.[0-9０-９]+)?$/;
      if (!re.test(value) && value != undefined && value.length != 0) {
        return Promise.reject(
          rule.field == "latitude"
            ? STRINGS.please_enter_the_latitude_format_correctly
            : STRINGS.please_enter_the_longitude_format_correctly
        );
      } else {
        return Promise.resolve();
      }
    },
  }),
  email: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (
        value === undefined ||
        value === null ||
        value?.length === 0 ||
        regex.test(value)
      ) {
        return Promise.resolve();
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.email_error);
      } else {
        return Promise.resolve();
      }
    },
  }),
  isEmpty: (value) => {
    const regex = /^\s+$/;
    if (value === undefined || value.length === 0 || regex.test(value)) {
      return true;
    } else {
      return false;
    }
  },
  removeSpecialCharactors: (str) =>
    str.replace(
      /[^ a-zA-Z0-9@._-\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/g,
      ""
    ),
  sortPosition: (a, b) => {
    if (a.position < b.position) {
      return 1;
    }
    if (a.position > b.position) {
      return -1;
    }
    return 0;
  },
  convertPhoneJp: (phone) => {
    const cleaned = ("" + phone).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})/);

    if (match) {
      return match[1] + "-" + match[2] + "-" + match[3];
    }
    return phone;
  },
  isFloatNumber: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re = /^[+-]?\d+(\.\d+)?$/;
      if (value === undefined || value.length === 0 || regex.test(value)) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.number_please);
      } else {
        return Promise.resolve();
      }
    },
  }),

  isUrl: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
      if (
        value === undefined ||
        value?.length === 0 ||
        regex.test(value) ||
        value === null
      ) {
        return Promise.resolve();
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.invalid_url);
      } else {
        return Promise.resolve();
      }
    },
  }),
  isUrlWithRequired: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re =
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
      if (
        value === undefined ||
        value?.length === 0 ||
        regex.test(value) ||
        value === null
      ) {
        return Promise.reject(STRINGS.fill_valid_url);
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.invalid_url);
      } else {
        return Promise.resolve();
      }
    },
  }),
  isNumberCombineJp: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re = /^[0-9０-９]*$/;
      if (
        value === undefined ||
        value.length === 0 ||
        regex.test(value) ||
        value === null
      ) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.number_please);
      } else {
        return Promise.resolve();
      }
    },
  }),
  isPositiveNumber: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re = /^[0-9０-９]*[1-9１-９][0-9０-９]*$/;
      if (getFieldValue("couponBirthday") === LOGIC.coupon_time.tax) {
        return Promise.resolve();
      }
      if (
        value === undefined ||
        value?.length === 0 ||
        regex.test(value) ||
        value === null
      ) {
        return Promise.reject(STRINGS.this_field_is_required);
      }
      if (!re.test(value)) {
        return Promise.reject(STRINGS.positive_num);
      }
      return Promise.resolve();
    },
  }),
  isNumberPointPrice: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re = /^[0-9０-９]*[0-9０-９][0-9０-９]*$/;
      if (
        value === undefined ||
        value?.length === 0 ||
        regex.test(value) ||
        value === null
      ) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.invalid_value);
      } else {
        return Promise.resolve();
      }
    },
  }),
  isHexColor: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i;
      if (value === undefined || value?.length === 0 || regex.test(value)) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.invalid_hex_color);
      } else {
        return Promise.resolve();
      }
    },
  }),
  isPositiveNumberWithoutRequired: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re = /^[0-9０-９]*[1-9１-９][0-9０-９]*$/;
      if (
        value === undefined ||
        value?.length === 0 ||
        regex.test(value) ||
        value === null
      ) {
        return Promise.resolve();
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.positive_num);
      } else {
        return Promise.resolve();
      }
    },
  }),
  isPositiveNumMessage: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re = /^[0-9０-９]*[1-9１-９][0-9０-９]*$/;
      if (
        value === undefined ||
        value?.length === 0 ||
        regex.test(value) ||
        value === null
      ) {
        return Promise.resolve();
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.invalid_input_value);
      } else {
        return Promise.resolve();
      }
    },
  }),
  isNormalNumberMessage: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re = /^[0-9０-９]*[0-9０-９][0-9０-９]*$/;
      if (
        value === undefined ||
        value?.length === 0 ||
        regex.test(value) ||
        value === null
      ) {
        return Promise.resolve();
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.invalid_input_value);
      } else {
        return Promise.resolve();
      }
    },
  }),
  validateQuill: (val) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      if (
        val === undefined ||
        val === null ||
        val.length === 0 ||
        regex.test(val)
      ) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else {
        return Promise.resolve();
      }
    },
  }),
  validateEditor: ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      if (
        value === undefined ||
        value === null ||
        value.length === 0 ||
        regex.test(value)
      ) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else {
        return Promise.resolve();
      }
    },
  }),
};
const Convert = {
  removeSpaceName: (e) => {
    return e;
  },
  utilData(data = [], fieldName) {
    return data?.map?.((i) => i[fieldName]) || [];
  },
  convertDate: (date) => {
    return moment(date).format("YYYY年MM月DD日");
  },
  convertDateTime: (date) => {
    if (moment(date).isValid()) {
      return moment(date).format("YYYY年MM月DD日 HH:mm:ss");
    }
    return "";
  },
  convertTime: (start, close, divive = ":") => {
    let closeTime = moment(
      moment().format("MM/DD/YYYY") + `${close}`,
      "MM/DD/YYYY HH:mm"
    );

    const splitStart = start.split(divive);
    const startHour = Number(splitStart[0]);
    // const startMinute = Number(splitStart[1]);

    const splitEnd = close.split(divive);
    const endHour = Number(splitEnd[0]);
    // const endMinute = Number(splitEnd[1]);

    if (endHour < startHour) {
      closeTime = moment(
        moment().add("1", "day").format("MM/DD/YYYY") + `${close}`,
        "MM/DD/YYYY HH:mm"
      );
    }

    return closeTime;
  },
};

export { Validate, Convert };
