/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, memo } from "react";
import { Upload, message, Select, Input, Form, Col } from "antd";
import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import moment from "moment";

import {
  ButtonCustom,
  FilterByTime,
  LoadingAbsolute,
  SuffixIconDown,
  SvgView,
  useForceUpdate,
  Validate,
} from "../../../elements";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util/CommonFunction";
import { CouponConfigTime } from "./CouponConfigTime";
import { CouponBirthday } from "./CouponBirthday";
import { CouponApplyCombo } from "./CouponApplyCombo";
import { COLORS, Svgs, STRINGS, LOGIC } from "../../../constants";
import { CouponMemberType } from "./CouponMemberType";
import { CouponFormMultipleCoupon } from "./CouponFormMultipleCoupon";
import Quill from "../../../elements/Quill";
import Auth from "../../../store/Authentication";

import "./CouponForm.scss";
import { CouponFormShowToUser } from "./CouponFormShowToUser";

const { Option } = Select;

const CouponForm = memo((props) => {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const disabled = props.data?.storeId == 0 && userAdmin.type === "STORE_ADMIN";
  const [couponType, setCouponType] = useState(
    props.data?.type || LOGIC.coupon_time.default
  );
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [loadingImg, setLoadingImg] = useState(false);

  const imageUrl = useRef("");
  const content = useRef("");
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    if (props.data?.id) {
      content.current = props.data.description;
      imageUrl.current = props.data.imageUrl;
      forceUpdate();
    }
  }, [props.data]);

  const handleUpImage = async (file) => {
    setLoadingImg(true);
    let result = await FetchApi.uploadFile(file);
    if (result?.fileUrl) {
      imageUrl.current = result.fileUrl;
      setLoadingImg(false);
    } else {
      message.warning(STRINGS.upload_img_failed);
      setLoadingImg(false);
    }
  };

  const onChangeHtml = (e) => {
    content.current = e;
  };

  const removeImg = () => {
    imageUrl.current = "";
    forceUpdate();
  };

  const onConfirm = async () => {
    try {
      await form.validateFields();
      let item = { ...form.getFieldValue() };
      if (
        couponType !== LOGIC.coupon_time.birthday &&
        new Date(item.startDate) >= new Date(item.endDate)
      ) {
        message.warning(STRINGS.endtime_greater_than_starttime);
        return;
      }

      if (Object.keys(props.data).length < 1) {
        item.description = content.current;
        item.imageUrl = imageUrl.current;

        if (!item.type) {
          item.type = item.couponBirthday;
        }
        if (item.couponTypeStartTime && item.couponTypeEndTime) {
          item.timeOfDay = `${moment(item.couponTypeStartTime).format(
            "HH:mm"
          )};${moment(item.couponTypeEndTime).format("HH:mm")}`;
        }
        item.exactDays = (item.exactDays || []).map((i) =>
          moment(i).format("MM/DD/YYYY")
        );
        if (couponType === LOGIC.coupon_time.tax) {
          item.discount = 0;
        }
        setLoading(true);

        const result = await FetchApi.createCoupon(item);
        if (result.success) {
          item.id = result.couponDTO.id;
          item.store = result.couponDTO.store;
          message.success(STRINGS.create_coupon_success);
          props.onConfirm && props.onConfirm(item);
          props.disableModal();
          props.refetch && props.refetch();
        } else {
          message.error(result.message || STRINGS.something_wrong);
          setLoading(false);
        }
      } else {
        item.description = content.current;
        item.imageUrl = imageUrl.current;
        item.id = props.data.id;
        item.type = couponType;
        if (item.couponTypeStartTime && item.couponTypeEndTime) {
          item.timeOfDay = `${moment(item.couponTypeStartTime).format(
            "HH:mm"
          )};${moment(item.couponTypeEndTime).format("HH:mm")}`;
        }

        item.exactDays = (item.exactDays || []).map((i) =>
          moment(i).format("MM/DD/YYYY")
        );

        if (couponType === LOGIC.coupon_time.tax) {
          item.discount = 0;
        }
        setLoading(true);
        const result = await FetchApi.updateCoupon(item);
        if (result.success) {
          message.success(STRINGS.update_coupon_success);
          props.onConfirm && props.onConfirm(item);
          props.disableModal();
        } else {
          message.error(result.message || STRINGS.something_wrong);
          setLoading(false);
        }
      }
    } catch (error) {
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  const uploadButton = (
    <div>
      {loadingImg ? (
        <LoadingOutlined />
      ) : (
        <PlusCircleOutlined style={{ color: COLORS.primary_3, fontSize: 32 }} />
      )}
      <div className="ant-upload-text">{STRINGS.upload}</div>
    </div>
  );
  const checkShowCouponTime =
    couponType === LOGIC.coupon_time.tax ||
    couponType === LOGIC.coupon_time.early ||
    couponType === LOGIC.coupon_time.first;
  const renderCouponSetting = () => {
    if (couponType !== LOGIC.coupon_time.birthday) {
      return (
        <div>
          <div className="title-time">{STRINGS.config_start_time}</div>
          <div className="start-time">
            <Form.Item
              name="startDate"
              required
              rules={[
                {
                  required: true,
                  message: STRINGS.please_select_start_date,
                },
              ]}
              initialValue={moment(props.data?.startDate)}>
              <FilterByTime
                style={{ width: "100%" }}
                label={STRINGS.start_date}
                format={LOGIC.timeFormat}
                picker="date"
                showTime
                onOk={true}
                labelStyle={{ color: "#757575" }}
                allowClear={false}
                disabled={disabled}
              />
            </Form.Item>
          </div>
          <div className="end-time">
            <Form.Item
              name="endDate"
              required
              rules={[
                {
                  required: true,
                  message: STRINGS.please_select_end_date,
                },
              ]}
              initialValue={moment(props.data?.endDate)}>
              <FilterByTime
                labelStyle={{ color: "#757575" }}
                style={{ width: "100%" }}
                label={STRINGS.end_date}
                format={LOGIC.timeFormat}
                picker={"date"}
                showTime
                allowClear={false}
                disabled={disabled}
              />
            </Form.Item>
          </div>
          {!!props.isAdmin && (
            <div className="select-store">
              <Form.Item
                label={STRINGS.store_applied}
                name="storeId"
                required
                initialValue={props.data.storeId || 0}
                rules={[
                  {
                    required: true,
                    message: STRINGS.please_select_store,
                  },
                ]}>
                <Select
                  style={{ width: "100%" }}
                  suffixIcon={<SuffixIconDown />}>
                  {props.storeList.sort(Validate.sortPosition).map((item) => (
                    <Option key={"" + item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          )}
          {!checkShowCouponTime && (
            <Form.Item name="type">
              <CouponConfigTime
                form={form}
                initialValue={props.data}
                couponType={couponType}
                setCouponType={setCouponType}
              />
            </Form.Item>
          )}
        </div>
      );
    }
  };

  let initialValues = props.data?.type || LOGIC.coupon_time.default;
  let initialDiscount = props.data?.discount;
  if (
    props.data?.type === LOGIC.coupon_time.time ||
    props.data?.type === LOGIC.coupon_time.day ||
    props.data?.type === LOGIC.coupon_time.date
  ) {
    initialValues = LOGIC.coupon_time.default;
  }
  if (props.data?.type === LOGIC.coupon_time.tax) {
    initialDiscount = STRINGS.coupon_tax_discount;
  }

  const rightForm = () => {
    return (
      <div className="form-right">
        <div className="input-title">
          <Form.Item name="couponBirthday">
            <CouponBirthday />
          </Form.Item>
          <Form.Item
            label={STRINGS.title}
            name="title"
            required
            rules={[Validate.emptyContent]}
            initialValue={props.data?.title}>
            <Input
              placeholder={STRINGS.input_title_coupont}
              disabled={disabled}
            />
          </Form.Item>
        </div>

        <Form.Item
          label={STRINGS.discount_amount_yen}
          name="discount"
          required
          rules={[Validate.isPositiveNumber]}
          shouldUpdate={(prevValues, curValues) => {
            if (curValues.couponBirthday !== prevValues.couponBirthday) {
              if (curValues.couponBirthday === LOGIC.coupon_time.tax) {
                form.setFieldsValue({
                  discount: STRINGS.coupon_tax_discount,
                });
              } else {
                form.setFieldsValue({ discount: props.data?.discount });
              }
            }
          }}>
          <Input
            placeholder={STRINGS.discount_amount_yen}
            disabled={disabled || couponType === LOGIC.coupon_time.tax}
            style={{ width: 150 }}
          />
        </Form.Item>
        <Form.Item name="showWithAppUser">
          <CouponFormShowToUser />
        </Form.Item>
        <CouponApplyCombo form={form} couponId={props.data?.id} />
        <CouponMemberType form={form} couponId={props.data?.id} />
        <Form.Item name="applyWithOtherCoupon">
          <CouponFormMultipleCoupon />
        </Form.Item>
        <div className="input-content">
          <div className="label-content" style={{ textAlign: "left" }}>
            {STRINGS.description}
          </div>
          <Quill
            disabled={disabled}
            style={{ height: 300, marginBottom: 100 }}
            onChange={onChangeHtml}
            value={props.data?.description}
          />
        </div>
      </div>
    );
  };

  return (
    <Col
      className="warrap-coupon-form"
      style={{
        pointerEvents: props.disableCouponAdmin ? "none" : "auto",
      }}>
      <Form
        style={{ display: "flex" }}
        layout="vertical"
        form={form}
        initialValues={{
          couponBirthday: initialValues,
          discount: initialDiscount,
          type: props.data?.type,
          courseIdApply: props.data?.courseIds,
          applyAllCourse: props.data?.applyAllCourse,
          customerTypesApply: props.data?.customerTypes,
          applyWithOtherCoupon: props.data?.applyWithOtherCoupon,
          showWithAppUser: props.data?.showWithAppUser,
        }}
        onValuesChange={(e) => {
          if (e.couponBirthday) {
            setCouponType(e.couponBirthday);
            form.setFieldValue("type", e.couponBirthday);
          }
        }}>
        <div className="form-left">
          <div className="upload-image">
            <Form.Item label={STRINGS.image} name="imageUrl">
              <div className="input-image">
                {imageUrl.current && !disabled && (
                  <button
                    style={{
                      position: "absolute",
                      alignSelf: "flex-end",
                      top: 10,
                      right: 10,
                      width: "32px",
                      height: "32px",
                      borderWidth: 0,
                      backgroundColor: "white",
                      borderRadius: 8,
                      overflow: "hidden",
                      paddingTop: 5,
                      cursor: "pointer",
                    }}
                    onClick={removeImg}>
                    <SvgView svgData={Svgs.delete} />
                  </button>
                )}
                <ImgCrop
                  modalTitle={STRINGS.editImageCrop}
                  modalOk={STRINGS.ok}
                  modalCancel={STRINGS.cancel}
                  rotationSlider
                  aspect={3 / 2}>
                  <Upload
                    disabled={disabled}
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={CommonFunction.beforeUpload}
                    customRequest={handleUpImage}>
                    {imageUrl.current ? (
                      <img
                        src={imageUrl.current}
                        alt="img"
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </ImgCrop>
              </div>
            </Form.Item>
          </div>
          {renderCouponSetting()}
        </div>
        {rightForm()}
      </Form>
      <div className="footer-model">
        <ButtonCustom
          onClick={props.disableModal}
          isCancelButton
          style={{ marginRight: 10 }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom loading={loading} onClick={onConfirm}>
          {props.data?.title ? STRINGS.update : STRINGS.save}
        </ButtonCustom>
      </div>
      {loading && <LoadingAbsolute />}
    </Col>
  );
});

export default CouponForm;
