import React, { useState } from "react";
import { message, Space, Table } from "antd";
import { useQuery, useQueryClient } from "react-query";
import { PlusCircleOutlined } from "@ant-design/icons";

import { FetchApi } from "../../api";
import {
  AlertService,
  Container,
  ModalCustomService,
  LoadingView,
  ErrorView,
  SvgView,
  ButtonCustom,
} from "../../elements";
import { CommonFunction } from "../../util/CommonFunction";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import { Svgs, STRINGS } from "../../constants";
import Add from "./items/Add";
import Edit from "./items/Edit";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";

const Gacha = () => {
  const [pagination, setPagination] = useState({ size: 10, page: 1 });
  const cache = useQueryClient();
  const { data, isFetching, isLoading } = useQuery(
    [`GachaList-${pagination.size}-${pagination.page}`],
    () => FetchApi.getAllGacha({ page: pagination.page, size: pagination.size })
  );
  useScrollByMouse(data);

  const columns = [
    {
      title: STRINGS.winning_digits,
      dataIndex: "numberOfDigits",
      key: "numberOfDigits",
      align: "center",
      width: 120,
    },
    {
      title: STRINGS.award_name,
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: STRINGS.points_awarded,
      dataIndex: "prizePoint",
      key: "prizePoint",
      align: "center",
      width: 260,
    },
    {
      title: STRINGS.image,
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl) => (
        <img
          style={{ width: 320, height: 100 }}
          src={
            imageUrl ||
            "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/5f75afb3-6220-413b-9823-56461b42b3873d7b3552-e660-43b2-8e35-6eec209c8543e5f33cf1-c54d-44d9-905a-f42ae506c3184088a280-f631-43c9-ad4c-58dd843cfd75.png"
          }
          alt="img-coupon"
        />
      ),
      align: "center",
    },
    {
      key: "action",
      render: (text, record) => {
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onEdit(record)}>
              <SvgView svgData={Svgs.edit} />
            </button>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={removeItem(record)}>
              <SvgView svgData={Svgs.delete} />
            </button>
          </Space>
        );
      },
    },
  ];
  const setData = () => {
    cache.refetchQueries(`GachaList-${pagination.size}-${pagination.page}`);
  };
  const onAdd = () => {
    ModalCustomService.set({
      title: STRINGS.add_new,
      wrapperStyle: { maxWidth: 600 },
      content: <Add data={data} setData={setData} />,
    });
  };
  const onEdit = (record) => () => {
    ModalCustomService.set({
      title: STRINGS.update,
      wrapperStyle: { maxWidth: 600 },
      content: <Edit item={record} data={data} setData={setData} />,
    });
  };

  const removeItem = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${STRINGS.confirm_delete_common}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteGacha(item.id);
        if (result.success) {
          setData([...data.gachaDTOList, "delete"]);
          message.success(STRINGS.deleted_common);
        } else {
          CommonFunction.logError(result);
        }
      },
    });
  };

  if (isLoading) {
    return <LoadingView />;
  }

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Container
      title={STRINGS.gacha_list}
      renderRight={() => (
        <ButtonCustom onClick={onAdd} icon={<PlusCircleOutlined />}>
          {STRINGS.add_new}
        </ButtonCustom>
      )}>
      <Table
        loading={isFetching || isLoading}
        rowKey={"id"}
        columns={columns}
        dataSource={data?.gachaDTOList || []}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
        scroll={{ x: "auto", y: "calc(100vh - 350px)" }}
      />
      <PaginationWithSizeChange
        size={pagination.size}
        total={data?.pageDto?.totalElements || 0}
        onChange={(pageNumber) => {
          setPagination({ size: pagination.size, page: pageNumber });
        }}
        onShowSizeChange={(e) => {
          setPagination({ size: e.size, page: 1 });
        }}
        current={pagination.page}
      />
    </Container>
  );
};

export default Gacha;
