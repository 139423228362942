import React from "react";
import { Button, Col, Row, message } from "antd";
import moment from "moment";

import { COLORS, LOGIC, STRINGS } from "../../../constants";
import {
  useMutation,
  useQueryClient,
  queryCache,
  QueryClient,
} from "react-query";
import { FetchApi } from "../../../api";
import { AlertService, ModalCustomService } from "../../../elements";
import { staffScheduleKey } from "../modules/staffScheduleKey";
import useGetCouponsSelected from "../modules/useGetCouponsSelected";

function ModalBookingDetailPayment({
  disabled,
  total,
  disablePay,
  isChargeCancelFee,
  form,
  data,
  dataBooking,
  onUpdateBooking,
}) {
  const couponsDefault = dataBooking?.coupons || [];
  const refetchQuery = useQueryClient();
  const { checkCouponIsChanged } = useGetCouponsSelected(form, couponsDefault);
  const mutaion = useMutation({
    mutationFn: FetchApi.paidBooking,
    mutationKey: ["paidBooking", data.id],
  });

  const onConfirm = async () => {
    if (checkCouponIsChanged) {
      let selectedDate;
      if (moment().hour() < 5) {
        selectedDate = moment().subtract(1, "day").format("MM/DD/YYYY");
      } else selectedDate = moment().format("MM/DD/YYYY");
      const lastTimeCallApi = "";
      const resultUpdate = await onUpdateBooking(data);
      if (resultUpdate.success) {
        mutaion.mutate(data.id, {
          onSuccess: async (response) => {
            if (response.success) {
              message.success(STRINGS.pay_booking_success);
            } else message.error(STRINGS.network_error);
          },
        });
        await FetchApi.getCalendarOrderForAdmin(
          selectedDate,
          checkCouponIsChanged,
          lastTimeCallApi
        );
        AlertService.removeEventListener();
        ModalCustomService.close();
        refetchQuery.refetchQueries(staffScheduleKey.paidBooking);
        return;
      }
    }
    mutaion.mutate(data.id, {
      onSuccess: (response) => {
        if (response.success) {
          refetchQuery.refetchQueries(staffScheduleKey.paidBooking);
          message.success(STRINGS.pay_booking_success);
          AlertService.removeEventListener();
          ModalCustomService.close();
          return;
        }
        message.error(STRINGS.network_error);
      },
    });
  };
  const renderCombo = () => {
    const { comboDTOS } = form.getFieldValue();
    if (!comboDTOS || comboDTOS.length === 0 || !comboDTOS[0]?.id) {
      return (
        <Col style={{ fontWeight: "bold" }}>
          {data.minutes}
          {STRINGS.minute}
        </Col>
      );
    }
    return (
      <Col>
        {comboDTOS.map((i, index) => (
          <Col
            key={i.id}
            style={{ fontWeight: "bold", whiteSpace: "pre-line" }}
          >
            {i.comboName + "\xa0\xa0"}
            {i.minutes}
            {STRINGS.minute + "\n"}
            {!!i.minuteExtra && (
              <Row>
                +{STRINGS.extension + "\xa0\xa0"}
                {i.minuteExtra}
                {STRINGS.minute + "\xa0\xa0"}
                {/* {i.priceExtra}
                {STRINGS.yen} */}
              </Row>
            )}
          </Col>
        ))}
      </Col>
    );
  };
  const renderDiscount = (couponSelected = []) => {
    return (
      <div style={{ fontWeight: "bold" }}>
        {couponSelected.map((i) => {
          return (
            <div key={`${i?.id}`}>
              <Col>
                {i?.title || STRINGS.coupon_management}
                {"\xa0 \xa0"} {"-" + (i?.discount || 0)}
                {STRINGS.yen}
              </Col>
              {!!data?.pointUse && (
                <Col>
                  {STRINGS.discount_point}
                  {"\xa0 \xa0"} {"-" + (data?.pointUse || 0)}
                  pt({STRINGS.yen})
                </Col>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const contentPay = () => {
    const { couponSelected } = form.getFieldValue();
    return (
      <div
        style={{ fontSize: 25, color: COLORS.sub_accent, fontWeight: "bold" }}
      >
        <Row>
          <Col span={6}>{STRINGS.course}</Col>
          {renderCombo()}
        </Row>
        {(couponSelected?.length > 0 || !!data?.pointUse) && (
          <Row>
            <Col span={6}>{STRINGS.discount_book}</Col>
            {renderDiscount(couponSelected)}
          </Row>
        )}

        {!!data.chooseStaff && (
          <Row>
            <Col span={6}>{STRINGS.name2}</Col>
            <Col>{data.staffName}</Col>
          </Row>
        )}
        <Row style={{ alignItems: "center" }}>
          <Col span={6}>{STRINGS.money_book}</Col>
          <Col style={{ color: COLORS.primary, fontSize: 60 }}>
            {total}
            {STRINGS.yen}
          </Col>
        </Row>
      </div>
    );
  };
  const dataAlert = {
    onConfirm: onConfirm,
    styleModal: { minWidth: 600 },
    title: STRINGS.cash_register,
    textConfirm: STRINGS.confirm,
    content: contentPay(),

    confirmStyle: {
      fontWeight: "bold",
      fontSize: 22,
      width: 150,
    },
    titleStyle: {
      fontWeight: "bold",
      fontSize: 22,
    },
    cancelStyle: {
      fontWeight: "bold",
      fontSize: 22,
      width: 150,
    },
  };

  const checkIsAfterEndTime = moment().isAfter(data?.timeFinishWorking);
  let colorText = "#F19834";
  let colorCash = "#FE6C06";
  if (disabled || data.paid || !checkIsAfterEndTime) {
    colorText = COLORS.disabled;
  }
  if (
    disabled ||
    data.paid ||
    disablePay ||
    !isChargeCancelFee ||
    !checkIsAfterEndTime
  ) {
    colorCash = COLORS.disabled;
  }

  return (
    <Col>
      <Row
        style={{
          justifyContent: "flex-end",
          fontSize: 40,
          fontWeight: "bold",
          color: COLORS.sub_accent,
        }}
      >
        <span>{STRINGS.total + ":\xa0\xa0"}</span>
        <span
          style={{
            color: colorText,
          }}
        >
          {LOGIC.currencyYenFormatter(total)}
        </span>
      </Row>
      <Row style={{ justifyContent: "flex-end" }}>
        <Button
          className="btn-payment"
          disabled={
            disabled ||
            data.paid ||
            disablePay ||
            !isChargeCancelFee ||
            !checkIsAfterEndTime
          }
          onClick={() => {
            AlertService.set(dataAlert);
          }}
          style={{
            backgroundColor: colorCash,
          }}
        >
          <Row className="icon">¥</Row>
          <Row className="text">{STRINGS.cash_register}</Row>
        </Button>
      </Row>
      {!isChargeCancelFee && (
        <Row
          style={{
            justifyContent: "flex-end",
            color: COLORS.required,
            fontWeight: "bold",
            whiteSpace: "pre-line",
            textAlign: "left",
          }}
        >
          {STRINGS.payment_des}
        </Row>
      )}
    </Col>
  );
}
export { ModalBookingDetailPayment };
