/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "antd";
import { CheckSquareFilled, BorderOutlined } from "@ant-design/icons";

import { COLORS, LOGIC } from "../../../constants";
import { Validate } from "../../../elements";

import "./CouponForm.scss";

function DayItem({ item, isDisabled, onChange, value }) {
  const [isSelect, setIsSelect] = useState(() => {
    const indexNote = value.findIndex((i) => i === item);
    if (indexNote > -1) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    const indexNote = value.findIndex((i) => i === item);
    if (indexNote > -1) {
      setIsSelect(true);
    } else {
      setIsSelect(false);
    }
  }, [value]);

  const renderIcon = () => {
    let backgroundColor;
    if (isDisabled) {
      backgroundColor = COLORS.disabled;
    }
    if (isSelect) {
      return (
        <CheckSquareFilled
          style={{ fontSize: 28, color: COLORS.primary, backgroundColor }}
        />
      );
    }
    return (
      <BorderOutlined
        style={{ fontSize: 28, color: COLORS.border, backgroundColor }}
      />
    );
  };

  return (
    <Col
      xl={8}
      xs={24}
      style={{ marginTop: "5px", marginBottom: "5px" }}
      onClick={() => {
        if (isDisabled) {
          return;
        }
        const indexNote = value.findIndex((i) => i === item);
        setIsSelect(!isSelect);
        if (indexNote > -1) {
          const arrClone = [...value];
          arrClone.splice(indexNote, 1);
          onChange(arrClone);
          return;
        }
        onChange([...value, item]);
      }}>
      <Row style={{ alignItems: "center" }}>
        {renderIcon()}
        {"\xa0" + LOGIC.day[item]}
      </Row>
    </Col>
  );
}

function CouponDayContent({ value, onChange, isDisabled, couponType }) {
  useCallback(() => {
    if (couponType !== LOGIC.coupon_time.day) {
      onChange([]);
    }
  }, [couponType]);
  return (
    <Row style={{ paddingTop: 15 }}>
      {Object.keys(LOGIC.day).map((item) => {
        return (
          <DayItem
            key={`${item}`}
            item={item}
            value={value}
            isDisabled={isDisabled}
            onChange={onChange}
          />
        );
      })}
    </Row>
  );
}

function CouponDay({ form, couponType, initialValue }) {
  const [rules, setRules] = useState([]);
  useCallback(() => {
    if (couponType === LOGIC.coupon_time.day) {
      setRules([Validate.emptyContent]);
    } else {
      setRules([]);
      form.resetFields();
    }
  }, [couponType]);
  return (
    <Form.Item
      initialValue={
        couponType === LOGIC.coupon_time.day
          ? initialValue.daysOfWeek || []
          : []
      }
      rules={rules}
      name={"daysOfWeek"}>
      <CouponDayContent
        form={form}
        isDisabled={couponType !== LOGIC.coupon_time.day}
        couponType={couponType}
      />
    </Form.Item>
  );
}

export { CouponDay };
