/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, memo, useRef, useEffect } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  DatePicker,
  message,
  Select,
  Checkbox,
  Radio,
  Space,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";

import { STRINGS, LOGIC } from "../../constants/Strings";
import {
  Container,
  ButtonCustom,
  AlertService,
  SuffixIconDown,
} from "../../elements";
import "../../util/variables.scss";
import "./StoreProvidePoint.scss";
import { Validate } from "../../elements/Validate";

import { FetchApi } from "../../api";
import Auth from "../../store/Authentication";
import { StoreProvidePointListStaff } from "./items/StoreProvidePointListStaff";
import { COLORS } from "../../constants";

const GetUserApp = ({ value, onChange }) => {
  const [options, setOptions] = useState([]);

  const timeout = useRef();

  const fetch = async (searchText = "") => {
    let result = await FetchApi.getUserApp({
      page: 1,
      size: 50,
      search: searchText,
    });
    if (!Array.isArray(result?.data) || result?.data?.length < 1) {
      onChange && onChange("");
      setOptions([]);
      message.warning(STRINGS.unavailable_user);
      return;
    }
    const filterData=result?.data?.filter(item=>!item.deleted)
    setOptions(filterData || []);
  };

  useEffect(() => {
    fetch();
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, []);

  const onSelect = (e, item) => {
    if (!item?.user?.name) {
      onChange && onChange("");
      return;
    }
    onChange && onChange(item.user);
  };
  const onSearch = (searchText) => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(async () => {
      fetch(searchText);
    }, 800);
  };

  return (
    <Select
      showSearch
      onSearch={onSearch}
      filterOption={false}
      onSelect={onSelect}
      suffixIcon={<SearchOutlined />}
      placeholder={STRINGS.search_provide_point_place_holder}
      optionLabelProp={"title"}>
      {(options || []).map((item) => {
        return (
          <Select.Option
            key={`${item.id}`}
            value={`${item.id}`}
            user={item}
            title={item.name}>
            <div style={{ borderWidth: 20 }}>
              <div style={{ fontWeight: "bold" }}>
                {item.name} - {item.userName}
              </div>
              <div style={{ color: "grey" }}>{item.phoneNumber || ""}</div>
            </div>
          </Select.Option>
        );
      })}
    </Select>
  );
};

const StoreInfo = memo(() => {
  const [form] = Form.useForm();
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const { data: dataStore } = useQuery("store-provide-point", () =>
    FetchApi.getAllStoreFilter(1, 50)
  );

  const onEdit = async () => {
    try {
      await form.validateFields();
      AlertService.set({
        textConfirm: STRINGS.confirm,
        title: STRINGS.confirm,
        content: STRINGS.grant_notice,
        onConfirm: async () => {
          const fieldData = form.getFieldValue();
          const data = {
            ...fieldData,
            userId: fieldData.customer?.id,
            customerName: fieldData.customer?.name,
            staffId: fieldData.staff?.staffId,
            staffName: fieldData.staff?.staffName,
          };
          const result = await FetchApi.createSendPoint(data);
          if (result.success) {
            form.resetFields();
            message.success(STRINGS.saved_common);
          } else {
            message.error(result.message || STRINGS.something_wrong);
          }
        },
      });
    } catch (error) {
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  const renderHeaderRight = () => (
    <Row>
      <ButtonCustom
        onClick={form.resetFields}
        isCancelButton
        style={{
          marginRight: 10,
        }}>
        {STRINGS.cancel}
      </ButtonCustom>
      <ButtonCustom onClick={onEdit}>{STRINGS.grant_points}</ButtonCustom>
    </Row>
  );

  return (
    <Container
      className="store-provide-point-wrapper"
      hasBorderBottom
      styleHeader={{ padding: "15px" }}
      style={{ paddingBottom: 300 }}
      renderLeft={() => (
        <div
          style={{
            fontWeight: "bold",
            fontSize: 22,
            color: COLORS.accent,
          }}>
          {STRINGS.store_provide_point}
        </div>
      )}
      renderRight={renderHeaderRight}>
      <Form
        form={form}
        layout="vertical"
        style={{ padding: "0px 15px 55px 15px" }}
        onValuesChange={(e) => {
          if (e.customer) {
            form.setFieldsValue({
              customerId: e.customer.customerId,
              email: e.customer.email,
              phoneNumber: e.customer.phoneNumber,
              furiganaName: e.customer.furiganaName,
            });
          }
        }}>
        <Row gutter={20}>
          <Col xl={8}>
            <Form.Item
              required
              name="customer"
              label={STRINGS.user_name}
              rules={[Validate.emptyContent]}>
              <GetUserApp />
            </Form.Item>
            <Form.Item
              required
              name="customerId"
              label={STRINGS.member_id}
              rules={[Validate.emptyContent]}>
              <Input disabled />
            </Form.Item>
            <Form.Item
              required
              name="email"
              label={STRINGS.email}
              rules={[Validate.emptyContent]}>
              <Input disabled />
            </Form.Item>
            <Form.Item name="phoneNumber" label={STRINGS.phone_number}>
              <Input disabled />
            </Form.Item>
            <Row>
              <Col xl={12}>
                <Row>
                  {STRINGS.gacha}
                  <Form.Item
                    style={{ marginTop: -5, marginLeft: 12 }}
                    name="spinGacha"
                    valuePropName="checked"
                    initialValue={false}>
                    <Checkbox />
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xl={8}>
            <Form.Item
              required
              name="furiganaName"
              label={STRINGS.furigana_name}>
              <Input disabled />
            </Form.Item>
            <Form.Item name="staff" label={STRINGS.staff_name_2}>
              <StoreProvidePointListStaff />
            </Form.Item>
            {userAdmin.type === "SYSTEM_ADMIN" && (
              <Form.Item
                name="storeId"
                label={STRINGS.store}
                // required
                // rules={[Validate.emptyContent]}
              >
                <Select suffixIcon={<SuffixIconDown />}>
                  {(dataStore?.data || []).map((itm, idx) => {
                    return (
                      <Select.Option key={itm.id} value={itm.id}>
                        {itm.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item
              label={STRINGS.time_of_visit}
              name="timeVisit"
              required
              rules={[Validate.emptyContent]}>
              <DatePicker
                style={{ width: "100%", borderRadius: 4 }}
                format={LOGIC.timeFormat}
                showTime
                placeholder=""
                clearIcon={false}
              />
            </Form.Item>
            <Form.Item
              name="numberOfPoints"
              label={STRINGS.points}
              required
              rules={[Validate.isPositiveNumber]}>
              <Input />
            </Form.Item>
            <Form.Item
              style={{ alignSelf: "flex-end" }}
              name="pointSendType"
              initialValue={
                userAdmin.type === "SYSTEM_ADMIN" ? "special" : "default"
              }>
              <Radio.Group>
                <Space direction="vertical">
                  {userAdmin.type === "SYSTEM_ADMIN" && (
                    <Radio value={"special"}>{STRINGS.special_point}</Radio>
                  )}
                  <Radio value={"default"}>{STRINGS.visit_point}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Container>
  );
});

export default StoreInfo;
