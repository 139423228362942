import React, { useRef } from "react";
import { Col, message, Row } from "antd";
import { useQueryClient } from "react-query";

import { COLORS, STRINGS } from "../../../constants";
import { ButtonCustom, ModalCustomService } from "../../../elements";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util/CommonFunction";
import Auth from "../../../store/Authentication";
import RadioAllCancelStaff from "../../../elements/RadioAllCancelStaff";

export function ModalStaffCancelBooking({
  dataBooking,
  refetch,
  onClose,
  status,
  form,
  isChargeCancelFee,
  setIsChargeCancelFee,
}) {
  const cache = useQueryClient();
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const staffChangeRequest = useRef({});

  const onCancelBooking = async () => {
    try {
      if (!staffChangeRequest.current.id) {
        message.warning(STRINGS.please_select_a_reception_staff);
        return;
      }
      if (!staffChangeRequest.current.password) {
        message.warning(STRINGS.please_type_password);
        return;
      }
      if (isChargeCancelFee) {
        const result = await FetchApi.chargeCancelFee({
          userId: dataBooking.userId,
          staffIdAndPasswordRequest: {
            staffId: staffChangeRequest.current.id,
            password: staffChangeRequest.current.password,
          },
        });
        if (result.success) {
          setIsChargeCancelFee(true);
          message.success(STRINGS.charge_cancel_fee_success);
          refetch();
          onClose();
          return;
        }
        if (result.code === 4004) {
          message.error(STRINGS.unavailable_user);
          return;
        }
        message.error(result.message);
        return;
      }
      const result = await FetchApi.cancelBooking({
        id: dataBooking.id,
        status,

        staffIdAndPasswordRequest: {
          staffId: staffChangeRequest.current.id,
          password: staffChangeRequest.current.password,
        },
      });

      if (result.success) {
        cache.refetchQueries("bookingComing");
        cache.refetchQueries(`getDetailCourseBooking-${dataBooking.id}`);
        cache.refetchQueries(`bookingComing-${userAdmin.storeId}`);

        refetch();
        message.success(STRINGS.canceled_common);
        refetch && refetch();
        ModalCustomService.close();
        return;
      }
      CommonFunction.logError(result);
    } catch (error) {}
  };

  return (
    <Col className="checkin-time-wrapper" style={{ paddingTop: 10 }}>
      <RadioAllCancelStaff onChange={(e) => (staffChangeRequest.current = e)} />

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={onClose}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom
          onClick={onCancelBooking}
          style={{
            backgroundColor: COLORS.primary,
            borderColor: COLORS.primary,
            color: COLORS.background,
          }}>
          {STRINGS.sure}
        </ButtonCustom>
      </Row>
    </Col>
  );
}
