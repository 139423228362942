import { message } from "antd";
import moment from "moment";

import Auth from "../store/Authentication";
import { Api, STRINGS } from "../constants";

const URL_API = process.env.REACT_APP_API;
const CANT_CONNECT_INTERNET = "Can't connect internet";
// const NETWORK_ERROR = "Network error";
// const SERVER_ERROR = "Server error";
const SIZE = 10;

let responseRefresh;

const refreshToken = async () => {
  // const refresh_token = encodeURIComponent(account.refresh_token);
  const header = {
    method: "POST",
    Accept: "application/json",
    // "Access-Control-Request-Headers": "*",
  };
  const api = Api.refreshToken(Auth.getRefreshToken());
  try {
    if (!responseRefresh) {
      responseRefresh = true;
      responseRefresh = await fetch(api, header);
    }
    const res = await responseRefresh.json();

    if (res.success) {
      Auth.setAccessToken(res.accessToken);
      return {
        success: true,
        access_token: res.accessToken,
      };
    }

    if (responseRefresh.status === 500) {
      Auth.logout();
      window.location.href = "/login";
    }
    return {
      success: false,
      message: STRINGS.server_error,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
    };
  } finally {
    responseRefresh = undefined;
  }
};

function fetchWithTimeOut(promise, ms = 25000) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error(CANT_CONNECT_INTERNET));
    }, ms);
    promise.then(resolve, reject);
  });
}

const CommonCall = async (api, header) => {
  console.log("api", api);
  try {
    const accessToken = Auth.getAccessToken();
    let headers;
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Request-Headers": "*",
      };
    } else {
      headers = {
        Accept: "application/json",
      };
    }
    if (header && (header.method === "POST" || header.method === "PUT")) {
      headers = {
        ...headers,
        "Content-Type": "application/json",
      };
    }
    let head = {
      ...header,
      headers,
    };

    const response = await fetchWithTimeOut(
      fetch(api, {
        ...head,
        credentials: "omit",
      })
    );
    if (response.status === 500) {
      return {
        code: response.status,
        message: STRINGS.server_error,
        success: false,
      };
    }

    if (response.status === 200) {
      return await response.json();
    }

    if (response.status === 401) {
      //refresh token
      const resToken = await refreshToken();

      if (resToken.success) {
        const newHeaders = {
          ...headers,
          Authorization: `Bearer ${resToken.access_token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Request-Headers": "*",
        };
        const newHead = {
          ...head,
          headers: newHeaders,
        };
        const responseRefeshToken = await fetchWithTimeOut(fetch(api, newHead));
        const resultRefeshToken = await responseRefeshToken.json();
        return { ...resultRefeshToken, success: true };
      }
      return {
        code: response.code,
        success: false,
      };
    }

    const resJson = await response.json();
    return {
      code: resJson.status,
      message: resJson.message,
      success: false,
    };
  } catch (error) {
    return {
      success: false,
      message: STRINGS.network_error,
    };
  }
};

const FetchApi = {
  login: async (data) => {
    const body = {
      email: data.email,
      password: data.password,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.login;
    const result = await CommonCall(api, header);
    return result;
  },
  resetPasswordEmail: async (email) => {
    const header = {
      method: "POST",
    };
    const api = `${URL_API}v2/forgotPasswordAdmin?email=${email}`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateStaffPassword: async ({ password, staffId }) => {
    const header = {
      method: "PUT",
    };
    const api = `${URL_API}api/v3/admin/updateStaffPassword?staffId=${staffId}&password=${password}`;
    const result = await CommonCall(api, header);
    return result;
  },
  resetStaffPassword: async ({ password, staffId }) => {
    const header = {
      method: "PUT",
    };
    const api = `${URL_API}api/v3/admin/reset-staff-password?staffId=${staffId}&password=${password}`;
    const result = await CommonCall(api, header);
    return result;
  },
  defaultResetStaffPassword: async () => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v3/admin/default-reset-staff-password`;
    const result = await CommonCall(api, header);
    return result;
  },
  confirmEmailResetPassword: async (obj) => {
    const header = {
      method: "POST",
      // body: JSON.stringify(obj),
    };
    const api = `${URL_API}v2/confirmEmailResetPassword?code=${obj.code}&email=${obj.email}`;
    const result = await CommonCall(api, header);
    return result;
  },
  confirmChangePassword: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}v2/confirmChangePassword`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateStaffStatusFlow: async (data) => {
    const header = {
      method: "PUT",
      body: data,
    };
    const api = `${URL_API}api/v3/admin/staff-status-flow`;
    const result = await CommonCall(api, header);
    if (result.success) message.success("更新しました。");
    return result;
  },
  getCoupon: async (obj) => {
    const api = Api.getCoupon({
      page: obj.page,
      size: obj.size,
      storeId: obj.storeId === "null" || !obj.storeId ? null : obj.storeId,
    });
    const result = await CommonCall(api);
    if (result.success) {
      return {
        data: [...(result.couponDTOList || [])],
        total: result.pageDto ? result.pageDto.totalElements : 0,
      };
    } else {
      message.error(result.message || STRINGS.something_wrong);
    }
    return result;
  },

  getUserApp: async (obj) => {
    const api = Api.getUserApp({
      page: obj.page,
      size: obj.size || SIZE,
      search: obj.search === "null" || !obj.search ? null : obj.search,
      sex: obj.sex === "null" || !obj.sex ? null : obj.sex,
      storeId: obj.storeId === "null" || !obj.storeId ? null : obj.storeId,
      customerType:
        obj.customerType === "null" || !obj.customerType
          ? null
          : obj.customerType,
      sort: obj.sort,
      includeDeleted: obj.includeDeleted,
    });
    const header = {
      method: "GET",
    };
    const result = await CommonCall(api, header);
    if (result.success) {
      let dataReturn = {
        data: [...(result.users || [])],
        total: result.pageDto ? result.pageDto.totalElements : 0,
        success: true,
        last: result.pageDto?.last,
      };
      if (Array.isArray(result?.users)) {
        const arrAfterInsertIndex = result.users.map((item, index) => {
          return { ...item, index: index + 1 };
        });
        dataReturn = { ...dataReturn, data: arrAfterInsertIndex };
      }
      return dataReturn;
    } else {
      message.error(result.message || STRINGS.something_wrong);
    }
    return result;
  },
  getBookingManualHistory: async (obj) => {
    const api = Api.getBookingManualHistory({
      page: obj.page,
      size: obj.size || SIZE,
      searchText:
        obj.search === "null" || !obj.searchText ? null : obj.searchText,
      staffId: obj.staffId === "null" || !obj.staffId ? null : obj.staffId,
      from: obj.from,
      to: obj.to,
    });
    const header = {
      method: "GET",
    };
    const result = await CommonCall(api, header);
    if (result.success) {
      let dataReturn = {
        data: [...(result.bookingHistories || [])],
        revenue: result.revenue,
        total: result.page ? result.page.totalElements : 0,
        success: true,
        last: result.page?.last,
      };
      if (Array.isArray(result?.bookingHistories)) {
        const arrAfterInsertIndex = result.bookingHistories.map(
          (item, index) => {
            return { ...item, index: index + 1 };
          }
        );
        dataReturn = { ...dataReturn, data: arrAfterInsertIndex };
      }
      return dataReturn;
    } else {
      message.error(result.message || STRINGS.something_wrong);
    }
    return result;
  },

  updateUserByAdmin: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/updateUserByAdmin`;
    const result = await CommonCall(api, header);
    return result;
  },
  downloadUsers: async ({ search, sex, storeId, sort }) => {
    const sex1 = sex === "null" || !sex ? null : sex;
    const search1 = search === "null" || !search ? null : search;
    const storeId1 = storeId === "null" || !storeId ? null : storeId;
    const result = await downFile(
      `${URL_API}api/v2/admin/downloadUsers?${
        search1 ? "&search=" + search1 : ""
      }${sex1 ? "&sex=" + sex1 : ""}${storeId1 ? "&storeId=" + storeId1 : ""}${
        sort ? "&sort=" + sort : ""
      }`,
      "GET",
      `users_${search1 || ""}_${storeId1 || ""}_${sex1 || ""}_${
        sort || ""
      }.xlsx`
    );
    return result;
  },
  downloadFixedSchedule: async ({ firstDayOfMonth }) => {
    const result = await downFile(
      `${URL_API}api/v2/admin/download-shift-schedule?firstDayOfMonth=${firstDayOfMonth}`,
      "POST",
      `シフト_${firstDayOfMonth}.xlsx`
    );
    return result;
  },
  downloadActualSchedule: async ({ firstDayOfMonth }) => {
    const result = await downFile(
      `${URL_API}api/v2/admin/download-shift?firstDayOfMonth=${firstDayOfMonth}`,
      "POST",
      `スケジュール_${firstDayOfMonth}.xlsx`
    );
    return result;
  },
  downloadExpectedSchedule: async ({ firstDayOfMonth }) => {
    const result = await downFile(
      `${URL_API}api/v2/admin/download-monthly-shift-schedule?firstDayOfMonth=${firstDayOfMonth}`,
      "POST",
      `勤務契約_${firstDayOfMonth}.xlsx`
    );
    return result;
  },
  deActiveUserApp: async (id, email) => {
    const api = Api.deActiveUserApp(id, email);
    const header = {
      method: "GET",
    };
    const result = await CommonCall(api, header);
    return result;
  },
  chargeCancelFee: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.chargeCancelFee;

    const result = await CommonCall(api, header);
    return result;
  },
  getHistoryCancelBooking: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = Api.getHistoryCancelBooking;

    const result = await CommonCall(api, header);
    return result;
  },
  getAllReview: async ({
    name = null,
    storeId = null,
    page,
    size = 10,
    staffId,
  }) => {
    const header = {
      method: "GET",
    };
    const api = Api.getAllReview({
      name,
      page,
      size,
      storeId,
      staffId,
    });
    const result = await CommonCall(api, header);
    if (result.success) {
      return {
        data: [...(result.reviewDTOS || [])],
        total: result.pageDto ? result.pageDto.totalElements : 0,
      };
    } else {
      message.error(result.message || STRINGS.something_wrong);
    }
    return result;
  },
  getAllReviewForAdmin: async ({
    name = null,
    storeId = null,
    page,
    size = 10,
    staffId,
  }) => {
    const header = {
      method: "GET",
    };
    const api = Api.getAllReviewForAdmin({
      name,
      page,
      size,
      storeId,
      staffId,
    });
    const result = await CommonCall(api, header);
    if (result.success) {
      return {
        data: [...(result.reviewBookingDTOS || [])],
        total: result.pageDto ? result.pageDto.totalElements : 0,
      };
    } else {
      message.error(result.message || STRINGS.something_wrong);
    }
    return result;
  },
  deleteReview: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = `${URL_API}api/v2/admin/deleteReview?id=${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  downloadReviews: async ({ nameSearch, storeId, staffId }) => {
    const staffId1 = staffId === "null" || !staffId ? null : staffId;
    const storeId1 = storeId === "null" || !storeId ? null : storeId;

    const result = await downFile(
      `${URL_API}api/v2/admin/downloadReviews?${
        nameSearch ? "&nameSearch=" + nameSearch : ""
      }${staffId1 ? "&staffId=" + staffId1 : ""}${
        storeId1 ? "&storeId=" + storeId1 : ""
      }`,
      "GET",
      `reviews_${nameSearch || ""}_${storeId1 || ""}_${staffId1 || ""}.xlsx`
    );
    return result;
  },
  downloadReviewsNew: async ({ search, storeId, staffId }) => {
    const staffId1 = staffId === "null" || !staffId ? null : staffId;
    const storeId1 = storeId === "null" || !storeId ? null : storeId;

    const result = await downFile(
      `${URL_API}api/v2/admin/download-review-booking?${
        search ? "&search=" + search : ""
      }${staffId1 ? "&staffId=" + staffId1 : ""}${
        storeId1 ? "&storeId=" + storeId1 : ""
      }`,
      "GET",
      `reviews_${search || ""}_${storeId1 || ""}_${staffId1 || ""}.xlsx`
    );
    return result;
  },
  getAllNotification: async (page, size = 10) => {
    const header = {
      method: "GET",
    };
    const api = Api.getAllNotification(page, size);
    const result = await CommonCall(api, header);
    if (result.success) {
      return {
        data: [...(result.notifications || [])],
        total: result.pageDto ? result.pageDto.totalElements : 0,
      };
    } else {
      message.error(result.message || STRINGS.something_wrong);
    }
    return result;
  },

  uploadFile: async (file) => {
    var formData = new FormData();
    formData.append("file", file.file || file);
    const header = {
      method: "POST",
      body: formData,
    };
    const api = Api.uploadFile;
    try {
      const result = await fetch(api, header);
      const responseJSON = await result.json();
      return responseJSON;
    } catch (error) {
      // message.warning(NETWORK_ERROR);
    }
  },
  uploadFileSound: async (file) => {
    var formData = new FormData();
    const accessToken = Auth.getAccessToken();
    formData.append("soundFile", file.file);
    const header = {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const api = Api.uploadFileSound;
    try {
      const result = await fetch(api, header);
      const responseJSON = await result.json();
      return responseJSON;
    } catch (error) {
      // message.warning(NETWORK_ERROR);
    }
  },
  //slide
  getAllSlides: async () => {
    const header = {
      method: "GET",
    };
    const api = Api.getAllSlides;
    const result = await CommonCall(api, header);
    return result;
  },
  updatePositionSlide: async (arrayPosition) => {
    const body = {
      slidePosition: arrayPosition,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = `${URL_API}api/v2/admin/changePositionSlide`;
    const result = await CommonCall(api, header);
    return result;
  },
  createSlide: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = Api.slide;
    const result = await CommonCall(api, header);
    return result;
  },
  editSlide: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.slide;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteSlide: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = Api.deleteSlide(id);
    const result = await CommonCall(api, header);
    return result;
  },

  pushNotification: async ({
    content,
    description,
    imageUrl,
    all,
    title,
    type,
    userIds,
    storeId,
    endTime,
    startTime,
    showPopup,
  }) => {
    const body = {
      content,
      description,
      imageUrl,
      sendAll: all,
      title,
      type,
      userIds,
      storeId,
      endTime,
      startTime,
      showPopup,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.pushNotification;
    const result = await CommonCall(api, header);
    return result;
  },

  updatePushNotification: async ({
    id,
    content,
    description,
    imageUrl,
    all,
    title,
    type,
    userIds,
    endTime,
    startTime,
    showPopup,
  }) => {
    const body = {
      id,
      content,
      description,
      imageUrl,
      sendAll: all,
      title,
      type,
      userIds,
      endTime,
      startTime,
      showPopup,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = Api.pushNotification;
    const result = await CommonCall(api, header);
    return result;
  },

  //store
  updatePositionStore: async (arrayPosition) => {
    const body = {
      storePosition: arrayPosition,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = Api.updatePositionStore;
    const result = await CommonCall(api, header);
    return result;
  },

  getAllStore: async (page, size = 50) => {
    const header = {
      method: "GET",
    };
    const api = Api.getAllStore({
      page,
      size,
    });
    const result = await CommonCall(api, header);
    if (result.success) {
      return {
        data: [
          ...(result.storeList.sort((a, b) => {
            return b.position - a.position;
          }) || []),
        ],
        total: result.pageDto ? result.pageDto.totalElements : 0,
        success: true,
      };
    } else {
      message.error(result.message || STRINGS.something_wrong);
    }
    return result;
  },

  getAllStoreFilter: async (page, size = 50) => {
    const header = {
      method: "GET",
    };
    const api = Api.getAllStoreFilter({
      page,
      size,
    });
    const result = await CommonCall(api, header);
    if (result.success) {
      return {
        data: result.storeList || [],
        total: result.pageDto ? result.pageDto.totalElements : 0,
      };
    }
    return result;
  },
  findStoreByOwner: async () => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/findStoreByOwner`;
    const result = await CommonCall(api, header);
    return result;
  },

  createCoupon: async (obj) => {
    const body = {
      storeId: obj.storeId,
      title: obj.title,
      imageUrl: obj.imageUrl,
      description: obj.description,
      startDate: obj.startDate,
      endDate: obj.endDate,
      ...obj,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.coupon;
    const result = await CommonCall(api, header);
    return result;
  },
  updateCoupon: async (obj) => {
    const body = {
      id: obj.id,
      storeId: obj.storeId,
      title: obj.title,
      imageUrl: obj.imageUrl,
      description: obj.description,
      startDate: obj.startDate,
      endDate: obj.endDate,
      ...obj,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = Api.coupon;
    const result = await CommonCall(api, header);
    return result;
  },

  deleteCoupon: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = Api.deleteCoupon(id);
    const result = await CommonCall(api, header);
    return result;
  },

  deleteNotification: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = Api.deleteNotification(id);
    const result = await CommonCall(api, header);
    return result;
  },

  createStore: async (obj) => {
    let arrImg = obj.images.map((item) => {
      return item.imageUrl || item.url;
    });
    const body = {
      name: obj.name,
      email: obj.email,
      phoneNumber: obj.phoneNumber,
      address: obj.address,
      longitude: obj.longitude,
      latitude: obj.latitude,
      openTime: moment(obj.openTime).format("HH:mm"),
      closeTime: moment(obj.closeTime).format("HH:mm"),
      nameOwner: obj.nameOwner,
      password: obj.password,
      description: obj.description,
      images: arrImg,
      imageMapUrl: obj.imageMapUrl,
      storeUrl: obj.storeUrl,
      pointReview: obj.pointReview,
      pointCheckIn: obj.pointCheckIn,
      bed: obj.bed,
      storeCode: obj.storeCode,
      enableViewRevenue: obj.enableViewRevenue,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.store;
    const result = await CommonCall(api, header);
    return result;
  },
  changeStorePosition: async (storePosition) => {
    const body = {
      storePosition: storePosition,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = `${URL_API}api/v1/admin/changePosition`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateStore: async (obj) => {
    let arrImg = obj.images.map((item) => {
      return item.imageUrl || item.url;
    });
    const body = {
      id: obj.id,
      email: obj.email,
      name: obj.name,
      phoneNumber: obj.phoneNumber,
      address: obj.address,
      longitude: obj.longitude,
      latitude: obj.latitude,
      openTime: moment(obj.openTime).format("HH:mm"),
      closeTime: moment(obj.closeTime).format("HH:mm"),
      nameOwner: obj.nameOwner,
      password: obj.password,
      description: obj.description,
      images: arrImg,
      imageMapUrl: obj.imageMapUrl,
      storeUrl: obj.storeUrl,
      pointReview: obj.pointReview,
      pointCheckIn: obj.pointCheckIn,
      bed: obj.bed,
      storeCode: obj.storeCode,
      enableViewRevenue: obj.enableViewRevenue,
      numberOfExtraRestTime: obj.numberOfExtraRestTime,
      numberOfExtraRestTimeMinutes: obj.numberOfExtraRestTimeMinutes,
      note: obj.note,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = Api.store;
    const result = await CommonCall(api, header);
    return result;
  },

  deleteStore: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = Api.deleteStore(id);
    const result = await CommonCall(api, header);
    return result;
  },

  //news
  getAllNews: async (page, size = 10) => {
    const header = {
      method: "GET",
    };
    const api = Api.getAllNews({
      page,
      size,
    });
    const result = await CommonCall(api, header);
    if (result.success) {
      return {
        success: true,
        data: [...(result.newsList || [])],
        total: result.page ? result.page.totalElements : 0,
      };
    } else {
      message.error(result.message || STRINGS.something_wrong);
    }
    return result;
  },
  createNews: async (obj) => {
    const body = {
      content: obj.content,
      description: obj.description,
      imageUrl: obj.imageUrl,
      time: obj.time,
      title: obj.title,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.createNews;
    const result = await CommonCall(api, header);
    return result;
  },
  updateNews: async (obj) => {
    const body = {
      content: obj.content,
      description: obj.description,
      imageUrl: obj.imageUrl,
      time: obj.time,
      title: obj.title,
      id: obj.id,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = Api.updateNews;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteNews: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = Api.deleteNews(id);
    const result = await CommonCall(api, header);
    return result;
  },
  getListSkill: async () => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getAllSkill`;
    const result = await CommonCall(api, header);
    return result;
  },
  getListStaff: async (storeId, page, size = 10, staffId, staffName) => {
    const header = {
      method: "GET",
    };
    const api = Api.getListStaff(storeId, page, size, staffId, staffName);
    const result = await CommonCall(api, header);
    if (result.success) {
      return {
        success: true,
        data: [...(result.staffs || [])],
        total: result.pageDto ? result.pageDto.totalElements : 0,
        pageDto: result.pageDto,
      };
    }
    return result;
  },
  getRatingStaff: async (staffId) => {
    const header = {
      method: "GET",
    };
    const api = Api.getRatingStaff(staffId);
    const result = await CommonCall(api, header);
    if (typeof result !== "number") return null;
    return result;
  },
  getPacksStaff: async (staffId) => {
    const header = {
      method: "GET",
    };
    const api = Api.getPacksStaff(staffId);
    const result = await CommonCall(api, header);
    if (result.success) {
      return result;
    }
    return result;
  },
  getListStaffCancel: async (storeId, page, size = 10, staffId, staffName) => {
    const header = {
      method: "GET",
    };
    const api = Api.getListStaffCancel(storeId, page, size, staffId, staffName);
    const result = await CommonCall(api, header);
    if (result.success) {
      return {
        success: true,
        data: [...(result.staffs || [])],
        total: result.pageDto ? result.pageDto.totalElements : 0,
        pageDto: result.pageDto,
      };
    }
    return result;
  },
  getListStaffNotWorkingStore: async (day, page = 1, size = 50) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getStaffNotWorking?day=${day}&page=${page}&size=${size}`;
    const result = await CommonCall(api, header);
    if (result.success) {
      return {
        success: true,
        data: [...(result.staffs || [])],
        total: result.pageDto ? result.pageDto.totalElements : 0,
        pageDto: result.pageDto,
      };
    }
    return result;
  },
  addStaff: async (obj) => {
    const body = {
      ...obj,
      name: obj.name,
      phoneNumber: obj.phoneNumber,
      avatarUrl: obj.avatarUrl,
      birthday: obj.birthday,
      sex: obj.sex,
      email: obj.email,
      storeId: obj.storeId,
      storeIds: obj.storeIds,
      selfIntroduction: obj.selfIntroduction,
      skillRequests: obj.skillRequests,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.addStaff;
    const result = await CommonCall(api, header);
    return result;
  },
  createStaffByStore: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/createStaffByStore`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateStaff: async (obj) => {
    const body = {
      ...obj,
      staffId: obj.id,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = Api.updateStaff;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteStaff: async (staffId) => {
    const header = {
      method: "DELETE",
    };
    const api = Api.deleteStaff(staffId);
    const result = await CommonCall(api, header);
    return result;
  },
  getStaffById: async (staffId) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getStaffById?staffId=${staffId}`;
    const result = await CommonCall(api, header);
    return result;
  },

  createBooking: async (obj) => {
    const body = {
      date: obj.date + " 00:00:00",
      email: obj.email,
      nameCustomer: obj.nameCustomer,
      packageCareId: obj.packageCareId ? parseInt(obj.packageCareId) : null,
      phoneNumber: obj.phoneNumber || "",
      staffId: obj.staffId ? parseInt(obj.staffId) : null,
      storeId: obj.storeId ? parseInt(obj.storeId) : null,
      time: obj.time,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = Api.createBooking;
    const result = await CommonCall(api, header);
    return result;
  },
  updateBooking: async (obj) => {
    const body = {
      id: parseInt(obj.id),
      date: obj.date + " 00:00:00",
      email: obj.email,
      nameCustomer: obj.nameCustomer,
      packageCareId: obj.packageCareId ? parseInt(obj.packageCareId) : null,
      phoneNumber: obj.phoneNumber || "",
      staffId: obj.staffId ? parseInt(obj.staffId) : null,
      storeId: obj.storeId ? parseInt(obj.storeId) : null,
      time: obj.time,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = Api.updateBooking;
    const result = await CommonCall(api, header);
    return result;
  },

  actionBookingStore: async (action, storeId) => {
    const header = {
      method: "PUT",
    };
    const api = Api.actionBookingStore(action, storeId);
    const result = await CommonCall(api, header);
    return result;
  },

  //point-reception
  getRequestPointByStore: async ({ page, size }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getRequestPointByStore?page=${page}&size=${size}`;
    const result = await CommonCall(api, header);
    return result;
  },
  confirmRequestCheckInPoint: async (id) => {
    const header = {
      method: "PUT",
    };
    const api = `${URL_API}api/v2/admin/confirmRequestCheckInPoint/${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  denyRequestCheckInPoint: async (id) => {
    const header = {
      method: "PUT",
    };
    const api = `${URL_API}api/v2/admin/denyRequestCheckInPoint/${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  confirmRequestUsePoint: async (id) => {
    const header = {
      method: "PUT",
    };
    const api = `${URL_API}api/v2/admin/confirmRequestUsePoint/${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  denyRequestUsePoint: async (id) => {
    const header = {
      method: "PUT",
    };
    const api = `${URL_API}api/v2/admin/denyRequestUsePoint/${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  getHistoryPointByStore: async ({ page, size }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getHistoryPointUserByStore?page=${page}&size=${size}`;
    const result = await CommonCall(api, header);
    return result;
  },
  getHistoryPointStoreAdmin: async ({
    page,
    size,
    endDate,
    startDate,
    nameSearch,
    pointType,
    storeId,
  }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getHistoryVisitUser?storeId=${storeId}&page=${page}&size=${size}&endDate=${endDate}&startDate=${startDate}${
      nameSearch ? "&nameSearch=" + nameSearch : ""
    }${pointType ? "&pointType=" + pointType : ""}`;
    const result = await CommonCall(api, header);
    return result;
  },
  getHistoryVisitUser: async ({
    page,
    size,
    endDate,
    startDate,
    nameSearch,
    pointType,
    storeId,
    staffId,
  }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/get-booking-history?storeId=${storeId}&page=${page}&size=${size}&endDate=${endDate}&startDate=${startDate}${
      nameSearch ? "&nameSearch=" + nameSearch : ""
    }${pointType ? "&pointType=" + pointType : ""}${
      staffId ? "&staffId=" + staffId : ""
    }`;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteHistoryVisitUser: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = `${URL_API}api/v2/admin/deleteHistoryVisitUser?pointId=${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteHistoryVisitStore: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = `${URL_API}api/v2/admin/delete-booking-history?bookingId=${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  downloadAllBookingInToday: async () => {
    const result = await downFile(
      `${URL_API}api/v3/admin/download-today-bookings`,
      "GET",
      `momitoku-today.xlsx`
    );
    return result;
  },
  downloadHistoryVisit: async ({
    storeId,
    startDate,
    endDate,
    nameSearch,
    pointType,
  }) => {
    const result = await downFile(
      `${URL_API}api/v2/admin/downloadHistoryVisit?startDate=${startDate}&endDate=${endDate}&storeId=${storeId}${
        nameSearch ? "&nameSearch=" + nameSearch : ""
      }${pointType ? "&pointType=" + pointType : ""}`,
      "GET",
      `HistoryVisit_${nameSearch || ""}_${storeId || ""}_${startDate || ""}_${
        endDate || ""
      }_${pointType || ""}.xlsx`
    );
    return result;
  },
  downloadHistoryStore: async ({ storeId, startDate, endDate }) => {
    const result = await downFile(
      `${URL_API}api/v2/admin/booking-history/excel/download?storeId=${storeId}&endDate=${endDate}&startDate=${startDate}`,
      "GET",
      `HistoryStore_${storeId || ""}_${startDate || ""}_${endDate || ""}.xlsx`
    );
    return result;
  },
  //getProvidePointManual
  getProvidePointManual: async (bookingTime) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/booking-point-manual?bookingTimeLong=${bookingTime}`;
    const result = await CommonCall(api, header);
    return result;
  },

  // point-history
  getHistoryPointStore: async ({ page, size, year }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getHistoryPointStore?page=${page}&size=${size}&year=${year}`;
    const result = await CommonCall(api, header);
    return result;
  },
  //mail
  getEmailSetting: async ({ page, size }) => {
    const header = {
      method: "GET",
    };
    const api = Api.emailSetting;
    const result = await CommonCall(api, header);
    return result;
  },
  updateEmailSetting: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.emailSetting;
    const result = await CommonCall(api, header);
    return result;
  },
  //gacha
  getAllGacha: async ({ page, size }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getAllGacha?page=${page}&size=${size}`;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteGacha: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = `${URL_API}api/v2/admin/deleteGacha/${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  createGacha: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/createGacha`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateGacha: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/updateGacha`;
    const result = await CommonCall(api, header);
    return result;
  },

  //combo
  createCombo: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/createCombo`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateCombo: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/updateCombo`;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteCombo: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = `${URL_API}api/v2/admin/deleteCombo?comboId=${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  getComboList: async ({ page, size }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getAllCombo?page=${page}&size=${size}`;
    const result = await CommonCall(api, header);
    return result;
  },
  getAllCourseOfCoupon: async ({ couponId }) => {
    const header = {
      method: "GET",
    };
    const api = Api.getAllCourseOfCoupon({ couponId });
    const result = await CommonCall(api, header);
    return result;
  },
  getAllComboCategory: async () => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/comboCategory`;
    const result = await CommonCall(api, header);
    return result;
  },
  updatePositionCombo: async (arrayPosition) => {
    const body = {
      comboPosition: arrayPosition,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = `${URL_API}api/v2/admin/changePositionCombo`;
    const result = await CommonCall(api, header);
    return result;
  },

  //store staff account
  createStaffAdmin: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/createReceptionist`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateStaffAdmin: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/updateReceptionist`;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteStaffAdmin: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = `${URL_API}api/v2/admin/deleteReceptionist?id=${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  getAllStaffAdmin: async ({ page, size }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getAllReceptionist?page=${page}&size=${size}`;
    const result = await CommonCall(api, header);
    return result;
  },

  createSendPoint: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/createSendPoint`;
    const result = await CommonCall(api, header);
    return result;
  },
  orderInfo: async ({ userId }) => {
    const header = {
      method: "GET",
    };
    const api = Api.orderInfo({ userId });
    const result = await CommonCall(api, header);
    return result;
  },

  //booking
  getCalendarOrderForAdmin: async (
    date,
    checkCouponIsChanged,
    lastTimeCallApi
  ) => {
    let time = lastTimeCallApi;
    const header = {
      method: "GET",
    };
    if (checkCouponIsChanged) {
      time = new Date().getTime();
    }
    const api = `${URL_API}api/v2/admin/booking?date=${date}&lastTimeCallApi=${time}`;
    const result = await CommonCall(api, header);
    return result;
  },

  updateScheduleTemporaryByStore: async (obj) => {
    const body = {
      shiftRequests: obj.shiftRequests.map((item) => {
        return {
          id: item.scheduleId,
          endTime: moment(item.endTime).isValid()
            ? moment(item.endTime).format("HH:mm")
            : item.endTime,
          startTime: moment(item.startTime).isValid()
            ? moment(item.startTime).format("HH:mm")
            : item.startTime,
          session: item.session,
        };
      }),
      staffChangeRequest: {
        ...obj.staffChangeRequest,
        type: obj.type, //update_shift, on_leave
      },
      workingDay: obj.workingDay,
      type: obj.type,
      staffId: obj.staffId,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = `${URL_API}api/v2/admin/updateShift`;
    const result = await CommonCall(api, header);
    return result;
  },
  getStaffByComboPacks: async ({
    desiredTime,
    storeId,
    comboPackIdsRequests,
  }) => {
    const body = {
      desireTime: desiredTime,
      storeId: storeId,
      comboPackIdsRequests: comboPackIdsRequests,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const result = await CommonCall(Api.getStaffByComboPacks, header);
    return result;
  },
  getStaffByNotComboPacks: async ({ storeId }) => {
    const header = {
      method: "GET",
    };
    const api = Api.getStaffWorkingToday({ storeId });
    const result = await CommonCall(api, header);
    return result;
  },
  suggestBooking: async (dataBooking) => {
    const header = {
      method: "POST",
      body: JSON.stringify(dataBooking),
    };

    const result = await CommonCall(Api.suggestBooking, header);
    return result;
  },
  createOrderRoleAdmin: async (dataBooking) => {
    const header = {
      method: "POST",
      body: JSON.stringify(dataBooking),
    };

    const result = await CommonCall(Api.createOrderRoleAdmin, header);
    return result;
  },
  createShift: async (obj) => {
    const body = {
      shiftRequests: obj.shiftRequests.map((item) => {
        return {
          id: item.scheduleId,
          endTime: moment(item.endTime).isValid()
            ? moment(item.endTime).format("HH:mm")
            : item.endTime,
          startTime: moment(item.startTime).isValid()
            ? moment(item.startTime).format("HH:mm")
            : item.startTime,
          session: item.session,
        };
      }),
      staffChangeRequest: {
        ...obj.staffChangeRequest,
        type: obj.type, //create_shift, create_shift_new_staff
      },
      staffId: obj.staffId,
      workingDay: obj.workingDay,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = `${URL_API}api/v2/admin/createShift`;
    const result = await CommonCall(api, header);
    return result;
  },

  getSchedulePermanent: async ({ firstDayOfMonth }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/get-shift-schedule?firstDayOfMonth=${firstDayOfMonth}`;
    const result = await CommonCall(api, header);
    return result;
  },
  genShiftSchedule: async ({ firstDayOfMonth }) => {
    const body = {
      firstDayOfMonth: firstDayOfMonth,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const result = await CommonCall(
      Api.genShiftSchedule(firstDayOfMonth),
      header
    );
    return result;
  },
  createSchedulePermanent: async (obj) => {
    const body = {
      shiftRequests: obj.shiftRequests.map((item) => {
        return {
          id: item.scheduleId,
          endTime: moment(item.endTime).isValid()
            ? moment(item.endTime).format("HH:mm")
            : item.endTime,
          startTime: moment(item.startTime).isValid()
            ? moment(item.startTime).format("HH:mm")
            : item.startTime,
          session: item.session,
        };
      }),
      firstDayOfMonth: obj.firstDayOfMonth,
      staffId: obj.staffId,
      dayOfWeek: obj.dayOfWeek,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(body),
    };
    const api = `${URL_API}api/v2/admin/create-shift-schedule`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateSchedulePermanent: async (obj) => {
    const body = {
      shiftRequests: obj.shiftRequests.map((item) => {
        return {
          id: item.id,
          endTime: moment(item.endTime).isValid()
            ? moment(item.endTime).format("HH:mm")
            : item.endTime,
          startTime: moment(item.startTime).isValid()
            ? moment(item.startTime).format("HH:mm")
            : item.startTime,
          session: item.session,
          type: item.type,
        };
      }),
      firstDayOfMonth: obj.firstDayOfMonth,
      staffId: obj.staffId,
      dayOfWeek: obj.dayOfWeek,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = `${URL_API}api/v2/admin/update-shift-schedule`;
    const result = await CommonCall(api, header);
    return result;
  },
  getBookingComing: async ({ storeId }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/staff-statuses?storeId=${storeId}`;
    const result = await CommonCall(api, header);
    return result;
  },

  timekeepingStaff: async ({ restMinute, staffId, type }) => {
    const header = {
      method: "PUT",
    };
    const api = `${URL_API}api/v2/admin/timekeepingStaff?staffId=${staffId}&type=${type}${
      restMinute ? "&restMinute=" + restMinute : ""
    }`;
    const result = await CommonCall(api, header);
    return result;
  },
  actionBooking: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/actionBooking`;
    const result = await CommonCall(api, header);
    return result;
  },
  cancelBooking: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.cancelBooking;
    const result = await CommonCall(api, header);
    return result;
  },
  historyUserBooking: async ({ page, size, userId }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/historyUserBooking?page=${page}&size=${size}&userId=${userId}`;
    const result = await CommonCall(api, header);
    return result;
  },

  //CM store
  getAllCommercial: async () => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getAllCommercial?page=${1}&size=${50}`;
    const result = await CommonCall(api, header);
    return result;
  },
  changePositionCommercial: async (arrayPosition) => {
    const body = {
      commercialPosition: arrayPosition,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = `${URL_API}api/v2/admin/changePositionCommercial`;
    const result = await CommonCall(api, header);
    return result;
  },
  createCommercial: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/createCommercial`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateCommercial: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/updateCommercial`;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteCommercial: async (id) => {
    const header = {
      method: "PUT",
    };
    const api = `${URL_API}api/v2/admin/deleteCommercial?id=${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  //noti-sound
  getNotiSound: async () => {
    const header = {
      method: "GET",
    };
    const api = Api.getNotiSound;
    const result = await CommonCall(api, header);
    return result;
  },
  createNotiSound: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = Api.createNotiSound;
    const result = await CommonCall(api, header);
    return result;
  },
  updateNotiSound: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.updateNotiSound;
    const result = await CommonCall(api, header);
    return result;
  },

  //ng
  getListNG: async ({ page, size, staffId }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getListNG?page=${page}&size=${size}${
      staffId ? "&staffId=" + staffId : ""
    }`;
    const result = await CommonCall(api, header);
    return result;
  },
  createNG: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/createNG`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateNG: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/updateNG`;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteNG: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/deleteNG`;
    const result = await CommonCall(api, header);
    return result;
  },
  timekeepingCustom: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/timekeepingCustom`;
    const result = await CommonCall(api, header);
    return result;
  },
  restTimeExtra: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/restTimeExtra`;
    const result = await CommonCall(api, header);
    return result;
  },
  requestExtraBreak: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/request-extra-break`;
    const result = await CommonCall(api, header);
    return result;
  },
  checkInBooking: async ({ bookingId, userCheckInTime, userId }) => {
    const header = {
      method: "POST",
    };

    let api = `${URL_API}api/v2/user-check-in?bookingId=${bookingId}&userCheckInTime=${userCheckInTime}`;
    if (!!userId) {
      api = `${URL_API}api/v2/user-check-in?bookingId=${bookingId}&userCheckInTime=${userCheckInTime}&userId=${userId}`;
    }
    const result = await CommonCall(api, header);
    return result;
  },
  //noti staff
  getListNotificationStaff: async (page, size = 10) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/getAllNotificationStaff?page=${page}&size=${size}`;
    const result = await CommonCall(api, header);
    if (result.success) {
      return {
        data: [...(result.notifications || [])],
        total: result.pageDto ? result.pageDto.totalElements : 0,
      };
    } else {
      message.error(result.message || STRINGS.something_wrong);
    }
    return result;
  },
  pushNotificationStaff: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify({ ...obj, sendAll: obj.all }),
    };
    const api = `${URL_API}api/v2/admin/pushNotificationStaff`;
    const result = await CommonCall(api, header);
    return result;
  },
  updatePushNotificationStaff: async (obj) => {
    const body = {
      ...obj,
      sendAll: obj.all,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = `${URL_API}api/v2/admin/updateNotificationStaff`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateInfoBooking: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/updateUserInfoBooking`;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteNotificationStaff: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = `${URL_API}api/v2/admin/deleteNotificationStaff?notificationId=${id}`;
    const result = await CommonCall(api, header);
    return result;
  },
  getCouponBooking: async (birthday, userId, bookingId) => {
    const header = {
      method: "GET",
    };
    const result = await CommonCall(
      Api.getCouponBooking(birthday, userId, bookingId),
      header
    );
    return result;
  },
  getComboRevenue: async () => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/comboAddition`;
    const result = await CommonCall(api, header);
    return result;
  },
  updateComboRevenue: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = `${URL_API}api/v2/admin/comboAddition`;
    const result = await CommonCall(api, header);
    return result;
  },

  //staff history change
  getStaffHistoryChange: async ({ page, size, sort }) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/historyChange?page=${page}&size=${size}&sort=${sort}`;
    const result = await CommonCall(api, header);
    if (result.success) {
      let dataReturn = {
        data: [...(result.historyChangeDTOS || [])],
        total: result.pageDto ? result.pageDto.totalElements : 0,
        success: true,
        last: result.pageDto?.last,
      };
      if (Array.isArray(result?.historyChangeDTOS)) {
        const arrAfterInsertIndex = result.historyChangeDTOS.map(
          (item, index) => {
            return { ...item, index: index + 1 };
          }
        );
        dataReturn = { ...dataReturn, data: arrAfterInsertIndex };
      }
      return dataReturn;
    } else {
      message.error(result.message || STRINGS.something_wrong);
    }
    return result;
  },

  getComboPacksAvailableWithBooking: async (bookingId, userType) => {
    const header = {
      method: "GET",
    };
    const api = `${URL_API}api/v2/admin/get-combo-packs-available-with-booking?bookingId=${bookingId}`;
    const result = await CommonCall(api, header);
    return result;
  },
  getProlongedCourse: async (bookingId) => {
    const header = {
      method: "GET",
    };
    const api = Api.getProlongedCourse(bookingId);
    const result = await CommonCall(api, header);
    return result;
  },
  getCourseAvailableWithBooking: async (bookingId) => {
    const header = {
      method: "GET",
    };
    const api = Api.getCourseAvailableWithBooking(bookingId);
    const result = await CommonCall(api, header);
    return result;
  },

  getStaffStatusFlow: async () => {
    const header = {
      method: "GET",
    };
    const api = Api.getStaffStatusFlow;
    const result = await CommonCall(api, header);
    return result;
  },
  setCancelBookingTerm: async (description) => {
    const body = {
      description: description,
    };
    const header = {
      method: "PUT",
      body: JSON.stringify(body),
    };
    const api = Api.cancelBookingTerm;
    const result = await CommonCall(api, header);
    return result;
  },
  staffResting: async () => {
    const header = {
      method: "GET",
    };
    const api = Api.staffResting;
    const result = await CommonCall(api, header);
    return result;
  },

  getStatusBooking: async (bookingId) => {
    const header = {
      method: "GET",
    };
    const api = Api.getStatusBooking(bookingId);
    const result = await CommonCall(api, header);
    return result;
  },
  getStaffWorkStatistics: async ({ day, staffId }) => {
    const header = {
      method: "GET",
    };
    const api = Api.getStaffWorkStatistics({ day: day, staffId: staffId });
    const result = await CommonCall(api, header);
    return result;
  },
  getDetailCourseBooking: async ({ id }) => {
    const header = {
      method: "GET",
    };
    const api = Api.getDetailCourseBooking({ id: id });
    const result = await CommonCall(api, header);
    return result;
  },
  updateInfomationBooking: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.updateInfomationBooking;
    const result = await CommonCall(api, header);
    return result;
  },
  createUserNonApp: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = Api.createUserNonApp;
    const result = await CommonCall(api, header);
    return result;
  },
  createBookingManual: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = Api.bookingManual;
    const result = await CommonCall(api, header);
    return result;
  },
  updateBookingManual: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.bookingManual;
    const result = await CommonCall(api, header);
    return result;
  },
  deleteBookingManual: async ({ bookingHistoryId }) => {
    const header = {
      method: "DELETE",
    };
    const api = Api.deleteBookingManual({ bookingHistoryId });
    const result = await CommonCall(api, header);
    return result;
  },
  validateStaffPassword: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = Api.validateStaffPassword;
    const result = await CommonCall(api, header);
    return result;
  },
  getPackOfStore: async ({ userId }) => {
    const header = {
      method: "GET",
    };
    const api = Api.getPackOfStore({ userId });
    const result = await CommonCall(api, header);
    return result;
  },
  checkStoreHaveBooking: async () => {
    const header = {
      method: "GET",
    };
    const api = Api.checkStoreHaveBooking;
    const result = await CommonCall(api, header);
    return result;
  },
  bookingHistoryManualTimeline: async (date) => {
    const header = {
      method: "GET",
    };
    const api = Api.bookingHistoryManualTimeline(date);
    const result = await CommonCall(api, header);
    return result;
  },
  bookingManualDetail: async ({ bookingId }) => {
    const header = {
      method: "GET",
    };
    const api = Api.getBookingManualHistory({ bookingId });
    const result = await CommonCall(api, header);
    return result;
  },
  listBookingPoint: async ({ page, size }) => {
    const header = {
      method: "GET",
    };
    const api = Api.listBookingPoint({ page, size });
    const result = await CommonCall(api, header);
    return result;
  },
  addBookingPoint: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = Api.bookingPoint();
    const result = await CommonCall(api, header);
    return result;
  },
  editBookingPoint: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.bookingPoint();
    const result = await CommonCall(api, header);
    return result;
  },
  deleteBookingPoint: async (id) => {
    const header = {
      method: "DELETE",
    };
    const api = Api.bookingPoint(id);
    const result = await CommonCall(api, header);
    return result;
  },
  changePositionBookingPoint: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.changePositionBookingPoint;
    const result = await CommonCall(api, header);
    return result;
  },
  createStoreSurcharge: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = Api.storeSurcharge;
    const result = await CommonCall(api, header);
    return result;
  },
  getStoreSurcharge: async () => {
    const header = {
      method: "GET",
    };
    const api = Api.storeSurcharge;
    const result = await CommonCall(api, header);
    return result;
  },
  updateStoreSurcharge: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.storeSurcharge;
    const result = await CommonCall(api, header);
    return result;
  },
  requestChangeStaffBooking: async ({ bookingId }) => {
    const header = {
      method: "POST",
    };
    const api = Api.requestChangeStaffBooking({ bookingId });
    const result = await CommonCall(api, header);
    return result;
  },
  acceptChangeStaffBooking: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = Api.acceptChangeStaffBooking;
    const result = await CommonCall(api, header);
    return result;
  },
  copyBookingManualData: async ({ from, to, storeId }) => {
    const header = {
      method: "POST",
    };
    const api = Api.copyBookingManualData({ from, to, storeId });
    const result = await CommonCall(api, header);
    return result;
  },
  changeUserBooking: async ({ bookingId, userId }) => {
    const header = {
      method: "PUT",
    };
    const api = Api.changeUserBooking({ bookingId, userId });
    const result = await CommonCall(api, header);
    return result;
  },
  checkBatchBooking: async ({ bookingId }) => {
    const header = {
      method: "GET",
    };
    const api = Api.checkBatchBooking({ bookingId });
    const result = await CommonCall(api, header);
    return result;
  },
  cancelMultipleBooking: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.cancelMultipleBooking;
    const result = await CommonCall(api, header);
    return result;
  },
  getStoreRevenueInRange: async ({ from, to, page, size }) => {
    const header = {
      method: "GET",
    };
    const api = Api.getStoreRevenueInRange({ from, to, page, size });
    const result = await CommonCall(api, header);
    return result;
  },
  getStaffsbyStore: async (storeId) => {
    const header = {
      method: "GET",
    };
    const api = Api.getStaffsbyStore(storeId);
    const result = await CommonCall(api, header);
    return result;
  },
  getPacksOfStore: async (userId) => {
    const header = {
      method: "GET",
    };
    const api = Api.getPacksOfStore(userId);
    const result = await CommonCall(api, header);
    return result;
  },
  getTodayStoreRevenue: async () => {
    const header = {
      method: "GET",
    };
    const api = Api.getTodayStoreRevenue;
    const result = await CommonCall(api, header);
    return result;
  },
  confirmStoreRevenue: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = Api.confirmStoreRevenue;
    const result = await CommonCall(api, header);
    return result;
  },
  changeStartTimeBooking: async ({ bookingId, newStartTime }) => {
    const header = {
      method: "PUT",
    };
    const api = Api.changeStartTimeBooking({ bookingId, newStartTime });
    const result = await CommonCall(api, header);
    return result;
  },
  downloadStoreRevenueInRange: async ({ from, to }) => {
    const dateFromFormat = moment(from).format("YYYY/MM/DD");
    const dateToFormat = moment(to).format("YYYY/MM/DD");
    const result = await downFile(
      Api.downloadStoreRevenueInRange({ from, to }),
      "GET",
      `売上集計${dateFromFormat}-${dateToFormat}.xlsx`
    );
    return result;
  },
  requestExtraBreakSpecificTime: async (obj) => {
    const header = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    const api = Api.requestExtraBreakSpecificTime;
    const result = await CommonCall(api, header);
    return result;
  },
  updateBookingHistory: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.updateBookingHistory;
    const result = await CommonCall(api, header);
    return result;
  },
  requestChangeEndTimeShift: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.requestChangeEndTimeShift;
    const result = await CommonCall(api, header);
    return result;
  },
  genShiftSpecificMonth: async (firstDateOfMonth) => {
    const header = {
      method: "POST",
    };
    const api = Api.genShiftSpecificMonth(firstDateOfMonth);
    const result = await CommonCall(api, header);
    return result;
  },
  copySchedule: async (firstDateOfBeGenMonth) => {
    const header = {
      method: "POST",
    };
    const api = Api.copySchedule(firstDateOfBeGenMonth);
    const result = await CommonCall(api, header);
    return result;
  },
  paidBooking: async (bookingId) => {
    const header = {
      method: "PUT",
    };
    const api = Api.paidBooking(bookingId);
    const result = await CommonCall(api, header);
    return result;
  },
  deleteRestTimeExtra: async (data) => {
    const header = {
      method: "DELETE",
    };
    try {
      const api = Api.deleteRestTimeExtra(data);
      const result = await CommonCall(api, header);
      return result;
    } catch (e) {
      message.error(STRINGS.network_error);
    }
  },
  getStaffsbyStore: async (storeId) => {
    const header = {
      method: "GET",
    };
    const api = Api.getStaffsbyStore(storeId);
    const result = await CommonCall(api, header);
    return result;
  },
  getPacksOfStore: async (userId) => {
    const header = {
      method: "GET",
    };
    const api = Api.getPacksOfStore(userId);
    const result = await CommonCall(api, header);
    return result;
  },
  updateBookingHistory: async (obj) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(obj),
    };
    const api = Api.updateBookingHistory;
    const result = await CommonCall(api, header);
    return result;
  },
};

const downFile = async (api, method, fileName) => {
  const accessToken = Auth.getAccessToken();
  try {
    const result = await fetch(api, {
      method: method,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (result.status === 200) {
      const blob = await result.blob();
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } else if (result.status === 401) {
      const resToken = await refreshToken();
      if (resToken.success) {
        const responseRefeshToken = await fetch(api, {
          method: method,
          headers: {
            Authorization: `Bearer ${resToken.access_token}`,
          },
        });
        const resultRefeshToken = await responseRefeshToken.json();
        return resultRefeshToken;
      } else {
        message.error(STRINGS.something_wrong);
      }
    } else {
      return message.error(STRINGS.something_wrong);
    }
  } catch (e) {
    message.error(STRINGS.network_error);
  }
};

export { FetchApi, CommonCall };
