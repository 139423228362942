import React, { useState } from "react";
import { Form } from "antd";

import { StaffBookingEachUser } from ".";

import "../StaffBooking.scss";

function Content({ form, num }) {
  return (
    <div>
      {[...Array(num)].map((_, index) => {
        return (
          <StaffBookingEachUser
            key={`${index}`}
            numberOrdinal={index + 1}
            form={form}
            total={num}
          />
        );
      })}
    </div>
  );
}
function StaffBookingUserContainer({ form }) {
  const [num, setNum] = useState(form.getFieldValue("bookingNum"));
  return (
    <Form.Item
      name="bookingNumberUser"
      shouldUpdate={(prevValues, curValues) => {
        if (prevValues.bookingNum !== curValues.bookingNum) {
          setNum(curValues.bookingNum);
        }
      }}>
      <Content form={form} num={num} />
    </Form.Item>
  );
}

export { StaffBookingUserContainer };
