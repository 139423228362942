import { useQuery } from "react-query";
import { FetchApi } from "../../api";
import Auth from "../../store/Authentication";

const useListStoreStaffCancel = () => {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const { data, isLoading, refetch, isFetching } = useQuery(
    [`useListStoreStaff-${userAdmin.storeId}`],
    () => FetchApi.getListStaffCancel(userAdmin.storeId, 1, 50)
  );

  return {
    listStoreStaffCancel: data,
    refetchListStoreStaff: refetch,
    isLoadingListStoreStaff: isLoading,
    isFetchingListStoreStaff: isFetching,
  };
};

export { useListStoreStaffCancel };
