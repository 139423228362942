import { useEffect, useState } from "react";
import { useGetNotiSound } from "./useGetCalendarOrders";

export function useAudio(url) {
  const { notiSound, isLoading } = useGetNotiSound();

  const [audio, setAudio] = useState(url ? new Audio(url) : null);
  const [playing, setPlaying] = useState(false);

  const startAudio = () => setPlaying(true);
  const pauseAudio = () => setPlaying(false);
  useEffect(() => {
    playing ? audio?.play?.() : audio?.pause?.();
  }, [playing, audio]);

  useEffect(() => {
    if (notiSound) {
      setAudio(new Audio(notiSound));
    }
  }, [notiSound, isLoading]);

  useEffect(() => {
    audio?.addEventListener?.("ended", () => setPlaying(false));
    return () => {
      audio?.removeEventListener?.("ended", () => setPlaying(false));
    };
  }, [audio]);

  return { playing, startAudio, pauseAudio };
}
