import React from "react";
import { Col, Row } from "antd";

import { COLORS, STRINGS, LOGIC } from "../../../constants";
import { CouponTime } from "./CouponTime";
import { CouponDay } from "./CouponDay";
import { CouponDate } from "./CouponDate";

import "./CouponForm.scss";

function CouponConfigTime({
  initialValue,
  couponType,
  form,
  onChange,
  value,
  setCouponType,
}) {
  const listItem = [
    {
      title: STRINGS.coupon_time,
      type: LOGIC.coupon_time.time,
    },
    {
      title: STRINGS.coupon_day,
      type: LOGIC.coupon_time.day,
    },
    {
      title: STRINGS.coupon_date,
      type: LOGIC.coupon_time.date,
    },
  ];

  const renderSelect = (item) => {
    if (item.type === value) {
      return (
        <div
          style={{
            width: "18px",
            height: "18px",
            borderRadius: "9px",
            backgroundColor: COLORS.success,
          }}
        />
      );
    }
  };
  const renderContent = (i) => {
    const formProps = {
      couponType: couponType,
      form: form,
      initialValue: initialValue,
    };
    if (i.type === LOGIC.coupon_time.time) {
      return (
        <CouponTime
          {...formProps}
          initialValue={couponType === i.type ? initialValue.timeOfDay : ""}
        />
      );
    }
    if (i.type === LOGIC.coupon_time.day) {
      return <CouponDay {...formProps} />;
    }
    return <CouponDate {...formProps} />;
  };
  return (
    <div className="end-time">
      {listItem.map((i) => {
        return (
          <Row key={`${i.title}`} style={{ marginTop: 10 }}>
            <Col>
              <Row>
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "12px",
                    border: `1px solid ${COLORS.border}`,
                    backgroundColor: COLORS.background,
                    display: "flex",
                    placeContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    if (couponType === i.type) {
                      setCouponType();
                      onChange("");
                      return;
                    }
                    setCouponType(i.type);
                    onChange(i.type);
                  }}
                >
                  {renderSelect(i)}
                </div>
                <Col>{i.title}</Col>
              </Row>
              {renderContent(i)}
            </Col>
          </Row>
        );
      })}
    </div>
  );
}

export { CouponConfigTime };
