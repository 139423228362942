import { useQuery } from "react-query";

import { FetchApi } from "../../../api";
import Auth from "../../../store/Authentication";

export function useGetAllCourseOfCoupon({ couponId }) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const value = useQuery(["sysCourseCoupon"], () => {
    if (userAdmin.type === "STORE_ADMIN") {
      return FetchApi.getComboList({
        size: 40,
        page: 1,
      });
    }
    return FetchApi.getAllCourseOfCoupon({
      couponId,
    });
  });
  return value;
}
