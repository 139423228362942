import {
  AudioOutlined,
  ContactsOutlined,
  FileSyncOutlined,
  // HistoryOutlined,
} from "@ant-design/icons";

import { COLORS, STRINGS, Svgs } from "../../../constants";
import BookingManual from "../../booking-manual/BookingManual";
// import ManagerUser from "../../manager-user/ManagerUser";
import StaffBooking from "../../staff-booking/StaffBooking";
import StaffCancelHistory from "../../staff-cancel-history/StaffCancelHistory";
import WorkChangeHistory from "../../staff-change-history/StaffChangeHistory";
import StaffFixedSchedule from "../../staff-fixed-schedule/StaffFixedSchedule";
import StaffManagerSchedule from "../../staff-manager-schedule/StaffManagerSchedule";
import StaffNotGood from "../../staff-not-good/StaffNotGood";
import StaffScheduleManual from "../../staff-schedule-manual/StaffScheduleManual";
import StaffSchedule from "../../staff-schedule/StaffSchedule";
import HistoryStore from "../../store-history/HistoryStore";
import HistoryPointStore from "../../store-point-history/HistoryPointStore";
import PointReception from "../../store-point-reception/PointReception";
import UploadAudio from "../../upload-audio/UploadAudio";
import ChangeStaffBookingHistory from "../../change-staff-booking-history/ChangeStaffBookingHistory";

export const staffAdmin = [
  { component: StaffSchedule, url: "/" },
  { component: StaffManagerSchedule, url: "/manager-schedule" },
  { component: StaffScheduleManual, url: "/staff-schedule-manual" },
  { component: BookingManual, url: "/booking-manual" },
  { component: PointReception, url: "/point-reception" },
  { component: StaffBooking, url: "/staff-booking" },
  { component: StaffFixedSchedule, url: "/manager-fixed-schedule" },
  { component: StaffNotGood, url: "/staff-not-good" },
  { component: WorkChangeHistory, url: "/change-history" },
  // { component: ManagerUser, url: "/users" },
  {
    component: HistoryStore,
    url: "/history-store",
  },
  {
    component: HistoryPointStore,
    url: "/history-point-store",
  },
  {
    component: UploadAudio,
    url: "/upload-audio",
  },
  {
    component: StaffCancelHistory,
    url: "/cancel-booking-history",
  },
  {
    component: ChangeStaffBookingHistory,
    url: "/change-staff-booking-history",
  },
];

export const menuStaff = [
  {
    key: "pointReception",
    icon: Svgs.pointReception(),
    title: STRINGS.point_reception,
    linkTo: "/point-reception",
  },
  {
    key: "staffSchedule",
    icon: Svgs.fileSearch,
    title: STRINGS.booking_today,
    linkTo: "/",
  },
  {
    key: "staffScheduleManual",
    icon: Svgs.fileSearch,
    title: STRINGS.treatment_reception,
    linkTo: "/staff-schedule-manual",
  },
  {
    key: "staffBookingManual",
    icon: Svgs.visitHistory(),
    title: STRINGS.history_booking_manual,
    linkTo: "/booking-manual",
  },
  {
    key: "staffManagerSchedule",
    icon: Svgs.calendarTime,
    title: STRINGS.booking_list_other_days,
    linkTo: "/manager-schedule",
  },

  {
    key: "staffFixedSchedule",
    icon: Svgs.registrationBlack,
    title: STRINGS.shift_list,
    linkTo: "/manager-fixed-schedule",
  },
  {
    key: "historyStore",
    icon: Svgs.visitHistory(),
    title: STRINGS.history_go_store,
    linkTo: "/history-store",
  },
  {
    key: "historyPoint",
    icon: Svgs.visitHistory(),
    title: STRINGS.visit_history,
    linkTo: "/history-point-store",
  },
  // {
  //   key: "users",
  //   icon: Svgs.user,
  //   title: STRINGS.user_management,
  //   linkTo: "/users",
  // },
  {
    key: "staffNotGood",
    icon: Svgs.slideSetting(),
    title: STRINGS.NG_list,
    linkTo: "/staff-not-good",
  },
  {
    key: "staffChangeHistory",
    icon: Svgs.staffChangeHistory(),
    title: STRINGS.job_change_history,
    linkTo: "/change-history",
    menuItem: [
      {
        key: "changeWorkingHourHistory",
        icon: (
          <FileSyncOutlined
            style={{ fontSize: 20, color: COLORS.background }}
          />
        ),
        title: STRINGS.change_working_hour_history,
        linkTo: "/change-history",
      },
      // comment when deploy product
      // {
      //   key: "changeStaffHistory",
      //   icon: (
      //     <HistoryOutlined style={{ fontSize: 20, color: COLORS.background }} />
      //   ),
      //   title: STRINGS.change_staff_history,
      //   linkTo: "/change-staff-booking-history",
      // },
      {
        key: "cancelBookingHistory",
        icon: (
          <ContactsOutlined
            style={{ fontSize: 20, color: COLORS.background }}
          />
        ),
        title: STRINGS.cancel_booking_history,
        linkTo: "/cancel-booking-history",
      },
    ],
  },
  {
    key: "uploadAudio",
    icon: <AudioOutlined style={{ fontSize: 20, color: COLORS.background }} />,
    title: STRINGS.audio_setting,
    linkTo: "/upload-audio",
  },
];
