import React from "react";
import { Form, Row, InputNumber } from "antd";

import { STRINGS } from "../../../../../constants";

function ConfigPriceSelectGender({ initialValue }) {
  return (
    <Row style={{ paddingTop: 30 }}>
      <div style={{ fontWeight: "bold", fontSize: 18, minWidth: 200 }}>
        {STRINGS.price_select_gender}
      </div>
      <Form.Item
        name={"surchargeDesignation"}
        initialValue={initialValue}
        //  rules={[Validate.isNumberPointPrice]}
      >
        <InputNumber
          style={{ fontWeight: "bold", fontSize: 15 }}
          formatter={(value) =>
            `${value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} 円`
          }
          parser={(value) =>
            value
              .replace("円", "")
              .replace(" ", "")
              .replace(/\$\s?|(,*)/g, "")
          }
        />
      </Form.Item>
    </Row>
  );
}
export { ConfigPriceSelectGender };
