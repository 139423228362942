import { Col, Row, Table } from "antd";
import { useQuery } from "react-query";
import { FetchApi } from "../../../../api";

import { COLORS, LOGIC, STRINGS } from "../../../../constants";
import { Convert, LoadingView } from "../../../../elements";
import { CommonFunction } from "../../../../util/CommonFunction";
// import { useGetTodayStoreRevenue } from "../../../modal-confirm-revenue/modules/useGetTodayStoreRevenue";

export default function BodyTableCancelFee({
  staffInfo,
  shiftStatus,
  onCheckout,
}) {
  const { data, isLoading } = useQuery([`staffCancelBookingHistory`], () =>
    FetchApi.getHistoryCancelBooking({
      page: 1,
      size: 50,
      sort: "desc",
      paymentWithStaffId: staffInfo.staffId,
    })
  );
  const columns = [
    {
      title: STRINGS.day_go_store,
      dataIndex: "reservationTime",
      key: "reservationTime",
      width: 160,
      align: "center",
      render: (_, dataStoreRevenue) =>
        Convert.convertDate(dataStoreRevenue?.booking?.createdTime),
    },
    {
      title: STRINGS.user_name,
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
      align: "center",
    },
    {
      title: STRINGS.name2,
      dataIndex: "isAssign",
      key: "isAssign",
      width: 80,
      align: "-webkit-center",
      render: (_, dataStoreRevenue) =>
        dataStoreRevenue?.booking?.chooseStaff ? (
          <div
            style={{
              backgroundColor: COLORS.primary,
              width: 16,
              height: 16,
              borderRadius: "50%",
            }}
          />
        ) : (
          ""
        ),
    },
    {
      title: STRINGS.content_course,
      dataIndex: "packs",
      key: "packs",
      width: 150,
      align: "center",
      render: (e) =>
        e.map((item, index) => <Col key={`${index}`}>{item.comboName}</Col>),
    },
    {
      title: STRINGS.time,
      dataIndex: "packs",
      key: "packs",
      align: "center",
      width: 100,
      render: (e) =>
        e.map((item, index) => (
          <Col key={`${index}`}>{(item.minutes || 0) + STRINGS.minute}</Col>
        )),
    },
    {
      title: STRINGS.cancellation_charge,
      dataIndex: "fee",
      key: "fee",
      align: "center",
      width: 150,
      render: (e) => CommonFunction.currencyFormat(e),
    },
  ];

  if (isLoading) {
    return <LoadingView />;
  }
  let totalCancelFee = 0;
  if (Array.isArray(data?.bookingCancellationHistories)) {
    (data?.bookingCancellationHistories || []).forEach((i) => {
      totalCancelFee += i.fee;
    });
  }
  return (
    <Col style={{ paddingBottom: 20 }}>
      <Row
        style={{
          color: "#4B2A05",
          fontSize: 25,
          fontWeight: "bold",
          justifyContent: "center",
          padding: 10,
        }}>
        {STRINGS.list_cancel_fee_paid}
      </Row>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={data?.bookingCancellationHistories || []}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
      />
      <Row style={{ marginTop: 20, paddingLeft: 10, alignItems: "center" }}>
        <Col xl={7}>
          {/* <Row
            style={{
              border: `1px solid ${COLORS.accent}`,
              padding: "4px 8px",
              borderRadius: 6,
              fontSize: 22,
              fontWeight: "bold",
              color: COLORS.accent,
              width: "fit-content",
            }}>
            {dataCombos?.length || 0}
            {STRINGS.people}
          </Row> */}
        </Col>
        <Col xl={5}>
          {/* <Row
            style={{
              paddingLeft: 55,
              fontSize: 22,
              fontWeight: "bold",
              color: COLORS.primary,
              width: "fit-content",
            }}>
            {totalAssign}
            {STRINGS.people}
          </Row> */}
        </Col>
        <Col xl={12} style={{}}>
          <Row
            style={{
              fontWeight: "bold",
              alignItems: "center",
              justifyContent: "space-between",
              paddingRight: 10,
            }}>
            <Row style={{ alignItems: "center" }}>
              {/* <Col style={{ color: COLORS.accent }}>{STRINGS.fee_store}</Col>
              <Col style={{ color: COLORS.primary, fontSize: 43 }}>
                {"\xa0\xa0"}
                {LOGIC.currencyFormatter(total)}
              </Col> */}
            </Row>
            <Row style={{ alignItems: "center", textAlign: "-webkit-right" }}>
              <Col style={{ color: COLORS.accent }}>
                {STRINGS.total_cancel_fee}
              </Col>
              <Col style={{ color: COLORS.primary, fontSize: 43 }}>
                {"\xa0\xa0"}
                {LOGIC.currencyFormatter(totalCancelFee)}
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Col>
  );
}
