import React, { useRef, useState } from "react";
import { Col, message, Row, Radio } from "antd";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import moment from "moment";

import {
  ButtonCustom,
  LoadingAbsolute,
  ModalCustomService,
  RadioAllStaffNotWorking,
} from "../../../elements";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util/CommonFunction";
import { TimePickerAutoAccept } from "../../../elements/TimePickerAutoAccept";
import { COLORS, STRINGS } from "../../../constants";
import Auth from "../../../store/Authentication";

import "../StoreActualSchedule.scss";

function HoursPicker({ onChange }) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const [timeRangeList, setTimeRangeList] = useState(() => {
    const newTime = [
      {
        startTime: moment(userAdmin.storeDTO?.openTime, "HH"),
        endTime: moment(userAdmin.storeDTO?.closeTime, "HH"),
        session: "早",
      },
    ];
    onChange(newTime);
    return newTime;
  });

  const range = (a, b = 23) => {
    const arr = [];
    while (a < b + 1) {
      arr.push(a++);
    }
    return arr;
  };

  // const disabledMinutes = (selectedHour) => {
  //   let minutes = [];
  //   const closeHour = userAdmin.storeDTO.closeTime
  //     ? parseInt(userAdmin.storeDTO.closeTime.slice(0, 2))
  //     : 24;
  //   const closeMinute = userAdmin.storeDTO.closeTime
  //     ? parseInt(userAdmin.storeDTO.closeTime.slice(3, 5))
  //     : [];
  //   if (selectedHour == closeHour) {
  //     for (let i = 0; i < 60; i++) {
  //       if (i > closeMinute) {
  //         minutes.push(i);
  //       }
  //     }
  //   }
  //   return minutes;
  // };

  const openHour = userAdmin.storeDTO?.openTime
    ? parseInt(userAdmin.storeDTO.openTime.slice(0, 2))
    : [];
  const closeHour = userAdmin.storeDTO?.closeTime
    ? parseInt(userAdmin.storeDTO.closeTime.slice(0, 2))
    : 24;
  let openHours = [];
  let closeHours = [];

  if (closeHour > openHour) {
    openHours = Array.from(Array(openHour).keys());
    closeHours = range(closeHour + 1);
  } else {
    openHours = range(closeHour + 1, openHour - 1);
  }

  const addTimeRangeList = () => {
    const newStartTime = moment(userAdmin.storeDTO?.openTime, "HH");
    const newEndTime = moment(userAdmin.storeDTO?.closeTime, "HH");
    const clone = JSON.parse(
      JSON.stringify([
        ...timeRangeList,
        {
          startTime: newStartTime,
          endTime: newEndTime,
          session: "早",
          action: "ADD",
        },
      ])
    );
    setTimeRangeList(clone);
    onChange(clone);
  };
  const removeTimeRange = (index) => () => {
    const clone = JSON.parse(JSON.stringify([...timeRangeList]));
    clone[index].action = "DELETE";
    clone.splice(index, 1);
    setTimeRangeList(clone);
    onChange(clone);
  };
  const onChangeHour = (index, type) => async (e) => {
    const clone = JSON.parse(JSON.stringify([...timeRangeList]));
    clone[index][type] = moment(e).format("HH:mm");
    setTimeRangeList(clone);
    onChange(clone);
  };
  const onChangeSession = (index) => (e) => {
    const clone = JSON.parse(JSON.stringify([...timeRangeList]));
    clone[index].session = e.target.value;
    setTimeRangeList(clone);
    onChange(clone);
  };

  return (
    <Col style={{ paddingTop: 14, width: "70%" }}>
      <Row style={{ textAlign: "left", fontWeight: "bold", color: "#4B2A05" }}>
        <Col span={8} style={{ textAlign: "-webkit-center" }}>
          {STRINGS.checkin}
        </Col>
        <Col span={8} style={{ textAlign: "-webkit-center" }}>
          {STRINGS.checkout}
        </Col>
        <Col span={6} style={{ textAlign: "-webkit-center" }}>
          {STRINGS.shift}
        </Col>
      </Row>
      {timeRangeList.map((item, index) => {
        return (
          <Row
            key={`${timeRangeList}-${index}`}
            style={{
              textAlign: "left",
              alignItems: "center",
              marginBottom: 6,
            }}>
            <Col span={8}>
              <TimePickerAutoAccept
                value={moment(item.startTime, "HH:mm")}
                onChangeHour={onChangeHour(index, "startTime")}
                minuteStep={10}
                format={"HH:mm"}
                disabledHours={() => {
                  return [...openHours, ...closeHours];
                }}
              />
            </Col>
            <Col span={8}>
              <TimePickerAutoAccept
                value={moment(item.endTime, "HH:mm")}
                onChangeHour={onChangeHour(index, "endTime")}
                minuteStep={10}
                format={"HH:mm"}
                disabledHours={() => {
                  return [...openHours, ...closeHours];
                }}
              />
            </Col>
            <Col span={4}>
              <Radio.Group
                defaultValue={item.session}
                style={{ width: "90%", display: "grid" }}
                onChange={onChangeSession(index)}>
                <Radio value={"早"}>早</Radio>
                <Radio value={"中"}>中</Radio>
                <Radio value={"遅"}>遅</Radio>
              </Radio.Group>
            </Col>
            {timeRangeList.length > 1 && (
              <MinusCircleFilled
                style={{
                  fontSize: 27,
                  paddingRight: 3,
                  color: COLORS.sub_accent,
                }}
                span={2}
                onClick={removeTimeRange(index)}
              />
            )}
            {index === timeRangeList.length - 1 && timeRangeList.length < 5 && (
              <PlusCircleFilled
                style={{ fontSize: 27, color: COLORS.sub_accent }}
                span={2}
                onClick={addTimeRangeList}
              />
            )}
          </Row>
        );
      })}
    </Col>
  );
}

function ModalAddStaff({ staffInfo, refetchCalendarOrder }) {
  const shiftRequests = useRef([]);
  const staffId = useRef();
  const [loading, setLoading] = useState(false);

  const onAddStaff = async () => {
    let isSame = false;
    //validate time duplicate or time inside another time
    // const isTimeNotValid = shiftRequests.current.some((item, index) => {
    //   if (moment(item.endTime).isSame(moment(item.startTime))) {
    //     isSame = true;
    //     return true;
    //   }
    //   const filterItemCurrent = shiftRequests.current.filter(
    //     (itm, idx) => index !== idx
    //   );
    //   const res = filterItemCurrent.some((itm, idx) => {
    //     if (moment(itm.endTime).isBefore(itm.startTime)) {
    //       return (
    //         moment(item.startTime).isSameOrAfter(moment(itm.startTime)) &&
    //         moment(item.startTime).isBefore(moment(itm.endTime).add(1, "days"))
    //       );
    //     }
    //     return (
    //       moment(item.startTime).isSameOrAfter(moment(itm.startTime)) &&
    //       moment(item.startTime).isBefore(moment(itm.endTime))
    //     );
    //   });
    //   return res;
    // });
    if (isSame) {
      message.warning(STRINGS.endtime_greater_than_starttime);
      return;
    }
    // if (isTimeNotValid) {
    //   message.warning(STRINGS.shift_duplicated);
    //   return;
    // }

    if (!shiftRequests.current.length) {
      message.warning(STRINGS.please_select_shift);
      return;
    }
    try {
      setLoading(true);
      const result = await FetchApi.createShift({
        shiftRequests: shiftRequests.current,
        staffId: staffId.current,
        workingDay: staffInfo.day,
        type: "create_shift_new_staff",
      });
      setLoading(false);
      if (result.success) {
        refetchCalendarOrder();
        message.success(STRINGS.saved_common);
        ModalCustomService.close();
        if (result.code == 2001) {
          message.warning(STRINGS.bed_full);
          return;
        }
        if (result.code == 2002) {
          message.warning(STRINGS.bed_full_2);
          return;
        }
      } else if (result.code == 4005) {
        message.warning(STRINGS.shift_duplicated);
      } else if (result.code == 4006) {
        message.warning(STRINGS.shift_existed_another_store);
      } else if (result.code == 4003) {
        message.warning(STRINGS.password_is_incorrect);
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      setLoading(false);
      message.error(STRINGS.something_wrong);
    }
  };

  return (
    <Col className="checkin-time-wrapper">
      <Row
        style={{
          color: "#4B2A05",
          fontSize: 18,
          paddingTop: 14,
          fontWeight: "bold",
          justifyContent: "center",
        }}>
        {STRINGS.please_select_your_commute_time}
      </Row>

      <HoursPicker onChange={(e) => (shiftRequests.current = e)} />

      <Row
        style={{
          color: "#4B2A05",
          fontSize: 18,
          paddingTop: 14,
          fontWeight: "bold",
          justifyContent: "center",
        }}>
        {STRINGS.please_select_an_attendance_staff}
      </Row>
      <RadioAllStaffNotWorking
        day={staffInfo.day}
        onChange={(e) => (staffId.current = e)}
      />

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onAddStaff}>{STRINGS.sure}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}

export default ModalAddStaff;
