import React, { useState } from "react";
import {
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  message,
  DatePicker,
} from "antd";
import moment from "moment";

import { FetchApi } from "../../../api";
import {
  ButtonCustom,
  ModalCustomService,
  LoadingAbsolute,
  Validate,
  SuffixIconDown,
} from "../../../elements";
import { CommonFunction } from "../../../util/CommonFunction";
import { Svgs, STRINGS, Province, NewProvince } from "../../../constants";
import Auth from "../../../store/Authentication";
import SelectionListNG from "./SelectionListNG";

import "../User.scss";

const Edit = ({ item, data, setData, stores }) => {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState(
    item.banned ? "X" : item.customerType
  );
  const [provinceFilter, setProvinceFilter] = useState(
    Province.filter((_item, _index) => {
      return _item.cityId == item.cityId;
    })
  );

  const onEdit = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      fieldData.id = item.id;
      fieldData.banned = false;
      fieldData.address = `${
        NewProvince.find((i) => i.id === fieldData.cityId)?.name1 || ""
      } ${Province.find((i) => i.id === fieldData.districtId)?.name1 || ""}`;
      if (fieldData.customerType === "X") {
        fieldData.banned = true;
        delete fieldData.customerType;
      }
      setLoading(true);
      const result = await FetchApi.updateUserByAdmin({
        ...fieldData,
        birthday: moment(fieldData.birthday).format("MM/DD/YYYY"),
      });
      setLoading(false);
      if (result.success) {
        const index = data.data.findIndex((itm) => itm.id === item.id);
        const clone = JSON.parse(JSON.stringify({ ...data }));
        clone.data[index] = {
          ...item,
          ...result.user,
          staffIdsNG: fieldData.staffIdsNG,
        };
        setData(clone.data);
        ModalCustomService.close();
        message.success(STRINGS.saved_common);
      } else if (result.code === 4008) {
        message.warning(STRINGS.phone_is_existed);
      } else if (result.code === 4005) {
        message.warning(STRINGS.email_is_existed);
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  const phoneNumber = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      // const re =
      //   /^(?:(0([5,7,9]{1})0([0-9]{8}))|(0([8]{1})0([1-9]{1}[0-9]{7})))$/;
      const re = /^0[0-9]{9,10}$/;

      if (value === undefined || value?.length === 0 || regex.test(value)) {
        // return Promise.reject(STRINGS.this_field_is_required);
        return Promise.resolve();
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.fill_with_number);
      } else {
        return Promise.resolve();
      }
    },
  });
  const furigana = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      const re = /^[ぁ-ん]+$/;
      // const re = /^\d*$/;
      if (value === undefined || value?.length === 0 || regex.test(value)) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else if (!re.test(value)) {
        return Promise.reject(STRINGS.please_no_space);
      } else {
        return Promise.resolve();
      }
    },
  });

  const cloneStores = [...stores];
  cloneStores.shift();
  let rules = [Validate.email, Validate.emptyContent];
  if (["P", "S", "R", "D", "X"].some((i) => userType === i)) {
    rules = [];
  }

  return (
    <Col className="user-setting-form">
      <Form
        layout="vertical"
        form={form}
        onValuesChange={(e) => {
          if (e.cityId) {
            const a = Province.filter((_item, _index) => {
              return _item.cityId == e.cityId;
            });
            setProvinceFilter(a);
            form.setFieldsValue({ districtId: null });
          }
        }}>
        <Row style={{ flexWrap: "wrap" }} gutter={20}>
          <Col xl={12} md={12}>
            <Form.Item
              style={{ marginBottom: 10, paddingRight: 20 }}
              name="name"
              label={STRINGS.user_name}
              required
              rules={[Validate.emptyContent]}
              initialValue={item.name}>
              <Input />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10, paddingRight: 20 }}
              name="furiganaName"
              label={STRINGS.furigana_name}
              rules={[furigana]}
              required
              initialValue={item.furiganaName}>
              <Input />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10, paddingRight: 20 }}
              name="phoneNumber"
              label={STRINGS.phone_number}
              rules={[phoneNumber]}
              initialValue={item.phoneNumber}>
              <Input />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10, paddingRight: 20 }}
              name="storeId"
              label={STRINGS.store}
              required
              rules={[Validate.emptyContent]}
              initialValue={item.storeId}>
              <Select
                suffixIcon={<SuffixIconDown />}
                disabled={userAdmin.type !== "SYSTEM_ADMIN"}>
                {(cloneStores || []).map((itm, idx) => {
                  return (
                    <Select.Option key={itm.id} value={itm.id}>
                      {itm.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10, paddingRight: 20 }}
              name="customerType"
              label={STRINGS.user_type}
              initialValue={item.banned ? "X" : item.customerType}
              rules={[Validate.emptyContent]}>
              <Select
                suffixIcon={<SuffixIconDown />}
                onChange={(e) => {
                  form.setFieldsValue("customerType", e);
                  setUserType(e);
                }}>
                {Object.keys(STRINGS.customer_type_new).map((itm, idx) => {
                  let disabled = false;
                  if (
                    (item.customerType === "S" || item.customerType === "P") &&
                    (itm === "D" || itm === "A" || itm === "R")
                  ) {
                    disabled = true;
                  }
                  if (
                    (item.customerType === "D" || item.customerType === "A") &&
                    (itm === "S" || itm === "P" || itm === "R")
                  ) {
                    disabled = true;
                  }
                  if (
                    item.customerType === "R" &&
                    (itm === "D" || itm === "A")
                  ) {
                    disabled = true;
                  }
                  return (
                    <Select.Option key={itm} value={itm} disabled={disabled}>
                      {STRINGS.customer_type_new[itm]}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10, paddingRight: 20 }}
              name="cancelFee"
              label={STRINGS.cancellation_charge}
              rules={[Validate.isNormalNumberMessage]}
              initialValue={item.cancelFee}>
              <Input />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10, paddingRight: 20 }}
              label={STRINGS.NG_list_user}
              name={"staffIdsNG"}
              initialValue={item.staffIdsNG}>
              <SelectionListNG value={item.staffIdsNG} />
            </Form.Item>
          </Col>
          <Col xl={12} md={12}>
            <Form.Item
              style={{ marginBottom: 12 }}
              name="sex"
              label={STRINGS.gender}
              initialValue={item.sex}>
              <Radio.Group>
                <Radio value={"male"}>{STRINGS.male}</Radio>
                <Radio value={"female"}>{STRINGS.female}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10 }}
              name="email"
              required={userType === "A"}
              rules={rules}
              label={STRINGS.email}
              initialValue={item.email}>
              <Input />
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 10 }}
              name="birthday"
              label={STRINGS.birthday}
              initialValue={
                moment(item.birthday).isValid() ? moment(item.birthday) : ""
              }>
              <DatePicker
                style={{ width: "100%" }}
                placeholder={STRINGS.delivery_start_time}
                suffixIcon={Svgs.datepicker()}
                format={"YYYY/MM/DD"}
                disabledDate={(e) => {
                  let customDate = moment().format("MM/DD/YYYY");
                  return e && e.isAfter(moment(customDate, "MM/DD/YYYY"));
                }}
              />
            </Form.Item>
            <Row>
              <Col xl={12} xs={24}>
                <Form.Item
                  name="cityId"
                  label={"県"}
                  initialValue={item.cityId}>
                  <Select suffixIcon={<SuffixIconDown />} placeholder="県">
                    {NewProvince.map((itm, idx) => {
                      return (
                        <Select.Option key={itm.id} value={itm.id}>
                          {itm.name1}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={12} xs={24} key={provinceFilter.current}>
                <Form.Item
                  name="districtId"
                  label={"市"}
                  initialValue={item.districtId}>
                  <Select suffixIcon={<SuffixIconDown />} placeholder="市">
                    {provinceFilter.map((itm, idx) => {
                      return (
                        <Select.Option key={itm.id} value={itm.id}>
                          {itm.name1}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              style={{ marginBottom: 10 }}
              name="backgroundDisease"
              label={STRINGS.info_background_disease}
              initialValue={item.backgroundDisease}>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onEdit}>{STRINGS.update}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
};

export default Edit;
