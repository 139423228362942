import React, { useState, useEffect, useRef, memo } from "react";
import { Table, Space, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

import { FetchApi } from "../../api";
import {
  AlertService,
  ButtonCustom,
  Container,
  FilterByStore,
  LoadingView,
  ModalCustomService,
  SvgView,
} from "../../elements";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import { COLORS, Svgs, STRINGS, LOGIC } from "../../constants";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";
import CouponForm from "./items/CouponForm";
import Auth from "../../store/Authentication";

// const SelectStore = ({ storeOptions, onSearchByStore }) => {
//   if (JSON.parse(Auth.getCurrentUser()).type === "SYSTEM_ADMIN") {
//     return (
//       <Select
//         defaultValue={storeOptions[0] ? storeOptions[0].name : STRINGS.all}
//         style={{ width: "100%" }}
//         onSelect={onSearchByStore}
//       >
//         {storeOptions.map((item) => (
//           <Option key={"" + item.id}>{item.name}</Option>
//         ))}
//       </Select>
//     );
//   }
// };

const Coupon = memo(() => {
  const cache = useQueryClient();
  const [pagination, setPagination] = useState({ size: 10, page: 1 });
  const [storeOptions, setStoreOptions] = useState([]);
  const storeSelected = useRef(0);
  const couponEditing = useRef({});

  const userAdmin = JSON.parse(Auth.getCurrentUser());

  useEffect(() => {
    // if (userAdmin && userAdmin.type === "SYSTEM_ADMIN") {
    const fetchStore = async () => {
      let result = await FetchApi.getAllStoreFilter(1, 50);
      if (result.total) {
        setStoreOptions([{ name: STRINGS.all, id: 0 }, ...result.data]);
      }
    };
    fetchStore();
    // }
  }, []);

  const columns = [
    {
      title: STRINGS.coupon_image,
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (imageUrl) => (
        <div
          style={{
            border: `1px solid ${COLORS.border_2}`,
            display: "flex",
            width: "100%",
            height: "160px",
            alignItems: "center",
          }}>
          <img
            style={{ width: "100%", maxHeight: "100%" }}
            src={
              imageUrl ||
              "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/5f75afb3-6220-413b-9823-56461b42b3873d7b3552-e660-43b2-8e35-6eec209c8543e5f33cf1-c54d-44d9-905a-f42ae506c3184088a280-f631-43c9-ad4c-58dd843cfd75.png"
            }
            alt="img-coupon"
          />
        </div>
      ),
      width: 290,
    },
    {
      title: STRINGS.coupon_title,
      dataIndex: "title",
      key: "title",
      width: 300,
    },
    {
      title: STRINGS.start_date,
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate, o) => {
        if (o.type === LOGIC.coupon_time.birthday) {
          return "";
        }
        return <div>{moment(startDate).format(LOGIC.timeFormat)}</div>;
      },
      width: 200,
    },
    {
      title: STRINGS.end_date,
      key: "endDate",
      dataIndex: "endDate",
      render: (endDate, o) => {
        if (o.type === LOGIC.coupon_time.birthday) {
          return "";
        }
        return <div>{moment(endDate).format(LOGIC.timeFormat)}</div>;
      },
      width: 200,
    },
    {
      title: STRINGS.discount_amount_yen,
      key: "discount",
      dataIndex: "discount",
      render: (e) => e || 0,
      align: "center",
    },
    {
      title: STRINGS.store_applied,
      dataIndex: "storeId",
      key: "storeId",
      render: (e) =>
        storeOptions.find((itm) => itm.id === e)?.name || STRINGS.all,
      width: 250,
    },
    {
      key: "action",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onClickEdit(record)}>
              <SvgView svgData={Svgs.edit} />
            </button>
            {record.storeId == 0 && userAdmin.type === "STORE_ADMIN" ? null : (
              <button
                style={{
                  border: 0,
                  backgroundColor: "transparent",
                  cursor: "pointer",
                }}
                onClick={removeCoupon(record)}>
                <SvgView svgData={Svgs.delete} />
              </button>
            )}
          </Space>
        );
      },
    },
  ];

  // call api
  const { data, refetch, status, isLoading } = useQuery(
    [`getCoupons-${pagination.size}-${pagination.page}`],
    () => {
      return FetchApi.getCoupon({
        size: pagination.size,
        page: pagination.page,
        storeId: storeSelected.current,
      });
    }
  );
  useScrollByMouse(data);

  const onClickEdit = (item) => () => {
    couponEditing.current = { ...item };
    ModalCustomService.set({
      title: STRINGS.add_new_coupon,
      content: (
        <CouponForm
          data={couponEditing.current}
          storeList={storeOptions}
          onConfirm={onConfirm}
          isAdmin={userAdmin && userAdmin.type === "SYSTEM_ADMIN"}
          disableModal={disableModal}
          disableCouponAdmin={
            item.storeId == 0 && userAdmin?.type === "STORE_ADMIN"
          }
        />
      ),
    });
  };

  const addCoupon = () => {
    couponEditing.current = {};
    ModalCustomService.set({
      title: STRINGS.update,
      content: (
        <CouponForm
          data={couponEditing.current}
          storeList={storeOptions}
          onConfirm={onConfirm}
          isAdmin={userAdmin && userAdmin.type === "SYSTEM_ADMIN"}
          disableModal={disableModal}
          refetch={refetch}
        />
      ),
    });
  };

  const disableModal = () => {
    ModalCustomService.close();
  };

  const removeCoupon = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${item.title}${STRINGS.confirm_delete_coupon}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteCoupon(item.id);
        if (result.success) {
          cache.refetchQueries(
            `getCoupons-${pagination.size}-${pagination.page}`
          );
          message.success(STRINGS.delete_success);
        } else {
          message.warning(result.message || STRINGS.delete_failed);
        }
      },
    });
  };

  const onConfirm = (item) => {
    cache.refetchQueries(`getCoupons-${pagination.size}-${pagination.page}`);
  };

  const onSearchByStore = (e) => {
    storeSelected.current = e;
    setPagination({ size: pagination.size, page: 1 });
    refetch();
  };

  const renderLeft =
    userAdmin.type === "SYSTEM_ADMIN"
      ? () => (
          <FilterByStore
            title={STRINGS.store}
            listStore={storeOptions}
            onSearchByStore={onSearchByStore}
          />
        )
      : null;

  return (
    <Container
      renderLeft={renderLeft}
      renderRight={() => (
        <ButtonCustom onClick={addCoupon} icon={<PlusCircleOutlined />}>
          {STRINGS.add_new}
        </ButtonCustom>
      )}
      styleHeader={{ alignItems: "flex-end" }}
      childrenStyle={{ marginTop: 10 }}>
      <div className="content-coupon">
        {status === "loading" ? (
          <LoadingView />
        ) : (
          <>
            <Table
              rowKey={"id"}
              columns={columns}
              dataSource={data?.data || []}
              locale={{ emptyText: STRINGS.no_data }}
              loading={isLoading}
              pagination={false}
              scroll={{ x: 1500, y: "calc(100vh - 330px)" }}
            />
            <PaginationWithSizeChange
              size={pagination.size}
              total={data ? data.total : 0}
              onChange={(pageNumber) => {
                setPagination({ size: pagination.size, page: pageNumber });
              }}
              onShowSizeChange={(e) => {
                setPagination({ size: e.size, page: 1 });
              }}
              current={pagination.page}
            />
          </>
        )}
      </div>
    </Container>
  );
});
export default Coupon;
