/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";

import { FetchApi } from "../../../api";
import { COLORS, LOGIC, STRINGS } from "../../../constants";
import { ModalCustomService, Validate } from "../../../elements";
import PaginationWithSizeChange from "../../../elements/PaginationWithSizeChange";
import ModalInfoCustomer from "../items/ModalInfoCustomer";

function InfoCustomer({ label, value, styleValue, styleWrapper }) {
  const renderValue = () => {
    if (Array.isArray(value)) {
      return value.map((item, index) => (
        <span
          key={`${index}`}
          style={{
            fontWeight: "bold",
            color: COLORS.accent,
            fontSize: 20,
            ...styleValue,
          }}>
          {item}
          {index !== value.length - 1 ? ",\xa0" : ""}
        </span>
      ));
    }
    return (
      <Col
        style={{
          fontWeight: "bold",
          color: COLORS.accent,
          fontSize: 20,
          ...styleValue,
        }}>
        {value}
      </Col>
    );
  };
  return (
    <Row
      style={{
        textAlign: "left",
        marginTop: 4,
        alignItems: "center",
        ...styleWrapper,
      }}>
      <Col style={{ width: 100, color: COLORS.accent, fontSize: 20 }}>
        {label}
      </Col>
      {renderValue()}
    </Row>
  );
}

export default function ModalBookingDetailTableRight({
  userId,
  setNG,
  data,
  NG,
  refetch,
}) {
  const [pagination, setPagination] = useState({ size: 10, page: 1 });
  const { data: dataNG } = useQuery(
    [
      `TableRight-ModalBookingDetail-${pagination.size}-${pagination.page}-${userId}`,
    ],
    () =>
      FetchApi.historyUserBooking({
        page: pagination.page,
        size: pagination.size,
        userId: userId,
      }),
    {
      enabled: !!userId,
    }
  );

  useEffect(() => {
    if (dataNG?.staffNotGood?.length > 0) {
      setNG(dataNG?.staffNotGood);
    }
  }, [dataNG]);

  const renderFee = (item) => {
    const status = item.status;
    let cancelFeeDes = STRINGS.cancel_fee[status];
    if (!item.cancelFee) {
      cancelFeeDes = STRINGS.cancel_fee.cancel_accompany(0);
    }
    if (
      status.toString().includes("cancel") 
    ) {
      if (status === "cancel_no_contact") {
        return (
          <Col style={{ color: COLORS.required }}>
            {STRINGS.cancel_no_contact_fee(item.cancelFee || 0)}
            {/* {item.orderAccompanies?.map((i, index) => {
              return (
                <Col key={`${index}`}>
                  {STRINGS.hangout_user_cancel(i.customerName || "")}
                  {"\xa0\xa0\xa0\xa0"}
                  {STRINGS.cancel_no_contact_fee(i.cancelFee || 0)}
                </Col>
              );
            })} */}
          </Col>
        );
      }
      return (
        <Col style={{ color: COLORS.required }}>
          {cancelFeeDes}
          {/* {item.orderAccompanies?.map((i, index) => {
            if (i.status === "cancel_no_contact") {
              return (
                <Col key={`${index}`}>
                  {STRINGS.hangout_user_cancel(i.customerName || "")}
                  {"\xa0\xa0\xa0\xa0"}
                  {STRINGS.cancel_no_contact_fee(i.cancelFee || 0)}
                </Col>
              );
            }
            if (i.status === "cancel_contact") {
              return (
                <Col key={`${index}`}>
                  {STRINGS.hangout_user_cancel(i.customerName || "")}
                  {"\xa0\xa0\xa0\xa0"}
                  {STRINGS.cancel_fee.cancel_accompany(i.cancelFee || 0)}
                </Col>
              );
            }
            return (
              <Col key={`${index}`}>
                {STRINGS.hangout_user_cancel(i.customerName || "")}
                {"\xa0\xa0\xa0\xa0"}
                {STRINGS.cancel_fee[i.status]}
              </Col>
            );
          })} */}
        </Col>
      );
    }
  };
  const renderComment = (item) => {
    if (
      item.reviewComment ||
      item.status.toString().includes("cancel") ||
      item.status.toString().includes("done")
    ) {
      return (
        <Row style={{ width: "100%" }}>
          <Col span={3}></Col>
          <Col span={21} style={{ marginTop: 10 }}>
            <Row style={{ width: "100%" }}>
              <Col span={4} style={{ fontWeight: "900", fontSize: 15 }}>
                {STRINGS.remarks} :
              </Col>
              <Col span={20}>{!!item.reviewComment && item.reviewComment}</Col>
            </Row>
            {renderFee(item)}
          </Col>
        </Row>
      );
    }
  };
  return (
    <Col className="table-right">
      <Row>
        <Col span={12} style={{ fontWeight: "bold", color: COLORS.sub_accent }}>
          {[
            { label: STRINGS.name_user_booking, value: data.name },
            {
              label: STRINGS.furigana_name,
              value: data.furiganaName,
            },
            { label: STRINGS.gender, value: STRINGS[data.gender] },
            {
              label: STRINGS.member,
              value: data.banned
                ? STRINGS.customer_type_new.X
                : STRINGS.customer_type_new[data.customerType || data.type] ||
                  "ー",
              styleValue: {
                color: data.banned
                  ? LOGIC.colorCustomerType.X
                  : LOGIC.colorCustomerType[data.customerType || data.type],
              },
            },
            {
              label: STRINGS.registration_date,
              value: data.registrationDate
                ? moment(data.registrationDate).format("YYYY.MM.DD")
                : "",
            },
            {
              label: STRINGS.favourite_store,
              value: data.storeName,
            },
            {
              label: STRINGS.NG_designation,
              value: NG.length > 0 ? NG : "",
            },
            {
              label: STRINGS.phone_number,
              value: data.phoneNumber
                ? Validate.convertPhoneJp(data.phoneNumber)
                : "",
            },
            {
              label: STRINGS.birthday,
              value: moment(data.birthday).isValid()
                ? `${moment(data.birthday).format(
                    "YYYY.MM.DD"
                  )}(${moment().diff(moment(data.birthday), "years")})`
                : "",
            },
            {
              label: STRINGS.address,
              value: data.address,
            },
          ].map((item) => (
            <InfoCustomer
              key={item.label}
              label={item.label}
              value={item.value || "ー"}
              styleValue={item.styleValue}
              styleWrapper={item.styleWrapper}
            />
          ))}
        </Col>
        <Col span={12}>
          <Button
            disabled
            className="bottom-btn"
            style={{
              width: "100%",
              height: "fit-content",
              justifyContent: "center",
              backgroundColor: "#4f7a28",
            }}
            onClick={() => {
              ModalCustomService.set({
                title: STRINGS.edit_information_customer,
                wrapperStyle: { width: "auto" },
                contentWrapperStyle: { width: "fit-content" },
                contentStyle: { maxHeight: "80vh", padding: 0 },
                content: (
                  <ModalInfoCustomer
                    data={data}
                    refetchCalendarOrder={refetch}
                  />
                ),
              });
            }}>
            <EditOutlined className="icon" style={{ fontSize: 30 }} />
            <Row
              style={{
                fontSize: 23,
                fontWeight: "bold",
                letterSpacing: 3,
              }}>
              {(data.customerType || data.type) === "R" && !data.banned
                ? STRINGS.registration
                : STRINGS.edit}
            </Row>
          </Button>
          <Row
            style={{
              marginTop: 18,
              fontSize: 18,
              fontWeight: "bold",
              whiteSpace: "pre-line",
              justifyContent: "center",
              color: COLORS.sub_accent,
            }}>
            {STRINGS.info_background}
          </Row>
          <Col
            style={{
              paddingBottom: 20,
              border: `1px solid ${COLORS.sub_text}`,
              borderRadius: 5,
              minHeight: 150,
              paddingTop: 15,
            }}>
            <div
              style={{
                whiteSpace: "pre-line",
                fontWeight: "bold",
                color: COLORS.sub_accent,
              }}>
              {data.backgroundDisease}
            </div>
          </Col>
        </Col>
      </Row>

      <Row
        style={{
          fontSize: 40,
          letterSpacing: 8,
          fontWeight: "bold",
          justifyContent: "center",
          marginTop: 10,
          color: COLORS.sub_accent,
        }}>
        {STRINGS.history_go_store}
      </Row>
      <Row
        className="theader"
        style={{ marginTop: 20, color: COLORS.sub_accent }}>
        <Col span={3} style={{ fontSize: 18 }}>
          {STRINGS.name2}
        </Col>
        <Col span={6} style={{ fontSize: 18 }}>
          {STRINGS.date2}
        </Col>
        <Col span={5} style={{ fontSize: 18 }}>
          {STRINGS.store_management}
        </Col>
        <Col span={5} style={{ fontSize: 18 }}>
          {STRINGS.responsible_person}
        </Col>
        <Col span={5} style={{ fontSize: 18 }}>
          {STRINGS.course}
        </Col>
      </Row>
      {(dataNG?.orderHistoryDTOS || []).map((item, index) => {
        return (
          <Col
            key={`${index}`}
            className="row-table-custom"
            style={{
              paddingTop: 10,
              borderBottom: "1px solid #b4bbc4",
              color: COLORS.sub_accent,
              fontWeight: "bold",
            }}>
            <Row>
              <Col span={3}>
                {item.chooseStaff ? (
                  <Col
                    style={{
                      marginTop: 5,
                      width: 14,
                      height: 14,
                      left: "40%",
                      borderRadius: 7,
                      borderWidth: 1,
                      borderColor: COLORS.primary,
                      borderStyle: "solid",
                    }}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col span={6} style={{ alignSelf: "center" }}>
                {moment(item.startTime).format(LOGIC.dateFormat)}
              </Col>
              <Col span={5}>{item.storeName}</Col>
              <Col span={5}>{item.staffName}</Col>
              <Col span={5}>
                {Array.isArray(item.comboOrders) && item.comboOrders.length > 0
                  ? item.comboOrders.map(
                      (itm, idx, arr) =>
                        `${itm.comboName || "none"} (${
                          itm.minutes || "none"
                        })` + (idx !== arr.length - 1 ? ", " : "")
                    )
                  : ""}
              </Col>
            </Row>
            <Row
              style={{ marginLeft: 14, paddingBottom: 10, textAlign: "left" }}>
              {renderComment(item)}
            </Row>
          </Col>
        );
      })}

      <PaginationWithSizeChange
        styles={{ color: COLORS.sub_accent }}
        size={pagination.size}
        total={dataNG?.pageDto?.totalElements || 0}
        onChange={(pageNumber) => {
          setPagination({ size: pagination.size, page: pageNumber });
        }}
        onShowSizeChange={(e) => {
          setPagination({ size: e.size, page: 1 });
        }}
        current={pagination.page}
      />
    </Col>
  );
}
