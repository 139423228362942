import React, { useRef, memo } from "react";
import { Table, Space, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "react-query";

import {
  AlertService,
  ButtonCustom,
  Container,
  ErrorView,
  ModalCustomService,
  SvgView,
} from "../../elements";
import { FetchApi } from "../../api";
import { STRINGS, Svgs } from "../../constants";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";
import Add from "./items/Add";
import Edit from "./items/Edit";

import "./StoreStaffAccount.scss";

const StoreStaff = memo(() => {
  const cache = useQueryClient();
  const page = useRef(1);
  const size = useRef(10);

  const columns = [
    {
      title: STRINGS.staff_name_2,
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: STRINGS.email,
      key: "email",
      dataIndex: "email",
      width: 300,
    },
    {
      key: "action",
      render: (text, record) => {
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onEdit(record)}>
              <SvgView svgData={Svgs.edit} />
            </button>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onRemove(record)}>
              <SvgView svgData={Svgs.delete} />
            </button>
          </Space>
        );
      },
      fixed: "right",
      width: 70,
    },
  ];

  const { data, refetch, isLoading, isFetching } = useQuery(
    ["store-staff-account"],
    () => {
      return FetchApi.getAllStaffAdmin({
        page: page.current,
        size: size.current,
      });
    }
  );
  useScrollByMouse(data);

  const setData = () => {
    cache.refetchQueries(`store-staff-account`);
  };
  const onRemove = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${item.name}${STRINGS.confirm_delete_staff}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteStaffAdmin(item.id);
        if (result.success) {
          setData([...data?.staffAdminDTOS, "delete"]);
          message.success(STRINGS.delete_success);
          return;
        }
        message.warning(STRINGS.delete_failed);
      },
    });
  };

  const onCreate = () => {
    ModalCustomService.set({
      title: STRINGS.add_new,
      wrapperStyle: { maxWidth: 600 },
      content: <Add data={data} setData={setData} />,
    });
  };
  const onEdit = (record) => () => {
    ModalCustomService.set({
      title: STRINGS.update,
      wrapperStyle: { maxWidth: 600 },
      content: <Edit data={data} setData={setData} item={record} />,
    });
  };

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Container
      className="manager-store-staff-account"
      renderRight={() => (
        <ButtonCustom onClick={onCreate} icon={<PlusCircleOutlined />}>
          {STRINGS.add_new}
        </ButtonCustom>
      )}>
      <div className="content-staff">
        <Table
          loading={isLoading || isFetching}
          rowKey={"id"}
          columns={columns}
          dataSource={data ? data.staffAdminDTOS : []}
          locale={{ emptyText: STRINGS.no_data }}
          pagination={false}
          scroll={{ x: 600, y: "calc(100vh - 320px)" }}
        />
        <PaginationWithSizeChange
          size={size.current}
          total={data ? data.total : 0}
          onChange={(pageNumber) => {
            page.current = pageNumber;
            refetch();
          }}
          onShowSizeChange={(e) => {
            size.current = Number(e.size);
            page.current = 1;
            refetch();
          }}
          current={page.current}
        />
      </div>
    </Container>
  );
});

export default StoreStaff;
