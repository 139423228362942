import React from "react";
import { Row } from "antd";

import { COLORS, STRINGS, LOGIC } from "../../../constants";

import "./CouponForm.scss";

function CouponBirthday({ onChange, value }) {
  const couponBirthdayList = [
    {
      title: STRINGS.normal_coupon,
      type: LOGIC.coupon_time.default,
    },
    {
      title: STRINGS.birthday_coupon,
      type: LOGIC.coupon_time.birthday,
    },
    {
      title: STRINGS.tax_coupon,
      type: LOGIC.coupon_time.tax,
    },
    {
      title: STRINGS.first_time_use_service_coupon,
      type: LOGIC.coupon_time.first,
    },
    {
      title: STRINGS.serve_booking_coupon,
      type: LOGIC.coupon_time.early,
    },
  ];
  const renderSelect = (item) => {
    if (item.type === value) {
      return (
        <div
          style={{
            width: "18px",
            height: "18px",
            borderRadius: "9px",
            backgroundColor: COLORS.success,
          }}
        />
      );
    }
  };
  return (
    <Row>
      {couponBirthdayList.map((i) => {
        return (
          <Row
            key={`${i.title}`}
            onClick={() => {
              onChange(i.type);
            }}
            style={{ paddingRight: 20, cursor: "pointer" }}>
            <div
              style={{
                width: 24,
                height: 24,
                borderRadius: 12,
                border: `1px solid ${COLORS.border}`,
                backgroundColor: COLORS.background,
                display: "flex",
                placeContent: "center",
                alignItems: "center",
                marginRight: 10,
                marginBottom: 10,
              }}>
              {renderSelect(i)}
            </div>
            {i.title}
          </Row>
        );
      })}
    </Row>
  );
}

export { CouponBirthday };
