import React, { useState, forwardRef } from "react";
import { Col, Row, message, Form } from "antd";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

import { STRINGS } from "../../constants";
import { FetchApi } from "../../api";
import { LoadingView, ModalCustomService } from "../../elements";
import { CommonFunction } from "../../util/CommonFunction";
import { ModalBookingDetailPayment } from "./elements/ModalBookingDetailPayment";
import { ModalBookingDetailComment } from "./elements/ModalBookingDetailComment";
import { useStaffManualBooking } from "../../store/StateStore";
import {
  checkCouponSpecial,
  useCheckInfoStaff,
  useConvertCourseBooking,
  useGetPackStore,
} from "./modules/useGetPackStore";
import { BookingManualInfo } from "./items/BookingManualInfo";
import { BookingManualDetailBottomButton } from "./items/BookingManualDetailBottomButton";
import ModalBookingDetailTableCombo from "./elements/ModalBookingDetailTableCombo";
import ModalBookingDetailTableRight from "./elements/ModalBookingDetailTableRight";

import "./BookingManualDetail.scss";

const BookingManualDetail = forwardRef((props, ref) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { data } = props;

  const { staffInfoLocal } = useStaffManualBooking();
  const { isLoading: isLoadingStaffInfo, designationFee } = useCheckInfoStaff({
    staffId: staffInfoLocal?.id,
    data,
  });
  const { dataCombo, dataProlonged, isLoading } = useGetPackStore({
    userId: data.user?.id,
  });
  const { data: listCoupon } = useQuery("couponAll", () =>
    FetchApi.getCouponBooking()
  );
  const { detailCourseBooking } = useConvertCourseBooking({
    data,
    dataCombo,
    dataProlonged,
  });
  const [combosPicked, setCombosPicked] = useState(detailCourseBooking);
  const [total, setTotal] = useState(data.amount || 0);
  const onCreateBooking = async ({ customerUserId }) => {
    const {
      comboDTOS,
      checkinTime,
      assigned,
      comment,
      couponIds,
      startTime,
      numberOfPointsUsed,
    } = form.getFieldValue();

    let checkIn;
    if (data.checkinTime && moment(data.checkinTime).isValid()) {
      checkIn = moment(data.userCheckInTime).format("HH:mm");
    }
    if (checkinTime) {
      checkIn = moment(checkinTime).format("HH:mm");
    }
    const packs = [];

    comboDTOS.forEach((item, index) => {
      if (item.comboPackId) {
        if (item.comboExtraId) {
          // packIds.push({
          //   id: item.comboPackId,
          //   minutes: item.minutes + item.minuteExtra,
          //   price: item.price + item.priceExtra,
          //   prolongedCourseId: item.comboExtraId,
          // });
          packs.push({
            packId: item.comboPackId,
            prolongedPackId: item.comboExtraId,
          });
        } else {
          packs.push({ packId: item.comboPackId });
        }
      }
    });
    let result;
    if (data.id) {
      result = await FetchApi.updateBookingManual({
        id: data.id,
        assigned,
        comment,
        numberOfPointsUsed,
        couponIds: couponIds?.map?.((i) => i.id || i.value || i),
        checkinTime: checkIn,
        customerId: data.user?.customerId,
        customerName: data.user?.name,
        customerUserId: customerUserId || data.user?.id,
        packs: packs,
        phoneNumber: data.user?.phoneNumber || data.phoneNumber,
        staffId: staffInfoLocal?.id,
        staffName: staffInfoLocal?.name,
        staffPassword: staffInfoLocal?.password,
        start: moment(startTime).toISOString(),
      });
    } else if (!customerUserId) {
      result = await FetchApi.createBookingManual({
        assigned,
        comment,
        numberOfPointsUsed,
        couponIds: couponIds?.map?.((i) => i.id || i.value || i),
        checkinTime: checkIn,
        customerId: data.user?.customerId,
        customerName: data.user?.name,
        customerUserId: data.user?.id,
        packs: packs,
        phoneNumber: data.user?.phoneNumber || data.phoneNumber,
        staffId: staffInfoLocal?.id,
        staffName: staffInfoLocal?.name,
        staffPassword: staffInfoLocal?.password,
        start: moment(startTime).toISOString(),
      });
    }
    if (result.success) {
      queryClient.refetchQueries("getHistoryBookingManual");
      message.success(STRINGS.done_successful);
      ModalCustomService.close();
      return;
    }
    CommonFunction.logError(result);
  };
  const onValuesChange = () => {
    const { comboDTOS, couponIds, assigned } = form.getFieldValue();

    const combosPickedObj = {};
    const combosExtraObj = {};
    let totalPrice = 0;
    (dataCombo || []).map((i) => {
      return (combosPickedObj[i.id] = i);
    });
    dataProlonged.map((i) => {
      return (combosExtraObj[i.id] = i);
    });

    const comboSelectedConvert = comboDTOS.map((i) => {
      let comboObj = {};
      if (i?.comboPackId) {
        comboObj = { ...combosPickedObj[i?.comboPackId] };
      }
      if (i?.comboExtraId) {
        const comboExtraObjConvert = combosExtraObj[i.comboExtraId];

        // if (comboExtraObjConvert.comboId === comboObj?.comboId) {
        comboObj = {
          ...comboObj,
          comboExtraId: i?.comboExtraId,
          minuteExtra: comboExtraObjConvert.minutes,
          priceExtra: comboExtraObjConvert.price || 0,
          priceForCurrentUser:
            comboObj.priceForCurrentUser +
            comboExtraObjConvert.priceForCurrentUser,
        };
        // }
        //  else {
        //   comboObj = {
        //     ...comboObj,
        //     comboExtraId: null,
        //     minuteExtra: null,
        //     priceExtra: 0,
        //   };
        // }
      }
      return comboObj;
    });

    setCombosPicked(comboSelectedConvert);

    const clone = comboSelectedConvert.map((item) => {
      return {
        ...item,
        comboPackId: item?.id,
      };
    });
    form.setFieldsValue({
      comboDTOS: JSON.parse(JSON.stringify([...clone])),
    });
    let totalDiscount = 0;
    // couponIds.forEach((e) => {
    //   totalDiscount = totalDiscount + (e?.discount || 0);
    // });
    couponIds?.forEach?.((i) => {
      const coursesUseThisCoupon = comboSelectedConvert.filter((item) =>
        i.courseIds.includes(item?.courseId)
      );
      const ratio = comboSelectedConvert.length - coursesUseThisCoupon?.length;
      if (ratio >=0) totalDiscount += i.discount || 0;
    });
    const checkIsCouponSpecial = checkCouponSpecial({ arrCoupon: couponIds });
    if (checkIsCouponSpecial) {
      comboSelectedConvert.forEach((i) => {
        if (i?.priceForCurrentUser) {
          totalPrice += i.priceForCurrentUser - i.tax;
        }
      });
    } else {
      comboSelectedConvert.forEach((i) => {
        if (i?.priceForCurrentUser) {
          totalPrice += i.priceForCurrentUser;
        }
      });
    }
    let designationFeeBooking = 0;
    if (assigned) {
      designationFeeBooking = designationFee;
    }
    setTotal(
      totalPrice -
        totalDiscount -
        (data.numberOfPointsUsed || 0) +
        (designationFeeBooking || 0)
    );
  };
  if (isLoading || isLoadingStaffInfo) {
    return <LoadingView />;
  }
  const couponDTO = {};
  listCoupon?.couponDTOList?.map?.((i) => {
    return (couponDTO[i.id] = i);
  });
  return (
    <Form
      form={form}
      initialValues={{
        startTime: data.start && moment(new Date(data.start)),
        couponIds: data.couponIds?.map?.((i) => couponDTO[i]) || [],
        assigned: data.assigned,
        comment: data.comment,
        comboDTOS: detailCourseBooking || [undefined],
        checkinTime: data.checkinTime && moment(data.checkinTime, "HH:mm"),
        numberOfPointsUsed: data.numberOfPointsUsed,
      }}
      onValuesChange={onValuesChange}>
      <Row className="modal-booking-detail-wrapper">
        <Col className="customer-info">
          <BookingManualInfo
            setTotal={setTotal}
            data={data}
            listCoupon={listCoupon}
          />

          <ModalBookingDetailTableCombo
            form={form}
            data={data}
            combosPicked={combosPicked}
            detailCourseBooking={detailCourseBooking}
          />

          <ModalBookingDetailPayment total={total} />

          <ModalBookingDetailComment />
          <BookingManualDetailBottomButton onCreateBooking={onCreateBooking} />
        </Col>

        <ModalBookingDetailTableRight
          bookingId={data.id}
          userId={data.user?.id}
          onCreateBooking={onCreateBooking}
          data={
            data.user
              ? {
                  ...data.user,
                  sex: data.user.sex || data.user.gender,
                  customerType: data.user.customerType || data.user.type,
                }
              : {
                  phoneNumber: data.phoneNumber,
                }
          }
        />
      </Row>
    </Form>
  );
});
export default BookingManualDetail;
