/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Col, Row, Form, Upload, Select, Input, message, Spin } from "antd";
import {
  CaretDownOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

import { useForceUpdate } from "../../../elements/ForceUpdate";
import { CommonFunction } from "../../../util/CommonFunction";
import { FetchApi } from "../../../api";
import { Validate } from "../../../elements/Validate";
import {
  ButtonCustom,
  LoadingAbsolute,
  ModalCustomService,
  SvgView,
} from "../../../elements";
import { useGetComboList } from "../../../util/hooks/useGetComboList";
import { COLORS, Svgs, STRINGS } from "../../../constants";
import Auth from "../../../store/Authentication";

import Bronze from "../../../assets/img/bronze.png";
import Silver from "../../../assets/img/sliver.png";
import Gold from "../../../assets/img/gold.png";
import Platinum from "../../../assets/img/platinum.png";
import Diamond from "../../../assets/img/diamond.png";

import "../StoreStaff.scss";

function Add({ setData, data }) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const forceUpdate = useForceUpdate();
  const avatarUrl = useRef("");
  const skillRequests = useRef([]);
  const [comboPacks, setComboPacks] = useState([]);
  const rank = Form.useWatch("rank", form);
  const rankImage = useRef();
  const removeImg = () => {
    avatarUrl.current = "";
    forceUpdate();
  };
  const handleUpImage = async (file) => {
    setLoadingImg(true);
    let result = await FetchApi.uploadFile(file);
    if (result?.fileUrl) {
      avatarUrl.current = result.fileUrl;
      setLoadingImg(false);
    } else {
      message.warning(STRINGS.upload_img_failed);
      setLoadingImg(false);
    }
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  const uploadButton = (
    <div>
      {loadingImg ? (
        <LoadingOutlined />
      ) : (
        <PlusOutlined style={{ color: COLORS.primary_3, fontSize: 64 }} />
      )}
    </div>
  );

  // const range = (a, b = 23) => {
  //   const arr = [];
  //   while (a < b + 1) {
  //     arr.push(a++);
  //   }
  //   return arr;
  // };

  // const disabledMinutes = (selectedHour) => {
  //   let minutes = [];
  //   const openHour =
  //     storePicked && storePicked.openTime
  //       ? parseInt(storePicked.openTime.slice(0, 2))
  //       : [];
  //   const closeHour =
  //     storePicked && storePicked.closeTime
  //       ? parseInt(storePicked.closeTime.slice(0, 2))
  //       : 24;
  //   const openMinute =
  //     storePicked && storePicked.openTime
  //       ? parseInt(storePicked.openTime.slice(3, 5))
  //       : [];
  //   const closeMinute =
  //     storePicked && storePicked.closeTime
  //       ? parseInt(storePicked.closeTime.slice(3, 5))
  //       : [];
  //   if (selectedHour == closeHour) {
  //     for (let i = 0; i < 60; i++) {
  //       if (i > closeMinute) {
  //         minutes.push(i);
  //       }
  //     }
  //   }
  //   return minutes;
  // };

  const onAdd = async () => {
    try {
      await form.validateFields();
      let updateRank = "BRONZE";
      if (rank === STRINGS.rank.silver) updateRank = "SILVER";
      if (rank === STRINGS.rank.gold) updateRank = "GOLD";
      if (rank === STRINGS.rank.platinum) updateRank = "PLATINUM";
      if (rank === STRINGS.rank.diamond) updateRank = "DIAMOND";
      const fieldData = form.getFieldValue();
      fieldData.avatarUrl = avatarUrl.current;
      fieldData.storeIds = [userAdmin.storeId];
      fieldData.skillRequests = skillRequests.current;
      fieldData.skills = skillRequests.current;
      fieldData.rank = updateRank;
      setLoading(true);
      const result = await FetchApi.createStaffByStore(fieldData);
      if (result.success) {
        ModalCustomService.close();
        setData([result.staffDTO, ...(data?.data || [])]);
        message.success(STRINGS.add_staff_successful);
      } else if (result.message === "This staff phone already exist") {
        setLoading(false);
        message.error(STRINGS.phone_is_existed);
      } else if (result.message === "This staff email already exist") {
        setLoading(false);
        message.error(STRINGS.email_is_existed);
      } else if (result.message === "Staff can only work at most 3 stores") {
        setLoading(false);
        message.error(STRINGS.staff_max_3_store);
      } else if (result.message === "This staff already exist") {
        setLoading(false);
        message.error(STRINGS.staff_is_existed);
      } else {
        setLoading(false);
        message.error(result.message || STRINGS.add_staff_failed);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  const onGetInfoStaff = async () => {
    if (!form.getFieldValue().getStaffInfo) {
      message.warning(STRINGS.fill_your_staff_id);
      return;
    }
    const res = await FetchApi.getStaffById(form.getFieldValue().getStaffInfo);
    if (res.success) {
      const {
        email,
        name,
        phoneNumber,
        selfIntroduction,
        sex,
        // skills,
        comboPacks,
        restMinute,
      } = res.staffDTO;
      avatarUrl.current = res.staffDTO.avatarUrl;
      // skillRequests.current = skills || [];
      form.setFieldsValue({
        name,
        email,
        phoneNumber,
        selfIntroduction,
        sex,
        comboPackIds: comboPacks,
        restMinute,
      });
      setComboPacks(comboPacks);
      forceUpdate();
      // form.resetFields(["skill"]);
    } else if (res.code === 4004) {
      message.warning(STRINGS.no_staff_avaliable);
    } else {
      CommonFunction.logError(res);
    }
  };

  // const openHour = storePicked?.openTime
  //   ? parseInt(storePicked.openTime.slice(0, 2))
  //   : [];
  // const closeHour = storePicked?.closeTime
  //   ? parseInt(storePicked.closeTime.slice(0, 2))
  //   : 24;
  // let openHours = [];
  // let closeHours = [];

  // if (closeHour > openHour) {
  //   openHours = Array.from(Array(openHour).keys());
  //   closeHours = range(closeHour + 1);
  // } else {
  //   openHours = range(closeHour + 1, openHour - 1);
  // }
  let patternFee = new RegExp(/^[0-9]+$/);
  let initialFee = Form.useWatch("initialFee", form);
  if (rank === STRINGS.rank.bronze) {
    patternFee = new RegExp(/^(0?\d|\d\d|0\d\d|1\d\d|200)$/);
    rankImage.current = Bronze;
    initialFee = 100;
  }
  if (rank === STRINGS.rank.silver) {
    initialFee = 300;
    rankImage.current = Silver;
  }
  if (rank === STRINGS.rank.gold) {
    initialFee = 500;
    rankImage.current = Gold;
  }
  if (rank === STRINGS.rank.platinum) {
    initialFee = 700;
    rankImage.current = Platinum;
  }
  if (rank === STRINGS.rank.diamond) {
    initialFee = 1000;
    rankImage.current = Diamond;
  }
  useEffect(() => {
    form.setFieldValue("designationFee", initialFee);
  }, [initialFee]);
  return (
    <Col>
      <Form layout="vertical" form={form}>
        <Row gutter={20}>
          <Col className="staff-avatar">
            <div className="name">{STRINGS.avatar}</div>
            {avatarUrl.current && (
              <button
                style={{
                  position: "absolute",
                  alignSelf: "flex-end",
                  top: 30,
                  right: 10,
                  width: "32px",
                  height: "32px",
                  borderWidth: 0,
                  backgroundColor: "white",
                  borderRadius: 8,
                  overflow: "hidden",
                  paddingTop: 5,
                  cursor: "pointer",
                }}
                onClick={removeImg}
              >
                <SvgView svgData={Svgs.delete} />
              </button>
            )}
            <ImgCrop
              modalTitle={STRINGS.editImageCrop}
              modalOk={STRINGS.ok}
              modalCancel={STRINGS.cancel}
              rotate
              shape={"round"}
              aspect={1 / 1}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={CommonFunction.beforeUpload}
                customRequest={handleUpImage}
                onPreview={onPreview}
              >
                {!!avatarUrl.current ? (
                  <img
                    style={{ width: 120, borderRadius: 60 }}
                    src={avatarUrl.current}
                    alt="img"
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </ImgCrop>
          </Col>
          <Col style={{ flex: 1 }}>
            <Row style={{ justifyContent: "flex-start", alignItems: "center" }}>
              <Form.Item name="getStaffInfo" label={STRINGS.staff_id}>
                <Input
                  style={{ minWidth: 250 }}
                  placeholder={STRINGS.fill_your_staff_id}
                />
              </Form.Item>
              <ButtonCustom
                onClick={onGetInfoStaff}
                style={{
                  marginLeft: 20,
                }}
              >
                {STRINGS.search_booking}
              </ButtonCustom>
            </Row>
            <Row gutter={20}>
              <Col xl={8} md={8} xs={8}>
                <Form.Item
                  label={STRINGS.staff_name_2}
                  name="name"
                  required
                  rules={[Validate.emptyContent]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xl={8} md={8} xs={8}>
                <Form.Item
                  label={STRINGS.gender}
                  name="sex"
                  required
                  rules={[Validate.emptyContent]}
                >
                  <Select
                    suffixIcon={
                      <CaretDownOutlined style={{ color: COLORS.border_6 }} />
                    }
                  >
                    {[
                      { name: STRINGS.male, id: "male" },
                      { name: STRINGS.female, id: "female" },
                    ].map((item, index) => (
                      <Select.Option key={"" + item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} md={8} xs={8}>
                <Form.Item
                  label={STRINGS.email}
                  name="email"
                  rules={[Validate.email]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col xl={8} md={8} xs={8}>
                <Form.Item
                  label={STRINGS.phone_number}
                  name="phoneNumber"
                  required
                  rules={[Validate.phoneNumberJp]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xl={8} md={8} xs={8}>
                <Form.Item
                  label={STRINGS.interval_time}
                  name="restMinute"
                  required
                  rules={[Validate.emptyContent]}>
                  <Select
                    suffixIcon={
                      <CaretDownOutlined style={{ color: COLORS.border_6 }} />
                    }>
                    {[
                      { name: `30 ${STRINGS.minute}`, id: "30" },
                      { name: `20 ${STRINGS.minute}`, id: "20" },
                      { name: `10 ${STRINGS.minute}`, id: "10" },
                    ].map((item, index) => (
                      <Select.Option key={"" + item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={8} md={8} xs={8}>
                <Form.Item label={STRINGS.rank.title} name="rank">
                  <Select
                  disabled
                    options={[
                      {
                        value: STRINGS.rank.bronze,
                        label: STRINGS.rank.bronze,
                      },

                      {
                        value: STRINGS.rank.silver,
                        label: STRINGS.rank.silver,
                      },
                      {
                        value: STRINGS.rank.gold,
                        label: STRINGS.rank.gold,
                      },
                      {
                        value: STRINGS.rank.platinum,
                        label: STRINGS.rank.platinum,
                      },
                      {
                        value: STRINGS.rank.diamond,
                        label: STRINGS.rank.diamond,
                      },
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Col xl={8} md={8} xs={8}>
                <Row>
                  <Form.Item
                    label={
                      <div>
                        <span>{STRINGS.designation_fee}</span>&nbsp;
                        {rank === STRINGS.rank.bronze && <span>(0 ~ 200)</span>}
                      </div>
                    }
                    name="designationFee"
                    rules={[
                      {
                        pattern: patternFee,
                        message: STRINGS.number_please,
                      },
                    ]}
                  >
                    <Input disabled={rank != STRINGS.rank.bronze} />
                  </Form.Item>
                  <Col style={{ marginTop: 26, fontSize: 16, marginLeft: 10 }}>
                    {STRINGS.yen}
                  </Col>
                </Row>
              </Col>
              {/* <Col xl={8} md={12} xs={12}>
                <Form.Item
                  label={STRINGS.procedure_range}
                  name="skill"
                  initialValue={
                    Array.isArray(skillRequests.current)
                      ? skillRequests.current.map((itm) => itm.id)
                      : null
                  }>
                  <Select
                    onChange={(e, f) => {
                      skillRequests.current = [];
                      e.forEach((element) => {
                        const it = listSkill.find((itm) => itm.id == element);
                        skillRequests.current.push(it);
                      });
                    }}
                    mode="multiple"
                    suffixIcon={
                      <CaretDownOutlined style={{ color:  }} />
                    }>
                    {listSkill.map((item, index) => (
                      <Select.Option key={"" + item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> */}
            </Row>
            <Row style={{ width: "100%", padding: 0 }}>
              <Form.Item
                style={{ width: "100%" }}
                label={STRINGS.self_introduction}
                name="selfIntroduction"
              >
                <Input />
              </Form.Item>
            </Row>
            <Row style={{ width: "100%" }}>
              <Form.Item name="comboPackIds" style={{ width: "100%" }}>
                <WorkableCombo comboPacks={comboPacks} />
              </Form.Item>
            </Row>
          </Col>
        </Row>
        {/* <Form.Item name="staffScheduleRequests">
          <ListCalendar
            disabledMinutes={disabledMinutes}
            openHours={openHours}
            closeHours={closeHours}
          />
        </Form.Item> */}
      </Form>

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}
        >
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onAdd}>{STRINGS.save}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}

// function ListCalendar({ onChange, disabledMinutes, openHours, closeHours }) {
//   const [list, setList] = useState([]);

//   React.useEffect(() => {
//     const listClone = [];
//     for (let i = 1; i < 8; i++) {
//       listClone.push({
//         dayOfWeek: i,
//         shiftRequests: [
//           { startTime: "", endTime: "" },
//           { startTime: "", endTime: "" },
//           { startTime: "", endTime: "" },
//           { startTime: "", endTime: "" },
//           { startTime: "", endTime: "" },
//         ],
//       });
//     }
//     setList(listClone);
//     onChange(listClone);
//   }, []);

//   const onChangeCalendar = (item, e, shiftIndex) => {
//     const listClone = [...list];
//     listClone[item.dayOfWeek - 1].shiftRequests[shiftIndex].startTime = moment(
//       e[0]
//     ).format("HH:mm");
//     listClone[item.dayOfWeek - 1].shiftRequests[shiftIndex].endTime = moment(
//       e[1]
//     ).format("HH:mm");
//     setList(listClone);
//     onChange(listClone);
//   };

//   return (
//     <div className="calendar">
//       <div className="row-title">{STRINGS.config_schedule}</div>
//       <div className="calendar-content">
//         {list.map((day, dayIndex) => {
//           return (
//             <div key={`${dayIndex}`} className="item-calendar">
//               <div className="item-day">{LOGIC.day[day.dayOfWeek]}</div>
//               <Col>
//                 {day.shiftRequests.map((shift, shiftIndex) => {
//                   return (
//                     <TimePicker.RangePicker
//                       key={`${shiftIndex}`}
//                       className="item-button"
//                       format="HH:mm"
//                       suffixIcon={false}
//                       placeholder={[STRINGS.start_time, STRINGS.end_time]}
//                       bordered={false}
//                       onChange={(e) => onChangeCalendar(day, e, shiftIndex)}
//                       disabledHours={() => {
//                         return [...openHours, ...closeHours];
//                       }}
//                       disabledMinutes={disabledMinutes}
//                       order={true}
//                     />
//                   );
//                 })}
//               </Col>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }

function WorkableCombo({ value, onChange, comboPacks }) {
  const [list, setList] = useState({});
  // const list = useRef({});
  const { listCombo, isLoadingGetComboList, isFetchingGetComboList } =
    useGetComboList();

  useEffect(() => {
    if (value?.length > 0) {
      const arrCombo = [];
      const cloneList = {};
      value.forEach((item) => {
        if (Array.isArray(cloneList[item?.comboId])) {
          cloneList[item.comboId].push(item.id);
        } else {
          cloneList[item.comboId] = [];
          cloneList[item.comboId].push(item.id);
        }
        arrCombo.push(item.id);
      });
      onChange(arrCombo);
      setList(cloneList);
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(comboPacks)) {
      const arrCombo = [];
      const cloneList = {};
      comboPacks.forEach((item) => {
        if (Array.isArray(cloneList[item?.comboId])) {
          cloneList[item.comboId].push(item.id);
        } else {
          cloneList[item.comboId] = [];
          cloneList[item.comboId].push(item.id);
        }
        arrCombo.push(item.id);
      });
      onChange(arrCombo);
      setList(cloneList);
    }
  }, [comboPacks]);
  if (isLoadingGetComboList || isFetchingGetComboList) {
    return <Spin style={{ position: "absolute", left: `calc(100% / 2)` }} />;
  }

  return (
    <Col>
      {listCombo.map((item) => {
        return (
          <Row
            key={item.id}
            style={{
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 6,
            }}
          >
            <Col span={5} style={{ color: "#757575" }}>
              {item.name}
            </Col>
            <Col span={18}>
              <Select
                value={list[item.id]}
                onChange={(e) => {
                  let apiData = [];
                  const newList = { ...list, [item.id]: e };
                  setList(newList);
                  Object.values(newList).forEach((item) => {
                    apiData = [...apiData, ...item];
                    onChange(apiData);
                  });
                }}
                mode="multiple"
                suffixIcon={
                  <CaretDownOutlined style={{ color: COLORS.border_6 }} />
                }
              >
                {(item.comboPackDTOS || []).map((item, index) => (
                  <Select.Option key={`${item.id}`} value={item.id}>
                    {item.comboName}({item.minutes}分)
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        );
      })}
    </Col>
  );
}

export { WorkableCombo };
export default Add;
