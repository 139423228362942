import { Col, message, Row, Select } from "antd";
import React, { useRef, useState } from "react";
import moment from "moment";

import {
  ButtonCustom,
  LoadingAbsolute,
  ModalCustomService,
  SuffixIconDown,
} from "../../../elements";
import { STRINGS } from "../../../constants/Strings";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util/CommonFunction";

import "../StoreActualSchedule.scss";

function ModalRequestAbsent({ staffInfo, workingDay, refetchCalendarOrder }) {
  const staffChangeRequest = useRef({});
  const shiftRequests = useRef(
    staffInfo?.workShiftDTOS?.[0]
      ? staffInfo?.workShiftDTOS?.[0]?.scheduleId
      : ""
  );
  const [loading, setLoading] = useState(false);

  const onChangeRestTime = async () => {
    try {
      setLoading(true);
      const result = await FetchApi.timekeepingCustom({
        staffChangeRequest: { ...staffChangeRequest.current, type: "on_leave" },
        scheduleId: shiftRequests.current,
      });
      setLoading(false);
      if (result.success) {
        message.success(STRINGS.saved_common);
        ModalCustomService.close();
        refetchCalendarOrder();
      } else if (result.code == 4006) {
        message.warning(STRINGS.has_booking_please_change);
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      message.error(STRINGS.something_wrong);
      setLoading(false);
    }
  };

  return (
    <Col className="checkin-time-wrapper">
      <Col style={{ padding: 10, width: 500 }}>
        <Select
          onChange={(e) => {
            shiftRequests.current = e;
          }}
          style={{ width: "50%", marginTop: 14 }}
          suffixIcon={<SuffixIconDown />}
          defaultValue={staffInfo?.workShiftDTOS?.[0]?.scheduleId}>
          {staffInfo.workShiftDTOS.map((item) => {
            return (
              <Select.Option key={`${item.scheduleId}`} value={item.scheduleId}>
                {moment(item.startTime).format("HH:mm")}~
                {moment(item.endTime).format("HH:mm")}
              </Select.Option>
            );
          })}
        </Select>

        <Row justify="center" style={{ margin: "20px 0px" }}>
          <ButtonCustom
            onClick={ModalCustomService.close}
            isCancelButton
            style={{
              marginRight: 10,
            }}>
            {STRINGS.cancel}
          </ButtonCustom>
          <ButtonCustom onClick={onChangeRestTime}>{STRINGS.sure}</ButtonCustom>
        </Row>
      </Col>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}

export default ModalRequestAbsent;
