import React from "react";
import { Col, Row } from "antd";

import { COLORS, LOGIC, STRINGS } from "../../../constants";

function ModalBookingDetailPayment({
  disabled,
  total,
  disablePay,
  isChargeCancelFee,
  onAction,
  form,
  data,
  couponSelected,
  statusBooking,
}) {
  // const renderCombo = () => {
  //   const { comboDTOS } = form.getFieldValue();
  //   if (!comboDTOS || comboDTOS.length === 0 || !comboDTOS[0]?.id) {
  //     return (
  //       <Col style={{ fontWeight: "bold" }}>
  //         {data.minutes}
  //         {STRINGS.minute}
  //       </Col>
  //     );
  //   }
  //   return (
  //     <Col span={19}>
  //       {comboDTOS.map((i, index) => (
  //         <Col
  //           key={`${index}`}
  //           style={{ fontWeight: "bold", whiteSpace: "pre-line" }}>
  //           {i.comboName + "\xa0\xa0"}
  //           {i.minutes}
  //           {STRINGS.minute + "\n"}
  //           {!!i.minuteExtra && (
  //             <Row>
  //               +{STRINGS.extension + "\xa0\xa0"}
  //               {i.minuteExtra}
  //               {STRINGS.minute + "\xa0\xa0"}
  //               {/* {i.priceExtra}
  //               {STRINGS.yen} */}
  //             </Row>
  //           )}
  //         </Col>
  //       ))}
  //     </Col>
  //   );
  // };
  // const renderDiscount = () => {
  //   return (
  //     <div style={{ fontWeight: "bold" }}>
  //       {!!couponSelected?.discount && (
  //         <Col>
  //           {STRINGS.coupon_management}
  //           {"\xa0 \xa0"} {"-" + couponSelected.discount}
  //           {STRINGS.yen}
  //         </Col>
  //       )}
  //       {!!data?.pointUse && (
  //         <Col>
  //           {STRINGS.discount_point}
  //           {"\xa0 \xa0"} {"-" + (data?.pointUse || 0)}
  //           pt({STRINGS.yen})
  //         </Col>
  //       )}
  //     </div>
  //   );
  // };
  // const contentPay = () => {
  //   const { couponSelected } = form.getFieldValue();
  //   return (
  //     <div
  //       style={{ fontSize: 25, color: COLORS.sub_accent, fontWeight: "bold" }}>
  //       <Row>
  //         <Col span={5}>{STRINGS.course}</Col>
  //         {renderCombo()}
  //       </Row>
  //       {(!!couponSelected?.discount || !!data?.pointUse) && (
  //         <Row>
  //           <Col span={5}>{STRINGS.discount_book}</Col>
  //           {renderDiscount()}
  //         </Row>
  //       )}

  //       {!!data.chooseStaff && (
  //         <Row>
  //           <Col span={5}>{STRINGS.name2}</Col>
  //           <Col>{data.staffName}</Col>
  //         </Row>
  //       )}
  //       <Row style={{ alignItems: "center" }}>
  //         <Col span={5}>{STRINGS.money_book}</Col>
  //         <Col style={{ color: COLORS.primary, fontSize: 60 }}>
  //           {total}
  //           {STRINGS.yen}
  //         </Col>
  //       </Row>
  //     </div>
  //   );
  // };
  let colorText = "#F19834";
  // let colorCash = "#FE6C06";
  if (disabled || data.paid) {
    colorText = COLORS.disabled;
  }
  if (disabled || data.paid || disablePay || !isChargeCancelFee) {
    // colorCash = COLORS.disabled;
  }
  return (
    <Col>
      <Row
        style={{
          justifyContent: "flex-end",
          fontSize: 40,
          fontWeight: "bold",
          color: COLORS.sub_accent,
        }}>
        <span>{STRINGS.total + ":\xa0\xa0"}</span>
        <span
          style={{
            color: colorText,
          }}>
          {LOGIC.currencyYenFormatter(total)}
        </span>
      </Row>
    </Col>
  );
}
export { ModalBookingDetailPayment };
