import { Col, Input, message, Row, Space, Table } from "antd";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { SearchOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import { FetchApi } from "../../api";
import {
  BlockUI,
  LoadingView,
  Container,
  ErrorView,
  ButtonCustom,
  AlertService,
  SvgView,
} from "../../elements/";
import { Convert, Validate } from "../../elements";
import { FilterByTime } from "../../elements/FilterByTime";
import { COLORS, STRINGS, LOGIC, Svgs } from "../../constants";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";
import Auth from "../../store/Authentication";

import "../../util/variables.scss";
import "./HistoryStore.scss";
import { StaffList } from "../staff-not-good/items/StaffList";
import { AccountService } from "../../services";
const formatsu = "YYYY-MM-DD";

const HistoryStore = () => {
  const { id } = useParams();
  const blockUI = useRef();
  const page = useRef(1);
  const size = useRef(10);
  const startTimeRef = useRef(moment().subtract(1, "days").format(formatsu));
  const staffId = useRef();
  const endTimeRef = useRef(moment().format(formatsu));
  const searchText = useRef();
  const pointType = useRef("");
  const timeout = useRef();
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const { data, refetch, isLoading } = useQuery([`HistoryStore`], () =>
    FetchApi.getHistoryVisitUser({
      size: size.current,
      page: page.current,
      startDate: moment(startTimeRef.current).format("MM/DD/YYYY"),
      endDate: moment(endTimeRef.current).format("MM/DD/YYYY"),
      nameSearch: searchText.current,
      pointType: pointType.current,
      storeId: id || userAdmin.storeId,
      staffId: staffId.current,
    })
  );
  const renderPointProvider = (record) => {
    const {
      autoBookingPoint,
      manualBookingPoint,
      manualBookingPointName,
      autoBookingPointName,
    } = record;
    if (autoBookingPoint && manualBookingPoint) {
      return (
        <>
          <p>{`${autoBookingPointName} ${autoBookingPoint}pt`}</p>
          <p>{`${manualBookingPointName} ${manualBookingPoint}pt`}</p>
        </>
      );
    }
    if (autoBookingPoint) {
      return `${autoBookingPointName} ${autoBookingPoint}pt`;
    }
    if (manualBookingPoint) {
      return `${manualBookingPointName} ${manualBookingPoint}pt`;
    }
  };
  useEffect(() => {
    AccountService.set(userAdmin);
  }, [userAdmin]);
  const columns = [
    {
      title: STRINGS.day_go_store,
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime) => moment(startTime).format(LOGIC.timeFormat),
      align: "center",
    },
    // {
    //   title: "予約方法",
    //   dataIndex: "createFrom",
    //   key: "createFrom",
    //   align: "center",
    // },
    // {
    //   title: "受付時刻",
    //   dataIndex: "startTime",
    //   key: "startTimeStaff",
    //   render: (startTime) =>
    //     moment(startTime).subtract(10, "m").format(LOGIC.timeFormat),
    //   align: "center",
    // },
    // {
    //   title: "ポイント利用",
    //   dataIndex: "pointUserUsed",
    //   key: "pointUserUsed",
    //   align: "center",
    // },
    // {
    //   title: "ポイント付与",
    //   key: "pointProvider",
    //   render: (record) => {
    //     return renderPointProvider(record);
    //   },
    //   align: "center",
    // },
    {
      title: STRINGS.user_name,
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      render: (e) => Convert.removeSpaceName(e),
    },
    {
      title: STRINGS.furigana_name,
      dataIndex: "furiganaName",
      key: "furiganaName",
      align: "center",
      render: (e) => Convert.removeSpaceName(e),
    },
    {
      title: "ID",
      dataIndex: "customerId",
      key: "customerId",
      align: "center",
    },
    {
      title: STRINGS.responsible_person,
      dataIndex: "staffName",
      key: "staffName",
      align: "center",
    },
    {
      title: STRINGS.staff_id,
      dataIndex: "staffId",
      key: "staffId",
      align: "center",
    },
    {
      title: STRINGS.is_assign,
      dataIndex: "chooseStaff",
      key: "chooseStaff",
      render: (e, dataAll) => {
        if (e) {
          return (
            <div
              style={{
                backgroundColor: "red",
                width: 10,
                height: 10,
                borderRadius: 5,
                marginLeft: "45%",
              }}
            />
          );
        }
        if (dataAll.assignedSex) {
          return (
            <Col
              style={{
                fontWeight: "bold",
                color: COLORS.gender_staff[dataAll.assignedSex],
              }}>
              {STRINGS.staff_gender[dataAll.assignedSex]}
            </Col>
          );
        }
      },
      align: "center",
    },
    {
      title: STRINGS.course_used,
      dataIndex: "comboOrders",
      key: "comboOrders",
      render: (e) => {
        if (Array.isArray(e)) {
          return e.map((item, index) => {
            return (
              <Col key={`${index}`}>
                <Row>{item.comboName}</Row>
                <Row>
                  <p style={{ margin: "0 auto" }}>
                    {item.minutes}
                    {STRINGS.minute}
                  </p>
                </Row>
              </Col>
            );
          });
        }
      },
      align: "center",
    },
    {
      title: STRINGS.total_price,
      dataIndex: "price",
      key: "price",
      align: "center",
      render: (e) => {
        if (e) {
          return (
            <div>
              {e} {STRINGS.yen}
            </div>
          );
        }
      },
    },
    {
      title: STRINGS.store_wage,
      dataIndex: "storeWage",
      key: "storeWage",
      align: "center",
      render: (e) => {
        if (e) {
          return (
            <div>
              {e} {STRINGS.yen}
            </div>
          );
        }
      },
    },
    {
      title: STRINGS.cancel,
      dataIndex: "status",
      key: "status",
      render: (e) => {
        if (e.toString().includes("cancel")) {
          return LOGIC.actionBookingType[e];
        }
      },
      align: "center",
    },
    {
      title: STRINGS.time_checkin,
      dataIndex: "userCheckinTime",
      key: "userCheckinTime",
      render: (userCheckinTime) =>
        userCheckinTime && moment(userCheckinTime).format(LOGIC.timeFormat),
      align: "center",
    },
    {
      title: STRINGS.time_checkout,
      dataIndex: "userCheckoutTime",
      key: "userCheckoutTime",
      render: (userCheckoutTime) =>
        userCheckoutTime && moment(userCheckoutTime).format(LOGIC.timeFormat),
      align: "center",
    },
    {
      title: STRINGS.createFrom,
      dataIndex: "createFrom",
      key: "createFrom",
      align: "center",
    },
    {
      title: STRINGS.startTimeStaff,
      dataIndex: "startTime",
      key: "startTime",
      render: (startTime) => {
        return moment(startTime).subtract(10, "m").format(LOGIC.timeFormat);
      },
      align: "center",
    },
    {
      title: STRINGS.pointUserUsed,
      dataIndex: "pointUserUsed",
      key: "pointUserUsed",
      align: "center",
    },
    {
      title: STRINGS.pointProvider,
      key: "pointProvider",
      render: (record) => {
        return renderPointProvider(record);
      },
      align: "center",
    },
    {
      title: STRINGS.remarks,
      dataIndex: "commentOfStaff",
      key: "commentOfStaff",
      align: "center",
    },

    {
      key: "action",
      render: (text, record) => {
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onRemove(record)}>
              <SvgView svgData={Svgs.delete} />
            </button>
          </Space>
        );
      },
      fixed: "right",
      width: 60,
    },
  ];

  const onRemove = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: STRINGS.confirm_delete_common,
      onConfirm: async () => {
        let result = await FetchApi.deleteHistoryVisitStore(item.orderId);
        if (result.success) {
          refetch();
          message.success(STRINGS.delete_success);
        } else {
          message.warning(result.message || STRINGS.delete_failed);
        }
      },
    });
  };

  const onSearchByText = (text) => {
    searchText.current = Validate.removeSpecialCharactors(text.target.value);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      if (text === "") {
        searchText.current = null;
      }
      page.current = 1;
      refetch();
    }, 800);
  };

  const renderLeft = () => {
    return (
      <Row align="bottom" style={{ marginBottom: 4 }}>
        <Col>
          <FilterByTime
            key={endTimeRef.current + startTimeRef.current}
            label={STRINGS.start_time}
            onChange={(e) => (startTimeRef.current = e)}
            picker="date"
            format={LOGIC.dateFormat}
            value={startTimeRef.current}
            formatOnChange={formatsu}
            allowClear={false}
          />
        </Col>
        <Col style={{ margin: "0px 10px" }}>
          <FilterByTime
            key={endTimeRef.current + startTimeRef.current}
            picker="date"
            label={STRINGS.end_time}
            onChange={(e) => (endTimeRef.current = e)}
            format={LOGIC.dateFormat}
            formatOnChange={formatsu}
            value={endTimeRef.current}
            allowClear={false}
          />
        </Col>
        <ButtonCustom
          onClick={() => {
            if (
              moment(endTimeRef.current).isBefore(moment(startTimeRef.current))
            ) {
              const end = endTimeRef.current;
              endTimeRef.current = startTimeRef.current;
              startTimeRef.current = end;
            }
            refetch();
          }}>
          {STRINGS.filter}
        </ButtonCustom>

        {userAdmin?.type === "STORE_ADMIN" && (
          <ButtonCustom
            onClick={() => {
              FetchApi.downloadHistoryStore({
                startDate: moment(startTimeRef.current).format("MM/DD/YYYY"),
                endDate: moment(endTimeRef.current).format("MM/DD/YYYY"),
                storeId: id || userAdmin.storeId,
              });
            }}
            style={{
              marginLeft: 16,
            }}>
            {STRINGS.download}
          </ButtonCustom>
        )}
        {/* <Col style={{ margin: "0px 10px" }}>
          <Row>{STRINGS.type_pt_history}</Row>
          <Select
            defaultValue=""
            style={{ width: 146 }}
            onChange={(e) => {
              pointType.current = e;
              page.current = 1;
              refetch();
            }}>
            <Option value="">{STRINGS.all}</Option>
            <Option value="gacha_point">{STRINGS.lottery_point}</Option>
            <Option value="check_in_point">{STRINGS.visit_point}</Option>
            <Option value="use_point">{STRINGS.type_pt_use}</Option>
            <Option value="review_point">{STRINGS.review_point}</Option>
          </Select>
        </Col> */}
        {/* <ButtonCustom
          onClick={() =>
            FetchApi.downloadHistoryVisit({
              startDate: startTimeRef.current,
              endDate: endTimeRef.current,
              nameSearch: searchText.current,
              pointType: pointType.current,
              storeId: id || userAdmin.storeId,
            })
          }>
          {STRINGS.download}
        </ButtonCustom> */}
      </Row>
    );
  };
  const renderRight = () => {
    return (
      <Row style={{ alignItems: "flex-end" }}>
        {/* <Col>
          <div>{STRINGS.total_store_points}</div>
          <Input
            className="input-point-disabled"
            key={data?.totalSendPointStore}
            style={{ borderRadius: 4, width: 140 }}
            value={data?.totalSendPointStore}
            disabled
          />
        </Col> */}
        {/* <Col style={{ margin: "0px 10px" }}>
          <div>{STRINGS.total_points_used}</div>
          <Input
            className="input-point-disabled"
            style={{ borderRadius: 4, width: 140 }}
            value={data?.totalUsePointStore}
            disabled
          />
        </Col> */}
        <Col>
          <Input
            onChange={onSearchByText}
            // className="placeholder-fontsize-9"
            style={{
              borderRadius: 4,
              width: 280,
              flexDirection: "row-reverse",
              paddingLeft: 4,
              marginRight: 4,
            }}
            suffix={<SearchOutlined style={{ color: "#BFBFBF" }} />}
            placeholder={STRINGS.search_pt_history_place_holder}
          />
        </Col>
        <StaffList
          optionAllStaff
          onChange={(e) => {
            if (e === "all") {
              staffId.current = "";
            } else {
              staffId.current = e;
            }

            refetch();
          }}
          isRequired={false}
          style={{ width: 280 }}
        />
      </Row>
    );
  };

  if (isLoading) {
    return <LoadingView />;
  }

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }
  return (
    <Container
      className="history-store"
      renderLeft={renderLeft}
      renderRight={renderRight}
      childrenStyle={{ marginTop: 10 }}>
      <BlockUI ref={blockUI} />
      <Table
        loading={isLoading}
        rowKey={(record) =>
          record.orderId +
          record.staffId +
          record.userCheckinTime +
          record.startTime
        }
        columns={columns}
        dataSource={data?.orderHistoryDTOS}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
        scroll={{ x: 1500, y: "calc(100vh - 320px)" }}
      />
      <PaginationWithSizeChange
        size={size.current}
        total={data?.pageDto?.totalElements || 0}
        onChange={(pageNumber) => {
          page.current = pageNumber;
          refetch();
        }}
        onShowSizeChange={(e) => {
          size.current = e.size;
          page.current = 1;
          refetch();
        }}
        current={page.current}
      />
    </Container>
  );
};

export default HistoryStore;
