import React, { useRef, useState } from "react";
import { Col, Row, Select, Input, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  ButtonCustom,
  LoadingAbsolute,
  ModalCustomService,
  SuffixIconDown,
} from "../../../elements";
import { STRINGS, COLORS } from "../../../constants";
import { FetchApi } from "../../../api";
import { TimePickerAutoAccept } from "../../../elements/TimePickerAutoAccept";
import StaffConfigModal from "./StaffConfigModal/StaffConfigModal";

import "../StaffSchedule.scss";
import { useCheckStoreHaveBooking } from "../../layout/modules/useCheckStoreHaveBooking";

const Option = Select.Option;

export default function ModalRequestRestChooseTime({
  staffInfo,
  scheduleSelected,
  shiftStatus,
  refetchCalendarOrder,
  disabled,
  selectedDate,
}) {
  const { numberOfExtraRestTimeMinutes } = useCheckStoreHaveBooking();

  const generateListMinute = () => {
    if (numberOfExtraRestTimeMinutes) {
      const length = (Number(numberOfExtraRestTimeMinutes) - 30) / 10 + 1;
      return Array.from(Array(length).keys()).map((i) => {
        return {
          value: 30 + i * 10,
          label: `${30 + i * 10}${STRINGS.minute}`,
        };
      });
    }
    return;
  };
  const list = generateListMinute();
  const filtered = (staffInfo.workShiftDTOS || []).filter(
    (itm) => itm.scheduleId === scheduleSelected
  );

  const totalRestMinuteExtra = filtered?.[0]?.totalRestMinuteExtra;

  const [loading, setLoading] = useState(false);
  const staffChangeRequest = useRef({});
  const startRestTime = useRef();
  const newRestTime = useRef(totalRestMinuteExtra);

  const onChangeRestTime = async () => {
    if (!staffChangeRequest.current.password) {
      message.warning(STRINGS.please_type_password);
      return;
    }
    if (!newRestTime.current || !startRestTime.current) {
      message.warning(STRINGS.please_fill_required_fields);
      return;
    }

    try {
      setLoading(true);

      const result = await FetchApi.requestExtraBreakSpecificTime({
        minutes: newRestTime.current,
        shiftId: scheduleSelected,
        staffChangeRequest: {
          ...staffChangeRequest.current,
        },
        staffId: staffInfo.staffId,
        type: "CREATE",
        start: moment(startRestTime.current).format("YYYY-MM-DD HH:mm:ss"),
      });
      setLoading(false);
      if (result.success) {
        refetchCalendarOrder();
        message.success(STRINGS.saved_common);
        ModalCustomService.close();
        return;
      }
      if (result.code === 4003 || result.code === 4103) {
        message.warning(STRINGS.password_is_incorrect);
        return;
      }
      if (result.code === 4005) {
        message.warning(STRINGS.cannot_rest_because_booking);
        return;
      }
      message.warning(STRINGS.cannot_rest_choose_different_time);
      // CommonFunction.logError(result);
    } catch (error) {
      setLoading(false);
      message.error(STRINGS.something_wrong);
    }
  };

  const onCancel = () => {
    ModalCustomService.set({
      wrapperStyle: { width: "auto", maxWidth: 1200 },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <StaffConfigModal
          data={staffInfo}
          shiftStatus={shiftStatus}
          refetchCalendarOrder={refetchCalendarOrder}
          disabled={disabled}
          selectedDate={selectedDate}
        />
      ),
    });
  };

  return (
    <Col>
      <Row className="title-modal">
        <Row style={{ color: "white", fontSize: 16 }}>{STRINGS.rest2}</Row>
        <button onClick={ModalCustomService.close}>
          <CloseOutlined
            className="close"
            style={{ fontSize: "16px", color: "#fff" }}
          />
        </button>
      </Row>

      <Col style={{ padding: 25 }}>
        <Row>
          <Row style={{ alignItems: "center" }}>
            <Col style={{ fontSize: 18 }}>{STRINGS.wish_time}</Col>
            <TimePickerAutoAccept
              minuteStep={10}
              format={"HH:mm"}
              style={{
                borderRadius: 5,
                color: COLORS.sub_accent,
                fontSize: 22,
                fontWeight: "bold",
                border: `1px solid ${COLORS.sub_text}`,
                width: 210,
                marginLeft: 10,
              }}
              onChange={(e) => {
                if (moment(e).hour() < 5) {
                  startRestTime.current = moment(e).add(1, "day");
                } else {
                  startRestTime.current = e;
                }
              }}
              showTime={{
                format: "HH:mm",
                minuteStep: 10,
              }}
            />
          </Row>
          <Row style={{ paddingLeft: 20, alignItems: "center" }}>
            <Col style={{ fontSize: 18 }}>{STRINGS.time_rest_want}:</Col>
            <Select
              onChange={(e) => (newRestTime.current = e)}
              defaultValue={totalRestMinuteExtra}
              style={{
                textAlign: "left",
                width: 210,
                borderRadius: 5,
                border: `1px solid ${COLORS.sub_text}`,
                marginLeft: 10,
              }}
              suffixIcon={<SuffixIconDown />}>
              {list?.map((item) => (
                <Option key={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Row>
        </Row>
        <Row
          style={{
            color: "#4B2A05",
            fontSize: 18,
            paddingTop: 14,
            fontWeight: "bold",
            justifyContent: "center",
          }}>
          {STRINGS.password_for_this_staff}
        </Row>
        <Col
          style={{ padding: 10, cursor: "pointer", margin: "10px 30px" }}
          span={12}>
          <Row style={{ alignItems: "center" }}>
            <Row
              style={{
                border: "1px solid #497D37",
                width: 30,
                height: 30,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 15,
                marginRight: 12,
              }}>
              <Row
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  backgroundColor: "#497D37",
                }}
              />
            </Row>

            <Row style={{ fontSize: 14, color: COLORS.border_7 }}>
              {staffInfo.staffName}
              <Col style={{ fontSize: 14, color: COLORS.required }}>*</Col>
            </Row>
          </Row>

          <Input.Password
            onChange={(e) => {
              staffChangeRequest.current = {
                password: e.target.value,
              };
            }}
          />
        </Col>
        <Row justify="center" style={{ margin: "20px 0px" }}>
          <ButtonCustom
            onClick={onCancel}
            isCancelButton
            style={{
              marginRight: 10,
            }}>
            {STRINGS.cancel}
          </ButtonCustom>
          <ButtonCustom onClick={onChangeRestTime}>{STRINGS.sure}</ButtonCustom>
        </Row>
      </Col>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}
