/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { SyncOutlined, MoneyCollectOutlined } from "@ant-design/icons";
import moment from "moment";

import { FetchApi } from "../../../api";
import { LOGIC, STRINGS, COLORS } from "../../../constants";
import { ButtonCustom, ModalCustomService } from "../../../elements";
import { useCheckStoreHaveBooking } from "../../layout/modules/useCheckStoreHaveBooking";
import StaffConfigModal from "./StaffConfigModal/StaffConfigModal";
import ModalConfirmRevenue from "../../modal-confirm-revenue/ModalConfirmRevenue";
import ModalStepDetail from "./ModalStepDetail";
import { useGetDataHeader } from "../modules/useGetDataHeader";
import { QueryCache, useQueryClient } from "react-query";
import { staffScheduleKey } from "../modules/staffScheduleKey";

function HeaderSchedule({
  staffOrderDTOS,
  refetchCalendarOrder,
  selectedDate,
}) {
  const [time, setTime] = useState(moment());
  const { isEnableViewRevenue } = useCheckStoreHaveBooking();
  const { dataHeader, refetchHeader } = useGetDataHeader();
  const queryClient = useQueryClient();
  const queryCache = new QueryCache({
    onError: (error) => {
      console.log(error);
    },
    onSuccess: (data) => {
      console.log(data);
    },
  });
  useEffect(() => {
    const refetchInterval = setInterval(() => {
      setTime(moment());
      const now = moment();
      const minute = now.minutes();
      const second = now.seconds();
      if (!(minute % 10) && second == 30) {
        refetchHeader();
      }
    }, 1000);

    return () => {
      refetchInterval && clearInterval(refetchInterval);
    };
  }, []);

  const onGoDetailStaffSchedule = (id) => {
    let lastTime = staffOrderDTOS[0]?.workShiftDTOS[0]?.endTime;

    for (let time of staffOrderDTOS) {
      if (moment(time.workShiftDTOS[0]?.endTime).isAfter(moment(lastTime))) {
        lastTime = time.workShiftDTOS[0]?.endTime;
      }
    }
    const dataStaff = staffOrderDTOS.find((i) => i.staffId == id);
    ModalCustomService.set({
      wrapperStyle: { width: "auto", maxWidth: 1200 },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <StaffConfigModal
          data={dataStaff}
          refetchCalendarOrder={refetchCalendarOrder}
          shiftStatus={dataStaff?.shiftStatus}
          selectedDate={selectedDate}
          lastTime={lastTime}
        />
      ),
    });
  };

  let dataPositionNull = [];
  let dataFiltered = (dataHeader?.staffStatuses || []).filter((item) => {
    if (!item.position && item.status) {
      dataPositionNull.push(item);
    }
    if (item.status && item.position) {
      return true;
    }
  });

  dataFiltered.sort((a, b) => a.position - b.position);
  dataFiltered = [...dataFiltered, ...dataPositionNull];

  if (dataFiltered.length < 6) {
    for (let i = dataFiltered.length; i < 6; i++) {
      dataFiltered.push({});
    }
  }
  const goOnStepDetail = () => {
    ModalCustomService.set({
      wrapperStyle: { width: "auto", maxWidth: 1000 },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: <ModalStepDetail />,
    });
  };
  return (
    <Row style={{ backgroundColor: "white", alignItems: "center" }}>
      <Col
        style={{
          padding: "14px 18px",
          fontSize: 26,
          fontWeight: "bold",
          color: "#4b2a05",
        }}
      >
        <Row>
          {`${time.format("MM月DD日")} (${
            LOGIC.day[time.isoWeekday() || 7]
          }) ${time.format("HH:mm")}`}
        </Row>
        <Row>
          {/* <Button
        style={{
          borderColor: COLORS.primary,
          backgroundColor: COLORS.primary,
          borderRadius: 5,
          color: "white",
          marginRight: 20,
          fontSize: 20,
          height: "fit-content",
        }}
        icon={<HomeOutlined />}>
        {STRINGS.download_schedule}
      </Button> */}
          {!!isEnableViewRevenue && (
            <ButtonCustom
              onClick={() => {
                ModalCustomService.set({
                  title: STRINGS.show_revenue_day,
                  titleStyle: {
                    fontSize: 25,
                  },
                  wrapperStyle: { width: "auto", maxWidth: 1200 },
                  contentWrapperStyle: { width: "fit-content" },
                  contentStyle: { maxHeight: "80vh", padding: 0 },
                  content: <ModalConfirmRevenue />,
                });
              }}
              style={{
                marginRight: 20,
                fontSize: 20,
                height: "fit-content",
              }}
              icon={<MoneyCollectOutlined />}
            >
              {STRINGS.show_revenue_day}
            </ButtonCustom>
          )}

          <ButtonCustom
            onClick={() => {
              queryCache.clear();
              queryClient.refetchQueries(staffScheduleKey.paidBooking);
            }}
            style={{
              borderColor: COLORS.sub_secondary,
              backgroundColor: COLORS.sub_secondary,
              fontSize: 20,
              height: "fit-content",
            }}
            icon={<SyncOutlined />}
          >
            {STRINGS.renew_booking}
          </ButtonCustom>

          <ButtonCustom
            onClick={() => {
              FetchApi.downloadAllBookingInToday();
            }}
            style={{
              borderColor: COLORS.primary,
              backgroundColor: COLORS.primary,
              fontSize: 20,
              marginLeft: 20,
              height: "fit-content",
            }}
          >
            {STRINGS.download_ALL_booking_in_Today}
          </ButtonCustom>
          {/* <Col
        style={{
          marginLeft: 10,
          fontWeight: "bold",
          fontSize: 22,
          color: COLORS.primary,
        }}>
        {STRINGS.display_price}
      </Col>
      <Col
        style={{
          marginLeft: 6,
          fontSize: 22,
          fontWeight: "bold",
          color: COLORS.primary,
        }}>
        {LOGIC.currencyFormatter(totalMoney || 0)}
      </Col> */}
        </Row>
      </Col>

      <Col>
        <table className="table-recommend">
          <tbody>
            <tr>
              <td className="th-parent" onClick={() => goOnStepDetail()}>
                <div
                  style={{
                    fontSize: 15,
                    color: COLORS.sub_accent,
                    paddingBottom: 5,
                    fontWeight: "bold",
                  }}
                >
                  {STRINGS.stt}
                </div>

                <div
                  style={{
                    fontSize: 15,
                    marginTop: 6,
                    fontWeight: "bold",
                    color: COLORS.primary,
                    cursor: "pointer",
                  }}
                >
                  ({STRINGS.detail})
                </div>
              </td>
              <td>
                <table>
                  <tbody>
                    <tr>
                      {(dataFiltered || []).map((item, index) => {
                        return (
                          <td
                            onClick={() => {
                              item.staffId &&
                                onGoDetailStaffSchedule(item.staffId);
                            }}
                            className="td-line-1"
                            style={{ color: "#000" }}
                            key={`${index}`}
                          >
                            {index + 1}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      {(dataFiltered || []).map((item, index) => {
                        let color = "gray";
                        if (
                          item.status === "BOOKING" ||
                          item.status === "BREAK"
                        ) {
                          color = COLORS.sub_accent;
                        }
                        if (item.status === "WAITING_BOOKING") {
                          color = COLORS.primary;
                        }
                        if (item.status === "OVERTIME") {
                          color = "blue";
                        }
                        return (
                          <td
                            onClick={() => {
                              item.staffId &&
                                onGoDetailStaffSchedule(item.staffId);
                            }}
                            style={{ color }}
                            className="td-line-2"
                            key={`${index}`}
                          >
                            {item.staffName}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
    </Row>
  );
}

export default HeaderSchedule;
