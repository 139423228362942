/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useQuery } from "react-query";

import { CaretDownOutlined } from "@ant-design/icons";
import { FetchApi } from "../api";
import { COLORS, STRINGS } from "../constants";

const { Option } = Select;

const StoreSelect = ({
  style,
  value,
  onChange,
  className,
  allowClear = true,
}) => {
  const [stores, setStores] = useState([]);
  const [value2, setValue2] = useState(value);
  const { data, isLoading } = useQuery(["store-select-element"], () => {
    return FetchApi.getAllStoreFilter(1, 50);
  });

  useEffect(() => {
    const fetchStore = async () => {
      if (data?.total) {
        const storeFilter = data.data.filter((item) => item.id === value2)[0];
        setValue2(storeFilter?.id);
        setStores(data.data);
      }
    };
    fetchStore();
  }, [data]);

  const onSelect = (e) => {
    setValue2(e);
    onChange && onChange(e);
  };
  const onClear = (e) => {
    setValue2(e);
    onChange && onChange(e);
  };

  const sortPosition = (a, b) => {
    if (a.position < b.position) {
      return 1;
    }
    if (a.position > b.position) {
      return -1;
    }
    return 0;
  };

  const storesSorted = stores.sort(sortPosition);

  if (isLoading) {
    return (
      <Select
        suffixIcon={<CaretDownOutlined style={{ color: COLORS.border_6 }} />}
      />
    );
  }

  return (
    <Select
      allowClear={allowClear}
      onClear={onClear}
      className={className}
      value={value2}
      placeholder={STRINGS.select_one_store}
      onSelect={onSelect}
      style={{ ...style }}
      suffixIcon={<CaretDownOutlined style={{ color: COLORS.border_6 }} />}>
      {storesSorted.map((itm) => (
        <Option key={itm.id} value={itm.id}>
          {itm.name}
        </Option>
      ))}
    </Select>
  );
};

export { StoreSelect };
