import React, { useState } from "react";
import { Col, Row } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";

import { COLORS, STRINGS } from "../../../constants";
import { StaffBookingCourse } from ".";

import "../StaffBooking.scss";

function StaffBookingEachUser({ form, numberOrdinal, total }) {
  const [isShow, setIsShow] = useState(true);

  let rotate = 90;
  if (isShow) {
    rotate = -90;
  }
  if (total === 1) {
    return (
      <Col className="booking-user-area" style={{ paddingBottom: 40 }}>
        <Row
          style={{
            justifyContent: "flex-end",
            zIndex: 100,
            position: "absolute",
            right: 20,
          }}
          onClick={() => {
            setIsShow(!isShow);
          }}>
          <Row
            style={{
              border: `1px solid ${COLORS.border}`,
              height: "fit-content",
              padding: 3,
            }}>
            <DoubleRightOutlined rotate={rotate} />
          </Row>
        </Row>

        {isShow && (
          <StaffBookingCourse
            form={form}
            numberOrdinal={numberOrdinal}
            style={{ paddingTop: 10 }}
          />
        )}
      </Col>
    );
  }
  return (
    <Col className="booking-user-area">
      <Col>
        <Row
          style={{ justifyContent: "space-between" }}
          onClick={() => {
            setIsShow(!isShow);
          }}>
          <Row className="text-booking">{STRINGS.user_num(numberOrdinal)}</Row>
          <Row
            style={{
              border: `1px solid ${COLORS.border}`,
              height: "fit-content",
              padding: "3px",
            }}>
            <DoubleRightOutlined rotate={rotate} />
          </Row>
        </Row>
      </Col>

      {isShow && (
        <StaffBookingCourse form={form} numberOrdinal={numberOrdinal} />
      )}
    </Col>
  );
}

export { StaffBookingEachUser };
