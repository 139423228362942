/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Form, Row } from "antd";

import { COLORS, STRINGS } from "../../../constants";

import "../StaffBooking.scss";

function StaffBookingTotalPrice({ form, numberOrdinal }) {
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalMinute, setTotalMinute] = useState(0);
  useEffect(() => {
    form.setFieldsValue({
      [`bookingTotalPrice${numberOrdinal}`]: totalPrice,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOrdinal, totalPrice]);

  return (
    <Form.Item
      name={`bookingTotalPrice${numberOrdinal}`}
      shouldUpdate={(prevValues, curValues) => {
        let prices = 0;
        let totalMinute = 0;

        console.log("formDataValue", curValues);
        const comboAll = curValues[`bookingComboAll${numberOrdinal}`];
        const comboFav = curValues[`bookingComboFav${numberOrdinal}`];
        const comboStore = curValues[`bookingComboStore${numberOrdinal}`];
        const comboMinutes = curValues[`bookingMinute${numberOrdinal}`];
        const assignStaff = curValues[`bookingSelectStaff${numberOrdinal}`];

        if (comboFav) {
          prices = comboFav?.price || 0;
        } else {
          if (comboAll) {
            prices = comboAll?.price || 0;
          }
          if (comboStore && Object.keys(comboStore).length !== 0) {
            for (const [_, value] of Object.entries(comboStore)) {
              if (value?.price) {
                prices = prices + value.price;
              }
            }
          }
        }
        if (assignStaff?.designationFee) {
          prices = prices + assignStaff.designationFee;
        }
        if (comboMinutes) {
          totalMinute = comboMinutes;
        } else if (comboFav) {
          totalMinute = comboFav?.minutes;
        } else {
          if (comboAll) {
            totalMinute = comboAll.minutes;
          }
          if (comboStore && Object.keys(comboStore).length !== 0) {
            for (const [_, value] of Object.entries(comboStore)) {
              if (value?.price) {
                totalMinute = totalMinute + value.minutes;
              }
            }
          }
        }

        setTotalPrice(prices);
        setTotalMinute(totalMinute);
      }}>
      <Row style={{ justifyContent: "flex-end" }}>
        <Row
          style={{
            font: "normal normal bold 18px/24px Roboto",
            marginRight: "40px",
          }}>
          {STRINGS.time}
          <div
            style={{
              font: "normal normal bold 24px/20px Roboto",
              color: COLORS.primary,
              paddingLeft: "20px",
            }}>
            {totalMinute}
            {STRINGS.minute}
          </div>
        </Row>
        <Row
          style={{
            font: "normal normal bold 18px/24px Roboto",
          }}>
          {STRINGS.total}
          <div
            style={{
              font: "normal normal bold 24px/20px Roboto",
              color: COLORS.primary,
              paddingLeft: "20px",
            }}>
            {totalPrice}
            {STRINGS.yen}
          </div>
        </Row>
      </Row>
    </Form.Item>
  );
}

export { StaffBookingTotalPrice };
