import { AutoComplete, Col, Input, Row } from "antd";
import React, { useState } from "react";

import { COLORS, STRINGS } from "../../../constants";
import { ButtonCustom, ModalCustomService } from "../../../elements";
import { FetchApi } from "../../../api";
import ModalInfoUserDetail from "./ModalInfoUserDetail";

import "../StaffScheduleManual.scss";

function ModalSearchBooking({ selectedDate, refetchCalendarOrder }) {
  const [options, setOptions] = useState([]);

  const searchResult = (datas) => {
    return datas.map((item, index) => {
      return {
        name: item.name,
        value: "" + item.id,
        item: item,
        label: (
          <div style={{ borderWidth: 20 }}>
            <div style={{ fontWeight: "bold" }}>{item.name || ""}</div>
            <div style={{ color: "grey" }}>{item.phoneNumber}</div>
          </div>
        ),
      };
    });
  };
  const onSelect = (e, item) => {
    if (!item?.item?.id) {
      return;
    }
    ModalCustomService.set({
      title: STRINGS.customer_info,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <ModalInfoUserDetail refetch={refetchCalendarOrder} data={item.item} />
      ),
    });
  };
  const onSearch = async (searchText) => {
    if (!searchText) {
      setOptions([]);
      return;
    }
    let result = await FetchApi.getUserApp({
      page: 1,
      size: 50,
      search: searchText,
    });
    if (!Array.isArray(result?.data) || result?.data?.length < 1) {
      setOptions([]);
      return;
    }
    setOptions(searchResult(result.data) || []);
  };

  return (
    <Col className="checkin-time-wrapper">
      <Col style={{ margin: "10px 0px", color: COLORS.accent }}>
        {STRINGS.search_name_furi_phone}
      </Col>
      <AutoComplete
        open={options?.length > 0}
        options={options}
        onSelect={onSelect}>
        <Input
          style={{ width: 250 }}
          onPressEnter={(e, f) => {
            onSelect(e, f);
            onSearch(e.target.value);
          }}
        />
      </AutoComplete>

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom isCancelButton onClick={ModalCustomService.close}>
          {STRINGS.cancel}
        </ButtonCustom>
      </Row>
    </Col>
  );
}

export default ModalSearchBooking;
