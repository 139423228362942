import React from "react";
import Quill from "../../../elements/Quill";
import { Form } from "antd";
import { Validate } from "../../../elements";
import { STRINGS } from "../../../constants";

function Detail({ data }) {
  return (
    <Form.Item
      initialValue={data?.content}
      className="form-action-quill"
      label={STRINGS.description}
      name="content"
      rules={[Validate.validateEditor]}
      required>
      <Quill className="quill-slide" />
    </Form.Item>
  );
}

export default Detail;
