import { Col, message } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { FetchApi } from "../../../../api";
import { STRINGS } from "../../../../constants";
import { LoadingAbsolute, ModalCustomService } from "../../../../elements";
import { CommonFunction } from "../../../../util/CommonFunction";
import BodyTable from "./BodyTable";
import BodyTableCancelFee from "./BodyTableCancelFee";
import BodyTop from "./BodyTop";
import Header from "./Header";

function StaffConfigModal({
  data,
  shiftStatus,
  refetchCalendarOrder,
  disabled,
  selectedDate,
  lastTime,
}) {
  const [staffInfo] = useState(data || {});
  const [scheduleSelected, setScheduleSelected] = useState(
    data.workShiftDTOS?.[0]?.scheduleId
  );
  const [loading, setLoading] = useState(false);
  const { data: dataStaffResting, isLoading } = useQuery(
    [`check-staff-rest`],
    () => FetchApi.staffResting()
  );

  const onCheckout = async () => {
    try {
      setLoading(true);
      const result = await FetchApi.timekeepingStaff({
        type: "check_out",
        staffId: staffInfo.staffId,
      });
      setLoading(false);
      if (result.success) {
        refetchCalendarOrder();
        message.success(STRINGS.checkout_success);
        ModalCustomService.close();
        return;
      }
      if (result.code == 4101 || result.code == 4102) {
        message.warning(result.message);
        return;
      }
      if (result.code == 4004) {
        message.error(STRINGS.cannot_checkout_because_error);
        return;
      }
      if (result.code == 4005) {
        message.error(STRINGS.cannot_rest_because_booking);
        return;
      }
      if (result.code == 4006) {
        message.error(STRINGS.cannot_checkout_because_resting);
        return;
      }
      if (result.code === 3001) {
        message.error(STRINGS.cannot_checkout_because_error);
        return;
      }
      CommonFunction.logError(STRINGS.something_wrong);
    } catch (error) {
      message.error(STRINGS.something_wrong);
      setLoading(false);
    }
  };

  return (
    <Col>
      <Header staffInfo={staffInfo} />
      <Col className="staff-schedule-modal-staff-detail">
        <BodyTop
          shiftStatus={shiftStatus}
          staffInfo={staffInfo}
          scheduleSelected={scheduleSelected}
          setScheduleSelected={setScheduleSelected}
          refetchCalendarOrder={refetchCalendarOrder}
          disabled={disabled}
          dataStaffResting={dataStaffResting}
          selectedDate={selectedDate}
        />
        <BodyTable
          staffInfo={staffInfo}
          shiftStatus={shiftStatus}
          onCheckout={onCheckout}
          lastTime={lastTime}
        />
        <BodyTableCancelFee
          staffInfo={staffInfo}
          shiftStatus={shiftStatus}
          onCheckout={onCheckout}
        />
      </Col>
      {loading || (isLoading && <LoadingAbsolute />)}
    </Col>
  );
}

export default StaffConfigModal;
