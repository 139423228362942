/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Row, Select } from "antd";

import { COLORS, STRINGS } from "../../../constants";
import {
  Convert,
  LoadingView,
  SuffixIconDown,
  Validate,
} from "../../../elements";
import { useGetAllCourseOfCoupon } from "../modules/useGetCourseSysAdCoupon";

function SelectComboApply({
  value,
  onChange,
  data,
  applyAllCombo,
  setApplyAllCombo,
}) {
  useEffect(() => {
    if (applyAllCombo) {
      onChange(Convert.utilData(data?.comboDTOS, "id"));
    }
  }, [applyAllCombo]);

  useEffect(() => {
    if (value?.length < data?.comboDTOS?.length) {
      setApplyAllCombo(false);
    } else {
      setApplyAllCombo(true);
    }
  }, [value]);

  return (
    <Select
      style={{
        textAlign: "left",
        width: "fit-content",
        minWidth: "100%",
        whiteSpace: "pre-line",
        borderRadius: 5,
        color: COLORS.sub_accent,
        fontWeight: "900",
      }}
      placeholder={STRINGS.please_choose_combo}
      allowClear
      mode="multiple"
      value={value}
      suffixIcon={<SuffixIconDown />}
      onChange={onChange}>
      {data?.comboDTOS?.map?.((item) => {
        return (
          <Select.Option
            key={item.id}
            value={item.id}
            style={{
              height: 60,
              color: COLORS.sub_accent,
              fontWeight: "bold",
            }}>
            <Row style={{ flexWrap: "wrap", whiteSpace: "pre-line" }}>
              {item.name}
            </Row>
          </Select.Option>
        );
      })}
    </Select>
  );
}

export function CouponApplyCombo({ form, couponId }) {
  const { data, isLoading } = useGetAllCourseOfCoupon({ couponId });
  const [applyAllCombo, setApplyAllCombo] = useState(
    form.getFieldValue("applyAllCourse")
  );
  useEffect(() => {
    form.setFieldsValue({ applyAllCourse: applyAllCombo });
  }, [applyAllCombo]);
  const renderSelect = () => {
    if (applyAllCombo) {
      return (
        <div
          style={{
            width: "18px",
            height: "18px",
            borderRadius: "9px",
            backgroundColor: COLORS.success,
          }}
        />
      );
    }
  };

  if (isLoading) {
    return <LoadingView />;
  }
  return (
    <Form.Item
      label={
        <Row>
          {STRINGS.course_has_applied}
          <div style={{ color: COLORS.required_2 }}>*</div>
          <Row
            onClick={() => {
              if (applyAllCombo) {
                form.setFieldsValue({ courseIdApply: [] });
              }
              setApplyAllCombo(!applyAllCombo);
            }}
            style={{ marginLeft: 50, paddingRight: 20, cursor: "pointer" }}>
            <div
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "12px",
                border: `1px solid ${COLORS.border}`,
                backgroundColor: COLORS.background,
                display: "flex",
                placeContent: "center",
                alignItems: "center",
                marginRight: "10px",
              }}>
              {renderSelect()}
            </div>
            {STRINGS.apply_all_course}
          </Row>
        </Row>
      }
      name="courseIdApply"
      rules={[Validate.emptyContent]}>
      <SelectComboApply
        data={data}
        applyAllCombo={applyAllCombo}
        setApplyAllCombo={setApplyAllCombo}
      />
    </Form.Item>
  );
}
