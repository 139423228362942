/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Row } from "antd";

import { COLORS, STRINGS } from "../../../constants";

export function CouponFormShowToUser({ value, onChange }) {
  const couponShare = [
    {
      title: STRINGS.coupon_show,
      type: true,
    },
    {
      title: STRINGS.coupon_hide,
      type: false,
    },
  ];
  const renderSelect = (item) => {
    if (item.type === value) {
      return (
        <div
          style={{
            width: "18px",
            height: "18px",
            borderRadius: "9px",
            backgroundColor: COLORS.success,
          }}
        />
      );
    }
  };
  return (
    <Row>
      {couponShare.map((i) => {
        return (
          <Row
            key={`${i.title}`}
            onClick={() => {
              onChange(i.type);
            }}
            style={{ paddingRight: 20, cursor: "pointer", marginTop: 10 }}>
            <div
              style={{
                width: 24,
                height: 24,
                borderRadius: 12,
                border: `1px solid ${COLORS.border}`,
                backgroundColor: COLORS.background,
                display: "flex",
                placeContent: "center",
                alignItems: "center",
                marginRight: 10,
              }}>
              {renderSelect(i)}
            </div>
            {i.title}
          </Row>
        );
      })}
    </Row>
  );
}
