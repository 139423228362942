import { Col, message, Row } from "antd";
import React, { useRef } from "react";
import { FetchApi } from "../../../api";
import { STRINGS } from "../../../constants";
import { ButtonCustom, ModalCustomService } from "../../../elements";
import RadioAllStoreStaff from "../../../elements/RadioAllStoreStaff";
import { CommonFunction } from "../../../util/CommonFunction";

export const Delete = ({ refetch, item }) => {
  const staffChangeRequest = useRef({});

  const onConfirm = async () => {
    if (!staffChangeRequest.current.id) {
      message.warning(STRINGS.please_select_a_reception_staff);
      return;
    }
    if (!staffChangeRequest.current.password) {
      message.warning(STRINGS.please_type_password);
      return;
    }
    let result = await FetchApi.deleteNG({
      id: item.id,
      staffChangeRequest: staffChangeRequest.current,
    });
    if (result.success) {
      ModalCustomService.close();
      refetch();
      message.success(STRINGS.deleted_common);
    } else if (result.code == 4003) {
      message.warning(STRINGS.password_is_incorrect);
    } else {
      CommonFunction.logError(result);
    }
  };

  return (
    <Col className="checkin-time-wrapper">
      <Col style={{ padding: 10, width: 500 }}>
        <Row
          style={{
            fontSize: 18,
            paddingTop: 10,
            color: "#4B2A05",
            fontWeight: "bold",
            justifyContent: "center",
          }}>
          {STRINGS.please_select_a_reception_staff}
        </Row>

        <RadioAllStoreStaff
          onChange={(e) => (staffChangeRequest.current = e)}
        />

        <Row justify="center" style={{ margin: "20px 0px" }}>
          <ButtonCustom
            onClick={ModalCustomService.close}
            isCancelButton
            style={{
              marginRight: 10,
            }}>
            {STRINGS.cancel}
          </ButtonCustom>
          <ButtonCustom onClick={onConfirm}>{STRINGS.sure}</ButtonCustom>
        </Row>
      </Col>
    </Col>
  );
};
