import React from "react";
import { Col, Row } from "antd";

import { COLORS, LOGIC, STRINGS } from "../../../constants";

function ModalBookingDetailPayment({ total }) {
  let colorText = COLORS.primary;
  return (
    <Col>
      <Row
        style={{
          justifyContent: "flex-end",
          fontSize: 40,
          fontWeight: "bold",
          color: COLORS.sub_accent,
        }}>
        <span>{STRINGS.total + ":\xa0\xa0"}</span>
        <span
          style={{
            color: colorText,
          }}>
          {LOGIC.currencyYenFormatter(total)}
        </span>
      </Row>
    </Col>
  );
}
export { ModalBookingDetailPayment };
