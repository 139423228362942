const City = [
  {
    id: 40,
    name1: "福岡県",
    name2: "フクオカケン",
  },
  {
    id: 2,
    name1: "青森県",
    name2: "アオモリケン",
  },
  {
    id: 3,
    name1: "岩手県",
    name2: "イワテケン",
  },
  {
    id: 4,
    name1: "宮城県",
    name2: "ミヤギケン",
  },
  {
    id: 5,
    name1: "秋田県",
    name2: "アキタケン",
  },
  {
    id: 6,
    name1: "山形県",
    name2: "ヤマガタケン",
  },
  {
    id: 7,
    name1: "福島県",
    name2: "フクシマケン",
  },
  {
    id: 8,
    name1: "茨城県",
    name2: "イバラキケン",
  },
  {
    id: 9,
    name1: "栃木県",
    name2: "トチギケン",
  },
  {
    id: 10,
    name1: "群馬県",
    name2: "グンマケン",
  },
  {
    id: 11,
    name1: "埼玉県",
    name2: "サイタマケン",
  },
  {
    id: 12,
    name1: "千葉県",
    name2: "チバケン",
  },
  {
    id: 13,
    name1: "東京都",
    name2: "トウキョウト",
  },
  {
    id: 14,
    name1: "神奈川県",
    name2: "カナガワケン",
  },
  {
    id: 15,
    name1: "新潟県",
    name2: "ニイガタケン",
  },
  {
    id: 16,
    name1: "富山県",
    name2: "トヤマケン",
  },
  {
    id: 17,
    name1: "石川県",
    name2: "イシカワケン",
  },
  {
    id: 18,
    name1: "福井県",
    name2: "フクイケン",
  },
  {
    id: 19,
    name1: "山梨県",
    name2: "ヤマナシケン",
  },
  {
    id: 20,
    name1: "長野県",
    name2: "ナガノケン",
  },
  {
    id: 21,
    name1: "岐阜県",
    name2: "ギフケン",
  },
  {
    id: 22,
    name1: "静岡県",
    name2: "シズオカケン",
  },
  {
    id: 23,
    name1: "愛知県",
    name2: "アイチケン",
  },
  {
    id: 24,
    name1: "三重県",
    name2: "ミエケン",
  },
  {
    id: 25,
    name1: "滋賀県",
    name2: "シガケン",
  },
  {
    id: 26,
    name1: "京都府",
    name2: "キョウトフ",
  },
  {
    id: 27,
    name1: "大阪府",
    name2: "オオサカフ",
  },
  {
    id: 28,
    name1: "兵庫県",
    name2: "ヒョウゴケン",
  },
  {
    id: 29,
    name1: "奈良県",
    name2: "ナラケン",
  },
  {
    id: 30,
    name1: "和歌山県",
    name2: "ワカヤマケン",
  },
  {
    id: 31,
    name1: "鳥取県",
    name2: "トットリケン",
  },
  {
    id: 32,
    name1: "島根県",
    name2: "シマネケン",
  },
  {
    id: 33,
    name1: "岡山県",
    name2: "オカヤマケン",
  },
  {
    id: 34,
    name1: "広島県",
    name2: "ヒロシマケン",
  },
  {
    id: 35,
    name1: "山口県",
    name2: "ヤマグチケン",
  },
  {
    id: 36,
    name1: "徳島県",
    name2: "トクシマケン",
  },
  {
    id: 37,
    name1: "香川県",
    name2: "カガワケン",
  },
  {
    id: 38,
    name1: "愛媛県",
    name2: "エヒメケン",
  },
  {
    id: 39,
    name1: "高知県",
    name2: "コウチケン",
  },

  {
    id: 41,
    name1: "佐賀県",
    name2: "サガケン",
  },
  {
    id: 42,
    name1: "長崎県",
    name2: "ナガサキケン",
  },
  {
    id: 43,
    name1: "熊本県",
    name2: "クマモトケン",
  },
  {
    id: 44,
    name1: "大分県",
    name2: "オオイタケン",
  },
  {
    id: 45,
    name1: "宮崎県",
    name2: "ミヤザキケン",
  },
  {
    id: 46,
    name1: "鹿児島県",
    name2: "カゴシマケン",
  },
  {
    id: 47,
    name1: "沖縄県",
    name2: "オキナワケン",
  },
];
const Province = [
  {
    id: 40131,
    name1: "福岡市東区",
    name2: "フクオカシヒガシク",
    cityId: 40,
  },

  {
    id: 40132,
    name1: "福岡市博多区",
    name2: "フクオカシハカタク",
    cityId: 40,
  },
  {
    id: 40133,
    name1: "福岡市中央区",
    name2: "フクオカシチュウオウク",
    cityId: 40,
  },
  {
    id: 40134,
    name1: "福岡市南区",
    name2: "フクオカシミナミク",
    cityId: 40,
  },
  {
    id: 40135,
    name1: "福岡市西区",
    name2: "フクオカシニシク",
    cityId: 40,
  },
  {
    id: 40136,
    name1: "福岡市城南区",
    name2: "フクオカシジョウナンク",
    cityId: 40,
  },
  {
    id: 40137,
    name1: "福岡市早良区",
    name2: "フクオカシサワラク",
    cityId: 40,
  },
  {
    id: 1102,
    name1: "札幌市北区",
    name2: "サッポロシキタク",
    cityId: 1,
  },
  {
    id: 1103,
    name1: "札幌市東区",
    name2: "サッポロシヒガシク",
    cityId: 1,
  },
  {
    id: 1104,
    name1: "札幌市白石区",
    name2: "サッポロシシロイシク",
    cityId: 1,
  },
  {
    id: 1105,
    name1: "札幌市豊平区",
    name2: "サッポロシトヨヒラク",
    cityId: 1,
  },
  {
    id: 1106,
    name1: "札幌市南区",
    name2: "サッポロシミナミク",
    cityId: 1,
  },
  {
    id: 1107,
    name1: "札幌市西区",
    name2: "サッポロシニシク",
    cityId: 1,
  },
  {
    id: 1108,
    name1: "札幌市厚別区",
    name2: "サッポロシアツベツク",
    cityId: 1,
  },
  {
    id: 1109,
    name1: "札幌市手稲区",
    name2: "サッポロシテイネク",
    cityId: 1,
  },
  {
    id: 1110,
    name1: "札幌市清田区",
    name2: "サッポロシキヨタク",
    cityId: 1,
  },
  {
    id: 1202,
    name1: "函館市",
    name2: "ハコダテシ",
    cityId: 1,
  },
  {
    id: 1203,
    name1: "小樽市",
    name2: "オタルシ",
    cityId: 1,
  },
  {
    id: 1204,
    name1: "旭川市",
    name2: "アサヒカワシ",
    cityId: 1,
  },
  {
    id: 1205,
    name1: "室蘭市",
    name2: "ムロランシ",
    cityId: 1,
  },
  {
    id: 1206,
    name1: "釧路市",
    name2: "クシロシ",
    cityId: 1,
  },
  {
    id: 1207,
    name1: "帯広市",
    name2: "オビヒロシ",
    cityId: 1,
  },
  {
    id: 1208,
    name1: "北見市",
    name2: "キタミシ",
    cityId: 1,
  },
  {
    id: 1209,
    name1: "夕張市",
    name2: "ユウバリシ",
    cityId: 1,
  },
  {
    id: 1210,
    name1: "岩見沢市",
    name2: "イワミザワシ",
    cityId: 1,
  },
  {
    id: 1211,
    name1: "網走市",
    name2: "アバシリシ",
    cityId: 1,
  },
  {
    id: 1212,
    name1: "留萌市",
    name2: "ルモイシ",
    cityId: 1,
  },
  {
    id: 1213,
    name1: "苫小牧市",
    name2: "トマコマイシ",
    cityId: 1,
  },
  {
    id: 1214,
    name1: "稚内市",
    name2: "ワッカナイシ",
    cityId: 1,
  },
  {
    id: 1215,
    name1: "美唄市",
    name2: "ビバイシ",
    cityId: 1,
  },
  {
    id: 1216,
    name1: "芦別市",
    name2: "アシベツシ",
    cityId: 1,
  },
  {
    id: 1217,
    name1: "江別市",
    name2: "エベツシ",
    cityId: 1,
  },
  {
    id: 1218,
    name1: "赤平市",
    name2: "アカビラシ",
    cityId: 1,
  },
  {
    id: 1219,
    name1: "紋別市",
    name2: "モンベツシ",
    cityId: 1,
  },
  {
    id: 1220,
    name1: "士別市",
    name2: "シベツシ",
    cityId: 1,
  },
  {
    id: 1221,
    name1: "名寄市",
    name2: "ナヨロシ",
    cityId: 1,
  },
  {
    id: 1222,
    name1: "三笠市",
    name2: "ミカサシ",
    cityId: 1,
  },
  {
    id: 1223,
    name1: "根室市",
    name2: "ネムロシ",
    cityId: 1,
  },
  {
    id: 1224,
    name1: "千歳市",
    name2: "チトセシ",
    cityId: 1,
  },
  {
    id: 1225,
    name1: "滝川市",
    name2: "タキカワシ",
    cityId: 1,
  },
  {
    id: 1226,
    name1: "砂川市",
    name2: "スナガワシ",
    cityId: 1,
  },
  {
    id: 1227,
    name1: "歌志内市",
    name2: "ウタシナイシ",
    cityId: 1,
  },
  {
    id: 1228,
    name1: "深川市",
    name2: "フカガワシ",
    cityId: 1,
  },
  {
    id: 1229,
    name1: "富良野市",
    name2: "フラノシ",
    cityId: 1,
  },
  {
    id: 1230,
    name1: "登別市",
    name2: "ノボリベツシ",
    cityId: 1,
  },
  {
    id: 1231,
    name1: "恵庭市",
    name2: "エニワシ",
    cityId: 1,
  },
  {
    id: 1233,
    name1: "伊達市",
    name2: "ダテシ",
    cityId: 1,
  },
  {
    id: 1234,
    name1: "北広島市",
    name2: "キタヒロシマシ",
    cityId: 1,
  },
  {
    id: 1235,
    name1: "石狩市",
    name2: "イシカリシ",
    cityId: 1,
  },
  {
    id: 1236,
    name1: "北斗市",
    name2: "ホクトシ",
    cityId: 1,
  },
  {
    id: 1303,
    name1: "石狩郡当別町",
    name2: "イシカリグントウベツチョウ",
    cityId: 1,
  },
  {
    id: 1304,
    name1: "石狩郡新篠津村",
    name2: "イシカリグンシンシノツムラ",
    cityId: 1,
  },
  {
    id: 1331,
    name1: "松前郡松前町",
    name2: "マツマエグンマツマエチョウ",
    cityId: 1,
  },
  {
    id: 1332,
    name1: "松前郡福島町",
    name2: "マツマエグンフクシマチョウ",
    cityId: 1,
  },
  {
    id: 1333,
    name1: "上磯郡知内町",
    name2: "カミイソグンシリウチチョウ",
    cityId: 1,
  },
  {
    id: 1334,
    name1: "上磯郡木古内町",
    name2: "カミイソグンキコナイチョウ",
    cityId: 1,
  },
  {
    id: 1337,
    name1: "亀田郡七飯町",
    name2: "カメダグンナナエチョウ",
    cityId: 1,
  },
  {
    id: 1343,
    name1: "茅部郡鹿部町",
    name2: "カヤベグンシカベチョウ",
    cityId: 1,
  },
  {
    id: 1345,
    name1: "茅部郡森町",
    name2: "カヤベグンモリマチ",
    cityId: 1,
  },
  {
    id: 1346,
    name1: "二海郡八雲町",
    name2: "フタミグンヤクモチョウ",
    cityId: 1,
  },
  {
    id: 1347,
    name1: "山越郡長万部町",
    name2: "ヤマコシグンオシャマンベチョウ",
    cityId: 1,
  },
  {
    id: 1361,
    name1: "檜山郡江差町",
    name2: "ヒヤマグンエサシチョウ",
    cityId: 1,
  },
  {
    id: 1362,
    name1: "檜山郡上ノ国町",
    name2: "ヒヤマグンカミノクニチョウ",
    cityId: 1,
  },
  {
    id: 1363,
    name1: "檜山郡厚沢部町",
    name2: "ヒヤマグンアッサブチョウ",
    cityId: 1,
  },
  {
    id: 1364,
    name1: "爾志郡乙部町",
    name2: "ニシグンオトベチョウ",
    cityId: 1,
  },
  {
    id: 1367,
    name1: "奥尻郡奥尻町",
    name2: "オクシリグンオクシリチョウ",
    cityId: 1,
  },
  {
    id: 1370,
    name1: "瀬棚郡今金町",
    name2: "セタナグンイマカネチョウ",
    cityId: 1,
  },
  {
    id: 1371,
    name1: "久遠郡せたな町",
    name2: "クドウグンセタナチョウ",
    cityId: 1,
  },
  {
    id: 1391,
    name1: "島牧郡島牧村",
    name2: "シママキグンシママキムラ",
    cityId: 1,
  },
  {
    id: 1392,
    name1: "寿都郡寿都町",
    name2: "スッツグンスッツチョウ",
    cityId: 1,
  },
  {
    id: 1393,
    name1: "寿都郡黒松内町",
    name2: "スッツグンクロマツナイチョウ",
    cityId: 1,
  },
  {
    id: 1394,
    name1: "磯谷郡蘭越町",
    name2: "イソヤグンランコシチョウ",
    cityId: 1,
  },
  {
    id: 1395,
    name1: "虻田郡ニセコ町",
    name2: "アブタグンニセコチョウ",
    cityId: 1,
  },
  {
    id: 1396,
    name1: "虻田郡真狩村",
    name2: "アブタグンマッカリムラ",
    cityId: 1,
  },
  {
    id: 1397,
    name1: "虻田郡留寿都村",
    name2: "アブタグンルスツムラ",
    cityId: 1,
  },
  {
    id: 1398,
    name1: "虻田郡喜茂別町",
    name2: "アブタグンキモベツチョウ",
    cityId: 1,
  },
  {
    id: 1399,
    name1: "虻田郡京極町",
    name2: "アブタグンキョウゴクチョウ",
    cityId: 1,
  },
  {
    id: 1400,
    name1: "虻田郡倶知安町",
    name2: "アブタグンクッチャンチョウ",
    cityId: 1,
  },
  {
    id: 1401,
    name1: "岩内郡共和町",
    name2: "イワナイグンキョウワチョウ",
    cityId: 1,
  },
  {
    id: 1402,
    name1: "岩内郡岩内町",
    name2: "イワナイグンイワナイチョウ",
    cityId: 1,
  },
  {
    id: 1403,
    name1: "古宇郡泊村",
    name2: "フルウグントマリムラ",
    cityId: 1,
  },
  {
    id: 1404,
    name1: "古宇郡神恵内村",
    name2: "フルウグンカモエナイムラ",
    cityId: 1,
  },
  {
    id: 1405,
    name1: "積丹郡積丹町",
    name2: "シャコタングンシャコタンチョウ",
    cityId: 1,
  },
  {
    id: 1406,
    name1: "古平郡古平町",
    name2: "フルビラグンフルビラチョウ",
    cityId: 1,
  },
  {
    id: 1407,
    name1: "余市郡仁木町",
    name2: "ヨイチグンニキチョウ",
    cityId: 1,
  },
  {
    id: 1408,
    name1: "余市郡余市町",
    name2: "ヨイチグンヨイチチョウ",
    cityId: 1,
  },
  {
    id: 1409,
    name1: "余市郡赤井川村",
    name2: "ヨイチグンアカイガワムラ",
    cityId: 1,
  },
  {
    id: 1423,
    name1: "空知郡南幌町",
    name2: "ソラチグンナンポロチョウ",
    cityId: 1,
  },
  {
    id: 1424,
    name1: "空知郡奈井江町",
    name2: "ソラチグンナイエチョウ",
    cityId: 1,
  },
  {
    id: 1425,
    name1: "空知郡上砂川町",
    name2: "ソラチグンカミスナガワチョウ",
    cityId: 1,
  },
  {
    id: 1427,
    name1: "夕張郡由仁町",
    name2: "ユウバリグンユニチョウ",
    cityId: 1,
  },
  {
    id: 1428,
    name1: "夕張郡長沼町",
    name2: "ユウバリグンナガヌマチョウ",
    cityId: 1,
  },
  {
    id: 1429,
    name1: "夕張郡栗山町",
    name2: "ユウバリグンクリヤマチョウ",
    cityId: 1,
  },
  {
    id: 1430,
    name1: "樺戸郡月形町",
    name2: "カバトグンツキガタチョウ",
    cityId: 1,
  },
  {
    id: 1431,
    name1: "樺戸郡浦臼町",
    name2: "カバトグンウラウスチョウ",
    cityId: 1,
  },
  {
    id: 1432,
    name1: "樺戸郡新十津川町",
    name2: "カバトグンシントツカワチョウ",
    cityId: 1,
  },
  {
    id: 1433,
    name1: "雨竜郡妹背牛町",
    name2: "ウリュウグンモセウシチョウ",
    cityId: 1,
  },
  {
    id: 1434,
    name1: "雨竜郡秩父別町",
    name2: "ウリュウグンチップベツチョウ",
    cityId: 1,
  },
  {
    id: 1436,
    name1: "雨竜郡雨竜町",
    name2: "ウリュウグンウリュウチョウ",
    cityId: 1,
  },
  {
    id: 1437,
    name1: "雨竜郡北竜町",
    name2: "ウリュウグンホクリュウチョウ",
    cityId: 1,
  },
  {
    id: 1438,
    name1: "雨竜郡沼田町",
    name2: "ウリュウグンヌマタチョウ",
    cityId: 1,
  },
  {
    id: 1452,
    name1: "上川郡鷹栖町",
    name2: "カミカワグンタカスチョウ",
    cityId: 1,
  },
  {
    id: 1453,
    name1: "上川郡東神楽町",
    name2: "カミカワグンヒガシカグラチョウ",
    cityId: 1,
  },
  {
    id: 1454,
    name1: "上川郡当麻町",
    name2: "カミカワグントウマチョウ",
    cityId: 1,
  },
  {
    id: 1455,
    name1: "上川郡比布町",
    name2: "カミカワグンピップチョウ",
    cityId: 1,
  },
  {
    id: 1456,
    name1: "上川郡愛別町",
    name2: "カミカワグンアイベツチョウ",
    cityId: 1,
  },
  {
    id: 1457,
    name1: "上川郡上川町",
    name2: "カミカワグンカミカワチョウ",
    cityId: 1,
  },
  {
    id: 1458,
    name1: "上川郡東川町",
    name2: "カミカワグンヒガシカワチョウ",
    cityId: 1,
  },
  {
    id: 1459,
    name1: "上川郡美瑛町",
    name2: "カミカワグンビエイチョウ",
    cityId: 1,
  },
  {
    id: 1460,
    name1: "空知郡上富良野町",
    name2: "ソラチグンカミフラノチョウ",
    cityId: 1,
  },
  {
    id: 1461,
    name1: "空知郡中富良野町",
    name2: "ソラチグンナカフラノチョウ",
    cityId: 1,
  },
  {
    id: 1462,
    name1: "空知郡南富良野町",
    name2: "ソラチグンミナミフラノチョウ",
    cityId: 1,
  },
  {
    id: 1463,
    name1: "勇払郡占冠村",
    name2: "ユウフツグンシムカップムラ",
    cityId: 1,
  },
  {
    id: 1464,
    name1: "上川郡和寒町",
    name2: "カミカワグンワッサムチョウ",
    cityId: 1,
  },
  {
    id: 1465,
    name1: "上川郡剣淵町",
    name2: "カミカワグンケンブチチョウ",
    cityId: 1,
  },
  {
    id: 1468,
    name1: "上川郡下川町",
    name2: "カミカワグンシモカワチョウ",
    cityId: 1,
  },
  {
    id: 1469,
    name1: "中川郡美深町",
    name2: "ナカガワグンビフカチョウ",
    cityId: 1,
  },
  {
    id: 1470,
    name1: "中川郡音威子府村",
    name2: "ナカガワグンオトイネップムラ",
    cityId: 1,
  },
  {
    id: 1471,
    name1: "中川郡中川町",
    name2: "ナカガワグンナカガワチョウ",
    cityId: 1,
  },
  {
    id: 1472,
    name1: "雨竜郡幌加内町",
    name2: "ウリュウグンホロカナイチョウ",
    cityId: 1,
  },
  {
    id: 1481,
    name1: "増毛郡増毛町",
    name2: "マシケグンマシケチョウ",
    cityId: 1,
  },
  {
    id: 1482,
    name1: "留萌郡小平町",
    name2: "ルモイグンオビラチョウ",
    cityId: 1,
  },
  {
    id: 1483,
    name1: "苫前郡苫前町",
    name2: "トママエグントママエチョウ",
    cityId: 1,
  },
  {
    id: 1484,
    name1: "苫前郡羽幌町",
    name2: "トママエグンハボロチョウ",
    cityId: 1,
  },
  {
    id: 1485,
    name1: "苫前郡初山別村",
    name2: "トママエグンショサンベツムラ",
    cityId: 1,
  },
  {
    id: 1486,
    name1: "天塩郡遠別町",
    name2: "テシオグンエンベツチョウ",
    cityId: 1,
  },
  {
    id: 1487,
    name1: "天塩郡天塩町",
    name2: "テシオグンテシオチョウ",
    cityId: 1,
  },
  {
    id: 1511,
    name1: "宗谷郡猿払村",
    name2: "ソウヤグンサルフツムラ",
    cityId: 1,
  },
  {
    id: 1512,
    name1: "枝幸郡浜頓別町",
    name2: "エサシグンハマトンベツチョウ",
    cityId: 1,
  },
  {
    id: 1513,
    name1: "枝幸郡中頓別町",
    name2: "エサシグンナカトンベツチョウ",
    cityId: 1,
  },
  {
    id: 1514,
    name1: "枝幸郡枝幸町",
    name2: "エサシグンエサシチョウ",
    cityId: 1,
  },
  {
    id: 1516,
    name1: "天塩郡豊富町",
    name2: "テシオグントヨトミチョウ",
    cityId: 1,
  },
  {
    id: 1517,
    name1: "礼文郡礼文町",
    name2: "レブングンレブンチョウ",
    cityId: 1,
  },
  {
    id: 1518,
    name1: "利尻郡利尻町",
    name2: "リシリグンリシリチョウ",
    cityId: 1,
  },
  {
    id: 1519,
    name1: "利尻郡利尻富士町",
    name2: "リシリグンリシリフジチョウ",
    cityId: 1,
  },
  {
    id: 1520,
    name1: "天塩郡幌延町",
    name2: "テシオグンホロノベチョウ",
    cityId: 1,
  },
  {
    id: 1543,
    name1: "網走郡美幌町",
    name2: "アバシリグンビホロチョウ",
    cityId: 1,
  },
  {
    id: 1544,
    name1: "網走郡津別町",
    name2: "アバシリグンツベツチョウ",
    cityId: 1,
  },
  {
    id: 1545,
    name1: "斜里郡斜里町",
    name2: "シャリグンシャリチョウ",
    cityId: 1,
  },
  {
    id: 1546,
    name1: "斜里郡清里町",
    name2: "シャリグンキヨサトチョウ",
    cityId: 1,
  },
  {
    id: 1547,
    name1: "斜里郡小清水町",
    name2: "シャリグンコシミズチョウ",
    cityId: 1,
  },
  {
    id: 1549,
    name1: "常呂郡訓子府町",
    name2: "トコログンクンネップチョウ",
    cityId: 1,
  },
  {
    id: 1550,
    name1: "常呂郡置戸町",
    name2: "トコログンオケトチョウ",
    cityId: 1,
  },
  {
    id: 1552,
    name1: "常呂郡佐呂間町",
    name2: "トコログンサロマチョウ",
    cityId: 1,
  },
  {
    id: 1555,
    name1: "紋別郡遠軽町",
    name2: "モンベツグンエンガルチョウ",
    cityId: 1,
  },
  {
    id: 1559,
    name1: "紋別郡湧別町",
    name2: "モンベツグンユウベツチョウ",
    cityId: 1,
  },
  {
    id: 1560,
    name1: "紋別郡滝上町",
    name2: "モンベツグンタキノウエチョウ",
    cityId: 1,
  },
  {
    id: 1561,
    name1: "紋別郡興部町",
    name2: "モンベツグンオコッペチョウ",
    cityId: 1,
  },
  {
    id: 1562,
    name1: "紋別郡西興部村",
    name2: "モンベツグンニシオコッペムラ",
    cityId: 1,
  },
  {
    id: 1563,
    name1: "紋別郡雄武町",
    name2: "モンベツグンオウムチョウ",
    cityId: 1,
  },
  {
    id: 1564,
    name1: "網走郡大空町",
    name2: "アバシリグンオオゾラチョウ",
    cityId: 1,
  },
  {
    id: 1571,
    name1: "虻田郡豊浦町",
    name2: "アブタグントヨウラチョウ",
    cityId: 1,
  },
  {
    id: 1575,
    name1: "有珠郡壮瞥町",
    name2: "ウスグンソウベツチョウ",
    cityId: 1,
  },
  {
    id: 1578,
    name1: "白老郡白老町",
    name2: "シラオイグンシラオイチョウ",
    cityId: 1,
  },
  {
    id: 1581,
    name1: "勇払郡厚真町",
    name2: "ユウフツグンアツマチョウ",
    cityId: 1,
  },
  {
    id: 1584,
    name1: "虻田郡洞爺湖町",
    name2: "アブタグントウヤコチョウ",
    cityId: 1,
  },
  {
    id: 1585,
    name1: "勇払郡安平町",
    name2: "ユウフツグンアビラチョウ",
    cityId: 1,
  },
  {
    id: 1586,
    name1: "勇払郡むかわ町",
    name2: "ユウフツグンムカワチョウ",
    cityId: 1,
  },
  {
    id: 1601,
    name1: "沙流郡日高町",
    name2: "サルグンヒダカチョウ",
    cityId: 1,
  },
  {
    id: 1602,
    name1: "沙流郡平取町",
    name2: "サルグンビラトリチョウ",
    cityId: 1,
  },
  {
    id: 1604,
    name1: "新冠郡新冠町",
    name2: "ニイカップグンニイカップチョウ",
    cityId: 1,
  },
  {
    id: 1607,
    name1: "浦河郡浦河町",
    name2: "ウラカワグンウラカワチョウ",
    cityId: 1,
  },
  {
    id: 1608,
    name1: "様似郡様似町",
    name2: "サマニグンサマニチョウ",
    cityId: 1,
  },
  {
    id: 1609,
    name1: "幌泉郡えりも町",
    name2: "ホロイズミグンエリモチョウ",
    cityId: 1,
  },
  {
    id: 1610,
    name1: "日高郡新ひだか町",
    name2: "ヒダカグンシンヒダカチョウ",
    cityId: 1,
  },
  {
    id: 1631,
    name1: "河東郡音更町",
    name2: "カトウグンオトフケチョウ",
    cityId: 1,
  },
  {
    id: 1632,
    name1: "河東郡士幌町",
    name2: "カトウグンシホロチョウ",
    cityId: 1,
  },
  {
    id: 1633,
    name1: "河東郡上士幌町",
    name2: "カトウグンカミシホロチョウ",
    cityId: 1,
  },
  {
    id: 1634,
    name1: "河東郡鹿追町",
    name2: "カトウグンシカオイチョウ",
    cityId: 1,
  },
  {
    id: 1635,
    name1: "上川郡新得町",
    name2: "カミカワグンシントクチョウ",
    cityId: 1,
  },
  {
    id: 1636,
    name1: "上川郡清水町",
    name2: "カミカワグンシミズチョウ",
    cityId: 1,
  },
  {
    id: 1637,
    name1: "河西郡芽室町",
    name2: "カサイグンメムロチョウ",
    cityId: 1,
  },
  {
    id: 1638,
    name1: "河西郡中札内村",
    name2: "カサイグンナカサツナイムラ",
    cityId: 1,
  },
  {
    id: 1639,
    name1: "河西郡更別村",
    name2: "カサイグンサラベツムラ",
    cityId: 1,
  },
  {
    id: 1641,
    name1: "広尾郡大樹町",
    name2: "ヒロオグンタイキチョウ",
    cityId: 1,
  },
  {
    id: 1642,
    name1: "広尾郡広尾町",
    name2: "ヒロオグンヒロオチョウ",
    cityId: 1,
  },
  {
    id: 1643,
    name1: "中川郡幕別町",
    name2: "ナカガワグンマクベツチョウ",
    cityId: 1,
  },
  {
    id: 1644,
    name1: "中川郡池田町",
    name2: "ナカガワグンイケダチョウ",
    cityId: 1,
  },
  {
    id: 1645,
    name1: "中川郡豊頃町",
    name2: "ナカガワグントヨコロチョウ",
    cityId: 1,
  },
  {
    id: 1646,
    name1: "中川郡本別町",
    name2: "ナカガワグンホンベツチョウ",
    cityId: 1,
  },
  {
    id: 1647,
    name1: "足寄郡足寄町",
    name2: "アショログンアショロチョウ",
    cityId: 1,
  },
  {
    id: 1648,
    name1: "足寄郡陸別町",
    name2: "アショログンリクベツチョウ",
    cityId: 1,
  },
  {
    id: 1649,
    name1: "十勝郡浦幌町",
    name2: "トカチグンウラホロチョウ",
    cityId: 1,
  },
  {
    id: 1661,
    name1: "釧路郡釧路町",
    name2: "クシログンクシロチョウ",
    cityId: 1,
  },
  {
    id: 1662,
    name1: "厚岸郡厚岸町",
    name2: "アッケシグンアッケシチョウ",
    cityId: 1,
  },
  {
    id: 1663,
    name1: "厚岸郡浜中町",
    name2: "アッケシグンハマナカチョウ",
    cityId: 1,
  },
  {
    id: 1664,
    name1: "川上郡標茶町",
    name2: "カワカミグンシベチャチョウ",
    cityId: 1,
  },
  {
    id: 1665,
    name1: "川上郡弟子屈町",
    name2: "カワカミグンテシカガチョウ",
    cityId: 1,
  },
  {
    id: 1667,
    name1: "阿寒郡鶴居村",
    name2: "アカングンツルイムラ",
    cityId: 1,
  },
  {
    id: 1668,
    name1: "白糠郡白糠町",
    name2: "シラヌカグンシラヌカチョウ",
    cityId: 1,
  },
  {
    id: 1691,
    name1: "野付郡別海町",
    name2: "ノツケグンベツカイチョウ",
    cityId: 1,
  },
  {
    id: 1692,
    name1: "標津郡中標津町",
    name2: "シベツグンナカシベツチョウ",
    cityId: 1,
  },
  {
    id: 1693,
    name1: "標津郡標津町",
    name2: "シベツグンシベツチョウ",
    cityId: 1,
  },
  {
    id: 1694,
    name1: "目梨郡羅臼町",
    name2: "メナシグンラウスチョウ",
    cityId: 1,
  },
  {
    id: 2201,
    name1: "青森市",
    name2: "アオモリシ",
    cityId: 2,
  },
  {
    id: 2202,
    name1: "弘前市",
    name2: "ヒロサキシ",
    cityId: 2,
  },
  {
    id: 2203,
    name1: "八戸市",
    name2: "ハチノヘシ",
    cityId: 2,
  },
  {
    id: 2204,
    name1: "黒石市",
    name2: "クロイシシ",
    cityId: 2,
  },
  {
    id: 2205,
    name1: "五所川原市",
    name2: "ゴショガワラシ",
    cityId: 2,
  },
  {
    id: 2206,
    name1: "十和田市",
    name2: "トワダシ",
    cityId: 2,
  },
  {
    id: 2207,
    name1: "三沢市",
    name2: "ミサワシ",
    cityId: 2,
  },
  {
    id: 2208,
    name1: "むつ市",
    name2: "ムツシ",
    cityId: 2,
  },
  {
    id: 2209,
    name1: "つがる市",
    name2: "ツガルシ",
    cityId: 2,
  },
  {
    id: 2210,
    name1: "平川市",
    name2: "ヒラカワシ",
    cityId: 2,
  },
  {
    id: 2301,
    name1: "東津軽郡平内町",
    name2: "ヒガシツガルグンヒラナイマチ",
    cityId: 2,
  },
  {
    id: 2303,
    name1: "東津軽郡今別町",
    name2: "ヒガシツガルグンイマベツマチ",
    cityId: 2,
  },
  {
    id: 2304,
    name1: "東津軽郡蓬田村",
    name2: "ヒガシツガルグンヨモギタムラ",
    cityId: 2,
  },
  {
    id: 2307,
    name1: "東津軽郡外ヶ浜町",
    name2: "ヒガシツガルグンソトガハママチ",
    cityId: 2,
  },
  {
    id: 2321,
    name1: "西津軽郡鰺ヶ沢町",
    name2: "ニシツガルグンアジガサワマチ",
    cityId: 2,
  },
  {
    id: 2323,
    name1: "西津軽郡深浦町",
    name2: "ニシツガルグンフカウラマチ",
    cityId: 2,
  },
  {
    id: 2343,
    name1: "中津軽郡西目屋村",
    name2: "ナカツガルグンニシメヤムラ",
    cityId: 2,
  },
  {
    id: 2361,
    name1: "南津軽郡藤崎町",
    name2: "ミナミツガルグンフジサキマチ",
    cityId: 2,
  },
  {
    id: 2362,
    name1: "南津軽郡大鰐町",
    name2: "ミナミツガルグンオオワニマチ",
    cityId: 2,
  },
  {
    id: 2367,
    name1: "南津軽郡田舎館村",
    name2: "ミナミツガルグンイナカダテムラ",
    cityId: 2,
  },
  {
    id: 2381,
    name1: "北津軽郡板柳町",
    name2: "キタツガルグンイタヤナギマチ",
    cityId: 2,
  },
  {
    id: 2384,
    name1: "北津軽郡鶴田町",
    name2: "キタツガルグンツルタマチ",
    cityId: 2,
  },
  {
    id: 2387,
    name1: "北津軽郡中泊町",
    name2: "キタツガルグンナカドマリマチ",
    cityId: 2,
  },
  {
    id: 2401,
    name1: "上北郡野辺地町",
    name2: "カミキタグンノヘジマチ",
    cityId: 2,
  },
  {
    id: 2402,
    name1: "上北郡七戸町",
    name2: "カミキタグンシチノヘマチ",
    cityId: 2,
  },
  {
    id: 2405,
    name1: "上北郡六戸町",
    name2: "カミキタグンロクノヘマチ",
    cityId: 2,
  },
  {
    id: 2406,
    name1: "上北郡横浜町",
    name2: "カミキタグンヨコハママチ",
    cityId: 2,
  },
  {
    id: 2408,
    name1: "上北郡東北町",
    name2: "カミキタグントウホクマチ",
    cityId: 2,
  },
  {
    id: 2411,
    name1: "上北郡六ヶ所村",
    name2: "カミキタグンロッカショムラ",
    cityId: 2,
  },
  {
    id: 2412,
    name1: "上北郡おいらせ町",
    name2: "カミキタグンオイラセチョウ",
    cityId: 2,
  },
  {
    id: 2423,
    name1: "下北郡大間町",
    name2: "シモキタグンオオママチ",
    cityId: 2,
  },
  {
    id: 2424,
    name1: "下北郡東通村",
    name2: "シモキタグンヒガシドオリムラ",
    cityId: 2,
  },
  {
    id: 2425,
    name1: "下北郡風間浦村",
    name2: "シモキタグンカザマウラムラ",
    cityId: 2,
  },
  {
    id: 2426,
    name1: "下北郡佐井村",
    name2: "シモキタグンサイムラ",
    cityId: 2,
  },
  {
    id: 2441,
    name1: "三戸郡三戸町",
    name2: "サンノヘグンサンノヘマチ",
    cityId: 2,
  },
  {
    id: 2442,
    name1: "三戸郡五戸町",
    name2: "サンノヘグンゴノヘマチ",
    cityId: 2,
  },
  {
    id: 2443,
    name1: "三戸郡田子町",
    name2: "サンノヘグンタッコマチ",
    cityId: 2,
  },
  {
    id: 2445,
    name1: "三戸郡南部町",
    name2: "サンノヘグンナンブチョウ",
    cityId: 2,
  },
  {
    id: 2446,
    name1: "三戸郡階上町",
    name2: "サンノヘグンハシカミチョウ",
    cityId: 2,
  },
  {
    id: 2450,
    name1: "三戸郡新郷村",
    name2: "サンノヘグンシンゴウムラ",
    cityId: 2,
  },
  {
    id: 3201,
    name1: "盛岡市",
    name2: "モリオカシ",
    cityId: 3,
  },
  {
    id: 3202,
    name1: "宮古市",
    name2: "ミヤコシ",
    cityId: 3,
  },
  {
    id: 3203,
    name1: "大船渡市",
    name2: "オオフナトシ",
    cityId: 3,
  },
  {
    id: 3205,
    name1: "花巻市",
    name2: "ハナマキシ",
    cityId: 3,
  },
  {
    id: 3206,
    name1: "北上市",
    name2: "キタカミシ",
    cityId: 3,
  },
  {
    id: 3207,
    name1: "久慈市",
    name2: "クジシ",
    cityId: 3,
  },
  {
    id: 3208,
    name1: "遠野市",
    name2: "トオノシ",
    cityId: 3,
  },
  {
    id: 3209,
    name1: "一関市",
    name2: "イチノセキシ",
    cityId: 3,
  },
  {
    id: 3210,
    name1: "陸前高田市",
    name2: "リクゼンタカタシ",
    cityId: 3,
  },
  {
    id: 3211,
    name1: "釜石市",
    name2: "カマイシシ",
    cityId: 3,
  },
  {
    id: 3213,
    name1: "二戸市",
    name2: "ニノヘシ",
    cityId: 3,
  },
  {
    id: 3214,
    name1: "八幡平市",
    name2: "ハチマンタイシ",
    cityId: 3,
  },
  {
    id: 3215,
    name1: "奥州市",
    name2: "オウシュウシ",
    cityId: 3,
  },
  {
    id: 3216,
    name1: "滝沢市",
    name2: "タキザワシ",
    cityId: 3,
  },
  {
    id: 3301,
    name1: "岩手郡雫石町",
    name2: "イワテグンシズクイシチョウ",
    cityId: 3,
  },
  {
    id: 3302,
    name1: "岩手郡葛巻町",
    name2: "イワテグンクズマキマチ",
    cityId: 3,
  },
  {
    id: 3303,
    name1: "岩手郡岩手町",
    name2: "イワテグンイワテマチ",
    cityId: 3,
  },
  {
    id: 3305,
    name1: "岩手郡滝沢村",
    name2: "イワテグンタキザワムラ",
    cityId: 3,
  },
  {
    id: 3321,
    name1: "紫波郡紫波町",
    name2: "シワグンシワチョウ",
    cityId: 3,
  },
  {
    id: 3322,
    name1: "紫波郡矢巾町",
    name2: "シワグンヤハバチョウ",
    cityId: 3,
  },
  {
    id: 3366,
    name1: "和賀郡西和賀町",
    name2: "ワガグンニシワガマチ",
    cityId: 3,
  },
  {
    id: 3381,
    name1: "胆沢郡金ケ崎町",
    name2: "イサワグンカネガサキチョウ",
    cityId: 3,
  },
  {
    id: 3402,
    name1: "西磐井郡平泉町",
    name2: "ニシイワイグンヒライズミチョウ",
    cityId: 3,
  },
  {
    id: 3422,
    name1: "東磐井郡藤沢町",
    name2: "ヒガシイワイグンフジサワチョウ",
    cityId: 3,
  },
  {
    id: 3441,
    name1: "気仙郡住田町",
    name2: "ケセングンスミタチョウ",
    cityId: 3,
  },
  {
    id: 3461,
    name1: "上閉伊郡大槌町",
    name2: "カミヘイグンオオツチチョウ",
    cityId: 3,
  },
  {
    id: 3482,
    name1: "下閉伊郡山田町",
    name2: "シモヘイグンヤマダマチ",
    cityId: 3,
  },
  {
    id: 3483,
    name1: "下閉伊郡岩泉町",
    name2: "シモヘイグンイワイズミチョウ",
    cityId: 3,
  },
  {
    id: 3484,
    name1: "下閉伊郡田野畑村",
    name2: "シモヘイグンタノハタムラ",
    cityId: 3,
  },
  {
    id: 3485,
    name1: "下閉伊郡普代村",
    name2: "シモヘイグンフダイムラ",
    cityId: 3,
  },
  {
    id: 3501,
    name1: "九戸郡軽米町",
    name2: "クノヘグンカルマイマチ",
    cityId: 3,
  },
  {
    id: 3503,
    name1: "九戸郡野田村",
    name2: "クノヘグンノダムラ",
    cityId: 3,
  },
  {
    id: 3506,
    name1: "九戸郡九戸村",
    name2: "クノヘグンクノヘムラ",
    cityId: 3,
  },
  {
    id: 3507,
    name1: "九戸郡洋野町",
    name2: "クノヘグンヒロノチョウ",
    cityId: 3,
  },
  {
    id: 3524,
    name1: "二戸郡一戸町",
    name2: "ニノヘグンイチノヘマチ",
    cityId: 3,
  },
  {
    id: 4101,
    name1: "仙台市青葉区",
    name2: "センダイシアオバク",
    cityId: 4,
  },
  {
    id: 4102,
    name1: "仙台市宮城野区",
    name2: "センダイシミヤギノク",
    cityId: 4,
  },
  {
    id: 4103,
    name1: "仙台市若林区",
    name2: "センダイシワカバヤシク",
    cityId: 4,
  },
  {
    id: 4104,
    name1: "仙台市太白区",
    name2: "センダイシタイハクク",
    cityId: 4,
  },
  {
    id: 4105,
    name1: "仙台市泉区",
    name2: "センダイシイズミク",
    cityId: 4,
  },
  {
    id: 4202,
    name1: "石巻市",
    name2: "イシノマキシ",
    cityId: 4,
  },
  {
    id: 4203,
    name1: "塩竈市",
    name2: "シオガマシ",
    cityId: 4,
  },
  {
    id: 4205,
    name1: "気仙沼市",
    name2: "ケセンヌマシ",
    cityId: 4,
  },
  {
    id: 4206,
    name1: "白石市",
    name2: "シロイシシ",
    cityId: 4,
  },
  {
    id: 4207,
    name1: "名取市",
    name2: "ナトリシ",
    cityId: 4,
  },
  {
    id: 4208,
    name1: "角田市",
    name2: "カクダシ",
    cityId: 4,
  },
  {
    id: 4209,
    name1: "多賀城市",
    name2: "タガジョウシ",
    cityId: 4,
  },
  {
    id: 4211,
    name1: "岩沼市",
    name2: "イワヌマシ",
    cityId: 4,
  },
  {
    id: 4212,
    name1: "登米市",
    name2: "トメシ",
    cityId: 4,
  },
  {
    id: 4213,
    name1: "栗原市",
    name2: "クリハラシ",
    cityId: 4,
  },
  {
    id: 4214,
    name1: "東松島市",
    name2: "ヒガシマツシマシ",
    cityId: 4,
  },
  {
    id: 4215,
    name1: "大崎市",
    name2: "オオサキシ",
    cityId: 4,
  },
  {
    id: 4216,
    name1: "富谷市",
    name2: "トミヤシ",
    cityId: 4,
  },
  {
    id: 4301,
    name1: "刈田郡蔵王町",
    name2: "カッタグンザオウマチ",
    cityId: 4,
  },
  {
    id: 4302,
    name1: "刈田郡七ヶ宿町",
    name2: "カッタグンシチカシュクマチ",
    cityId: 4,
  },
  {
    id: 4321,
    name1: "柴田郡大河原町",
    name2: "シバタグンオオガワラマチ",
    cityId: 4,
  },
  {
    id: 4322,
    name1: "柴田郡村田町",
    name2: "シバタグンムラタマチ",
    cityId: 4,
  },
  {
    id: 4323,
    name1: "柴田郡柴田町",
    name2: "シバタグンシバタマチ",
    cityId: 4,
  },
  {
    id: 4324,
    name1: "柴田郡川崎町",
    name2: "シバタグンカワサキマチ",
    cityId: 4,
  },
  {
    id: 4341,
    name1: "伊具郡丸森町",
    name2: "イググンマルモリマチ",
    cityId: 4,
  },
  {
    id: 4361,
    name1: "亘理郡亘理町",
    name2: "ワタリグンワタリチョウ",
    cityId: 4,
  },
  {
    id: 4362,
    name1: "亘理郡山元町",
    name2: "ワタリグンヤマモトチョウ",
    cityId: 4,
  },
  {
    id: 4401,
    name1: "宮城郡松島町",
    name2: "ミヤギグンマツシママチ",
    cityId: 4,
  },
  {
    id: 4404,
    name1: "宮城郡七ヶ浜町",
    name2: "ミヤギグンシチガハママチ",
    cityId: 4,
  },
  {
    id: 4406,
    name1: "宮城郡利府町",
    name2: "ミヤギグンリフチョウ",
    cityId: 4,
  },
  {
    id: 4421,
    name1: "黒川郡大和町",
    name2: "クロカワグンタイワチョウ",
    cityId: 4,
  },
  {
    id: 4422,
    name1: "黒川郡大郷町",
    name2: "クロカワグンオオサトチョウ",
    cityId: 4,
  },
  {
    id: 4424,
    name1: "黒川郡大衡村",
    name2: "クロカワグンオオヒラムラ",
    cityId: 4,
  },
  {
    id: 4444,
    name1: "加美郡色麻町",
    name2: "カミグンシカマチョウ",
    cityId: 4,
  },
  {
    id: 4445,
    name1: "加美郡加美町",
    name2: "カミグンカミマチ",
    cityId: 4,
  },
  {
    id: 4501,
    name1: "遠田郡涌谷町",
    name2: "トオダグンワクヤチョウ",
    cityId: 4,
  },
  {
    id: 4505,
    name1: "遠田郡美里町",
    name2: "トオダグンミサトマチ",
    cityId: 4,
  },
  {
    id: 4581,
    name1: "牡鹿郡女川町",
    name2: "オシカグンオナガワチョウ",
    cityId: 4,
  },
  {
    id: 4606,
    name1: "本吉郡南三陸町",
    name2: "モトヨシグンミナミサンリクチョウ",
    cityId: 4,
  },
  {
    id: 5201,
    name1: "秋田市",
    name2: "アキタシ",
    cityId: 5,
  },
  {
    id: 5202,
    name1: "能代市",
    name2: "ノシロシ",
    cityId: 5,
  },
  {
    id: 5203,
    name1: "横手市",
    name2: "ヨコテシ",
    cityId: 5,
  },
  {
    id: 5204,
    name1: "大館市",
    name2: "オオダテシ",
    cityId: 5,
  },
  {
    id: 5206,
    name1: "男鹿市",
    name2: "オガシ",
    cityId: 5,
  },
  {
    id: 5207,
    name1: "湯沢市",
    name2: "ユザワシ",
    cityId: 5,
  },
  {
    id: 5209,
    name1: "鹿角市",
    name2: "カヅノシ",
    cityId: 5,
  },
  {
    id: 5210,
    name1: "由利本荘市",
    name2: "ユリホンジョウシ",
    cityId: 5,
  },
  {
    id: 5211,
    name1: "潟上市",
    name2: "カタガミシ",
    cityId: 5,
  },
  {
    id: 5212,
    name1: "大仙市",
    name2: "ダイセンシ",
    cityId: 5,
  },
  {
    id: 5213,
    name1: "北秋田市",
    name2: "キタアキタシ",
    cityId: 5,
  },
  {
    id: 5214,
    name1: "にかほ市",
    name2: "ニカホシ",
    cityId: 5,
  },
  {
    id: 5215,
    name1: "仙北市",
    name2: "センボクシ",
    cityId: 5,
  },
  {
    id: 5303,
    name1: "鹿角郡小坂町",
    name2: "カヅノグンコサカマチ",
    cityId: 5,
  },
  {
    id: 5327,
    name1: "北秋田郡上小阿仁村",
    name2: "キタアキタグンカミコアニムラ",
    cityId: 5,
  },
  {
    id: 5346,
    name1: "山本郡藤里町",
    name2: "ヤマモトグンフジサトマチ",
    cityId: 5,
  },
  {
    id: 5348,
    name1: "山本郡三種町",
    name2: "ヤマモトグンミタネチョウ",
    cityId: 5,
  },
  {
    id: 5349,
    name1: "山本郡八峰町",
    name2: "ヤマモトグンハッポウチョウ",
    cityId: 5,
  },
  {
    id: 5361,
    name1: "南秋田郡五城目町",
    name2: "ミナミアキタグンゴジョウメマチ",
    cityId: 5,
  },
  {
    id: 5363,
    name1: "南秋田郡八郎潟町",
    name2: "ミナミアキタグンハチロウガタマチ",
    cityId: 5,
  },
  {
    id: 5366,
    name1: "南秋田郡井川町",
    name2: "ミナミアキタグンイカワマチ",
    cityId: 5,
  },
  {
    id: 5368,
    name1: "南秋田郡大潟村",
    name2: "ミナミアキタグンオオガタムラ",
    cityId: 5,
  },
  {
    id: 5434,
    name1: "仙北郡美郷町",
    name2: "センボクグンミサトチョウ",
    cityId: 5,
  },
  {
    id: 5463,
    name1: "雄勝郡羽後町",
    name2: "オガチグンウゴマチ",
    cityId: 5,
  },
  {
    id: 5464,
    name1: "雄勝郡東成瀬村",
    name2: "オガチグンヒガシナルセムラ",
    cityId: 5,
  },
  {
    id: 6201,
    name1: "山形市",
    name2: "ヤマガタシ",
    cityId: 6,
  },
  {
    id: 6202,
    name1: "米沢市",
    name2: "ヨネザワシ",
    cityId: 6,
  },
  {
    id: 6203,
    name1: "鶴岡市",
    name2: "ツルオカシ",
    cityId: 6,
  },
  {
    id: 6204,
    name1: "酒田市",
    name2: "サカタシ",
    cityId: 6,
  },
  {
    id: 6205,
    name1: "新庄市",
    name2: "シンジョウシ",
    cityId: 6,
  },
  {
    id: 6206,
    name1: "寒河江市",
    name2: "サガエシ",
    cityId: 6,
  },
  {
    id: 6207,
    name1: "上山市",
    name2: "カミノヤマシ",
    cityId: 6,
  },
  {
    id: 6208,
    name1: "村山市",
    name2: "ムラヤマシ",
    cityId: 6,
  },
  {
    id: 6209,
    name1: "長井市",
    name2: "ナガイシ",
    cityId: 6,
  },
  {
    id: 6210,
    name1: "天童市",
    name2: "テンドウシ",
    cityId: 6,
  },
  {
    id: 6211,
    name1: "東根市",
    name2: "ヒガシネシ",
    cityId: 6,
  },
  {
    id: 6212,
    name1: "尾花沢市",
    name2: "オバナザワシ",
    cityId: 6,
  },
  {
    id: 6213,
    name1: "南陽市",
    name2: "ナンヨウシ",
    cityId: 6,
  },
  {
    id: 6301,
    name1: "東村山郡山辺町",
    name2: "ヒガシムラヤマグンヤマノベマチ",
    cityId: 6,
  },
  {
    id: 6302,
    name1: "東村山郡中山町",
    name2: "ヒガシムラヤマグンナカヤママチ",
    cityId: 6,
  },
  {
    id: 6321,
    name1: "西村山郡河北町",
    name2: "ニシムラヤマグンカホクチョウ",
    cityId: 6,
  },
  {
    id: 6322,
    name1: "西村山郡西川町",
    name2: "ニシムラヤマグンニシカワマチ",
    cityId: 6,
  },
  {
    id: 6323,
    name1: "西村山郡朝日町",
    name2: "ニシムラヤマグンアサヒマチ",
    cityId: 6,
  },
  {
    id: 6324,
    name1: "西村山郡大江町",
    name2: "ニシムラヤマグンオオエマチ",
    cityId: 6,
  },
  {
    id: 6341,
    name1: "北村山郡大石田町",
    name2: "キタムラヤマグンオオイシダマチ",
    cityId: 6,
  },
  {
    id: 6361,
    name1: "最上郡金山町",
    name2: "モガミグンカネヤママチ",
    cityId: 6,
  },
  {
    id: 6362,
    name1: "最上郡最上町",
    name2: "モガミグンモガミマチ",
    cityId: 6,
  },
  {
    id: 6363,
    name1: "最上郡舟形町",
    name2: "モガミグンフナガタマチ",
    cityId: 6,
  },
  {
    id: 6364,
    name1: "最上郡真室川町",
    name2: "モガミグンマムロガワマチ",
    cityId: 6,
  },
  {
    id: 6365,
    name1: "最上郡大蔵村",
    name2: "モガミグンオオクラムラ",
    cityId: 6,
  },
  {
    id: 6366,
    name1: "最上郡鮭川村",
    name2: "モガミグンサケガワムラ",
    cityId: 6,
  },
  {
    id: 6367,
    name1: "最上郡戸沢村",
    name2: "モガミグントザワムラ",
    cityId: 6,
  },
  {
    id: 6381,
    name1: "東置賜郡高畠町",
    name2: "ヒガシオキタマグンタカハタマチ",
    cityId: 6,
  },
  {
    id: 6382,
    name1: "東置賜郡川西町",
    name2: "ヒガシオキタマグンカワニシマチ",
    cityId: 6,
  },
  {
    id: 6401,
    name1: "西置賜郡小国町",
    name2: "ニシオキタマグンオグニマチ",
    cityId: 6,
  },
  {
    id: 6402,
    name1: "西置賜郡白鷹町",
    name2: "ニシオキタマグンシラタカマチ",
    cityId: 6,
  },
  {
    id: 6403,
    name1: "西置賜郡飯豊町",
    name2: "ニシオキタマグンイイデマチ",
    cityId: 6,
  },
  {
    id: 6426,
    name1: "東田川郡三川町",
    name2: "ヒガシタガワグンミカワマチ",
    cityId: 6,
  },
  {
    id: 6428,
    name1: "東田川郡庄内町",
    name2: "ヒガシタガワグンショウナイマチ",
    cityId: 6,
  },
  {
    id: 6461,
    name1: "飽海郡遊佐町",
    name2: "アクミグンユザマチ",
    cityId: 6,
  },
  {
    id: 7201,
    name1: "福島市",
    name2: "フクシマシ",
    cityId: 7,
  },
  {
    id: 7202,
    name1: "会津若松市",
    name2: "アイヅワカマツシ",
    cityId: 7,
  },
  {
    id: 7203,
    name1: "郡山市",
    name2: "コオリヤマシ",
    cityId: 7,
  },
  {
    id: 7204,
    name1: "いわき市",
    name2: "イワキシ",
    cityId: 7,
  },
  {
    id: 7205,
    name1: "白河市",
    name2: "シラカワシ",
    cityId: 7,
  },
  {
    id: 7207,
    name1: "須賀川市",
    name2: "スカガワシ",
    cityId: 7,
  },
  {
    id: 7208,
    name1: "喜多方市",
    name2: "キタカタシ",
    cityId: 7,
  },
  {
    id: 7209,
    name1: "相馬市",
    name2: "ソウマシ",
    cityId: 7,
  },
  {
    id: 7210,
    name1: "二本松市",
    name2: "ニホンマツシ",
    cityId: 7,
  },
  {
    id: 7211,
    name1: "田村市",
    name2: "タムラシ",
    cityId: 7,
  },
  {
    id: 7212,
    name1: "南相馬市",
    name2: "ミナミソウマシ",
    cityId: 7,
  },
  {
    id: 7213,
    name1: "伊達市",
    name2: "ダテシ",
    cityId: 7,
  },
  {
    id: 7214,
    name1: "本宮市",
    name2: "モトミヤシ",
    cityId: 7,
  },
  {
    id: 7301,
    name1: "伊達郡桑折町",
    name2: "ダテグンコオリマチ",
    cityId: 7,
  },
  {
    id: 7303,
    name1: "伊達郡国見町",
    name2: "ダテグンクニミマチ",
    cityId: 7,
  },
  {
    id: 7308,
    name1: "伊達郡川俣町",
    name2: "ダテグンカワマタマチ",
    cityId: 7,
  },
  {
    id: 7322,
    name1: "安達郡大玉村",
    name2: "アダチグンオオタマムラ",
    cityId: 7,
  },
  {
    id: 7342,
    name1: "岩瀬郡鏡石町",
    name2: "イワセグンカガミイシマチ",
    cityId: 7,
  },
  {
    id: 7344,
    name1: "岩瀬郡天栄村",
    name2: "イワセグンテンエイムラ",
    cityId: 7,
  },
  {
    id: 7362,
    name1: "南会津郡下郷町",
    name2: "ミナミアイヅグンシモゴウマチ",
    cityId: 7,
  },
  {
    id: 7364,
    name1: "南会津郡檜枝岐村",
    name2: "ミナミアイヅグンヒノエマタムラ",
    cityId: 7,
  },
  {
    id: 7367,
    name1: "南会津郡只見町",
    name2: "ミナミアイヅグンタダミマチ",
    cityId: 7,
  },
  {
    id: 7368,
    name1: "南会津郡南会津町",
    name2: "ミナミアイヅグンミナミアイヅマチ",
    cityId: 7,
  },
  {
    id: 7402,
    name1: "耶麻郡北塩原村",
    name2: "ヤマグンキタシオバラムラ",
    cityId: 7,
  },
  {
    id: 7405,
    name1: "耶麻郡西会津町",
    name2: "ヤマグンニシアイヅマチ",
    cityId: 7,
  },
  {
    id: 7407,
    name1: "耶麻郡磐梯町",
    name2: "ヤマグンバンダイマチ",
    cityId: 7,
  },
  {
    id: 7408,
    name1: "耶麻郡猪苗代町",
    name2: "ヤマグンイナワシロマチ",
    cityId: 7,
  },
  {
    id: 7421,
    name1: "河沼郡会津坂下町",
    name2: "カワヌマグンアイヅバンゲマチ",
    cityId: 7,
  },
  {
    id: 7422,
    name1: "河沼郡湯川村",
    name2: "カワヌマグンユガワムラ",
    cityId: 7,
  },
  {
    id: 7423,
    name1: "河沼郡柳津町",
    name2: "カワヌマグンヤナイヅマチ",
    cityId: 7,
  },
  {
    id: 7444,
    name1: "大沼郡三島町",
    name2: "オオヌマグンミシママチ",
    cityId: 7,
  },
  {
    id: 7445,
    name1: "大沼郡金山町",
    name2: "オオヌマグンカネヤママチ",
    cityId: 7,
  },
  {
    id: 7446,
    name1: "大沼郡昭和村",
    name2: "オオヌマグンショウワムラ",
    cityId: 7,
  },
  {
    id: 7447,
    name1: "大沼郡会津美里町",
    name2: "オオヌマグンアイヅミサトマチ",
    cityId: 7,
  },
  {
    id: 7461,
    name1: "西白河郡西郷村",
    name2: "ニシシラカワグンニシゴウムラ",
    cityId: 7,
  },
  {
    id: 7464,
    name1: "西白河郡泉崎村",
    name2: "ニシシラカワグンイズミザキムラ",
    cityId: 7,
  },
  {
    id: 7465,
    name1: "西白河郡中島村",
    name2: "ニシシラカワグンナカジマムラ",
    cityId: 7,
  },
  {
    id: 7466,
    name1: "西白河郡矢吹町",
    name2: "ニシシラカワグンヤブキマチ",
    cityId: 7,
  },
  {
    id: 7481,
    name1: "東白川郡棚倉町",
    name2: "ヒガシシラカワグンタナグラマチ",
    cityId: 7,
  },
  {
    id: 7482,
    name1: "東白川郡矢祭町",
    name2: "ヒガシシラカワグンヤマツリマチ",
    cityId: 7,
  },
  {
    id: 7483,
    name1: "東白川郡塙町",
    name2: "ヒガシシラカワグンハナワマチ",
    cityId: 7,
  },
  {
    id: 7484,
    name1: "東白川郡鮫川村",
    name2: "ヒガシシラカワグンサメガワムラ",
    cityId: 7,
  },
  {
    id: 7501,
    name1: "石川郡石川町",
    name2: "イシカワグンイシカワマチ",
    cityId: 7,
  },
  {
    id: 7502,
    name1: "石川郡玉川村",
    name2: "イシカワグンタマカワムラ",
    cityId: 7,
  },
  {
    id: 7503,
    name1: "石川郡平田村",
    name2: "イシカワグンヒラタムラ",
    cityId: 7,
  },
  {
    id: 7504,
    name1: "石川郡浅川町",
    name2: "イシカワグンアサカワマチ",
    cityId: 7,
  },
  {
    id: 7505,
    name1: "石川郡古殿町",
    name2: "イシカワグンフルドノマチ",
    cityId: 7,
  },
  {
    id: 7521,
    name1: "田村郡三春町",
    name2: "タムラグンミハルマチ",
    cityId: 7,
  },
  {
    id: 7522,
    name1: "田村郡小野町",
    name2: "タムラグンオノマチ",
    cityId: 7,
  },
  {
    id: 7541,
    name1: "双葉郡広野町",
    name2: "フタバグンヒロノマチ",
    cityId: 7,
  },
  {
    id: 7542,
    name1: "双葉郡楢葉町",
    name2: "フタバグンナラハマチ",
    cityId: 7,
  },
  {
    id: 7543,
    name1: "双葉郡富岡町",
    name2: "フタバグントミオカマチ",
    cityId: 7,
  },
  {
    id: 7544,
    name1: "双葉郡川内村",
    name2: "フタバグンカワウチムラ",
    cityId: 7,
  },
  {
    id: 7545,
    name1: "双葉郡大熊町",
    name2: "フタバグンオオクママチ",
    cityId: 7,
  },
  {
    id: 7546,
    name1: "双葉郡双葉町",
    name2: "フタバグンフタバマチ",
    cityId: 7,
  },
  {
    id: 7547,
    name1: "双葉郡浪江町",
    name2: "フタバグンナミエマチ",
    cityId: 7,
  },
  {
    id: 7548,
    name1: "双葉郡葛尾村",
    name2: "フタバグンカツラオムラ",
    cityId: 7,
  },
  {
    id: 7561,
    name1: "相馬郡新地町",
    name2: "ソウマグンシンチマチ",
    cityId: 7,
  },
  {
    id: 7564,
    name1: "相馬郡飯舘村",
    name2: "ソウマグンイイタテムラ",
    cityId: 7,
  },
  {
    id: 8201,
    name1: "水戸市",
    name2: "ミトシ",
    cityId: 8,
  },
  {
    id: 8202,
    name1: "日立市",
    name2: "ヒタチシ",
    cityId: 8,
  },
  {
    id: 8203,
    name1: "土浦市",
    name2: "ツチウラシ",
    cityId: 8,
  },
  {
    id: 8204,
    name1: "古河市",
    name2: "コガシ",
    cityId: 8,
  },
  {
    id: 8205,
    name1: "石岡市",
    name2: "イシオカシ",
    cityId: 8,
  },
  {
    id: 8207,
    name1: "結城市",
    name2: "ユウキシ",
    cityId: 8,
  },
  {
    id: 8208,
    name1: "龍ケ崎市",
    name2: "リュウガサキシ",
    cityId: 8,
  },
  {
    id: 8210,
    name1: "下妻市",
    name2: "シモツマシ",
    cityId: 8,
  },
  {
    id: 8211,
    name1: "常総市",
    name2: "ジョウソウシ",
    cityId: 8,
  },
  {
    id: 8212,
    name1: "常陸太田市",
    name2: "ヒタチオオタシ",
    cityId: 8,
  },
  {
    id: 8214,
    name1: "高萩市",
    name2: "タカハギシ",
    cityId: 8,
  },
  {
    id: 8215,
    name1: "北茨城市",
    name2: "キタイバラキシ",
    cityId: 8,
  },
  {
    id: 8216,
    name1: "笠間市",
    name2: "カサマシ",
    cityId: 8,
  },
  {
    id: 8217,
    name1: "取手市",
    name2: "トリデシ",
    cityId: 8,
  },
  {
    id: 8219,
    name1: "牛久市",
    name2: "ウシクシ",
    cityId: 8,
  },
  {
    id: 8220,
    name1: "つくば市",
    name2: "ツクバシ",
    cityId: 8,
  },
  {
    id: 8221,
    name1: "ひたちなか市",
    name2: "ヒタチナカシ",
    cityId: 8,
  },
  {
    id: 8222,
    name1: "鹿嶋市",
    name2: "カシマシ",
    cityId: 8,
  },
  {
    id: 8223,
    name1: "潮来市",
    name2: "イタコシ",
    cityId: 8,
  },
  {
    id: 8224,
    name1: "守谷市",
    name2: "モリヤシ",
    cityId: 8,
  },
  {
    id: 8225,
    name1: "常陸大宮市",
    name2: "ヒタチオオミヤシ",
    cityId: 8,
  },
  {
    id: 8226,
    name1: "那珂市",
    name2: "ナカシ",
    cityId: 8,
  },
  {
    id: 8227,
    name1: "筑西市",
    name2: "チクセイシ",
    cityId: 8,
  },
  {
    id: 8228,
    name1: "坂東市",
    name2: "バンドウシ",
    cityId: 8,
  },
  {
    id: 8229,
    name1: "稲敷市",
    name2: "イナシキシ",
    cityId: 8,
  },
  {
    id: 8230,
    name1: "かすみがうら市",
    name2: "カスミガウラシ",
    cityId: 8,
  },
  {
    id: 8231,
    name1: "桜川市",
    name2: "サクラガワシ",
    cityId: 8,
  },
  {
    id: 8232,
    name1: "神栖市",
    name2: "カミスシ",
    cityId: 8,
  },
  {
    id: 8233,
    name1: "行方市",
    name2: "ナメガタシ",
    cityId: 8,
  },
  {
    id: 8234,
    name1: "鉾田市",
    name2: "ホコタシ",
    cityId: 8,
  },
  {
    id: 8235,
    name1: "つくばみらい市",
    name2: "ツクバミライシ",
    cityId: 8,
  },
  {
    id: 8236,
    name1: "小美玉市",
    name2: "オミタマシ",
    cityId: 8,
  },
  {
    id: 8302,
    name1: "東茨城郡茨城町",
    name2: "ヒガシイバラキグンイバラキマチ",
    cityId: 8,
  },
  {
    id: 8309,
    name1: "東茨城郡大洗町",
    name2: "ヒガシイバラキグンオオアライマチ",
    cityId: 8,
  },
  {
    id: 8310,
    name1: "東茨城郡城里町",
    name2: "ヒガシイバラキグンシロサトマチ",
    cityId: 8,
  },
  {
    id: 8341,
    name1: "那珂郡東海村",
    name2: "ナカグントウカイムラ",
    cityId: 8,
  },
  {
    id: 8364,
    name1: "久慈郡大子町",
    name2: "クジグンダイゴマチ",
    cityId: 8,
  },
  {
    id: 8442,
    name1: "稲敷郡美浦村",
    name2: "イナシキグンミホムラ",
    cityId: 8,
  },
  {
    id: 8443,
    name1: "稲敷郡阿見町",
    name2: "イナシキグンアミマチ",
    cityId: 8,
  },
  {
    id: 8447,
    name1: "稲敷郡河内町",
    name2: "イナシキグンカワチマチ",
    cityId: 8,
  },
  {
    id: 8521,
    name1: "結城郡八千代町",
    name2: "ユウキグンヤチヨマチ",
    cityId: 8,
  },
  {
    id: 8542,
    name1: "猿島郡五霞町",
    name2: "サシマグンゴカマチ",
    cityId: 8,
  },
  {
    id: 8546,
    name1: "猿島郡境町",
    name2: "サシマグンサカイマチ",
    cityId: 8,
  },
  {
    id: 8564,
    name1: "北相馬郡利根町",
    name2: "キタソウマグントネマチ",
    cityId: 8,
  },
  {
    id: 9201,
    name1: "宇都宮市",
    name2: "ウツノミヤシ",
    cityId: 9,
  },
  {
    id: 9202,
    name1: "足利市",
    name2: "アシカガシ",
    cityId: 9,
  },
  {
    id: 9203,
    name1: "栃木市",
    name2: "トチギシ",
    cityId: 9,
  },
  {
    id: 9204,
    name1: "佐野市",
    name2: "サノシ",
    cityId: 9,
  },
  {
    id: 9205,
    name1: "鹿沼市",
    name2: "カヌマシ",
    cityId: 9,
  },
  {
    id: 9206,
    name1: "日光市",
    name2: "ニッコウシ",
    cityId: 9,
  },
  {
    id: 9208,
    name1: "小山市",
    name2: "オヤマシ",
    cityId: 9,
  },
  {
    id: 9209,
    name1: "真岡市",
    name2: "モオカシ",
    cityId: 9,
  },
  {
    id: 9210,
    name1: "大田原市",
    name2: "オオタワラシ",
    cityId: 9,
  },
  {
    id: 9211,
    name1: "矢板市",
    name2: "ヤイタシ",
    cityId: 9,
  },
  {
    id: 9213,
    name1: "那須塩原市",
    name2: "ナスシオバラシ",
    cityId: 9,
  },
  {
    id: 9214,
    name1: "さくら市",
    name2: "サクラシ",
    cityId: 9,
  },
  {
    id: 9215,
    name1: "那須烏山市",
    name2: "ナスカラスヤマシ",
    cityId: 9,
  },
  {
    id: 9216,
    name1: "下野市",
    name2: "シモツケシ",
    cityId: 9,
  },
  {
    id: 9301,
    name1: "河内郡上三川町",
    name2: "カワチグンカミノカワマチ",
    cityId: 9,
  },
  {
    id: 9321,
    name1: "上都賀郡西方町",
    name2: "カミツガグンニシカタマチ",
    cityId: 9,
  },
  {
    id: 9342,
    name1: "芳賀郡益子町",
    name2: "ハガグンマシコマチ",
    cityId: 9,
  },
  {
    id: 9343,
    name1: "芳賀郡茂木町",
    name2: "ハガグンモテギマチ",
    cityId: 9,
  },
  {
    id: 9344,
    name1: "芳賀郡市貝町",
    name2: "ハガグンイチカイマチ",
    cityId: 9,
  },
  {
    id: 9345,
    name1: "芳賀郡芳賀町",
    name2: "ハガグンハガマチ",
    cityId: 9,
  },
  {
    id: 9361,
    name1: "下都賀郡壬生町",
    name2: "シモツガグンミブマチ",
    cityId: 9,
  },
  {
    id: 9364,
    name1: "下都賀郡野木町",
    name2: "シモツガグンノギマチ",
    cityId: 9,
  },
  {
    id: 9365,
    name1: "下都賀郡大平町",
    name2: "シモツガグンオオヒラマチ",
    cityId: 9,
  },
  {
    id: 9366,
    name1: "下都賀郡藤岡町",
    name2: "シモツガグンフジオカマチ",
    cityId: 9,
  },
  {
    id: 9367,
    name1: "下都賀郡岩舟町",
    name2: "シモツガグンイワフネマチ",
    cityId: 9,
  },
  {
    id: 9368,
    name1: "下都賀郡都賀町",
    name2: "シモツガグンツガマチ",
    cityId: 9,
  },
  {
    id: 9384,
    name1: "塩谷郡塩谷町",
    name2: "シオヤグンシオヤマチ",
    cityId: 9,
  },
  {
    id: 9386,
    name1: "塩谷郡高根沢町",
    name2: "シオヤグンタカネザワマチ",
    cityId: 9,
  },
  {
    id: 9407,
    name1: "那須郡那須町",
    name2: "ナスグンナスマチ",
    cityId: 9,
  },
  {
    id: 9411,
    name1: "那須郡那珂川町",
    name2: "ナスグンナカガワマチ",
    cityId: 9,
  },
  {
    id: 10201,
    name1: "前橋市",
    name2: "マエバシシ",
    cityId: 10,
  },
  {
    id: 10202,
    name1: "高崎市",
    name2: "タカサキシ",
    cityId: 10,
  },
  {
    id: 10203,
    name1: "桐生市",
    name2: "キリュウシ",
    cityId: 10,
  },
  {
    id: 10204,
    name1: "伊勢崎市",
    name2: "イセサキシ",
    cityId: 10,
  },
  {
    id: 10205,
    name1: "太田市",
    name2: "オオタシ",
    cityId: 10,
  },
  {
    id: 10206,
    name1: "沼田市",
    name2: "ヌマタシ",
    cityId: 10,
  },
  {
    id: 10207,
    name1: "館林市",
    name2: "タテバヤシシ",
    cityId: 10,
  },
  {
    id: 10208,
    name1: "渋川市",
    name2: "シブカワシ",
    cityId: 10,
  },
  {
    id: 10209,
    name1: "藤岡市",
    name2: "フジオカシ",
    cityId: 10,
  },
  {
    id: 10210,
    name1: "富岡市",
    name2: "トミオカシ",
    cityId: 10,
  },
  {
    id: 10211,
    name1: "安中市",
    name2: "アンナカシ",
    cityId: 10,
  },
  {
    id: 10212,
    name1: "みどり市",
    name2: "ミドリシ",
    cityId: 10,
  },
  {
    id: 10344,
    name1: "北群馬郡榛東村",
    name2: "キタグンマグンシントウムラ",
    cityId: 10,
  },
  {
    id: 10345,
    name1: "北群馬郡吉岡町",
    name2: "キタグンマグンヨシオカマチ",
    cityId: 10,
  },
  {
    id: 10366,
    name1: "多野郡上野村",
    name2: "タノグンウエノムラ",
    cityId: 10,
  },
  {
    id: 10367,
    name1: "多野郡神流町",
    name2: "タノグンカンナマチ",
    cityId: 10,
  },
  {
    id: 10382,
    name1: "甘楽郡下仁田町",
    name2: "カンラグンシモニタマチ",
    cityId: 10,
  },
  {
    id: 10383,
    name1: "甘楽郡南牧村",
    name2: "カンラグンナンモクムラ",
    cityId: 10,
  },
  {
    id: 10384,
    name1: "甘楽郡甘楽町",
    name2: "カンラグンカンラマチ",
    cityId: 10,
  },
  {
    id: 10421,
    name1: "吾妻郡中之条町",
    name2: "アガツマグンナカノジョウマチ",
    cityId: 10,
  },
  {
    id: 10424,
    name1: "吾妻郡長野原町",
    name2: "アガツマグンナガノハラマチ",
    cityId: 10,
  },
  {
    id: 10425,
    name1: "吾妻郡嬬恋村",
    name2: "アガツマグンツマゴイムラ",
    cityId: 10,
  },
  {
    id: 10426,
    name1: "吾妻郡草津町",
    name2: "アガツマグンクサツマチ",
    cityId: 10,
  },
  {
    id: 10427,
    name1: "吾妻郡六合村",
    name2: "アガツマグンクニムラ",
    cityId: 10,
  },
  {
    id: 10428,
    name1: "吾妻郡高山村",
    name2: "アガツマグンタカヤマムラ",
    cityId: 10,
  },
  {
    id: 10429,
    name1: "吾妻郡東吾妻町",
    name2: "アガツマグンヒガシアガツママチ",
    cityId: 10,
  },
  {
    id: 10443,
    name1: "利根郡片品村",
    name2: "トネグンカタシナムラ",
    cityId: 10,
  },
  {
    id: 10444,
    name1: "利根郡川場村",
    name2: "トネグンカワバムラ",
    cityId: 10,
  },
  {
    id: 10448,
    name1: "利根郡昭和村",
    name2: "トネグンショウワムラ",
    cityId: 10,
  },
  {
    id: 10449,
    name1: "利根郡みなかみ町",
    name2: "トネグンミナカミマチ",
    cityId: 10,
  },
  {
    id: 10464,
    name1: "佐波郡玉村町",
    name2: "サワグンタマムラマチ",
    cityId: 10,
  },
  {
    id: 10521,
    name1: "邑楽郡板倉町",
    name2: "オウラグンイタクラマチ",
    cityId: 10,
  },
  {
    id: 10522,
    name1: "邑楽郡明和町",
    name2: "オウラグンメイワマチ",
    cityId: 10,
  },
  {
    id: 10523,
    name1: "邑楽郡千代田町",
    name2: "オウラグンチヨダマチ",
    cityId: 10,
  },
  {
    id: 10524,
    name1: "邑楽郡大泉町",
    name2: "オウラグンオオイズミマチ",
    cityId: 10,
  },
  {
    id: 10525,
    name1: "邑楽郡邑楽町",
    name2: "オウラグンオウラマチ",
    cityId: 10,
  },
  {
    id: 11101,
    name1: "さいたま市西区",
    name2: "サイタマシニシク",
    cityId: 11,
  },
  {
    id: 11102,
    name1: "さいたま市北区",
    name2: "サイタマシキタク",
    cityId: 11,
  },
  {
    id: 11103,
    name1: "さいたま市大宮区",
    name2: "サイタマシオオミヤク",
    cityId: 11,
  },
  {
    id: 11104,
    name1: "さいたま市見沼区",
    name2: "サイタマシミヌマク",
    cityId: 11,
  },
  {
    id: 11105,
    name1: "さいたま市中央区",
    name2: "サイタマシチュウオウク",
    cityId: 11,
  },
  {
    id: 11106,
    name1: "さいたま市桜区",
    name2: "サイタマシサクラク",
    cityId: 11,
  },
  {
    id: 11107,
    name1: "さいたま市浦和区",
    name2: "サイタマシウラワク",
    cityId: 11,
  },
  {
    id: 11108,
    name1: "さいたま市南区",
    name2: "サイタマシミナミク",
    cityId: 11,
  },
  {
    id: 11109,
    name1: "さいたま市緑区",
    name2: "サイタマシミドリク",
    cityId: 11,
  },
  {
    id: 11110,
    name1: "さいたま市岩槻区",
    name2: "サイタマシイワツキク",
    cityId: 11,
  },
  {
    id: 11201,
    name1: "川越市",
    name2: "カワゴエシ",
    cityId: 11,
  },
  {
    id: 11202,
    name1: "熊谷市",
    name2: "クマガヤシ",
    cityId: 11,
  },
  {
    id: 11203,
    name1: "川口市",
    name2: "カワグチシ",
    cityId: 11,
  },
  {
    id: 11206,
    name1: "行田市",
    name2: "ギョウダシ",
    cityId: 11,
  },
  {
    id: 11207,
    name1: "秩父市",
    name2: "チチブシ",
    cityId: 11,
  },
  {
    id: 11208,
    name1: "所沢市",
    name2: "トコロザワシ",
    cityId: 11,
  },
  {
    id: 11209,
    name1: "飯能市",
    name2: "ハンノウシ",
    cityId: 11,
  },
  {
    id: 11210,
    name1: "加須市",
    name2: "カゾシ",
    cityId: 11,
  },
  {
    id: 11211,
    name1: "本庄市",
    name2: "ホンジョウシ",
    cityId: 11,
  },
  {
    id: 11212,
    name1: "東松山市",
    name2: "ヒガシマツヤマシ",
    cityId: 11,
  },
  {
    id: 11214,
    name1: "春日部市",
    name2: "カスカベシ",
    cityId: 11,
  },
  {
    id: 11215,
    name1: "狭山市",
    name2: "サヤマシ",
    cityId: 11,
  },
  {
    id: 11216,
    name1: "羽生市",
    name2: "ハニュウシ",
    cityId: 11,
  },
  {
    id: 11217,
    name1: "鴻巣市",
    name2: "コウノスシ",
    cityId: 11,
  },
  {
    id: 11218,
    name1: "深谷市",
    name2: "フカヤシ",
    cityId: 11,
  },
  {
    id: 11219,
    name1: "上尾市",
    name2: "アゲオシ",
    cityId: 11,
  },
  {
    id: 11221,
    name1: "草加市",
    name2: "ソウカシ",
    cityId: 11,
  },
  {
    id: 11222,
    name1: "越谷市",
    name2: "コシガヤシ",
    cityId: 11,
  },
  {
    id: 11223,
    name1: "蕨市",
    name2: "ワラビシ",
    cityId: 11,
  },
  {
    id: 11224,
    name1: "戸田市",
    name2: "トダシ",
    cityId: 11,
  },
  {
    id: 11225,
    name1: "入間市",
    name2: "イルマシ",
    cityId: 11,
  },
  {
    id: 11226,
    name1: "鳩ヶ谷市",
    name2: "ハトガヤシ",
    cityId: 11,
  },
  {
    id: 11227,
    name1: "朝霞市",
    name2: "アサカシ",
    cityId: 11,
  },
  {
    id: 11228,
    name1: "志木市",
    name2: "シキシ",
    cityId: 11,
  },
  {
    id: 11229,
    name1: "和光市",
    name2: "ワコウシ",
    cityId: 11,
  },
  {
    id: 11230,
    name1: "新座市",
    name2: "ニイザシ",
    cityId: 11,
  },
  {
    id: 11231,
    name1: "桶川市",
    name2: "オケガワシ",
    cityId: 11,
  },
  {
    id: 11232,
    name1: "久喜市",
    name2: "クキシ",
    cityId: 11,
  },
  {
    id: 11233,
    name1: "北本市",
    name2: "キタモトシ",
    cityId: 11,
  },
  {
    id: 11234,
    name1: "八潮市",
    name2: "ヤシオシ",
    cityId: 11,
  },
  {
    id: 11235,
    name1: "富士見市",
    name2: "フジミシ",
    cityId: 11,
  },
  {
    id: 11237,
    name1: "三郷市",
    name2: "ミサトシ",
    cityId: 11,
  },
  {
    id: 11238,
    name1: "蓮田市",
    name2: "ハスダシ",
    cityId: 11,
  },
  {
    id: 11239,
    name1: "坂戸市",
    name2: "サカドシ",
    cityId: 11,
  },
  {
    id: 11240,
    name1: "幸手市",
    name2: "サッテシ",
    cityId: 11,
  },
  {
    id: 11241,
    name1: "鶴ヶ島市",
    name2: "ツルガシマシ",
    cityId: 11,
  },
  {
    id: 11242,
    name1: "日高市",
    name2: "ヒダカシ",
    cityId: 11,
  },
  {
    id: 11243,
    name1: "吉川市",
    name2: "ヨシカワシ",
    cityId: 11,
  },
  {
    id: 11245,
    name1: "ふじみ野市",
    name2: "フジミノシ",
    cityId: 11,
  },
  {
    id: 11246,
    name1: "白岡市",
    name2: "シラオカシ",
    cityId: 11,
  },
  {
    id: 11301,
    name1: "北足立郡伊奈町",
    name2: "キタアダチグンイナマチ",
    cityId: 11,
  },
  {
    id: 11324,
    name1: "入間郡三芳町",
    name2: "イルマグンミヨシマチ",
    cityId: 11,
  },
  {
    id: 11326,
    name1: "入間郡毛呂山町",
    name2: "イルマグンモロヤママチ",
    cityId: 11,
  },
  {
    id: 11327,
    name1: "入間郡越生町",
    name2: "イルマグンオゴセマチ",
    cityId: 11,
  },
  {
    id: 11341,
    name1: "比企郡滑川町",
    name2: "ヒキグンナメガワマチ",
    cityId: 11,
  },
  {
    id: 11342,
    name1: "比企郡嵐山町",
    name2: "ヒキグンランザンマチ",
    cityId: 11,
  },
  {
    id: 11343,
    name1: "比企郡小川町",
    name2: "ヒキグンオガワマチ",
    cityId: 11,
  },
  {
    id: 11346,
    name1: "比企郡川島町",
    name2: "ヒキグンカワジママチ",
    cityId: 11,
  },
  {
    id: 11347,
    name1: "比企郡吉見町",
    name2: "ヒキグンヨシミマチ",
    cityId: 11,
  },
  {
    id: 11348,
    name1: "比企郡鳩山町",
    name2: "ヒキグンハトヤママチ",
    cityId: 11,
  },
  {
    id: 11349,
    name1: "比企郡ときがわ町",
    name2: "ヒキグントキガワマチ",
    cityId: 11,
  },
  {
    id: 11361,
    name1: "秩父郡横瀬町",
    name2: "チチブグンヨコゼマチ",
    cityId: 11,
  },
  {
    id: 11362,
    name1: "秩父郡皆野町",
    name2: "チチブグンミナノマチ",
    cityId: 11,
  },
  {
    id: 11363,
    name1: "秩父郡長瀞町",
    name2: "チチブグンナガトロマチ",
    cityId: 11,
  },
  {
    id: 11365,
    name1: "秩父郡小鹿野町",
    name2: "チチブグンオガノマチ",
    cityId: 11,
  },
  {
    id: 11369,
    name1: "秩父郡東秩父村",
    name2: "チチブグンヒガシチチブムラ",
    cityId: 11,
  },
  {
    id: 11381,
    name1: "児玉郡美里町",
    name2: "コダマグンミサトマチ",
    cityId: 11,
  },
  {
    id: 11383,
    name1: "児玉郡神川町",
    name2: "コダマグンカミカワマチ",
    cityId: 11,
  },
  {
    id: 11385,
    name1: "児玉郡上里町",
    name2: "コダマグンカミサトマチ",
    cityId: 11,
  },
  {
    id: 11408,
    name1: "大里郡寄居町",
    name2: "オオサトグンヨリイマチ",
    cityId: 11,
  },
  {
    id: 11421,
    name1: "北埼玉郡騎西町",
    name2: "キタサイタマグンキサイマチ",
    cityId: 11,
  },
  {
    id: 11424,
    name1: "北埼玉郡北川辺町",
    name2: "キタサイタマグンキタカワベマチ",
    cityId: 11,
  },
  {
    id: 11425,
    name1: "北埼玉郡大利根町",
    name2: "キタサイタマグンオオトネマチ",
    cityId: 11,
  },
  {
    id: 11442,
    name1: "南埼玉郡宮代町",
    name2: "ミナミサイタマグンミヤシロマチ",
    cityId: 11,
  },
  {
    id: 11446,
    name1: "南埼玉郡菖蒲町",
    name2: "ミナミサイタマグンショウブマチ",
    cityId: 11,
  },
  {
    id: 11461,
    name1: "北葛飾郡栗橋町",
    name2: "キタカツシカグンクリハシマチ",
    cityId: 11,
  },
  {
    id: 11462,
    name1: "北葛飾郡鷲宮町",
    name2: "キタカツシカグンワシミヤマチ",
    cityId: 11,
  },
  {
    id: 11464,
    name1: "北葛飾郡杉戸町",
    name2: "キタカツシカグンスギトマチ",
    cityId: 11,
  },
  {
    id: 11465,
    name1: "北葛飾郡松伏町",
    name2: "キタカツシカグンマツブシマチ",
    cityId: 11,
  },
  {
    id: 12101,
    name1: "千葉市中央区",
    name2: "チバシチュウオウク",
    cityId: 12,
  },
  {
    id: 12102,
    name1: "千葉市花見川区",
    name2: "チバシハナミガワク",
    cityId: 12,
  },
  {
    id: 12103,
    name1: "千葉市稲毛区",
    name2: "チバシイナゲク",
    cityId: 12,
  },
  {
    id: 12104,
    name1: "千葉市若葉区",
    name2: "チバシワカバク",
    cityId: 12,
  },
  {
    id: 12105,
    name1: "千葉市緑区",
    name2: "チバシミドリク",
    cityId: 12,
  },
  {
    id: 12106,
    name1: "千葉市美浜区",
    name2: "チバシミハマク",
    cityId: 12,
  },
  {
    id: 12202,
    name1: "銚子市",
    name2: "チョウシシ",
    cityId: 12,
  },
  {
    id: 12203,
    name1: "市川市",
    name2: "イチカワシ",
    cityId: 12,
  },
  {
    id: 12204,
    name1: "船橋市",
    name2: "フナバシシ",
    cityId: 12,
  },
  {
    id: 12205,
    name1: "館山市",
    name2: "タテヤマシ",
    cityId: 12,
  },
  {
    id: 12206,
    name1: "木更津市",
    name2: "キサラヅシ",
    cityId: 12,
  },
  {
    id: 12207,
    name1: "松戸市",
    name2: "マツドシ",
    cityId: 12,
  },
  {
    id: 12208,
    name1: "野田市",
    name2: "ノダシ",
    cityId: 12,
  },
  {
    id: 12210,
    name1: "茂原市",
    name2: "モバラシ",
    cityId: 12,
  },
  {
    id: 12211,
    name1: "成田市",
    name2: "ナリタシ",
    cityId: 12,
  },
  {
    id: 12212,
    name1: "佐倉市",
    name2: "サクラシ",
    cityId: 12,
  },
  {
    id: 12213,
    name1: "東金市",
    name2: "トウガネシ",
    cityId: 12,
  },
  {
    id: 12215,
    name1: "旭市",
    name2: "アサヒシ",
    cityId: 12,
  },
  {
    id: 12216,
    name1: "習志野市",
    name2: "ナラシノシ",
    cityId: 12,
  },
  {
    id: 12217,
    name1: "柏市",
    name2: "カシワシ",
    cityId: 12,
  },
  {
    id: 12218,
    name1: "勝浦市",
    name2: "カツウラシ",
    cityId: 12,
  },
  {
    id: 12219,
    name1: "市原市",
    name2: "イチハラシ",
    cityId: 12,
  },
  {
    id: 12220,
    name1: "流山市",
    name2: "ナガレヤマシ",
    cityId: 12,
  },
  {
    id: 12221,
    name1: "八千代市",
    name2: "ヤチヨシ",
    cityId: 12,
  },
  {
    id: 12222,
    name1: "我孫子市",
    name2: "アビコシ",
    cityId: 12,
  },
  {
    id: 12223,
    name1: "鴨川市",
    name2: "カモガワシ",
    cityId: 12,
  },
  {
    id: 12224,
    name1: "鎌ケ谷市",
    name2: "カマガヤシ",
    cityId: 12,
  },
  {
    id: 12225,
    name1: "君津市",
    name2: "キミツシ",
    cityId: 12,
  },
  {
    id: 12226,
    name1: "富津市",
    name2: "フッツシ",
    cityId: 12,
  },
  {
    id: 12227,
    name1: "浦安市",
    name2: "ウラヤスシ",
    cityId: 12,
  },
  {
    id: 12228,
    name1: "四街道市",
    name2: "ヨツカイドウシ",
    cityId: 12,
  },
  {
    id: 12229,
    name1: "袖ケ浦市",
    name2: "ソデガウラシ",
    cityId: 12,
  },
  {
    id: 12230,
    name1: "八街市",
    name2: "ヤチマタシ",
    cityId: 12,
  },
  {
    id: 12231,
    name1: "印西市",
    name2: "インザイシ",
    cityId: 12,
  },
  {
    id: 12232,
    name1: "白井市",
    name2: "シロイシ",
    cityId: 12,
  },
  {
    id: 12233,
    name1: "富里市",
    name2: "トミサトシ",
    cityId: 12,
  },
  {
    id: 12234,
    name1: "南房総市",
    name2: "ミナミボウソウシ",
    cityId: 12,
  },
  {
    id: 12235,
    name1: "匝瑳市",
    name2: "ソウサシ",
    cityId: 12,
  },
  {
    id: 12236,
    name1: "香取市",
    name2: "カトリシ",
    cityId: 12,
  },
  {
    id: 12237,
    name1: "山武市",
    name2: "サンムシ",
    cityId: 12,
  },
  {
    id: 12238,
    name1: "いすみ市",
    name2: "イスミシ",
    cityId: 12,
  },
  {
    id: 12239,
    name1: "大網白里市",
    name2: "オオアミシラサトシ",
    cityId: 12,
  },
  {
    id: 12322,
    name1: "印旛郡酒々井町",
    name2: "インバグンシスイマチ",
    cityId: 12,
  },
  {
    id: 12325,
    name1: "印旛郡印旛村",
    name2: "インバグンインバムラ",
    cityId: 12,
  },
  {
    id: 12328,
    name1: "印旛郡本埜村",
    name2: "インバグンモトノムラ",
    cityId: 12,
  },
  {
    id: 12329,
    name1: "印旛郡栄町",
    name2: "インバグンサカエマチ",
    cityId: 12,
  },
  {
    id: 12342,
    name1: "香取郡神崎町",
    name2: "カトリグンコウザキマチ",
    cityId: 12,
  },
  {
    id: 12347,
    name1: "香取郡多古町",
    name2: "カトリグンタコマチ",
    cityId: 12,
  },
  {
    id: 12349,
    name1: "香取郡東庄町",
    name2: "カトリグントウノショウマチ",
    cityId: 12,
  },
  {
    id: 12403,
    name1: "山武郡九十九里町",
    name2: "サンブグンクジュウクリマチ",
    cityId: 12,
  },
  {
    id: 12409,
    name1: "山武郡芝山町",
    name2: "サンブグンシバヤママチ",
    cityId: 12,
  },
  {
    id: 12410,
    name1: "山武郡横芝光町",
    name2: "サンブグンヨコシバヒカリマチ",
    cityId: 12,
  },
  {
    id: 12421,
    name1: "長生郡一宮町",
    name2: "チョウセイグンイチノミヤマチ",
    cityId: 12,
  },
  {
    id: 12422,
    name1: "長生郡睦沢町",
    name2: "チョウセイグンムツザワマチ",
    cityId: 12,
  },
  {
    id: 12423,
    name1: "長生郡長生村",
    name2: "チョウセイグンチョウセイムラ",
    cityId: 12,
  },
  {
    id: 12424,
    name1: "長生郡白子町",
    name2: "チョウセイグンシラコマチ",
    cityId: 12,
  },
  {
    id: 12426,
    name1: "長生郡長柄町",
    name2: "チョウセイグンナガラマチ",
    cityId: 12,
  },
  {
    id: 12427,
    name1: "長生郡長南町",
    name2: "チョウセイグンチョウナンマチ",
    cityId: 12,
  },
  {
    id: 12441,
    name1: "夷隅郡大多喜町",
    name2: "イスミグンオオタキマチ",
    cityId: 12,
  },
  {
    id: 12443,
    name1: "夷隅郡御宿町",
    name2: "イスミグンオンジュクマチ",
    cityId: 12,
  },
  {
    id: 12463,
    name1: "安房郡鋸南町",
    name2: "アワグンキョナンマチ",
    cityId: 12,
  },
  {
    id: 13101,
    name1: "千代田区",
    name2: "チヨダク",
    cityId: 13,
  },
  {
    id: 13102,
    name1: "中央区",
    name2: "チュウオウク",
    cityId: 13,
  },
  {
    id: 13103,
    name1: "港区",
    name2: "ミナトク",
    cityId: 13,
  },
  {
    id: 13104,
    name1: "新宿区",
    name2: "シンジュクク",
    cityId: 13,
  },
  {
    id: 13105,
    name1: "文京区",
    name2: "ブンキョウク",
    cityId: 13,
  },
  {
    id: 13106,
    name1: "台東区",
    name2: "タイトウク",
    cityId: 13,
  },
  {
    id: 13107,
    name1: "墨田区",
    name2: "スミダク",
    cityId: 13,
  },
  {
    id: 13108,
    name1: "江東区",
    name2: "コウトウク",
    cityId: 13,
  },
  {
    id: 13109,
    name1: "品川区",
    name2: "シナガワク",
    cityId: 13,
  },
  {
    id: 13110,
    name1: "目黒区",
    name2: "メグロク",
    cityId: 13,
  },
  {
    id: 13111,
    name1: "大田区",
    name2: "オオタク",
    cityId: 13,
  },
  {
    id: 13112,
    name1: "世田谷区",
    name2: "セタガヤク",
    cityId: 13,
  },
  {
    id: 13113,
    name1: "渋谷区",
    name2: "シブヤク",
    cityId: 13,
  },
  {
    id: 13114,
    name1: "中野区",
    name2: "ナカノク",
    cityId: 13,
  },
  {
    id: 13115,
    name1: "杉並区",
    name2: "スギナミク",
    cityId: 13,
  },
  {
    id: 13116,
    name1: "豊島区",
    name2: "トシマク",
    cityId: 13,
  },
  {
    id: 13117,
    name1: "北区",
    name2: "キタク",
    cityId: 13,
  },
  {
    id: 13118,
    name1: "荒川区",
    name2: "アラカワク",
    cityId: 13,
  },
  {
    id: 13119,
    name1: "板橋区",
    name2: "イタバシク",
    cityId: 13,
  },
  {
    id: 13120,
    name1: "練馬区",
    name2: "ネリマク",
    cityId: 13,
  },
  {
    id: 13121,
    name1: "足立区",
    name2: "アダチク",
    cityId: 13,
  },
  {
    id: 13122,
    name1: "葛飾区",
    name2: "カツシカク",
    cityId: 13,
  },
  {
    id: 13123,
    name1: "江戸川区",
    name2: "エドガワク",
    cityId: 13,
  },
  {
    id: 13201,
    name1: "八王子市",
    name2: "ハチオウジシ",
    cityId: 13,
  },
  {
    id: 13202,
    name1: "立川市",
    name2: "タチカワシ",
    cityId: 13,
  },
  {
    id: 13203,
    name1: "武蔵野市",
    name2: "ムサシノシ",
    cityId: 13,
  },
  {
    id: 13204,
    name1: "三鷹市",
    name2: "ミタカシ",
    cityId: 13,
  },
  {
    id: 13205,
    name1: "青梅市",
    name2: "オウメシ",
    cityId: 13,
  },
  {
    id: 13206,
    name1: "府中市",
    name2: "フチュウシ",
    cityId: 13,
  },
  {
    id: 13207,
    name1: "昭島市",
    name2: "アキシマシ",
    cityId: 13,
  },
  {
    id: 13208,
    name1: "調布市",
    name2: "チョウフシ",
    cityId: 13,
  },
  {
    id: 13209,
    name1: "町田市",
    name2: "マチダシ",
    cityId: 13,
  },
  {
    id: 13210,
    name1: "小金井市",
    name2: "コガネイシ",
    cityId: 13,
  },
  {
    id: 13211,
    name1: "小平市",
    name2: "コダイラシ",
    cityId: 13,
  },
  {
    id: 13212,
    name1: "日野市",
    name2: "ヒノシ",
    cityId: 13,
  },
  {
    id: 13213,
    name1: "東村山市",
    name2: "ヒガシムラヤマシ",
    cityId: 13,
  },
  {
    id: 13214,
    name1: "国分寺市",
    name2: "コクブンジシ",
    cityId: 13,
  },
  {
    id: 13215,
    name1: "国立市",
    name2: "クニタチシ",
    cityId: 13,
  },
  {
    id: 13218,
    name1: "福生市",
    name2: "フッサシ",
    cityId: 13,
  },
  {
    id: 13219,
    name1: "狛江市",
    name2: "コマエシ",
    cityId: 13,
  },
  {
    id: 13220,
    name1: "東大和市",
    name2: "ヒガシヤマトシ",
    cityId: 13,
  },
  {
    id: 13221,
    name1: "清瀬市",
    name2: "キヨセシ",
    cityId: 13,
  },
  {
    id: 13222,
    name1: "東久留米市",
    name2: "ヒガシクルメシ",
    cityId: 13,
  },
  {
    id: 13223,
    name1: "武蔵村山市",
    name2: "ムサシムラヤマシ",
    cityId: 13,
  },
  {
    id: 13224,
    name1: "多摩市",
    name2: "タマシ",
    cityId: 13,
  },
  {
    id: 13225,
    name1: "稲城市",
    name2: "イナギシ",
    cityId: 13,
  },
  {
    id: 13227,
    name1: "羽村市",
    name2: "ハムラシ",
    cityId: 13,
  },
  {
    id: 13228,
    name1: "あきる野市",
    name2: "アキルノシ",
    cityId: 13,
  },
  {
    id: 13229,
    name1: "西東京市",
    name2: "ニシトウキョウシ",
    cityId: 13,
  },
  {
    id: 13303,
    name1: "西多摩郡瑞穂町",
    name2: "ニシタマグンミズホマチ",
    cityId: 13,
  },
  {
    id: 13305,
    name1: "西多摩郡日の出町",
    name2: "ニシタマグンヒノデマチ",
    cityId: 13,
  },
  {
    id: 13307,
    name1: "西多摩郡檜原村",
    name2: "ニシタマグンヒノハラムラ",
    cityId: 13,
  },
  {
    id: 13308,
    name1: "西多摩郡奥多摩町",
    name2: "ニシタマグンオクタママチ",
    cityId: 13,
  },
  {
    id: 13361,
    name1: "大島町",
    name2: "オオシママチ",
    cityId: 13,
  },
  {
    id: 13362,
    name1: "利島村",
    name2: "トシマムラ",
    cityId: 13,
  },
  {
    id: 13363,
    name1: "新島村",
    name2: "ニイジマムラ",
    cityId: 13,
  },
  {
    id: 13364,
    name1: "神津島村",
    name2: "コウヅシマムラ",
    cityId: 13,
  },
  {
    id: 13381,
    name1: "三宅島三宅村",
    name2: "ミヤケジマミヤケムラ",
    cityId: 13,
  },
  {
    id: 13382,
    name1: "御蔵島村",
    name2: "ミクラジマムラ",
    cityId: 13,
  },
  {
    id: 13401,
    name1: "八丈島八丈町",
    name2: "ハチジョウジマハチジョウマチ",
    cityId: 13,
  },
  {
    id: 13402,
    name1: "青ヶ島村",
    name2: "アオガシマムラ",
    cityId: 13,
  },
  {
    id: 13421,
    name1: "小笠原村",
    name2: "オガサワラムラ",
    cityId: 13,
  },
  {
    id: 14101,
    name1: "横浜市鶴見区",
    name2: "ヨコハマシツルミク",
    cityId: 14,
  },
  {
    id: 14102,
    name1: "横浜市神奈川区",
    name2: "ヨコハマシカナガワク",
    cityId: 14,
  },
  {
    id: 14103,
    name1: "横浜市西区",
    name2: "ヨコハマシニシク",
    cityId: 14,
  },
  {
    id: 14104,
    name1: "横浜市中区",
    name2: "ヨコハマシナカク",
    cityId: 14,
  },
  {
    id: 14105,
    name1: "横浜市南区",
    name2: "ヨコハマシミナミク",
    cityId: 14,
  },
  {
    id: 14106,
    name1: "横浜市保土ケ谷区",
    name2: "ヨコハマシホドガヤク",
    cityId: 14,
  },
  {
    id: 14107,
    name1: "横浜市磯子区",
    name2: "ヨコハマシイソゴク",
    cityId: 14,
  },
  {
    id: 14108,
    name1: "横浜市金沢区",
    name2: "ヨコハマシカナザワク",
    cityId: 14,
  },
  {
    id: 14109,
    name1: "横浜市港北区",
    name2: "ヨコハマシコウホクク",
    cityId: 14,
  },
  {
    id: 14110,
    name1: "横浜市戸塚区",
    name2: "ヨコハマシトツカク",
    cityId: 14,
  },
  {
    id: 14111,
    name1: "横浜市港南区",
    name2: "ヨコハマシコウナンク",
    cityId: 14,
  },
  {
    id: 14112,
    name1: "横浜市旭区",
    name2: "ヨコハマシアサヒク",
    cityId: 14,
  },
  {
    id: 14113,
    name1: "横浜市緑区",
    name2: "ヨコハマシミドリク",
    cityId: 14,
  },
  {
    id: 14114,
    name1: "横浜市瀬谷区",
    name2: "ヨコハマシセヤク",
    cityId: 14,
  },
  {
    id: 14115,
    name1: "横浜市栄区",
    name2: "ヨコハマシサカエク",
    cityId: 14,
  },
  {
    id: 14116,
    name1: "横浜市泉区",
    name2: "ヨコハマシイズミク",
    cityId: 14,
  },
  {
    id: 14117,
    name1: "横浜市青葉区",
    name2: "ヨコハマシアオバク",
    cityId: 14,
  },
  {
    id: 14118,
    name1: "横浜市都筑区",
    name2: "ヨコハマシツヅキク",
    cityId: 14,
  },
  {
    id: 14131,
    name1: "川崎市川崎区",
    name2: "カワサキシカワサキク",
    cityId: 14,
  },
  {
    id: 14132,
    name1: "川崎市幸区",
    name2: "カワサキシサイワイク",
    cityId: 14,
  },
  {
    id: 14133,
    name1: "川崎市中原区",
    name2: "カワサキシナカハラク",
    cityId: 14,
  },
  {
    id: 14134,
    name1: "川崎市高津区",
    name2: "カワサキシタカツク",
    cityId: 14,
  },
  {
    id: 14135,
    name1: "川崎市多摩区",
    name2: "カワサキシタマク",
    cityId: 14,
  },
  {
    id: 14136,
    name1: "川崎市宮前区",
    name2: "カワサキシミヤマエク",
    cityId: 14,
  },
  {
    id: 14137,
    name1: "川崎市麻生区",
    name2: "カワサキシアサオク",
    cityId: 14,
  },
  {
    id: 14151,
    name1: "相模原市緑区",
    name2: "サガミハラシミドリク",
    cityId: 14,
  },
  {
    id: 14152,
    name1: "相模原市中央区",
    name2: "サガミハラシチュウオウク",
    cityId: 14,
  },
  {
    id: 14153,
    name1: "相模原市南区",
    name2: "サガミハラシミナミク",
    cityId: 14,
  },
  {
    id: 14201,
    name1: "横須賀市",
    name2: "ヨコスカシ",
    cityId: 14,
  },
  {
    id: 14203,
    name1: "平塚市",
    name2: "ヒラツカシ",
    cityId: 14,
  },
  {
    id: 14204,
    name1: "鎌倉市",
    name2: "カマクラシ",
    cityId: 14,
  },
  {
    id: 14205,
    name1: "藤沢市",
    name2: "フジサワシ",
    cityId: 14,
  },
  {
    id: 14206,
    name1: "小田原市",
    name2: "オダワラシ",
    cityId: 14,
  },
  {
    id: 14207,
    name1: "茅ヶ崎市",
    name2: "チガサキシ",
    cityId: 14,
  },
  {
    id: 14208,
    name1: "逗子市",
    name2: "ズシシ",
    cityId: 14,
  },
  {
    id: 14209,
    name1: "相模原市",
    name2: "サガミハラシ",
    cityId: 14,
  },
  {
    id: 14210,
    name1: "三浦市",
    name2: "ミウラシ",
    cityId: 14,
  },
  {
    id: 14211,
    name1: "秦野市",
    name2: "ハダノシ",
    cityId: 14,
  },
  {
    id: 14212,
    name1: "厚木市",
    name2: "アツギシ",
    cityId: 14,
  },
  {
    id: 14213,
    name1: "大和市",
    name2: "ヤマトシ",
    cityId: 14,
  },
  {
    id: 14214,
    name1: "伊勢原市",
    name2: "イセハラシ",
    cityId: 14,
  },
  {
    id: 14215,
    name1: "海老名市",
    name2: "エビナシ",
    cityId: 14,
  },
  {
    id: 14216,
    name1: "座間市",
    name2: "ザマシ",
    cityId: 14,
  },
  {
    id: 14217,
    name1: "南足柄市",
    name2: "ミナミアシガラシ",
    cityId: 14,
  },
  {
    id: 14218,
    name1: "綾瀬市",
    name2: "アヤセシ",
    cityId: 14,
  },
  {
    id: 14301,
    name1: "三浦郡葉山町",
    name2: "ミウラグンハヤママチ",
    cityId: 14,
  },
  {
    id: 14321,
    name1: "高座郡寒川町",
    name2: "コウザグンサムカワマチ",
    cityId: 14,
  },
  {
    id: 14341,
    name1: "中郡大磯町",
    name2: "ナカグンオオイソマチ",
    cityId: 14,
  },
  {
    id: 14342,
    name1: "中郡二宮町",
    name2: "ナカグンニノミヤマチ",
    cityId: 14,
  },
  {
    id: 14361,
    name1: "足柄上郡中井町",
    name2: "アシガラカミグンナカイマチ",
    cityId: 14,
  },
  {
    id: 14362,
    name1: "足柄上郡大井町",
    name2: "アシガラカミグンオオイマチ",
    cityId: 14,
  },
  {
    id: 14363,
    name1: "足柄上郡松田町",
    name2: "アシガラカミグンマツダマチ",
    cityId: 14,
  },
  {
    id: 14364,
    name1: "足柄上郡山北町",
    name2: "アシガラカミグンヤマキタマチ",
    cityId: 14,
  },
  {
    id: 14366,
    name1: "足柄上郡開成町",
    name2: "アシガラカミグンカイセイマチ",
    cityId: 14,
  },
  {
    id: 14382,
    name1: "足柄下郡箱根町",
    name2: "アシガラシモグンハコネマチ",
    cityId: 14,
  },
  {
    id: 14383,
    name1: "足柄下郡真鶴町",
    name2: "アシガラシモグンマナヅルマチ",
    cityId: 14,
  },
  {
    id: 14384,
    name1: "足柄下郡湯河原町",
    name2: "アシガラシモグンユガワラマチ",
    cityId: 14,
  },
  {
    id: 14401,
    name1: "愛甲郡愛川町",
    name2: "アイコウグンアイカワマチ",
    cityId: 14,
  },
  {
    id: 14402,
    name1: "愛甲郡清川村",
    name2: "アイコウグンキヨカワムラ",
    cityId: 14,
  },
  {
    id: 15101,
    name1: "新潟市北区",
    name2: "ニイガタシキタク",
    cityId: 15,
  },
  {
    id: 15102,
    name1: "新潟市東区",
    name2: "ニイガタシヒガシク",
    cityId: 15,
  },
  {
    id: 15103,
    name1: "新潟市中央区",
    name2: "ニイガタシチュウオウク",
    cityId: 15,
  },
  {
    id: 15104,
    name1: "新潟市江南区",
    name2: "ニイガタシコウナンク",
    cityId: 15,
  },
  {
    id: 15105,
    name1: "新潟市秋葉区",
    name2: "ニイガタシアキハク",
    cityId: 15,
  },
  {
    id: 15106,
    name1: "新潟市南区",
    name2: "ニイガタシミナミク",
    cityId: 15,
  },
  {
    id: 15107,
    name1: "新潟市西区",
    name2: "ニイガタシニシク",
    cityId: 15,
  },
  {
    id: 15108,
    name1: "新潟市西蒲区",
    name2: "ニイガタシニシカンク",
    cityId: 15,
  },
  {
    id: 15202,
    name1: "長岡市",
    name2: "ナガオカシ",
    cityId: 15,
  },
  {
    id: 15204,
    name1: "三条市",
    name2: "サンジョウシ",
    cityId: 15,
  },
  {
    id: 15205,
    name1: "柏崎市",
    name2: "カシワザキシ",
    cityId: 15,
  },
  {
    id: 15206,
    name1: "新発田市",
    name2: "シバタシ",
    cityId: 15,
  },
  {
    id: 15208,
    name1: "小千谷市",
    name2: "オヂヤシ",
    cityId: 15,
  },
  {
    id: 15209,
    name1: "加茂市",
    name2: "カモシ",
    cityId: 15,
  },
  {
    id: 15210,
    name1: "十日町市",
    name2: "トオカマチシ",
    cityId: 15,
  },
  {
    id: 15211,
    name1: "見附市",
    name2: "ミツケシ",
    cityId: 15,
  },
  {
    id: 15212,
    name1: "村上市",
    name2: "ムラカミシ",
    cityId: 15,
  },
  {
    id: 15213,
    name1: "燕市",
    name2: "ツバメシ",
    cityId: 15,
  },
  {
    id: 15216,
    name1: "糸魚川市",
    name2: "イトイガワシ",
    cityId: 15,
  },
  {
    id: 15217,
    name1: "妙高市",
    name2: "ミョウコウシ",
    cityId: 15,
  },
  {
    id: 15218,
    name1: "五泉市",
    name2: "ゴセンシ",
    cityId: 15,
  },
  {
    id: 15222,
    name1: "上越市",
    name2: "ジョウエツシ",
    cityId: 15,
  },
  {
    id: 15223,
    name1: "阿賀野市",
    name2: "アガノシ",
    cityId: 15,
  },
  {
    id: 15224,
    name1: "佐渡市",
    name2: "サドシ",
    cityId: 15,
  },
  {
    id: 15225,
    name1: "魚沼市",
    name2: "ウオヌマシ",
    cityId: 15,
  },
  {
    id: 15226,
    name1: "南魚沼市",
    name2: "ミナミウオヌマシ",
    cityId: 15,
  },
  {
    id: 15227,
    name1: "胎内市",
    name2: "タイナイシ",
    cityId: 15,
  },
  {
    id: 15307,
    name1: "北蒲原郡聖籠町",
    name2: "キタカンバラグンセイロウマチ",
    cityId: 15,
  },
  {
    id: 15342,
    name1: "西蒲原郡弥彦村",
    name2: "ニシカンバラグンヤヒコムラ",
    cityId: 15,
  },
  {
    id: 15361,
    name1: "南蒲原郡田上町",
    name2: "ミナミカンバラグンタガミマチ",
    cityId: 15,
  },
  {
    id: 15385,
    name1: "東蒲原郡阿賀町",
    name2: "ヒガシカンバラグンアガマチ",
    cityId: 15,
  },
  {
    id: 15405,
    name1: "三島郡出雲崎町",
    name2: "サントウグンイズモザキマチ",
    cityId: 15,
  },
  {
    id: 15441,
    name1: "北魚沼郡川口町",
    name2: "キタウオヌマグンカワグチマチ",
    cityId: 15,
  },
  {
    id: 15461,
    name1: "南魚沼郡湯沢町",
    name2: "ミナミウオヌマグンユザワマチ",
    cityId: 15,
  },
  {
    id: 15482,
    name1: "中魚沼郡津南町",
    name2: "ナカウオヌマグンツナンマチ",
    cityId: 15,
  },
  {
    id: 15504,
    name1: "刈羽郡刈羽村",
    name2: "カリワグンカリワムラ",
    cityId: 15,
  },
  {
    id: 15581,
    name1: "岩船郡関川村",
    name2: "イワフネグンセキカワムラ",
    cityId: 15,
  },
  {
    id: 15586,
    name1: "岩船郡粟島浦村",
    name2: "イワフネグンアワシマウラムラ",
    cityId: 15,
  },
  {
    id: 16201,
    name1: "富山市",
    name2: "トヤマシ",
    cityId: 16,
  },
  {
    id: 16202,
    name1: "高岡市",
    name2: "タカオカシ",
    cityId: 16,
  },
  {
    id: 16204,
    name1: "魚津市",
    name2: "ウオヅシ",
    cityId: 16,
  },
  {
    id: 16205,
    name1: "氷見市",
    name2: "ヒミシ",
    cityId: 16,
  },
  {
    id: 16206,
    name1: "滑川市",
    name2: "ナメリカワシ",
    cityId: 16,
  },
  {
    id: 16207,
    name1: "黒部市",
    name2: "クロベシ",
    cityId: 16,
  },
  {
    id: 16208,
    name1: "砺波市",
    name2: "トナミシ",
    cityId: 16,
  },
  {
    id: 16209,
    name1: "小矢部市",
    name2: "オヤベシ",
    cityId: 16,
  },
  {
    id: 16210,
    name1: "南砺市",
    name2: "ナントシ",
    cityId: 16,
  },
  {
    id: 16211,
    name1: "射水市",
    name2: "イミズシ",
    cityId: 16,
  },
  {
    id: 16321,
    name1: "中新川郡舟橋村",
    name2: "ナカニイカワグンフナハシムラ",
    cityId: 16,
  },
  {
    id: 16322,
    name1: "中新川郡上市町",
    name2: "ナカニイカワグンカミイチマチ",
    cityId: 16,
  },
  {
    id: 16323,
    name1: "中新川郡立山町",
    name2: "ナカニイカワグンタテヤママチ",
    cityId: 16,
  },
  {
    id: 16342,
    name1: "下新川郡入善町",
    name2: "シモニイカワグンニュウゼンマチ",
    cityId: 16,
  },
  {
    id: 16343,
    name1: "下新川郡朝日町",
    name2: "シモニイカワグンアサヒマチ",
    cityId: 16,
  },
  {
    id: 17201,
    name1: "金沢市",
    name2: "カナザワシ",
    cityId: 17,
  },
  {
    id: 17202,
    name1: "七尾市",
    name2: "ナナオシ",
    cityId: 17,
  },
  {
    id: 17203,
    name1: "小松市",
    name2: "コマツシ",
    cityId: 17,
  },
  {
    id: 17204,
    name1: "輪島市",
    name2: "ワジマシ",
    cityId: 17,
  },
  {
    id: 17205,
    name1: "珠洲市",
    name2: "スズシ",
    cityId: 17,
  },
  {
    id: 17206,
    name1: "加賀市",
    name2: "カガシ",
    cityId: 17,
  },
  {
    id: 17207,
    name1: "羽咋市",
    name2: "ハクイシ",
    cityId: 17,
  },
  {
    id: 17209,
    name1: "かほく市",
    name2: "カホクシ",
    cityId: 17,
  },
  {
    id: 17210,
    name1: "白山市",
    name2: "ハクサンシ",
    cityId: 17,
  },
  {
    id: 17211,
    name1: "能美市",
    name2: "ノミシ",
    cityId: 17,
  },
  {
    id: 17212,
    name1: "野々市市",
    name2: "ノノイチシ",
    cityId: 17,
  },
  {
    id: 17324,
    name1: "能美郡川北町",
    name2: "ノミグンカワキタマチ",
    cityId: 17,
  },
  {
    id: 17361,
    name1: "河北郡津幡町",
    name2: "カホクグンツバタマチ",
    cityId: 17,
  },
  {
    id: 17365,
    name1: "河北郡内灘町",
    name2: "カホクグンウチナダマチ",
    cityId: 17,
  },
  {
    id: 17384,
    name1: "羽咋郡志賀町",
    name2: "ハクイグンシカマチ",
    cityId: 17,
  },
  {
    id: 17386,
    name1: "羽咋郡宝達志水町",
    name2: "ハクイグンホウダツシミズチョウ",
    cityId: 17,
  },
  {
    id: 17407,
    name1: "鹿島郡中能登町",
    name2: "カシマグンナカノトマチ",
    cityId: 17,
  },
  {
    id: 17461,
    name1: "鳳珠郡穴水町",
    name2: "ホウスグンアナミズマチ",
    cityId: 17,
  },
  {
    id: 17463,
    name1: "鳳珠郡能登町",
    name2: "ホウスグンノトチョウ",
    cityId: 17,
  },
  {
    id: 18201,
    name1: "福井市",
    name2: "フクイシ",
    cityId: 18,
  },
  {
    id: 18202,
    name1: "敦賀市",
    name2: "ツルガシ",
    cityId: 18,
  },
  {
    id: 18204,
    name1: "小浜市",
    name2: "オバマシ",
    cityId: 18,
  },
  {
    id: 18205,
    name1: "大野市",
    name2: "オオノシ",
    cityId: 18,
  },
  {
    id: 18206,
    name1: "勝山市",
    name2: "カツヤマシ",
    cityId: 18,
  },
  {
    id: 18207,
    name1: "鯖江市",
    name2: "サバエシ",
    cityId: 18,
  },
  {
    id: 18208,
    name1: "あわら市",
    name2: "アワラシ",
    cityId: 18,
  },
  {
    id: 18209,
    name1: "越前市",
    name2: "エチゼンシ",
    cityId: 18,
  },
  {
    id: 18210,
    name1: "坂井市",
    name2: "サカイシ",
    cityId: 18,
  },
  {
    id: 18322,
    name1: "吉田郡永平寺町",
    name2: "ヨシダグンエイヘイジチョウ",
    cityId: 18,
  },
  {
    id: 18382,
    name1: "今立郡池田町",
    name2: "イマダテグンイケダチョウ",
    cityId: 18,
  },
  {
    id: 18404,
    name1: "南条郡南越前町",
    name2: "ナンジョウグンミナミエチゼンチョウ",
    cityId: 18,
  },
  {
    id: 18423,
    name1: "丹生郡越前町",
    name2: "ニュウグンエチゼンチョウ",
    cityId: 18,
  },
  {
    id: 18442,
    name1: "三方郡美浜町",
    name2: "ミカタグンミハマチョウ",
    cityId: 18,
  },
  {
    id: 18481,
    name1: "大飯郡高浜町",
    name2: "オオイグンタカハマチョウ",
    cityId: 18,
  },
  {
    id: 18483,
    name1: "大飯郡おおい町",
    name2: "オオイグンオオイチョウ",
    cityId: 18,
  },
  {
    id: 18501,
    name1: "三方上中郡若狭町",
    name2: "ミカタカミナカグンワカサチョウ",
    cityId: 18,
  },
  {
    id: 19201,
    name1: "甲府市",
    name2: "コウフシ",
    cityId: 19,
  },
  {
    id: 19202,
    name1: "富士吉田市",
    name2: "フジヨシダシ",
    cityId: 19,
  },
  {
    id: 19204,
    name1: "都留市",
    name2: "ツルシ",
    cityId: 19,
  },
  {
    id: 19205,
    name1: "山梨市",
    name2: "ヤマナシシ",
    cityId: 19,
  },
  {
    id: 19206,
    name1: "大月市",
    name2: "オオツキシ",
    cityId: 19,
  },
  {
    id: 19207,
    name1: "韮崎市",
    name2: "ニラサキシ",
    cityId: 19,
  },
  {
    id: 19208,
    name1: "南アルプス市",
    name2: "ミナミアルプスシ",
    cityId: 19,
  },
  {
    id: 19209,
    name1: "北杜市",
    name2: "ホクトシ",
    cityId: 19,
  },
  {
    id: 19210,
    name1: "甲斐市",
    name2: "カイシ",
    cityId: 19,
  },
  {
    id: 19211,
    name1: "笛吹市",
    name2: "フエフキシ",
    cityId: 19,
  },
  {
    id: 19212,
    name1: "上野原市",
    name2: "ウエノハラシ",
    cityId: 19,
  },
  {
    id: 19213,
    name1: "甲州市",
    name2: "コウシュウシ",
    cityId: 19,
  },
  {
    id: 19214,
    name1: "中央市",
    name2: "チュウオウシ",
    cityId: 19,
  },
  {
    id: 19346,
    name1: "西八代郡市川三郷町",
    name2: "ニシヤツシログンイチカワミサトチョウ",
    cityId: 19,
  },
  {
    id: 19362,
    name1: "南巨摩郡鰍沢町",
    name2: "ミナミコマグンカジカザワチョウ",
    cityId: 19,
  },
  {
    id: 19364,
    name1: "南巨摩郡早川町",
    name2: "ミナミコマグンハヤカワチョウ",
    cityId: 19,
  },
  {
    id: 19365,
    name1: "南巨摩郡身延町",
    name2: "ミナミコマグンミノブチョウ",
    cityId: 19,
  },
  {
    id: 19366,
    name1: "南巨摩郡南部町",
    name2: "ミナミコマグンナンブチョウ",
    cityId: 19,
  },
  {
    id: 19368,
    name1: "南巨摩郡富士川町",
    name2: "ミナミコマグンフジカワチョウ",
    cityId: 19,
  },
  {
    id: 19384,
    name1: "中巨摩郡昭和町",
    name2: "ナカコマグンショウワチョウ",
    cityId: 19,
  },
  {
    id: 19422,
    name1: "南都留郡道志村",
    name2: "ミナミツルグンドウシムラ",
    cityId: 19,
  },
  {
    id: 19423,
    name1: "南都留郡西桂町",
    name2: "ミナミツルグンニシカツラチョウ",
    cityId: 19,
  },
  {
    id: 19424,
    name1: "南都留郡忍野村",
    name2: "ミナミツルグンオシノムラ",
    cityId: 19,
  },
  {
    id: 19425,
    name1: "南都留郡山中湖村",
    name2: "ミナミツルグンヤマナカコムラ",
    cityId: 19,
  },
  {
    id: 19429,
    name1: "南都留郡鳴沢村",
    name2: "ミナミツルグンナルサワムラ",
    cityId: 19,
  },
  {
    id: 19430,
    name1: "南都留郡富士河口湖町",
    name2: "ミナミツルグンフジカワグチコマチ",
    cityId: 19,
  },
  {
    id: 19442,
    name1: "北都留郡小菅村",
    name2: "キタツルグンコスゲムラ",
    cityId: 19,
  },
  {
    id: 19443,
    name1: "北都留郡丹波山村",
    name2: "キタツルグンタバヤマムラ",
    cityId: 19,
  },
  {
    id: 20201,
    name1: "長野市",
    name2: "ナガノシ",
    cityId: 20,
  },
  {
    id: 20202,
    name1: "松本市",
    name2: "マツモトシ",
    cityId: 20,
  },
  {
    id: 20203,
    name1: "上田市",
    name2: "ウエダシ",
    cityId: 20,
  },
  {
    id: 20204,
    name1: "岡谷市",
    name2: "オカヤシ",
    cityId: 20,
  },
  {
    id: 20205,
    name1: "飯田市",
    name2: "イイダシ",
    cityId: 20,
  },
  {
    id: 20206,
    name1: "諏訪市",
    name2: "スワシ",
    cityId: 20,
  },
  {
    id: 20207,
    name1: "須坂市",
    name2: "スザカシ",
    cityId: 20,
  },
  {
    id: 20208,
    name1: "小諸市",
    name2: "コモロシ",
    cityId: 20,
  },
  {
    id: 20209,
    name1: "伊那市",
    name2: "イナシ",
    cityId: 20,
  },
  {
    id: 20210,
    name1: "駒ヶ根市",
    name2: "コマガネシ",
    cityId: 20,
  },
  {
    id: 20211,
    name1: "中野市",
    name2: "ナカノシ",
    cityId: 20,
  },
  {
    id: 20212,
    name1: "大町市",
    name2: "オオマチシ",
    cityId: 20,
  },
  {
    id: 20213,
    name1: "飯山市",
    name2: "イイヤマシ",
    cityId: 20,
  },
  {
    id: 20214,
    name1: "茅野市",
    name2: "チノシ",
    cityId: 20,
  },
  {
    id: 20215,
    name1: "塩尻市",
    name2: "シオジリシ",
    cityId: 20,
  },
  {
    id: 20217,
    name1: "佐久市",
    name2: "サクシ",
    cityId: 20,
  },
  {
    id: 20218,
    name1: "千曲市",
    name2: "チクマシ",
    cityId: 20,
  },
  {
    id: 20219,
    name1: "東御市",
    name2: "トウミシ",
    cityId: 20,
  },
  {
    id: 20220,
    name1: "安曇野市",
    name2: "アヅミノシ",
    cityId: 20,
  },
  {
    id: 20303,
    name1: "南佐久郡小海町",
    name2: "ミナミサクグンコウミマチ",
    cityId: 20,
  },
  {
    id: 20304,
    name1: "南佐久郡川上村",
    name2: "ミナミサクグンカワカミムラ",
    cityId: 20,
  },
  {
    id: 20305,
    name1: "南佐久郡南牧村",
    name2: "ミナミサクグンミナミマキムラ",
    cityId: 20,
  },
  {
    id: 20306,
    name1: "南佐久郡南相木村",
    name2: "ミナミサクグンミナミアイキムラ",
    cityId: 20,
  },
  {
    id: 20307,
    name1: "南佐久郡北相木村",
    name2: "ミナミサクグンキタアイキムラ",
    cityId: 20,
  },
  {
    id: 20309,
    name1: "南佐久郡佐久穂町",
    name2: "ミナミサクグンサクホマチ",
    cityId: 20,
  },
  {
    id: 20321,
    name1: "北佐久郡軽井沢町",
    name2: "キタサクグンカルイザワマチ",
    cityId: 20,
  },
  {
    id: 20323,
    name1: "北佐久郡御代田町",
    name2: "キタサクグンミヨタマチ",
    cityId: 20,
  },
  {
    id: 20324,
    name1: "北佐久郡立科町",
    name2: "キタサクグンタテシナマチ",
    cityId: 20,
  },
  {
    id: 20349,
    name1: "小県郡青木村",
    name2: "チイサガタグンアオキムラ",
    cityId: 20,
  },
  {
    id: 20350,
    name1: "小県郡長和町",
    name2: "チイサガタグンナガワマチ",
    cityId: 20,
  },
  {
    id: 20361,
    name1: "諏訪郡下諏訪町",
    name2: "スワグンシモスワマチ",
    cityId: 20,
  },
  {
    id: 20362,
    name1: "諏訪郡富士見町",
    name2: "スワグンフジミマチ",
    cityId: 20,
  },
  {
    id: 20363,
    name1: "諏訪郡原村",
    name2: "スワグンハラムラ",
    cityId: 20,
  },
  {
    id: 20382,
    name1: "上伊那郡辰野町",
    name2: "カミイナグンタツノマチ",
    cityId: 20,
  },
  {
    id: 20383,
    name1: "上伊那郡箕輪町",
    name2: "カミイナグンミノワマチ",
    cityId: 20,
  },
  {
    id: 20384,
    name1: "上伊那郡飯島町",
    name2: "カミイナグンイイジママチ",
    cityId: 20,
  },
  {
    id: 20385,
    name1: "上伊那郡南箕輪村",
    name2: "カミイナグンミナミミノワムラ",
    cityId: 20,
  },
  {
    id: 20386,
    name1: "上伊那郡中川村",
    name2: "カミイナグンナカガワムラ",
    cityId: 20,
  },
  {
    id: 20388,
    name1: "上伊那郡宮田村",
    name2: "カミイナグンミヤダムラ",
    cityId: 20,
  },
  {
    id: 20402,
    name1: "下伊那郡松川町",
    name2: "シモイナグンマツカワマチ",
    cityId: 20,
  },
  {
    id: 20403,
    name1: "下伊那郡高森町",
    name2: "シモイナグンタカモリマチ",
    cityId: 20,
  },
  {
    id: 20404,
    name1: "下伊那郡阿南町",
    name2: "シモイナグンアナンチョウ",
    cityId: 20,
  },
  {
    id: 20407,
    name1: "下伊那郡阿智村",
    name2: "シモイナグンアチムラ",
    cityId: 20,
  },
  {
    id: 20409,
    name1: "下伊那郡平谷村",
    name2: "シモイナグンヒラヤムラ",
    cityId: 20,
  },
  {
    id: 20410,
    name1: "下伊那郡根羽村",
    name2: "シモイナグンネバムラ",
    cityId: 20,
  },
  {
    id: 20411,
    name1: "下伊那郡下條村",
    name2: "シモイナグンシモジョウムラ",
    cityId: 20,
  },
  {
    id: 20412,
    name1: "下伊那郡売木村",
    name2: "シモイナグンウルギムラ",
    cityId: 20,
  },
  {
    id: 20413,
    name1: "下伊那郡天龍村",
    name2: "シモイナグンテンリュウムラ",
    cityId: 20,
  },
  {
    id: 20414,
    name1: "下伊那郡泰阜村",
    name2: "シモイナグンヤスオカムラ",
    cityId: 20,
  },
  {
    id: 20415,
    name1: "下伊那郡喬木村",
    name2: "シモイナグンタカギムラ",
    cityId: 20,
  },
  {
    id: 20416,
    name1: "下伊那郡豊丘村",
    name2: "シモイナグントヨオカムラ",
    cityId: 20,
  },
  {
    id: 20417,
    name1: "下伊那郡大鹿村",
    name2: "シモイナグンオオシカムラ",
    cityId: 20,
  },
  {
    id: 20422,
    name1: "木曽郡上松町",
    name2: "キソグンアゲマツマチ",
    cityId: 20,
  },
  {
    id: 20423,
    name1: "木曽郡南木曽町",
    name2: "キソグンナギソマチ",
    cityId: 20,
  },
  {
    id: 20425,
    name1: "木曽郡木祖村",
    name2: "キソグンキソムラ",
    cityId: 20,
  },
  {
    id: 20429,
    name1: "木曽郡王滝村",
    name2: "キソグンオウタキムラ",
    cityId: 20,
  },
  {
    id: 20430,
    name1: "木曽郡大桑村",
    name2: "キソグンオオクワムラ",
    cityId: 20,
  },
  {
    id: 20432,
    name1: "木曽郡木曽町",
    name2: "キソグンキソマチ",
    cityId: 20,
  },
  {
    id: 20446,
    name1: "東筑摩郡麻績村",
    name2: "ヒガシチクマグンオミムラ",
    cityId: 20,
  },
  {
    id: 20448,
    name1: "東筑摩郡生坂村",
    name2: "ヒガシチクマグンイクサカムラ",
    cityId: 20,
  },
  {
    id: 20450,
    name1: "東筑摩郡山形村",
    name2: "ヒガシチクマグンヤマガタムラ",
    cityId: 20,
  },
  {
    id: 20451,
    name1: "東筑摩郡朝日村",
    name2: "ヒガシチクマグンアサヒムラ",
    cityId: 20,
  },
  {
    id: 20452,
    name1: "東筑摩郡筑北村",
    name2: "ヒガシチクマグンチクホクムラ",
    cityId: 20,
  },
  {
    id: 20481,
    name1: "北安曇郡池田町",
    name2: "キタアヅミグンイケダマチ",
    cityId: 20,
  },
  {
    id: 20482,
    name1: "北安曇郡松川村",
    name2: "キタアヅミグンマツカワムラ",
    cityId: 20,
  },
  {
    id: 20485,
    name1: "北安曇郡白馬村",
    name2: "キタアヅミグンハクバムラ",
    cityId: 20,
  },
  {
    id: 20486,
    name1: "北安曇郡小谷村",
    name2: "キタアヅミグンオタリムラ",
    cityId: 20,
  },
  {
    id: 20521,
    name1: "埴科郡坂城町",
    name2: "ハニシナグンサカキマチ",
    cityId: 20,
  },
  {
    id: 20541,
    name1: "上高井郡小布施町",
    name2: "カミタカイグンオブセマチ",
    cityId: 20,
  },
  {
    id: 20543,
    name1: "上高井郡高山村",
    name2: "カミタカイグンタカヤマムラ",
    cityId: 20,
  },
  {
    id: 20561,
    name1: "下高井郡山ノ内町",
    name2: "シモタカイグンヤマノウチマチ",
    cityId: 20,
  },
  {
    id: 20562,
    name1: "下高井郡木島平村",
    name2: "シモタカイグンキジマダイラムラ",
    cityId: 20,
  },
  {
    id: 20563,
    name1: "下高井郡野沢温泉村",
    name2: "シモタカイグンノザワオンセンムラ",
    cityId: 20,
  },
  {
    id: 20583,
    name1: "上水内郡信濃町",
    name2: "カミミノチグンシナノマチ",
    cityId: 20,
  },
  {
    id: 20588,
    name1: "上水内郡小川村",
    name2: "カミミノチグンオガワムラ",
    cityId: 20,
  },
  {
    id: 20590,
    name1: "上水内郡飯綱町",
    name2: "カミミノチグンイイヅナマチ",
    cityId: 20,
  },
  {
    id: 20602,
    name1: "下水内郡栄村",
    name2: "シモミノチグンサカエムラ",
    cityId: 20,
  },
  {
    id: 21201,
    name1: "岐阜市",
    name2: "ギフシ",
    cityId: 21,
  },
  {
    id: 21202,
    name1: "大垣市",
    name2: "オオガキシ",
    cityId: 21,
  },
  {
    id: 21203,
    name1: "高山市",
    name2: "タカヤマシ",
    cityId: 21,
  },
  {
    id: 21204,
    name1: "多治見市",
    name2: "タジミシ",
    cityId: 21,
  },
  {
    id: 21205,
    name1: "関市",
    name2: "セキシ",
    cityId: 21,
  },
  {
    id: 21206,
    name1: "中津川市",
    name2: "ナカツガワシ",
    cityId: 21,
  },
  {
    id: 21207,
    name1: "美濃市",
    name2: "ミノシ",
    cityId: 21,
  },
  {
    id: 21208,
    name1: "瑞浪市",
    name2: "ミズナミシ",
    cityId: 21,
  },
  {
    id: 21209,
    name1: "羽島市",
    name2: "ハシマシ",
    cityId: 21,
  },
  {
    id: 21210,
    name1: "恵那市",
    name2: "エナシ",
    cityId: 21,
  },
  {
    id: 21211,
    name1: "美濃加茂市",
    name2: "ミノカモシ",
    cityId: 21,
  },
  {
    id: 21212,
    name1: "土岐市",
    name2: "トキシ",
    cityId: 21,
  },
  {
    id: 21213,
    name1: "各務原市",
    name2: "カカミガハラシ",
    cityId: 21,
  },
  {
    id: 21214,
    name1: "可児市",
    name2: "カニシ",
    cityId: 21,
  },
  {
    id: 21215,
    name1: "山県市",
    name2: "ヤマガタシ",
    cityId: 21,
  },
  {
    id: 21216,
    name1: "瑞穂市",
    name2: "ミズホシ",
    cityId: 21,
  },
  {
    id: 21217,
    name1: "飛騨市",
    name2: "ヒダシ",
    cityId: 21,
  },
  {
    id: 21218,
    name1: "本巣市",
    name2: "モトスシ",
    cityId: 21,
  },
  {
    id: 21219,
    name1: "郡上市",
    name2: "グジョウシ",
    cityId: 21,
  },
  {
    id: 21220,
    name1: "下呂市",
    name2: "ゲロシ",
    cityId: 21,
  },
  {
    id: 21221,
    name1: "海津市",
    name2: "カイヅシ",
    cityId: 21,
  },
  {
    id: 21302,
    name1: "羽島郡岐南町",
    name2: "ハシマグンギナンチョウ",
    cityId: 21,
  },
  {
    id: 21303,
    name1: "羽島郡笠松町",
    name2: "ハシマグンカサマツチョウ",
    cityId: 21,
  },
  {
    id: 21341,
    name1: "養老郡養老町",
    name2: "ヨウロウグンヨウロウチョウ",
    cityId: 21,
  },
  {
    id: 21361,
    name1: "不破郡垂井町",
    name2: "フワグンタルイチョウ",
    cityId: 21,
  },
  {
    id: 21362,
    name1: "不破郡関ケ原町",
    name2: "フワグンセキガハラチョウ",
    cityId: 21,
  },
  {
    id: 21381,
    name1: "安八郡神戸町",
    name2: "アンパチグンゴウドチョウ",
    cityId: 21,
  },
  {
    id: 21382,
    name1: "安八郡輪之内町",
    name2: "アンパチグンワノウチチョウ",
    cityId: 21,
  },
  {
    id: 21383,
    name1: "安八郡安八町",
    name2: "アンパチグンアンパチチョウ",
    cityId: 21,
  },
  {
    id: 21401,
    name1: "揖斐郡揖斐川町",
    name2: "イビグンイビガワチョウ",
    cityId: 21,
  },
  {
    id: 21403,
    name1: "揖斐郡大野町",
    name2: "イビグンオオノチョウ",
    cityId: 21,
  },
  {
    id: 21404,
    name1: "揖斐郡池田町",
    name2: "イビグンイケダチョウ",
    cityId: 21,
  },
  {
    id: 21421,
    name1: "本巣郡北方町",
    name2: "モトスグンキタガタチョウ",
    cityId: 21,
  },
  {
    id: 21501,
    name1: "加茂郡坂祝町",
    name2: "カモグンサカホギチョウ",
    cityId: 21,
  },
  {
    id: 21502,
    name1: "加茂郡富加町",
    name2: "カモグントミカチョウ",
    cityId: 21,
  },
  {
    id: 21503,
    name1: "加茂郡川辺町",
    name2: "カモグンカワベチョウ",
    cityId: 21,
  },
  {
    id: 21504,
    name1: "加茂郡七宗町",
    name2: "カモグンヒチソウチョウ",
    cityId: 21,
  },
  {
    id: 21505,
    name1: "加茂郡八百津町",
    name2: "カモグンヤオツチョウ",
    cityId: 21,
  },
  {
    id: 21506,
    name1: "加茂郡白川町",
    name2: "カモグンシラカワチョウ",
    cityId: 21,
  },
  {
    id: 21507,
    name1: "加茂郡東白川村",
    name2: "カモグンヒガシシラカワムラ",
    cityId: 21,
  },
  {
    id: 21521,
    name1: "可児郡御嵩町",
    name2: "カニグンミタケチョウ",
    cityId: 21,
  },
  {
    id: 21604,
    name1: "大野郡白川村",
    name2: "オオノグンシラカワムラ",
    cityId: 21,
  },
  {
    id: 22101,
    name1: "静岡市葵区",
    name2: "シズオカシアオイク",
    cityId: 22,
  },
  {
    id: 22102,
    name1: "静岡市駿河区",
    name2: "シズオカシスルガク",
    cityId: 22,
  },
  {
    id: 22103,
    name1: "静岡市清水区",
    name2: "シズオカシシミズク",
    cityId: 22,
  },
  {
    id: 22131,
    name1: "浜松市中区",
    name2: "ハママツシナカク",
    cityId: 22,
  },
  {
    id: 22132,
    name1: "浜松市東区",
    name2: "ハママツシヒガシク",
    cityId: 22,
  },
  {
    id: 22133,
    name1: "浜松市西区",
    name2: "ハママツシニシク",
    cityId: 22,
  },
  {
    id: 22134,
    name1: "浜松市南区",
    name2: "ハママツシミナミク",
    cityId: 22,
  },
  {
    id: 22135,
    name1: "浜松市北区",
    name2: "ハママツシキタク",
    cityId: 22,
  },
  {
    id: 22136,
    name1: "浜松市浜北区",
    name2: "ハママツシハマキタク",
    cityId: 22,
  },
  {
    id: 22137,
    name1: "浜松市天竜区",
    name2: "ハママツシテンリュウク",
    cityId: 22,
  },
  {
    id: 22203,
    name1: "沼津市",
    name2: "ヌマヅシ",
    cityId: 22,
  },
  {
    id: 22205,
    name1: "熱海市",
    name2: "アタミシ",
    cityId: 22,
  },
  {
    id: 22206,
    name1: "三島市",
    name2: "ミシマシ",
    cityId: 22,
  },
  {
    id: 22207,
    name1: "富士宮市",
    name2: "フジノミヤシ",
    cityId: 22,
  },
  {
    id: 22208,
    name1: "伊東市",
    name2: "イトウシ",
    cityId: 22,
  },
  {
    id: 22209,
    name1: "島田市",
    name2: "シマダシ",
    cityId: 22,
  },
  {
    id: 22210,
    name1: "富士市",
    name2: "フジシ",
    cityId: 22,
  },
  {
    id: 22211,
    name1: "磐田市",
    name2: "イワタシ",
    cityId: 22,
  },
  {
    id: 22212,
    name1: "焼津市",
    name2: "ヤイヅシ",
    cityId: 22,
  },
  {
    id: 22213,
    name1: "掛川市",
    name2: "カケガワシ",
    cityId: 22,
  },
  {
    id: 22214,
    name1: "藤枝市",
    name2: "フジエダシ",
    cityId: 22,
  },
  {
    id: 22215,
    name1: "御殿場市",
    name2: "ゴテンバシ",
    cityId: 22,
  },
  {
    id: 22216,
    name1: "袋井市",
    name2: "フクロイシ",
    cityId: 22,
  },
  {
    id: 22219,
    name1: "下田市",
    name2: "シモダシ",
    cityId: 22,
  },
  {
    id: 22220,
    name1: "裾野市",
    name2: "スソノシ",
    cityId: 22,
  },
  {
    id: 22221,
    name1: "湖西市",
    name2: "コサイシ",
    cityId: 22,
  },
  {
    id: 22222,
    name1: "伊豆市",
    name2: "イズシ",
    cityId: 22,
  },
  {
    id: 22223,
    name1: "御前崎市",
    name2: "オマエザキシ",
    cityId: 22,
  },
  {
    id: 22224,
    name1: "菊川市",
    name2: "キクガワシ",
    cityId: 22,
  },
  {
    id: 22225,
    name1: "伊豆の国市",
    name2: "イズノクニシ",
    cityId: 22,
  },
  {
    id: 22226,
    name1: "牧之原市",
    name2: "マキノハラシ",
    cityId: 22,
  },
  {
    id: 22301,
    name1: "賀茂郡東伊豆町",
    name2: "カモグンヒガシイズチョウ",
    cityId: 22,
  },
  {
    id: 22302,
    name1: "賀茂郡河津町",
    name2: "カモグンカワヅチョウ",
    cityId: 22,
  },
  {
    id: 22304,
    name1: "賀茂郡南伊豆町",
    name2: "カモグンミナミイズチョウ",
    cityId: 22,
  },
  {
    id: 22305,
    name1: "賀茂郡松崎町",
    name2: "カモグンマツザキチョウ",
    cityId: 22,
  },
  {
    id: 22306,
    name1: "賀茂郡西伊豆町",
    name2: "カモグンニシイズチョウ",
    cityId: 22,
  },
  {
    id: 22325,
    name1: "田方郡函南町",
    name2: "タガタグンカンナミチョウ",
    cityId: 22,
  },
  {
    id: 22341,
    name1: "駿東郡清水町",
    name2: "スントウグンシミズチョウ",
    cityId: 22,
  },
  {
    id: 22342,
    name1: "駿東郡長泉町",
    name2: "スントウグンナガイズミチョウ",
    cityId: 22,
  },
  {
    id: 22344,
    name1: "駿東郡小山町",
    name2: "スントウグンオヤマチョウ",
    cityId: 22,
  },
  {
    id: 22361,
    name1: "富士郡芝川町",
    name2: "フジグンシバカワチョウ",
    cityId: 22,
  },
  {
    id: 22424,
    name1: "榛原郡吉田町",
    name2: "ハイバラグンヨシダチョウ",
    cityId: 22,
  },
  {
    id: 22429,
    name1: "榛原郡川根本町",
    name2: "ハイバラグンカワネホンチョウ",
    cityId: 22,
  },
  {
    id: 22461,
    name1: "周智郡森町",
    name2: "シュウチグンモリマチ",
    cityId: 22,
  },
  {
    id: 22503,
    name1: "浜名郡新居町",
    name2: "ハマナグンアライチョウ",
    cityId: 22,
  },
  {
    id: 23101,
    name1: "名古屋市千種区",
    name2: "ナゴヤシチクサク",
    cityId: 23,
  },
  {
    id: 23102,
    name1: "名古屋市東区",
    name2: "ナゴヤシヒガシク",
    cityId: 23,
  },
  {
    id: 23103,
    name1: "名古屋市北区",
    name2: "ナゴヤシキタク",
    cityId: 23,
  },
  {
    id: 23104,
    name1: "名古屋市西区",
    name2: "ナゴヤシニシク",
    cityId: 23,
  },
  {
    id: 23105,
    name1: "名古屋市中村区",
    name2: "ナゴヤシナカムラク",
    cityId: 23,
  },
  {
    id: 23106,
    name1: "名古屋市中区",
    name2: "ナゴヤシナカク",
    cityId: 23,
  },
  {
    id: 23107,
    name1: "名古屋市昭和区",
    name2: "ナゴヤシショウワク",
    cityId: 23,
  },
  {
    id: 23108,
    name1: "名古屋市瑞穂区",
    name2: "ナゴヤシミズホク",
    cityId: 23,
  },
  {
    id: 23109,
    name1: "名古屋市熱田区",
    name2: "ナゴヤシアツタク",
    cityId: 23,
  },
  {
    id: 23110,
    name1: "名古屋市中川区",
    name2: "ナゴヤシナカガワク",
    cityId: 23,
  },
  {
    id: 23111,
    name1: "名古屋市港区",
    name2: "ナゴヤシミナトク",
    cityId: 23,
  },
  {
    id: 23112,
    name1: "名古屋市南区",
    name2: "ナゴヤシミナミク",
    cityId: 23,
  },
  {
    id: 23113,
    name1: "名古屋市守山区",
    name2: "ナゴヤシモリヤマク",
    cityId: 23,
  },
  {
    id: 23114,
    name1: "名古屋市緑区",
    name2: "ナゴヤシミドリク",
    cityId: 23,
  },
  {
    id: 23115,
    name1: "名古屋市名東区",
    name2: "ナゴヤシメイトウク",
    cityId: 23,
  },
  {
    id: 23116,
    name1: "名古屋市天白区",
    name2: "ナゴヤシテンパクク",
    cityId: 23,
  },
  {
    id: 23201,
    name1: "豊橋市",
    name2: "トヨハシシ",
    cityId: 23,
  },
  {
    id: 23202,
    name1: "岡崎市",
    name2: "オカザキシ",
    cityId: 23,
  },
  {
    id: 23203,
    name1: "一宮市",
    name2: "イチノミヤシ",
    cityId: 23,
  },
  {
    id: 23204,
    name1: "瀬戸市",
    name2: "セトシ",
    cityId: 23,
  },
  {
    id: 23205,
    name1: "半田市",
    name2: "ハンダシ",
    cityId: 23,
  },
  {
    id: 23206,
    name1: "春日井市",
    name2: "カスガイシ",
    cityId: 23,
  },
  {
    id: 23207,
    name1: "豊川市",
    name2: "トヨカワシ",
    cityId: 23,
  },
  {
    id: 23208,
    name1: "津島市",
    name2: "ツシマシ",
    cityId: 23,
  },
  {
    id: 23209,
    name1: "碧南市",
    name2: "ヘキナンシ",
    cityId: 23,
  },
  {
    id: 23210,
    name1: "刈谷市",
    name2: "カリヤシ",
    cityId: 23,
  },
  {
    id: 23211,
    name1: "豊田市",
    name2: "トヨタシ",
    cityId: 23,
  },
  {
    id: 23212,
    name1: "安城市",
    name2: "アンジョウシ",
    cityId: 23,
  },
  {
    id: 23213,
    name1: "西尾市",
    name2: "ニシオシ",
    cityId: 23,
  },
  {
    id: 23214,
    name1: "蒲郡市",
    name2: "ガマゴオリシ",
    cityId: 23,
  },
  {
    id: 23215,
    name1: "犬山市",
    name2: "イヌヤマシ",
    cityId: 23,
  },
  {
    id: 23216,
    name1: "常滑市",
    name2: "トコナメシ",
    cityId: 23,
  },
  {
    id: 23217,
    name1: "江南市",
    name2: "コウナンシ",
    cityId: 23,
  },
  {
    id: 23219,
    name1: "小牧市",
    name2: "コマキシ",
    cityId: 23,
  },
  {
    id: 23220,
    name1: "稲沢市",
    name2: "イナザワシ",
    cityId: 23,
  },
  {
    id: 23221,
    name1: "新城市",
    name2: "シンシロシ",
    cityId: 23,
  },
  {
    id: 23222,
    name1: "東海市",
    name2: "トウカイシ",
    cityId: 23,
  },
  {
    id: 23223,
    name1: "大府市",
    name2: "オオブシ",
    cityId: 23,
  },
  {
    id: 23224,
    name1: "知多市",
    name2: "チタシ",
    cityId: 23,
  },
  {
    id: 23225,
    name1: "知立市",
    name2: "チリュウシ",
    cityId: 23,
  },
  {
    id: 23226,
    name1: "尾張旭市",
    name2: "オワリアサヒシ",
    cityId: 23,
  },
  {
    id: 23227,
    name1: "高浜市",
    name2: "タカハマシ",
    cityId: 23,
  },
  {
    id: 23228,
    name1: "岩倉市",
    name2: "イワクラシ",
    cityId: 23,
  },
  {
    id: 23229,
    name1: "豊明市",
    name2: "トヨアケシ",
    cityId: 23,
  },
  {
    id: 23230,
    name1: "日進市",
    name2: "ニッシンシ",
    cityId: 23,
  },
  {
    id: 23231,
    name1: "田原市",
    name2: "タハラシ",
    cityId: 23,
  },
  {
    id: 23232,
    name1: "愛西市",
    name2: "アイサイシ",
    cityId: 23,
  },
  {
    id: 23233,
    name1: "清須市",
    name2: "キヨスシ",
    cityId: 23,
  },
  {
    id: 23234,
    name1: "北名古屋市",
    name2: "キタナゴヤシ",
    cityId: 23,
  },
  {
    id: 23235,
    name1: "弥富市",
    name2: "ヤトミシ",
    cityId: 23,
  },
  {
    id: 23236,
    name1: "みよし市",
    name2: "ミヨシシ",
    cityId: 23,
  },
  {
    id: 23237,
    name1: "あま市",
    name2: "アマシ",
    cityId: 23,
  },
  {
    id: 23238,
    name1: "長久手市",
    name2: "ナガクテシ",
    cityId: 23,
  },
  {
    id: 23302,
    name1: "愛知郡東郷町",
    name2: "アイチグントウゴウチョウ",
    cityId: 23,
  },
  {
    id: 23304,
    name1: "愛知郡長久手町",
    name2: "アイチグンナガクテチョウ",
    cityId: 23,
  },
  {
    id: 23342,
    name1: "西春日井郡豊山町",
    name2: "ニシカスガイグントヨヤマチョウ",
    cityId: 23,
  },
  {
    id: 23361,
    name1: "丹羽郡大口町",
    name2: "ニワグンオオグチチョウ",
    cityId: 23,
  },
  {
    id: 23362,
    name1: "丹羽郡扶桑町",
    name2: "ニワグンフソウチョウ",
    cityId: 23,
  },
  {
    id: 23424,
    name1: "海部郡大治町",
    name2: "アマグンオオハルチョウ",
    cityId: 23,
  },
  {
    id: 23425,
    name1: "海部郡蟹江町",
    name2: "アマグンカニエチョウ",
    cityId: 23,
  },
  {
    id: 23427,
    name1: "海部郡飛島村",
    name2: "アマグントビシマムラ",
    cityId: 23,
  },
  {
    id: 23441,
    name1: "知多郡阿久比町",
    name2: "チタグンアグイチョウ",
    cityId: 23,
  },
  {
    id: 23442,
    name1: "知多郡東浦町",
    name2: "チタグンヒガシウラチョウ",
    cityId: 23,
  },
  {
    id: 23445,
    name1: "知多郡南知多町",
    name2: "チタグンミナミチタチョウ",
    cityId: 23,
  },
  {
    id: 23446,
    name1: "知多郡美浜町",
    name2: "チタグンミハマチョウ",
    cityId: 23,
  },
  {
    id: 23447,
    name1: "知多郡武豊町",
    name2: "チタグンタケトヨチョウ",
    cityId: 23,
  },
  {
    id: 23481,
    name1: "幡豆郡一色町",
    name2: "ハズグンイッシキチョウ",
    cityId: 23,
  },
  {
    id: 23482,
    name1: "幡豆郡吉良町",
    name2: "ハズグンキラチョウ",
    cityId: 23,
  },
  {
    id: 23483,
    name1: "幡豆郡幡豆町",
    name2: "ハズグンハズチョウ",
    cityId: 23,
  },
  {
    id: 23501,
    name1: "額田郡幸田町",
    name2: "ヌカタグンコウタチョウ",
    cityId: 23,
  },
  {
    id: 23561,
    name1: "北設楽郡設楽町",
    name2: "キタシタラグンシタラチョウ",
    cityId: 23,
  },
  {
    id: 23562,
    name1: "北設楽郡東栄町",
    name2: "キタシタラグントウエイチョウ",
    cityId: 23,
  },
  {
    id: 23563,
    name1: "北設楽郡豊根村",
    name2: "キタシタラグントヨネムラ",
    cityId: 23,
  },
  {
    id: 24201,
    name1: "津市",
    name2: "ツシ",
    cityId: 24,
  },
  {
    id: 24202,
    name1: "四日市市",
    name2: "ヨッカイチシ",
    cityId: 24,
  },
  {
    id: 24203,
    name1: "伊勢市",
    name2: "イセシ",
    cityId: 24,
  },
  {
    id: 24204,
    name1: "松阪市",
    name2: "マツサカシ",
    cityId: 24,
  },
  {
    id: 24205,
    name1: "桑名市",
    name2: "クワナシ",
    cityId: 24,
  },
  {
    id: 24207,
    name1: "鈴鹿市",
    name2: "スズカシ",
    cityId: 24,
  },
  {
    id: 24208,
    name1: "名張市",
    name2: "ナバリシ",
    cityId: 24,
  },
  {
    id: 24209,
    name1: "尾鷲市",
    name2: "オワセシ",
    cityId: 24,
  },
  {
    id: 24210,
    name1: "亀山市",
    name2: "カメヤマシ",
    cityId: 24,
  },
  {
    id: 24211,
    name1: "鳥羽市",
    name2: "トバシ",
    cityId: 24,
  },
  {
    id: 24212,
    name1: "熊野市",
    name2: "クマノシ",
    cityId: 24,
  },
  {
    id: 24214,
    name1: "いなべ市",
    name2: "イナベシ",
    cityId: 24,
  },
  {
    id: 24215,
    name1: "志摩市",
    name2: "シマシ",
    cityId: 24,
  },
  {
    id: 24216,
    name1: "伊賀市",
    name2: "イガシ",
    cityId: 24,
  },
  {
    id: 24303,
    name1: "桑名郡木曽岬町",
    name2: "クワナグンキソサキチョウ",
    cityId: 24,
  },
  {
    id: 24324,
    name1: "員弁郡東員町",
    name2: "イナベグントウインチョウ",
    cityId: 24,
  },
  {
    id: 24341,
    name1: "三重郡菰野町",
    name2: "ミエグンコモノチョウ",
    cityId: 24,
  },
  {
    id: 24343,
    name1: "三重郡朝日町",
    name2: "ミエグンアサヒチョウ",
    cityId: 24,
  },
  {
    id: 24344,
    name1: "三重郡川越町",
    name2: "ミエグンカワゴエチョウ",
    cityId: 24,
  },
  {
    id: 24441,
    name1: "多気郡多気町",
    name2: "タキグンタキチョウ",
    cityId: 24,
  },
  {
    id: 24442,
    name1: "多気郡明和町",
    name2: "タキグンメイワチョウ",
    cityId: 24,
  },
  {
    id: 24443,
    name1: "多気郡大台町",
    name2: "タキグンオオダイチョウ",
    cityId: 24,
  },
  {
    id: 24461,
    name1: "度会郡玉城町",
    name2: "ワタライグンタマキチョウ",
    cityId: 24,
  },
  {
    id: 24470,
    name1: "度会郡度会町",
    name2: "ワタライグンワタライチョウ",
    cityId: 24,
  },
  {
    id: 24471,
    name1: "度会郡大紀町",
    name2: "ワタライグンタイキチョウ",
    cityId: 24,
  },
  {
    id: 24472,
    name1: "度会郡南伊勢町",
    name2: "ワタライグンミナミイセチョウ",
    cityId: 24,
  },
  {
    id: 24543,
    name1: "北牟婁郡紀北町",
    name2: "キタムログンキホクチョウ",
    cityId: 24,
  },
  {
    id: 24561,
    name1: "南牟婁郡御浜町",
    name2: "ミナミムログンミハマチョウ",
    cityId: 24,
  },
  {
    id: 24562,
    name1: "南牟婁郡紀宝町",
    name2: "ミナミムログンキホウチョウ",
    cityId: 24,
  },
  {
    id: 25201,
    name1: "大津市",
    name2: "オオツシ",
    cityId: 25,
  },
  {
    id: 25202,
    name1: "彦根市",
    name2: "ヒコネシ",
    cityId: 25,
  },
  {
    id: 25203,
    name1: "長浜市",
    name2: "ナガハマシ",
    cityId: 25,
  },
  {
    id: 25204,
    name1: "近江八幡市",
    name2: "オウミハチマンシ",
    cityId: 25,
  },
  {
    id: 25206,
    name1: "草津市",
    name2: "クサツシ",
    cityId: 25,
  },
  {
    id: 25207,
    name1: "守山市",
    name2: "モリヤマシ",
    cityId: 25,
  },
  {
    id: 25208,
    name1: "栗東市",
    name2: "リットウシ",
    cityId: 25,
  },
  {
    id: 25209,
    name1: "甲賀市",
    name2: "コウカシ",
    cityId: 25,
  },
  {
    id: 25210,
    name1: "野洲市",
    name2: "ヤスシ",
    cityId: 25,
  },
  {
    id: 25211,
    name1: "湖南市",
    name2: "コナンシ",
    cityId: 25,
  },
  {
    id: 25212,
    name1: "高島市",
    name2: "タカシマシ",
    cityId: 25,
  },
  {
    id: 25213,
    name1: "東近江市",
    name2: "ヒガシオウミシ",
    cityId: 25,
  },
  {
    id: 25214,
    name1: "米原市",
    name2: "マイバラシ",
    cityId: 25,
  },
  {
    id: 25381,
    name1: "蒲生郡安土町",
    name2: "ガモウグンアヅチチョウ",
    cityId: 25,
  },
  {
    id: 25383,
    name1: "蒲生郡日野町",
    name2: "ガモウグンヒノチョウ",
    cityId: 25,
  },
  {
    id: 25384,
    name1: "蒲生郡竜王町",
    name2: "ガモウグンリュウオウチョウ",
    cityId: 25,
  },
  {
    id: 25425,
    name1: "愛知郡愛荘町",
    name2: "エチグンアイショウチョウ",
    cityId: 25,
  },
  {
    id: 25441,
    name1: "犬上郡豊郷町",
    name2: "イヌカミグントヨサトチョウ",
    cityId: 25,
  },
  {
    id: 25442,
    name1: "犬上郡甲良町",
    name2: "イヌカミグンコウラチョウ",
    cityId: 25,
  },
  {
    id: 25443,
    name1: "犬上郡多賀町",
    name2: "イヌカミグンタガチョウ",
    cityId: 25,
  },
  {
    id: 26101,
    name1: "京都市北区",
    name2: "キョウトシキタク",
    cityId: 26,
  },
  {
    id: 26102,
    name1: "京都市上京区",
    name2: "キョウトシカミギョウク",
    cityId: 26,
  },
  {
    id: 26103,
    name1: "京都市左京区",
    name2: "キョウトシサキョウク",
    cityId: 26,
  },
  {
    id: 26104,
    name1: "京都市中京区",
    name2: "キョウトシナカギョウク",
    cityId: 26,
  },
  {
    id: 26105,
    name1: "京都市東山区",
    name2: "キョウトシヒガシヤマク",
    cityId: 26,
  },
  {
    id: 26106,
    name1: "京都市下京区",
    name2: "キョウトシシモギョウク",
    cityId: 26,
  },
  {
    id: 26107,
    name1: "京都市南区",
    name2: "キョウトシミナミク",
    cityId: 26,
  },
  {
    id: 26108,
    name1: "京都市右京区",
    name2: "キョウトシウキョウク",
    cityId: 26,
  },
  {
    id: 26109,
    name1: "京都市伏見区",
    name2: "キョウトシフシミク",
    cityId: 26,
  },
  {
    id: 26110,
    name1: "京都市山科区",
    name2: "キョウトシヤマシナク",
    cityId: 26,
  },
  {
    id: 26111,
    name1: "京都市西京区",
    name2: "キョウトシニシキョウク",
    cityId: 26,
  },
  {
    id: 26201,
    name1: "福知山市",
    name2: "フクチヤマシ",
    cityId: 26,
  },
  {
    id: 26202,
    name1: "舞鶴市",
    name2: "マイヅルシ",
    cityId: 26,
  },
  {
    id: 26203,
    name1: "綾部市",
    name2: "アヤベシ",
    cityId: 26,
  },
  {
    id: 26204,
    name1: "宇治市",
    name2: "ウジシ",
    cityId: 26,
  },
  {
    id: 26205,
    name1: "宮津市",
    name2: "ミヤヅシ",
    cityId: 26,
  },
  {
    id: 26206,
    name1: "亀岡市",
    name2: "カメオカシ",
    cityId: 26,
  },
  {
    id: 26207,
    name1: "城陽市",
    name2: "ジョウヨウシ",
    cityId: 26,
  },
  {
    id: 26208,
    name1: "向日市",
    name2: "ムコウシ",
    cityId: 26,
  },
  {
    id: 26209,
    name1: "長岡京市",
    name2: "ナガオカキョウシ",
    cityId: 26,
  },
  {
    id: 26210,
    name1: "八幡市",
    name2: "ヤワタシ",
    cityId: 26,
  },
  {
    id: 26211,
    name1: "京田辺市",
    name2: "キョウタナベシ",
    cityId: 26,
  },
  {
    id: 26212,
    name1: "京丹後市",
    name2: "キョウタンゴシ",
    cityId: 26,
  },
  {
    id: 26213,
    name1: "南丹市",
    name2: "ナンタンシ",
    cityId: 26,
  },
  {
    id: 26214,
    name1: "木津川市",
    name2: "キヅガワシ",
    cityId: 26,
  },
  {
    id: 26303,
    name1: "乙訓郡大山崎町",
    name2: "オトクニグンオオヤマザキチョウ",
    cityId: 26,
  },
  {
    id: 26322,
    name1: "久世郡久御山町",
    name2: "クセグンクミヤマチョウ",
    cityId: 26,
  },
  {
    id: 26343,
    name1: "綴喜郡井手町",
    name2: "ツヅキグンイデチョウ",
    cityId: 26,
  },
  {
    id: 26344,
    name1: "綴喜郡宇治田原町",
    name2: "ツヅキグンウジタワラチョウ",
    cityId: 26,
  },
  {
    id: 26364,
    name1: "相楽郡笠置町",
    name2: "ソウラクグンカサギチョウ",
    cityId: 26,
  },
  {
    id: 26365,
    name1: "相楽郡和束町",
    name2: "ソウラクグンワヅカチョウ",
    cityId: 26,
  },
  {
    id: 26366,
    name1: "相楽郡精華町",
    name2: "ソウラクグンセイカチョウ",
    cityId: 26,
  },
  {
    id: 26367,
    name1: "相楽郡南山城村",
    name2: "ソウラクグンミナミヤマシロムラ",
    cityId: 26,
  },
  {
    id: 26407,
    name1: "船井郡京丹波町",
    name2: "フナイグンキョウタンバチョウ",
    cityId: 26,
  },
  {
    id: 26463,
    name1: "与謝郡伊根町",
    name2: "ヨサグンイネチョウ",
    cityId: 26,
  },
  {
    id: 26465,
    name1: "与謝郡与謝野町",
    name2: "ヨサグンヨサノチョウ",
    cityId: 26,
  },
  {
    id: 27102,
    name1: "大阪市都島区",
    name2: "オオサカシミヤコジマク",
    cityId: 27,
  },
  {
    id: 27103,
    name1: "大阪市福島区",
    name2: "オオサカシフクシマク",
    cityId: 27,
  },
  {
    id: 27104,
    name1: "大阪市此花区",
    name2: "オオサカシコノハナク",
    cityId: 27,
  },
  {
    id: 27106,
    name1: "大阪市西区",
    name2: "オオサカシニシク",
    cityId: 27,
  },
  {
    id: 27107,
    name1: "大阪市港区",
    name2: "オオサカシミナトク",
    cityId: 27,
  },
  {
    id: 27108,
    name1: "大阪市大正区",
    name2: "オオサカシタイショウク",
    cityId: 27,
  },
  {
    id: 27109,
    name1: "大阪市天王寺区",
    name2: "オオサカシテンノウジク",
    cityId: 27,
  },
  {
    id: 27111,
    name1: "大阪市浪速区",
    name2: "オオサカシナニワク",
    cityId: 27,
  },
  {
    id: 27113,
    name1: "大阪市西淀川区",
    name2: "オオサカシニシヨドガワク",
    cityId: 27,
  },
  {
    id: 27114,
    name1: "大阪市東淀川区",
    name2: "オオサカシヒガシヨドガワク",
    cityId: 27,
  },
  {
    id: 27115,
    name1: "大阪市東成区",
    name2: "オオサカシヒガシナリク",
    cityId: 27,
  },
  {
    id: 27116,
    name1: "大阪市生野区",
    name2: "オオサカシイクノク",
    cityId: 27,
  },
  {
    id: 27117,
    name1: "大阪市旭区",
    name2: "オオサカシアサヒク",
    cityId: 27,
  },
  {
    id: 27118,
    name1: "大阪市城東区",
    name2: "オオサカシジョウトウク",
    cityId: 27,
  },
  {
    id: 27119,
    name1: "大阪市阿倍野区",
    name2: "オオサカシアベノク",
    cityId: 27,
  },
  {
    id: 27120,
    name1: "大阪市住吉区",
    name2: "オオサカシスミヨシク",
    cityId: 27,
  },
  {
    id: 27121,
    name1: "大阪市東住吉区",
    name2: "オオサカシヒガシスミヨシク",
    cityId: 27,
  },
  {
    id: 27122,
    name1: "大阪市西成区",
    name2: "オオサカシニシナリク",
    cityId: 27,
  },
  {
    id: 27123,
    name1: "大阪市淀川区",
    name2: "オオサカシヨドガワク",
    cityId: 27,
  },
  {
    id: 27124,
    name1: "大阪市鶴見区",
    name2: "オオサカシツルミク",
    cityId: 27,
  },
  {
    id: 27125,
    name1: "大阪市住之江区",
    name2: "オオサカシスミノエク",
    cityId: 27,
  },
  {
    id: 27126,
    name1: "大阪市平野区",
    name2: "オオサカシヒラノク",
    cityId: 27,
  },
  {
    id: 27127,
    name1: "大阪市北区",
    name2: "オオサカシキタク",
    cityId: 27,
  },
  {
    id: 27128,
    name1: "大阪市中央区",
    name2: "オオサカシチュウオウク",
    cityId: 27,
  },
  {
    id: 27141,
    name1: "堺市堺区",
    name2: "サカイシサカイク",
    cityId: 27,
  },
  {
    id: 27142,
    name1: "堺市中区",
    name2: "サカイシナカク",
    cityId: 27,
  },
  {
    id: 27143,
    name1: "堺市東区",
    name2: "サカイシヒガシク",
    cityId: 27,
  },
  {
    id: 27144,
    name1: "堺市西区",
    name2: "サカイシニシク",
    cityId: 27,
  },
  {
    id: 27145,
    name1: "堺市南区",
    name2: "サカイシミナミク",
    cityId: 27,
  },
  {
    id: 27146,
    name1: "堺市北区",
    name2: "サカイシキタク",
    cityId: 27,
  },
  {
    id: 27147,
    name1: "堺市美原区",
    name2: "サカイシミハラク",
    cityId: 27,
  },
  {
    id: 27202,
    name1: "岸和田市",
    name2: "キシワダシ",
    cityId: 27,
  },
  {
    id: 27203,
    name1: "豊中市",
    name2: "トヨナカシ",
    cityId: 27,
  },
  {
    id: 27204,
    name1: "池田市",
    name2: "イケダシ",
    cityId: 27,
  },
  {
    id: 27205,
    name1: "吹田市",
    name2: "スイタシ",
    cityId: 27,
  },
  {
    id: 27206,
    name1: "泉大津市",
    name2: "イズミオオツシ",
    cityId: 27,
  },
  {
    id: 27207,
    name1: "高槻市",
    name2: "タカツキシ",
    cityId: 27,
  },
  {
    id: 27208,
    name1: "貝塚市",
    name2: "カイヅカシ",
    cityId: 27,
  },
  {
    id: 27209,
    name1: "守口市",
    name2: "モリグチシ",
    cityId: 27,
  },
  {
    id: 27210,
    name1: "枚方市",
    name2: "ヒラカタシ",
    cityId: 27,
  },
  {
    id: 27211,
    name1: "茨木市",
    name2: "イバラキシ",
    cityId: 27,
  },
  {
    id: 27212,
    name1: "八尾市",
    name2: "ヤオシ",
    cityId: 27,
  },
  {
    id: 27213,
    name1: "泉佐野市",
    name2: "イズミサノシ",
    cityId: 27,
  },
  {
    id: 27214,
    name1: "富田林市",
    name2: "トンダバヤシシ",
    cityId: 27,
  },
  {
    id: 27215,
    name1: "寝屋川市",
    name2: "ネヤガワシ",
    cityId: 27,
  },
  {
    id: 27216,
    name1: "河内長野市",
    name2: "カワチナガノシ",
    cityId: 27,
  },
  {
    id: 27217,
    name1: "松原市",
    name2: "マツバラシ",
    cityId: 27,
  },
  {
    id: 27218,
    name1: "大東市",
    name2: "ダイトウシ",
    cityId: 27,
  },
  {
    id: 27219,
    name1: "和泉市",
    name2: "イズミシ",
    cityId: 27,
  },
  {
    id: 27220,
    name1: "箕面市",
    name2: "ミノオシ",
    cityId: 27,
  },
  {
    id: 27221,
    name1: "柏原市",
    name2: "カシワラシ",
    cityId: 27,
  },
  {
    id: 27222,
    name1: "羽曳野市",
    name2: "ハビキノシ",
    cityId: 27,
  },
  {
    id: 27223,
    name1: "門真市",
    name2: "カドマシ",
    cityId: 27,
  },
  {
    id: 27224,
    name1: "摂津市",
    name2: "セッツシ",
    cityId: 27,
  },
  {
    id: 27225,
    name1: "高石市",
    name2: "タカイシシ",
    cityId: 27,
  },
  {
    id: 27226,
    name1: "藤井寺市",
    name2: "フジイデラシ",
    cityId: 27,
  },
  {
    id: 27227,
    name1: "東大阪市",
    name2: "ヒガシオオサカシ",
    cityId: 27,
  },
  {
    id: 27228,
    name1: "泉南市",
    name2: "センナンシ",
    cityId: 27,
  },
  {
    id: 27229,
    name1: "四條畷市",
    name2: "シジョウナワテシ",
    cityId: 27,
  },
  {
    id: 27230,
    name1: "交野市",
    name2: "カタノシ",
    cityId: 27,
  },
  {
    id: 27231,
    name1: "大阪狭山市",
    name2: "オオサカサヤマシ",
    cityId: 27,
  },
  {
    id: 27232,
    name1: "阪南市",
    name2: "ハンナンシ",
    cityId: 27,
  },
  {
    id: 27301,
    name1: "三島郡島本町",
    name2: "ミシマグンシマモトチョウ",
    cityId: 27,
  },
  {
    id: 27321,
    name1: "豊能郡豊能町",
    name2: "トヨノグントヨノチョウ",
    cityId: 27,
  },
  {
    id: 27322,
    name1: "豊能郡能勢町",
    name2: "トヨノグンノセチョウ",
    cityId: 27,
  },
  {
    id: 27341,
    name1: "泉北郡忠岡町",
    name2: "センボクグンタダオカチョウ",
    cityId: 27,
  },
  {
    id: 27361,
    name1: "泉南郡熊取町",
    name2: "センナングンクマトリチョウ",
    cityId: 27,
  },
  {
    id: 27362,
    name1: "泉南郡田尻町",
    name2: "センナングンタジリチョウ",
    cityId: 27,
  },
  {
    id: 27366,
    name1: "泉南郡岬町",
    name2: "センナングンミサキチョウ",
    cityId: 27,
  },
  {
    id: 27381,
    name1: "南河内郡太子町",
    name2: "ミナミカワチグンタイシチョウ",
    cityId: 27,
  },
  {
    id: 27382,
    name1: "南河内郡河南町",
    name2: "ミナミカワチグンカナンチョウ",
    cityId: 27,
  },
  {
    id: 27383,
    name1: "南河内郡千早赤阪村",
    name2: "ミナミカワチグンチハヤアカサカムラ",
    cityId: 27,
  },
  {
    id: 28101,
    name1: "神戸市東灘区",
    name2: "コウベシヒガシナダク",
    cityId: 28,
  },
  {
    id: 28102,
    name1: "神戸市灘区",
    name2: "コウベシナダク",
    cityId: 28,
  },
  {
    id: 28105,
    name1: "神戸市兵庫区",
    name2: "コウベシヒョウゴク",
    cityId: 28,
  },
  {
    id: 28106,
    name1: "神戸市長田区",
    name2: "コウベシナガタク",
    cityId: 28,
  },
  {
    id: 28107,
    name1: "神戸市須磨区",
    name2: "コウベシスマク",
    cityId: 28,
  },
  {
    id: 28108,
    name1: "神戸市垂水区",
    name2: "コウベシタルミク",
    cityId: 28,
  },
  {
    id: 28109,
    name1: "神戸市北区",
    name2: "コウベシキタク",
    cityId: 28,
  },
  {
    id: 28110,
    name1: "神戸市中央区",
    name2: "コウベシチュウオウク",
    cityId: 28,
  },
  {
    id: 28111,
    name1: "神戸市西区",
    name2: "コウベシニシク",
    cityId: 28,
  },
  {
    id: 28201,
    name1: "姫路市",
    name2: "ヒメジシ",
    cityId: 28,
  },
  {
    id: 28202,
    name1: "尼崎市",
    name2: "アマガサキシ",
    cityId: 28,
  },
  {
    id: 28203,
    name1: "明石市",
    name2: "アカシシ",
    cityId: 28,
  },
  {
    id: 28204,
    name1: "西宮市",
    name2: "ニシノミヤシ",
    cityId: 28,
  },
  {
    id: 28205,
    name1: "洲本市",
    name2: "スモトシ",
    cityId: 28,
  },
  {
    id: 28206,
    name1: "芦屋市",
    name2: "アシヤシ",
    cityId: 28,
  },
  {
    id: 28207,
    name1: "伊丹市",
    name2: "イタミシ",
    cityId: 28,
  },
  {
    id: 28208,
    name1: "相生市",
    name2: "アイオイシ",
    cityId: 28,
  },
  {
    id: 28209,
    name1: "豊岡市",
    name2: "トヨオカシ",
    cityId: 28,
  },
  {
    id: 28210,
    name1: "加古川市",
    name2: "カコガワシ",
    cityId: 28,
  },
  {
    id: 28212,
    name1: "赤穂市",
    name2: "アコウシ",
    cityId: 28,
  },
  {
    id: 28213,
    name1: "西脇市",
    name2: "ニシワキシ",
    cityId: 28,
  },
  {
    id: 28214,
    name1: "宝塚市",
    name2: "タカラヅカシ",
    cityId: 28,
  },
  {
    id: 28215,
    name1: "三木市",
    name2: "ミキシ",
    cityId: 28,
  },
  {
    id: 28216,
    name1: "高砂市",
    name2: "タカサゴシ",
    cityId: 28,
  },
  {
    id: 28217,
    name1: "川西市",
    name2: "カワニシシ",
    cityId: 28,
  },
  {
    id: 28218,
    name1: "小野市",
    name2: "オノシ",
    cityId: 28,
  },
  {
    id: 28219,
    name1: "三田市",
    name2: "サンダシ",
    cityId: 28,
  },
  {
    id: 28220,
    name1: "加西市",
    name2: "カサイシ",
    cityId: 28,
  },
  {
    id: 28221,
    name1: "篠山市",
    name2: "ササヤマシ",
    cityId: 28,
  },
  {
    id: 28222,
    name1: "養父市",
    name2: "ヤブシ",
    cityId: 28,
  },
  {
    id: 28223,
    name1: "丹波市",
    name2: "タンバシ",
    cityId: 28,
  },
  {
    id: 28224,
    name1: "南あわじ市",
    name2: "ミナミアワジシ",
    cityId: 28,
  },
  {
    id: 28225,
    name1: "朝来市",
    name2: "アサゴシ",
    cityId: 28,
  },
  {
    id: 28226,
    name1: "淡路市",
    name2: "アワジシ",
    cityId: 28,
  },
  {
    id: 28227,
    name1: "宍粟市",
    name2: "シソウシ",
    cityId: 28,
  },
  {
    id: 28228,
    name1: "加東市",
    name2: "カトウシ",
    cityId: 28,
  },
  {
    id: 28229,
    name1: "たつの市",
    name2: "タツノシ",
    cityId: 28,
  },
  {
    id: 28301,
    name1: "川辺郡猪名川町",
    name2: "カワベグンイナガワチョウ",
    cityId: 28,
  },
  {
    id: 28365,
    name1: "多可郡多可町",
    name2: "タカグンタカチョウ",
    cityId: 28,
  },
  {
    id: 28381,
    name1: "加古郡稲美町",
    name2: "カコグンイナミチョウ",
    cityId: 28,
  },
  {
    id: 28382,
    name1: "加古郡播磨町",
    name2: "カコグンハリマチョウ",
    cityId: 28,
  },
  {
    id: 28442,
    name1: "神崎郡市川町",
    name2: "カンザキグンイチカワチョウ",
    cityId: 28,
  },
  {
    id: 28443,
    name1: "神崎郡福崎町",
    name2: "カンザキグンフクサキチョウ",
    cityId: 28,
  },
  {
    id: 28446,
    name1: "神崎郡神河町",
    name2: "カンザキグンカミカワチョウ",
    cityId: 28,
  },
  {
    id: 28464,
    name1: "揖保郡太子町",
    name2: "イボグンタイシチョウ",
    cityId: 28,
  },
  {
    id: 28481,
    name1: "赤穂郡上郡町",
    name2: "アコウグンカミゴオリチョウ",
    cityId: 28,
  },
  {
    id: 28501,
    name1: "佐用郡佐用町",
    name2: "サヨウグンサヨウチョウ",
    cityId: 28,
  },
  {
    id: 28585,
    name1: "美方郡香美町",
    name2: "ミカタグンカミチョウ",
    cityId: 28,
  },
  {
    id: 28586,
    name1: "美方郡新温泉町",
    name2: "ミカタグンシンオンセンチョウ",
    cityId: 28,
  },
  {
    id: 29201,
    name1: "奈良市",
    name2: "ナラシ",
    cityId: 29,
  },
  {
    id: 29202,
    name1: "大和高田市",
    name2: "ヤマトタカダシ",
    cityId: 29,
  },
  {
    id: 29203,
    name1: "大和郡山市",
    name2: "ヤマトコオリヤマシ",
    cityId: 29,
  },
  {
    id: 29204,
    name1: "天理市",
    name2: "テンリシ",
    cityId: 29,
  },
  {
    id: 29205,
    name1: "橿原市",
    name2: "カシハラシ",
    cityId: 29,
  },
  {
    id: 29206,
    name1: "桜井市",
    name2: "サクライシ",
    cityId: 29,
  },
  {
    id: 29207,
    name1: "五條市",
    name2: "ゴジョウシ",
    cityId: 29,
  },
  {
    id: 29208,
    name1: "御所市",
    name2: "ゴセシ",
    cityId: 29,
  },
  {
    id: 29209,
    name1: "生駒市",
    name2: "イコマシ",
    cityId: 29,
  },
  {
    id: 29210,
    name1: "香芝市",
    name2: "カシバシ",
    cityId: 29,
  },
  {
    id: 29211,
    name1: "葛城市",
    name2: "カツラギシ",
    cityId: 29,
  },
  {
    id: 29212,
    name1: "宇陀市",
    name2: "ウダシ",
    cityId: 29,
  },
  {
    id: 29322,
    name1: "山辺郡山添村",
    name2: "ヤマベグンヤマゾエムラ",
    cityId: 29,
  },
  {
    id: 29342,
    name1: "生駒郡平群町",
    name2: "イコマグンヘグリチョウ",
    cityId: 29,
  },
  {
    id: 29343,
    name1: "生駒郡三郷町",
    name2: "イコマグンサンゴウチョウ",
    cityId: 29,
  },
  {
    id: 29344,
    name1: "生駒郡斑鳩町",
    name2: "イコマグンイカルガチョウ",
    cityId: 29,
  },
  {
    id: 29345,
    name1: "生駒郡安堵町",
    name2: "イコマグンアンドチョウ",
    cityId: 29,
  },
  {
    id: 29361,
    name1: "磯城郡川西町",
    name2: "シキグンカワニシチョウ",
    cityId: 29,
  },
  {
    id: 29362,
    name1: "磯城郡三宅町",
    name2: "シキグンミヤケチョウ",
    cityId: 29,
  },
  {
    id: 29363,
    name1: "磯城郡田原本町",
    name2: "シキグンタワラモトチョウ",
    cityId: 29,
  },
  {
    id: 29385,
    name1: "宇陀郡曽爾村",
    name2: "ウダグンソニムラ",
    cityId: 29,
  },
  {
    id: 29386,
    name1: "宇陀郡御杖村",
    name2: "ウダグンミツエムラ",
    cityId: 29,
  },
  {
    id: 29401,
    name1: "高市郡高取町",
    name2: "タカイチグンタカトリチョウ",
    cityId: 29,
  },
  {
    id: 29402,
    name1: "高市郡明日香村",
    name2: "タカイチグンアスカムラ",
    cityId: 29,
  },
  {
    id: 29424,
    name1: "北葛城郡上牧町",
    name2: "キタカツラギグンカンマキチョウ",
    cityId: 29,
  },
  {
    id: 29425,
    name1: "北葛城郡王寺町",
    name2: "キタカツラギグンオウジチョウ",
    cityId: 29,
  },
  {
    id: 29426,
    name1: "北葛城郡広陵町",
    name2: "キタカツラギグンコウリョウチョウ",
    cityId: 29,
  },
  {
    id: 29427,
    name1: "北葛城郡河合町",
    name2: "キタカツラギグンカワイチョウ",
    cityId: 29,
  },
  {
    id: 29441,
    name1: "吉野郡吉野町",
    name2: "ヨシノグンヨシノチョウ",
    cityId: 29,
  },
  {
    id: 29442,
    name1: "吉野郡大淀町",
    name2: "ヨシノグンオオヨドチョウ",
    cityId: 29,
  },
  {
    id: 29443,
    name1: "吉野郡下市町",
    name2: "ヨシノグンシモイチチョウ",
    cityId: 29,
  },
  {
    id: 29444,
    name1: "吉野郡黒滝村",
    name2: "ヨシノグンクロタキムラ",
    cityId: 29,
  },
  {
    id: 29446,
    name1: "吉野郡天川村",
    name2: "ヨシノグンテンカワムラ",
    cityId: 29,
  },
  {
    id: 29447,
    name1: "吉野郡野迫川村",
    name2: "ヨシノグンノセガワムラ",
    cityId: 29,
  },
  {
    id: 29449,
    name1: "吉野郡十津川村",
    name2: "ヨシノグントツカワムラ",
    cityId: 29,
  },
  {
    id: 29450,
    name1: "吉野郡下北山村",
    name2: "ヨシノグンシモキタヤマムラ",
    cityId: 29,
  },
  {
    id: 29451,
    name1: "吉野郡上北山村",
    name2: "ヨシノグンカミキタヤマムラ",
    cityId: 29,
  },
  {
    id: 29452,
    name1: "吉野郡川上村",
    name2: "ヨシノグンカワカミムラ",
    cityId: 29,
  },
  {
    id: 29453,
    name1: "吉野郡東吉野村",
    name2: "ヨシノグンヒガシヨシノムラ",
    cityId: 29,
  },
  {
    id: 30201,
    name1: "和歌山市",
    name2: "ワカヤマシ",
    cityId: 30,
  },
  {
    id: 30202,
    name1: "海南市",
    name2: "カイナンシ",
    cityId: 30,
  },
  {
    id: 30203,
    name1: "橋本市",
    name2: "ハシモトシ",
    cityId: 30,
  },
  {
    id: 30204,
    name1: "有田市",
    name2: "アリダシ",
    cityId: 30,
  },
  {
    id: 30205,
    name1: "御坊市",
    name2: "ゴボウシ",
    cityId: 30,
  },
  {
    id: 30206,
    name1: "田辺市",
    name2: "タナベシ",
    cityId: 30,
  },
  {
    id: 30207,
    name1: "新宮市",
    name2: "シングウシ",
    cityId: 30,
  },
  {
    id: 30208,
    name1: "紀の川市",
    name2: "キノカワシ",
    cityId: 30,
  },
  {
    id: 30209,
    name1: "岩出市",
    name2: "イワデシ",
    cityId: 30,
  },
  {
    id: 30304,
    name1: "海草郡紀美野町",
    name2: "カイソウグンキミノチョウ",
    cityId: 30,
  },
  {
    id: 30341,
    name1: "伊都郡かつらぎ町",
    name2: "イトグンカツラギチョウ",
    cityId: 30,
  },
  {
    id: 30343,
    name1: "伊都郡九度山町",
    name2: "イトグンクドヤマチョウ",
    cityId: 30,
  },
  {
    id: 30344,
    name1: "伊都郡高野町",
    name2: "イトグンコウヤチョウ",
    cityId: 30,
  },
  {
    id: 30361,
    name1: "有田郡湯浅町",
    name2: "アリダグンユアサチョウ",
    cityId: 30,
  },
  {
    id: 30362,
    name1: "有田郡広川町",
    name2: "アリダグンヒロガワチョウ",
    cityId: 30,
  },
  {
    id: 30366,
    name1: "有田郡有田川町",
    name2: "アリダグンアリダガワチョウ",
    cityId: 30,
  },
  {
    id: 30381,
    name1: "日高郡美浜町",
    name2: "ヒダカグンミハマチョウ",
    cityId: 30,
  },
  {
    id: 30382,
    name1: "日高郡日高町",
    name2: "ヒダカグンヒダカチョウ",
    cityId: 30,
  },
  {
    id: 30383,
    name1: "日高郡由良町",
    name2: "ヒダカグンユラチョウ",
    cityId: 30,
  },
  {
    id: 30390,
    name1: "日高郡印南町",
    name2: "ヒダカグンイナミチョウ",
    cityId: 30,
  },
  {
    id: 30391,
    name1: "日高郡みなべ町",
    name2: "ヒダカグンミナベチョウ",
    cityId: 30,
  },
  {
    id: 30392,
    name1: "日高郡日高川町",
    name2: "ヒダカグンヒダカガワチョウ",
    cityId: 30,
  },
  {
    id: 30401,
    name1: "西牟婁郡白浜町",
    name2: "ニシムログンシラハマチョウ",
    cityId: 30,
  },
  {
    id: 30404,
    name1: "西牟婁郡上富田町",
    name2: "ニシムログンカミトンダチョウ",
    cityId: 30,
  },
  {
    id: 30406,
    name1: "西牟婁郡すさみ町",
    name2: "ニシムログンスサミチョウ",
    cityId: 30,
  },
  {
    id: 30421,
    name1: "東牟婁郡那智勝浦町",
    name2: "ヒガシムログンナチカツウラチョウ",
    cityId: 30,
  },
  {
    id: 30422,
    name1: "東牟婁郡太地町",
    name2: "ヒガシムログンタイジチョウ",
    cityId: 30,
  },
  {
    id: 30424,
    name1: "東牟婁郡古座川町",
    name2: "ヒガシムログンコザガワチョウ",
    cityId: 30,
  },
  {
    id: 30427,
    name1: "東牟婁郡北山村",
    name2: "ヒガシムログンキタヤマムラ",
    cityId: 30,
  },
  {
    id: 30428,
    name1: "東牟婁郡串本町",
    name2: "ヒガシムログンクシモトチョウ",
    cityId: 30,
  },
  {
    id: 31201,
    name1: "鳥取市",
    name2: "トットリシ",
    cityId: 31,
  },
  {
    id: 31202,
    name1: "米子市",
    name2: "ヨナゴシ",
    cityId: 31,
  },
  {
    id: 31203,
    name1: "倉吉市",
    name2: "クラヨシシ",
    cityId: 31,
  },
  {
    id: 31204,
    name1: "境港市",
    name2: "サカイミナトシ",
    cityId: 31,
  },
  {
    id: 31302,
    name1: "岩美郡岩美町",
    name2: "イワミグンイワミチョウ",
    cityId: 31,
  },
  {
    id: 31325,
    name1: "八頭郡若桜町",
    name2: "ヤズグンワカサチョウ",
    cityId: 31,
  },
  {
    id: 31328,
    name1: "八頭郡智頭町",
    name2: "ヤズグンチヅチョウ",
    cityId: 31,
  },
  {
    id: 31329,
    name1: "八頭郡八頭町",
    name2: "ヤズグンヤズチョウ",
    cityId: 31,
  },
  {
    id: 31364,
    name1: "東伯郡三朝町",
    name2: "トウハクグンミササチョウ",
    cityId: 31,
  },
  {
    id: 31370,
    name1: "東伯郡湯梨浜町",
    name2: "トウハクグンユリハマチョウ",
    cityId: 31,
  },
  {
    id: 31371,
    name1: "東伯郡琴浦町",
    name2: "トウハクグンコトウラチョウ",
    cityId: 31,
  },
  {
    id: 31372,
    name1: "東伯郡北栄町",
    name2: "トウハクグンホクエイチョウ",
    cityId: 31,
  },
  {
    id: 31384,
    name1: "西伯郡日吉津村",
    name2: "サイハクグンヒエヅソン",
    cityId: 31,
  },
  {
    id: 31386,
    name1: "西伯郡大山町",
    name2: "サイハクグンダイセンチョウ",
    cityId: 31,
  },
  {
    id: 31389,
    name1: "西伯郡南部町",
    name2: "サイハクグンナンブチョウ",
    cityId: 31,
  },
  {
    id: 31390,
    name1: "西伯郡伯耆町",
    name2: "サイハクグンホウキチョウ",
    cityId: 31,
  },
  {
    id: 31401,
    name1: "日野郡日南町",
    name2: "ヒノグンニチナンチョウ",
    cityId: 31,
  },
  {
    id: 31402,
    name1: "日野郡日野町",
    name2: "ヒノグンヒノチョウ",
    cityId: 31,
  },
  {
    id: 31403,
    name1: "日野郡江府町",
    name2: "ヒノグンコウフチョウ",
    cityId: 31,
  },
  {
    id: 32201,
    name1: "松江市",
    name2: "マツエシ",
    cityId: 32,
  },
  {
    id: 32202,
    name1: "浜田市",
    name2: "ハマダシ",
    cityId: 32,
  },
  {
    id: 32203,
    name1: "出雲市",
    name2: "イズモシ",
    cityId: 32,
  },
  {
    id: 32204,
    name1: "益田市",
    name2: "マスダシ",
    cityId: 32,
  },
  {
    id: 32205,
    name1: "大田市",
    name2: "オオダシ",
    cityId: 32,
  },
  {
    id: 32206,
    name1: "安来市",
    name2: "ヤスギシ",
    cityId: 32,
  },
  {
    id: 32207,
    name1: "江津市",
    name2: "ゴウツシ",
    cityId: 32,
  },
  {
    id: 32209,
    name1: "雲南市",
    name2: "ウンナンシ",
    cityId: 32,
  },
  {
    id: 32304,
    name1: "八束郡東出雲町",
    name2: "ヤツカグンヒガシイズモチョウ",
    cityId: 32,
  },
  {
    id: 32343,
    name1: "仁多郡奥出雲町",
    name2: "ニタグンオクイズモチョウ",
    cityId: 32,
  },
  {
    id: 32386,
    name1: "飯石郡飯南町",
    name2: "イイシグンイイナンチョウ",
    cityId: 32,
  },
  {
    id: 32401,
    name1: "簸川郡斐川町",
    name2: "ヒカワグンヒカワチョウ",
    cityId: 32,
  },
  {
    id: 32441,
    name1: "邑智郡川本町",
    name2: "オオチグンカワモトマチ",
    cityId: 32,
  },
  {
    id: 32448,
    name1: "邑智郡美郷町",
    name2: "オオチグンミサトチョウ",
    cityId: 32,
  },
  {
    id: 32449,
    name1: "邑智郡邑南町",
    name2: "オオチグンオオナンチョウ",
    cityId: 32,
  },
  {
    id: 32501,
    name1: "鹿足郡津和野町",
    name2: "カノアシグンツワノチョウ",
    cityId: 32,
  },
  {
    id: 32505,
    name1: "鹿足郡吉賀町",
    name2: "カノアシグンヨシカチョウ",
    cityId: 32,
  },
  {
    id: 32525,
    name1: "隠岐郡海士町",
    name2: "オキグンアマチョウ",
    cityId: 32,
  },
  {
    id: 32526,
    name1: "隠岐郡西ノ島町",
    name2: "オキグンニシノシマチョウ",
    cityId: 32,
  },
  {
    id: 32527,
    name1: "隠岐郡知夫村",
    name2: "オキグンチブムラ",
    cityId: 32,
  },
  {
    id: 32528,
    name1: "隠岐郡隠岐の島町",
    name2: "オキグンオキノシマチョウ",
    cityId: 32,
  },
  {
    id: 33101,
    name1: "岡山市北区",
    name2: "オカヤマシキタク",
    cityId: 33,
  },
  {
    id: 33102,
    name1: "岡山市中区",
    name2: "オカヤマシナカク",
    cityId: 33,
  },
  {
    id: 33103,
    name1: "岡山市東区",
    name2: "オカヤマシヒガシク",
    cityId: 33,
  },
  {
    id: 33104,
    name1: "岡山市南区",
    name2: "オカヤマシミナミク",
    cityId: 33,
  },
  {
    id: 33202,
    name1: "倉敷市",
    name2: "クラシキシ",
    cityId: 33,
  },
  {
    id: 33203,
    name1: "津山市",
    name2: "ツヤマシ",
    cityId: 33,
  },
  {
    id: 33204,
    name1: "玉野市",
    name2: "タマノシ",
    cityId: 33,
  },
  {
    id: 33205,
    name1: "笠岡市",
    name2: "カサオカシ",
    cityId: 33,
  },
  {
    id: 33207,
    name1: "井原市",
    name2: "イバラシ",
    cityId: 33,
  },
  {
    id: 33208,
    name1: "総社市",
    name2: "ソウジャシ",
    cityId: 33,
  },
  {
    id: 33209,
    name1: "高梁市",
    name2: "タカハシシ",
    cityId: 33,
  },
  {
    id: 33210,
    name1: "新見市",
    name2: "ニイミシ",
    cityId: 33,
  },
  {
    id: 33211,
    name1: "備前市",
    name2: "ビゼンシ",
    cityId: 33,
  },
  {
    id: 33212,
    name1: "瀬戸内市",
    name2: "セトウチシ",
    cityId: 33,
  },
  {
    id: 33213,
    name1: "赤磐市",
    name2: "アカイワシ",
    cityId: 33,
  },
  {
    id: 33214,
    name1: "真庭市",
    name2: "マニワシ",
    cityId: 33,
  },
  {
    id: 33215,
    name1: "美作市",
    name2: "ミマサカシ",
    cityId: 33,
  },
  {
    id: 33216,
    name1: "浅口市",
    name2: "アサクチシ",
    cityId: 33,
  },
  {
    id: 33346,
    name1: "和気郡和気町",
    name2: "ワケグンワケチョウ",
    cityId: 33,
  },
  {
    id: 33423,
    name1: "都窪郡早島町",
    name2: "ツクボグンハヤシマチョウ",
    cityId: 33,
  },
  {
    id: 33445,
    name1: "浅口郡里庄町",
    name2: "アサクチグンサトショウチョウ",
    cityId: 33,
  },
  {
    id: 33461,
    name1: "小田郡矢掛町",
    name2: "オダグンヤカゲチョウ",
    cityId: 33,
  },
  {
    id: 33586,
    name1: "真庭郡新庄村",
    name2: "マニワグンシンジョウソン",
    cityId: 33,
  },
  {
    id: 33606,
    name1: "苫田郡鏡野町",
    name2: "トマタグンカガミノチョウ",
    cityId: 33,
  },
  {
    id: 33622,
    name1: "勝田郡勝央町",
    name2: "カツタグンショウオウチョウ",
    cityId: 33,
  },
  {
    id: 33623,
    name1: "勝田郡奈義町",
    name2: "カツタグンナギチョウ",
    cityId: 33,
  },
  {
    id: 33643,
    name1: "英田郡西粟倉村",
    name2: "アイダグンニシアワクラソン",
    cityId: 33,
  },
  {
    id: 33663,
    name1: "久米郡久米南町",
    name2: "クメグンクメナンチョウ",
    cityId: 33,
  },
  {
    id: 33666,
    name1: "久米郡美咲町",
    name2: "クメグンミサキチョウ",
    cityId: 33,
  },
  {
    id: 33681,
    name1: "加賀郡吉備中央町",
    name2: "カガグンキビチュウオウチョウ",
    cityId: 33,
  },
  {
    id: 34101,
    name1: "広島市中区",
    name2: "ヒロシマシナカク",
    cityId: 34,
  },
  {
    id: 34102,
    name1: "広島市東区",
    name2: "ヒロシマシヒガシク",
    cityId: 34,
  },
  {
    id: 34103,
    name1: "広島市南区",
    name2: "ヒロシマシミナミク",
    cityId: 34,
  },
  {
    id: 34104,
    name1: "広島市西区",
    name2: "ヒロシマシニシク",
    cityId: 34,
  },
  {
    id: 34105,
    name1: "広島市安佐南区",
    name2: "ヒロシマシアサミナミク",
    cityId: 34,
  },
  {
    id: 34106,
    name1: "広島市安佐北区",
    name2: "ヒロシマシアサキタク",
    cityId: 34,
  },
  {
    id: 34107,
    name1: "広島市安芸区",
    name2: "ヒロシマシアキク",
    cityId: 34,
  },
  {
    id: 34108,
    name1: "広島市佐伯区",
    name2: "ヒロシマシサエキク",
    cityId: 34,
  },
  {
    id: 34202,
    name1: "呉市",
    name2: "クレシ",
    cityId: 34,
  },
  {
    id: 34203,
    name1: "竹原市",
    name2: "タケハラシ",
    cityId: 34,
  },
  {
    id: 34204,
    name1: "三原市",
    name2: "ミハラシ",
    cityId: 34,
  },
  {
    id: 34205,
    name1: "尾道市",
    name2: "オノミチシ",
    cityId: 34,
  },
  {
    id: 34207,
    name1: "福山市",
    name2: "フクヤマシ",
    cityId: 34,
  },
  {
    id: 34208,
    name1: "府中市",
    name2: "フチュウシ",
    cityId: 34,
  },
  {
    id: 34209,
    name1: "三次市",
    name2: "ミヨシシ",
    cityId: 34,
  },
  {
    id: 34210,
    name1: "庄原市",
    name2: "ショウバラシ",
    cityId: 34,
  },
  {
    id: 34211,
    name1: "大竹市",
    name2: "オオタケシ",
    cityId: 34,
  },
  {
    id: 34212,
    name1: "東広島市",
    name2: "ヒガシヒロシマシ",
    cityId: 34,
  },
  {
    id: 34213,
    name1: "廿日市市",
    name2: "ハツカイチシ",
    cityId: 34,
  },
  {
    id: 34214,
    name1: "安芸高田市",
    name2: "アキタカタシ",
    cityId: 34,
  },
  {
    id: 34215,
    name1: "江田島市",
    name2: "エタジマシ",
    cityId: 34,
  },
  {
    id: 34302,
    name1: "安芸郡府中町",
    name2: "アキグンフチュウチョウ",
    cityId: 34,
  },
  {
    id: 34304,
    name1: "安芸郡海田町",
    name2: "アキグンカイタチョウ",
    cityId: 34,
  },
  {
    id: 34307,
    name1: "安芸郡熊野町",
    name2: "アキグンクマノチョウ",
    cityId: 34,
  },
  {
    id: 34309,
    name1: "安芸郡坂町",
    name2: "アキグンサカチョウ",
    cityId: 34,
  },
  {
    id: 34368,
    name1: "山県郡安芸太田町",
    name2: "ヤマガタグンアキオオタチョウ",
    cityId: 34,
  },
  {
    id: 34369,
    name1: "山県郡北広島町",
    name2: "ヤマガタグンキタヒロシマチョウ",
    cityId: 34,
  },
  {
    id: 34431,
    name1: "豊田郡大崎上島町",
    name2: "トヨタグンオオサキカミジマチョウ",
    cityId: 34,
  },
  {
    id: 34462,
    name1: "世羅郡世羅町",
    name2: "セラグンセラチョウ",
    cityId: 34,
  },
  {
    id: 34545,
    name1: "神石郡神石高原町",
    name2: "ジンセキグンジンセキコウゲンチョウ",
    cityId: 34,
  },
  {
    id: 35201,
    name1: "下関市",
    name2: "シモノセキシ",
    cityId: 35,
  },
  {
    id: 35202,
    name1: "宇部市",
    name2: "ウベシ",
    cityId: 35,
  },
  {
    id: 35203,
    name1: "山口市",
    name2: "ヤマグチシ",
    cityId: 35,
  },
  {
    id: 35204,
    name1: "萩市",
    name2: "ハギシ",
    cityId: 35,
  },
  {
    id: 35206,
    name1: "防府市",
    name2: "ホウフシ",
    cityId: 35,
  },
  {
    id: 35207,
    name1: "下松市",
    name2: "クダマツシ",
    cityId: 35,
  },
  {
    id: 35208,
    name1: "岩国市",
    name2: "イワクニシ",
    cityId: 35,
  },
  {
    id: 35210,
    name1: "光市",
    name2: "ヒカリシ",
    cityId: 35,
  },
  {
    id: 35211,
    name1: "長門市",
    name2: "ナガトシ",
    cityId: 35,
  },
  {
    id: 35212,
    name1: "柳井市",
    name2: "ヤナイシ",
    cityId: 35,
  },
  {
    id: 35213,
    name1: "美祢市",
    name2: "ミネシ",
    cityId: 35,
  },
  {
    id: 35215,
    name1: "周南市",
    name2: "シュウナンシ",
    cityId: 35,
  },
  {
    id: 35216,
    name1: "山陽小野田市",
    name2: "サンヨウオノダシ",
    cityId: 35,
  },
  {
    id: 35305,
    name1: "大島郡周防大島町",
    name2: "オオシマグンスオウオオシマチョウ",
    cityId: 35,
  },
  {
    id: 35321,
    name1: "玖珂郡和木町",
    name2: "クガグンワキチョウ",
    cityId: 35,
  },
  {
    id: 35341,
    name1: "熊毛郡上関町",
    name2: "クマゲグンカミノセキチョウ",
    cityId: 35,
  },
  {
    id: 35343,
    name1: "熊毛郡田布施町",
    name2: "クマゲグンタブセチョウ",
    cityId: 35,
  },
  {
    id: 35344,
    name1: "熊毛郡平生町",
    name2: "クマゲグンヒラオチョウ",
    cityId: 35,
  },
  {
    id: 35502,
    name1: "阿武郡阿武町",
    name2: "アブグンアブチョウ",
    cityId: 35,
  },
  {
    id: 35504,
    name1: "阿武郡阿東町",
    name2: "アブグンアトウチョウ",
    cityId: 35,
  },
  {
    id: 36201,
    name1: "徳島市",
    name2: "トクシマシ",
    cityId: 36,
  },
  {
    id: 36202,
    name1: "鳴門市",
    name2: "ナルトシ",
    cityId: 36,
  },
  {
    id: 36203,
    name1: "小松島市",
    name2: "コマツシマシ",
    cityId: 36,
  },
  {
    id: 36204,
    name1: "阿南市",
    name2: "アナンシ",
    cityId: 36,
  },
  {
    id: 36205,
    name1: "吉野川市",
    name2: "ヨシノガワシ",
    cityId: 36,
  },
  {
    id: 36206,
    name1: "阿波市",
    name2: "アワシ",
    cityId: 36,
  },
  {
    id: 36207,
    name1: "美馬市",
    name2: "ミマシ",
    cityId: 36,
  },
  {
    id: 36208,
    name1: "三好市",
    name2: "ミヨシシ",
    cityId: 36,
  },
  {
    id: 36301,
    name1: "勝浦郡勝浦町",
    name2: "カツウラグンカツウラチョウ",
    cityId: 36,
  },
  {
    id: 36302,
    name1: "勝浦郡上勝町",
    name2: "カツウラグンカミカツチョウ",
    cityId: 36,
  },
  {
    id: 36321,
    name1: "名東郡佐那河内村",
    name2: "ミョウドウグンサナゴウチソン",
    cityId: 36,
  },
  {
    id: 36341,
    name1: "名西郡石井町",
    name2: "ミョウザイグンイシイチョウ",
    cityId: 36,
  },
  {
    id: 36342,
    name1: "名西郡神山町",
    name2: "ミョウザイグンカミヤマチョウ",
    cityId: 36,
  },
  {
    id: 36368,
    name1: "那賀郡那賀町",
    name2: "ナカグンナカチョウ",
    cityId: 36,
  },
  {
    id: 36383,
    name1: "海部郡牟岐町",
    name2: "カイフグンムギチョウ",
    cityId: 36,
  },
  {
    id: 36387,
    name1: "海部郡美波町",
    name2: "カイフグンミナミチョウ",
    cityId: 36,
  },
  {
    id: 36388,
    name1: "海部郡海陽町",
    name2: "カイフグンカイヨウチョウ",
    cityId: 36,
  },
  {
    id: 36401,
    name1: "板野郡松茂町",
    name2: "イタノグンマツシゲチョウ",
    cityId: 36,
  },
  {
    id: 36402,
    name1: "板野郡北島町",
    name2: "イタノグンキタジマチョウ",
    cityId: 36,
  },
  {
    id: 36403,
    name1: "板野郡藍住町",
    name2: "イタノグンアイズミチョウ",
    cityId: 36,
  },
  {
    id: 36404,
    name1: "板野郡板野町",
    name2: "イタノグンイタノチョウ",
    cityId: 36,
  },
  {
    id: 36405,
    name1: "板野郡上板町",
    name2: "イタノグンカミイタチョウ",
    cityId: 36,
  },
  {
    id: 36468,
    name1: "美馬郡つるぎ町",
    name2: "ミマグンツルギチョウ",
    cityId: 36,
  },
  {
    id: 36489,
    name1: "三好郡東みよし町",
    name2: "ミヨシグンヒガシミヨシチョウ",
    cityId: 36,
  },
  {
    id: 37201,
    name1: "高松市",
    name2: "タカマツシ",
    cityId: 37,
  },
  {
    id: 37202,
    name1: "丸亀市",
    name2: "マルガメシ",
    cityId: 37,
  },
  {
    id: 37203,
    name1: "坂出市",
    name2: "サカイデシ",
    cityId: 37,
  },
  {
    id: 37204,
    name1: "善通寺市",
    name2: "ゼンツウジシ",
    cityId: 37,
  },
  {
    id: 37205,
    name1: "観音寺市",
    name2: "カンオンジシ",
    cityId: 37,
  },
  {
    id: 37206,
    name1: "さぬき市",
    name2: "サヌキシ",
    cityId: 37,
  },
  {
    id: 37207,
    name1: "東かがわ市",
    name2: "ヒガシカガワシ",
    cityId: 37,
  },
  {
    id: 37208,
    name1: "三豊市",
    name2: "ミトヨシ",
    cityId: 37,
  },
  {
    id: 37322,
    name1: "小豆郡土庄町",
    name2: "ショウズグントノショウチョウ",
    cityId: 37,
  },
  {
    id: 37324,
    name1: "小豆郡小豆島町",
    name2: "ショウズグンショウドシマチョウ",
    cityId: 37,
  },
  {
    id: 37341,
    name1: "木田郡三木町",
    name2: "キタグンミキチョウ",
    cityId: 37,
  },
  {
    id: 37364,
    name1: "香川郡直島町",
    name2: "カガワグンナオシマチョウ",
    cityId: 37,
  },
  {
    id: 37386,
    name1: "綾歌郡宇多津町",
    name2: "アヤウタグンウタヅチョウ",
    cityId: 37,
  },
  {
    id: 37387,
    name1: "綾歌郡綾川町",
    name2: "アヤウタグンアヤガワチョウ",
    cityId: 37,
  },
  {
    id: 37403,
    name1: "仲多度郡琴平町",
    name2: "ナカタドグンコトヒラチョウ",
    cityId: 37,
  },
  {
    id: 37404,
    name1: "仲多度郡多度津町",
    name2: "ナカタドグンタドツチョウ",
    cityId: 37,
  },
  {
    id: 37406,
    name1: "仲多度郡まんのう町",
    name2: "ナカタドグンマンノウチョウ",
    cityId: 37,
  },
  {
    id: 38201,
    name1: "松山市",
    name2: "マツヤマシ",
    cityId: 38,
  },
  {
    id: 38202,
    name1: "今治市",
    name2: "イマバリシ",
    cityId: 38,
  },
  {
    id: 38203,
    name1: "宇和島市",
    name2: "ウワジマシ",
    cityId: 38,
  },
  {
    id: 38204,
    name1: "八幡浜市",
    name2: "ヤワタハマシ",
    cityId: 38,
  },
  {
    id: 38205,
    name1: "新居浜市",
    name2: "ニイハマシ",
    cityId: 38,
  },
  {
    id: 38206,
    name1: "西条市",
    name2: "サイジョウシ",
    cityId: 38,
  },
  {
    id: 38207,
    name1: "大洲市",
    name2: "オオズシ",
    cityId: 38,
  },
  {
    id: 38210,
    name1: "伊予市",
    name2: "イヨシ",
    cityId: 38,
  },
  {
    id: 38213,
    name1: "四国中央市",
    name2: "シコクチュウオウシ",
    cityId: 38,
  },
  {
    id: 38214,
    name1: "西予市",
    name2: "セイヨシ",
    cityId: 38,
  },
  {
    id: 38215,
    name1: "東温市",
    name2: "トウオンシ",
    cityId: 38,
  },
  {
    id: 38356,
    name1: "越智郡上島町",
    name2: "オチグンカミジマチョウ",
    cityId: 38,
  },
  {
    id: 38386,
    name1: "上浮穴郡久万高原町",
    name2: "カミウケナグンクマコウゲンチョウ",
    cityId: 38,
  },
  {
    id: 38401,
    name1: "伊予郡松前町",
    name2: "イヨグンマサキチョウ",
    cityId: 38,
  },
  {
    id: 38402,
    name1: "伊予郡砥部町",
    name2: "イヨグントベチョウ",
    cityId: 38,
  },
  {
    id: 38422,
    name1: "喜多郡内子町",
    name2: "キタグンウチコチョウ",
    cityId: 38,
  },
  {
    id: 38442,
    name1: "西宇和郡伊方町",
    name2: "ニシウワグンイカタチョウ",
    cityId: 38,
  },
  {
    id: 38484,
    name1: "北宇和郡松野町",
    name2: "キタウワグンマツノチョウ",
    cityId: 38,
  },
  {
    id: 38488,
    name1: "北宇和郡鬼北町",
    name2: "キタウワグンキホクチョウ",
    cityId: 38,
  },
  {
    id: 38506,
    name1: "南宇和郡愛南町",
    name2: "ミナミウワグンアイナンチョウ",
    cityId: 38,
  },
  {
    id: 39201,
    name1: "高知市",
    name2: "コウチシ",
    cityId: 39,
  },
  {
    id: 39202,
    name1: "室戸市",
    name2: "ムロトシ",
    cityId: 39,
  },
  {
    id: 39203,
    name1: "安芸市",
    name2: "アキシ",
    cityId: 39,
  },
  {
    id: 39204,
    name1: "南国市",
    name2: "ナンコクシ",
    cityId: 39,
  },
  {
    id: 39205,
    name1: "土佐市",
    name2: "トサシ",
    cityId: 39,
  },
  {
    id: 39206,
    name1: "須崎市",
    name2: "スサキシ",
    cityId: 39,
  },
  {
    id: 39208,
    name1: "宿毛市",
    name2: "スクモシ",
    cityId: 39,
  },
  {
    id: 39209,
    name1: "土佐清水市",
    name2: "トサシミズシ",
    cityId: 39,
  },
  {
    id: 39210,
    name1: "四万十市",
    name2: "シマントシ",
    cityId: 39,
  },
  {
    id: 39211,
    name1: "香南市",
    name2: "コウナンシ",
    cityId: 39,
  },
  {
    id: 39212,
    name1: "香美市",
    name2: "カミシ",
    cityId: 39,
  },
  {
    id: 39301,
    name1: "安芸郡東洋町",
    name2: "アキグントウヨウチョウ",
    cityId: 39,
  },
  {
    id: 39302,
    name1: "安芸郡奈半利町",
    name2: "アキグンナハリチョウ",
    cityId: 39,
  },
  {
    id: 39303,
    name1: "安芸郡田野町",
    name2: "アキグンタノチョウ",
    cityId: 39,
  },
  {
    id: 39304,
    name1: "安芸郡安田町",
    name2: "アキグンヤスダチョウ",
    cityId: 39,
  },
  {
    id: 39305,
    name1: "安芸郡北川村",
    name2: "アキグンキタガワムラ",
    cityId: 39,
  },
  {
    id: 39306,
    name1: "安芸郡馬路村",
    name2: "アキグンウマジムラ",
    cityId: 39,
  },
  {
    id: 39307,
    name1: "安芸郡芸西村",
    name2: "アキグンゲイセイムラ",
    cityId: 39,
  },
  {
    id: 39341,
    name1: "長岡郡本山町",
    name2: "ナガオカグンモトヤマチョウ",
    cityId: 39,
  },
  {
    id: 39344,
    name1: "長岡郡大豊町",
    name2: "ナガオカグンオオトヨチョウ",
    cityId: 39,
  },
  {
    id: 39363,
    name1: "土佐郡土佐町",
    name2: "トサグントサチョウ",
    cityId: 39,
  },
  {
    id: 39364,
    name1: "土佐郡大川村",
    name2: "トサグンオオカワムラ",
    cityId: 39,
  },
  {
    id: 39386,
    name1: "吾川郡いの町",
    name2: "アガワグンイノチョウ",
    cityId: 39,
  },
  {
    id: 39387,
    name1: "吾川郡仁淀川町",
    name2: "アガワグンニヨドガワチョウ",
    cityId: 39,
  },
  {
    id: 39401,
    name1: "高岡郡中土佐町",
    name2: "タカオカグンナカトサチョウ",
    cityId: 39,
  },
  {
    id: 39402,
    name1: "高岡郡佐川町",
    name2: "タカオカグンサカワチョウ",
    cityId: 39,
  },
  {
    id: 39403,
    name1: "高岡郡越知町",
    name2: "タカオカグンオチチョウ",
    cityId: 39,
  },
  {
    id: 39405,
    name1: "高岡郡檮原町",
    name2: "タカオカグンユスハラチョウ",
    cityId: 39,
  },
  {
    id: 39410,
    name1: "高岡郡日高村",
    name2: "タカオカグンヒダカムラ",
    cityId: 39,
  },
  {
    id: 39411,
    name1: "高岡郡津野町",
    name2: "タカオカグンツノチョウ",
    cityId: 39,
  },
  {
    id: 39412,
    name1: "高岡郡四万十町",
    name2: "タカオカグンシマントチョウ",
    cityId: 39,
  },
  {
    id: 39424,
    name1: "幡多郡大月町",
    name2: "ハタグンオオツキチョウ",
    cityId: 39,
  },
  {
    id: 39427,
    name1: "幡多郡三原村",
    name2: "ハタグンミハラムラ",
    cityId: 39,
  },
  {
    id: 39428,
    name1: "幡多郡黒潮町",
    name2: "ハタグンクロシオチョウ",
    cityId: 39,
  },
  {
    id: 40101,
    name1: "北九州市門司区",
    name2: "キタキュウシュウシモジク",
    cityId: 40,
  },
  {
    id: 40103,
    name1: "北九州市若松区",
    name2: "キタキュウシュウシワカマツク",
    cityId: 40,
  },
  {
    id: 40105,
    name1: "北九州市戸畑区",
    name2: "キタキュウシュウシトバタク",
    cityId: 40,
  },
  {
    id: 40106,
    name1: "北九州市小倉北区",
    name2: "キタキュウシュウシコクラキタク",
    cityId: 40,
  },
  {
    id: 40107,
    name1: "北九州市小倉南区",
    name2: "キタキュウシュウシコクラミナミク",
    cityId: 40,
  },
  {
    id: 40108,
    name1: "北九州市八幡東区",
    name2: "キタキュウシュウシヤハタヒガシク",
    cityId: 40,
  },
  {
    id: 40109,
    name1: "北九州市八幡西区",
    name2: "キタキュウシュウシヤハタニシク",
    cityId: 40,
  },

  {
    id: 40202,
    name1: "大牟田市",
    name2: "オオムタシ",
    cityId: 40,
  },
  {
    id: 40203,
    name1: "久留米市",
    name2: "クルメシ",
    cityId: 40,
  },
  {
    id: 40204,
    name1: "直方市",
    name2: "ノオガタシ",
    cityId: 40,
  },
  {
    id: 40205,
    name1: "飯塚市",
    name2: "イイヅカシ",
    cityId: 40,
  },
  {
    id: 40206,
    name1: "田川市",
    name2: "タガワシ",
    cityId: 40,
  },
  {
    id: 40207,
    name1: "柳川市",
    name2: "ヤナガワシ",
    cityId: 40,
  },
  {
    id: 40210,
    name1: "八女市",
    name2: "ヤメシ",
    cityId: 40,
  },
  {
    id: 40211,
    name1: "筑後市",
    name2: "チクゴシ",
    cityId: 40,
  },
  {
    id: 40212,
    name1: "大川市",
    name2: "オオカワシ",
    cityId: 40,
  },
  {
    id: 40213,
    name1: "行橋市",
    name2: "ユクハシシ",
    cityId: 40,
  },
  {
    id: 40214,
    name1: "豊前市",
    name2: "ブゼンシ",
    cityId: 40,
  },
  {
    id: 40215,
    name1: "中間市",
    name2: "ナカマシ",
    cityId: 40,
  },
  {
    id: 40216,
    name1: "小郡市",
    name2: "オゴオリシ",
    cityId: 40,
  },
  {
    id: 40217,
    name1: "筑紫野市",
    name2: "チクシノシ",
    cityId: 40,
  },
  {
    id: 40218,
    name1: "春日市",
    name2: "カスガシ",
    cityId: 40,
  },
  {
    id: 40219,
    name1: "大野城市",
    name2: "オオノジョウシ",
    cityId: 40,
  },
  {
    id: 40220,
    name1: "宗像市",
    name2: "ムナカタシ",
    cityId: 40,
  },
  {
    id: 40221,
    name1: "太宰府市",
    name2: "ダザイフシ",
    cityId: 40,
  },
  {
    id: 40223,
    name1: "古賀市",
    name2: "コガシ",
    cityId: 40,
  },
  {
    id: 40224,
    name1: "福津市",
    name2: "フクツシ",
    cityId: 40,
  },
  {
    id: 40225,
    name1: "うきは市",
    name2: "ウキハシ",
    cityId: 40,
  },
  {
    id: 40226,
    name1: "宮若市",
    name2: "ミヤワカシ",
    cityId: 40,
  },
  {
    id: 40227,
    name1: "嘉麻市",
    name2: "カマシ",
    cityId: 40,
  },
  {
    id: 40228,
    name1: "朝倉市",
    name2: "アサクラシ",
    cityId: 40,
  },
  {
    id: 40229,
    name1: "みやま市",
    name2: "ミヤマシ",
    cityId: 40,
  },
  {
    id: 40230,
    name1: "糸島市",
    name2: "イトシマシ",
    cityId: 40,
  },
  {
    id: 40305,
    name1: "筑紫郡那珂川町",
    name2: "チクシグンナカガワマチ",
    cityId: 40,
  },
  {
    id: 40341,
    name1: "糟屋郡宇美町",
    name2: "カスヤグンウミマチ",
    cityId: 40,
  },
  {
    id: 40342,
    name1: "糟屋郡篠栗町",
    name2: "カスヤグンササグリマチ",
    cityId: 40,
  },
  {
    id: 40343,
    name1: "糟屋郡志免町",
    name2: "カスヤグンシメマチ",
    cityId: 40,
  },
  {
    id: 40344,
    name1: "糟屋郡須惠町",
    name2: "カスヤグンスエマチ",
    cityId: 40,
  },
  {
    id: 40345,
    name1: "糟屋郡新宮町",
    name2: "カスヤグンシングウマチ",
    cityId: 40,
  },
  {
    id: 40348,
    name1: "糟屋郡久山町",
    name2: "カスヤグンヒサヤママチ",
    cityId: 40,
  },
  {
    id: 40349,
    name1: "糟屋郡粕屋町",
    name2: "カスヤグンカスヤマチ",
    cityId: 40,
  },
  {
    id: 40381,
    name1: "遠賀郡芦屋町",
    name2: "オンガグンアシヤマチ",
    cityId: 40,
  },
  {
    id: 40382,
    name1: "遠賀郡水巻町",
    name2: "オンガグンミズマキマチ",
    cityId: 40,
  },
  {
    id: 40383,
    name1: "遠賀郡岡垣町",
    name2: "オンガグンオカガキマチ",
    cityId: 40,
  },
  {
    id: 40384,
    name1: "遠賀郡遠賀町",
    name2: "オンガグンオンガチョウ",
    cityId: 40,
  },
  {
    id: 40401,
    name1: "鞍手郡小竹町",
    name2: "クラテグンコタケマチ",
    cityId: 40,
  },
  {
    id: 40402,
    name1: "鞍手郡鞍手町",
    name2: "クラテグンクラテマチ",
    cityId: 40,
  },
  {
    id: 40421,
    name1: "嘉穂郡桂川町",
    name2: "カホグンケイセンマチ",
    cityId: 40,
  },
  {
    id: 40447,
    name1: "朝倉郡筑前町",
    name2: "アサクラグンチクゼンマチ",
    cityId: 40,
  },
  {
    id: 40448,
    name1: "朝倉郡東峰村",
    name2: "アサクラグントウホウムラ",
    cityId: 40,
  },
  {
    id: 40503,
    name1: "三井郡大刀洗町",
    name2: "ミイグンタチアライマチ",
    cityId: 40,
  },
  {
    id: 40522,
    name1: "三潴郡大木町",
    name2: "ミズマグンオオキマチ",
    cityId: 40,
  },
  {
    id: 40544,
    name1: "八女郡広川町",
    name2: "ヤメグンヒロカワマチ",
    cityId: 40,
  },
  {
    id: 40601,
    name1: "田川郡香春町",
    name2: "タガワグンカワラマチ",
    cityId: 40,
  },
  {
    id: 40602,
    name1: "田川郡添田町",
    name2: "タガワグンソエダマチ",
    cityId: 40,
  },
  {
    id: 40604,
    name1: "田川郡糸田町",
    name2: "タガワグンイトダマチ",
    cityId: 40,
  },
  {
    id: 40605,
    name1: "田川郡川崎町",
    name2: "タガワグンカワサキマチ",
    cityId: 40,
  },
  {
    id: 40608,
    name1: "田川郡大任町",
    name2: "タガワグンオオトウマチ",
    cityId: 40,
  },
  {
    id: 40609,
    name1: "田川郡赤村",
    name2: "タガワグンアカムラ",
    cityId: 40,
  },
  {
    id: 40610,
    name1: "田川郡福智町",
    name2: "タガワグンフクチマチ",
    cityId: 40,
  },
  {
    id: 40621,
    name1: "京都郡苅田町",
    name2: "ミヤコグンカンダマチ",
    cityId: 40,
  },
  {
    id: 40625,
    name1: "京都郡みやこ町",
    name2: "ミヤコグンミヤコマチ",
    cityId: 40,
  },
  {
    id: 40642,
    name1: "築上郡吉富町",
    name2: "チクジョウグンヨシトミマチ",
    cityId: 40,
  },
  {
    id: 40646,
    name1: "築上郡上毛町",
    name2: "チクジョウグンコウゲマチ",
    cityId: 40,
  },
  {
    id: 40647,
    name1: "築上郡築上町",
    name2: "チクジョウグンチクジョウマチ",
    cityId: 40,
  },
  {
    id: 41201,
    name1: "佐賀市",
    name2: "サガシ",
    cityId: 41,
  },
  {
    id: 41202,
    name1: "唐津市",
    name2: "カラツシ",
    cityId: 41,
  },
  {
    id: 41203,
    name1: "鳥栖市",
    name2: "トスシ",
    cityId: 41,
  },
  {
    id: 41204,
    name1: "多久市",
    name2: "タクシ",
    cityId: 41,
  },
  {
    id: 41205,
    name1: "伊万里市",
    name2: "イマリシ",
    cityId: 41,
  },
  {
    id: 41206,
    name1: "武雄市",
    name2: "タケオシ",
    cityId: 41,
  },
  {
    id: 41207,
    name1: "鹿島市",
    name2: "カシマシ",
    cityId: 41,
  },
  {
    id: 41208,
    name1: "小城市",
    name2: "オギシ",
    cityId: 41,
  },
  {
    id: 41209,
    name1: "嬉野市",
    name2: "ウレシノシ",
    cityId: 41,
  },
  {
    id: 41210,
    name1: "神埼市",
    name2: "カンザキシ",
    cityId: 41,
  },
  {
    id: 41327,
    name1: "神埼郡吉野ヶ里町",
    name2: "カンザキグンヨシノガリチョウ",
    cityId: 41,
  },
  {
    id: 41341,
    name1: "三養基郡基山町",
    name2: "ミヤキグンキヤマチョウ",
    cityId: 41,
  },
  {
    id: 41345,
    name1: "三養基郡上峰町",
    name2: "ミヤキグンカミミネチョウ",
    cityId: 41,
  },
  {
    id: 41346,
    name1: "三養基郡みやき町",
    name2: "ミヤキグンミヤキチョウ",
    cityId: 41,
  },
  {
    id: 41387,
    name1: "東松浦郡玄海町",
    name2: "ヒガシマツウラグンゲンカイチョウ",
    cityId: 41,
  },
  {
    id: 41401,
    name1: "西松浦郡有田町",
    name2: "ニシマツウラグンアリタチョウ",
    cityId: 41,
  },
  {
    id: 41423,
    name1: "杵島郡大町町",
    name2: "キシマグンオオマチチョウ",
    cityId: 41,
  },
  {
    id: 41424,
    name1: "杵島郡江北町",
    name2: "キシマグンコウホクマチ",
    cityId: 41,
  },
  {
    id: 41425,
    name1: "杵島郡白石町",
    name2: "キシマグンシロイシチョウ",
    cityId: 41,
  },
  {
    id: 41441,
    name1: "藤津郡太良町",
    name2: "フジツグンタラチョウ",
    cityId: 41,
  },
  {
    id: 42201,
    name1: "長崎市",
    name2: "ナガサキシ",
    cityId: 42,
  },
  {
    id: 42202,
    name1: "佐世保市",
    name2: "サセボシ",
    cityId: 42,
  },
  {
    id: 42203,
    name1: "島原市",
    name2: "シマバラシ",
    cityId: 42,
  },
  {
    id: 42204,
    name1: "諫早市",
    name2: "イサハヤシ",
    cityId: 42,
  },
  {
    id: 42205,
    name1: "大村市",
    name2: "オオムラシ",
    cityId: 42,
  },
  {
    id: 42207,
    name1: "平戸市",
    name2: "ヒラドシ",
    cityId: 42,
  },
  {
    id: 42208,
    name1: "松浦市",
    name2: "マツウラシ",
    cityId: 42,
  },
  {
    id: 42209,
    name1: "対馬市",
    name2: "ツシマシ",
    cityId: 42,
  },
  {
    id: 42210,
    name1: "壱岐市",
    name2: "イキシ",
    cityId: 42,
  },
  {
    id: 42211,
    name1: "五島市",
    name2: "ゴトウシ",
    cityId: 42,
  },
  {
    id: 42212,
    name1: "西海市",
    name2: "サイカイシ",
    cityId: 42,
  },
  {
    id: 42213,
    name1: "雲仙市",
    name2: "ウンゼンシ",
    cityId: 42,
  },
  {
    id: 42214,
    name1: "南島原市",
    name2: "ミナミシマバラシ",
    cityId: 42,
  },
  {
    id: 42307,
    name1: "西彼杵郡長与町",
    name2: "ニシソノギグンナガヨチョウ",
    cityId: 42,
  },
  {
    id: 42308,
    name1: "西彼杵郡時津町",
    name2: "ニシソノギグントギツチョウ",
    cityId: 42,
  },
  {
    id: 42321,
    name1: "東彼杵郡東彼杵町",
    name2: "ヒガシソノギグンヒガシソノギチョウ",
    cityId: 42,
  },
  {
    id: 42322,
    name1: "東彼杵郡川棚町",
    name2: "ヒガシソノギグンカワタナチョウ",
    cityId: 42,
  },
  {
    id: 42323,
    name1: "東彼杵郡波佐見町",
    name2: "ヒガシソノギグンハサミチョウ",
    cityId: 42,
  },
  {
    id: 42383,
    name1: "北松浦郡小値賀町",
    name2: "キタマツウラグンオヂカチョウ",
    cityId: 42,
  },
  {
    id: 42388,
    name1: "北松浦郡江迎町",
    name2: "キタマツウラグンエムカエチョウ",
    cityId: 42,
  },
  {
    id: 42389,
    name1: "北松浦郡鹿町町",
    name2: "キタマツウラグンシカマチチョウ",
    cityId: 42,
  },
  {
    id: 42391,
    name1: "北松浦郡佐々町",
    name2: "キタマツウラグンサザチョウ",
    cityId: 42,
  },
  {
    id: 42411,
    name1: "南松浦郡新上五島町",
    name2: "ミナミマツウラグンシンカミゴトウチョウ",
    cityId: 42,
  },
  {
    id: 43101,
    name1: "熊本市中央区",
    name2: "クマモトシチュウオウク",
    cityId: 43,
  },
  {
    id: 43102,
    name1: "熊本市東区",
    name2: "クマモトシヒガシク",
    cityId: 43,
  },
  {
    id: 43103,
    name1: "熊本市西区",
    name2: "クマモトシニシク",
    cityId: 43,
  },
  {
    id: 43104,
    name1: "熊本市南区",
    name2: "クマモトシミナミク",
    cityId: 43,
  },
  {
    id: 43105,
    name1: "熊本市北区",
    name2: "クマモトシキタク",
    cityId: 43,
  },
  {
    id: 43201,
    name1: "熊本市",
    name2: "クマモトシ",
    cityId: 43,
  },
  {
    id: 43202,
    name1: "八代市",
    name2: "ヤツシロシ",
    cityId: 43,
  },
  {
    id: 43203,
    name1: "人吉市",
    name2: "ヒトヨシシ",
    cityId: 43,
  },
  {
    id: 43204,
    name1: "荒尾市",
    name2: "アラオシ",
    cityId: 43,
  },
  {
    id: 43205,
    name1: "水俣市",
    name2: "ミナマタシ",
    cityId: 43,
  },
  {
    id: 43206,
    name1: "玉名市",
    name2: "タマナシ",
    cityId: 43,
  },
  {
    id: 43208,
    name1: "山鹿市",
    name2: "ヤマガシ",
    cityId: 43,
  },
  {
    id: 43210,
    name1: "菊池市",
    name2: "キクチシ",
    cityId: 43,
  },
  {
    id: 43211,
    name1: "宇土市",
    name2: "ウトシ",
    cityId: 43,
  },
  {
    id: 43212,
    name1: "上天草市",
    name2: "カミアマクサシ",
    cityId: 43,
  },
  {
    id: 43213,
    name1: "宇城市",
    name2: "ウキシ",
    cityId: 43,
  },
  {
    id: 43214,
    name1: "阿蘇市",
    name2: "アソシ",
    cityId: 43,
  },
  {
    id: 43215,
    name1: "天草市",
    name2: "アマクサシ",
    cityId: 43,
  },
  {
    id: 43216,
    name1: "合志市",
    name2: "コウシシ",
    cityId: 43,
  },
  {
    id: 43341,
    name1: "下益城郡城南町",
    name2: "シモマシキグンジョウナンマチ",
    cityId: 43,
  },
  {
    id: 43348,
    name1: "下益城郡美里町",
    name2: "シモマシキグンミサトマチ",
    cityId: 43,
  },
  {
    id: 43364,
    name1: "玉名郡玉東町",
    name2: "タマナグンギョクトウマチ",
    cityId: 43,
  },
  {
    id: 43367,
    name1: "玉名郡南関町",
    name2: "タマナグンナンカンマチ",
    cityId: 43,
  },
  {
    id: 43368,
    name1: "玉名郡長洲町",
    name2: "タマナグンナガスマチ",
    cityId: 43,
  },
  {
    id: 43369,
    name1: "玉名郡和水町",
    name2: "タマナグンナゴミマチ",
    cityId: 43,
  },
  {
    id: 43385,
    name1: "鹿本郡植木町",
    name2: "カモトグンウエキマチ",
    cityId: 43,
  },
  {
    id: 43403,
    name1: "菊池郡大津町",
    name2: "キクチグンオオヅマチ",
    cityId: 43,
  },
  {
    id: 43404,
    name1: "菊池郡菊陽町",
    name2: "キクチグンキクヨウマチ",
    cityId: 43,
  },
  {
    id: 43423,
    name1: "阿蘇郡南小国町",
    name2: "アソグンミナミオグニマチ",
    cityId: 43,
  },
  {
    id: 43424,
    name1: "阿蘇郡小国町",
    name2: "アソグンオグニマチ",
    cityId: 43,
  },
  {
    id: 43425,
    name1: "阿蘇郡産山村",
    name2: "アソグンウブヤマムラ",
    cityId: 43,
  },
  {
    id: 43428,
    name1: "阿蘇郡高森町",
    name2: "アソグンタカモリマチ",
    cityId: 43,
  },
  {
    id: 43432,
    name1: "阿蘇郡西原村",
    name2: "アソグンニシハラムラ",
    cityId: 43,
  },
  {
    id: 43433,
    name1: "阿蘇郡南阿蘇村",
    name2: "アソグンミナミアソムラ",
    cityId: 43,
  },
  {
    id: 43441,
    name1: "上益城郡御船町",
    name2: "カミマシキグンミフネマチ",
    cityId: 43,
  },
  {
    id: 43442,
    name1: "上益城郡嘉島町",
    name2: "カミマシキグンカシママチ",
    cityId: 43,
  },
  {
    id: 43443,
    name1: "上益城郡益城町",
    name2: "カミマシキグンマシキマチ",
    cityId: 43,
  },
  {
    id: 43444,
    name1: "上益城郡甲佐町",
    name2: "カミマシキグンコウサマチ",
    cityId: 43,
  },
  {
    id: 43447,
    name1: "上益城郡山都町",
    name2: "カミマシキグンヤマトチョウ",
    cityId: 43,
  },
  {
    id: 43468,
    name1: "八代郡氷川町",
    name2: "ヤツシログンヒカワチョウ",
    cityId: 43,
  },
  {
    id: 43482,
    name1: "葦北郡芦北町",
    name2: "アシキタグンアシキタマチ",
    cityId: 43,
  },
  {
    id: 43484,
    name1: "葦北郡津奈木町",
    name2: "アシキタグンツナギマチ",
    cityId: 43,
  },
  {
    id: 43501,
    name1: "球磨郡錦町",
    name2: "クマグンニシキマチ",
    cityId: 43,
  },
  {
    id: 43505,
    name1: "球磨郡多良木町",
    name2: "クマグンタラギマチ",
    cityId: 43,
  },
  {
    id: 43506,
    name1: "球磨郡湯前町",
    name2: "クマグンユノマエマチ",
    cityId: 43,
  },
  {
    id: 43507,
    name1: "球磨郡水上村",
    name2: "クマグンミズカミムラ",
    cityId: 43,
  },
  {
    id: 43510,
    name1: "球磨郡相良村",
    name2: "クマグンサガラムラ",
    cityId: 43,
  },
  {
    id: 43511,
    name1: "球磨郡五木村",
    name2: "クマグンイツキムラ",
    cityId: 43,
  },
  {
    id: 43512,
    name1: "球磨郡山江村",
    name2: "クマグンヤマエムラ",
    cityId: 43,
  },
  {
    id: 43513,
    name1: "球磨郡球磨村",
    name2: "クマグンクマムラ",
    cityId: 43,
  },
  {
    id: 43514,
    name1: "球磨郡あさぎり町",
    name2: "クマグンアサギリチョウ",
    cityId: 43,
  },
  {
    id: 43531,
    name1: "天草郡苓北町",
    name2: "アマクサグンレイホクマチ",
    cityId: 43,
  },
  {
    id: 44201,
    name1: "大分市",
    name2: "オオイタシ",
    cityId: 44,
  },
  {
    id: 44202,
    name1: "別府市",
    name2: "ベップシ",
    cityId: 44,
  },
  {
    id: 44203,
    name1: "中津市",
    name2: "ナカツシ",
    cityId: 44,
  },
  {
    id: 44204,
    name1: "日田市",
    name2: "ヒタシ",
    cityId: 44,
  },
  {
    id: 44205,
    name1: "佐伯市",
    name2: "サイキシ",
    cityId: 44,
  },
  {
    id: 44206,
    name1: "臼杵市",
    name2: "ウスキシ",
    cityId: 44,
  },
  {
    id: 44207,
    name1: "津久見市",
    name2: "ツクミシ",
    cityId: 44,
  },
  {
    id: 44208,
    name1: "竹田市",
    name2: "タケタシ",
    cityId: 44,
  },
  {
    id: 44209,
    name1: "豊後高田市",
    name2: "ブンゴタカダシ",
    cityId: 44,
  },
  {
    id: 44210,
    name1: "杵築市",
    name2: "キツキシ",
    cityId: 44,
  },
  {
    id: 44211,
    name1: "宇佐市",
    name2: "ウサシ",
    cityId: 44,
  },
  {
    id: 44212,
    name1: "豊後大野市",
    name2: "ブンゴオオノシ",
    cityId: 44,
  },
  {
    id: 44213,
    name1: "由布市",
    name2: "ユフシ",
    cityId: 44,
  },
  {
    id: 44214,
    name1: "国東市",
    name2: "クニサキシ",
    cityId: 44,
  },
  {
    id: 44322,
    name1: "東国東郡姫島村",
    name2: "ヒガシクニサキグンヒメシマムラ",
    cityId: 44,
  },
  {
    id: 44341,
    name1: "速見郡日出町",
    name2: "ハヤミグンヒジマチ",
    cityId: 44,
  },
  {
    id: 44461,
    name1: "玖珠郡九重町",
    name2: "クスグンココノエマチ",
    cityId: 44,
  },
  {
    id: 44462,
    name1: "玖珠郡玖珠町",
    name2: "クスグンクスマチ",
    cityId: 44,
  },
  {
    id: 45201,
    name1: "宮崎市",
    name2: "ミヤザキシ",
    cityId: 45,
  },
  {
    id: 45202,
    name1: "都城市",
    name2: "ミヤコノジョウシ",
    cityId: 45,
  },
  {
    id: 45203,
    name1: "延岡市",
    name2: "ノベオカシ",
    cityId: 45,
  },
  {
    id: 45204,
    name1: "日南市",
    name2: "ニチナンシ",
    cityId: 45,
  },
  {
    id: 45205,
    name1: "小林市",
    name2: "コバヤシシ",
    cityId: 45,
  },
  {
    id: 45206,
    name1: "日向市",
    name2: "ヒュウガシ",
    cityId: 45,
  },
  {
    id: 45207,
    name1: "串間市",
    name2: "クシマシ",
    cityId: 45,
  },
  {
    id: 45208,
    name1: "西都市",
    name2: "サイトシ",
    cityId: 45,
  },
  {
    id: 45209,
    name1: "えびの市",
    name2: "エビノシ",
    cityId: 45,
  },
  {
    id: 45301,
    name1: "宮崎郡清武町",
    name2: "ミヤザキグンキヨタケチョウ",
    cityId: 45,
  },
  {
    id: 45341,
    name1: "北諸県郡三股町",
    name2: "キタモロカタグンミマタチョウ",
    cityId: 45,
  },
  {
    id: 45361,
    name1: "西諸県郡高原町",
    name2: "ニシモロカタグンタカハルチョウ",
    cityId: 45,
  },
  {
    id: 45362,
    name1: "西諸県郡野尻町",
    name2: "ニシモロカタグンノジリチョウ",
    cityId: 45,
  },
  {
    id: 45382,
    name1: "東諸県郡国富町",
    name2: "ヒガシモロカタグンクニトミチョウ",
    cityId: 45,
  },
  {
    id: 45383,
    name1: "東諸県郡綾町",
    name2: "ヒガシモロカタグンアヤチョウ",
    cityId: 45,
  },
  {
    id: 45401,
    name1: "児湯郡高鍋町",
    name2: "コユグンタカナベチョウ",
    cityId: 45,
  },
  {
    id: 45402,
    name1: "児湯郡新富町",
    name2: "コユグンシントミチョウ",
    cityId: 45,
  },
  {
    id: 45403,
    name1: "児湯郡西米良村",
    name2: "コユグンニシメラソン",
    cityId: 45,
  },
  {
    id: 45404,
    name1: "児湯郡木城町",
    name2: "コユグンキジョウチョウ",
    cityId: 45,
  },
  {
    id: 45405,
    name1: "児湯郡川南町",
    name2: "コユグンカワミナミチョウ",
    cityId: 45,
  },
  {
    id: 45406,
    name1: "児湯郡都農町",
    name2: "コユグンツノチョウ",
    cityId: 45,
  },
  {
    id: 45421,
    name1: "東臼杵郡門川町",
    name2: "ヒガシウスキグンカドガワチョウ",
    cityId: 45,
  },
  {
    id: 45429,
    name1: "東臼杵郡諸塚村",
    name2: "ヒガシウスキグンモロツカソン",
    cityId: 45,
  },
  {
    id: 45430,
    name1: "東臼杵郡椎葉村",
    name2: "ヒガシウスキグンシイバソン",
    cityId: 45,
  },
  {
    id: 45431,
    name1: "東臼杵郡美郷町",
    name2: "ヒガシウスキグンミサトチョウ",
    cityId: 45,
  },
  {
    id: 45441,
    name1: "西臼杵郡高千穂町",
    name2: "ニシウスキグンタカチホチョウ",
    cityId: 45,
  },
  {
    id: 45442,
    name1: "西臼杵郡日之影町",
    name2: "ニシウスキグンヒノカゲチョウ",
    cityId: 45,
  },
  {
    id: 45443,
    name1: "西臼杵郡五ヶ瀬町",
    name2: "ニシウスキグンゴカセチョウ",
    cityId: 45,
  },
  {
    id: 46201,
    name1: "鹿児島市",
    name2: "カゴシマシ",
    cityId: 46,
  },
  {
    id: 46203,
    name1: "鹿屋市",
    name2: "カノヤシ",
    cityId: 46,
  },
  {
    id: 46204,
    name1: "枕崎市",
    name2: "マクラザキシ",
    cityId: 46,
  },
  {
    id: 46206,
    name1: "阿久根市",
    name2: "アクネシ",
    cityId: 46,
  },
  {
    id: 46208,
    name1: "出水市",
    name2: "イズミシ",
    cityId: 46,
  },
  {
    id: 46210,
    name1: "指宿市",
    name2: "イブスキシ",
    cityId: 46,
  },
  {
    id: 46213,
    name1: "西之表市",
    name2: "ニシノオモテシ",
    cityId: 46,
  },
  {
    id: 46214,
    name1: "垂水市",
    name2: "タルミズシ",
    cityId: 46,
  },
  {
    id: 46215,
    name1: "薩摩川内市",
    name2: "サツマセンダイシ",
    cityId: 46,
  },
  {
    id: 46216,
    name1: "日置市",
    name2: "ヒオキシ",
    cityId: 46,
  },
  {
    id: 46217,
    name1: "曽於市",
    name2: "ソオシ",
    cityId: 46,
  },
  {
    id: 46218,
    name1: "霧島市",
    name2: "キリシマシ",
    cityId: 46,
  },
  {
    id: 46219,
    name1: "いちき串木野市",
    name2: "イチキクシキノシ",
    cityId: 46,
  },
  {
    id: 46220,
    name1: "南さつま市",
    name2: "ミナミサツマシ",
    cityId: 46,
  },
  {
    id: 46221,
    name1: "志布志市",
    name2: "シブシシ",
    cityId: 46,
  },
  {
    id: 46222,
    name1: "奄美市",
    name2: "アマミシ",
    cityId: 46,
  },
  {
    id: 46223,
    name1: "南九州市",
    name2: "ミナミキュウシュウシ",
    cityId: 46,
  },
  {
    id: 46224,
    name1: "伊佐市",
    name2: "イサシ",
    cityId: 46,
  },
  {
    id: 46225,
    name1: "姶良市",
    name2: "アイラシ",
    cityId: 46,
  },
  {
    id: 46303,
    name1: "鹿児島郡三島村",
    name2: "カゴシマグンミシマムラ",
    cityId: 46,
  },
  {
    id: 46304,
    name1: "鹿児島郡十島村",
    name2: "カゴシマグントシマムラ",
    cityId: 46,
  },
  {
    id: 46392,
    name1: "薩摩郡さつま町",
    name2: "サツマグンサツマチョウ",
    cityId: 46,
  },
  {
    id: 46404,
    name1: "出水郡長島町",
    name2: "イズミグンナガシマチョウ",
    cityId: 46,
  },
  {
    id: 46441,
    name1: "姶良郡加治木町",
    name2: "アイラグンカジキチョウ",
    cityId: 46,
  },
  {
    id: 46443,
    name1: "姶良郡蒲生町",
    name2: "アイラグンカモウチョウ",
    cityId: 46,
  },
  {
    id: 46452,
    name1: "姶良郡湧水町",
    name2: "アイラグンユウスイチョウ",
    cityId: 46,
  },
  {
    id: 46468,
    name1: "曽於郡大崎町",
    name2: "ソオグンオオサキチョウ",
    cityId: 46,
  },
  {
    id: 46482,
    name1: "肝属郡東串良町",
    name2: "キモツキグンヒガシクシラチョウ",
    cityId: 46,
  },
  {
    id: 46490,
    name1: "肝属郡錦江町",
    name2: "キモツキグンキンコウチョウ",
    cityId: 46,
  },
  {
    id: 46491,
    name1: "肝属郡南大隅町",
    name2: "キモツキグンミナミオオスミチョウ",
    cityId: 46,
  },
  {
    id: 46492,
    name1: "肝属郡肝付町",
    name2: "キモツキグンキモツキチョウ",
    cityId: 46,
  },
  {
    id: 46501,
    name1: "熊毛郡中種子町",
    name2: "クマゲグンナカタネチョウ",
    cityId: 46,
  },
  {
    id: 46502,
    name1: "熊毛郡南種子町",
    name2: "クマゲグンミナミタネチョウ",
    cityId: 46,
  },
  {
    id: 46505,
    name1: "熊毛郡屋久島町",
    name2: "クマゲグンヤクシマチョウ",
    cityId: 46,
  },
  {
    id: 46523,
    name1: "大島郡大和村",
    name2: "オオシマグンヤマトソン",
    cityId: 46,
  },
  {
    id: 46524,
    name1: "大島郡宇検村",
    name2: "オオシマグンウケンソン",
    cityId: 46,
  },
  {
    id: 46525,
    name1: "大島郡瀬戸内町",
    name2: "オオシマグンセトウチチョウ",
    cityId: 46,
  },
  {
    id: 46527,
    name1: "大島郡龍郷町",
    name2: "オオシマグンタツゴウチョウ",
    cityId: 46,
  },
  {
    id: 46529,
    name1: "大島郡喜界町",
    name2: "オオシマグンキカイチョウ",
    cityId: 46,
  },
  {
    id: 46530,
    name1: "大島郡徳之島町",
    name2: "オオシマグントクノシマチョウ",
    cityId: 46,
  },
  {
    id: 46531,
    name1: "大島郡天城町",
    name2: "オオシマグンアマギチョウ",
    cityId: 46,
  },
  {
    id: 46532,
    name1: "大島郡伊仙町",
    name2: "オオシマグンイセンチョウ",
    cityId: 46,
  },
  {
    id: 46533,
    name1: "大島郡和泊町",
    name2: "オオシマグンワドマリチョウ",
    cityId: 46,
  },
  {
    id: 46534,
    name1: "大島郡知名町",
    name2: "オオシマグンチナチョウ",
    cityId: 46,
  },
  {
    id: 46535,
    name1: "大島郡与論町",
    name2: "オオシマグンヨロンチョウ",
    cityId: 46,
  },
  {
    id: 47201,
    name1: "那覇市",
    name2: "ナハシ",
    cityId: 47,
  },
  {
    id: 47205,
    name1: "宜野湾市",
    name2: "ギノワンシ",
    cityId: 47,
  },
  {
    id: 47207,
    name1: "石垣市",
    name2: "イシガキシ",
    cityId: 47,
  },
  {
    id: 47208,
    name1: "浦添市",
    name2: "ウラソエシ",
    cityId: 47,
  },
  {
    id: 47209,
    name1: "名護市",
    name2: "ナゴシ",
    cityId: 47,
  },
  {
    id: 47210,
    name1: "糸満市",
    name2: "イトマンシ",
    cityId: 47,
  },
  {
    id: 47211,
    name1: "沖縄市",
    name2: "オキナワシ",
    cityId: 47,
  },
  {
    id: 47212,
    name1: "豊見城市",
    name2: "トミグスクシ",
    cityId: 47,
  },
  {
    id: 47213,
    name1: "うるま市",
    name2: "ウルマシ",
    cityId: 47,
  },
  {
    id: 47214,
    name1: "宮古島市",
    name2: "ミヤコジマシ",
    cityId: 47,
  },
  {
    id: 47215,
    name1: "南城市",
    name2: "ナンジョウシ",
    cityId: 47,
  },
  {
    id: 47301,
    name1: "国頭郡国頭村",
    name2: "クニガミグンクニガミソン",
    cityId: 47,
  },
  {
    id: 47302,
    name1: "国頭郡大宜味村",
    name2: "クニガミグンオオギミソン",
    cityId: 47,
  },
  {
    id: 47303,
    name1: "国頭郡東村",
    name2: "クニガミグンヒガシソン",
    cityId: 47,
  },
  {
    id: 47306,
    name1: "国頭郡今帰仁村",
    name2: "クニガミグンナキジンソン",
    cityId: 47,
  },
  {
    id: 47308,
    name1: "国頭郡本部町",
    name2: "クニガミグンモトブチョウ",
    cityId: 47,
  },
  {
    id: 47311,
    name1: "国頭郡恩納村",
    name2: "クニガミグンオンナソン",
    cityId: 47,
  },
  {
    id: 47313,
    name1: "国頭郡宜野座村",
    name2: "クニガミグンギノザソン",
    cityId: 47,
  },
  {
    id: 47314,
    name1: "国頭郡金武町",
    name2: "クニガミグンキンチョウ",
    cityId: 47,
  },
  {
    id: 47315,
    name1: "国頭郡伊江村",
    name2: "クニガミグンイエソン",
    cityId: 47,
  },
  {
    id: 47324,
    name1: "中頭郡読谷村",
    name2: "ナカガミグンヨミタンソン",
    cityId: 47,
  },
  {
    id: 47325,
    name1: "中頭郡嘉手納町",
    name2: "ナカガミグンカデナチョウ",
    cityId: 47,
  },
  {
    id: 47326,
    name1: "中頭郡北谷町",
    name2: "ナカガミグンチャタンチョウ",
    cityId: 47,
  },
  {
    id: 47327,
    name1: "中頭郡北中城村",
    name2: "ナカガミグンキタナカグスクソン",
    cityId: 47,
  },
  {
    id: 47328,
    name1: "中頭郡中城村",
    name2: "ナカガミグンナカグスクソン",
    cityId: 47,
  },
  {
    id: 47329,
    name1: "中頭郡西原町",
    name2: "ナカガミグンニシハラチョウ",
    cityId: 47,
  },
  {
    id: 47348,
    name1: "島尻郡与那原町",
    name2: "シマジリグンヨナバルチョウ",
    cityId: 47,
  },
  {
    id: 47350,
    name1: "島尻郡南風原町",
    name2: "シマジリグンハエバルチョウ",
    cityId: 47,
  },
  {
    id: 47353,
    name1: "島尻郡渡嘉敷村",
    name2: "シマジリグントカシキソン",
    cityId: 47,
  },
  {
    id: 47354,
    name1: "島尻郡座間味村",
    name2: "シマジリグンザマミソン",
    cityId: 47,
  },
  {
    id: 47355,
    name1: "島尻郡粟国村",
    name2: "シマジリグンアグニソン",
    cityId: 47,
  },
  {
    id: 47356,
    name1: "島尻郡渡名喜村",
    name2: "シマジリグントナキソン",
    cityId: 47,
  },
  {
    id: 47357,
    name1: "島尻郡南大東村",
    name2: "シマジリグンミナミダイトウソン",
    cityId: 47,
  },
  {
    id: 47358,
    name1: "島尻郡北大東村",
    name2: "シマジリグンキタダイトウソン",
    cityId: 47,
  },
  {
    id: 47359,
    name1: "島尻郡伊平屋村",
    name2: "シマジリグンイヘヤソン",
    cityId: 47,
  },
  {
    id: 47360,
    name1: "島尻郡伊是名村",
    name2: "シマジリグンイゼナソン",
    cityId: 47,
  },
  {
    id: 47361,
    name1: "島尻郡久米島町",
    name2: "シマジリグンクメジマチョウ",
    cityId: 47,
  },
  {
    id: 47362,
    name1: "島尻郡八重瀬町",
    name2: "シマジリグンヤエセチョウ",
    cityId: 47,
  },
  {
    id: 47375,
    name1: "宮古郡多良間村",
    name2: "ミヤコグンタラマソン",
    cityId: 47,
  },
  {
    id: 47381,
    name1: "八重山郡竹富町",
    name2: "ヤエヤマグンタケトミチョウ",
    cityId: 47,
  },
  {
    id: 47382,
    name1: "八重山郡与那国町",
    name2: "ヤエヤマグンヨナグニチョウ",
    cityId: 47,
  },
];
const NewProvince = [
  {
    id: 40,
    name1: "福岡県",
    name2: "フクオカケン",
  },
  {
    id: 44,
    name1: "大分県",
    name2: "オオイタケン",
  },

  {
    id: 999,
    name1: "他県",
    name2: "他県",
  },
];
export { City, Province, NewProvince };
