import { useQuery } from "react-query";
import { FetchApi } from "../../api";

const useGetComboCategories = () => {
  const { data } = useQuery([`get-all-combo-category`], () =>
    FetchApi.getAllComboCategory()
  );
  return {
    comboCategories: data?.comboCategories || [],
  };
};

export { useGetComboCategories };
