/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Form, message } from "antd";

import { FetchApi } from "../../../api";
import { ModalCustomService } from "../../../elements";
import { STRINGS } from "../../../constants";
import { CommonFunction } from "../../../util/CommonFunction";

import "../SlideSetting.scss";
import SlideOption from "./SlideOption";

const Edit = ({ item, data, setData }) => {
  const [action, setAction] = useState(item.type);
  const [newsList, setNewsList] = useState([]);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getNewsList = async () => {
      const res = await FetchApi.getAllNews(1, 50);
      if (res.success) {
        setNewsList(res);
      }
    };
    if (action === "NEWS") {
      getNewsList();
    }
  }, [action]);

  useEffect(() => {
    form.resetFields(["newsId"]);
  }, [newsList]);

  const onEdit = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      if (new Date(fieldData.startTime) >= new Date(fieldData.endTime)) {
        message.warning(STRINGS.endtime_greater_than_starttime);
        return;
      }
      fieldData.id = item.id;
      setLoading(true);
      const result = await FetchApi.editSlide(fieldData);
      setLoading(false);
      if (result.success) {
        const index = data.slides.findIndex((itm) => itm.id === item.id);
        const clone = JSON.parse(JSON.stringify({ ...data }));
        clone.slides[index] = result.slide;
        setData(clone.slides);
        ModalCustomService.close();
        message.success(STRINGS.registered_common);
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return (
    <SlideOption onClick={onEdit} loading={loading} form={form} item={item} />
  );
};

export default Edit;
