import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Row, Input, message, AutoComplete } from "antd";
import moment from "moment";
import { SearchOutlined, CheckCircleOutlined } from "@ant-design/icons";

import { COLORS, STRINGS } from "../../../constants";
import { FetchApi } from "../../../api";
import ModalInfoUserDetail from "../items/ModalInfoUserDetail";
import { ModalCustomService } from "../../../elements";
import ModalBookingDetail from "../items/ModalBookingDetail";
import ModalCancelBooking from "../../modal-cancel-booking/ModalCancelBooking";

function ModalBookingDetailAction({
  data,
  form,
  setLoading,
  setIsCheckInBooking,
  setDisablePay,
  actionBookingType,
  textType,
  isCheckInBooking,
  refetch,
  cache,
  disablePay,
  disabled,
  selectedDate,
  onAction,
  statusBooking,
}) {
  const [options, setOptions] = useState([]);
  const search = useRef();
  const timeout = useRef();

  useEffect(() => {
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, []);
  const searchResult = (datas) => {
    return datas.map((item, index) => {
      return {
        name: item.name,
        value: "" + item.id,
        item: item,
        label: (
          <div style={{ borderWidth: 20 }}>
            <div style={{ fontWeight: "bold" }}>{item.name || ""}</div>
            <div style={{ color: "grey" }}>{item.phoneNumber}</div>
          </div>
        ),
      };
    });
  };
  const onSelect = (e, item) => {
    if (!item?.item?.id) {
      return;
    }
    ModalCustomService.set({
      title: STRINGS.customer_info,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <ModalInfoUserDetail
          haveCheckIn
          onCheckInBooking={onCheckInBooking}
          refetch={refetch}
          data={item.item}
        />
      ),
    });
  };
  const onSearch = (searchText) => {
    search.current = searchText;

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(async () => {
      if (!searchText) {
        setOptions([]);
        return;
      }
      let result = await FetchApi.getUserApp({
        page: 1,
        size: 50,
        search: searchText,
      });
      if (!Array.isArray(result?.data) || result?.data?.length < 1) {
        setOptions([]);
        return;
      }
      setOptions(searchResult(result.data) || []);
    }, 800);
  };

  const onCancelBooking = async (type) => {
    const result = await FetchApi.checkBatchBooking({ bookingId: data.id });
    if (result?.batchBooking) {
      ModalCustomService.set({
        title: STRINGS.cancel_confirm,
        titleStyle: {
          fontSize: 25,
        },
        wrapperStyle: { width: "auto" },
        contentWrapperStyle: { width: "fit-content" },
        contentStyle: { maxHeight: "80vh", padding: 0 },

        content: (
          <ModalCancelBooking
            bookingList={result.bookingWithNameOfUserList?.filter?.(
              (i) => i.bookingId !== data.id
            )}
            mainBookingId={data.id}
            type={type}
            refetch={refetch}
          />
        ),
      });
      return;
    }
    onAction(type)();
  };

  const onCheckInBooking = async (userId) => {
    setLoading(true);
    const { userCheckInTime } = form.getFieldValue();
    let checkTime = moment();

    if (moment(data.userCheckInTime).isValid()) {
      checkTime = moment(data.userCheckInTime);
    }
    if (userCheckInTime) {
      checkTime = moment(userCheckInTime);
    }
    const result = await FetchApi.checkInBooking({
      bookingId: data.id,
      userCheckInTime: moment(checkTime).toISOString(),
      userId: userId,
    });

    setLoading(false);
    if (result.success) {
      message.success(STRINGS.check_in_success);
      setIsCheckInBooking(true);
      cache.refetchQueries("bookingComing");
      await cache.refetchQueries(`getDetailCourseBooking-${data.id}`);
      data.userCheckInTime = checkTime;
      setDisablePay(false);
      refetch();
      if (!!userId) {
        const newData = cache.getQueryData(`getDetailCourseBooking-${data.id}`);
        ModalCustomService.set({
          title: STRINGS.detail_reservation,
          titleStyle: {
            fontSize: 25,
          },
          wrapperStyle: { width: "auto" },
          contentWrapperStyle: { width: "fit-content" },
          contentStyle: { maxHeight: "80vh", padding: 0 },

          content: (
            <ModalBookingDetail
              refetch={refetch}
              data={newData.calendarOrder}
            />
          ),
        });
      }
      return;
    }
    if (result.code === 4005) {
      message.error(STRINGS.check_in_fail_beyond_time);
      return;
    }
    if (result.code === 4001) {
      message.error(STRINGS.check_in_fail_before_time);
      return;
    }
    if (result.code === 4003) {
      message.error(STRINGS.have_checked_in);
      return;
    }
    message.error(result.message);
  };
  const renderButtonCheckin = () => {
    let backgroundColor = "#FE6C06";
    let fontSize = 30;
    let paddingRight = 15;
    if (isCheckInBooking || disabled) {
      backgroundColor = COLORS.disabled;
    }
    if (data.userId) {
      return (
        <Button
          disabled={isCheckInBooking || disabled}
          className="bottom-btn"
          style={{
            width: "95%",
            height: 83,
            justifyContent: "center",
            lineHeight: 1.3,
            backgroundColor,
          }}
          onClick={() => onCheckInBooking()}
        >
          <CheckCircleOutlined className="icon" style={{ fontSize: 35 }} />
          <Row
            style={{
              fontWeight: "bold",
              whiteSpace: "pre-line",
              textAlign: "left",
              fontSize: 43,
            }}
          >
            {STRINGS.checkin_booking}
          </Row>
        </Button>
      );
    }

    return (
      <Col>
        <Row
          style={{
            alignItems: "center",
            marginBottom: 10,
            color: COLORS.accent,
            fontWeight: "bold",
            fontSize: 24,
          }}
        >
          {STRINGS.search_booking + "\xa0\xa0"}
          <SearchOutlined />
          <AutoComplete
            options={options}
            onSelect={onSelect}
            onSearch={onSearch}
            style={{
              width: "68%",
              marginLeft: 10,
              border: `1px solid ${COLORS.text}`,
              borderRadius: 3,
            }}
          >
            <Input
              onPressEnter={(e, f) => onSelect(e, f)}
              placeholder={STRINGS.search_name_phone}
              style={{ fontSize: 16, fontWeight: "bold" }}
            />
          </AutoComplete>
        </Row>
        <Button
          disabled={isCheckInBooking || disabled}
          className="bottom-btn"
          style={{
            width: "95%",
            height: 41,
            justifyContent: "center",
            lineHeight: 1.3,
            backgroundColor,
          }}
          onClick={() => onCheckInBooking()}
        >
          <CheckCircleOutlined
            className="icon"
            style={{ fontSize: 35, paddingRight }}
          />
          <Row
            style={{
              fontWeight: "bold",
              whiteSpace: "pre-line",
              textAlign: "left",
              fontSize,
            }}
          >
            {STRINGS.checkin_booking}
          </Row>
        </Button>
      </Col>
    );
  };
  const useRenderActionBooking = () => {
    let disableCancelButton =
      statusBooking === "done" || !disablePay || isCheckInBooking || data.paid;
    useEffect(() => {
      const checkTimeAfterCheckin = setInterval(() => {
        cache.refetchQueries(`getDetailCourseBooking-${data.id}`);
      }, 10000);
      return () => clearInterval(checkTimeAfterCheckin);
    }, []);
    if (actionBookingType) {
      return <Col style={{ color: "green" }}>{textType}</Col>;
    }

    if (
      data.createdTime ||
      statusBooking === "done" ||
      !disablePay ||
      isCheckInBooking ||
      data.paid
    ) {
      if (
        data.startTime &&
        !data.paid &&
        moment(data.startTime).add(30, "minute").isAfter(moment())
      ) {
        return (
          <>
            <Col span={12}>{renderButtonCheckin()}</Col>
            <Col span={12}>
              <p
                className="bottom-btn"
                style={{
                  fontWeight: "bold",
                  backgroundColor: COLORS.background,
                  color: COLORS.sub_secondary,
                  fontSize: 22,
                }}
              >
                {STRINGS.cancel}
              </p>
              <Row>
                <Button
                  className="bottom-btn"
                  onClick={() => onCancelBooking("cancel_no_contact")}
                  style={{
                    backgroundColor: COLORS.sub_secondary,
                    fontSize: 22,
                    fontWeight: "bold",
                  }}
                >
                  {STRINGS.cancellation_without_permission}
                </Button>
                <Button
                  className="bottom-btn"
                  onClick={() => onCancelBooking("cancel_by_store")}
                  style={{
                    fontWeight: "bold",
                    backgroundColor: COLORS.sub_secondary,
                    fontSize: 22,
                  }}
                >
                  {STRINGS.cancellation_at_store}
                </Button>
                {moment(data.startTime).isAfter(moment()) && (
                  <Button
                    className="bottom-btn"
                    onClick={() => onCancelBooking("cancel_contact")}
                    style={{
                      fontSize: 22,
                      fontWeight: "bold",
                      justifyContent: "center",
                      backgroundColor: COLORS.sub_secondary,
                    }}
                  >
                    {STRINGS.canceled_with_contact}
                  </Button>
                )}
              </Row>
            </Col>
          </>
        );
      }
      return null;
    }

    const format = "YYYY/MM/DD";
    if (
      !selectedDate ||
      moment().isBefore(moment(selectedDate)) ||
      moment().format(format) === moment(selectedDate).format(format)
    ) {
      disableCancelButton = false;
    }
    return (
      <>
        <Col span={12}>{renderButtonCheckin()}</Col>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <Row
                style={{
                  color: COLORS.secondary,
                  fontSize: 22,
                  width: "95%",
                  fontWeight: "bold",
                  justifyContent: "center",
                }}
              >
                {STRINGS.cancel}
              </Row>
            </Col>
            <Col span={12}>
              <Button
                className="bottom-btn"
                onClick={() => onCancelBooking("cancel_no_contact")}
                disabled={disableCancelButton}
                style={{
                  width: "95%",
                  justifyContent: "center",
                  backgroundColor: disableCancelButton
                    ? COLORS.disabled
                    : COLORS.sub_secondary,
                }}
              >
                <Row
                  style={{
                    fontSize: 22,
                    letterSpacing: 8,
                    fontWeight: "bold",
                  }}
                >
                  {STRINGS.cancellation_without_permission}
                </Row>
              </Button>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Button
                className="bottom-btn"
                onClick={() => onCancelBooking("cancel_by_store")}
                disabled={disableCancelButton}
                style={{
                  width: "95%",
                  justifyContent: "center",
                  backgroundColor: disableCancelButton
                    ? COLORS.disabled
                    : COLORS.sub_secondary,
                }}
              >
                <Row
                  style={{
                    fontSize: 22,
                    letterSpacing: 8,
                    fontWeight: "bold",
                  }}
                >
                  {STRINGS.cancellation_at_store}
                </Row>
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="bottom-btn"
                onClick={() => onCancelBooking("cancel_contact")}
                disabled={disableCancelButton}
                style={{
                  width: "95%",
                  justifyContent: "center",
                  backgroundColor: disableCancelButton
                    ? COLORS.disabled
                    : COLORS.sub_secondary,
                }}
              >
                <Row
                  style={{
                    fontSize: 22,
                    letterSpacing: 8,
                    fontWeight: "bold",
                  }}
                >
                  {STRINGS.canceled_with_contact}
                </Row>
              </Button>
            </Col>
          </Row>
        </Col>
      </>
    );
  };
  return <Row>{useRenderActionBooking()}</Row>;
}
export { ModalBookingDetailAction };
