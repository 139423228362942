import React, { useState } from "react";
import { Col, Row, Table } from "antd";
import { useQuery } from "react-query";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import { FetchApi } from "../../api";
import { Container, ErrorView, ButtonCustom } from "../../elements";
import { STRINGS } from "../../constants";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";

const StaffChangeHistory = () => {
  const [pagination, setPagination] = useState({ size: 10, page: 1 });
  const [sort, setSort] = useState("desc");
  const { data, isLoading } = useQuery(
    [`StaffHistoryChange-${pagination.size}-${pagination.page}-${sort}`],
    () =>
      FetchApi.getStaffHistoryChange({
        page: pagination.page,
        size: pagination.size,
        sort: sort,
      })
  );

  const columns = [
    {
      title: "No",
      key: "index",
      dataIndex: "index",
      fixed: "left",
      width: 70,
      sorter: (a, b) => a.index - b.index,
      sortDirections: ["ascend", "descend", "ascend"],
      align: "center",
    },
    {
      title: STRINGS.reception_datetime,
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      width: 120,
    },

    {
      title: STRINGS.reception,
      dataIndex: "staffNameChange",
      key: "staffNameChange",
      align: "center",
      width: 120,
    },
    {
      title: STRINGS.staff_change,
      width: 150,
      dataIndex: "staffIdChange",
      key: "staffIdChange",
      align: "center",
    },
    {
      title: STRINGS.staff_name_owner,
      dataIndex: "staffNameOwner",
      key: "staffNameOwner",
      align: "center",
      width: 120,
    },
    {
      title: STRINGS.working_day,
      dataIndex: "workingDay",
      key: "workingDay",
      align: "center",
      width: 100,
    },
    {
      title: STRINGS.type_change_text,
      dataIndex: "typeChange",
      key: "typeChange",
      align: "center",
      width: 220,
      render: (e) => STRINGS.type_change[e],
    },
    {
      title: STRINGS.old_value,
      dataIndex: "oldValue",
      key: "oldValue",
      align: "center",
      width: 220,
    },
    {
      title: STRINGS.new_value,
      dataIndex: "newValue",
      key: "newValue",
      align: "center",
      width: 220,
    },
    {
      title: STRINGS.reason,
      dataIndex: "note",
      key: "note",
      align: "center",
      width: 220,
    },
  ];

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Container>
      <Col style={{ marginRight: 10 }}>
        <ButtonCustom
          style={{ marginBottom: 14 }}
          onClick={() => setSort((prev) => (prev === "desc" ? "asc" : "desc"))}>
          <Row style={{ alignItems: "center" }}>
            {STRINGS.sorting}
            <ArrowUpOutlined style={{ marginLeft: 2 }} />
            <ArrowDownOutlined />
          </Row>
        </ButtonCustom>
      </Col>
      <Table
        loading={isLoading}
        rowKey={(e) => e.id + Math.random()}
        columns={columns}
        dataSource={data?.data || []}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
        scroll={{ x: "auto", y: "calc(100vh - 350px)" }}
      />
      <PaginationWithSizeChange
        size={pagination.size}
        total={data?.total || 0}
        onChange={(pageNumber) => {
          setPagination({ size: pagination.size, page: pageNumber });
        }}
        onShowSizeChange={(e) => {
          setPagination({ size: e.size, page: 1 });
        }}
        current={pagination.page}
      />
    </Container>
  );
};

export default StaffChangeHistory;
