import React, { useState, useEffect, useRef } from "react";
import { Col, Row, AutoComplete } from "antd";

import { FetchApi } from "../../../api";
import { Validate } from "../../../elements/Validate";
import { STRINGS } from "../../../constants";

const CustomerList = ({ style, onChange, value, disabled, defaultName }) => {
  const [loading] = useState(false);
  const [data, setData] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    size: 20,
    last: false,
    refetch: false,
  });
  const searchText = useRef("");
  const timeout = useRef();

  const handleChange = (val, option) => {
    onChange?.(Number(option.key));
  };
  const onScroll = (event) => {
    let target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      if (!pagination.last) {
        setPagination((prev) => {
          return { ...prev, page: prev.page + 1 };
        });
      }
    }
  };

  const fetch = async () => {
    const result = await FetchApi.getUserApp({
      size: pagination.size,
      page: pagination.page,
      search: searchText.current,
      includeDeleted: false,
    });

    if (result.success) {
      setData((prev) => {
        if (pagination.page === 1) {
          return [...result.data];
        }
        return [...prev, ...result.data];
      });
      setPagination((prev) => {
        return { ...prev, last: result.last };
      });
    }
  };

  useEffect(() => {
    fetch();
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, pagination.refetch]);

  let dataCheckLoading = [];

  if (!pagination.last && Array.isArray(data)) {
    dataCheckLoading = [...data, { id: "more", name: "..." }];
  }

  if (Array.isArray(data) && pagination.last) {
    dataCheckLoading = data;
  }
  const options = dataCheckLoading.map((item, index) => (
    <AutoComplete.Option
      key={`${item.id}`}
      value={`${item.name}`}
      disabled={item.id === "more"}
    >
      {item.name}
    </AutoComplete.Option>
  ));

  const defaultVal = defaultName
    ? defaultName
    : data
    ? data.find((itm) => itm.id == value)?.name
    : "";

  return (
    <Col>
      <Row style={{ color: "#757575", marginBottom: 4 }}>
        {STRINGS.search_name_furi_phone}
        <Col style={{ color: "red" }}>{"\xa0*"}</Col>
      </Row>

      {Array.isArray(data) ? (
        <AutoComplete
          allowClear={true}
          defaultValue={defaultVal}
          disabled={disabled}
          style={{ width: 300 }}
          onPopupScroll={onScroll}
          onSelect={handleChange}
          onChange={(e) => {
            const converted = Validate.removeSpecialCharactors(e);
            searchText.current = converted || null;
            if (timeout.current) {
              clearTimeout(timeout.current);
            }
            timeout.current = setTimeout(() => {
              setPagination({
                page: 1,
                size: 20,
                last: true,
                refetch: !pagination.refetch,
              });
            }, 800);
          }}
        >
          {options}
        </AutoComplete>
      ) : (
        <div style={{ width: 300, height: 32 }} />
      )}

      {/* {Array.isArray(data) && (
        <Select
          disabled={disabled}
          defaultValue={value}
          showSearch
          placeholder={""}
          onChange={handleChange}
          onSearch={(e) => {
            const converted = Validate.removeSpecialCharactors(e);
            searchText.current = converted || null;
            if (timeout.current) {
              clearTimeout(timeout.current);
            }
            timeout.current = setTimeout(() => {
              setPagination({
                page: 1,
                size: 20,
                last: true,
                refetch: !pagination.refetch,
              });
            }, 800);
          }}
          // optionFilterProp="children"
          // filterOption={null}
          onPopupScroll={onScroll}
          suffixIcon={<SuffixIconDown />}
          style={{ width: 300, alignSelf: "flex-end", ...style }}>
          {options}
        </Select>
      )} */}
    </Col>
  );
};

export { CustomerList };
