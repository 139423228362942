import React, { useRef, useState } from "react";
import { Col, Row, message } from "antd";
import { PlusCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import moment from "moment";

import {
  ButtonCustom,
  LoadingAbsolute,
  ModalCustomService,
} from "../../../elements";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util/CommonFunction";
import { TimePickerAutoAccept } from "../../../elements/TimePickerAutoAccept";
import { COLORS, STRINGS } from "../../../constants";
import Auth from "../../../store/Authentication";
import RadioAllStoreStaff from "../../../elements/RadioAllStoreStaff";

import "../StaffManagerSchedule.scss";

function HoursPicker({ onChange, workShiftDTOS = [] }) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const [timeRangeList, setTimeRangeList] = useState(
    workShiftDTOS.map((item) => {
      return {
        ...item,
        startTime: moment(item.startTime).format("HH:mm"),
        endTime: moment(item.endTime).format("HH:mm"),
      };
    })
  );

  const range = (a, b = 23) => {
    const arr = [];
    while (a < b + 1) {
      arr.push(a++);
    }
    return arr;
  };

  const openHour = userAdmin.storeDTO?.openTime
    ? parseInt(userAdmin.storeDTO.openTime.slice(0, 2))
    : [];
  const closeHour = userAdmin.storeDTO?.closeTime
    ? parseInt(userAdmin.storeDTO.closeTime.slice(0, 2))
    : 24;
  let openHours = [];
  let closeHours = [];

  if (closeHour > openHour) {
    openHours = Array.from(Array(openHour).keys());
    closeHours = range(closeHour + 1);
  } else {
    openHours = range(closeHour + 1, openHour - 1);
  }

  const addTimeRangeList = () => {
    const newStartTime = userAdmin.storeDTO?.openTime || "12:00";
    const newEndTime = userAdmin.storeDTO?.closeTime || "12:00";
    const clone = JSON.parse(
      JSON.stringify([
        ...timeRangeList,
        {
          startTime: newStartTime,
          endTime: newEndTime,
          session: "早",
          action: "ADD",
        },
      ])
    );
    setTimeRangeList(clone);
    onChange(clone);
  };
  const removeTimeRange = (index) => () => {
    const clone = JSON.parse(JSON.stringify([...timeRangeList]));
    clone[index].action = "DELETE";
    clone.splice(index, 1);
    setTimeRangeList(clone);
    onChange(clone);
  };
  const onChangeHour = (index, type) => async (e) => {
    const clone = JSON.parse(JSON.stringify([...timeRangeList]));
    clone[index][type] = moment(e).format("HH:mm");
    setTimeRangeList(clone);
    onChange(clone);
  };
  // const onChangeSession = (index) => (e) => {
  //   const clone = JSON.parse(JSON.stringify([...timeRangeList]));
  //   clone[index].session = e.target.value;
  //   setTimeRangeList(clone);
  //   onChange(clone);
  // };

  return (
    <Col style={{ paddingTop: 14, width: "70%" }}>
      <Row style={{ textAlign: "left", fontWeight: "bold", color: "#4B2A05" }}>
        <Col span={10} style={{ textAlign: "-webkit-center" }}>
          {STRINGS.checkin}
        </Col>
        <Col span={10} style={{ textAlign: "-webkit-center" }}>
          {STRINGS.checkout}
        </Col>
        {/* <Col span={4} style={{ fontWeight: "bold" }}>
          {STRINGS.shift}
        </Col> */}
      </Row>
      {timeRangeList.map((item, index) => {
        return (
          <Row
            key={`${timeRangeList}-${index}`}
            style={{
              textAlign: "left",
              alignItems: "center",
              marginBottom: 6,
            }}>
            <Col span={10}>
              <TimePickerAutoAccept
                value={moment(item.startTime, "HH:mm")}
                onChangeHour={onChangeHour(index, "startTime")}
                minuteStep={10}
                format={"HH:mm"}
                disabledHours={() => {
                  return [...openHours, ...closeHours];
                }}
              />
            </Col>
            <Col span={10}>
              <TimePickerAutoAccept
                value={moment(item.endTime, "HH:mm")}
                onChangeHour={onChangeHour(index, "endTime")}
                minuteStep={10}
                format={"HH:mm"}
                disabledHours={() => {
                  return [...openHours, ...closeHours];
                }}
              />
            </Col>
            {/* <Col span={4}>
              <Radio.Group
                defaultValue={item.session}
                style={{ width: "90%", display: "grid" }}
                onChange={onChangeSession(index)}>
                <Radio value={"早"}>早</Radio>
                <Radio value={"中"}>中</Radio>
                <Radio value={"遅"}>遅</Radio>
              </Radio.Group>
            </Col> */}
            {timeRangeList.length > 1 && (
              <MinusCircleFilled
                style={{
                  fontSize: 27,
                  paddingRight: 3,
                  color: COLORS.sub_accent,
                }}
                span={2}
                onClick={removeTimeRange(index)}
              />
            )}
            {index === timeRangeList.length - 1 && timeRangeList.length < 5 && (
              <PlusCircleFilled
                style={{ fontSize: 27, color: COLORS.sub_accent }}
                span={2}
                onClick={addTimeRangeList}
              />
            )}
          </Row>
        );
      })}
    </Col>
  );
}

function ModalChangeWorkingTime({
  staffInfo,
  refetchCalendarOrder,
  workingDay,
}) {
  const staffChangeRequest = useRef({});
  const shiftRequests = useRef(staffInfo.workShiftDTOS);
  const [loading, setLoading] = useState(false);

  const onChangeRestTime = async () => {
    if (!shiftRequests.current.length) {
      message.warning(STRINGS.please_select_shift);
      return;
    }
    if (!staffChangeRequest.current.id) {
      message.warning(STRINGS.please_select_a_reception_staff);
      return;
    }
    if (!staffChangeRequest.current.password) {
      message.warning(STRINGS.please_type_password);
      return;
    }
    try {
      setLoading(true);
      const result = await FetchApi.updateScheduleTemporaryByStore({
        staffChangeRequest: staffChangeRequest.current,
        shiftRequests: shiftRequests.current,
        type: "update_shift",
        workingDay: moment(workingDay).format("MM/DD/YYYY"),
        staffId: staffInfo.staffId,
      });
      setLoading(false);
      if (result.success) {
        refetchCalendarOrder();
        message.success(STRINGS.saved_common);
        ModalCustomService.close();
        if (result.code == 2001) {
          message.warning(STRINGS.bed_full);
          return;
        }
        if (result.code == 2002) {
          message.warning(STRINGS.bed_full_2);
          return;
        }
        return;
      }
      if (result.code == 4003) {
        message.warning(STRINGS.password_is_incorrect);
        return;
      }
      if (result.code == 4007) {
        message.error(STRINGS.update_shift_fail);
        return;
      }
      CommonFunction.logError(result);
    } catch (error) {
      message.error(STRINGS.something_wrong);
      setLoading(false);
    }
  };

  return (
    <Col className="checkin-time-wrapper">
      <Row
        style={{
          color: "#4B2A05",
          fontSize: 18,
          paddingTop: 14,
          fontWeight: "bold",
          justifyContent: "center",
        }}>
        {STRINGS.please_select_a_change_time}
      </Row>

      <HoursPicker
        workShiftDTOS={staffInfo.workShiftDTOS}
        onChange={(e) => (shiftRequests.current = e)}
      />

      <Row
        style={{
          color: "#4B2A05",
          fontSize: 18,
          paddingTop: 14,
          fontWeight: "bold",
          justifyContent: "center",
        }}>
        {STRINGS.please_select_a_reception_staff}
      </Row>

      <RadioAllStoreStaff onChange={(e) => (staffChangeRequest.current = e)} />

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onChangeRestTime}>{STRINGS.sure}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}

export default ModalChangeWorkingTime;
