import { Col, Form, Row, message, Select } from "antd";
import React from "react";

import { FetchApi } from "../../../api";
import { STRINGS, LOGIC } from "../../../constants";
import {
  ButtonCustom,
  ModalCustomService,
  LoadingAbsolute,
  SuffixIconDown,
} from "../../../elements";
import { CommonFunction } from "../../../util";
import Quill from "../../../elements/Quill";

import "../ManagerMail.scss";

const Option = Select.Option;

const Edit = ({ data, setData, item, refetch }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const onAdd = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      setLoading(true);
      const result = await FetchApi.updateEmailSetting({
        ...fieldData,
        id: item.id,
      });
      setLoading(false);

      if (result.success) {
        refetch();
        // const index = data.emailSettingDTOS.findIndex(
        //   (itm) => itm.id === item.id
        // );
        // const clone = JSON.parse(JSON.stringify({ ...data }));
        // clone.emailSettingDTOS[index] = result.emailSettingDTO;
        // setData(clone.emailSettingDTOS);
        ModalCustomService.close();
        message.success(STRINGS.saved_common);
      } else {
        setLoading(false);
        CommonFunction.logError(result);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return (
    <Col className="mail-form">
      <Form layout="vertical" form={form}>
        <Form.Item
          label={STRINGS.type_mail}
          name="type"
          initialValue={item.type}>
          <Select
            style={{ textAlign: "left" }}
            disabled
            suffixIcon={<SuffixIconDown />}>
            {Object.keys(LOGIC.typeMail).map((i) => {
              return <Option key={i}>{LOGIC.typeMail[i]}</Option>;
            })}
          </Select>
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 10 }}
          label={STRINGS.text_edit_mail}
          name="description"
          // rules={[Validate.validateEditor]}
          initialValue={item?.description}>
          <Quill className="quill-slide" />
        </Form.Item>
      </Form>
      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onAdd}>{STRINGS.update}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
};

export default Edit;
