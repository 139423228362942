import React, { useCallback, useState } from "react";
import { Button, Col, DatePicker, Row } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import { COLORS, STRINGS, LOGIC, Svgs } from "../../../constants";
import { ModalCustomService } from "../../../elements/ModalCustom/ModalCustomService";
import { HeaderStaffScheduleService } from "../../../services";
import { ModalRegCustomerBooking } from "../../staff-booking/items/CustomerConfig/ModalRegCustomerBooking";

import ModalAddStaff from "./ModalAddStaff";

function HeaderSchedule({
  selectedDate,
  setSelectedDate,
  refetchCalendarOrder,
  onCellScale,
}) {
  const [zoomType, setZoomType] = useState("zoomIn");
  const onZoomOut = () => {
    onCellScale("zoomOut")();
    setZoomType("zoomOut");
  };
  const onZoomIn = () => {
    onCellScale("zoomIn")();
    setZoomType("zoomIn");
  };
  const onZoomIpad = () => {
    onCellScale("ipad")();
    setZoomType("ipad");
  };
  const handleAbleModalSearch = useCallback(
    () => HeaderStaffScheduleService.set("onAbleModalSearch"),
    []
  );
  const handleAbleModalSpeechRecog = useCallback(
    () =>
      HeaderStaffScheduleService.set("onAbleModalSpeechRecogManagerSchedule"),
    []
  );
  const onAbleModalAddStaff = () => {
    ModalCustomService.set({
      title: STRINGS.bonus_staff_work,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <ModalAddStaff
          staffInfo={{ day: moment(selectedDate).format("MM/DD/YYYY") }}
          refetchCalendarOrder={refetchCalendarOrder}
        />
      ),
    });
  };
  const isDisabled = moment(selectedDate).isBefore(moment().subtract(1, "day"));
  let color = "#FF8000";
  let borderColor = "1px solid #FF8000";
  if (isDisabled) {
    color = COLORS.disabled;
    borderColor = `1px solid ${COLORS.disabled}`;
  }
  return (
    <Row
      style={{
        backgroundColor: COLORS.manager_schedule_background,
        padding: 18,
        justifyContent: "space-between",
      }}
    >
      <Col xl={12} sx={24}>
        <Row style={{ alignItems: "center", marginBottom: 4 }}>
          <DatePicker
            defaultValue={selectedDate}
            suffixIcon={Svgs.datepicker({ width: 19, height: 21 })}
            style={{
              width: 250,
              borderRadius: 4,
              padding: "6px 10px",
              marginRight: 20,
            }}
            allowClear={false}
            onChange={(e) => setSelectedDate(moment(e))}
            format={(e) => {
              return `${moment(e).format(LOGIC.dateFormat)} (${
                LOGIC.day[moment(e).isoWeekday() || 7]
              })`;
            }}
            picker={"date"}
          />
          <Button
            icon={<SearchOutlined />}
            onClick={handleAbleModalSearch}
            style={{
              height: "fit-content",
              fontSize: 20,
              alignSelf: "flex-start",
              borderRadius: 4,
              border: `1px solid ${COLORS.primary_2}`,
              backgroundColor: COLORS.primary_2,
              color: "white",
              padding: "4px 12px",
              fontWeight: "bold",
              marginRight: 10,
            }}
          >
            {STRINGS.search_booking}
          </Button>
          <Button
            onClick={() => {
              ModalCustomService.set({
                title: STRINGS.registration,
                wrapperStyle: { width: "auto" },
                contentWrapperStyle: { width: "fit-content" },
                contentStyle: { maxHeight: "80vh", padding: 0 },
                content: <ModalRegCustomerBooking data={{}} />,
              });
            }}
            style={{
              height: "fit-content",
              fontSize: 20,
              alignSelf: "flex-start",
              borderRadius: 4,
              border: `1px solid ${COLORS.sub_accent}`,
              backgroundColor: COLORS.sub_accent,
              color: "white",
              padding: "4px 12px",
              fontWeight: "bold",
              marginRight: 10,
            }}
          >
            {STRINGS.reg_new_user_booking}
          </Button>

          <Button
            onClick={handleAbleModalSpeechRecog}
            style={{
              height: "fit-content",
              fontSize: 20,
              alignSelf: "flex-start",
              borderRadius: 4,
              border: `1px solid ${COLORS.secondary}`,
              backgroundColor: COLORS.secondary,
              color: "white",
              padding: "4px 12px",
              fontWeight: "bold",
              marginRight: 20,
            }}
          >
            {STRINGS.reserve}
          </Button>
          {/* <Button
            style={{
              borderColor: COLORS.primary,
              backgroundColor: COLORS.primary,
              borderRadius: 5,
              color: "white",
            }}>
            {STRINGS.download}
          </Button> */}
        </Row>
      </Col>
      <Col>
        <Row>
          <Button
            onClick={onZoomIn}
            shape="circle"
            className="btn-zoom"
            style={{
              borderColor: COLORS.sub_accent,
              backgroundColor:
                zoomType === "zoomIn" ? COLORS.sub_accent : COLORS.background,
              color:
                zoomType === "zoomIn" ? COLORS.background : COLORS.sub_accent,
              fontWeight: "900",
              fontSize: 19,
            }}
          >
            大
          </Button>
          <Button
            onClick={onZoomOut}
            className="btn-zoom"
            shape="circle"
            style={{
              borderColor: COLORS.sub_accent,
              backgroundColor:
                zoomType === "zoomOut" ? COLORS.sub_accent : COLORS.background,
              color:
                zoomType === "zoomOut" ? COLORS.background : COLORS.sub_accent,
              fontWeight: "900",
              fontSize: 19,
            }}
          >
            中
          </Button>
          <Button
            onClick={onZoomIpad}
            className="btn-zoom"
            shape="circle"
            style={{
              borderColor: COLORS.sub_accent,
              backgroundColor:
                zoomType === "ipad" ? COLORS.sub_accent : COLORS.background,
              color:
                zoomType === "ipad" ? COLORS.background : COLORS.sub_accent,
              fontWeight: "900",
              fontSize: 19,
            }}
          >
            小
          </Button>
          <Button
            disabled={isDisabled}
            onClick={onAbleModalAddStaff}
            style={{
              width: "fit-content",
              padding: "5px 20px",
              borderRadius: 4,
              color: color,
              border: borderColor,
              alignItems: "center",
              height: "auto",
            }}
          >
            {"+  " + STRINGS.add_staff}
          </Button>
        </Row>
      </Col>
    </Row>
  );
}

export default HeaderSchedule;
