/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Form, Row, message, Col, Radio, Space } from "antd";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";

import { LoadingView } from "../../elements";
import { COLORS, STRINGS } from "../../constants";
import { StaffBookingStep2 } from "../staff-booking-step2/StaffBookingStep2";
import { StaffBookingStep3 } from "../staff-booking-step3/StaffBookingStep3";
import { FetchApi } from "../../api";
import {
  StaffBookingBottomSubmit,
  StaffBookingDate,
  StaffBookingNumberPeople,
  StaffBookingSteps,
  StaffBookingUserContainer,
} from "./items";
import { StaffBookingCustomerInfo } from "./items/CustomerConfig/StaffBookingCustomerInfo";
import Auth from "../../store/Authentication";
import "./StaffBooking.scss";

import { useQueryClient } from "react-query";
import { staffScheduleKey } from "../staff-schedule/modules/staffScheduleKey";

function ContentBooking({ form }) {
  const [stepValue, setStepValue] = useState(form.getFieldValue("bookingStep"));
  const userInfo = form.getFieldValue("userInfo");
  const renderStep = () => {
    if (stepValue === 0) {
      return (
        <div>
          <Col className="booking-date-num">
            <StaffBookingCustomerInfo value={userInfo} />

            <Row style={{ width: "100%" }}>
              <Col style={{ minWidth: "40%" }}>
                <StaffBookingDate form={form} />
              </Col>
              <Col style={{ paddingTop: 10, minWidth: "15%" }}>
                <Form.Item name="suggestType" initialValue={"wish"}>
                  <Radio.Group
                    size="large"
                    onChange={(e) => {
                      form.setFieldsValue({
                        suggestType: e.target.value,
                      });
                    }}
                  >
                    <Space direction="vertical">
                      <Radio
                        value={"wish"}
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          color: COLORS.primary,
                        }}
                      >
                        {STRINGS.hour_book}
                      </Radio>
                      <Radio
                        value={"fixed"}
                        style={{
                          fontWeight: "bold",
                          fontSize: 20,
                          color: COLORS.primary,
                        }}
                      >
                        {STRINGS.fixed_time_booking}
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="bookingNum"
                  initialValue={form.getFieldValue("bookingNum") || 1}
                >
                  <StaffBookingNumberPeople form={form} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <StaffBookingUserContainer form={form} />
        </div>
      );
    }
    if (stepValue === 1) {
      return <StaffBookingStep2 form={form} />;
    }
    if (stepValue === 2) {
      return <StaffBookingStep3 form={form} />;
    }
  };

  return (
    <Form.Item
      name="contentBooking"
      shouldUpdate={(prevValues, curValues) => {
        if (curValues.bookingStep !== prevValues.bookingStep) {
          setStepValue(curValues.bookingStep);
        }
      }}
    >
      {renderStep()}
    </Form.Item>
  );
}

const StaffBooking = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  // const {toggle} = useAudio("https://goldfirestudios.com/proj/howlerjs/sound.ogg")

  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const infoStore = userAdmin.storeDTO;

  const onPressNextStep = async () => {
    const dataBooking = form.getFieldValue();
    const bookingNum = dataBooking.bookingNum;
    const currentStep = dataBooking.bookingStep;
    const dataSuggest = dataBooking.bookingSuggestTime;

    if (currentStep === 0) {
      // user havent select any combo
      for (let i = 1; i <= bookingNum; i++) {
        let arrValue = [];
        if (
          !dataBooking[`bookingMinute${i}`] &&
          !dataBooking[`bookingComboFav${i}`] &&
          !dataBooking[`bookingComboAll${i}`]
        ) {
          if (
            !dataBooking[`bookingComboStore${i}`] ||
            Object.keys(dataBooking[`bookingComboStore${i}`]).length === 0
          ) {
            message.warning(STRINGS.num_havent_select_course(i));
            return;
          }
          for (const [_, value] of Object.entries(
            dataBooking[`bookingComboStore${i}`]
          )) {
            arrValue.push(value);
          }
          if (arrValue.every((i) => !i)) {
            message.warning(STRINGS.num_havent_select_course(i));
            return;
          }
        }
      }

      form.setFieldsValue({ bookingStep: currentStep + 1 });
      return;
    }
    if (currentStep === 1) {
      if (!dataBooking.bookingSuggestTime) {
        return;
      }
      form.setFieldsValue({ bookingStep: currentStep + 1 });
    }
    if (currentStep === 2) {
      setLoading(true);
      const bookingRequests = [...Array(bookingNum)].map((item, index) => {
        const numberOrdinal = index + 1;
        let totalMinute = 0;
        let option = null;

        // the information booking for each person
        const comboStore = dataBooking[`bookingComboStore${numberOrdinal}`];
        const comboFav = dataBooking[`bookingComboFav${numberOrdinal}`];
        const comboAll = dataBooking[`bookingComboAll${numberOrdinal}`];
        const assignStaff = dataBooking[`bookingSelectStaff${numberOrdinal}`];
        const fixedStartTime = dataBooking.suggestType === "fixed";
        let assignStaffId =
          dataBooking[`bookingSelectStaff${numberOrdinal}`]?.staffId || null;
        let assignedSex = null;

        const comboMinutes =
          dataBooking[`bookingMinute${numberOrdinal}`] || null;
        let customerName =
          dataBooking[`bookingUserName${numberOrdinal}`] || null;
        if (numberOrdinal === 1) {
          customerName = dataBooking[`userInfo`]?.name || null;
        }

        // const customerPhone =
        //   dataBooking[`bookingPhoneNumber${numberOrdinal}`] || null;
        const customerPhone = dataBooking[`userInfo`]?.phoneNumber || null;
        const note = dataBooking[`bookingNote${numberOrdinal}`] || null;
        const listId = [comboAll?.id];
        const optionComboAll =
          dataBooking[`bookingComboAllNote${numberOrdinal}`] || [];
        if (comboMinutes) {
          totalMinute = comboMinutes;
        } else if (comboFav) {
          totalMinute = comboFav?.minutes;
        } else {
          if (comboAll) {
            totalMinute = comboAll.minutes;
          }
          if (comboStore && Object.keys(comboStore).length !== 0) {
            for (const [_, value] of Object.entries(comboStore)) {
              if (value?.priceDefault) {
                totalMinute = totalMinute + value.minutes;
              }
            }
          }
        }

        optionComboAll.forEach((e) => {
          option = option + e + ";";
          return option;
        });
        if (comboFav && comboFav?.id) {
          listId.push(comboFav.id);
        }
        comboStore &&
          Object.keys(comboStore).map((key) => {
            if (comboStore[key]?.id) {
              listId.push(comboStore[key].id);
            }
          });
        if (assignStaffId === "male" || assignStaffId === "female") {
          assignedSex = assignStaffId.toUpperCase();
          assignStaffId = null;
        }
        return {
          assigned: !!assignStaffId,
          comboFavourite: !!comboFav,
          comboPackIds: listId.filter((item) => !!item),
          customerName: customerName,
          customerPhone: numberOrdinal === 1 ? customerPhone : null,
          minutes: totalMinute,
          note: note,
          owner: numberOrdinal === 1,
          assignedSex: assignedSex,
          staffId: dataSuggest.pairs[index]?.timeRange?.staffId,
          restTime: dataSuggest.pairs[index]?.residualTime?.restTimeResult,
          designationFee: assignStaff?.designationFee || 0,
          fixedStartTime: fixedStartTime,
        };
      });
      const resultCreateOrder = await FetchApi.createOrderRoleAdmin({
        bookingRequests: bookingRequests,
        startTime: dataSuggest.startTime,
        storeId: infoStore.id,
        needRotate: dataSuggest.suggestBySwapping,
      });

      setLoading(false);
      if (resultCreateOrder.success) {
        await queryClient.refetchQueries(staffScheduleKey.paidBooking);
        message.success(STRINGS.create_booking_success);
        if (moment(searchParams.get("date"), "MM/DD/YYYY").isAfter(moment())) {
          navigate(`/manager-schedule?date=${searchParams.get("date")}`);
        } else {
          navigate("/");
        }

        return;
      }
      if (resultCreateOrder.code === 4101) {
        message.error(STRINGS.this_user_is_banned);
        return;
      }
      if (resultCreateOrder.code === 500) {
        message.error(STRINGS.server_error);
        return;
      }
      if (resultCreateOrder.code === 4000) {
        message.error(STRINGS.can_not_booking_in_this_time);
        return;
      }
      message.error(resultCreateOrder.message);
    }
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingView />;
    }
    return <ContentBooking form={form} />;
  };
  return (
    <div className="staff-booking">
      <Row
        style={{
          marginTop: -26,
          fontSize: 16,
          cursor: "pointer",
          marginBottom: 8,
          width: "fit-content",
        }}
        onClick={() => {
          navigate(-1);
        }}
      >
        {"<"} {STRINGS.back}
      </Row>
      <Form
        form={form}
        name="control-book"
        initialValues={{
          userInfo: location.state?.data,
        }}
      >
        <StaffBookingSteps form={form} onPressNextStep={onPressNextStep} />

        {renderContent()}

        <StaffBookingBottomSubmit
          form={form}
          onPressNextStep={onPressNextStep}
        />
      </Form>
    </div>
  );
};

export default StaffBooking;
