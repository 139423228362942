/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

import { useGetCalendarOrders } from "../../util/hooks/";
import { ErrorView, LoadingView } from "../../elements";
import Schedule from "./items/Schedule";
import HeaderSchedule from "./items/HeaderSchedule";

import "./StaffManagerSchedule.scss";

function StaffManagerSchedule() {
  const scheduleObj = useRef();
  let [searchParams] = useSearchParams();

  const [selectedDate, setSelectedDate] = useState(() => {
    if (searchParams?.get("date")) {
      return moment(searchParams.get("date"), "MM/DD/YYYY");
    }
    return moment();
  });
  const { calendarOrder, isLoadingCalendarOrder, refetchCalendarOrder } =
    useGetCalendarOrders(selectedDate.format("MM/DD/YYYY"));

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      refetchCalendarOrder();
    }, 30000);

    return () => {
      refetchInterval && clearInterval(refetchInterval);
    };
  }, []);

  const onCellScale = (type) => () => {
    if (type === "zoomIn") {
      scheduleObj.current.timeScale.slotCount = 6;
      scheduleObj.current.cssClass = "schedule-cell-dimension";
    }
    if (type === "zoomOut") {
      scheduleObj.current.timeScale.slotCount = 1;
      scheduleObj.current.timeScale.slotCount = 2;
      scheduleObj.current.cssClass = "";
    }
    if (type === "ipad") {
      scheduleObj.current.cssClass = "schedule-cell-dimension-2";
      scheduleObj.current.timeScale.slotCount = 1;
      scheduleObj.current.timeScale.slotCount = 2;
    }
  };

  if (isLoadingCalendarOrder) {
    return <LoadingView />;
  }

  if (calendarOrder && !calendarOrder.success) {
    return <ErrorView data={calendarOrder} />;
  }

  return (
    <div className="staff-manager-schedule">
      <HeaderSchedule
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        refetchCalendarOrder={refetchCalendarOrder}
        onCellScale={onCellScale}
      />
      {!!calendarOrder?.staffOrderDTOS && (
        <Schedule
          calendarOrder={calendarOrder}
          refetchCalendarOrder={refetchCalendarOrder}
          setSelectedDate={setSelectedDate}
          scheduleObj={scheduleObj}
          selectedDate={selectedDate}
        />
      )}
    </div>
  );
}

export default StaffManagerSchedule;
