import React, { useState } from "react";
import { Steps, Form } from "antd";

import { COLORS, STRINGS } from "../../../constants";
import { ButtonCustom } from "../../../elements";

import "../StaffBooking.scss";

const { Step } = Steps;

function StaffBookingSteps({ form, onPressNextStep }) {
  const [currentStep, setCurrentStep] = useState(
    form.getFieldValue("bookingStep")
  );
  const [disabled, setDisabled] = useState(currentStep === 1);
  let backgroundColor = COLORS.primary;
  if (disabled) {
    backgroundColor = COLORS.disabled;
  }
  return (
    <Form.Item
      name="bookingStep"
      initialValue={0}
      shouldUpdate={(prevValues, curValues) => {
        setCurrentStep(curValues.bookingStep);

        if (
          curValues.bookingStep === 1 &&
          !curValues.bookingSuggestTime
          // ||
          // !re.test(curValues.bookingPhoneNumber1))
        ) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      }}>
      <div className="header-booking">
        <Steps
          className="header-step"
          size="small"
          current={form.getFieldValue("bookingStep")}
          style={{ maxWidth: "40%" }}>
          <Step title={STRINGS.step_1} />
          <Step title={STRINGS.step_2} />
          <Step title={STRINGS.step_3} />
        </Steps>
        <ButtonCustom
          disabled={disabled}
          onClick={onPressNextStep}
          style={{
            backgroundColor,
            borderStyle: "none",
          }}>
          {currentStep === 2 ? STRINGS.sure : STRINGS.next}
        </ButtonCustom>
      </div>
    </Form.Item>
  );
}

export { StaffBookingSteps };
