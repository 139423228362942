// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".manager-review .header-review {\n  width: 100%;\n  max-height: 60px;\n  height: 60px;\n  display: flex;\n  justify-content: space-between;\n}\n.manager-review .content-review {\n  margin-top: 10px;\n}", "",{"version":3,"sources":["webpack://./src/components/manager-review/Review.scss"],"names":[],"mappings":"AAEE;EACE,WAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;AASJ;AAPE;EACE,gBAAA;AASJ","sourcesContent":["@import \"../../util/variables.scss\";\n.manager-review {\n  .header-review {\n    width: 100%;\n    max-height: 60px;\n    height: 60px;\n    display: flex;\n    justify-content: space-between;\n  }\n  .content-review {\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainColor": "#4b2a05",
	"textColor": "#f19834",
	"redColor": "#FF0C0C",
	"grey1": "#efefef",
	"grey2": "#757575",
	"black1": "#303030",
	"borderColor": "#cbcbcb"
};
export default ___CSS_LOADER_EXPORT___;
