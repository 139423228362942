// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".upload-audio .avatar-uploader > .ant-upload {\n  width: 100%;\n  height: 230px;\n  margin-right: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/upload-audio/UploadAudio.scss"],"names":[],"mappings":"AAKE;EACE,WAAA;EACA,aAAA;EACA,kBAAA;AAMJ","sourcesContent":["@import \"../../util/variables.scss\";\n@import \"../../util/mixins.scss\";\n\n.upload-audio {\n  \n  .avatar-uploader > .ant-upload {\n    width: 100%;\n    height: 230px;\n    margin-right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainColor": "#4b2a05",
	"textColor": "#f19834",
	"redColor": "#FF0C0C",
	"grey1": "#efefef",
	"grey2": "#757575",
	"black1": "#303030",
	"borderColor": "#cbcbcb"
};
export default ___CSS_LOADER_EXPORT___;
