import React, { useRef, useState } from "react";
import { Col, Row, message } from "antd";

import {
  ButtonCustom,
  LoadingAbsolute,
  ModalCustomService,
} from "../../../elements";
import { STRINGS } from "../../../constants";
import { FetchApi } from "../../../api";
import { useStaffManualBooking } from "../../../store/StateStore";
import RadioAllStoreStaff from "../../../elements/RadioAllStoreStaff";

import "../BookingManual.scss";

export function ModalBookingManualSelectStaff({ navigate }) {
  const [loading, setLoading] = useState(false);
  const staffChangeRequest = useRef({});
  const { setStaffInfoLocal } = useStaffManualBooking();
  const onValidatePassword = async () => {
    if (!staffChangeRequest.current.id) {
      message.warning(STRINGS.please_select_a_reception_staff);
      return;
    }
    if (!staffChangeRequest.current.password) {
      message.warning(STRINGS.please_type_password);
      return;
    }

    try {
      setLoading(true);

      const result = await FetchApi.validateStaffPassword({
        staffId: staffChangeRequest.current.id,
        password: staffChangeRequest.current.password,
      });
      setLoading(false);
      if (result.code === 2) {
        message.warning(STRINGS.password_is_incorrect);
        return;
      }
      navigate("/booking-manual");
      setStaffInfoLocal({ ...staffChangeRequest.current });
      ModalCustomService.close();
    } catch (error) {
      setLoading(false);
      message.error(STRINGS.something_wrong);
    }
  };
  const onCancel = () => {
    ModalCustomService.close();
  };

  return (
    <Col>
      <Col style={{ padding: 10 }}>
        <RadioAllStoreStaff
          filterStaff
          onChange={(e) => (staffChangeRequest.current = e)}
        />

        <Row justify="center" style={{ margin: "20px 0px" }}>
          <ButtonCustom
            onClick={onCancel}
            isCancelButton
            style={{
              marginRight: 10,
            }}>
            {STRINGS.cancel}
          </ButtonCustom>
          <ButtonCustom onClick={onValidatePassword}>
            {STRINGS.sure}
          </ButtonCustom>
        </Row>
      </Col>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}
