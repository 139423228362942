import React, { useState } from "react";
import { Col, Row, Form } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { COLORS, STRINGS } from "../../../constants";

import "../StaffBooking.scss";

function StaffBookingComboFavItem({
  combo,
  idFatherCombo,
  selectedFav,
  setSelectedFav,
  form,
  numberOrdinal,
}) {
  const renderSelect = () => {
    if (combo.id === selectedFav?.id) {
      return <div className="radio-button-select" />;
    }
  };
  return (
    <Col
      onClick={() => {
        if (combo.id === selectedFav?.id) {
          setSelectedFav();
          form.setFieldsValue({
            [`bookingComboFav${numberOrdinal}`]: null,
          });
          return;
        }

        form.setFieldsValue({
          [`bookingComboAll${numberOrdinal}`]: null,
          [`bookingMinute${numberOrdinal}`]: null,
          [`bookingComboStore${numberOrdinal}`]: null,
          [`bookingComboFav${numberOrdinal}`]: {
            ...combo,
            idFatherCombo: idFatherCombo,
          },
        });
        setSelectedFav(combo);
      }}
      style={{
        borderRadius: "5px",
        flex: 1,
        margin: "5px",
        cursor: "pointer",
      }}>
      <Row
        className="minute-combo"
        style={{
          backgroundColor: COLORS.primary,
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          padding: "10px 20px",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div
          className="radio-button"
          style={{ position: "absolute", left: "20px" }}>
          {renderSelect()}
        </div>
        <div>
          {combo.minutes}
          {STRINGS.minute}
        </div>
      </Row>
      <Row
        style={{
          border: `1px solid ${COLORS.border}`,
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          backgroundColor: COLORS.background,
        }}>
        <Col span={8} style={{ padding: "10px 5px" }}>
          <div className="price_member">{STRINGS.app_member + "\n"}</div>
          <div className="price_member_yen">
            {combo.priceMemberApp}
            {STRINGS.yen}
          </div>
        </Col>
        <Col span={8} style={{ padding: "10px 5px" }}>
          <div className="price_text">{STRINGS.app_non_member + "\n\n\n"}</div>
          <div className="price_text_yen">
            {combo.priceNonApp} {STRINGS.yen}
          </div>
        </Col>
        <Col span={8} style={{ padding: "10px 5px" }}>
          <div className="price_text">{STRINGS.list_price + "\n"}</div>
          <div className="price_text_yen">
            {combo.priceDefault} {STRINGS.yen}
          </div>
        </Col>
      </Row>
    </Col>
  );
}

function StaffBookingComboFavList({
  item,
  setSelectedFav,
  selectedFav,
  form,
  numberOrdinal,
}) {
  const [isShowComponent, setIsShowComponent] = useState(false);
  const packComboFav = Array.isArray(item.comboPackDTOS)
    ? item.comboPackDTOS
    : [];
  const comboFavSelected = form.getFieldValue(
    `bookingComboFav${numberOrdinal}`
  );
  console.log("comboFavSelected", comboFavSelected);
  let rotate = 0;
  let backgroundColor = COLORS.background;
  if (isShowComponent) {
    rotate = 180;
  }
  if (comboFavSelected?.idFatherCombo === item.id) {
    backgroundColor = COLORS.sub_primary;
  }
  return (
    <Col
      onClick={() => {
        setIsShowComponent(!isShowComponent);
      }}
      style={{
        border: `1px solid ${COLORS.border}`,
        padding: "10px",
        width: "48%",
        borderRadius: "5px",
        height: "fit-content",
        backgroundColor,
        marginBottom: "10px",
      }}>
      <Row style={{ justifyContent: "space-between" }}>
        <Row className="text-des">{item.name}</Row>
        <Row
          style={{
            flex: 1,
            justifyContent: "flex-end",
          }}>
          <DownOutlined rotate={rotate} style={{ paddingTop: "5px" }} />
        </Row>
      </Row>
      {isShowComponent && (
        <Col>
          <Col
            style={{
              textAlign: "center",
              padding: "10px",
              color: COLORS.sub_accent,
              fontWeight: "bold",
            }}>
            {item.description}
          </Col>
          <Row style={{ justifyContent: "space-between" }}>
            {packComboFav.map((combo) => {
              return (
                <Col
                  xl={12}
                  xs={24}
                  key={`${combo.id}`}
                  style={{ marginBottom: "10px" }}>
                  <StaffBookingComboFavItem
                    combo={combo}
                    form={form}
                    selectedFav={selectedFav}
                    setSelectedFav={setSelectedFav}
                    numberOrdinal={numberOrdinal}
                    idFatherCombo={item.id}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
      )}
    </Col>
  );
}

function StaffBookingComboFav({ form, data = [], numberOrdinal }) {
  const [selectedFav, setSelectedFav] = useState(
    form.getFieldValue(`bookingComboFav${numberOrdinal}`)
  );

  return (
    <Form.Item
      name={`bookingComboFav${numberOrdinal}`}
      shouldUpdate={(prevValues, curValues) => {
        const comboStore = curValues[`bookingComboStore${numberOrdinal}`];
        if (
          curValues[`bookingMinute${numberOrdinal}`] ||
          curValues[`bookingComboAll${numberOrdinal}`] ||
          (comboStore && Object.keys(comboStore)?.length !== 0)
        ) {
          setSelectedFav();
        }
      }}>
      <Col>
        <div className="text-combo" style={{ paddingTop: 0 }}>
          {STRINGS.combo_recommend}
        </div>
        <Row style={{ justifyContent: "space-between" }}>
          {data.map((item) => {
            return (
              <StaffBookingComboFavList
                key={`${item.id}`}
                form={form}
                item={item}
                selectedFav={selectedFav}
                setSelectedFav={setSelectedFav}
                numberOrdinal={numberOrdinal}
              />
            );
          })}
        </Row>
      </Col>
    </Form.Item>
  );
}

export { StaffBookingComboFav };
