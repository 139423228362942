import React, { useCallback, useEffect, useRef, useState } from "react";
import { Avatar, Dropdown, Menu, Layout, Row, Col, Button } from "antd";
import {
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";

import { COLORS, STRINGS } from "../../../constants";
import { HeaderService } from "../../../services/HeaderService";
import { AccountService } from "../../../services/AccountService";
import { MenuCollapsingService } from "../../../services/MenuCollapsingService";
import { HeaderStaffScheduleService } from "../../../services/HeaderStaffScheduleService";
import { ModalCustomService } from "../../../elements";
import { ModalRegCustomerBooking } from "../../staff-booking/items/CustomerConfig/ModalRegCustomerBooking";
import { ModalBookingManualSelectStaff } from "../../booking-manual/items/ModalBookingManualSelectStaff";
import { useCheckStoreHaveBooking } from "../modules/useCheckStoreHaveBooking";
import Auth from "../../../store/Authentication";

const { Header } = Layout;

function MainHeader({ handleLogout, setSelectedKey, collapsed }) {
  const navigate = useNavigate();
  const location = useLocation();
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const [userName, setUserName] = useState(userAdmin?.name || "");
  const [header, setHeader] = useState("");
  const [zoomType, setZoomType] = useState("zoomIn");
  const { isBookingEnable, isLoading } = useCheckStoreHaveBooking();

  const currentRouteName = useRef();
  useEffect(() => {
    AccountService.onChange("MainHeader", (n) => {
      setUserName(n.name);
    });
    HeaderService.onChange("MainHeader", (headerName) => {
      setHeader(headerName);
      if (currentRouteName.current !== STRINGS.online_reservation_reception) {
        setZoomType("zoomIn");
      }
      currentRouteName.current = headerName;
    });
    return () => {
      HeaderService.deleteKey("MainHeader");
      AccountService.deleteKey("MainHeader");
    };
  }, []);
  const onZoomOut = useCallback(() => {
    setZoomType("zoomOut");
    HeaderStaffScheduleService.set("zoomOut");
  }, []);
  const onZoomIn = useCallback(() => {
    setZoomType("zoomIn");
    HeaderStaffScheduleService.set("zoomIn");
  }, []);
  const onZoomIpad = useCallback(() => {
    setZoomType("ipad");
    HeaderStaffScheduleService.set("ipad");
  }, []);
  const handleAbleModalSearch = useCallback(
    () => HeaderStaffScheduleService.set("onAbleModalSearch"),
    []
  );
  const handleAbleModalSpeechRecog = useCallback(
    () => HeaderStaffScheduleService.set("onAbleModalSpeechRecog"),
    []
  );

  const setServiceHeader = (e) => {
    HeaderService.set(e.key);
    setSelectedKey(null);
  };

  const menu = (
    <Menu className="profile-dropdown" style={{ top: -12 }}>
      {userAdmin && userAdmin.type === "SYSTEM_ADMIN" && (
        <>
          <Menu.Item
            key="profile"
            onClick={(e) => {
              setServiceHeader(e);
              navigate("/profile");
            }}
          >
            {STRINGS.profile}
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      <Menu.Item key="logout" onClick={handleLogout}>
        {STRINGS.logout}
      </Menu.Item>
    </Menu>
  );

  const renderHeaderStaffSchedule = () => {
    if (isLoading) {
      return null;
    }
    return (
      <Row style={{ justifyContent: "flex-end", alignItems: "center" }}>
        <Col style={{ alignSelf: "center", marginRight: 20 }}>
          <Row>
            <Button
              onClick={onZoomIn}
              shape="circle"
              className="btn-zoom"
              style={{
                borderColor: COLORS.sub_accent,
                backgroundColor:
                  zoomType === "zoomIn" ? COLORS.sub_accent : COLORS.background,
                color:
                  zoomType === "zoomIn" ? COLORS.background : COLORS.sub_accent,
                fontWeight: "900",
                fontSize: 19,
              }}
            >
              大
            </Button>
            <Button
              onClick={onZoomOut}
              className="btn-zoom"
              shape="circle"
              style={{
                borderColor: COLORS.sub_accent,
                backgroundColor:
                  zoomType === "zoomOut"
                    ? COLORS.sub_accent
                    : COLORS.background,
                color:
                  zoomType === "zoomOut"
                    ? COLORS.background
                    : COLORS.sub_accent,
                fontWeight: "900",
                fontSize: 19,
              }}
            >
              中
            </Button>
            <Button
              onClick={onZoomIpad}
              className="btn-zoom"
              shape="circle"
              style={{
                borderColor: COLORS.sub_accent,
                backgroundColor:
                  zoomType === "ipad" ? COLORS.sub_accent : COLORS.background,
                color:
                  zoomType === "ipad" ? COLORS.background : COLORS.sub_accent,
                fontWeight: "900",
                fontSize: 19,
              }}
            >
              小
            </Button>
          </Row>
        </Col>

        <Button
          icon={<SearchOutlined />}
          onClick={handleAbleModalSearch}
          style={{
            height: "fit-content",
            fontSize: 20,
            alignSelf: "flex-start",
            borderRadius: 4,
            border: `1px solid ${COLORS.primary_2}`,
            backgroundColor: COLORS.primary_2,
            color: "white",
            padding: "4px 12px",
            fontWeight: "bold",
            marginRight: 10,
          }}
        >
          {STRINGS.search_booking}
        </Button>
        <Button
          onClick={() => {
            ModalCustomService.set({
              title: STRINGS.registration,
              wrapperStyle: { width: "auto" },
              contentWrapperStyle: { width: "fit-content" },
              contentStyle: { maxHeight: "80vh", padding: 0 },
              content: <ModalRegCustomerBooking data={{}} />,
            });
          }}
          style={{
            height: "fit-content",
            fontSize: 20,
            alignSelf: "flex-start",
            borderRadius: 4,
            border: `1px solid ${COLORS.sub_accent}`,
            backgroundColor: COLORS.sub_accent,
            color: "white",
            padding: "4px 12px",
            fontWeight: "bold",
            marginRight: 10,
          }}
        >
          {STRINGS.reg_new_user_booking}
        </Button>
        {/* {!isBookingEnable ? (
          <Button
            onClick={() => {
              ModalCustomService.set({
                title: STRINGS.password_manual_booking,
                wrapperStyle: { width: "auto" },
                contentWrapperStyle: { width: "fit-content" },
                contentStyle: { maxHeight: "80vh", padding: 0 },
                content: <ModalBookingManualSelectStaff navigate={navigate} />,
              });
            }}
            style={{
              height: "fit-content",
              fontSize: 20,
              alignSelf: "flex-start",
              borderRadius: 4,
              border: `1px solid ${COLORS.accent_3}`,
              backgroundColor: COLORS.accent_3,
              color: COLORS.sub_accent,
              padding: "4px 12px",
              fontWeight: "bold",
              marginRight: 10,
            }}>
            {isBookingEnable ? STRINGS.history_examine : STRINGS.input}
          </Button>
        ) : null} */}
        <Button
          onClick={() => {
            if (location.pathname === "/staff-booking") {
              return;
            }
            handleAbleModalSpeechRecog();
          }}
          style={{
            height: "fit-content",
            fontSize: 20,
            alignSelf: "flex-start",
            borderRadius: 4,
            border: `1px solid ${COLORS.secondary}`,
            backgroundColor: COLORS.secondary,
            color: "white",
            padding: "4px 12px",
            fontWeight: "bold",
            marginRight: 20,
          }}
        >
          {STRINGS.reserve}
        </Button>
      </Row>
    );
  };

  if (userAdmin.type !== "SYSTEM_ADMIN" && userAdmin.type !== "STORE_ADMIN") {
    return (
      <Header className="header">
        <Row
          style={{
            padding: "0px 0px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Row style={{ alignSelf: "center" }}>
            <Row
              onClick={() => MenuCollapsingService.set()}
              style={{
                width: "fit-content",
                alignSelf: "center",
                padding: "10px 20px",
                marginLeft: 16,
                borderRadius: 5,
                cursor: "pointer",
                backgroundColor: COLORS.primary,
              }}
            >
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ color: "white", transform: `scale(1.5)` }}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ color: "white", transform: `scale(1.5)` }}
                />
              )}
            </Row>
            <Col
              style={{
                fontSize: 20,
                fontWeight: "bold",
                fontFamily: "sans-serif",
                marginLeft: 200,
              }}
            >
              {header}
            </Col>
            <Col
              style={{
                fontSize: 20,
                fontWeight: "bold",
                fontFamily: "sans-serif",
                marginLeft: 25,
              }}
            >
              <Row style={{ alignItems: "center" }}>
                <Avatar
                  className="avatar-profile-header"
                  style={{ backgroundColor: COLORS.accent_4 }}
                  icon={<UserOutlined />}
                />
                <div style={{ color: COLORS.accent, paddingLeft: 7 }}>
                  {userAdmin?.storeDTO?.name}
                </div>
              </Row>
            </Col>
          </Row>

          {(header === STRINGS.booking_today ||
            header === STRINGS.treatment_reception) && (
            <Col style={{ alignSelf: "center" }}>
              {renderHeaderStaffSchedule()}
            </Col>
          )}
        </Row>
      </Header>
    );
  }

  return (
    <Header className="header">
      <Dropdown overlay={menu} trigger={["click"]}>
        <div className="profile-header">
          <Avatar
            className="avatar-profile-header"
            style={{ backgroundColor: COLORS.accent_4 }}
            icon={<UserOutlined />}
          />
          <div className="profile-name">{userName}</div>
        </div>
      </Dropdown>
      <img
        onClick={() => (window.location.href = "/")}
        style={{
          width: 190,
          height: 47.5,
          alignSelf: "center",
          cursor: "pointer",
        }}
        src={
          "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/4b1587c2-9bf4-43bb-9067-3cd734f35419b47ba7a6-96a7-4440-9533-a03944b4ac1190e8ae58-f51e-4dc2-a2de-31c9ec5caa07c4e2b4b0-bf5c-4bb4-b386-c5d0dae1f94c.png"
        }
        alt="logo"
      />
      <div className="label-header">{header}</div>
    </Header>
  );
}

export default MainHeader;
