/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import { Table, Col, Space, Row, message } from "antd";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { FetchApi } from "../../api";
import {
  AlertService,
  ButtonCustom,
  Convert,
  FilterByTime,
  ModalCustomService,
  SvgView,
} from "../../elements";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import { ModalBookingManualSelectUser } from "./items/ModalBookingManualSelectUser";
import { COLORS, STRINGS, LOGIC, Svgs } from "../../constants";
import { useStaffManualBooking } from "../../store/StateStore";
import { useCheckStoreHaveBooking } from "../layout/modules/useCheckStoreHaveBooking";
import { useGetCalendarOrders } from "../../util/hooks";
import BookingManualDetail from "../booking-manual-detail/BookingManualDetail";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";
import Auth from "../../store/Authentication";

import "./BookingManual.scss";

const formatsu = "YYYY-MM-DD";
export default function BookingManual() {
  const navigate = useNavigate();
  const startTimeRef = useRef(moment().format(formatsu));
  const endTimeRef = useRef(moment().format(formatsu));
  const { staffInfoLocal, setStaffInfoLocal } = useStaffManualBooking();
  const { isBookingEnable } = useCheckStoreHaveBooking();
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  useGetCalendarOrders(moment().format("MM/DD/YYYY"));
  const searchText = useRef(null);

  const page = useRef(1);
  const size = useRef(10);

  useEffect(() => {
    return () => {
      setStaffInfoLocal();
    };
  }, []);

  const { data, refetch, isLoading } = useQuery(
    [`getHistoryBookingManual`],
    () => {
      return FetchApi.getBookingManualHistory({
        size: size.current,
        page: page.current,
        staffId: staffInfoLocal?.id,
        searchText: searchText.current,
        from: moment(startTimeRef.current).toISOString(),
        to: moment(endTimeRef.current).toISOString(),
      });
    }
  );
  useScrollByMouse(data);

  const columns = [
    {
      title: "No",
      key: "index",
      dataIndex: "index",
      fixed: "left",
      width: 70,
      sorter: (a, b) => a.index - b.index,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: STRINGS.day_go_store,
      dataIndex: "start",
      key: "start",
      width: 150,
      render: (e) => {
        const date = new Date(e);
        return moment(date).format(LOGIC.timeFormat);
      },
      align: "center",
    },
    {
      title: "ID",
      dataIndex: "customerId",
      key: "customerId",
      width: 150,
      align: "center",
      render: (e) => {
        return e || "ー";
      },
    },
    {
      title: STRINGS.customer_name,
      dataIndex: "customerName",
      key: "customerName",
      width: 120,
      align: "center",
      render: (e, dataItem) => {
        return Convert.removeSpaceName(e || dataItem?.user?.name);
      },
    },
    {
      title: STRINGS.name2,
      dataIndex: "assigned",
      key: "assigned",
      width: 80,
      align: "center",
      render: (e) => {
        if (e) {
          return (
            <Row style={{ justifyContent: "center" }}>
              <div
                style={{
                  backgroundColor: COLORS.primary,
                  width: 16,
                  height: 16,
                  borderRadius: "50%",
                }}
              />
            </Row>
          );
        }
      },
    },
    {
      title: STRINGS.course,
      dataIndex: "packs",
      key: "packs",
      width: 150,
      render: (e) => {
        if (Array.isArray(e)) {
          return e.map((item, index) => (
            <Col key={`${index}`}>{item.courseName}</Col>
          ));
        }
      },
      align: "center",
    },
    {
      title: STRINGS.time,
      key: "packsMinute",
      dataIndex: "packs",
      render: (e) => {
        if (Array.isArray(e)) {
          return e.map((item, index) => {
            if (item.packMinutes) {
              return (
                <Col key={`${index}`}>
                  {item.packMinutes}
                  {STRINGS.minute}
                </Col>
              );
            }
            return "-";
          });
        }
      },
      width: 80,
      align: "center",
    },
    {
      title: STRINGS.extension,
      dataIndex: "packs",
      key: "prolongedPacks",
      render: (e) => {
        if (Array.isArray(e)) {
          return e.map((item, index) => {
            if (item.prolongedMinutes) {
              return (
                <Col key={`${index}`}>
                  {item.prolongedMinutes}
                  {STRINGS.minute}
                </Col>
              );
            }
            return "";
          });
        }
      },
      width: 120,
      align: "center",
    },
    {
      title: STRINGS.total,
      key: "amount",
      dataIndex: "amount",
      width: 120,
      align: "center",
      render: (e) => `${LOGIC.currencyFormatter(e)}`,
    },
    {
      title: STRINGS.price_job,
      key: "staffWage",
      dataIndex: "staffWage",
      render: (e) => (
        <div
          style={{ color: COLORS.primary, fontWeight: "bold", fontSize: 16 }}>
          {LOGIC.currencyFormatter(e)}
        </div>
      ),
      width: 150,
      align: "center",
    },
    {
      title: STRINGS.remarks,
      key: "comment",
      dataIndex: "comment",
      width: 250,
      align: "center",
    },

    {
      title: STRINGS.responsible_person,
      dataIndex: "staffName",
      key: "staffName",
      width: 120,
      align: "center",
    },
    {
      title: "",
      key: "action",
      dataIndex: "action",
      render: (text, record) => (
        <>
          {!!staffInfoLocal?.id && (
            <Space size={10}>
              {!isBookingEnable && (
                <button
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                    cursor: "pointer",
                  }}
                  onClick={onEdit(record)}>
                  <SvgView svgData={Svgs.edit} />
                </button>
              )}

              <button
                className="button-delete"
                style={{
                  border: 0,
                  backgroundColor: "transparent",
                  ":hover": {
                    color: COLORS.background,
                    cursor: "pointer",
                  },
                }}
                onClick={removeBooking(record)}>
                <SvgView svgData={Svgs.delete} />
              </button>
            </Space>
          )}
        </>
      ),

      width: 100,
      fixed: "right",
    },
  ];

  const onEdit = (record) => () => {
    ModalCustomService.set({
      title: STRINGS.info_booking_manual,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: <BookingManualDetail data={{ ...record }} />,
    });
  };

  const removeBooking = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: STRINGS.sure_delete_booking,
      onConfirm: async () => {
        let result = await FetchApi.deleteBookingManual({
          bookingHistoryId: item.id,
        });
        if (result.success) {
          refetch();
          message.success(STRINGS.delete_success);
        } else {
          message.error(result.message || STRINGS.delete_failed);
        }
      },
    });
  };

  const onAddBookingManual = () => {
    ModalCustomService.set({
      title: STRINGS.search_booking,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: <ModalBookingManualSelectUser navigate={navigate} />,
    });
  };
  let backgroundColor = COLORS.disabled;
  let borderColor = COLORS.disabled;
  if (staffInfoLocal?.id) {
    backgroundColor = COLORS.primary;
    borderColor = COLORS.primary;
  }
  return (
    <div className="manager-user">
      <Row className="header-user" align="bottom" justify="space-between" wrap>
        <Row align="bottom">
          <FilterByTime
            key={endTimeRef.current + startTimeRef.current}
            label={STRINGS.start_time}
            onChange={(e) => (startTimeRef.current = e)}
            picker="date"
            format={LOGIC.dateFormat}
            value={startTimeRef.current}
            formatOnChange={formatsu}
            allowClear={false}
          />
          <Col style={{ margin: "0px 10px" }}>
            <FilterByTime
              key={endTimeRef.current + startTimeRef.current}
              picker="date"
              label={STRINGS.end_time}
              onChange={(e) => (endTimeRef.current = e)}
              format={LOGIC.dateFormat}
              formatOnChange={formatsu}
              value={endTimeRef.current}
              allowClear={false}
            />
          </Col>
          <ButtonCustom
            onClick={() => {
              if (
                moment(endTimeRef.current).isBefore(
                  moment(startTimeRef.current)
                )
              ) {
                const end = endTimeRef.current;
                endTimeRef.current = startTimeRef.current;
                startTimeRef.current = end;
              }
              refetch();
            }}>
            {STRINGS.filter}
          </ButtonCustom>
          <ButtonCustom
            style={{
              marginLeft: 10,
            }}
            onClick={async () => {
              const result = await FetchApi.copyBookingManualData({
                from: moment(startTimeRef.current).format(
                  "YYYY-MM-DD 00:00:00"
                ),
                to: moment(endTimeRef.current).format("YYYY-MM-DD 00:00:00"),
                storeId: userAdmin?.storeDTO?.id,
              });
              if (result.success) {
                message.success(STRINGS.done_successful);
                return;
              }
              message.error(STRINGS.server_error);
            }}>
            {STRINGS.copy_button}
          </ButtonCustom>
        </Row>
      </Row>
      <Row style={{ alignItems: "center", justifyContent: "space-between" }}>
        <Col
          style={{
            fontSize: 22,
            fontWeight: "bold",
            color: COLORS.sub_accent,
          }}>
          {!!staffInfoLocal?.name
            ? `${STRINGS.staff2}:\xa0\xa0${staffInfoLocal.name}`
            : ""}
        </Col>
        {!isBookingEnable && (
          <ButtonCustom
            disabled={!staffInfoLocal?.id}
            style={{
              color: COLORS.background,
              fontWeight: "bold",
              float: "right",
              marginBottom: 5,
              backgroundColor,
              borderColor,
            }}
            onClick={onAddBookingManual}>
            {STRINGS.add_math}
          </ButtonCustom>
        )}
      </Row>

      <div className="content-user">
        <Table
          loading={isLoading}
          rowKey={"id"}
          columns={columns}
          dataSource={data ? data.data : []}
          locale={{ emptyText: STRINGS.no_data }}
          pagination={false}
          scroll={{ x: 1650, y: "calc(100vh - 330px)" }}
          summary={() => (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} colSpan={8} align="right">
                  {STRINGS.total_income}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} colSpan={1} align="center">
                  <div
                    style={{
                      fontSize: 16,
                      color: COLORS.primary,
                      fontWeight: "bold",
                    }}>
                    {LOGIC.currencyFormatter(data?.revenue?.totalAmount || 0)}
                  </div>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} colSpan={1} align="center">
                  <div
                    style={{
                      fontSize: 16,
                      color: COLORS.primary,
                      fontWeight: "bold",
                    }}>
                    {LOGIC.currencyFormatter(
                      data?.revenue?.totalStaffWage || 0
                    )}
                  </div>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        <PaginationWithSizeChange
          size={size.current}
          total={data?.total}
          onChange={(pageNumber) => {
            page.current = pageNumber;
            refetch();
          }}
          onShowSizeChange={(e) => {
            size.current = e.size;
            page.current = 1;
            refetch();
          }}
          current={page.current}
        />
      </div>
    </div>
  );
}
