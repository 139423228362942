import React, { useState } from "react";
import { Col, Row, Form, Input, message } from "antd";

import { STRINGS } from "../../../constants";
import { FetchApi } from "../../../api";
import { Validate } from "../../../elements/Validate";
import {
  ButtonCustom,
  LoadingAbsolute,
  ModalCustomService,
} from "../../../elements";
import "../StoreStaffAccount.scss";

function Add({ setData, data }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onAdd = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      setLoading(true);
      const result = await FetchApi.createStaffAdmin(fieldData);
      if (result.success) {
        ModalCustomService.close();
        setData([result.staffAdminDTO, ...(data?.staffAdminDTOS || [])]);
        message.success(STRINGS.add_staff_successful);
      } else if (result.message === "Email exist!") {
        setLoading(false);
        message.error(STRINGS.email_is_existed);
      } else {
        setLoading(false);
        message.error(result.message || STRINGS.add_staff_failed);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return (
    <Col>
      <Form
        labelCol={{ span: 100 }}
        wrapperCol={{ span: 100 }}
        layout="vertical"
        form={form}>
        <Col>
          <Form.Item
            required
            name="name"
            label={STRINGS.name}
            rules={[Validate.emptyContent]}>
            <Input />
          </Form.Item>
          <Form.Item
            required
            name="email"
            label={STRINGS.email}
            rules={[Validate.email, Validate.emptyContent]}>
            <Input />
          </Form.Item>
          <Form.Item
            required
            name="password"
            label={STRINGS.password}
            rules={[Validate.validatePassword]}>
            <Input.Password />
          </Form.Item>
        </Col>
      </Form>

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onAdd}>{STRINGS.save}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}

export default Add;
