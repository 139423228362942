import React from "react";
import { Col, DatePicker, Form, Input, Radio, Row } from "antd";
import moment from "moment";

import { COLORS, STRINGS, Svgs } from "../../../constants";
import { useStaffManualBooking } from "../../../store/StateStore";
import { BookingManualDetailCoupon } from "./BookingManualDetailCoupon";
import Auth from "../../../store/Authentication";

export function BookingManualInfo({ data, listCoupon, setTotal }) {
  const { staffInfoLocal } = useStaffManualBooking();
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const startTime = moment(userAdmin.storeDTO?.openTime, "HH:mm");
  const endTime = moment(userAdmin.storeDTO?.closeTime, "HH:mm");
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  return (
    <Row
      style={{
        margin: "12px 0px 5px 0px",
      }}>
      <Col span={12} style={{ fontWeight: "bold" }}>
        <Row
          style={{
            textAlign: "left",
            marginTop: 4,
          }}>
          <Col
            span={8}
            style={{
              fontWeight: "bold",
              color: COLORS.sub_accent,
              fontSize: 22,
            }}>
            {STRINGS.day_go_store}
          </Col>
          <Col span={16}>
            <Form.Item style={{ marginBottom: 0 }} name="startTime">
              <DatePicker
                disabledTime={(e) => {
                  const hourStart = startTime.hour();
                  const hourEnd = endTime.hour();
                  if (hourStart < hourEnd) {
                    const timeDisable = range(0, hourStart).concat(
                      range(hourEnd + 1, 24)
                    );

                    return {
                      disabledHours: () => timeDisable,
                    };
                  } else {
                    return {
                      disabledHours: () => range(hourEnd, hourStart),
                    };
                  }
                }}
                suffixIcon={Svgs.datepicker()}
                style={{
                  borderRadius: 5,
                  width: "90%",
                  border: `1px solid ${COLORS.sub_text}`,
                }}
                placeholder={""}
                format={"MM月DD日 HH:mm"}
                showTime
                minuteStep={10}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            textAlign: "left",
            marginTop: 14,
          }}>
          <Col
            span={8}
            style={{
              fontWeight: "bold",
              color: COLORS.sub_accent,
              fontSize: 22,
            }}>
            {STRINGS.responsible_person}
          </Col>
          <Col
            span={16}
            style={{
              fontWeight: "bold",
              color: COLORS.sub_accent,
              fontSize: 22,
            }}>
            {staffInfoLocal?.name}
          </Col>
        </Row>
        <Row
          style={{
            textAlign: "left",
            marginTop: 10,
            alignItems: "center",
          }}>
          <Col
            span={8}
            style={{
              fontWeight: "bold",
              color: COLORS.sub_accent,
              fontSize: 22,
            }}>
            {STRINGS.coupon_management}
          </Col>
          <Col span={16}>
            <Form.Item style={{ marginBottom: 0 }} name="couponIds">
              <BookingManualDetailCoupon
                setTotal={setTotal}
                data={data}
                listCoupon={listCoupon}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={12} style={{ fontWeight: "bold" }}>
        <Row
          style={{
            textAlign: "left",
            alignItems: "center",
          }}>
          <Col
            span={8}
            style={{
              fontWeight: "bold",
              color: COLORS.sub_accent,
              fontSize: 22,
            }}>
            {STRINGS.is_assign}
          </Col>
          <Col span={16}>
            <Form.Item name="assigned" style={{ marginBottom: 0 }}>
              <Radio.Group size="large">
                <Radio
                  value={true}
                  style={{ fontSize: 22, color: COLORS.sub_accent }}>
                  {STRINGS.yes}
                </Radio>
                <Radio
                  value={false}
                  style={{ fontSize: 22, color: COLORS.sub_accent }}>
                  {STRINGS.no}
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            textAlign: "left",
            marginTop: 20,
            alignItems: "center",
          }}>
          <Col
            span={8}
            style={{
              fontWeight: "bold",
              color: COLORS.sub_accent,
              fontSize: 22,
            }}>
            {STRINGS.discount_point}
          </Col>
          <Col span={16}>
            <Form.Item style={{ marginBottom: 0 }} name="numberOfPointsUsed">
              <Input
                disabled={(data.user?.customerType || data.user?.type) === "P"}
                style={{
                  minHeight: 32,
                  border: `1px solid ${COLORS.sub_text}`,
                  fontSize: 18,
                  color: COLORS.sub_accent,
                  fontWeight: "bold",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
