/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Col, Row, TimePicker } from "antd";
import { useQuery } from "react-query";
import moment from "moment";
import { ClockCircleFilled } from "@ant-design/icons";

import { COLORS, STRINGS, Svgs } from "../../constants";
import { LoadingView } from "../../elements";
import { FetchApi } from "../../api";
import { useQueryListStaff } from "../staff-booking/modules";
import { StaffBookingStep2Phone } from "./items/StaffBookingStep2Phone";
import { StaffBookingStep2NoSuggest } from "./items/StaffBookingStep2NoSuggest";
import Auth from "../../store/Authentication";

import "./StaffBookingStep2.scss";

function StaffBookingStep2({ form }) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const infoStore = userAdmin.storeDTO;
  const dataBooking = form.getFieldValue();
  const specificTime = form.getFieldValue("suggestType") === "fixed";
  const [timeSelected, setTimeSelected] = useState();

  const numberPeople = dataBooking.bookingNum;
  const dateFormat = moment(dataBooking.bookingDate).format(
    "MM月DD日 \xa0 HH:mm"
  );
  const dayFormat = moment(dataBooking.bookingDate).format("MM月DD日");
  const timeFormat = moment(dataBooking.bookingDate).format("HH:mm");
  const {
    data: dataListStaff,
    isLoading: isLoadingStaff,
    isFetching: isFetchingStaff,
    status: statusFetchingStaff,
    refetch: refetchListStaff,
  } = useQueryListStaff({
    desiredTime: moment(dataBooking.bookingDate).toISOString(),
    storeId: infoStore.id,
    comboPackIdsRequests: [...Array(numberPeople)].map((_, index) => {
      const numberOrdinal = index + 1;

      const comboStore = dataBooking[`bookingComboStore${numberOrdinal}`];
      const comboFav = dataBooking[`bookingComboFav${numberOrdinal}`];
      const comboAll = dataBooking[`bookingComboAll${numberOrdinal}`];
      const listId = [comboAll?.id];

      comboStore &&
        Object.keys(comboStore).map((key) => {
          if (comboStore[key]?.id) {
            listId.push(comboStore[key].id);
          }
        });
      if (comboFav?.id) {
        listId.push(comboFav.id);
      }
      return {
        comboPackIds: listId.filter((item) => !!item),
        index: index,
      };
    }),
  });
  const { data, isLoading, isFetching, refetch } = useQuery(
    `suggest-booking-${dataBooking}-${dataListStaff?.mapStaffBySexGroupByIndex}`,
    () =>
      FetchApi.suggestBooking({
        startTimeStamp: moment(dataBooking.bookingDate).valueOf(),
        storeId: infoStore.id,
        specificTime: specificTime,
        requests: [...Array(numberPeople)].map((item, index) => {
          const numberOrdinal = index + 1;
          let staffIds = [];
          let assignStaffId =
            dataBooking[`bookingSelectStaff${numberOrdinal}`]?.staffId || null;
          let totalMinute = 0;

          // the information booking for each person
          const comboStore = dataBooking[`bookingComboStore${numberOrdinal}`];
          const comboFav = dataBooking[`bookingComboFav${numberOrdinal}`];
          const comboAll = dataBooking[`bookingComboAll${numberOrdinal}`];

          let assignStaffSex = null;
          const comboMinutes =
            dataBooking[`bookingMinute${numberOrdinal}`] || null;
          if (
            dataListStaff?.mapStaffBySexGroupByIndex &&
            dataListStaff?.mapStaffBySexGroupByIndex[index] &&
            Object.keys(dataListStaff?.mapStaffBySexGroupByIndex[index])
              .length > 0
          ) {
            if (assignStaffId === "male" || assignStaffId === "female") {
              dataListStaff?.mapStaffBySexGroupByIndex[index][
                assignStaffId
              ].map((i) => {
                staffIds.push(i.staffId);
              });
            } else {
              // eslint-disable-next-line no-unused-expressions
              dataListStaff?.mapStaffBySexGroupByIndex[index].male.map((i) => {
                staffIds.push(i.staffId);
              });
              // eslint-disable-next-line no-unused-expressions
              dataListStaff?.mapStaffBySexGroupByIndex[index].female.map(
                (i) => {
                  staffIds.push(i.staffId);
                }
              );
            }
          }
          if (assignStaffId === "male" || assignStaffId === "female") {
            assignStaffSex = assignStaffId.toUpperCase();
            assignStaffId = null;
          }

          if (comboMinutes) {
            totalMinute = comboMinutes;
          } else if (comboFav) {
            totalMinute = comboFav?.minutes;
          } else {
            if (comboAll) {
              totalMinute = comboAll.minutes;
            }
            if (comboStore && Object.keys(comboStore).length !== 0) {
              for (const [_, value] of Object.entries(comboStore)) {
                if (value?.priceDefault) {
                  totalMinute = totalMinute + value.minutes;
                }
              }
            }
          }

          return {
            userId: numberOrdinal === 1 ? dataBooking?.userInfo?.id : undefined,
            assignStaffId: assignStaffId,
            owner: numberOrdinal === 1,
            minutes: totalMinute,
            staffIds: staffIds,
            assignStaffSex: assignStaffSex,
          };
        }),
      }),
    {
      enabled:
        statusFetchingStaff === "success" &&
        !!dataListStaff?.mapStaffBySexGroupByIndex &&
        Object.keys(dataListStaff?.mapStaffBySexGroupByIndex).length > 0,
    }
  );

  // useEffect(() => {
  //   refetch();
  // }, [dataListStaff?.mapStaffBySexGroupByIndex]);

  const renderSelect = (index) => {
    if (index === timeSelected) {
      return <div className="radio-button-select" />;
    }
  };

  const renderListTime = () => {
    const bookingDate = form.getFieldValue("bookingDate");
    if (isLoading || isLoadingStaff || isFetching || isFetchingStaff) {
      return <LoadingView />;
    }
    if (!!data && data.success) {
      const isFixedTime = form.getFieldValue("suggestType") === "fixed";
      let dataPair = data?.pairCombinations;
      if (isFixedTime) {
        dataPair = [...data?.pairCombinations]?.filter((i) => {
          const startTime = moment(new Date(i.startTime)).format(
            "MM/DD/YYYY HH:mm"
          );
          const selectedTime = moment(bookingDate).format("MM/DD/YYYY HH:mm");
          return startTime === selectedTime;
        });
      }
      if (
        (data && !data.success) ||
        (dataListStaff && !dataListStaff.success) ||
        !Array.isArray(data?.pairCombinations) ||
        data?.pairCombinations?.length === 0 ||
        dataPair?.length === 0
      ) {
        return (
          <Col>
            <div
              style={{
                fontSize: 50,
                color: COLORS.required,
                fontWeight: "bold",
              }}>
              {STRINGS.no_suggest_available}
            </div>
            <Row>
              <Col
                style={{
                  borderRadius: 5,
                  padding: "10px 10px",
                  height: "fit-content",
                  width: 180,
                  border: "1px solid #d9d9d9",
                  marginTop: 30,
                  marginRight: 10,
                }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}>
                  {dayFormat}
                  {Svgs.datepicker()}
                </Row>
              </Col>
              <Col>
                <div style={{ color: COLORS.required }}>
                  {STRINGS.change_time_booking}
                </div>

                {/* <Col
                    style={{
                      borderRadius: 5,
                      padding: "10px 10px",
                      height: "fit-content",
                      width: 130,
                      border: "1px solid #d9d9d9",
                    }}>
                    <Row
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      {timeFormat}
                      <ClockCircleFilled
                        style={{ fontSize: 20, color: COLORS.sub_disabled }}
                      />
                    </Row>
                  </Col> */}
                <StaffBookingStep2NoSuggest
                  selectedTime={moment(bookingDate)}
                  form={form}
                  refetch={refetch}
                  refetchListStaff={refetchListStaff}
                />
              </Col>
            </Row>
          </Col>
        );
      }

      return (
        <Col>
          <Row>
            <Col style={{ minWidth: "40%" }}>
              <div className="text-title">
                {STRINGS.select_suggest_time(data?.pairCombinations?.length)}
              </div>
              <div className="text_des">{STRINGS.available_time}</div>
              {dataPair?.map?.((item, index) => {
                let borderColor = COLORS.border;
                let textColor = COLORS.text;
                let fontWeight = "normal";
                if (index === timeSelected) {
                  borderColor = COLORS.primary;
                  textColor = COLORS.primary;
                  fontWeight = "bold";
                }
                return (
                  <Row
                    key={`${index}-${item.staffId}`}
                    onClick={() => {
                      setTimeSelected(index);
                      form.setFieldsValue({ bookingSuggestTime: item });
                    }}
                    style={{
                      border: `1px solid ${borderColor}`,
                      margin: "10px 0px",
                      padding: "10px 30px",
                      width: "fit-content",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}>
                    <div className="radio-button">{renderSelect(index)}</div>
                    <div
                      style={{
                        paddingLeft: "10px",
                        color: textColor,
                        fontWeight,
                      }}>
                      {moment(new Date(item.startTime)).format(
                        "MM月DD日 \xa0  HH:mm"
                      )}
                    </div>
                  </Row>
                );
              })}
            </Col>
            {/* <StaffBookingStep2InfoCustomer form={form} /> */}
          </Row>
          <StaffBookingStep2Phone form={form} />
        </Col>
      );
    }
    return (
      <Col>
        <div
          style={{
            fontSize: 50,
            color: COLORS.required,
            fontWeight: "bold",
          }}>
          {STRINGS.no_suggest_available}
        </div>
        <Row>
          <Col
            style={{
              borderRadius: 5,
              padding: "10px 10px",
              height: "fit-content",
              width: 180,
              border: "1px solid #d9d9d9",
              marginTop: 30,
              marginRight: 10,
            }}>
            <Row
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              {dayFormat}
              {Svgs.datepicker()}
            </Row>
          </Col>
          <Col>
            <div style={{ color: COLORS.required }}>
              {STRINGS.change_time_booking}
            </div>
            <StaffBookingStep2NoSuggest
              selectedTime={moment(bookingDate)}
              form={form}
              refetch={refetch}
              refetchListStaff={refetchListStaff}
            />
          </Col>
        </Row>
      </Col>
    );
  };
  return (
    <div className="booking-step-2">
      <Row style={{ alignItems: "center", paddingBottom: "30px" }}>
        <div className="text-date">{STRINGS.wish_time}</div>
        <div className="text-time" style={{ paddingLeft: "20px" }}>
          {dateFormat}
        </div>
      </Row>

      {renderListTime()}
    </div>
  );
}

export { StaffBookingStep2 };
