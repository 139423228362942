import React, { useRef, useState } from "react";
import { Col, Form, message, Row } from "antd";
import { useQueryClient } from "react-query";
import { BorderOutlined, CheckSquareFilled } from "@ant-design/icons";
import moment from "moment";

import { COLORS, STRINGS } from "../../constants";
import { ButtonCustom, ModalCustomService } from "../../elements";
import { FetchApi } from "../../api";
import Auth from "../../store/Authentication";
import RadioAllStoreStaff from "../../elements/RadioAllStoreStaff";

function BookingItem({ item, onChange, value }) {
  const [isSelect, setIsSelect] = useState(false);
  const renderIcon = () => {
    if (isSelect) {
      return (
        <CheckSquareFilled style={{ fontSize: 28, color: COLORS.primary }} />
      );
    }
    return <BorderOutlined style={{ fontSize: 28, color: COLORS.border }} />;
  };

  return (
    <Row
      style={{
        marginTop: "10px",
        marginBottom: "10px",
        cursor: "pointer",
        marginLeft: "40%",
      }}
      onClick={() => {
        const indexNote = value.findIndex((i) => i === item.bookingId);
        setIsSelect(!isSelect);
        if (indexNote > -1) {
          const arrClone = [...value];
          arrClone.splice(indexNote, 1);
          onChange(arrClone);
          return;
        }
        onChange([...value, item.bookingId]);

        // setIsSelect(!isSelect);
      }}>
      <Row style={{ alignItems: "center" }}>
        {renderIcon()}
        {"\xa0" + item.userName}
      </Row>
    </Row>
  );
}

export function CancelBookingList({ bookingList, value, onChange }) {
  return (
    <Col>
      {bookingList.map((item) => {
        return (
          <BookingItem
            key={`${item.bookingId}`}
            item={item}
            bookingList={bookingList}
            value={value}
            onChange={onChange}
          />
        );
      })}
    </Col>
  );
}

export default function ModalCancelBooking({
  mainBookingId,
  bookingList,
  type,
  refetch,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingButtonRefuse, setLoadingButtonRefuse] = useState(false);
  const queryClient = useQueryClient();
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const staffChangeRequest = useRef({});

  const cancelMultipleBooking = async () => {
    if (!staffChangeRequest.current.id) {
      message.warning(STRINGS.please_select_a_reception_staff);
      return;
    }
    if (!staffChangeRequest.current.password) {
      message.warning(STRINGS.please_type_password);
      return;
    }
    const { bookingIds } = form.getFieldsValue();
    setLoading(true);
    const result = await FetchApi.cancelMultipleBooking({
      bookingIds,
      cancelType: type,
      staffIdAndPasswordRequest: {
        staffId: staffChangeRequest.current.id,
        password: staffChangeRequest.current.password,
      },
    });
    setLoading(false);
    if (result.success) {
      message.success(STRINGS.done_successful);
      ModalCustomService.close();
      queryClient.refetchQueries(
        `useGetCalendarOrders-${moment().format("MM/DD/YYYY")}`
      );
      queryClient.refetchQueries(`bookingComing-${userAdmin.storeId}`);
      refetch && refetch();
      return;
    }
    if (result.code === 403) {
      message.warning(STRINGS.password_is_incorrect);
      return;
    }
    message.error(STRINGS.server_error);
  };

  const cancelOnlyOwner = async () => {
    if (!staffChangeRequest.current.id) {
      message.warning(STRINGS.please_select_a_reception_staff);
      return;
    }
    if (!staffChangeRequest.current.password) {
      message.warning(STRINGS.please_type_password);
      return;
    }
    setLoadingButtonRefuse(true);
    const result = await FetchApi.cancelMultipleBooking({
      bookingIds: [mainBookingId],
      cancelType: type,
      staffIdAndPasswordRequest: {
        staffId: staffChangeRequest.current.id,
        password: staffChangeRequest.current.password,
      },
    });
    setLoadingButtonRefuse(false);
    if (result.success) {
      message.success(STRINGS.done_successful);
      ModalCustomService.close();
      queryClient.refetchQueries(
        `useGetCalendarOrders-${moment().format("MM/DD/YYYY")}`
      );
      queryClient.refetchQueries(`bookingComing-${userAdmin.storeId}`);
      refetch && refetch();
      return;
    }
    if (result.code === 403) {
      message.warning(STRINGS.password_is_incorrect);
      return;
    }
    message.error(STRINGS.server_error);
  };

  return (
    <Col className="checkin-time-wrapper">
      <Col
        style={{
          margin: "10px 0px",
          color: COLORS.accent,
          fontSize: 20,
          fontWeight: "bold",
        }}>
        {STRINGS.cancel_booking_des}
      </Col>
      <Form form={form}>
        <Form.Item name="bookingIds" initialValue={[mainBookingId]}>
          <CancelBookingList bookingList={bookingList} />
        </Form.Item>
      </Form>
      <RadioAllStoreStaff onChange={(e) => (staffChangeRequest.current = e)} />

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={cancelOnlyOwner}
          loading={loadingButtonRefuse}
          isCancelButton
          style={{ marginRight: 10 }}>
          {STRINGS.refuse}
        </ButtonCustom>
        <ButtonCustom onClick={cancelMultipleBooking} loading={loading}>
          {STRINGS.yeah}
        </ButtonCustom>
      </Row>
    </Col>
  );
}
