import { message } from "antd";
import { extend } from "@syncfusion/ej2-base";
import moment from "moment";

import { STRINGS, COLORS } from "../constants/";

const CommonFunction = {
  onPreview: async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  },
  beforeUpload: (file) => {
    const isJpgOrPng =
      (file.type === "image/jpeg" || file.type === "image/png") &&
      !file.name.includes(".gif");
    if (!isJpgOrPng) {
      message.error(STRINGS.only_jpg_png);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(STRINGS.smaller_than_2mb);
    }
    return isJpgOrPng && isLt2M;
  },
  beforeUploadAudio: (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(STRINGS.smaller_than_2mb);
    }
    return isLt2M;
  },
  logError: (result) => {
    if (result?.message === STRINGS.network_error) {
      message.error(result.message);
      return;
    }
    message.error(
      `${STRINGS.something_wrong} ${
        result?.status ? "(" + result?.status + ")" : ""
      }`
    );
  },
  textViewError: (result) => {
    if (result?.message === STRINGS.network_error) {
      return result.message;
    }
    return `${STRINGS.something_wrong} ${
      result?.status ? "(" + result?.status + ")" : ""
    }`;
  },
  dateFormat: "MM/DD/YYYY HH:mm",
  setUpDownLoad: (url, name) => {
    const xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = () => {
      if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
        const blobUrl = window.URL.createObjectURL(xmlHttp.response);
        const e = document.createElement("a");
        e.href = blobUrl;
        e.download = name;
        document.body.appendChild(e);
        e.click();
        document.body.removeChild(e);
      }
    };
    xmlHttp.responseType = "blob";
    xmlHttp.open("GET", url, true);
    xmlHttp.send(null);
  },
  sortOwnerDataCalendar: (data = []) => {
    const status = {
      working: 4,
      waiting_checkin: 3,
      unavailable: 2,
    };

    const newData = data.sort((a, b) => {
      const statusA = Number(status[a.dataStaff.shiftStatus]);
      const statusB = Number(status[b.dataStaff.shiftStatus]);
      return statusB - statusA;
    });

    const newData2 = newData.sort((a, b) => {
      const statusA = Number(status[a.dataStaff.shiftStatus]);
      const statusB = Number(status[b.dataStaff.shiftStatus]);
      const startTimeA = moment(a.dataStaff.workShiftDTOS?.[0]?.startTime);
      const startTimeB = moment(b.dataStaff.workShiftDTOS?.[0]?.startTime);
      if (statusA === statusB) {
        if (startTimeB.isAfter(startTimeA)) {
          return -1;
        }
        if (startTimeB.isBefore(startTimeA)) {
          return 1;
        }
        return 0;
      }
    });
    return newData2;
  },
  generateDataSchedule: (calendarOrder, refetchCalendarOrder) => {
    let ownerData = [];
    let datas = [];

    calendarOrder.staffOrderDTOS?.forEach?.((staff) => {
      ownerData.push({
        staffName: staff.staffName,
        checkIn: staff.checkIn,
        staffId: staff.staffId,
        startTime: "0:00",
        endTime: "0:00",
        dataStaff: staff,
        workShiftDTOS: staff.workShiftDTOS,
        refetchCalendarOrder: refetchCalendarOrder,
      });

      staff.workShiftDTOS?.forEach?.((workShift, workShiftIndex) => {
        const workShiftStartTime = moment(workShift.startTime);
        const workShiftEndTime = moment(workShift.endTime);

        workShift.bookingDTOS?.forEach?.((booking, bookingIndex) => {
          const bookingStartTime = moment(
            booking.startTimeBeforeCheckin || booking.startTime
          );
          const bookingWorkingEndTime = moment(booking.timeFinishWorking);
          const bookingEndTime = moment(booking.endTime);
          const restAfterBookingStartTime = moment(booking.startTime).add(
            booking.minutes,
            "minutes"
          );
          datas.push(
            {
              chooseStaff: booking.chooseStaff,
              id: booking.uuid + bookingIndex,
              title: booking.customerName || "none",
              customerPhone: booking.customerPhone,
              customerName: booking.customerName,
              startTime: bookingStartTime.toISOString(),
              endTime: bookingWorkingEndTime.toISOString(),
              staffId: staff.staffId,
              categoryColor: "#F0A060",
              textColor: "white",
              bookingDetail: booking,
              comboOrders: booking.comboOrders,
              assignedSex: booking.assignedSex,
            },
            {
              id: `REST_AFTER_BOOKING-${booking.id}-${bookingIndex}`,
              title: "",
              startTime: restAfterBookingStartTime.toISOString(),
              endTime: bookingEndTime.toISOString(),
              staffId: staff.staffId,
              categoryColor: "#D9D9D9",
              textColor: "transparent",
            }
          );
        });
        workShift.restTimeExtraDTOS?.forEach?.((restTime, restTimeIndex) => {
          const restStartTime = moment(restTime.startRestTime);
          const restEndTime = moment(restTime.endRestTime);
          datas.push({
            id: `REST_TIME-${staff.staffId}-${restTimeIndex}`,
            title: "",
            startTime: restStartTime.toISOString(),
            endTime: restEndTime.toISOString(),
            staffId: staff.staffId,
            categoryColor: "#999999",
            textColor: "transparent", //color for extra rest time
            shiftId:restTime.shiftId
          });
        });

        const datasOfThisStaff = datas.filter(
          (data) => data.staffId === staff.staffId
        );
        if (!datasOfThisStaff.length) {
          datas.push({
            id: `WORKING_TIME-NO_EVENT-${staff.staffId}`,
            title: "",
            startTime: workShiftStartTime.toISOString(),
            endTime: workShiftEndTime.toISOString(),
            staffId: staff.staffId,
            categoryColor: "#E9E9E9",
            textColor: "transparent", //color for working time
          });
        }

        let timeFlag = workShiftStartTime;
        // const datasClone = datas.filter((itm) => itm.staffId === staff.staffId);
        datasOfThisStaff.sort(
          (a, b) => new Date(a.startTime) - new Date(moment(b.startTime))
        );

        datasOfThisStaff.forEach?.((time, timeIndex, timeArr) => {
          if (timeFlag.isSameOrBefore(time.startTime)) {
            datas.push({
              id: `WORKING_TIME-${timeIndex}-${staff.staffId}`,
              title: "",
              startTime: moment(timeFlag).toISOString(),
              endTime: moment(time.startTime).toISOString(),
              staffId: staff.staffId,
              categoryColor: "#E9E9E9",
              textColor: "transparent", //color for working time
            });
            timeFlag = moment(time.endTime);
          }
          if (
            timeArr.length - 1 === timeIndex &&
            timeFlag.isSameOrAfter(time.endTime)
          ) {
            datas.push({
              id: `WORKING_TIME-${timeIndex}-${staff.staffId}`,
              title: "",
              startTime: timeFlag.toISOString(),
              endTime: workShiftEndTime.toISOString(),
              staffId: staff.staffId,
              categoryColor: "#E9E9E9",
              textColor: "transparent", //color for working time
            });
          }
        });
      });
    });
    const newOwnerData = CommonFunction.sortOwnerDataCalendar(ownerData);
    datas = extend([], datas, null, true);
    return { ownerData: newOwnerData, datas };
  },
  renderEventCalendar: (args) => {
    let categoryColor = args.data.categoryColor;
    // let bookingStatus = args.data.bookingDetail?.status;
    let bookingMinutes = args.data.bookingDetail?.minutes;
    let comboOrders = args.data.comboOrders || [];
    let notIsBooking = args.data.textColor == "transparent";
    let isCheckIn =
      args.data.bookingDetail?.userCheckInTime &&
      !args.data.bookingDetail?.paid;
    let isDone = args.data.bookingDetail?.paid;
    let borderColor = "white";
    let borderWidth = "1px";
    if (notIsBooking) {
      borderColor = "#dcdcdc";
    }
    if (args.data.chooseStaff) {
      borderColor = "red";
      borderWidth = "5px";
    }
    if (args.data.assignedSex) {
      borderColor = COLORS.gender_staff[args.data.assignedSex];
      borderWidth = "5px";
    }
    if (!args.element || !categoryColor) {
      return;
    }
    args.element.style.backgroundColor = categoryColor;
    args.element.style.borderRadius = 0;

    args.element.style.borderColor = borderColor;
    args.element.style.borderStyle = "solid";
    args.element.style.borderWidth = borderWidth;

    args.element.style.color = args.data.textColor;
    args.element.style.textAlign = "center";
    args.element.style.pointerEvents = notIsBooking ? "ー" : "auto";
    // args.element.getElementsByClassName("e-subject")[0].innerHTML = `${
    //   args.data?.customerName?.replace?.(/\s+/g, "") || "ー"
    // }`;
    // args.element.getElementsByClassName("e-time")[0].innerHTML = `(${
    //   args.data.customerPhone ? args.data.customerPhone.slice(-4) : "ー"
    // })`;
    // args.element.getElementsByClassName("e-location")[0].innerHTML = LOGIC
    //   .actionBookingType[bookingStatus]
    //   ? `(${LOGIC.actionBookingType[bookingStatus]})`
    //   : "";

    let totalMinute = 0;
    args.data?.comboOrders?.forEach((i) => {
      totalMinute = totalMinute + i.minutes;
    });
    args.element.getElementsByClassName("e-subject")[0].innerHTML = `${
      args.data?.customerName?.replace?.(/\s+/g, "") || "ー"
    }`;
    args.element.getElementsByClassName("e-subject")[0].style["white-space"] =
      "pre-line";
    args.element.getElementsByClassName("e-subject")[0].style["font-size"] =
      "16px";
    args.element.getElementsByClassName("e-time")[0].style["font-size"] =
      "16px";
    args.element.getElementsByClassName("e-time")[0].innerHTML = `(${
      totalMinute ? totalMinute + STRINGS.minute : "ー"
    })`;

    args.element.getElementsByClassName(
      "e-appointment-details"
    )[0].style.zIndex = 2;
    if (!notIsBooking) {
      let flag = 0;
      let img = document.createElement("img");
      if (isCheckIn) {
        img.src =
          "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/ce802ddd-c7a9-4b0d-84aa-4070e2933938e21b250e-c926-4fdb-b5c9-d47d3b5bf00f129df94a-e800-41ac-9c59-7ad65ca3e1762b8fad8d-7e29-47e8-b91c-233cf9efb617.png";
        img.setAttribute(
          "style",
          "width: 20px; height: 20px; position: absolute; top: 0px; right:0px; z-index: 2;"
        );
        args.element.appendChild(img);
      } else if (isDone) {
        img.src =
          "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/f3ee9a3b-2f32-43b3-9c71-7d35860f98242f7a3ed3-c264-4f64-9ad0-25971bd66b4c0081f042-a04f-4c4e-9471-535fcc00f2795d812fe1-7249-460c-82d4-edce511ba5ac.png";
        img.setAttribute(
          "style",
          "width: 20px; height: 20px; position: absolute; top: 0px; right:0px; z-index: 2;"
        );
        args.element.appendChild(img);
      }
      let totalMin = 0;

      comboOrders
        .sort((a, b) => a.categoryPosition - b.categoryPosition)
        .forEach((item, index, list) => {
          let elemDiv = document.createElement("div");
          elemDiv.style.position = "absolute";
          elemDiv.style.left = flag;
          elemDiv.style.width = `calc(${item.minutes / bookingMinutes} * 100%)`;
          elemDiv.style.height = "100%";
          elemDiv.style.zIndex = 1;
          elemDiv.style.backgroundColor = item.color;
          totalMin += item.minutes;

          flag = `calc(${totalMin / bookingMinutes} * 100%)`;
          args.element.appendChild(elemDiv);
        });
    }
  },
  generateMonth: () => {
    const currentMonth = moment();
    const months = [];
    const past3Month = moment().subtract(3, "month");
    const past2Month = moment().subtract(2, "month");
    const past1Month = moment().subtract(1, "month");

    const after1Month = moment().add(1, "month");
    const after2Month = moment().add(2, "month");
    const after3Month = moment().add(3, "month");

    months.push(
      past3Month,
      past2Month,
      past1Month,
      currentMonth,
      after1Month,
      after2Month,
      after3Month
    );
    return months;
  },
  currencyFormat: (e) => {
    if (!e) {
      return 0;
    }
    return `${e}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  },
  minute: (e) => {
    if (!e) {
      return 0;
    }
    return `${e}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  },
  roundPercent: (e) => {
    return Math.round((e * 100 + Number.EPSILON) * 100) / 100;
  },
};

export { CommonFunction };
