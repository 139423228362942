import { Button, Col, Radio, Row } from "antd";
import React from "react";
import moment from "moment";
import { COLORS, STRINGS } from "../../../../constants";
import ModalRestTime from "../ModalRestTime";
import { ModalCustomService } from "../../../../elements";
import ModalCheckinTime from "../ModalCheckinTime";
import ModalRequestRestMore from "../ModalRequestRestMore";

export default function BodyTopNotCheckOutYet({
  staffInfo,
  shiftStatus,
  scheduleSelected,
  setScheduleSelected,
  refetchCalendarOrder,
  disabled,
  dataStaffResting,
  selectedDate,
  data,
}) {
  const item = (data?.workShiftDTOS || []).find(
    (itm) => itm.scheduleId === scheduleSelected
  );

  const { restMinutesExtra, checkInTime, restMinuteDefault } = { ...item };
  const onCheckIn = () => {
    ModalCustomService.set({
      title:
        STRINGS.if_you_press_the_attendance_button_you_will_not_be_able_to_change_it,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <ModalRestTime
          refetchCalendarOrder={refetchCalendarOrder}
          staffInfo={staffInfo}
        />
      ),
    });
  };
  const onChangeCheckinTime = () => {
    ModalCustomService.set({
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <ModalCheckinTime
          refetchCalendarOrder={refetchCalendarOrder}
          staffInfo={staffInfo}
          scheduleSelected={scheduleSelected}
          shiftStatus={shiftStatus}
          setScheduleSelected={setScheduleSelected}
          disabled={disabled}
          selectedDate={selectedDate}
        />
      ),
    });
  };
  const onRequestRestMore = () => {
    ModalCustomService.set({
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <ModalRequestRestMore
          refetchCalendarOrder={refetchCalendarOrder}
          staffInfo={staffInfo}
          scheduleSelected={scheduleSelected}
          shiftStatus={shiftStatus}
          setScheduleSelected={setScheduleSelected}
          disabled={disabled}
          selectedDate={selectedDate}
        />
      ),
    });
  };

  const columns = [
    {
      label: STRINGS.working_hours,
      value:
        staffInfo.workShiftDTOS.length > 1 ? (
          <Radio.Group
            style={{ display: "flex", flexDirection: "column" }}
            defaultValue={scheduleSelected}
            onChange={(e) => setScheduleSelected(e.target.value)}>
            {staffInfo.workShiftDTOS.map((item) => {
              return (
                <Radio
                  className="value"
                  style={{ marginBottom: 0, fontSize: 22, fontWeight: "bold" }}
                  key={`${item.scheduleId}`}
                  value={item.scheduleId}>
                  {moment(item.startTime).format("HH:mm")}~
                  {moment(item.endTime).format("HH:mm")}
                </Radio>
              );
            })}
          </Radio.Group>
        ) : (
          <>
            {staffInfo.workShiftDTOS.map((item) => {
              return (
                <Row
                  className="value"
                  style={{ marginBottom: 0 }}
                  key={`${item.scheduleId}`}>
                  {moment(item.startTime).format("HH:mm")}~
                  {moment(item.endTime).format("HH:mm")}
                </Row>
              );
            })}
          </>
        ),
    },
    {
      label: STRINGS.attendance_time,
      value: checkInTime ? moment(checkInTime).format("HH:mm") : "ー",
    },
    {
      label: STRINGS.interval_time,
      value: `${restMinuteDefault || 0}\xa0${STRINGS.minute}`,
    },
    {
      label: STRINGS.time_rest_extra,
      value: `${restMinutesExtra || 0}\xa0${STRINGS.minute}`,
    },
  ];

  let hasResting = false;
  let disabledCheckin = true;
  let backgroundColor = COLORS.disabled;
  if (moment().isAfter(moment(item?.endTime))) {
    hasResting = true;
  }
  if (Array.isArray(dataStaffResting?.staffRestingDTOS)) {
    const staffSelected = dataStaffResting.staffRestingDTOS.find(
      (i) => i.staffId === staffInfo.staffId
    );
    if (staffSelected?.resting) {
      hasResting = true;
    }
  }
  if (
    moment().format("YYYY/MM/DD") === moment(selectedDate).format("YYYY/MM/DD")
  ) {
    disabledCheckin = false;
    backgroundColor = COLORS.primary;
  }

  return (
    <Row className="body-top">
      <Col xl={19} xs={24} style={{ padding: "0px 14px 0px 100px" }}>
        <Row>
          {columns.map((item, index) => {
            return (
              <Col
                xl={12}
                xs={24}
                style={{ margin: "7px 0px" }}
                key={`${item.label}`}>
                <Row align="middle" style={{ alignItems: "center" }}>
                  <Col className="label-base">{item.label}</Col>
                  <Col className="value-base">{item.value}</Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </Col>
      <Col xl={5} xs={24} style={{ padding: "4px 0px 14px 14px" }}>
        {shiftStatus === "working" && (
          <Row style={{ marginBottom: 14 }}>
            <Button
              className="btn-event"
              style={{
                backgroundColor: COLORS.secondary,
              }}
              onClick={onChangeCheckinTime}>
              <Col className="btn-title">時刻修正</Col>
            </Button>
          </Row>
        )}
        {shiftStatus === "waiting_checkin" && (
          <Row style={{ marginBottom: 14 }}>
            <Button
              className="btn-event"
              disabled={disabledCheckin}
              style={{
                backgroundColor,
              }}
              onClick={onCheckIn}>
              <Col className="btn-title">{STRINGS.checkin}</Col>
            </Button>
          </Row>
        )}
        {!hasResting && shiftStatus === "working" && (
          <Row>
            <Button
              className="btn-event"
              style={{
                backgroundColor: COLORS.primary,
              }}
              onClick={onRequestRestMore}>
              <Col className="btn-title">{STRINGS.rest2}</Col>
            </Button>
          </Row>
        )}
      </Col>
    </Row>
  );
}
