import React, { useRef, useState, useEffect, memo } from "react";
import { Table, Space, message, Avatar, Image } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "react-query";

import {
  AlertService,
  ButtonCustom,
  Container,
  LoadingView,
  ModalCustomService,
  SvgView,
} from "../../elements";
import { FetchApi } from "../../api";
import { STRINGS, Svgs } from "../../constants";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";
import Add from "./items/Add";
import Edit from "./items/Edit";

import "./StoreStaff.scss";

const StoreStaff = memo(() => {
  const cache = useQueryClient();
  const formRef = useRef();
  const page = useRef(1);
  const size = useRef(10);
  const [listStore, setListStore] = useState([]);
  const storeSelected = useRef(null);
  const firstRunUseEffect = useRef(true);
  const [newData, setNewData] = useState({ packs: [], rating: [] });
  const { data: defaultPassword } = useQuery(`get-default-password`, () =>
    FetchApi.defaultResetStaffPassword()
  );
  const columns = [
    {
      title: STRINGS.avatar,
      dataIndex: "avatarUrl",
      key: "avatarUrl",
      render: (avatarUrl, data) => (
        <div style={{ position: "relative" }}>
          <Avatar
            size={60}
            src={
              avatarUrl ||
              "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/a52b7629-5ce3-466c-a369-2d20989c2b8b909e75ab-1350-4ab3-8f79-8f08d91af2ba27aaf8bd-7d13-428f-861a-b9bc2fc39d453fa70bf0-af81-4d9b-83df-874ae1ef8b8c.png"
            }
          />

          <div
            style={{
              top: 0,
              position: "absolute",
              right: 15,
              backgroundColor: "white",
              borderRadius: "100%",
              overflow: "hidden",
              cursor: "pointer",
            }}
          >
            <Image src={data?.rank?.image} width={25} height={25} />
          </div>
        </div>
      ),
      width: 120,
      // fixed: "left",
    },
    {
      title: STRINGS.staff_id,
      dataIndex: "id",
      key: "id",
      render: (id) => id,
      width: 120,
    },
    {
      title: STRINGS.staff_name_2,
      dataIndex: "name",
      key: "name",
    },
    {
      title: STRINGS.gender,
      dataIndex: "sex",
      key: "sex",
      render: (e) => (e === "male" ? STRINGS.male : !e ? "" : STRINGS.female),
      width: 80,
    },
    {
      title: STRINGS.phone_number_2,
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      width: 160,
    },

    {
      title: STRINGS.email,
      key: "email",
      dataIndex: "email",
    },
    {
      title: STRINGS.rating_average,
      key: "performance",
      dataIndex: "performance",
      align: "center",
      render: (e) => <div>{parseFloat(e || 0).toFixed(2) + "%"}</div>,
    },
    // {
    //   title: STRINGS.average_score,
    //   key: "ratingAverage",
    //   dataIndex: "ratingAverage",
    //   render: (number) => {
    //     if (!number) {
    //       return "";
    //     }
    //     return (
    //       <div>
    //         {number} {<StarFilled style={{ color: "#E9B418" }} />}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: STRINGS.procedure_range,
    //   key: "comboPacks",
    //   dataIndex: "comboPacks",
    //   render: (e, i) => {
    //     // if (Array.isArray(e) && e.length > 0) {
    //     //   return e.map(
    //     //     (item, index) =>
    //     //       `${item.comboName} (${item.minutes})` +
    //     //       (index !== e.length - 1 ? ", " : "")
    //     //   );
    //     // }
    //     // return "";

    //     if (newData?.packs) {
    //       const data = newData?.packs?.find((item, idx) => {
    //         return Object.keys(item)[0] == i.id;
    //       });
    //       if (data) {
    //         return Object.values(data)[0]?.map((item, index) => {
    //           return (
    //             <Typography key={item.id}>
    //               {`${item.comboName} (${item.minutes})` +
    //                 (index !== Object.values(data)[0]?.length - 1 ? ", " : "")}
    //             </Typography>
    //           );
    //         });
    //       } else return;
    //     }
    //   },
    // },

    {
      title: STRINGS.self_introduction,
      key: "selfIntroduction",
      dataIndex: "selfIntroduction",
    },
    {
      key: "action",
      render: (text, record) => {
        if (record.deleted) {
          return null;
        }
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onEdit(record)}
            >
              <SvgView svgData={Svgs.edit} />
            </button>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onRemove(record)}
            >
              <SvgView svgData={Svgs.delete} />
            </button>
          </Space>
        );
      },
      fixed: "right",
      width: 100,
    },
  ];
  // const getRatingandComboPacksStaff = async (staffId) => {
  //   const dataRating = await FetchApi.getRatingStaff(staffId);
  //   const dataPacks = await FetchApi.getPacksStaff(staffId);
  //   if ((dataRating && dataPacks) || (dataRating === 0 && dataPacks)) {
  //     setNewData({
  //       packs: [...newData.packs, { [staffId]: dataPacks }],
  //       rating: [...newData.rating, { [staffId]: dataRating }],
  //     });
  //   }
  // };
  const { data, refetch, status } = useQuery(["all-staff-management"], () => {
    return FetchApi.getListStaff(
      storeSelected.current,
      page.current,
      size.current
    );
  });
  useScrollByMouse(data);

  useEffect(() => {
    if (firstRunUseEffect.current) {
      const fetchStore = async () => {
        let result = await FetchApi.getAllStoreFilter(1, 50);
        if (result.total) {
          setListStore([{ name: STRINGS.all, id: null }, ...result.data]);
        }
      };
      fetchStore();
      firstRunUseEffect.current = false;
      return;
    }
    formRef.current?.show?.();
  }, []);

  const setData = () => {
    cache.refetchQueries(`all-staff-management`);
  };
  const onRemove = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${item.name}${STRINGS.confirm_delete_staff}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteStaff(item.id);
        if (result.success) {
          setData([...data.data, "delete"]);
          message.success(STRINGS.delete_success);
          return;
        }
        message.warning(STRINGS.delete_failed);
      },
    });
  };

  const onCreate = () => {
    ModalCustomService.set({
      title: STRINGS.add_new,
      wrapperStyle: { maxWidth: 1000 },
      content: <Add data={data} setData={setData} listStore={listStore} />,
    });
  };
  const onEdit = (record) => () => {
    ModalCustomService.set({
      title: STRINGS.update,
      wrapperStyle: { maxWidth: 1000 },
      content: (
        <Edit
          data={data}
          setData={setData}
          item={record}
          listStore={listStore}
          setNewData={setNewData}
          defaultPassword={defaultPassword}
        />
      ),
    });
  };

  return (
    <Container
      className="manager-store-staff"
      renderRight={() => (
        <ButtonCustom onClick={onCreate} icon={<PlusCircleOutlined />}>
          {STRINGS.add_new}
        </ButtonCustom>
      )}
    >
      <div className="content-staff">
        {status === "loading" ? (
          <LoadingView />
        ) : (
          <>
            <Table
              rowClassName={(record) => {
                return record.deleted ? "staff-delete" : "";
              }}
              // onRow={(record) => {
              //   return {
              //     onClick: (event) => {
              //       getRatingandComboPacksStaff(record?.id);
              //     }, // click row
              //   };
              // }}
              rowKey={"id"}
              columns={columns}
              dataSource={data ? data.data : []}
              locale={{ emptyText: STRINGS.no_data }}
              pagination={false}
              scroll={{ x: "110%", y: "calc(100vh - 320px)" }}
            />
            <PaginationWithSizeChange
              size={size.current}
              total={data ? data.total : 0}
              onChange={(pageNumber) => {
                page.current = pageNumber;
                refetch();
              }}
              onShowSizeChange={(e) => {
                size.current = Number(e.size);
                page.current = 1;
                refetch();
              }}
              current={page.current}
            />
          </>
        )}
      </div>
    </Container>
  );
});

export default StoreStaff;
