import { Col, Row, Typography, message } from "antd";
import React from "react";
import { COLORS, STRINGS } from "../../../constants";
import { ButtonCustom, ModalCustomService } from "../../../elements";
import { useMutation } from "react-query";
import { FetchApi } from "../../../api";

export function ModalDeleteRestTime({
  startTime,
  shiftId,
  refetchCalendarOrder,
}) {
  const onCancel = () => {
    ModalCustomService.close();
  };
  const mutation = useMutation({
    mutationFn: FetchApi.deleteRestTimeExtra,
    mutationKey: ["deleteRestTimeExtra"],
  });
  const handleDelete = () => {
    mutation.mutate(
      { startTime, shiftId },
      {
        onSuccess: (response) => {
          if (response.code === 200) {
            refetchCalendarOrder();
            message.success(STRINGS.delete_success);
            ModalCustomService.close();
            return;
          }
          message.error(STRINGS.delete_failed);
          ModalCustomService.close();
        },
      }
    );
  };
  return (
    <Col style={{ padding: 20 }}>
      <Row>
        <Typography.Text>{STRINGS.delete_rest_time}</Typography.Text>
      </Row>
      <Row style={{ justifyContent: "center", marginTop: 20 }}>
        <Col span={8}>
          <ButtonCustom
            onClick={onCancel}
            style={{
              minWidth: 120,
              backgroundColor: COLORS.disabled,
              borderColor: COLORS.disabled,
              color: COLORS.sub_black,
            }}
          >
            {STRINGS.cancel}
          </ButtonCustom>
        </Col>
        <Col span={8}>
          <ButtonCustom
            onClick={handleDelete}
            loading={mutation.isLoading}
            style={{
              minWidth: 120,
              backgroundColor: COLORS.primary,
              borderColor: COLORS.primary,
            }}
          >
            {STRINGS.delete}
          </ButtonCustom>
        </Col>
      </Row>
    </Col>
  );
}
