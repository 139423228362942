import React, { useState } from "react";
import { Col, Row, Form, message } from "antd";
import { useQuery } from "react-query";

import { FetchApi } from "../../api";
import {
  ErrorView,
  FileUpload,
  LoadingAbsolute,
  LoadingView,
  Validate,
} from "../../elements";
import { ButtonCustom } from "../../elements";
import { STRINGS } from "../../constants";
import { CommonFunction } from "../../util";

import "./UploadAudio.scss";

export default function UploadAudio() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { data, isLoading } = useQuery(["getNotiSound"], FetchApi.getNotiSound);
  const onEdit = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      let result;
      setLoading(true);
      if (data?.notiSound?.notiSoundOneNewBooking) {
        result = await FetchApi.updateNotiSound({ ...fieldData });
      } else {
        result = await FetchApi.createNotiSound({ ...fieldData });
      }
      setLoading(false);
      if (result.success) {
        message.success(STRINGS.saved_common);
        return;
      }
      CommonFunction.logError(result);
    } catch (error) {
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  if (isLoading) {
    return <LoadingView />;
  }
  if (data && !data.success) {
    return <ErrorView data={data} />;
  }
  return (
    <Col className="upload-audio">
      <Form form={form}>
        <Form.Item
          name="notiSoundOneNewBooking"
          initialValue={data?.notiSound?.notiSoundOneNewBooking}
          rules={[Validate.emptyContent]}
          style={{ width: "100%" }}>
          <FileUpload />
        </Form.Item>
      </Form>
      <Row justify="end">
        <ButtonCustom onClick={onEdit}>{STRINGS.update}</ButtonCustom>
      </Row>

      {loading && <LoadingAbsolute styleSpin={{ top: "30%", left: "60%" }} />}
    </Col>
  );
}
