import React, { useRef, useState } from "react";
import { AutoComplete, Col, Form, Input, message, Row } from "antd";
import { useQueryClient } from "react-query";

import { COLORS, STRINGS } from "../../constants";
import { ButtonCustom, LoadingView, ModalCustomService } from "../../elements";
import { FetchApi } from "../../api";
import { useGetDetailCourseBooking } from "../staff-schedule/modules";
import { ScheduleBookingService } from "../../services";
import ModalBookingDetail from "../staff-schedule/items/ModalBookingDetail";
import moment from "moment";

export default function ModalChangeUserBooking({
  bookingId,
  refetch: refetchCalendar,
}) {

  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { refetch } = useGetDetailCourseBooking(bookingId);
  const queryClient = useQueryClient();
  const inputRef = useRef();

  const [selectedDate] = useState(() => {
    const hour = moment().hour();
    if (hour < 5) {
      return moment().subtract(1, "day");
    }
    return moment();
  });
  const searchResult = (datas) => {
    return datas.map((item, index) => {
      return {
        name: item.name,
        value: "" + item.id,
        item: item,
        label: (
          <div style={{ borderWidth: 20 }}>
            <div style={{ fontWeight: "bold" }}>{item.name || ""}</div>
            <div style={{ color: "grey" }}>{item.phoneNumber}</div>
          </div>
        ),
      };
    });
  };
  const onSelect = async (e, item) => {
    if (!item?.item?.id) {
      return;
    }
    setLoading(true);
    const result = await FetchApi.changeUserBooking({
      bookingId: bookingId,
      userId: item.item.id,
    });
    setLoading(false);
    if (result.success) {
      const { refetchCalendarOrder } = ScheduleBookingService.get();
      await refetch();
      await refetchCalendar();
      const reData = queryClient.getQueryData([
        `useGetCalendarOrders-${selectedDate.format("MM/DD/YYYY")}`,
      ]);
      let newData;
      reData?.staffOrderDTOS?.forEach((item) => {
        item?.workShiftDTOS?.forEach((i) => {
          i?.bookingDTOS?.forEach((value) => {
            if (value?.id == bookingId) newData = value;
            return value?.id == bookingId;
          });
        });
      });
      if (newData) {
        ModalCustomService.set({
          title: STRINGS.customer_info,
          wrapperStyle: { width: "auto" },
          contentWrapperStyle: { width: "fit-content" },
          contentStyle: { maxHeight: "80vh", padding: 0 },

          content: (
            <ModalBookingDetail refetch={refetchCalendarOrder} data={newData} />
          ),
        });
        return;
      }
      ModalCustomService.close();
      return;
    }
    if (result.code === 400) {
      message.error(STRINGS.staff_ng_customer);
      return;
    }
    message.error(STRINGS.network_error);
    // navigate("staff-booking", { state: { data: item?.item } });
  };
  const onSearch = async (searchText) => {
    if (!searchText) {
      setOptions([]);
      return;
    }
    let result = await FetchApi.getUserApp({
      page: 1,
      size: 50,
      search: searchText,
    });
    if (!Array.isArray(result?.data) || result?.data?.length < 1) {
      message.warning(STRINGS.no_user_found);
      setOptions([]);
      return;
    }
    const newData=result?.data?.filter(item=>!item.deleted)
    setOptions(searchResult(newData) || []);
  };

  return (
    <Col className="checkin-time-wrapper">
      <Col style={{ margin: "10px 0px", color: COLORS.accent }}>
        {STRINGS.search_name_furi_phone}
      </Col>
      <Form form={form}>
        {loading ? (
          <LoadingView />
        ) : (
          <Form.Item name="inputSearch">
            <AutoComplete
              key={`${options}`}
              options={options}
              open={options.length > 0}
              style={{ paddingVertical: 20 }}
              onSelect={onSelect}
            >
              <Input
                ref={inputRef}
                style={{ width: 250 }}
                onPressEnter={(e, f) => {
                  onSelect(e, f);
                  onSearch(e.target.value);
                }}
              />
            </AutoComplete>
          </Form.Item>
        )}
      </Form>

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom onClick={ModalCustomService.close} isCancelButton>
          {STRINGS.cancel}
        </ButtonCustom>
      </Row>
    </Col>
  );
}
