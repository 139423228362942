import React, { useState } from "react";
import { Col, Row } from "antd";
import moment from "moment";

import { ModalCustomService } from "../../../elements/ModalCustom/ModalCustomService";
import StaffConfigModal from "./StaffConfigModal/StaffConfigModal";
import { COLORS } from "../../../constants";

function ResourceHeaderTemplate(props) {
  const { dataStaff, refetchCalendarOrder } = props.resourceData;
  const [shiftStatus, setShiftStatus] = useState(dataStaff?.shiftStatus);

  const onGoDetailStaffSchedule = () => {
    ModalCustomService.set({
      wrapperStyle: { width: "auto", maxWidth: 1200 },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <StaffConfigModal
          data={dataStaff}
          refetchCalendarOrder={refetchCalendarOrder}
          setShiftStatus={setShiftStatus}
          shiftStatus={shiftStatus}
          selectedDate={props.selectedDate}
        />
      ),
    });
  };

  return (
    <Row
      style={{
        padding: 0,
        justifyContent: "space-between",
        alignItems: "center",
      }}>
      <Col span={14}>
        <Col
          className="number-of-lines-2"
          onClick={onGoDetailStaffSchedule}
          style={{
            fontWeight: "bold",
            cursor: "pointer",
            fontSize: 16,
            color: COLORS.sub_accent,
          }}>
          {dataStaff.staffName}
        </Col>
        <Row
          className="working-time"
          style={{ maxHeight: 60, overflow: "scroll" }}>
          {(dataStaff?.workShiftDTOS || []).map((item, index) => {
            return (
              <Col
                key={`${index}`}
                style={{
                  marginRight: 6,
                  fontWeight: "bold",
                  fontSize: 17,
                  color: COLORS.sub_accent,
                  whiteSpace: "pre-line",
                }}>
                {moment(item.startTime).format("HH")}～
                {moment(item.endTime).format("HH")}
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}

export default ResourceHeaderTemplate;
