import React, { useState } from "react";
import { Col, Row } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";

import { COLORS, STRINGS } from "../../../constants";

import "../StaffBookingStep3.scss";

function StaffBookingStep3Item({ form, numberOrdinal, totalUser }) {
  const dataBooking = form.getFieldValue();
  const [isShow, setIsShow] = useState(true);

  const comboStore = dataBooking[`bookingComboStore${numberOrdinal}`];
  const comboFav = dataBooking[`bookingComboFav${numberOrdinal}`];
  const comboAll = dataBooking[`bookingComboAll${numberOrdinal}`];
  const assignStaff = dataBooking[`bookingSelectStaff${numberOrdinal}`];
  const comboMinutes = dataBooking[`bookingMinute${numberOrdinal}`];
  const totalPrice = dataBooking[`bookingTotalPrice${numberOrdinal}`];
  const noteBooking = dataBooking[`bookingNote${numberOrdinal}`];
  const renderCombo = () => {
    if (comboMinutes) {
      return (
        <Row>
          {comboMinutes}
          {STRINGS.minute}
        </Row>
      );
    }
    if (comboFav && comboFav?.minutes) {
      return (
        <Row>
          <Row style={{ width: "15%" }}>
            {comboFav.name || comboFav.comboName}
          </Row>
          <Row style={{ width: "10%" }}>
            {comboFav?.minutes} {STRINGS.minute}
          </Row>
          {comboFav?.price}
          {STRINGS.yen}
        </Row>
      );
    }
    return (
      <Col>
        {!!comboAll && !!comboAll?.comboName && (
          <Row>
            <Row style={{ width: "15%" }}> {comboAll?.comboName}</Row>
            <Row style={{ width: "10%" }}>
              {comboAll?.minutes}
              {STRINGS.minute}
            </Row>
            {comboAll?.price}
            {STRINGS.yen}
          </Row>
        )}
        {!!comboStore &&
          Object.entries(comboStore).map(([key, value]) => {
            let minute, price;
            if (value?.minutes) {
              minute = value?.minutes + STRINGS.minute;
              price = (value?.price || 0) + STRINGS.yen;
            }
            return (
              <Row key={`${key}`}>
                <Row style={{ width: "15%" }}> {value?.comboName}</Row>
                <Row style={{ width: "10%" }}>{minute}</Row>
                {price}
              </Row>
            );
          })}
      </Col>
    );
  };

  let rotate = 90;
  let textTitle = STRINGS.confirm_booking_user(numberOrdinal);
  let paddingBottom = 0;
  let borderBottom = 0;
  let src =
    "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/a52b7629-5ce3-466c-a369-2d20989c2b8b909e75ab-1350-4ab3-8f79-8f08d91af2ba27aaf8bd-7d13-428f-861a-b9bc2fc39d453fa70bf0-af81-4d9b-83df-874ae1ef8b8c.png";
  const customerPhone = form.getFieldValue(`userInfo`)?.phoneNumber;
  const customerName = form.getFieldValue(`userInfo`)?.name;
  if (assignStaff?.avatarUrl) {
    src = assignStaff.avatarUrl;
  }
  if (isShow) {
    rotate = -90;
    paddingBottom = 10;
    borderBottom = `1px solid ${COLORS.border}`;
  }
  if (totalUser === 1) {
    textTitle = STRINGS.confirm_book;
  }
  return (
    <Col className="booking-user-area">
      <Row
        style={{ justifyContent: "space-between" }}
        onClick={() => {
          setIsShow(!isShow);
        }}>
        <Row className="text-booking">{textTitle}</Row>
        <Row
          style={{
            border: `1px solid ${COLORS.border}`,
            height: "fit-content",
            padding: "3px",
          }}>
          <DoubleRightOutlined rotate={rotate} />
        </Row>
      </Row>
      <Row
        style={{
          paddingTop: "20px",
          paddingBottom,
          borderBottom,
        }}>
        <Row style={{ alignItems: "center" }}>
          {STRINGS.name_user_booking}: {"\xa0"}
          <Row className="text-info" style={{ fontSize: "16px" }}>
            {numberOrdinal === 1
              ? customerName
              : form.getFieldValue(`bookingUserName${numberOrdinal}`)}
          </Row>
        </Row>
        {numberOrdinal === 1 && (
          <Row style={{ alignItems: "center" }}>
            <Row style={{ paddingLeft: "50px" }}>
              {STRINGS.phone_number}: {"\xa0"}
            </Row>
            <Row className="text-info" style={{ fontSize: "16px" }}>
              {customerPhone}
            </Row>
          </Row>
        )}
      </Row>
      {isShow && (
        <Col>
          <Col style={{ borderBottom: `1px solid ${COLORS.border}` }}>
            <Row
              style={{
                paddingTop: "15px",
                fontWeight: "bold",
                paddingBottom: "15px",
              }}>
              {STRINGS.course}
            </Row>
            {renderCombo()}

            <Row
              style={{
                paddingTop: "30px",
                paddingBottom: "30px",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <Row>
                {noteBooking && (
                  <Row>
                    <Row className="text-booking-step3">
                      {STRINGS.confirm_note + "\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}
                    </Row>
                    <Row>{noteBooking}</Row>
                  </Row>
                )}
              </Row>
              <Row
                style={{
                  justifyContent: "flex-end",
                  font: "normal normal bold 18px/24px Roboto",
                }}>
                {STRINGS.total}
                <div
                  style={{
                    font: " normal normal bold 24px/20px Roboto",
                    color: COLORS.primary,
                    paddingLeft: "20px",
                  }}>
                  {totalPrice}
                  {STRINGS.yen}
                </div>
              </Row>
            </Row>
          </Col>

          {!!assignStaff && (
            <Row
              className="text-booking"
              style={{ paddingTop: "20px", alignItems: "center" }}>
              {STRINGS.name2}

              <img
                alt=""
                src={src}
                style={{ width: 36, height: 36, margin: "0px 15px" }}
              />
              <Col style={{ fontSize: 14, color: COLORS.border_7 }}>
                {assignStaff?.name}
                {assignStaff?.designationFee
                  ? `(+${assignStaff.designationFee}${STRINGS.yen})`
                  : `(${STRINGS.free})`}
              </Col>
            </Row>
          )}
        </Col>
      )}
    </Col>
  );
}

export { StaffBookingStep3Item };
