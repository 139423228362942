import React, { useState, useEffect } from "react";
import { Col, Input, Row, Button } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";

import { COLORS, LOGIC, STRINGS } from "../../../constants";
import "../StaffSchedule.scss";
import { FetchApi } from "../../../api";
import { ModalCustomService } from "../../../elements";
import ModalInfoCustomer from "./ModalInfoCustomer";
import PaginationWithSizeChange from "../../../elements/PaginationWithSizeChange";

function ModalBookingDetailTableRight({ userId, setNG, onShowInfo }) {
  const [pagination, setPagination] = useState({ size: 10, page: 1 });
  const { data } = useQuery(
    [`TableRight-ModalBookingDetail-${pagination.size}-${pagination.page}`],
    () =>
      FetchApi.historyUserBooking({
        page: pagination.page,
        size: pagination.size,
        userId: userId,
      }),
    {
      enabled: !!userId,
    }
  );

  useEffect(() => {
    if (data?.staffNotGood?.length > 0) {
      setNG(data?.staffNotGood);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderFee = (item) => {
    const status = item.status;
    let cancelFeeDes = STRINGS.cancel_fee[status];
    if (!item.cancelFee) {
      cancelFeeDes = STRINGS.cancel_fee.cancel_accompany(0);
    }
    if (status.toString().includes("cancel")) {
      if (status === "cancel_no_contact") {
        return (
          <Col style={{ color: COLORS.required }}>
            {STRINGS.cancel_no_contact_fee(item.price || 0)}
          </Col>
        );
      }
      return <Col style={{ color: COLORS.required }}>{cancelFeeDes}</Col>;
    }
  };
  const renderComment = (item) => {
    if (item.reviewComment || item.status.toString().includes("cancel")) {
      return (
        <Row style={{ width: "100%" }}>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 14,
              fontWeight: "bold",
              color: COLORS.required,
            }}
            span={3}
          >
            X
          </Col>
          <Col span={20} style={{ marginTop: 10 }}>
            <Row style={{ width: "100%" }}>
              <Col span={4} style={{ fontWeight: "900", fontSize: 15 }}>
                {STRINGS.remarks} :
              </Col>
              <Col span={20}>{!!item.reviewComment && item.reviewComment}</Col>
            </Row>

            {renderFee(item)}
          </Col>
        </Row>
      );
    }
  };
  return (
    <Col className="table-right">
      <Row
        style={{
          fontSize: 40,
          letterSpacing: 8,
          fontWeight: "bold",
          justifyContent: "center",
          marginTop: 10,
          color: COLORS.sub_accent,
        }}
      >
        {STRINGS.history_go_store}
      </Row>
      <Row className="theader" style={{ marginTop: 20 }}>
        <Col span={3}>{STRINGS.name2}</Col>
        <Col span={6}>{STRINGS.date2}</Col>
        <Col span={5}>{STRINGS.store_management}</Col>
        <Col span={5}>{STRINGS.responsible_person}</Col>
        <Col span={5}>{STRINGS.course}</Col>
      </Row>
      {(data?.orderHistoryDTOS || []).map((item, index) => {
        return (
          <Col
            key={`${index}`}
            className="row-table-custom"
            style={{
              paddingTop: 10,
              borderBottom: "1px solid #b4bbc4",
            }}
          >
            <Row>
              <Col span={3}>
                {item.chooseStaff ? (
                  <Col
                    style={{
                      marginTop: 5,
                      width: 14,
                      height: 14,
                      left: "40%",
                      borderRadius: 7,
                      borderWidth: 1,
                      borderColor: COLORS.primary,
                      borderStyle: "solid",
                    }}
                  />
                ) : (
                  ""
                )}
              </Col>
              <Col span={6} style={{ alignSelf: "center" }}>
                {moment(item.startTime).format(LOGIC.dateFormat)}
              </Col>
              <Col span={5}>{item.storeName}</Col>
              <Col span={5}>{item.staffName}</Col>
              <Col span={5}>
                {Array.isArray(item.comboOrders) && item.comboOrders.length > 0
                  ? item.comboOrders.map(
                      (itm, idx, arr) =>
                        `${itm.comboName || "none"} (${
                          itm.minutes || "none"
                        })` + (idx !== arr.length - 1 ? ", " : "")
                    )
                  : ""}
              </Col>
            </Row>
            <Row
              style={{
                paddingBottom: 10,
                textAlign: "left",
              }}
            >
              {renderComment(item)}
              {/* {!!item.reviewComment && (
                  <Col style={{ marginTop: 10 }}>
                    <Row>
                      {STRINGS.remarks} : <br></br> {item.reviewComment}
                    </Row>
                  </Col>
                )} */}
            </Row>
          </Col>
        );
      })}

      <PaginationWithSizeChange
        size={pagination.size}
        total={data?.pageDto?.totalElements || 0}
        onChange={(pageNumber) => {
          setPagination({ size: pagination.size, page: pageNumber });
        }}
        onShowSizeChange={(e) => {
          setPagination({ size: e.size, page: 1 });
        }}
        current={pagination.page}
      />
    </Col>
  );
}

export default function ModalInfoUserDetail({
  haveCheckIn,
  onCheckInBooking,
  refetch,
  data,
  disabled,
}) {
  const [NG, setNG] = useState(
    Array.isArray(data.staffIdsNG) ? data.staffIdsNG : []
  );

  const onShowInfo = async () => {
    const dataUser = {
      customerName: data.name,
      customerPhone: data.phoneNumber,
      customerGender: data.sex,
      userId: data.id,
      backgroundDisease: data.backgroundDisease,
      bookingId: data.id,
      customerType: data.customerType,
      furiganaName: data.furiganaName,
      birthday: data.birthday,
      banned: data.banned,
      cityId: data.cityId,
      districtId: data.districtId,
    };
    ModalCustomService.set({
      title: STRINGS.edit_information_customer,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <ModalInfoCustomer data={dataUser} refetchCalendarOrder={refetch} />
      ),
    });
  };

  const InfoCustomer = ({ label, value, styleValue, styleWrapper }) => {
    return (
      <Row
        style={{
          textAlign: "left",
          marginBottom: 8,
          alignItems: "center",
          ...styleWrapper,
        }}
      >
        <Col span={8} style={{ color: "black", fontSize: 18 }}>
          {label}
        </Col>
        {!Array.isArray(value) ? (
          <Col style={{ fontWeight: "bold", fontSize: 18, ...styleValue }}>
            {value}
          </Col>
        ) : (
          value.map((item, index) => (
            <span
              key={`${index}`}
              style={{ fontWeight: "bold", fontSize: 18, ...styleValue }}
            >
              {item}
              {index !== value.length - 1 ? ",\xa0" : ""}
            </span>
          ))
        )}
      </Row>
    );
  };
  const checkDeleted = () => {
    if (data?.deleted)
      return (
        <Row>
          <p style={{ fontSize: 18, color: COLORS.required }}>
            {STRINGS.deleted_user}
          </p>
        </Row>
      );
    return null;
  };
  return (
    <Row className="modal-booking-detail-wrapper">
      <Col
        className="customer-info"
        style={{ pointerEvents: disabled ? "none" : "auto" }}
      >
        <Row style={{ margin: "12px 0px 5px 0px" }}>
          <Col span={13} style={{ fontWeight: "bold" }}>
            {checkDeleted()}
            {[
              { label: STRINGS.member_id, value: data.customerId },
              { label: STRINGS.furigana_name, value: data.furiganaName },
              { label: STRINGS.name_user_booking, value: data.name },
              { label: STRINGS.gender, value: STRINGS[data.sex] },
              { label: STRINGS.phone_number, value: data.phoneNumber },
              { label: STRINGS.email, value: data.email },
              {
                label: STRINGS.birthday,
                value: moment(data.birthday).isValid() ? data.birthday : "",
              },

              {
                label: STRINGS.address,
                value: data.address,
              },
              {
                label: STRINGS.favourite_store,
                value: data.storeName ? data.storeName : "ー",
              },
              {
                label: STRINGS.registration_date,
                value: data.registrationDate
                  ? moment(data.registrationDate).format(LOGIC.timeFormat)
                  : "ー",
              },
              {
                label: STRINGS.member,
                value: data.banned
                  ? STRINGS.customer_type_new.X
                  : STRINGS.customer_type_new[data.customerType] || "ー",
                styleValue: {
                  color: LOGIC.colorCustomerType[data.customerType],
                },
              },

              {
                label: STRINGS.NG_designation,
                value: NG.length > 0 ? NG : "ー",
              },
              {
                label: STRINGS.cancellation_charge,
                value: data.cancelFee
                  ? LOGIC.currencyFormatter(data.cancelFee)
                  : "ー",
                styleWrapper: { marginBottom: 20 },
              },

              //   {
              //     label: STRINGS.reservation_time,
              //     value: data.startTime
              //       ? `${moment(data.startTime).format("MM月DD日")}
              //         (${LOGIC.day[moment(data.startTime).weekday() || 7]})
              //          ${moment(data.startTime).format("HH:mm")}`
              //       : "ー",
              //   },
            ].map((item) => (
              <InfoCustomer
                key={item.label}
                label={item.label}
                value={item.value || "ー"}
                styleValue={item.styleValue}
                label2={item.label2}
                value2={item.value2}
                styleWrapper={item.styleWrapper}
              />
            ))}
          </Col>
          <Col span={11}>
            <Button
              // disabled="true"
              className="bottom-btn"
              style={{
                width: "100%",
                height: "fit-content",
                justifyContent: "center",
                backgroundColor: disabled ? COLORS.disabled : "#4f7a28",
              }}
              onClick={onShowInfo}
            >
              <EditOutlined className="icon" style={{ fontSize: 30 }} />
              <Row
                style={{
                  fontSize: 23,
                  fontWeight: "bold",
                  letterSpacing: 3,
                }}
              >
                {data.customerType === "R" && !data.banned
                  ? STRINGS.registration
                  : STRINGS.edit}
              </Row>
            </Button>
            {!!haveCheckIn && (
              <Button
                className="bottom-btn"
                style={{
                  width: "100%",
                  height: 83,
                  justifyContent: "center",
                  lineHeight: 1.3,
                  backgroundColor: "#FE6C06",
                }}
                onClick={() => onCheckInBooking(data.id)}
              >
                <CheckCircleOutlined
                  className="icon"
                  style={{ fontSize: 35 }}
                />
                <Row
                  style={{
                    fontWeight: "bold",
                    whiteSpace: "pre-line",
                    textAlign: "left",
                    fontSize: 38,
                  }}
                >
                  {STRINGS.checkin_booking}
                </Row>
              </Button>
            )}
          </Col>
        </Row>

        <Row style={{ fontSize: 18 }}>{STRINGS.background_disease}</Row>

        <Input.TextArea
          value={data.backgroundDisease}
          disabled
          style={{ fontSize: 18 }}
          rows={6}
        />
      </Col>

      <ModalBookingDetailTableRight
        userId={data.id}
        setNG={setNG}
        onShowInfo={onShowInfo}
      />
    </Row>
  );
}
