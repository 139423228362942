import React, { useState } from "react";
import { Col, Row } from "antd";

import { COLORS, STRINGS, LOGIC } from "../../../constants";
import { PointDayConfigTime } from "./PointDayConfigTime";
import { PointDayConfigDay } from "./PointDayConfigDay";
import { PointDayConfigDate } from "./PointDayConfigDate";

import "../ManagerPointDay.scss";

export function PointDayConfig({ form, initialValue }) {
  const [pointType, setPointType] = useState(initialValue?.type);

  const listItem = [
    {
      title: STRINGS.coupon_time,
      type: LOGIC.booking_point.rangeWithTime,
    },
    {
      title: STRINGS.coupon_day,
      type: LOGIC.booking_point.dayOfWeek,
    },
    {
      title: STRINGS.coupon_date,
      type: LOGIC.booking_point.date,
    },
  ];

  const renderSelect = (item) => {
    if (item.type === pointType) {
      return (
        <div
          style={{
            width: "18px",
            height: "18px",
            borderRadius: "9px",
            backgroundColor: COLORS.success,
          }}
        />
      );
    }
  };
  const renderContent = (i) => {
    const formProps = {
      pointType: pointType,
      form: form,
      initialValue: initialValue,
    };
    if (i.type === LOGIC.booking_point.rangeWithTime) {
      return <PointDayConfigTime {...formProps} />;
    }
    if (i.type === LOGIC.booking_point.dayOfWeek) {
      return <PointDayConfigDay {...formProps} />;
    }
    return <PointDayConfigDate {...formProps} />;
  };
  return (
    <div className="end-time">
      {listItem.map((i) => {
        return (
          <Row key={`${i.title}`} style={{ marginTop: 10 }}>
            <Col span={24}>
              <Row>
                <div
                  style={{
                    width: "24px",
                    height: "24px",
                    borderRadius: "12px",
                    border: `1px solid ${COLORS.border}`,
                    backgroundColor: COLORS.background,
                    display: "flex",
                    placeContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    if (pointType === i.type) {
                      setPointType();
                      form.setFieldsValue({ type: "" });
                      return;
                    }
                    setPointType(i.type);
                    form.setFieldsValue({ type: i.type });
                  }}>
                  {renderSelect(i)}
                </div>
                <Col>{i.title}</Col>
              </Row>
              {renderContent(i)}
            </Col>
          </Row>
        );
      })}
    </div>
  );
}
