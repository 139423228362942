import React, { useState } from "react";
import { Upload, message } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

import { FetchApi } from "../api";
import { CommonFunction } from "../util/CommonFunction";
import { COLORS, STRINGS } from "../constants";

const ImageListUpload = ({ value, onChange, aspect }) => {
  const [fileList, setFileList] = useState(() => {
    if (value && value.length > 0) {
      const valueFilter = [...value];
      valueFilter.forEach((item, index) => {
        valueFilter[index].url = item.imageUrl || item.url;
        valueFilter[index].uid = item.id;
      });
      return valueFilter;
    }
    return [];
  });

  const handleRequest = async (file) => {
    let result = await FetchApi.uploadFile(file);
    if (!result.fileUrl) {
      message.error(STRINGS.upload_img_failed);
    } else {
      setFileList([...fileList, { url: result.fileUrl, uid: new Date() }]);
      onChange &&
        onChange([...fileList, { url: result.fileUrl, uid: new Date() }]);
    }
  };

  const onRemove = async (item) => {
    let newSlide = fileList.filter((itm) => itm.uid !== item.uid);
    setFileList(newSlide);
    onChange && onChange(newSlide);
  };

  return (
    <div style={{ paddingTop: 15, textAlign: "left" }}>
      <ImgCrop
        modalTitle={STRINGS.editImageCrop}
        modalOk={STRINGS.ok}
        modalCancel={STRINGS.cancel}
        rotate
        aspect={aspect || 1 / 1}>
        <Upload
          listType="picture-card"
          fileList={fileList}
          customRequest={handleRequest}
          onPreview={CommonFunction.onPreview}
          onRemove={onRemove}>
          {fileList.length < 20 && (
            <div>
              <PlusCircleOutlined
                style={{ color: COLORS.primary_3, fontSize: 32 }}
              />
              <div>{STRINGS.upload}</div>
            </div>
          )}
        </Upload>
      </ImgCrop>
    </div>
  );
};

export { ImageListUpload };
