import React from "react";
import { Col, Form, Input, Row, message, Select } from "antd";

import { FetchApi } from "../../../api";
import { STRINGS } from "../../../constants";
import {
  ButtonCustom,
  ImageUpload,
  ModalCustomService,
  LoadingAbsolute,
  SuffixIconDown,
  Validate,
} from "../../../elements";
import { CommonFunction } from "../../../util/CommonFunction";

import "../Gacha.scss";

const Option = Select.Option;

const Edit = ({ data, setData, item }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  const onAdd = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      setLoading(true);
      const result = await FetchApi.updateGacha({ ...fieldData, id: item.id });
      if (result.success) {
        const index = data.gachaDTOList.findIndex((itm) => itm.id === item.id);
        const clone = JSON.parse(JSON.stringify({ ...data }));
        clone.gachaDTOList[index] = result.gachaDTO;
        setData(clone.gachaDTOList);
        ModalCustomService.close();
        message.success(STRINGS.saved_common);
      } else if (result.message === "This Gacha already exists") {
        setLoading(false);
        message.warning(STRINGS.this_gacha_is_existed);
      } else {
        setLoading(false);
        CommonFunction.logError(result);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return (
    <Col className="gacha-form">
      <Form layout="vertical" form={form}>
        <Form.Item
          style={{ marginBottom: 10 }}
          required
          name="name"
          label={STRINGS.award_name}
          rules={[Validate.emptyContent]}
          initialValue={item?.name}>
          <Input />
        </Form.Item>

        <Row style={{ width: "100%" }}>
          <Col xl={8} style={{ paddingRight: 20 }}>
            <Form.Item
              label={STRINGS.winning_digits}
              name="numberOfDigits"
              initialValue={item?.numberOfDigits}>
              <Select
                style={{ textAlign: "left" }}
                suffixIcon={<SuffixIconDown />}>
                <Option key={0}>0</Option>
                <Option key={1}>1</Option>
                <Option key={2}>2</Option>
                <Option key={3}>3</Option>
                <Option key={4}>4</Option>
                <Option key={5}>5</Option>
                <Option key={6}>6</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={16}>
            <Form.Item
              required
              label={STRINGS.points_awarded}
              name="prizePoint"
              rules={[Validate.isPositiveNumber]}
              initialValue={item?.prizePoint}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="imageUrl"
          label={STRINGS.image}
          style={{ width: "100%" }}
          initialValue={item?.imageUrl}>
          <ImageUpload aspect={3.2 / 1} />
        </Form.Item>
      </Form>
      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onAdd}>{STRINGS.update}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
};

export default Edit;
