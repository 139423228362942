import React, { useEffect, useState } from "react";
import { FetchApi } from "../../../api";
import { Col, DatePicker, Form, Radio, Row } from "antd";
import {
  ButtonCustom,
  ImageUpload,
  ModalCustomService,
  LoadingAbsolute,
  Validate,
} from "../../../elements";
import { Svgs, STRINGS, LOGIC } from "../../../constants";
import News from "./News";
import Detail from "./Detail";
import Webview from "./Webview";
import moment from "moment";

function SlideOption({ onClick, loading, form, item }) {
  const [action, setAction] = useState(item?.type);
  const [newsList, setNewsList] = useState([]);

  useEffect(() => {
    const getNewsList = async () => {
      const res = await FetchApi.getAllNews(1, 50);
      if (res.success) {
        setNewsList(res.data);
      }
    };
    if (action === "NEWS") {
      getNewsList();
    }
    form.resetFields(["newsId", "content", "link"]);
  }, [action]);

  useEffect(() => {
    form.resetFields(["newsId"]);
  }, [newsList]);

  const options = {
    row1: [
      {
        value: "DO_NOTHING",
        title: STRINGS.do_nothing,
        span: "8",
      },
      {
        value: "DETAIL",
        title: STRINGS.detailed_screen_display,
        span: "8",
      },
      {
        value: "NEWS",
        title: STRINGS.detailed_info,
        span: "8",
        style: { width: "max-content" },
      },
    ],
    row2: [
      {
        value: "WEBVIEW",
        title: STRINGS.display_web,
        span: "16",
      },
      {
        value: "STORE_LIST",
        title: STRINGS.store_list,
        span: "8",
      },
    ],
  };

  return (
    <Col className="slide-setting-form">
      <Form layout="vertical" form={form}>
        <Row style={{ flexWrap: "wrap" }} gutter={20}>
          <Col xl={16}>
            <Form.Item
              style={{ marginBottom: 10, paddingRight: 20 }}
              name="imageUrl"
              label={STRINGS.image_required}
              required
              rules={[Validate.emptyContent]}
              initialValue={item?.imageUrl}
            >
              <ImageUpload aspect={3 / 2} />
            </Form.Item>
          </Col>
          <Col style={{ marginTop: 20 }} xl={8}>
            <Form.Item
              name="startTime"
              label={STRINGS.delivery_start_time}
              rules={[Validate.emptyContent]}
              initialValue={moment(item?.startTime)}
              required
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={STRINGS.delivery_start_time}
                suffixIcon={Svgs.datepicker()}
                format={LOGIC.timeFormat}
                showTime
              />
            </Form.Item>
            <Form.Item
              name="endTime"
              label={STRINGS.delivery_end_time}
              rules={[Validate.emptyContent]}
              initialValue={moment(item?.endTime)}
              required
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={STRINGS.delivery_end_time}
                suffixIcon={Svgs.datepicker()}
                format={LOGIC.timeFormat}
                showTime
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Form.Item
      style={{ marginBottom: 20 }}
      label={STRINGS.name_slide}
      name="title"
      rules={[Validate.emptyContent]}
      required>
      <Input />
    </Form.Item> */}
        <Form.Item
          label={STRINGS.behavior_click}
          name="type"
          initialValue={item?.type ?? "DO_NOTHING"}
        >
          <Radio.Group
            style={{
              marginLeft: "10%",
            }}
            onChange={(e) => {
              setAction(e.target.value);
            }}
          >
            <Row>
              {options.row1.map((item) => (
                <Col key={item.value} span={item.span}>
                  <Radio
                    style={item.style ? item.style : null}
                    value={item.value}
                  >
                    {item.title}
                  </Radio>
                </Col>
              ))}
              <Row />
            </Row>
            <Row>
              {options.row2.map((item) => (
                <Col key={item.value} span={item.span}>
                  <Radio
                    style={item.style ? item.style : null}
                    value={item.value}
                  >
                    {item.title}
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
        {action === "DETAIL" && <Detail data={item} />}
        {action === "NEWS" && <News newsList={newsList} data={item} />}
        {action === "WEBVIEW" && <Webview data={item} />}
      </Form>
      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}
        >
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onClick}>{STRINGS.save}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}

export default SlideOption;
