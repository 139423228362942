/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Result, Col, Row, Form, Input, Button, message } from "antd";

import { FetchApi } from "../../api";
import { COLORS, STRINGS } from "../../constants";

// 'http://localhost:3000/v2/app/forgot_password?code=L98CPGekCikGDIxJVoQnkT35jiQuq7yA&email=host44@yopmail.com'

const ForgotPassword = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }
  const [form] = Form.useForm();

  const code = getQueryVariable("code");
  const email = getQueryVariable("email");

  useEffect(() => {
    let isMounted = true;
    const checkBeforeResetPass = async () => {
      const result = await FetchApi.confirmEmailResetPassword({
        code,
        email,
      });
      isMounted && setLoading(false);
      if (result.success) {
      } else if (result.code == 4003) {
        setError(STRINGS.password_has_changed);
      } else {
        isMounted && setError(STRINGS.something_wrong);
      }
    };
    checkBeforeResetPass();
    return () => {
      isMounted = false;
    };
  }, []);

  const validateNewPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      const re = /(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]*$/;
      if (value === undefined || value.length === 0) {
        return Promise.reject(STRINGS.this_field_is_required);
      }
      if (!re.test(value)) {
        return Promise.reject(STRINGS.password_has_character_and_number);
      }
      if (value.length < 8 || value.length > 32) {
        return Promise.reject(STRINGS.password_8_to_32);
      }
      return Promise.resolve();
    },
  });

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (value === undefined || value.length === 0) {
        return Promise.reject(STRINGS.this_field_is_required);
      }
      if (value !== form.getFieldValue("newPassword")) {
        return Promise.reject(STRINGS.password_is_not_matching);
      }
      return Promise.resolve();
    },
  });

  const onChangePass = async () => {
    try {
      await form.validateFields();
      const { newPassword } = form.getFieldValue();
      const result = await FetchApi.confirmChangePassword({
        code,
        email,
        password: newPassword,
      });
      if (result.success) {
        message.success(STRINGS.done_successful);
        window.location.href = "/login";
      } else {
        setError(result.message);
      }
    } catch (error) {
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return (
    <Row
      style={{ width: "100vw", height: "100vh" }}
      align="middle"
      justify="center">
      <Col style={{ textAlign: "-webkit-center" }}>
        <div className="title">
          <img
            src={
              "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/aa794753-9c10-4231-81c9-7f0f6f2475ebe363c7be-9fce-4005-97c5-75cda835b4c18a65a709-21ea-433e-811d-18acfe34cf33508d8e7b-ad27-4963-89b5-53eee6e66cbb.png"
            }
            alt="logo"
            style={{ width: 400 }}
          />
        </div>
        {loading && <Result title="Waiting..." />}
        {!!error && <Result title={error} />}
        {!loading && !error && (
          <Form
            layout="vertical"
            form={form}
            style={{ width: "400px", marginTop: 30 }}>
            <Form.Item
              label={STRINGS.new_password}
              name="newPassword"
              required
              rules={[validateNewPassword]}>
              <Input.Password
                onChange={() => form.validateFields(["confirmPass"])}
              />
            </Form.Item>
            <Form.Item
              label={STRINGS.confirm_password}
              name="confirmPass"
              required
              rules={[validateConfirmPassword]}>
              <Input.Password />
            </Form.Item>
            <Button
              style={{
                marginTop: 10,
                backgroundColor: COLORS.primary,
                color: "white",
                width: "100%",
                borderRadius: 5,
                height: 36,
              }}
              onClick={onChangePass}>
              {STRINGS.change}
            </Button>
          </Form>
        )}
      </Col>
    </Row>
  );
};

export default ForgotPassword;
