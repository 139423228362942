/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef } from "react";
import { Col, Row, Button, message, Form } from "antd";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

import { COLORS, LOGIC, STRINGS } from "../../../constants";
import { FetchApi } from "../../../api";
import {
  AlertService,
  LoadingAbsolute,
  ModalCustomService,
} from "../../../elements";
import { CommonFunction } from "../../../util/CommonFunction";
import { checkAddtionalFee, useGetDetailCourseBooking } from "../modules";
import { ModalBookingDetailPayment } from "../elements/ModalBookingDetailPayment";
import { ModalBookingDetailComment } from "../elements/ModalBookingDetailComment";
import { ModalBookingDetailAction } from "../elements/ModalBookingDetailAction";
import { ModalBookingDetailInfoCustomer } from "../elements/ModalBookingDetailInfoCustomer";
import { ModalStaffCancelBooking } from "../../staff-cancel-history/items/ModalStaffCancelBooking";
import { ScheduleBookingService } from "../../../services";
import { useGetStoreSurcharge } from "../../combo/tabs/price/modules/useGetStoreSurcharge";
import Auth from "../../../store/Authentication";
import ModalBookingDetailTableCombo from "../elements/ModalBookingDetailTableCombo";
import ModalBookingDetailTableRight from "../elements/ModalBookingDetailTableRight";
import { staffScheduleKey } from "../modules/staffScheduleKey";

import "../StaffSchedule.scss";

function useGetStatusBooking(bookingId) {
  const { data, refetch, isLoading } = useQuery({
    queryFn: () => FetchApi.getStatusBooking(bookingId),
    queryKey: [staffScheduleKey.getStatusBooking, bookingId],
  });
  return {
    statusBooking: data?.calendarOrder?.status,
    refetchStatusBooking: refetch,
    isLoadingStatusBooking: isLoading,
    customerId: data?.calendarOrder?.customerId,
  };
}
const ModalBookingDetail = forwardRef((props, ref) => {
  const { refetch, data = {}, disabled, selectedDate } = props;
  const { storeSurcharge, isLoading: isLoadingStoreSurcharge } =
    useGetStoreSurcharge();
  const [NG, setNG] = useState([]);
  const [loading, setLoading] = useState(false);
  const { statusBooking, isLoadingStatusBooking, customerId } =
    useGetStatusBooking(data.id);
  const { dataBooking, isLoading } = useGetDetailCourseBooking(data.id);

  const [form] = Form.useForm();
  const cache = useQueryClient();
  const [isCheckInBooking, setIsCheckInBooking] = useState(
    !!data.userCheckInTime
  );
  const [isChargeCancelFee, setIsChargeCancelFee] = useState(
    !dataBooking?.owed
  );

  const [disablePay, setDisablePay] = useState(!data.userCheckInTime);
  const [total, setTotal] = useState(data.price);
  const [providePoint, setProvidePoint] = useState();
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const { lateBookingFee, addtionalManFee } = checkAddtionalFee({
    storeSurcharge,
    data,
    comboOrders: dataBooking?.comboOrders,
    dataBooking: dataBooking,
  });
  useEffect(() => {
    if (data.userCheckInTime) {
      setIsCheckInBooking(true);
    } else {
      setDisablePay(true);
    }
  }, [statusBooking]);

  useEffect(() => {
    setProvidePoint(dataBooking?.bookingPointManualId);
    if (dataBooking?.owed) {
      setIsChargeCancelFee(false);
    } else {
      setIsChargeCancelFee(true);
    }
  }, [dataBooking]);

  const onAfterCloseModal = () => {
    const { bookingDetail, refetchCalendarOrder } =
      ScheduleBookingService.get();
    ModalCustomService.set({
      title: STRINGS.detail_reservation,
      titleStyle: {
        fontSize: 25,
      },
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },

      content: (
        <ModalBookingDetail
          refetch={refetchCalendarOrder}
          data={bookingDetail}
        />
      ),
    });
  };
  const onAction = (status, des, title, confirmText) => async () => {
    let styleModal;
    if (status === "done") {
      styleModal = { width: 900 };
    }
    if (status?.includes?.("cancel")) {
      ModalCustomService.set({
        title: STRINGS.staff_cancel_book,
        titleStyle: { fontSize: 25 },
        wrapperStyle: { width: "auto" },
        contentWrapperStyle: { width: "fit-content" },
        contentStyle: { maxHeight: "80vh", padding: 0 },
        onAfterClose: onAfterCloseModal,
        content: (
          <ModalStaffCancelBooking
            onClose={onAfterCloseModal}
            status={status}
            dataBooking={data}
            refetch={refetch}
            form={form}
          />
        ),
      });
      return;
    }
    ModalCustomService.close();
    AlertService.set({
      title: title || STRINGS.confirm,
      textConfirm: confirmText || STRINGS.confirm,
      styleModal,
      content: des || STRINGS.confirm_cancel_common,
      confirmStyle: {
        fontWeight: "bold",
        fontSize: 22,
        width: 150,
      },
      titleStyle: {
        fontWeight: "bold",
        fontSize: 22,
      },
      cancelStyle: {
        fontWeight: "bold",
        fontSize: 22,
        width: 150,
      },

      onConfirm: async () => {
        let result;
        const { comboDTOS, userCheckInTime, couponIds, comment, listCoupon } =
          form.getFieldValue();
        const newCoupons = [];
        if (listCoupon?.length > 0 && couponIds.length > 0) {
          couponIds.forEach((item) => {
            if (typeof item === "string") {
              const coupon = listCoupon.find((v) => v.title === item);
              newCoupons.push(coupon.id);
            } else newCoupons.push(item);
          });
        }
        if (status === "done") {
          let checkTime = null;
          if (moment(data.userCheckInTime).isValid()) {
            checkTime = moment(data.userCheckInTime);
          }
          if (userCheckInTime) {
            checkTime = moment(userCheckInTime);
          }

          const obj = {
            bookingId: data.id,
            packs: [],
            price: 0,
            sideEdit: "STORE",
          };
          comboDTOS.forEach((item, index) => {
            if (item.comboPackId) {
              if (item.comboExtraId) {
                obj.packs.push({
                  id: item.comboPackId,
                  minutes: item.minutes + item.minuteExtra,
                  price: item.price + item.priceExtra,
                  prolongedCourseId: item.comboExtraId,
                });
              } else {
                obj.packs.push({
                  id: item.comboPackId,
                  minutes: item.minutes,
                  price: item.price,
                });
              }
            }
          });
          if (obj.packs.length === 0) {
            obj.minutes = data.minutes;
          }

          result = await FetchApi.updateInfomationBooking({
            commentOfStaff: comment,
            couponIds: newCoupons,
            userCheckInTime: checkTime,
            ...obj,
            bookingPointManualId: dataBooking?.bookingPointManualId,
            providePointType: dataBooking?.bookingPointManualId
              ? "MANUAL"
              : "AUTO",
            pay: true,
          });
        } else {
          result = await FetchApi.cancelBooking({
            id: data.id,
            status,
            commentOfStaff: comment,
            couponIds: couponIds,
          });
        }

        if (result.success) {
          cache.refetchQueries("bookingComing");
          cache.refetchQueries(`getDetailCourseBooking-${data.id}`);
          cache.refetchQueries(`bookingComing-${userAdmin.storeId}`);

          refetch();
          if (status === "done") {
            message.success(STRINGS.pay_booking_success);
          } else {
            message.success(STRINGS.canceled_common);
          }
        } else {
          if (result.message) {
            CommonFunction.logError(result);
            return;
          }
        }
      },
    });
  };

  const chargeCancelFee = async () => {
    ModalCustomService.set({
      title: STRINGS.staff_cancel_book,
      titleStyle: { fontSize: 25 },
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      onAfterClose: onAfterCloseModal,
      content: (
        <ModalStaffCancelBooking
          onClose={onAfterCloseModal}
          dataBooking={data}
          refetch={refetch}
          form={form}
          isChargeCancelFee
          setIsChargeCancelFee={setIsChargeCancelFee}
        />
      ),
    });
  };

  const onUpdateBooking = async (data) => {
    ScheduleBookingService.remove();
    const { comboDTOS, userCheckInTime, comment, couponIds, listCoupon } =
      form.getFieldValue();
    let newCoupons = [];
    if (listCoupon?.length > 0) {
      couponIds.forEach((item) => {
        if (typeof item === "string") {
          const coupon = listCoupon.find((v) => v.title === item);
          newCoupons.push(coupon.id);
        } else newCoupons.push(item);
      });
    }
    if (newCoupons.length === 0) newCoupons = [...couponIds];
    const obj = {
      bookingId: data.id,
      packs: [],
      price: 0,
      sideEdit: "STORE",
    };
    comboDTOS?.forEach?.((item, index) => {
      if (item.comboPackId) {
        if (item.comboExtraId) {
          obj.packs.push({
            id: item.comboPackId,
            minutes: item.minutes + item.minuteExtra,
            price: item.price + item.priceExtra,
            prolongedCourseId: item.comboExtraId,
          });
        } else {
          obj.packs.push({
            id: item.comboPackId,
            minutes: item.minutes,
            price: item.price,
          });
        }
      }
    });
    if (obj.packs.length === 0) {
      obj.minutes = data.minutes;
    }
    let checkTime = null;
    if (moment(data.userCheckInTime).isValid()) {
      checkTime = moment(data.userCheckInTime);
    }
    if (userCheckInTime) {
      checkTime = moment(userCheckInTime);
    }

    const result = await FetchApi.updateInfomationBooking({
      commentOfStaff: comment,
      couponIds: newCoupons,
      userCheckInTime: checkTime,
      ...obj,
      bookingPointManualId: providePoint,
      providePointType: providePoint ? "MANUAL" : "AUTO",
      pay: false,
    });

    if (result.success) {
      cache.refetchQueries(staffScheduleKey.paidBooking);
      data.userCheckInTime = checkTime;
      message.success(STRINGS.done_successful);
      return result;
    } else {
      message.error(STRINGS.update_booking_fail);
    }
    return result;
  };

  const handleClickUpdate = () => {
    ModalCustomService.set({
      content: STRINGS.sure_update_booking,
      contentStyle: {
        whiteSpace: "pre-wrap",
      },
      textConfirm: STRINGS.confirm,
      textCancel: STRINGS.cancel,
      wrapperStyle: { width: 420 },
      onConfirm: () => {
        onUpdateBooking(dataBooking);
      },
    });
  };

  const onChangeValue = (value) => {
    setProvidePoint(value);
  };

  const actionBookingType = LOGIC.actionBookingType[statusBooking];
  let textType = actionBookingType;
  if (data.paid || statusBooking === "done") {
    textType = "";
  }

  return (
    <Row className="modal-booking-detail-wrapper">
      <Col className="customer-info">
        <ModalBookingDetailAction
          data={data}
          form={form}
          setLoading={setLoading}
          setIsCheckInBooking={setIsCheckInBooking}
          setDisablePay={setDisablePay}
          actionBookingType={actionBookingType}
          textType={textType}
          isCheckInBooking={isCheckInBooking}
          refetch={refetch}
          cache={cache}
          disablePay={disablePay}
          disabled={disabled}
          selectedDate={selectedDate}
          onAction={onAction}
          statusBooking={statusBooking}
        />

        <Row
          style={{
            margin: "12px 0px 5px 0px",
          }}
        >
          <Col span={12} style={{ fontWeight: "bold" }}>
            {!isLoadingStatusBooking &&
              [
                {
                  label: STRINGS.date_time_book,
                  value: data.startTime
                    ? moment(data.startTime).format("HH:mm")
                    : "",
                },
                {
                  label: STRINGS.create_from,
                  value: data.createFrom,
                },
                {
                  label: STRINGS.responsible_person,
                  value: data.staffName,
                },
                {
                  label: STRINGS.note_booking,
                  value: data.note,
                },
                {
                  label: STRINGS.coupon_management,
                  value: data.couponDTO?.title,
                },
                {
                  label: STRINGS.discount_point,
                  value: data.pointUse
                    ? `-${data.pointUse}pt(${STRINGS.yen})`
                    : "",
                },

                {
                  label: STRINGS.price_late,
                  value: lateBookingFee ? lateBookingFee + STRINGS.yen : "",
                },
                {
                  label: STRINGS.price_bonus,
                  value: addtionalManFee ? addtionalManFee + STRINGS.yen : "",
                },
              ].map((item) => (
                <ModalBookingDetailInfoCustomer
                  key={item.label}
                  label={item.label}
                  value={item.value || "ー"}
                  styleValue={item.styleValue}
                  styleWrapper={item.styleWrapper}
                  data={data}
                  disabled={disabled}
                  setTotal={setTotal}
                  total={total}
                  form={form}
                  statusBooking={statusBooking}
                  refetch={refetch}
                />
              ))}
          </Col>
          <Col
            span={12}
            style={{
              fontWeight: "bold",
              // pointerEvents: disabled ? "none" : "auto",
              marginTop: 12,
            }}
          >
            {!isLoadingStatusBooking &&
              [
                {
                  label: STRINGS.time_connect,
                  value: data.userCheckInTime,
                  styleWrapper: { marginTop: -7 },
                },
                {
                  label: STRINGS.time_receive_booking,
                  value: moment(data.createdTime).format("YYYY/MM/DD HH:mm"),
                },
                {
                  label: STRINGS.is_assign,
                  valueAssign: {
                    chooseStaff: data.chooseStaff,
                    assignedSex: data.assignedSex,
                  },
                },
                {
                  label: STRINGS.note_special_booking,
                  value: (data.comboOrders || [])
                    .find((i) => i.option)
                    ?.option?.replace(/;/g, "・")
                    ?.slice(0, -1),
                  styleWrapper: { alignItems: "flex-start" },
                },
                {
                  label: STRINGS.point_provide,
                  value: dataBooking?.bookingPointManualId,
                },
              ].map((item) => (
                <ModalBookingDetailInfoCustomer
                  key={item.id || item.label}
                  label={item.label}
                  value={item.value || "ー"}
                  valueAssign={item.valueAssign}
                  styleValue={item.styleValue}
                  styleWrapper={item.styleWrapper}
                  data={data}
                  disabled={disabled}
                  isCheckInBooking={isCheckInBooking}
                  setTotal={setTotal}
                  total={total}
                  form={form}
                  statusBooking={statusBooking}
                  refetch={refetch}
                  onChangeVlaue={onChangeValue}
                  bookingPointManualId={dataBooking?.bookingPointManualId}
                />
              ))}
            <Col
              style={{
                paddingTop: 14,
                textAlign: "-webkit-right",
              }}
            >
              <Row
                style={{
                  color:
                    disabled || isChargeCancelFee ? COLORS.disabled : "red",
                  fontSize: 22,
                  justifyContent: "flex-end",
                }}
              >
                {STRINGS.cancellation_charge + "\xa0\xa0"}
                {isChargeCancelFee
                  ? LOGIC.currencyFormatter(0)
                  : LOGIC.currencyFormatter(dataBooking?.feeDebt || 0)}
              </Row>
              <Button
                className="bottom-btn"
                onClick={chargeCancelFee}
                disabled={disabled || isChargeCancelFee}
                style={{
                  width: 160,
                  justifyContent: "center",
                  backgroundColor:
                    disabled || isChargeCancelFee ? COLORS.disabled : "red",
                }}
              >
                <Row
                  style={{
                    fontSize: 24,
                    fontWeight: "bold",
                  }}
                >
                  {isChargeCancelFee ? STRINGS.payment_3 : STRINGS.payment_2}
                </Row>
              </Button>
            </Col>
            <Row
              style={{
                justifyContent: "end",
                paddingTop: 16,
                marginRight: 6,
                paddingRight: 35,
              }}
            >
              <Button
                className="btn-payment"
                onClick={handleClickUpdate}
                style={{
                  backgroundColor: COLORS.primary_2,
                }}
              >
                <Row style={{ fontSize: 25, color: COLORS.background }}>
                  {STRINGS.sure}
                </Row>
              </Button>
            </Row>
          </Col>
        </Row>
        <Col>
          <ModalBookingDetailTableCombo
            form={form}
            data={data}
            originalTotal={data.price}
            setTotal={setTotal}
            disabledForm={
              // !!actionBookingType ||
              data.paid
              // || statusBooking === "done"
            }
            disabledClick={disabled}
          />
          <ModalBookingDetailPayment
            disabled={disabled}
            total={total}
            disablePay={disablePay}
            isChargeCancelFee={isChargeCancelFee}
            onAction={onAction}
            form={form}
            data={data}
            statusBooking={statusBooking}
            dataBooking={dataBooking}
            onUpdateBooking={onUpdateBooking}
          />
          <ModalBookingDetailComment form={form} />
        </Col>
      </Col>

      <ModalBookingDetailTableRight
        userId={data.userId}
        NG={NG}
        setNG={setNG}
        data={data}
        disabled={data.paid}
        refetch={refetch}
        customerId={customerId}
      />
      {(loading ||
        isLoading ||
        isLoadingStoreSurcharge ||
        isLoadingStatusBooking) && <LoadingAbsolute />}
    </Row>
  );
});
export default ModalBookingDetail;
