/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import { Row } from "antd";
import {
  TimelineViews,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
} from "@syncfusion/ej2-react-schedule";
import moment from "moment";

import { CommonFunction } from "../../../util/CommonFunction";
import { ModalCustomService } from "../../../elements";
import { COLORS, STRINGS } from "../../../constants";
import ResourceHeaderTemplate from "../items/ResourceHeaderTemplate";
import ModalBookingDetail from "../../staff-schedule/items/ModalBookingDetail";
import { ModalDeleteRestTime } from "./ModalDeleteRestTime";

function Schedule({
  calendarOrder,
  refetchCalendarOrder,
  scheduleObj,
  selectedDate,
}) {
  const [loading, setLoading] = useState(true);

  const { ownerData, datas } = CommonFunction.generateDataSchedule(
    calendarOrder,
    refetchCalendarOrder
  );

  useEffect(() => {
    if (!loading && calendarOrder?.staffOrderDTOS?.length) {
      scheduleObj.current.scrollTo(
        moment().subtract(20, "minutes").format("HH:mm")
      );
    }
  }, [loading]);

  const onCellClick = (args) => {
    args.cancel = true;
    if (args.event.title) {
      ModalCustomService.set({
        title: STRINGS.detail_reservation,
        titleStyle: {
          fontSize: 25,
        },
        wrapperStyle: { width: "auto" },
        contentWrapperStyle: { width: "fit-content" },
        contentStyle: { maxHeight: "80vh", padding: 0 },

        content: (
          <ModalBookingDetail disabled data={args.event.bookingDetail} />
        ),
      });
    }
    if (args.event.id.match("REST_TIME")) {
      const startTime = moment(args.event.startTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      ModalCustomService.set({
        title: STRINGS.confirm,
        titleStyle: {
          fontSize: 25,
        },
        wrapperStyle: { width: "auto" },
        contentWrapperStyle: { width: "fit-content" },
        contentStyle: { maxHeight: "80vh", padding: 0 },
        content: (
          <ModalDeleteRestTime
            startTime={startTime}
            shiftId={args.event.shiftId}
            refetchCalendarOrder={refetchCalendarOrder}
          />
        ),
      });
    }
    // let check = false;

    // if (ownerData[args.groupIndex]) {
    //   if (Array.isArray(ownerData[args.groupIndex]?.workShiftDTOS)) {
    //     check = ownerData[args.groupIndex].workShiftDTOS.some((item, index, arr) => {
    //       const { startTime, endTime } = item;
    //       if (
    //         !moment(args.startTime).isBetween(
    //           moment(startTime),
    //           moment(endTime),
    //           null,
    //           "()"
    //         ) &&
    //         !moment(args.endTime).isBetween(
    //           moment(startTime),
    //           moment(endTime),
    //           null,
    //           "()"
    //         ) &&
    //         !moment(args.startTime).isBetween(
    //           moment(arr[index ? index - 1 : 0].startTime),
    //           moment(arr[index ? index - 1 : 0].endTime),
    //           null,
    //           "()"
    //         ) &&
    //         !moment(args.endTime).isBetween(
    //           moment(arr[index ? index - 1 : 0].startTime),
    //           moment(arr[index ? index - 1 : 0].endTime),
    //           null,
    //           "()"
    //         ) &&
    //         moment(args.startTime).isAfter(moment())
    //       ) {
    //         return true;
    //       }
    //     });
    //   }
    // }

    // if (check) {
    //   ModalCustomService.set({
    //     title: STRINGS.add_working_hour,
    //     wrapperStyle: { width: "auto" },
    //     contentWrapperStyle: { width: "fit-content" },
    //     contentStyle: { maxHeight: "80vh", padding: 0 },
    //     content: (
    //       <ModalAddWorkingTime
    //         day={selectedDate}
    //         staffInfo={ownerData[args.groupIndex]}
    //         refetchCalendarOrder={refetchCalendarOrder}
    //         range={{ startTime: args.startTime, endTime: args.endTime }}
    //       />
    //     ),
    //   });
    // }
  };

  let height = 0;
  if (120 * ownerData.length > window.innerHeight * 0.7) {
    height = window.innerHeight * 0.7;
  } else {
    height = 120 * ownerData.length + 90;
  }

  const storeOpenTime = calendarOrder?.openTimeStore;
  const storeCloseTime = calendarOrder?.closeTimeStore;

  const isBetween24h =
    moment(storeCloseTime).isBefore(moment(storeOpenTime)) ||
    moment(storeCloseTime).format("HH:mm") == "00:00";

  return (
    <ScheduleComponent
      immediateRender={false}
      cssClass={"schedule-cell-dimension"}
      timeScale={{
        enable: true,
        interval: 60,
        slotCount: 6,
        majorSlotTemplate: (e) => {
          const time = moment(e.date).format("HH");
          return time > 9 ? time : "\xa0" + time.substring(1, 2);
        },
        minorSlotTemplate: (e) => (
          <div
            style={{
              paddingBottom: moment(e.date).format("mm") == 30 ? 3 : 7,
            }}
          >
            {"\xa0\xa0\xa0"}
          </div>
        ),
      }}
      eventClick={onCellClick}
      cellClick={onCellClick}
      popupOpen={(args) => (args.cancel = true)}
      startHour={
        isBetween24h
          ? moment(storeOpenTime).subtract(1, "hour").format("HH:00") || "8:00"
          : "0:00"
      }
      endHour={"24:00"}
      ref={scheduleObj}
      showHeaderBar={false}
      headerIndentTemplate={() => (
        <Row
          style={{
            marginLeft: 20,
            height: 60,
            alignItems: "center",
            fontWeight: "bold",
            fontSize: 22,
            color: COLORS.sub_accent,
          }}
        >
          {STRINGS.staff_name_2}
        </Row>
      )}
      resourceHeaderTemplate={(e) => (
        <ResourceHeaderTemplate
          resourceData={e.resourceData}
          refetchCalendarOrder={refetchCalendarOrder}
          selectedDate={selectedDate}
        />
      )}
      timeFormat={"HH:mm"}
      eventRendered={CommonFunction.renderEventCalendar}
      height={height}
      width="100%"
      selectedDate={selectedDate}
      created={() => {
        setLoading(false);
      }}
      // rowAutoHeight={true}
      eventSettings={{
        enableMaxHeight: true,
        dataSource: datas,
        fields: {
          id: "Id",
          subject: { title: "title", name: "title" },
          startTime: { name: "startTime" },
          endTime: { name: "endTime" },
        },
      }}
      group={{ enableCompactView: false, resources: ["Order"] }}
    >
      <ResourcesDirective>
        <ResourceDirective
          field="staffId"
          title="Order"
          name="Order"
          allowMultiple={true}
          dataSource={ownerData}
          textField="staffName"
          idField="staffId"
          colorField="color"
          startHourField="startTime"
          endHourField="endTime"
        />
      </ResourcesDirective>
      <ViewsDirective>
        <ViewDirective option="TimelineDay" interval={isBetween24h ? 1 : 2} />
      </ViewsDirective>
      <Inject services={[TimelineViews]} />
    </ScheduleComponent>
  );
}

export default memo(Schedule);
