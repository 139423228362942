/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useRef, useState } from "react";
import {
  TimelineViews,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
} from "@syncfusion/ej2-react-schedule";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { CommonFunction } from "../../../util/CommonFunction";
import { ModalCustomService } from "../../../elements";
import { COLORS, STRINGS } from "../../../constants";
import { HeaderStaffScheduleService } from "../../../services/HeaderStaffScheduleService";
import { ModalSpeechRecog } from "../items/ModalSpeechRecog";
import { ScheduleBookingService } from "../../../services";
import ModalBookingDetail from "../items/ModalBookingDetail";
import ModalSearchBooking from "../items/ModalSearchBooking";
import ResourceHeaderTemplate from "../items/ResourceHeaderTemplate";
function Schedule({
  calendarOrder,
  refetchCalendarOrder,
  selectedDate,
  dataFiltered,
  isFetchingHeader,
}) {
  const scheduleObj = useRef();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const { ownerData, datas } = CommonFunction.generateDataSchedule(
    calendarOrder,
    refetchCalendarOrder
  );

  useEffect(() => {
    const onAbleModalSearch = () => {
      ModalCustomService.set({
        title: STRINGS.search_booking,
        wrapperStyle: { width: "auto" },
        contentWrapperStyle: { width: "fit-content" },
        contentStyle: { maxHeight: "80vh", padding: 0 },
        content: (
          <ModalSearchBooking
            refetchCalendarOrder={refetchCalendarOrder}
            selectedDate={selectedDate}
          />
        ),
      });
    };
    const onAbleModalSpeechRecog = () => {
      ModalCustomService.set({
        title: STRINGS.search_booking,
        wrapperStyle: { width: "auto" },
        contentWrapperStyle: { width: "fit-content" },
        contentStyle: { maxHeight: "80vh", padding: 0 },
        content: <ModalSpeechRecog navigate={navigate} />,
      });
    };
    const onCellScale = (type) => () => {
      if (type === "zoomIn") {
        scheduleObj.current.timeScale.slotCount = 6;
        scheduleObj.current.cssClass = "schedule-cell-dimension";
      }
      if (type === "zoomOut") {
        scheduleObj.current.timeScale.slotCount = 0;
        scheduleObj.current.timeScale.slotCount = 2;
        scheduleObj.current.cssClass = "";
      }
      if (type === "ipad") {
        scheduleObj.current.cssClass = "schedule-cell-dimension-2";
        scheduleObj.current.timeScale.slotCount = 0;
        scheduleObj.current.timeScale.slotCount = 2;
      }
    };
    HeaderStaffScheduleService.onChange("StaffSchedule", (functionName) => {
      if (
        scheduleObj.current &&
        functionName !== "onAbleModalSearch" &&
        functionName !== "onAbleModalSpeechRecog"
      ) {
        onCellScale(functionName)();
      }
      if (functionName == "onAbleModalSearch") {
        onAbleModalSearch();
      }
      if (functionName == "onAbleModalSpeechRecog") {
        onAbleModalSpeechRecog();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCellClick = (args) => {
    args.cancel = true;
    if (args.event.title) {
      ScheduleBookingService.set({
        bookingDetail: args.event.bookingDetail,
        refetchCalendarOrder: refetchCalendarOrder,
      });

      ModalCustomService.set({
        title: STRINGS.detail_reservation,
        titleStyle: {
          fontSize: 25,
        },
        wrapperStyle: { width: "auto" },
        contentWrapperStyle: { width: "fit-content" },
        contentStyle: { maxHeight: "80vh", padding: 0 },
        content: (
          <ModalBookingDetail
            refetch={refetchCalendarOrder}
            data={args.event.bookingDetail}
          />
        ),
      });
    }
  };
  useEffect(() => {
    if (!loading && calendarOrder?.staffOrderDTOS?.length) {
      scheduleObj.current.scrollTo(
        moment().subtract(20, "minutes").format("HH:mm")
      );
    }
    return () => {
      ScheduleBookingService.remove();
    };
  }, [loading]);
  let height = 0;
  if (80 * ownerData.length > window.innerHeight * 0.8) {
    height = window.innerHeight * 0.8;
  } else {
    height = 80 * ownerData.length + 90;
  }

  const storeOpenTime = calendarOrder?.openTimeStore;
  const storeCloseTime = calendarOrder?.closeTimeStore;

  const isBetween24h =
    moment(storeCloseTime).isBefore(moment(storeOpenTime)) ||
    moment(storeCloseTime).format("HH:mm") == "00:00";

  return (
    <ScheduleComponent
      immediateRender={false}
      cssClass={"schedule-cell-dimension"}
      timeScale={{
        enable: true,
        interval: 60,
        slotCount: 6,
        majorSlotTemplate: (e) => {
          const time = moment(e.date).format("HH");
          return time > 9 ? time : "\xa0" + time.substring(1, 2);
        },
        minorSlotTemplate: (e) => (
          <div
            style={{
              paddingBottom: moment(e.date).format("mm") == 30 ? 3 : 7,
            }}
          >
            {"\xa0\xa0\xa0"}
          </div>
        ),
      }}
      cellClick={onCellClick}
      eventClick={onCellClick}
      popupOpen={(args) => (args.cancel = true)}
      startHour={
        isBetween24h
          ? moment(storeOpenTime).subtract(1, "hour").format("HH:00") || "8:00"
          : "0:00"
      }
      endHour={"24:00"}
      ref={scheduleObj}
      showHeaderBar={false}
      headerIndentTemplate={() => (
        <Row
          style={{
            marginLeft: 20,
            height: 60,
            alignItems: "center",
            fontWeight: "bold",
            fontSize: 22,
            color: COLORS.sub_accent,
          }}
        >
          {STRINGS.staff_name_2}
        </Row>
      )}
      resourceHeaderTemplate={(e) => (
        <ResourceHeaderTemplate
          selectedDate={selectedDate}
          resourceData={e.resourceData}
          refetchCalendarOrder={refetchCalendarOrder}
          calendarOrder={calendarOrder}
        />
      )}
      created={() => {
        setLoading(false);
      }}
      timeFormat={"HH:mm"}
      eventRendered={CommonFunction.renderEventCalendar}
      height={height}
      width="100%"
      selectedDate={selectedDate}
      // rowAutoHeight={true}
      eventSettings={{
        enableMaxHeight: true,
        dataSource: datas,
        fields: {
          id: "Id",
          subject: { title: "title", name: "title" },
          startTime: { name: "startTime" },
          endTime: { name: "endTime" },
        },
      }}
      group={{ enableCompactView: false, resources: ["Order"] }}
    >
      <ResourcesDirective>
        <ResourceDirective
          field="staffId"
          title="Order"
          name="Order"
          allowMultiple={true}
          dataSource={ownerData}
          textField="staffName"
          idField="staffId"
          colorField="color"
          startHourField="startTime"
          endHourField="endTime"
        />
      </ResourcesDirective>
      <ViewsDirective>
        <ViewDirective option="TimelineDay" interval={isBetween24h ? 1 : 2} />
      </ViewsDirective>
      <Inject services={[TimelineViews]} />
    </ScheduleComponent>
  );
}

export default memo(Schedule);
