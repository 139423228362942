import React, { useState } from "react";
import { Col, DatePicker, Form, Input, Radio, Row, message } from "antd";
import moment from "moment";

import { FetchApi } from "../../../api";
import { STRINGS, LOGIC, Svgs } from "../../../constants";
import {
  ButtonCustom,
  ImageUpload,
  ModalCustomService,
  LoadingAbsolute,
  Validate,
} from "../../../elements";
import { CommonFunction } from "../../../util/CommonFunction";
import Quill from "../../../elements/Quill";
import "../ManagerCM.scss";

const Edit = ({ item, data, setData }) => {
  const [action, setAction] = useState(item.type);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onEdit = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      if (new Date(fieldData.startTime) >= new Date(fieldData.endTime)) {
        message.warning(STRINGS.endtime_greater_than_starttime);
        return;
      }
      fieldData.id = item.id;
      setLoading(true);
      const result = await FetchApi.updateCommercial(fieldData);
      setLoading(false);
      if (result.success) {
        const index = data.commercialDTOS.findIndex(
          (itm) => itm.id === item.id
        );
        const clone = JSON.parse(JSON.stringify({ ...data }));
        clone.commercialDTOS[index] = result.commercialDTO;
        setData(clone.commercialDTOS);
        ModalCustomService.close();
        message.success(STRINGS.registered_common);
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  const ValidateQuill = ({ getFieldValue }) => ({
    validator(rule, value) {
      const regex = /^\s+$/;
      if (
        value === undefined ||
        value === null ||
        value.length === 0 ||
        regex.test(value)
      ) {
        return Promise.reject(STRINGS.this_field_is_required);
      } else {
        return Promise.resolve();
      }
    },
  });

  return (
    <Col className="cm-setting-form">
      <Form layout="vertical" form={form}>
        <Row style={{ flexWrap: "wrap" }} gutter={20}>
          <Col xl={16}>
            <Form.Item
              style={{ marginBottom: 10, paddingRight: 20 }}
              name="imageUrl"
              label={STRINGS.image_required}
              required
              rules={[Validate.emptyContent]}
              initialValue={item.imageUrl}>
              <ImageUpload aspect={3 / 2} />
            </Form.Item>
          </Col>
          <Col style={{ marginTop: 20 }} xl={8}>
            <Form.Item
              name="startTime"
              label={STRINGS.delivery_start_time}
              initialValue={item.startTime ? moment(item.startTime) : ""}
              rules={[Validate.emptyContent]}
              required>
              <DatePicker
                style={{ width: "100%" }}
                placeholder={STRINGS.delivery_start_time}
                suffixIcon={Svgs.datepicker()}
                format={LOGIC.timeFormat}
                showTime
              />
            </Form.Item>
            <Form.Item
              name="endTime"
              label={STRINGS.delivery_end_time}
              initialValue={item.endTime ? moment(item.endTime) : ""}
              rules={[Validate.emptyContent]}
              required>
              <DatePicker
                style={{ width: "100%" }}
                placeholder={STRINGS.delivery_end_time}
                suffixIcon={Svgs.datepicker()}
                format={LOGIC.timeFormat}
                showTime
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={STRINGS.behavior_click}
          name="type"
          initialValue={item.type}>
          <Radio.Group
            onChange={(e) => {
              setAction(e.target.value);
            }}>
            <Radio value={"DO_NOTHING"}>{STRINGS.do_nothing}</Radio>
            <Radio value={"DETAIL"}>{STRINGS.detailed_screen_display}</Radio>
            <Radio value={"WEBVIEW"}>{STRINGS.display_web}</Radio>
          </Radio.Group>
        </Form.Item>
        {action === "DETAIL" && (
          <Form.Item
            className="form-action-quill"
            label={STRINGS.description}
            name="description"
            rules={[ValidateQuill]}
            initialValue={item.content}
            required>
            <Quill className="quill-slide" />
          </Form.Item>
        )}
        {action === "WEBVIEW" && (
          <Form.Item
            className="form-action"
            label={"URL"}
            name="link"
            rules={[Validate.isUrlWithRequired]}
            initialValue={item.link}>
            <Input />
          </Form.Item>
        )}
      </Form>
      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onEdit}>{STRINGS.update}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
};

export default Edit;
