import React, { useState } from "react";
import { message, Space, Table } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import moment from "moment";

import { FetchApi } from "../../api";
import { STRINGS, LOGIC, Svgs } from "../../constants";
import {
  Container,
  ModalCustomService,
  LoadingView,
  ErrorView,
  Convert,
  SvgView,
  AlertService,
  ButtonCustom,
} from "../../elements";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import { Delete } from "./items/Delete";
import Add from "./items/Add";
import Edit from "./items/Edit";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";

import "../../util/variables.scss";
import Auth from "../../store/Authentication";
import { CommonFunction } from "../../util/CommonFunction";

const StaffNotGood = () => {
  const [pagination, setPagination] = useState({ size: 10, page: 1 });
  const { data, refetch, isFetching, isLoading } = useQuery(
    [`StaffNotGood-${pagination.size}-${pagination.page}`],
    () =>
      FetchApi.getListNG({
        page: pagination.page,
        size: pagination.size,
        staffId: "",
      })
  );
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  useScrollByMouse(data);

  const columns = [
    {
      title: STRINGS.customer_name,
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      width: "10%",
      render: (e) => Convert.removeSpaceName(e),
    },
    {
      title: STRINGS.furigana_name,
      dataIndex: "furiganaName",
      key: "furiganaName",
      align: "center",
      width: "10%",
      render: (e) => Convert.removeSpaceName(e),
    },
    {
      title: STRINGS.phone_number_2,
      dataIndex: "customerPhone",
      key: "customerPhone",
      align: "center",
      width: "10%",
    },
    {
      title: STRINGS.staff_name_3,
      dataIndex: "staffName",
      key: "staffName",
      align: "center",
      width: "10%",
    },
    {
      title: STRINGS.day_ordered,
      dataIndex: "bookedDate",
      key: "bookedDate",
      align: "center",
      width: "15%",
      render: (e) => moment(e).format(LOGIC.timeFormat),
    },
    {
      title: STRINGS.day_assigned,
      dataIndex: "assignedDate",
      key: "assignedDate",
      align: "center",
      width: "15%",
      render: (e) => moment(e).format(LOGIC.timeFormat),
    },
    {
      title: STRINGS.staff_assigned,
      dataIndex: "nameOfWhoCreatedNG",
      key: "nameOfWhoCreatedNG",
      align: "center",
      width: "10%",
    },
    {
      title: STRINGS.reason,
      align: "center",
      dataIndex: "feedback",
      key: "feedback",
      render: (e) => (
        <div
          dangerouslySetInnerHTML={{
            __html: e,
          }}
        />
      ),
      width: "40%",
    },
    {
      key: "action",
      render: (text, record) => {
        if (userAdmin.type !== "STORE_ADMIN") {
          return;
        }
        return (
          <Space size={5}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onEdit(record)}
            >
              <SvgView svgData={Svgs.book} />
            </button>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={removeItem(record)}
            >
              <SvgView svgData={Svgs.delete} />
            </button>
          </Space>
        );
      },
      width: 70,
    },
  ];

  const onAdd = () => {
    ModalCustomService.set({
      title: STRINGS.add_new,
      wrapperStyle: { maxWidth: 600 },
      content: <Add refetch={refetch} />,
    });
  };
  const onEdit = (record) => () => {
    ModalCustomService.set({
      title: STRINGS.edit_not_good,
      wrapperStyle: { maxWidth: 600 },
      content: <Edit item={record} data={data} refetch={refetch} />,
    });
  };

  const removeItem = (item) => async () => {
    if (userAdmin.type === "STORE_ADMIN") {
      AlertService.set({
        title: STRINGS.confirm,
        textConfirm: STRINGS.delete,
        content: `${STRINGS.confirm_delete_common}`,
        onConfirm: async () => {
          const result = await FetchApi.deleteNG({
            id: item.id,
          });
          if (result.success) {
            ModalCustomService.close();
            refetch();
            message.success(STRINGS.deleted_common);
          } else if (result.code == 4003) {
            message.warning(STRINGS.password_is_incorrect);
          } else {
            CommonFunction.logError(result);
          }
        },
      });

      return;
    }
    ModalCustomService.set({
      title: STRINGS.confirm_delete_common,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: <Delete refetch={refetch} item={item} />,
    });
  };

  const renderRight = () => {
    if (userAdmin.type === "RECEPTIONIST") {
      return;
    }
    return (
      <ButtonCustom onClick={onAdd} icon={<PlusCircleOutlined />}>
        {STRINGS.add_new}
      </ButtonCustom>
    );
  };

  if (isLoading) {
    return <LoadingView />;
  }

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }
  console.log("----", userAdmin.type);
  return (
    <Container renderRight={renderRight}>
      <Table
        loading={isFetching || isLoading}
        rowKey={"id"}
        columns={columns}
        dataSource={data?.notGoodDTOS || []}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
        scroll={{ x: "auto", y: "calc(100vh - 350px)" }}
      />
      <PaginationWithSizeChange
        size={pagination.size}
        total={data?.pageDto?.totalElements || 0}
        onChange={(pageNumber) => {
          setPagination({ size: pagination.size, page: pageNumber });
        }}
        onShowSizeChange={(e) => {
          setPagination({ size: e.size, page: 1 });
        }}
        current={pagination.page}
      />
    </Container>
  );
};

export default StaffNotGood;
