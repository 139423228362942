import React, { useState } from "react";
import { Col, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import {
  ButtonCustom,
  ErrorView,
  LoadingView,
  ModalCustomService,
} from "../../../elements";
import { COLORS, STRINGS } from "../../../constants";
import { useQueryListStaff } from "../modules";
import Auth from "../../../store/Authentication";

import "../StaffBooking.scss";

function SelectWorkerItem({
  data,
  form,
  numberOrdinal,
  staffSelected,
  setStaffSelected,
}) {
  const renderSelect = (item) => {
    if (data.staffId === staffSelected?.staffId) {
      return (
        <div
          style={{
            width: "18px",
            height: "18px",
            borderRadius: "9px",
            backgroundColor: COLORS.success,
          }}
        />
      );
    }
  };

  let backgroundColor = COLORS.background;

  for (let i = 1; i < 5; i++) {
    if (
      form.getFieldValue(`bookingSelectStaff${i}`)?.staffId === data.staffId &&
      i !== numberOrdinal &&
      data.name !== STRINGS.female &&
      data.name !== STRINGS.male
    ) {
      backgroundColor = COLORS.disabled;
    }
  }
  return (
    <Row
      style={{
        paddingBottom: "20px",
        width: "100%",
        cursor: "pointer",
      }}
      onClick={() => {
        if (backgroundColor === COLORS.disabled) {
          return;
        }
        if (data.staffId === staffSelected?.staffId) {
          setStaffSelected();
          return;
        }
        setStaffSelected(data);
      }}>
      <Col xl={4}>
        <div
          style={{
            width: "24px",
            height: "24px",
            borderRadius: "12px",
            border: `1px solid ${COLORS.border}`,
            display: "flex",
            placeContent: "center",
            alignItems: "center",
            marginRight: "10px",
            backgroundColor,
          }}>
          {renderSelect(data.staffId)}
        </div>
      </Col>
      {/* <img
        alt=""
        src={src}
        style={{ width: 36, height: 36, margin: "0px 10px" }}
      /> */}
      <Col xl={20}>
        <Row
          style={{
            fontSize: 14,
            color: COLORS.border_7,
            wordBreak: "break-all",
          }}>
          {data.name}
          {data.designationFee
            ? `(+${data.designationFee}${STRINGS.yen})`
            : `(${STRINGS.free})`}
        </Row>
      </Col>
    </Row>
  );
}

function StaffBookingModalSelectWorker({
  numberOrdinal,
  listId,
  desiredTime,
  form,
}) {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const storeId = userAdmin.storeDTO.id;
  const [staffSelected, setStaffSelected] = useState(
    form.getFieldValue(`bookingSelectStaff${numberOrdinal}`)
  );
  const { data, isLoading } = useQueryListStaff({
    desiredTime: desiredTime,
    storeId: storeId,
    comboPackIdsRequests: [{ comboPackIds: listId, index: numberOrdinal - 1 }],
    numberOrdinal: numberOrdinal,
  });

  const renderContent = () => {
    if (isLoading) {
      return <LoadingView />;
    }
    if (data && !data.success) {
      return <ErrorView data={data} />;
    }

    // let dataList =
    //   data?.mapStaffBySexGroupByIndex &&
    //   data?.mapStaffBySexGroupByIndex[numberOrdinal - 1] &&
    //   Array.isArray(data?.mapStaffBySexGroupByIndex[numberOrdinal - 1].male)
    //     ? data?.mapStaffBySexGroupByIndex[numberOrdinal - 1].male
    //     : []; //male
    // if (gender === Gender.female) {
    //   dataList =
    //     data?.mapStaffBySexGroupByIndex[numberOrdinal - 1].female || []; //female
    // }
    // let dataListMale = [];
    // let dataListFemale = [];
    let dataList = [];
    if (
      data?.mapStaffBySexGroupByIndex &&
      data?.mapStaffBySexGroupByIndex[numberOrdinal - 1] &&
      Array.isArray(data?.mapStaffBySexGroupByIndex[numberOrdinal - 1].male) &&
      Array.isArray(data?.mapStaffBySexGroupByIndex[numberOrdinal - 1].female)
    ) {
      // dataListMale = [
      //   { name: STRINGS.staff_gender.male, sex: "male", staffId: "male" },
      //   ...data?.mapStaffBySexGroupByIndex[numberOrdinal - 1].male,
      // ];
      // dataListFemale = [
      //   { name: STRINGS.staff_gender.female, sex: "female", staffId: "female" },
      //   ...data?.mapStaffBySexGroupByIndex[numberOrdinal - 1].female,
      // ];
      dataList = [
        {
          name: STRINGS.staff_gender.male,
          sex: "male",
          staffId: "male",
          designationFee: 0,
        },
        {
          name: STRINGS.staff_gender.female,
          sex: "female",
          staffId: "female",
          designationFee: 0,
        },
        ...data?.mapStaffBySexGroupByIndex[numberOrdinal - 1].male,
        ...data?.mapStaffBySexGroupByIndex[numberOrdinal - 1].female,
      ];
    }
    return (
      <Col>
        <Row
          style={{
            padding: "20px",
            borderBottom: `1px solid ${COLORS.border}`,
          }}>
          {/* <Col style={{ padding: "20px" }}> */}
          {dataList.map((item, index) => {
            return (
              <Col key={`${index}`} xl={12} xs={12}>
                <SelectWorkerItem
                  data={item}
                  form={form}
                  numberOrdinal={numberOrdinal}
                  setStaffSelected={setStaffSelected}
                  staffSelected={staffSelected}
                />
              </Col>
            );
          })}
          {/* </Col> */}
          {/* <Col style={{ padding: "20px" }}> */}
          {/* {dataListFemale.map((item, index) => {
              return (
                <SelectWorkerItem
                  key={`${index}`}
                  data={item}
                  form={form}
                  numberOrdinal={numberOrdinal}
                  setStaffSelected={setStaffSelected}
                  staffSelected={staffSelected}
                />
              );
            })} */}
          {/* </Col> */}
          {/* {listGender.map((i) => {
            let backgroundColor = COLORS.background;
            let textColor = COLORS.text;
            if (gender === i.sex) {
              backgroundColor = COLORS.primary;
              textColor = COLORS.background;
            }
            return (
              <Col
                key={i.sex}
                onClick={() => setGender(i.sex)}
                style={{
                  border: `1px solid ${COLORS.border}`,
                  backgroundColor,
                  padding: "10px 20px",
                  borderRadius: "5px",
                  marginRight: "10px",
                  color: textColor,
                }}>
                <Row>
                  <div
                    style={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "12px",
                      border: `1px solid ${COLORS.border}`,
                      backgroundColor: COLORS.background,
                      display: "flex",
                      placeContent: "center",
                      alignItems: "center",
                      marginRight: "10px",
                    }}>
                    {renderSelect(i.sex)}
                  </div>
                  {STRINGS.staff_gender[i.sex]}
                </Row>
              </Col>
            );
          })} */}
        </Row>
        <Row justify="center" style={{ margin: "20px 0px" }}>
          <ButtonCustom
            onClick={ModalCustomService.close}
            isCancelButton
            style={{
              marginRight: 10,
            }}>
            {STRINGS.cancel}
          </ButtonCustom>
          <ButtonCustom
            onClick={() => {
              form.setFieldsValue({
                [`bookingSelectStaff${numberOrdinal}`]: staffSelected,
              });
              ModalCustomService.close();
            }}>
            {STRINGS.ok}
          </ButtonCustom>
        </Row>
        {/* <SelectWorkerList
          list={dataList}
          form={form}
          numberOrdinal={numberOrdinal}
        /> */}
      </Col>
    );
  };

  return (
    <Col className="checkin-time-wrapper">
      <Row className="title-modal">
        <Row style={{ color: "white", fontSize: 16 }}>
          {STRINGS.please_select_staff}
        </Row>
        <button onClick={ModalCustomService.close}>
          <CloseOutlined style={{ fontSize: "16px", color: "#fff" }} />
        </button>
      </Row>
      {renderContent()}
    </Col>
  );
}

export { StaffBookingModalSelectWorker };
