import React, { useRef, useState } from "react";
import { Col, Row, Select, Input, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  ButtonCustom,
  LoadingAbsolute,
  ModalCustomService,
  SuffixIconDown,
} from "../../../elements";
import { STRINGS, COLORS } from "../../../constants";
import { FetchApi } from "../../../api";
import StaffConfigModal from "./StaffConfigModal/StaffConfigModal";

import "../StaffScheduleManual.scss";

const Option = Select.Option;

export default function ModalRequestRestMore({
  staffInfo,
  scheduleSelected,
  shiftStatus,
  refetchCalendarOrder,
  disabled,
  selectedDate,
}) {
  const [suggestBreak, setSuggestBreak] = useState();
  const list = [
    { value: 30, label: "30分" },
    { value: 40, label: "40分" },
    { value: 50, label: "50分" },
    { value: 60, label: "60分" },
    { value: 70, label: "70分" },
    { value: 80, label: "80分" },
    { value: 90, label: "90分" },
    { value: 100, label: "100分" },
    { value: 110, label: "110分" },
    { value: 120, label: "120分" },
  ];
  const filtered = (staffInfo.workShiftDTOS || []).filter(
    (itm) => itm.scheduleId === scheduleSelected
  );

  const totalRestMinuteExtra = filtered?.[0]?.totalRestMinuteExtra;

  const [loading, setLoading] = useState(false);
  const staffChangeRequest = useRef({});
  const newRestTime = useRef(totalRestMinuteExtra);

  const onChangeRestTime = async () => {
    // if (!staffChangeRequest.current.id) {
    //   message.warning(STRINGS.please_select_a_reception_staff);
    //   return;
    // }
    if (!staffChangeRequest.current.password) {
      message.warning(STRINGS.please_type_password);
      return;
    }
    if (!newRestTime.current) {
      message.warning(STRINGS.please_fill_required_fields);
      return;
    }
    try {
      setLoading(true);
      let result;
      if (suggestBreak?.start) {
        result = await FetchApi.requestExtraBreak({
          minutes: newRestTime.current,
          shiftId: scheduleSelected,
          staffChangeRequest: {
            ...staffChangeRequest.current,
          },
          staffId: staffInfo.staffId,
          type: "CREATE",
          start: moment(suggestBreak.start).format("YYYY-MM-DD HH:mm:ss"),
        });
      } else {
        result = await FetchApi.requestExtraBreak({
          minutes: newRestTime.current,
          shiftId: scheduleSelected,
          staffChangeRequest: {
            ...staffChangeRequest.current,
          },
          staffId: staffInfo.staffId,
          type: "SUGGEST",
        });
      }
      setLoading(false);
      if (result.code === 2000) {
        setSuggestBreak();
        refetchCalendarOrder();
        message.success(STRINGS.saved_common);
        ModalCustomService.close();
        return;
      }
      if ((result.code === 2005 || result.extraBreak) && !suggestBreak) {
        setSuggestBreak(result.extraBreak);
        return;
      }
      if (result.code === 4003 || result.code === 4103) {
        message.warning(STRINGS.password_is_incorrect);
        return;
      }
      if (result.code === 4005) {
        message.warning(STRINGS.cannot_rest_because_booking);
        return;
      }
      message.warning(STRINGS.cannot_rest);
      // CommonFunction.logError(result);
    } catch (error) {
      setLoading(false);
      message.error(STRINGS.something_wrong);
    }

    // try {
    //   setLoading(true);
    //   const result = await FetchApi.restTimeExtra({
    //     staffChangeRequest: {
    //       ...staffChangeRequest.current,
    //       type: "change_rest_minute",
    //     },
    //     scheduleId: scheduleSelected,
    //     restMinuteExtra: newRestTime.current,
    //   });
    //   setLoading(false);
    //   if (result.success) {
    //     refetchCalendarOrder();
    //     message.success(STRINGS.saved_common);
    //     ModalCustomService.close();
    //   } else if (result.code == 4005) {
    //     message.warning(STRINGS.cannot_rest_because_booking);
    //   } else if (result.code == 4003 || result.code == 4103) {
    //     message.warning(STRINGS.password_is_incorrect);
    //   } else {
    //     CommonFunction.logError(result);
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   message.error(STRINGS.something_wrong);
    // }
  };
  const onCancel = () => {
    ModalCustomService.set({
      wrapperStyle: { width: "auto", maxWidth: 1200 },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <StaffConfigModal
          data={staffInfo}
          shiftStatus={shiftStatus}
          refetchCalendarOrder={refetchCalendarOrder}
          disabled={disabled}
          selectedDate={selectedDate}
        />
      ),
    });
  };

  return (
    <Col>
      <Row className="title-modal">
        <Row style={{ color: "white", fontSize: 16 }}>{STRINGS.rest2}</Row>
        <button onClick={ModalCustomService.close}>
          <CloseOutlined
            className="close"
            style={{ fontSize: "16px", color: "#fff" }}
          />
        </button>
      </Row>

      <Col style={{ padding: 10, width: 500 }}>
        <Select
          onChange={(e) => (newRestTime.current = e)}
          defaultValue={totalRestMinuteExtra}
          style={{ textAlign: "left", width: 210, marginBottom: 10 }}
          suffixIcon={<SuffixIconDown />}>
          {list.map((item) => (
            <Option key={item.value}>{item.label}</Option>
          ))}
        </Select>

        <Row
          style={{
            color: "#4B2A05",
            fontSize: 18,
            paddingTop: 14,
            fontWeight: "bold",
            justifyContent: "center",
          }}>
          {STRINGS.password_for_this_staff}
        </Row>
        <Col
          style={{ padding: 10, cursor: "pointer", margin: "10px 30px" }}
          span={12}>
          <Row style={{ alignItems: "center" }}>
            <Row
              style={{
                border: "1px solid #497D37",
                width: 30,
                height: 30,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 15,
                marginRight: 12,
              }}>
              <Row
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 10,
                  backgroundColor: "#497D37",
                }}
              />
            </Row>

            <Row style={{ fontSize: 14, color: COLORS.border_7 }}>
              {staffInfo.staffName}
              <Col style={{ fontSize: 14, color: COLORS.required }}>*</Col>
            </Row>
          </Row>

          <Input.Password
            onChange={(e) => {
              staffChangeRequest.current = {
                password: e.target.value,
              };
            }}
          />
        </Col>
        {/* <Row
          style={{
            color: "#4B2A05",
            fontSize: 18,
            paddingTop: 14,
            fontWeight: "bold",
            justifyContent: "center",
          }}>
          {STRINGS.please_select_a_reception_staff}
        </Row>

        <RadioAllStoreStaff
          filterStaff
          onChange={(e) => (staffChangeRequest.current = e)}
        /> */}
        {!!suggestBreak && (
          <div>
            {STRINGS.staff_want_rest(
              moment(suggestBreak.start).format("HH:mm")
            )}
          </div>
        )}
        <Row justify="center" style={{ margin: "20px 0px" }}>
          <ButtonCustom
            onClick={onCancel}
            isCancelButton
            style={{
              marginRight: 10,
            }}>
            {STRINGS.cancel}
          </ButtonCustom>
          <ButtonCustom onClick={onChangeRestTime}>{STRINGS.sure}</ButtonCustom>
        </Row>
      </Col>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}
