import { useQuery } from "react-query";
import { FetchApi } from "../../api";

const tag = "GetComboList";

const useGetComboList = (obj) => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    [`useGetComboList-${obj?.refetcher}`],
    () => FetchApi.getComboList({ page: 1, size: 50 })
  );
  return {
    listCombo: data?.comboDTOS || [],
    [`refetch${tag}`]: refetch,
    [`isLoading${tag}`]: isLoading,
    [`isFetching${tag}`]: isFetching,
    tag: tag,
  };
};

export { useGetComboList };
