import React, { useState } from "react";

import { Container } from "../../elements/Container";
import { STRINGS } from "../../constants/Strings";
import { Tabs } from "antd";
import GrantPoints from "./items/GrantPoints";
import HistoryPoints from "./items/HistoryPoints";
import "../../util/variables.scss";
import { COLORS } from "../../constants";

const PointReception = () => {
  const [tab, setTab] = useState("grant_points");

  const statMenu = [
    {
      key: "grant_points",
      title: STRINGS.grant_points,
      component: <GrantPoints />,
      display: true,
    },
    {
      key: "history_points",
      title: STRINGS.history,
      component: <HistoryPoints />,
      display: true,
    },
  ];

  return (
    <Container>
      <div className="btn-choose-type">
        <Tabs activeKey={tab} onChange={setTab} type="card">
          {statMenu.map((item, index) => {
            if (!item.display) {
              return null;
            }
            return (
              <Tabs.TabPane
                tabKey={"" + item.key}
                key={"" + item.key}
                style={{ width: "100%" }}
                tab={
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "initial",
                      backgroundColor:
                        item.key === tab ? COLORS.accent : "white",
                      padding: "14px 0px",
                      textAlign: "center",
                    }}>
                    {item.title}
                  </div>
                }>
                {item.component}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>
    </Container>
  );
};

export default PointReception;
