import React, { useRef } from "react";
import { message, Space, Table, Image } from "antd";
import { PlusCircleOutlined, MenuOutlined } from "@ant-design/icons";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { useQueryClient } from "react-query";
import arrayMove from "array-move";

import { FetchApi } from "../../../../api";
import {
  AlertService,
  Container,
  LoadingView,
  ModalCustomService,
  ErrorView,
  SvgView,
  ButtonCustom,
} from "../../../../elements";
import { useScrollByMouse } from "../../../../util/hooks/useScrollByMouse";
import { CommonFunction } from "../../../../util/CommonFunction";
import { COLORS, STRINGS, LOGIC, Svgs } from "../../../../constants";
import { useGetAllCombo } from "./modules/useGetAllCombo";
import Add from "./items/Add";
import Edit from "./items/Edit";
import Auth from "../../../../store/Authentication";

import "../../Combo.scss";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: COLORS.border_5 }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const Combo = () => {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const arrayPosition = useRef();
  const queryClient = useQueryClient();
  const { data, refetch, isLoading } = useGetAllCombo();
  useScrollByMouse(data);

  const columnsWrapper = [
    {
      title: STRINGS.sort,
      dataIndex: "id",
      key: "id",
      render: (id, o) =>
        o.storeId == 0 && userAdmin.type === "STORE_ADMIN" ? null : (
          <DragHandle />
        ),
      width: 80,
      fixed: "left",
      align: "center",
    },
    {
      title: STRINGS.category_title,
      dataIndex: "name",
      key: "name",
      width: 180,
    },
    {
      title: STRINGS.category_title_short,
      dataIndex: "shortName",
      key: "shortName",
      width: 160,
    },
    {
      title: STRINGS.description_2,
      dataIndex: "description",
      key: "description",
      width: 300,
    },
    {
      title: STRINGS.image,
      dataIndex: "imageUrl",
      key: "imageUrl",
      render: (e) => (
        <Image style={{ margin: "10px 0px", width: 200 }} src={e} />
      ),
      width: 220,
    },
    {
      dataIndex: "comboPackDTOS",
      key: "comboPackDTOS",
      render: (comboPackDTOS) => (
        <Table
          style={{ margin: "10px 0px" }}
          rowKey={"id"}
          columns={columns}
          dataSource={comboPackDTOS || []}
          locale={{ emptyText: STRINGS.no_data }}
          pagination={false}
        />
      ),
      width: 650,
    },
    {
      width: 100,
      fixed: "right",
      render: (e, item) => (
        <Space size={10}>
          <button
            style={{
              border: 0,
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            onClick={onEdit(item)}>
            <SvgView svgData={Svgs.edit} />
          </button>
          {item.storeId == 0 && userAdmin.type === "STORE_ADMIN" ? null : (
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={removeItem(item)}>
              <SvgView svgData={Svgs.delete} />
            </button>
          )}
        </Space>
      ),
    },
  ];
  const columns = [
    {
      title: STRINGS.time,
      dataIndex: "minutes",
      key: "minutes",
      align: "center",
      render: (e) => (
        <div style={{ color: COLORS.sub_accent, fontWeight: "bold" }}>
          {e} 分
        </div>
      ),
    },
    {
      title: STRINGS.app_member,
      dataIndex: "priceMemberApp",
      key: "priceMemberApp",
      align: "center",
      render: (e) => (
        <div style={{ color: COLORS.sub_accent, fontWeight: "bold" }}>
          {LOGIC.currencyFormatter(e)}
        </div>
      ),
    },
    {
      title: STRINGS.app_non_member,
      dataIndex: "priceNonApp",
      key: "priceNonApp",
      align: "center",
      render: (e) => (
        <div style={{ color: COLORS.sub_accent, fontWeight: "bold" }}>
          {LOGIC.currencyFormatter(e)}
        </div>
      ),
    },
    {
      title: STRINGS.list_price,
      dataIndex: "priceDefault",
      key: "priceDefault",
      align: "center",
      render: (e) => (
        <div style={{ color: COLORS.sub_accent, fontWeight: "bold" }}>
          {LOGIC.currencyFormatter(e)}
        </div>
      ),
    },
    {
      title: STRINGS.price_job,
      dataIndex: "staffWage",
      key: "staffWage",
      align: "center",
      render: (e) => (
        <div style={{ color: COLORS.sub_accent, fontWeight: "bold" }}>
          {LOGIC.currencyFormatter(e)}
        </div>
      ),
    },
    {
      title: STRINGS.price_tax,
      dataIndex: "tax",
      key: "tax",
      align: "center",
      render: (e) => (
        <div style={{ color: COLORS.sub_accent, fontWeight: "bold" }}>
          {LOGIC.currencyFormatter(e)}
        </div>
      ),
    },
    {
      title: STRINGS.grant_points,
      dataIndex: "point",
      key: "point",
      align: "center",
      render: (e) => (
        <div style={{ color: COLORS.sub_accent, fontWeight: "bold" }}>
          {e} pt
        </div>
      ),
    },
    // {
    //   title: STRINGS.apply_coupon,
    //   dataIndex: "applyCoupon",
    //   key: "applyCoupon",
    //   align: "center",
    //   render: (e) => <Checkbox checked={e}></Checkbox>,
    // },
  ];

  const setData = () => {
    queryClient.refetchQueries("allCombo");
  };
  const onAdd = () => {
    ModalCustomService.set({
      title: STRINGS.add_new,
      wrapperStyle: { maxWidth: 1070 },
      contentStyle: { maxHeight: "70vh" },
      content: <Add data={data} setData={refetch} />,
    });
  };
  const onEdit = (record) => () => {
    ModalCustomService.set({
      title: STRINGS.update,
      wrapperStyle: { maxWidth: 1070 },
      contentStyle: { maxHeight: "70vh" },
      content: <Edit item={record} data={data} setData={setData} />,
    });
  };

  const removeItem = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${STRINGS.confirm_delete_common}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteCombo(item.id);
        if (result.success) {
          refetch();
          message.success(STRINGS.deleted_common);
        } else {
          CommonFunction.logError(result);
        }
      },
    });
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="sortable-list-tab"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    if (data) {
      const index = data.comboDTOS?.findIndex((x) =>
        userAdmin.type !== "STORE_ADMIN"
          ? x.id === restProps["data-row-key"]
          : x.storeId !== 0 && x.id === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
    } else {
      return <></>;
    }
  };

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(data.comboDTOS),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      queryClient.setQueryData("allCombo", (oldData) => {
        return { ...oldData, comboDTOS: newData };
      });
      arrayPosition.current = [];
      for (let index = 0; index < newData.length; index++) {
        let array = [];
        array.push(newData[index].id);
        array.push(newData.length - index);
        arrayPosition.current.push(array);
      }
      const result = await FetchApi.updatePositionCombo(arrayPosition.current);

      if (result.success) {
        message.success(STRINGS.change_position_success);
      } else {
        message.error(result.message);
      }
    }
  };

  if (isLoading) {
    return <LoadingView />;
  }

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Container
      className="combo-master"
      // title={STRINGS.course_setting}
      renderRight={() => (
        <ButtonCustom onClick={onAdd} icon={<PlusCircleOutlined />}>
          {STRINGS.add_new_course}
        </ButtonCustom>
      )}>
      <Table
        loading={isLoading}
        rowKey={"id"}
        columns={columnsWrapper}
        dataSource={data?.comboDTOS || []}
        pagination={false}
        scroll={{ x: 1600, y: "calc(100vh - 300px)" }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
      {/* {data.comboDTOS.map((item, index) => {
        return (
          <Col key={`${item.id}`} className="combo-item">
            <Row className="combo-item__title">{item.name}</Row>
            <Row className="combo-item__description">{item.description}</Row>
            <Image
              style={{ margin: "10px 0px", width: 375 }}
              src={item.imageUrl}
            />
            <Row>
              <Col lg={18}>
                <Table
                  rowKey={"id"}
                  columns={columns}
                  dataSource={item.comboPackDTOS || []}
                  locale={{ emptyText: STRINGS.no_data }}
                  pagination={false}
                />
              </Col>
              <Col lg={6} style={{ textAlign: "right", alignSelf: "flex-end" }}>
                <Space size={10}>
                  <button
                    style={{
                      border: 0,
                      backgroundColor: "transparent",
                    }}
                    onClick={onEdit(item)}>
                    <img
                      style={{ width: 20, height: 20 }}
                    
                      alt="edit"
                    />
                  </button>
                  {item.storeId == 0 &&
                  userAdmin.type === "STORE_ADMIN" ? null : (
                    <button
                      style={{
                        border: 0,
                        backgroundColor: "transparent",
                      }}
                      onClick={removeItem(item)}>
                      <img
                        alt="delete"
                        style={{ width: 20, height: 20 }}
                      />
                    </button>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
        );
      })} */}
    </Container>
  );
};

export default Combo;
