import { useQuery } from "react-query";
import { FetchApi } from "../../api";
import Auth from "../../store/Authentication";

const useListStoreStaffNotWorking = (day) => {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const { data, isLoading, refetch, isFetching } = useQuery(
    [`useListStoreStaffNotWorking-${userAdmin.storeId}`],
    () => FetchApi.getListStaffNotWorkingStore(day)
  );
  return {
    listStoreStaffNotWorking: data,
    refetchListStoreStaffNotWorking: refetch,
    isLoadingListStoreStaffNotWorking: isLoading,
    isFetchingListStoreStaffNotWorking: isFetching,
  };
};

export { useListStoreStaffNotWorking };
