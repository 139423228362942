import React from "react";
import { Typography, Table } from "antd";
import { STRINGS } from "../../constants";

const { Text } = Typography;

function ChangeStaffBookingHistory() {
  const columns = [
    {
        title: "No",
        key: "index",
        dataIndex: "index",
        fixed: "left",
        width: 70,
        sorter: (a, b) => a.index - b.index,
        sortDirections: ["ascend", "descend", "ascend"],
        align: "center",
      },
    {
      title: STRINGS.day_go_store,
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      width: 220,
    },
    {
      title: STRINGS.user_name,
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      width: 150,
    },
  ];
  const data = [
    {
      key: "createdTime",
      createdTime: "John Brown",
      customerName: 32,
    },
    {
      key: "customerName",
      createdTime: "Jim Green",
      customerName: 42,
    },
  ];
  return (
    <>
      <Text
        level={2}
        style={{
          padding: "14px 18px",
          fontSize: 26,
          fontWeight: "bold",
          color: "#4b2a05",
        }}
      >
        Coming soon
      </Text>

      <Table columns={columns} dataSource={data} />
    </>
  );
}

export default ChangeStaffBookingHistory;
