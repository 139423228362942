import { useQuery } from "react-query";

import { FetchApi } from "../../../../../api";

export function useGetAllCombo() {
  const value = useQuery(["allCombo"], () =>
    FetchApi.getComboList({
      size: 40,
      page: 1,
    })
  );
  return value;
}
