import React, { useState } from "react";
import { message, Upload } from "antd";
import { LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { CommonFunction } from "../util/CommonFunction";
import { FetchApi } from "../api";
import { COLORS, STRINGS } from "../constants";
// import { SvgView } from "./SvgView";

export function FileUpload({
  value,
  onChange,
  style,
  imageStyle,
  wrapperStyle,
  disabled,
}) {
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [audioUrl, setAudioUrl] = useState(value);

  // const removeFile = () => {
  //   setAudioUrl("");
  //   onChange?.("");
  // };

  const handleUploadFile = async (file) => {
    console.log("file", file.file);
    setLoadingUpload(true);
    let result = await FetchApi.uploadFileSound(file);
    if (result?.fileUrl) {
      setAudioUrl(result.fileUrl);
      setLoadingUpload(false);
      onChange?.(result.fileUrl);
    } else {
      message.warning(STRINGS.network_error);
      setLoadingUpload(false);
    }
  };

  const uploadButton = (
    <div style={{ alignSelf: "center", position: "absolute" }}>
      {loadingUpload ? (
        <LoadingOutlined />
      ) : (
        <PlusCircleOutlined style={{ color: COLORS.primary_3, fontSize: 32 }} />
      )}
      <div className="ant-upload-text">{STRINGS.upload}</div>
    </div>
  );
  return (
    <div style={{ width: "100%", height: "100%", ...wrapperStyle }}>
      {/* {audioUrl && !disabled && (
        <button
          style={{
            position: "absolute",
            alignSelf: "flex-end",
            top: 10,
            zIndex:1000,
            right: 10,
            width: "32px",
            height: "32px",
            borderWidth: 0,
            backgroundColor: "white",
            borderRadius: 8,
            overflow: "hidden",
            paddingTop: 5,
            cursor: "pointer",
          }}
          onClick={removeFile}>
          <SvgView svgData={Svgs.delete} />
        </button>
      )} */}
      <Upload
        disabled={disabled}
        style={{ ...style, width: "100%", backgroundColor: "red" }}
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={CommonFunction.beforeUploadAudio}
        customRequest={handleUploadFile}
      >
        {!!audioUrl && (
          <audio
            style={{ width: "100%", height: "100%", ...imageStyle }}
            controls
            src={audioUrl}
          >
            <track kind="captions" />
          </audio>
        )}
        {uploadButton}
      </Upload>
    </div>
  );
}
