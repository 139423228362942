import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, Button, message, Row, Col } from "antd";

import { FetchApi } from "../../api";
import { STRINGS } from "../../constants";
import { Validate } from "../../elements";
import { CommonFunction } from "../../util";
import Auth from "../../store/Authentication";

import "antd/dist/antd.min.css";
import "./Login.scss";

export default function Login() {
  let navigate = useNavigate();
  const location = useLocation();
  const [forgetPass, setForgetPass] = useState(false);

  const login = async (values) => {
    if (values && values.username && values.password) {
      const data = {
        email: values.username,
        password: values.password,
      };

      const result = await FetchApi.login(data);
      if (result && result.success && result.code === 200) {
        Auth.setToken(result.loginResult);
        Auth.setCurrentUser(result.user);
        // if (result.user.type === "STORE_ADMIN") {
        //   history.push("/");
        // } else {
        //   history.push("/");
        // }
        //
        if (location.state) {
          navigate(location.state);
          return;
        }
        navigate("/");
        return;
      }
      if (result && result.code === 4002) {
        message.error(STRINGS.wrong_username_or_password);
        return;
      }
      message.error(STRINGS.login_failed);
    }
  };
  const onForget = async (values) => {
    const { email } = values;
    if (email) {
      const res = await FetchApi.resetPasswordEmail(email);
      if (res.success) {
        message.success(STRINGS.please_check_your_email);
        setForgetPass(false);
      } else if (res.message === "User can't found") {
        message.warning(STRINGS.email_was_not_registered);
      } else {
        CommonFunction.logError(res);
      }
    }
  };

  return (
    <Row className="login-web" justify="space-around" align="middle">
      <Col>
        <img
          className="image-left"
          src={
            "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/aa794753-9c10-4231-81c9-7f0f6f2475ebe363c7be-9fce-4005-97c5-75cda835b4c18a65a709-21ea-433e-811d-18acfe34cf33508d8e7b-ad27-4963-89b5-53eee6e66cbb.png"
          }
          alt="logo"
        />
      </Col>
      <div className="authen-box">
        <div className="title">
          <img
            src={
              "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/4b1587c2-9bf4-43bb-9067-3cd734f35419b47ba7a6-96a7-4440-9533-a03944b4ac1190e8ae58-f51e-4dc2-a2de-31c9ec5caa07c4e2b4b0-bf5c-4bb4-b386-c5d0dae1f94c.png"
            }
            alt="logo"
          />
        </div>
        {!forgetPass ? (
          <Form layout="vertical" className="form-login" onFinish={login}>
            <Form.Item
              label={STRINGS.user_name}
              name="username"
              required
              rules={[
                {
                  required: true,
                  message: STRINGS.fill_username,
                },
              ]}>
              <Input
                className="form-input"
                placeholder={STRINGS.username_placeholder}
                style={{ width: 320 }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={STRINGS.password}
              required
              rules={[
                {
                  required: true,
                  message: STRINGS.fill_password,
                },
              ]}>
              <Input.Password
                className="form-input"
                placeholder={STRINGS.password_placeholder}
                style={{ width: 320 }}
                visibilityToggle={false}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                onClick={login}
                className="button-login"
                type="primary"
                style={{ width: 320 }}>
                {STRINGS.login}
              </Button>
            </Form.Item>
            <div
              onClick={() => setForgetPass(true)}
              className="forgot-password">
              {STRINGS.you_forgot_password}
            </div>
          </Form>
        ) : (
          <Form layout="vertical" className="form-login" onFinish={onForget}>
            <Form.Item
              label={STRINGS.email}
              name="email"
              required
              rules={[Validate.email, Validate.emptyContent]}>
              <Input
                className="form-input"
                placeholder={STRINGS.username_placeholder}
                style={{ width: 320 }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                onClick={onForget}
                className="button-login"
                type="primary"
                style={{ width: 320 }}>
                {STRINGS.confirm}
              </Button>
            </Form.Item>
            <div
              onClick={() => setForgetPass(false)}
              className="forgot-password">
              {STRINGS.login}
            </div>
          </Form>
        )}
      </div>
    </Row>
  );
}
