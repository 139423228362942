import React, { memo, useState, useEffect } from "react";
import { Table, Col, message, Row } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import { ButtonCustom, ErrorView, ModalCustomService } from "../../elements";
import { useGetFixedSchedule } from "../../util/hooks";
import { CommonFunction } from "../../util/CommonFunction";
import { COLORS, STRINGS, LOGIC } from "../../constants";
import { FetchApi } from "../../api";
import Add from "./items/Add";
import Edit from "./items/Edit";

import "./StoreFixedSchedule.scss";

const StoreFixedSchedule = memo(() => {
  moment.locale("ja-jp"); // important!
  moment.updateLocale("ja-jp", {
    week: { dow: 1 },
    weekdaysMin: ["日", "月", "火", "水", "木", "金", "土"],
    monthsShort: [
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月",
    ],
  });

  const [monthSelected, setMonthSelected] = useState(moment());
  const [loading, setLoading] = useState(false);
  const {
    listFixedSchedule,
    isLoading: isLoadingListFixedSchedule,
    refetch: refetchListFixedSchedule,
  } = useGetFixedSchedule({
    firstDayOfMonth: moment(monthSelected)
      .startOf("month")
      .format("MM/DD/YYYY"),
  });

  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (
      moment(monthSelected).isBefore(moment()) ||
      (moment().date() > 15 &&
        moment(monthSelected).diff(moment(), "days") >= 28 &&
        moment(monthSelected).diff(moment(), "days") <= 50)
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [monthSelected]);

  const renderItem = (e, index) => {
    let color = "#FF8000";
    if (isDisabled) {
      color = COLORS.disabled;
    }
    if (!e[index]?.shiftDTOS) {
      return (
        <PlusCircleOutlined
          onClick={onAdd(e, index)}
          style={{ color, fontSize: 32 }}
        />
      );
    }
    return e[index].shiftDTOS.map((shift, shiftIndex) => {
      return (
        <Col
          key={`${shiftIndex}`}
          style={{ textAlign: "-webkit-center" }}
          onClick={onEdit(e, e[index].shiftDTOS, index)}
        >
          {!!shift.session && (
            <Col
              style={{
                backgroundColor: LOGIC.shiftColor[shift.session],
                padding: "4px 24px",
                borderRadius: 6,
                marginBottom: 6,
                width: "fit-content",
              }}
            >
              {shift.session}
            </Col>
          )}
          <Col>
            {shift.startTime}~{shift.endTime}
          </Col>
        </Col>
      );
    });
  };

  const onAdd = (e, index) => () => {
    if (isDisabled) {
      return null;
    }
    const staffInfo = {
      staffId: e.staffId,
      dayOfWeek: index + 1,
    };
    ModalCustomService.set({
      title: e.staffName,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <Add
          refetch={refetchListFixedSchedule}
          staffInfo={staffInfo}
          firstDayOfMonth={moment(monthSelected)
            .startOf("month")
            .format("MM/DD/YYYY")}
        />
      ),
    });
  };
  const onEdit = (e, shift, index) => () => {
    if (isDisabled) {
      return null;
    }
    const staffInfo = {
      staffId: e.staffId,
      dayOfWeek: index + 1,
      shiftRequests: shift,
    };
    ModalCustomService.set({
      title: e.staffName,
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <Edit
          refetch={refetchListFixedSchedule}
          staffInfo={staffInfo}
          firstDayOfMonth={moment(monthSelected)
            .startOf("month")
            .format("MM/DD/YYYY")}
        />
      ),
    });
  };
  const columns = [
    {
      title: STRINGS.staff_name_2,
      dataIndex: "staffName",
      key: "staffName",
    },
    {
      title: "月",
      key: "monday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) =>
        renderItem(
          { ...e, staffId: others.staffId, staffName: others.staffName },
          0
        ),
      width: "12%",
    },
    {
      title: "火",
      key: "tuesday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) =>
        renderItem(
          { ...e, staffId: others.staffId, staffName: others.staffName },
          1
        ),
      width: "12%",
    },
    {
      title: "水",
      key: "wednesday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) =>
        renderItem(
          { ...e, staffId: others.staffId, staffName: others.staffName },
          2
        ),
      width: "12%",
    },
    {
      title: "木",
      key: "thursday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) =>
        renderItem(
          { ...e, staffId: others.staffId, staffName: others.staffName },
          3
        ),
      width: "12%",
    },
    {
      title: "金",
      key: "friday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) =>
        renderItem(
          { ...e, staffId: others.staffId, staffName: others.staffName },
          4
        ),
      width: "12%",
    },
    {
      title: "土",
      key: "saturday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) =>
        renderItem(
          { ...e, staffId: others.staffId, staffName: others.staffName },
          5
        ),
      width: "12%",
    },
    {
      title: "日",
      key: "sunday",
      dataIndex: "dayOfWeekDTOS",
      align: "center",
      render: (e, others) =>
        renderItem(
          { ...e, staffId: others.staffId, staffName: others.staffName },
          6
        ),
      width: "12%",
    },
  ];
  // const renderButtonCopy = () => {
  //   if (moment(monthSelected).diff(moment(), "months", true) >= 1.5) {
  //     return (
  //       <ButtonCustom
  //         onClick={async () => {
  //           setLoading(true);
  //           const result = await FetchApi.genShiftSchedule({
  //             firstDayOfMonth: moment(monthSelected)
  //               .startOf("month")
  //               .format("MM/DD/YYYY"),
  //           });
  //           setLoading(false);
  //           if (result.success) {
  //             refetchListFixedSchedule();
  //             message.success(STRINGS.done_successful);
  //             return;
  //           }
  //           message.error(result.message);
  //         }}
  //         style={{
  //           width: "fit-content",
  //           border: `1px solid ${COLORS.primary}`,
  //           height: "auto",
  //           float: "right",
  //           marginBottom: 20,
  //           fontSize: 20,
  //           boxShadow: "gray 0px 1px 4px 0px",
  //           color: COLORS.primary,
  //           backgroundColor: COLORS.background,
  //         }}
  //       >
  //         {STRINGS.copy_schedule}
  //       </ButtonCustom>
  //     );
  //   }
  // };
  if (listFixedSchedule && !listFixedSchedule.success) {
    return <ErrorView data={listFixedSchedule} />;
  }
  const listMonth = CommonFunction.generateMonth();

  return (
    <Col className="store-fixed-schedule">
      <Row justify="space-between">
        <Col style={{ marginBottom: 10 }} span={12}>
          {listMonth.map((i, index) => {
            let backgroundColor;
            let color = COLORS.primary;
            if (moment(i).month() === moment(monthSelected).month()) {
              backgroundColor = COLORS.primary;
              color = COLORS.background;
            }
            return (
              <ButtonCustom
                key={`${index}`}
                onClick={() => {
                  setMonthSelected(moment(i));
                }}
                style={{
                  minWidth: 90,
                  width: 90,
                  border: `1px solid ${COLORS.primary}`,
                  alignItems: "center",
                  height: "fit-content",
                  marginLeft: 20,
                  marginBottom: 20,
                  fontSize: 20,
                  boxShadow: "gray 0px 1px 4px 0px",
                  backgroundColor,
                  color,
                }}
              >
                {moment(i).month() + 1}
                {STRINGS.month}
              </ButtonCustom>
            );
          })}
        </Col>
        <Col span={12}>
          <ButtonCustom
            onClick={async () => {
              const result = await FetchApi.copySchedule(
                moment(monthSelected)
                  .startOf("month")
                  .format("YYYY-MM-DD 00:00:00")
              );
              if (result.success) {
                message.success(STRINGS.done_successful);
                refetchListFixedSchedule();
                return;
              }
              message.error(STRINGS.server_error);
            }}
            style={{
              fontSize: 20,
              marginLeft: 20,
              height: "fit-content",
              boxShadow: "gray 0px 1px 4px 0px",
              backgroundColor: COLORS.secondary,
              borderColor: COLORS.secondary,
              marginBottom: 18,
            }}
          >
            {STRINGS.copy_fixed_schedule}
          </ButtonCustom>
          <ButtonCustom
            onClick={async () => {
              const result = await FetchApi.genShiftSpecificMonth(
                moment(monthSelected)
                  .startOf("month")
                  .format("YYYY-MM-DD 00:00:00")
              );
              if (result.success) {
                message.success(STRINGS.done_successful);
                refetchListFixedSchedule();
                return;
              }
              message.error(STRINGS.server_error);
            }}
            style={{
              fontSize: 20,
              marginLeft: 20,
              boxShadow: "gray 0px 1px 4px 0px",
              backgroundColor: COLORS.secondary,
              borderColor: COLORS.secondary,
              height: "auto",
              marginBottom: 18,
            }}
          >
            {STRINGS.create_schedule}
          </ButtonCustom>
          <ButtonCustom
            onClick={async () => {
              await FetchApi.downloadExpectedSchedule({
                firstDayOfMonth: moment(monthSelected).add(1,'month')
                  .startOf("month")
                  .format("MM/DD/YYYY"),
              });
            }}
            style={{
              fontSize: 20,
              marginLeft: 20,
              height: "auto",
              boxShadow: "gray 0px 1px 4px 0px",
              marginBottom: 18,
            }}
          >
            {STRINGS.download_expected_schedule}
          </ButtonCustom>
          <ButtonCustom
            onClick={async () => {
              await FetchApi.downloadActualSchedule({
                firstDayOfMonth: moment(monthSelected)
                  .startOf("month")
                  .format("MM/DD/YYYY"),
              });
            }}
            style={{
              fontSize: 20,
              marginLeft: 20,
              height: "auto",
              boxShadow: "gray 0px 1px 4px 0px",
              marginBottom: 18,
            }}
          >
            {STRINGS.download_actual_schedule}
          </ButtonCustom>
          <ButtonCustom
            onClick={async () => {
              await FetchApi.downloadFixedSchedule({
                firstDayOfMonth: moment(monthSelected)
                  .startOf("month")
                  .format("MM/DD/YYYY"),
              });
            }}
            style={{
              fontSize: 20,
              marginLeft: 20,
              height: "auto",
              width: "fit-content",
              boxShadow: "gray 0px 1px 4px 0px",
            }}
          >
            {STRINGS.download_fixed_schedule}
          </ButtonCustom>
        </Col>
      </Row>
      <div
        style={{
          textAlign: "center",
          color: COLORS.sub_accent,
          fontSize: 40,
          fontWeight: "900",
        }}
      >
        {STRINGS.store_schedule_des}
      </div>
      {/* {renderButtonCopy()} */}
      <Table
        bordered={true}
        loading={isLoadingListFixedSchedule || loading}
        rowKey="staffId"
        columns={columns}
        dataSource={listFixedSchedule?.scheduleDTOS || []}
        pagination={false}
        scroll={{ x: "100%", y: "calc(100vh - 170px)" }}
      />
    </Col>
  );
});

export default StoreFixedSchedule;
