/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import moment from "moment";
import HeaderSchedule from "./items/HeaderSchedule";
import { useGetCalendarOrdersManual } from "../../util/hooks";
import { ErrorView, LoadingView } from "../../elements";
import Schedule from "./elements/Schedule";
import "./StaffScheduleManual.scss";

export default function StaffScheduleManual() {
  const [selectedDate, setSelectedDate] = useState(moment());
  const { calendarOrder, isLoadingCalendarOrder, refetchCalendarOrder } =
    useGetCalendarOrdersManual(selectedDate);

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      refetchCalendarOrder();
    }, 30000);

    return () => {
      refetchInterval && clearInterval(refetchInterval);
    };
  }, []);

  if (isLoadingCalendarOrder) {
    return <LoadingView />;
  }

  if (calendarOrder && !calendarOrder.success) {
    return <ErrorView data={calendarOrder} />;
  }

  return (
    <div className="staff-schedule">
      <HeaderSchedule
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        comboColors={calendarOrder.comboColors}
        totalMoney={calendarOrder.totalMoney}
        staffOrderDTOS={calendarOrder.staffOrderDTOS}
        refetchCalendarOrder={refetchCalendarOrder}
      />
      {!!calendarOrder?.staffOrderDTOS && (
        <Schedule
          calendarOrder={calendarOrder}
          refetchCalendarOrder={refetchCalendarOrder}
          selectedDate={selectedDate}
        />
      )}
    </div>
  );
}
