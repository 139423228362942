/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Col, Form, Row, TimePicker } from "antd";
import {
  ClockCircleFilled,
  MinusCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import moment from "moment";

import { Validate } from "../../../elements";
import { COLORS, STRINGS, LOGIC } from "../../../constants";

import "../ManagerPointDay.scss";

function HoursPicker({ onChange, disabled, value }) {
  const [timeRangeList, setTimeRangeList] = useState(() => {
    if (value?.length > 0) return value;
    return [
      {
        startTime: moment(),
        endTime: moment().add(1, "hours"),
      },
    ];
  });
  const addTimeRangeList = () => {
    if (disabled) {
      return;
    }
    const newStartTime = timeRangeList[timeRangeList.length - 1]
      ? moment(timeRangeList[timeRangeList.length - 1].endTime).add(1, "hours")
      : moment();
    const newEndTime = timeRangeList[timeRangeList.length - 1]
      ? moment(timeRangeList[timeRangeList.length - 1].endTime).add(2, "hours")
      : moment().add(1, "hours");
    const clone = [
      ...timeRangeList,
      { startTime: newStartTime, endTime: newEndTime },
    ];
    setTimeRangeList(clone);
    onChange(clone);
  };
  const removeTimeRange = (index) => () => {
    if (disabled) {
      return;
    }
    const clone = [...timeRangeList];
    clone[index].action = "DELETE";
    clone.splice(index, 1);
    setTimeRangeList(clone);
    onChange(clone);
  };
  const onChangeHour = (index, type) => (e) => {
    const clone = JSON.parse(JSON.stringify([...timeRangeList]));
    clone[index][type] = moment(e);
    setTimeRangeList(clone);
    onChange(clone);
  };

  return (
    <Col>
      {timeRangeList.map((item, index) => {
        return (
          <Row
            key={`${index}-${item?.startTime}`}
            style={{
              textAlign: "left",
              alignItems: "center",
              marginBottom: 6,
            }}>
            <Col span={10}>
              <TimePicker
                disabled={disabled}
                minuteStep={10}
                defaultValue={moment(item.startTime)}
                suffixIcon={
                  <ClockCircleFilled
                    style={{ fontSize: 18, color: COLORS.sub_disabled }}
                  />
                }
                style={{ borderRadius: 4, padding: "6px 10px", width: "90%" }}
                allowClear={false}
                format={"HH:mm"}
                onChange={onChangeHour(index, "startTime")}
              />
            </Col>
            <Col span={10}>
              <TimePicker
                disabled={disabled}
                minuteStep={10}
                defaultValue={moment(item.endTime)}
                suffixIcon={
                  <ClockCircleFilled
                    style={{ fontSize: 18, color: COLORS.sub_disabled }}
                  />
                }
                style={{ borderRadius: 4, padding: "6px 10px", width: "90%" }}
                allowClear={false}
                format={"HH:mm"}
                onChange={onChangeHour(index, "endTime")}
              />
            </Col>

            {timeRangeList.length > 1 && (
              <MinusCircleFilled
                style={{ fontSize: 18, marginRight: 6 }}
                span={2}
                onClick={removeTimeRange(index)}
              />
            )}
            {index === timeRangeList.length - 1 && timeRangeList.length < 5 && (
              <PlusCircleFilled
                style={{ fontSize: 18 }}
                span={2}
                onClick={addTimeRangeList}
              />
            )}
          </Row>
        );
      })}
    </Col>
  );
}

export function PointDayConfigTime({ form, pointType }) {
  const [rules, setRules] = useState([]);

  useEffect(() => {
    if (pointType === LOGIC.booking_point.rangeWithTime) {
      setRules([Validate.emptyContent]);
    } else {
      setRules([]);
      form.resetFields(["timeFrames"]);
    }
  }, [pointType]);

  return (
    <Col>
      <Row
        style={{
          textAlign: "left",
          paddingTop: 14,
          fontSize: 15,
        }}>
        <Col span={10}>{STRINGS.coupon_time_start}</Col>
        <Col span={10}>{STRINGS.coupon_time_end}</Col>
      </Row>
      <Col>
        <Form.Item name="timeFrames" rules={rules}>
          <HoursPicker
            disabled={pointType !== LOGIC.booking_point.rangeWithTime}
          />
        </Form.Item>
      </Col>
    </Col>
  );
}
