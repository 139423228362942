import React, { useState } from "react";
import { Col, Form, Row } from "antd";
import { BorderOutlined, CheckSquareFilled } from "@ant-design/icons";

import { COLORS, STRINGS } from "../../../constants";

import "../StaffBooking.scss";

const ListNote = [
  STRINGS.head,
  STRINGS.neck,
  STRINGS.shoulder,
  STRINGS.spine_back,
  STRINGS.hand,
  STRINGS.belt,
  STRINGS.butt,
  STRINGS.thighs,
  STRINGS.calf,
];

function ContentNote({ isDisabled, value, onChange }) {
  return (
    <Col style={{ padding: "0px 10px" }}>
      <div>{STRINGS.note_title}</div>
      <Row>
        {ListNote.map((item) => {
          return (
            <NoteItem
              key={`${item}`}
              item={item}
              isDisabled={isDisabled}
              value={value}
              onChange={onChange}
            />
          );
        })}
      </Row>
    </Col>
  );
}

function NoteItem({ item, isDisabled, onChange, value }) {
  const [isSelect, setIsSelect] = useState(() => {
    const indexNote = value.findIndex((i) => i === item);
    if (indexNote > -1) {
      return true;
    }
    return false;
  });
  const renderIcon = () => {
    if (isSelect) {
      return (
        <CheckSquareFilled style={{ fontSize: 28, color: COLORS.primary }} />
      );
    }
    return <BorderOutlined style={{ fontSize: 28, color: COLORS.border }} />;
  };
  if (isDisabled) {
    return null;
  }

  return (
    <Col
      xl={8}
      xs={24}
      style={{ marginTop: "5px", marginBottom: "5px" }}
      onClick={() => {
        const indexNote = value.findIndex((i) => i === item);
        setIsSelect(!isSelect);
        if (indexNote > -1) {
          const arrClone = [...value];
          arrClone.splice(indexNote, 1);
          onChange(arrClone);
          return;
        }
        onChange([...value, item]);

        // setIsSelect(!isSelect);
      }}>
      <Row style={{ alignItems: "center" }}>
        {renderIcon()}
        {"\xa0" + item}
      </Row>
    </Col>
  );
}

function StaffBookingComboAllNote({ form, numberOrdinal }) {
  const [isDisabled, setIsDisabled] = useState(() => {
    const comboAll = form.getFieldValue(`bookingComboAll${numberOrdinal}`);
    if (comboAll?.comboName === STRINGS.recovery) {
      return false;
    }
    return true;
  });
  return (
    <Form.Item
      initialValue={[]}
      name={`bookingComboAllNote${numberOrdinal}`}
      shouldUpdate={(prevValues, curValues) => {
        const comboAll = curValues[`bookingComboAll${numberOrdinal}`];
        if (comboAll?.comboName === STRINGS.recovery) {
          setIsDisabled(false);
          return;
        }
        setIsDisabled(true);
      }}>
      <ContentNote isDisabled={isDisabled} />
    </Form.Item>
  );
}

export { StaffBookingComboAllNote };
