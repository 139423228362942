import { Button, Col, Row, Table } from "antd";
import moment from "moment";
import { COLORS, LOGIC, STRINGS } from "../../../../constants";
import { CommonFunction } from "../../../../util/CommonFunction";

function BodyTable({ staffInfo, shiftStatus, onCheckout }) {
  const columns = [
    {
      dataIndex: "index",
      key: "index",
      width: 50,
      align: "center",
    },
    {
      title: STRINGS.reserve,
      dataIndex: "reservationTime",
      key: "reservationTime",
      width: 100,
      align: "center",
      render: (e) => {
        if (e?.length > 6) {
          return e.substring(0, 6) + "\n" + e.substring(6, 11);
        }
        return e;
      },
    },
    {
      title: STRINGS.customer_name,
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
      align: "center",
    },
    {
      title: STRINGS.name2,
      dataIndex: "isAssign",
      key: "isAssign",
      width: 80,
      align: "center",
      render: (e) =>
        e ? (
          <div
            style={{
              backgroundColor: COLORS.primary,
              width: 16,
              height: 16,
              borderRadius: "50%",
            }}
          />
        ) : (
          ""
        ),
    },
    {
      title: STRINGS.content_course,
      dataIndex: "course",
      key: "course",
      width: 150,
      align: "center",
      render: (e) =>
        e.map((item, index) => <Col key={`${index}`}>{item.courseName}</Col>),
    },
    {
      title: STRINGS.time,
      dataIndex: "course",
      key: "course",
      align: "center",
      width: 100,
      render: (e) =>
        e.map((item, index) => (
          <Col key={`${index}`}>{item.courseMinutes}</Col>
        )),
    },
    {
      title: STRINGS.price,
      dataIndex: "price",
      key: "price",
      align: "center",
      width: 150,
      render: (e) => CommonFunction.currencyFormat(e),
    },
    {
      title: STRINGS.remuneration,
      dataIndex: "staff2",
      align: "center",
      key: "staff2",
      width: 150,
      render: (e) => (
        <Col style={{ color: "#FF8000", fontSize: 22 }}>
          {CommonFunction.currencyFormat(e)}
        </Col>
      ),
    },
  ];

  let dataCombos = [];
  let total = 0;
  let totalAssign = 0;
  if (Array.isArray(staffInfo?.workShiftDTOS)) {
    staffInfo.workShiftDTOS.forEach((workShift) => {
      if (!Array.isArray(workShift.bookingDTOS)) {
        return;
      }

      workShift.bookingDTOS
        .filter((i) => i.status === "done" || i.paid)
        .forEach((booking, bookingIndex) => {
          const bookingStartTime = moment(booking.startTime);
          const bookingEndTime = moment(booking.timeFinishWorking);
          if (booking.chooseStaff) {
            totalAssign = totalAssign + 1;
          }
          const item = {
            index: bookingIndex + 1,
            id: booking.id,
            customerName: booking.customerName,
            reservationTime:
              bookingStartTime.format("HH:mm") +
              "~" +
              bookingEndTime.format("HH:mm"),
            course: [],
            price: booking.price,
            staff2: booking.salary || 0,
            situation: STRINGS.situation_booking[booking.status],
            isAssign: booking.chooseStaff,
          };
          if (
            Array.isArray(booking?.comboOrders) &&
            booking?.comboOrders.length > 0
          ) {
            (booking?.comboOrders || []).forEach((combo) => {
              item.course.push({
                courseName: combo.comboName,
                courseMinutes: combo.minutes + STRINGS.minute,
              });
            });
          } else {
            item.course.push({
              courseName: "",
              courseMinutes: booking.minutes + STRINGS.minute,
            });
          }

          total = total + (booking.salary || 0);
          dataCombos.push(item);
        });
    });
  }

  return (
    <Col style={{ paddingBottom: 20 }}>
      <Row
        style={{
          color: "#4B2A05",
          fontSize: 25,
          fontWeight: "bold",
          justifyContent: "center",
          padding: 10,
        }}>
        {STRINGS.reservation_list}
      </Row>
      <Table
        rowKey={"id"}
        columns={columns}
        dataSource={dataCombos}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
      />
      <Row style={{ marginTop: 20, paddingLeft: 10, alignItems: "center" }}>
        <Col xl={7}>
          <Row
            style={{
              border: `1px solid ${COLORS.accent}`,
              padding: "4px 8px",
              borderRadius: 6,
              fontSize: 22,
              fontWeight: "bold",
              color: COLORS.accent,
              width: "fit-content",
            }}>
            {dataCombos?.length || 0}
            {STRINGS.people}
          </Row>
        </Col>
        <Col xl={7}>
          <Row
            style={{
              paddingLeft: 55,
              fontSize: 22,
              fontWeight: "bold",
              color: COLORS.primary,
              width: "fit-content",
            }}>
            {totalAssign}
            {STRINGS.people}
          </Row>
        </Col>
        <Col xl={10} style={{ textAlign: "-webkit-right" }}>
          <Row
            style={{
              fontWeight: "bold",
              width: "fit-content",
              alignItems: "center",
              paddingRight: 10,
            }}>
            <Col style={{ color: COLORS.accent }}>{STRINGS.total_cong_nv}</Col>
            <Col style={{ color: COLORS.primary, fontSize: 43 }}>
              {"\xa0\xa0"}
              {LOGIC.currencyFormatter(total)}
            </Col>
          </Row>
        </Col>
      </Row>
      {shiftStatus === "working" && (
        <Row
          style={{
            justifyContent: "flex-end",
            paddingRight: 20,
            paddingTop: 10,
          }}>
          <Button
            onClick={onCheckout}
            className="btn-payment"
            style={{
              backgroundColor: "#FE6C06",
            }}>
            <Row className="icon">¥</Row>
            <Row className="text">{STRINGS.checkout}</Row>
          </Button>
        </Row>
      )}
    </Col>
  );
}

export default BodyTable;
