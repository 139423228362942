import React, { useRef, useState } from "react";
import { Col, Row, message, Form, Input } from "antd";
import moment from "moment";

import {
  ButtonCustom,
  LoadingAbsolute,
  ModalCustomService,
  Validate,
} from "../../../elements";
import { STRINGS } from "../../../constants/Strings";
import "../StaffSchedule.scss";
import RadioAllStoreStaff from "../../../elements/RadioAllStoreStaff";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util/CommonFunction";
import { TimePickerAutoAccept } from "../../../elements/TimePickerAutoAccept";
import { COLORS } from "../../../constants";

function ChangeTimeCheckOut({ onChange }) {
  return (
    <Row
      style={{
        alignItems: "center",
        fontWeight: "bold",
        color: COLORS.sub_accent,
        fontSize: 22,
        paddingTop: 20,
        paddingLeft: "29%",
      }}
    >
      <Col style={{ color: COLORS.sub_accent, fontSize: 18 }}>
        {STRINGS.checkout}
      </Col>
      <Col>
        <TimePickerAutoAccept
          minuteStep={10}
          format={"HH:mm"}
          style={{
            borderRadius: 5,
            color: COLORS.sub_accent,
            fontSize: 22,
            fontWeight: "bold",
            border: `1px solid ${COLORS.border}`,
            width: "100%",
            marginLeft: 10,
          }}
          onChange={(e) => {
            if (moment(e).hour() < 5) {
              onChange(moment(e).add(1, "day"));
            } else {
              onChange(e);
            }
          }}
          showTime={{
            format: "HH:mm",
            minuteStep: 10,
          }}
        />
      </Col>
    </Row>
  );
}

export default function ModalChangeCheckOutTime({
  refetchCalendarOrder,
  shiftId,
}) {
  const [form] = Form.useForm();
  const staffChangeRequest = useRef({});
  const [loading, setLoading] = useState(false);

  const onChangeRestTime = async () => {
    await form.validateFields();
    const dataForm = form.getFieldValue();
    if (!staffChangeRequest.current.id) {
      message.warning(STRINGS.please_select_a_reception_staff);
      return;
    }
    if (!staffChangeRequest.current.password) {
      message.warning(STRINGS.please_type_password);
      return;
    }
    try {
      setLoading(true);
      const result = await FetchApi.requestChangeEndTimeShift({
        staffChangeRequest: staffChangeRequest.current,
        note: dataForm.note,
        newEndTimestamp: moment(dataForm.newEndTimestamp).valueOf(),
        shiftId: shiftId,
      });
      setLoading(false);
      if (result.success) {
        refetchCalendarOrder();
        message.success(STRINGS.saved_common);
        ModalCustomService.close();
        return;
      }
      if (result.code == 4003) {
        message.warning(STRINGS.password_is_incorrect);
        return;
      }
      if (result.code == 4007) {
        message.error(STRINGS.update_shift_fail);
        return;
      }
      CommonFunction.logError(result);
    } catch (error) {
      console.log("error", error);
      message.error(STRINGS.something_wrong);
      setLoading(false);
    }
  };

  return (
    <Col className="checkin-time-wrapper">
      <Form form={form}>
        <Form.Item name="newEndTimestamp" rules={[Validate.emptyContent]}>
          <ChangeTimeCheckOut />
        </Form.Item>
        <Col>
          <Row
            style={{
              color: COLORS.sub_accent,
              fontSize: 18,
              paddingLeft: 63,
              alignItems: "center",
            }}
          >
            {STRINGS.reason}{" "}
            <Row style={{ color: COLORS.required }}>({STRINGS.required})</Row>
          </Row>
          <Form.Item
            style={{
              marginBottom: 10,
              width: "80%",
              alignItems: "center",
            }}
            name="note"
            rules={[Validate.emptyContent]}
          >
            <Input.TextArea style={{ borderRadius: 5 }} />
          </Form.Item>
        </Col>
        <Row
          style={{
            color: COLORS.required_2,
            fontSize: 15,
            paddingTop: 14,
            fontWeight: "bold",
            justifyContent: "center",
          }}
        >
          {STRINGS.change_work_description}
        </Row>
        <Row
          style={{
            color: "#4B2A05",
            fontSize: 18,
            paddingTop: 14,
            fontWeight: "bold",
            justifyContent: "center",
          }}
        >
          {STRINGS.please_select_a_reception_staff}
        </Row>

        <RadioAllStoreStaff
          containerStyle={{ paddingLeft: 30 }}
          onChange={(e) => (staffChangeRequest.current = e)}
        />
      </Form>

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}
        >
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onChangeRestTime}>{STRINGS.sure}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}
