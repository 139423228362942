/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useRef, useState, useCallback } from "react";
import { Layout, Menu } from "antd";
import {
  Route,
  Link,
  useLocation,
  Navigate,
  Routes,
  useNavigate,
} from "react-router-dom";

import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { HeaderService } from "../../services/HeaderService";
import { STRINGS, Svgs } from "../../constants";
import { AccountService } from "../../services/AccountService";
import { ModalCustomService } from "../../elements/ModalCustom/ModalCustomService";
import { MenuCollapsingService } from "../../services/MenuCollapsingService";
import { useMenuCollapsed } from "../../store/StateStore";
import { menuItems, systemAdmin } from "./items/SystemAdminRoute";
import { menuStaff, staffAdmin } from "./items/StaffAdminRoute";
import { menuStore, storeAdmin } from "./items/StoreAdminRoute";
import ProfileDetail from "../manager-profile/components/ProfileDetail";
import MainHeader from "./items/MainHeader";
import Auth from "../../store/Authentication";
import { useCheckStoreHaveBooking } from "./modules/useCheckStoreHaveBooking";

import "./Main.scss";
import "antd/dist/antd.min.css";

const { Content, Sider } = Layout;

const svgMenu = {
  operational_menu_store: Svgs.store(),
  work_manager_menu_store: Svgs.registrationBlack,
  staff_manager_menu_store: Svgs.notificationStaff,
  customer_manager_menu_store: Svgs.user,
  history_manager_menu_store: Svgs.visitHistory(),
  course_manager_menu_store: Svgs.combo(),
  relations_manager_menu_store: Svgs.mail(),
  customer_manager_menu: Svgs.user,
  group_manager_menu: Svgs.store(),
  staff_manager_menu: Svgs.staff(),
  slide_manager_menu: Svgs.slideSetting(),
  course_manager_menu: Svgs.combo(),
  relations_manager_menu: Svgs.mail(),
  point_reception: Svgs.pointReception(),
};

const Main = memo(() => {
  const navigate = useNavigate();
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState();
  const allowedRoutes = useRef([]);
  const lastPathNameStaffAdmin = useRef();
  const { collapsedLocal, setCollapsedLocal } = useMenuCollapsed();
  const [collapsed, setCollapsed] = useState(() => {
    return window.innerWidth < 1201 || collapsedLocal == "true";
  });
  const [defaultOpenKeys, setDefaultOpenKeys] = useState();
  const { isBookingEnable, isLoading } = useCheckStoreHaveBooking();

  const handleLogout = () => {
    ModalCustomService.set({
      content: <div>{STRINGS.do_u_want_logout}</div>,
      textConfirm: STRINGS.logout,
      textCancel: STRINGS.close,
      wrapperStyle: { width: 420 },
      onConfirm: () => {
        Auth.logout();
        navigate("/login");
      },
    });
  };
  const collapseSet = useCallback(() => {
    if (window.innerWidth < 1201) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, []);

  const menuType = (menutype, item = null) => {
    const arrCombine = [];
    Object.keys(menutype).forEach((item) => {
      menutype[item].forEach((menu) => {
        if (menu.key == selectedKey) {
          setDefaultOpenKeys(item);
        }
        arrCombine.push(menu);
      });
    });
    let clicked = arrCombine.find((_item) => _item?.key === item?.key);
    return { arrCombine, clicked };
  };

  useEffect(() => {
    setCollapsedLocal(collapsed);
  }, [collapsed]);

  useEffect(() => {
    AccountService.set(userAdmin);
    MenuCollapsingService.onChange("main", () => {
      setCollapsed((prev) => !prev);
    });
    window.addEventListener("resize", () => collapseSet());

    if (userAdmin?.type === "SYSTEM_ADMIN") {
      allowedRoutes.current = systemAdmin;
      const { arrCombine } = menuType(menuItems);
      const keyActive = arrCombine.find((_item) => {
        return location.pathname === _item.linkTo;
      });

      if (keyActive) {
        HeaderService.set(keyActive.key);
        setSelectedKey(keyActive.key);
      } else {
        if (location.pathname?.includes?.("history-store")) {
          HeaderService.set("pointHistory");
          setSelectedKey("pointHistory");
        }

        if (location.pathname === "/profile") {
          HeaderService.set("profile");
        }
      }
      return;
    }

    let menuActive;
    if (userAdmin?.type === "STORE_ADMIN") {
      allowedRoutes.current = storeAdmin;
      const { arrCombine } = menuType(menuStore);
      menuActive = arrCombine.find(
        (_item) => location.pathname === _item.linkTo
      );
    }
    if (
      userAdmin?.type === "STAFF_ADMIN" ||
      userAdmin?.type === "RECEPTIONIST"
    ) {
      allowedRoutes.current = staffAdmin;
      for (let _item of menuStaff) {
        if (_item.menuItem) {
          menuActive = _item.menuItem.find(
            (__item) => location.pathname === __item.linkTo
          );
          if (menuActive) break;
        } else if (location.pathname === _item.linkTo) {
          menuActive = _item;
          break;
        }
      }

      if (location.pathname === "/staff-booking") {
        menuActive = {};
        menuActive.key =
          lastPathNameStaffAdmin.current === "/"
            ? "staffSchedule"
            : "staffManagerSchedule";
      }
      lastPathNameStaffAdmin.current = location.pathname;
    }
    if (menuActive) {
      HeaderService.set(menuActive.key);
      setSelectedKey(menuActive.key);
    } else {
      if (location.pathname === "/profile") {
        HeaderService.set("profile");
      }
    }
    return () => {
      HeaderService.deleteKey("main");
      MenuCollapsingService.deleteKey("main");
      AccountService.deleteKey("main");
    };
  }, [navigate, location, userAdmin]);

  const onClickMenu = (item) => {
    if (item.key === "logout" || item.key === "logout2") {
      ModalCustomService.set({
        content: <div>{STRINGS.do_u_want_logout}</div>,
        textConfirm: STRINGS.logout,
        textCancel: STRINGS.close,
        wrapperStyle: { width: 420 },
        onConfirm: () => {
          Auth.logout();
          navigate("/login");
        },
      });
    } else {
      let clicked;
      if (userAdmin?.type === "SYSTEM_ADMIN") {
        clicked = menuType(menuItems, item).clicked;
      } else if (userAdmin?.type === "STORE_ADMIN") {
        clicked = menuType(menuStore, item).clicked;
      } else {
        for (let _item of menuStaff) {
          if (_item.menuItem) {
            clicked = _item.menuItem.find((__item) => __item.key === item.key);
            if (clicked) break;
          } else {
            if (_item.key === item.key) {
              clicked = _item;
              break;
            }
          }
        }
      }
      navigate(clicked?.linkTo);
    }
  };
  if (!Auth.isAuthenticated()) {
    return <Navigate to="/login" state={location.pathname} />;
  }

  return (
    <Layout style={{ minHeight: "100vh" }} className="layout-site">
      <MainHeader
        handleLogout={handleLogout}
        setSelectedKey={setSelectedKey}
        collapsed={collapsed}
      />
      <Layout className="layout-content">
        <Sider
          width={260}
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="sider-layout"
          breakpoint="lg"
        >
          {(!!defaultOpenKeys ||
            userAdmin.type === "STAFF_ADMIN" ||
            userAdmin.type === "RECEPTIONIST") && (
            <Menu
              theme="dark"
              className="menu-sider"
              mode="inline"
              style={{
                height: "100%",
                backgroundPosition: "bottom",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url('https://api.xn--vckg5a9gug849xgl0b.com/upload/image/a91c083e-84a7-47e0-b118-9b1d391f2234d4674b0a-cfbe-4041-b882-2de1fb1a81d52d2b8303-77ad-44ae-b3a9-f23dfd308f99fd258898-6abd-47a3-8b11-5883dea6ed08.png')`,
              }}
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKey]}
              onClick={onClickMenu}
            >
              {userAdmin.type === "SYSTEM_ADMIN" &&
                Object.keys(menuItems).map((item, index) => {
                  return (
                    <Menu.SubMenu
                      key={`${item}`}
                      title={STRINGS[item]}
                      icon={svgMenu[item]}
                    >
                      {menuItems[item].map((menu) => {
                        return (
                          <Menu.Item
                            key={menu.key}
                            icon={menu.icon}
                            className="menu-item"
                          >
                            <span>{menu.title}</span>
                            <Link to={menu.linkTo} />
                          </Menu.Item>
                        );
                      })}
                    </Menu.SubMenu>
                  );
                })}
              {userAdmin.type === "STORE_ADMIN" &&
                Object.keys(menuStore).map((item, index) => {
                  if (item === "point_reception") {
                    const menu = menuStore.point_reception[0];
                    return (
                      <Menu.Item key={menu.key} icon={menu.icon}>
                        <span>{menu.title}</span>
                        <Link to={menu.linkTo} />
                      </Menu.Item>
                    );
                  }
                  if (item === "set_the_work_order") {
                    const menu = menuStore.set_the_work_order[0];
                    return (
                      <Menu.Item key={menu.key} icon={menu.icon}>
                        <span>{menu.title}</span>
                        <Link to={menu.linkTo} />
                      </Menu.Item>
                    );
                  }
                  return (
                    <Menu.SubMenu
                      key={`${item}`}
                      title={STRINGS[item]}
                      icon={svgMenu[item]}
                    >
                      {menuStore[item].map((menu) => {
                        return (
                          <Menu.Item
                            key={menu.key}
                            icon={menu.icon}
                            className="menu-item"
                          >
                            <span>{menu.title}</span>
                            <Link to={menu.linkTo} />
                          </Menu.Item>
                        );
                      })}
                    </Menu.SubMenu>
                  );
                })}
              {(userAdmin.type === "STAFF_ADMIN" ||
                userAdmin.type === "RECEPTIONIST") &&
                menuStaff.map((item, index) => {
                  if (
                    item.key === "pointReception" ||
                    item.key === "staffBookingManual"
                  )
                    return null;
                  if (item.menuItem) {
                    return (
                      <Menu.SubMenu
                        key={`${item}`}
                        title={item.title}
                        icon={item.icon}
                      >
                        {item.menuItem.map((menu) => {
                          return (
                            <Menu.Item
                              key={menu.key}
                              icon={menu.icon}
                              className="menu-item2"
                            >
                              <span>{menu.title}</span>
                              <Link to={menu.linkTo} />
                            </Menu.Item>
                          );
                        })}
                      </Menu.SubMenu>
                    );
                  }
                  if (isLoading || isBookingEnable) {
                    if (
                      item.key === "staffScheduleManual" ||
                      item.key === "staffBookingManual" ||
                      item.key === "pointReception"
                    )
                      return null;
                  }
                  if (item.key === "staffScheduleManual") return null;
                  return (
                    <Menu.Item
                      key={item.key}
                      icon={item.icon}
                      className="menu-item-staff"
                    >
                      <span>{item.title}</span>
                      <Link to={item.linkTo} />
                    </Menu.Item>
                  );
                })}
              <Menu.Item
                key="logout2"
                icon={Svgs.logout()}
                className="menu-item-logout"
                onClick={handleLogout}
              >
                <span>{STRINGS.logout}</span>
              </Menu.Item>
            </Menu>
          )}
          {(userAdmin.type == "SYSTEM_ADMIN" ||
            userAdmin.type == "STORE_ADMIN") && (
            <div style={{ position: "absolute", right: -24, top: 0 }}>
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ fontSize: 22 }}
                  onClick={() => MenuCollapsingService.set()}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ fontSize: 22 }}
                  onClick={() => MenuCollapsingService.set()}
                />
              )}
            </div>
          )}
        </Sider>
        <Layout className="content-layout">
          <Content>
            <Routes>
              {allowedRoutes.current.map((item, index) => {
                const Component = item.component;
                return (
                  <Route
                    key={"" + index}
                    exact
                    path={`${item.url}`}
                    element={<Component />}
                  />
                );
              })}
              <Route exact path={"/profile"} element={<ProfileDetail />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
});

export default Main;
