import React, { useRef, useState, memo } from "react";
import { Table, Select, Input, Col, Space, Row, message } from "antd";
import {
  SearchOutlined,
  CaretDownOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  QuestionOutlined,
} from "@ant-design/icons";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";

import { FetchApi } from "../../api";
import {
  AlertService,
  ButtonCustom,
  Convert,
  FilterByStore,
  LoadingView,
  ModalCustomService,
  SvgView,
} from "../../elements";
import { COLORS, Svgs, STRINGS, LOGIC } from "../../constants";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";
import { UserTypeExplain } from "./items/UserTypeExplain";
import Edit from "./items/Edit";
import Auth from "../../store/Authentication";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";

import "./User.scss";

const { Option } = Select;

const ManagerUser = memo(() => {
  const cache = useQueryClient();

  const genderOptions = [
    { name: STRINGS.all, id: null },
    { name: STRINGS.male, id: "male" },
    { name: STRINGS.female, id: "female" },
  ];

  // const [hoverID, setHoverID] = useState();
  const [sort, setSort] = useState();

  const genderSelected = useRef(null);
  const storeSelected = useRef(null);
  const searchText = useRef(null);
  const customerType = useRef(null);

  const page = useRef(1);
  const size = useRef(10);
  const timeout = useRef();
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const { data, refetch, status } = useQuery([`getUsers-${sort}`], () => {
    return FetchApi.getUserApp({
      size: size.current,
      page: page.current,
      sex: genderSelected.current,
      customerType: customerType.current,
      storeId:
        userAdmin && userAdmin.type === "SYSTEM_ADMIN"
          ? storeSelected.current
          : userAdmin.storeId,
      search: searchText.current,
      sort: sort,
    });
  });

  const { data: dataStore } = useQuery([`getStoreList`], () =>
    FetchApi.getAllStoreFilter(1, 50)
  );
  const convertData = (data) => {
    if (data) {
      const notDeleted = data.filter((item) => !item.deleted);
      const isDeleted = data.filter((item) => item.deleted);
      const newData = notDeleted.concat(isDeleted);
      return newData;
    }
    return data;
  };

  useScrollByMouse(data);

  let listStore = [];
  if (dataStore?.data) {
    listStore = [{ name: STRINGS.all, id: null }, ...dataStore.data];
  }
  const columns = [
    {
      title: "No",
      key: "index",
      dataIndex: "index",
      // fixed: "left",
      width: 70,
      sorter: (a, b) => a.index - b.index,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "ID",
      dataIndex: "customerId",
      key: "customerId",
      width: 150,
      sorter: (a, b) => a.customerId - b.customerId,
    },
    {
      title: STRINGS.name,
      dataIndex: "name",
      key: "name",
      width: 120,
      render: (e) => Convert.removeSpaceName(e),
    },
    {
      title: STRINGS.furigana_name,
      dataIndex: "furiganaName",
      key: "furiganaName",
      width: 120,
      render: (e) => Convert.removeSpaceName(e),
    },
    {
      title: STRINGS.phone_number,
      dataIndex: "phoneNumber",
      key: "phone",
      width: 150,
    },
    {
      title: STRINGS.created_time,
      key: "createdTime",
      dataIndex: "createdTime",
      render: (createdTime) =>
        createdTime ? moment(createdTime).format(LOGIC.timeFormat) : "",
      sorter: (a, b) =>
        moment(a.createdTime).format("x") - moment(b.createdTime).format("x"),
      width: 150,
    },
    {
      title: STRINGS.address,
      dataIndex: "address",
      key: "address",
      width: 150,
    },
    {
      title: STRINGS.gender,
      key: "sex",
      dataIndex: "sex",
      render: (item) =>
        item === "male"
          ? STRINGS.male
          : item === "female"
          ? STRINGS.female
          : "",
      width: 80,
    },
    {
      title: STRINGS.store,
      key: "storeId",
      dataIndex: "storeId",
      render: (storeId) => {
        if (storeId) {
          const storeOfUser = listStore.find((x) => x.id === storeId);
          return storeOfUser?.name || "";
        }
        return STRINGS.all;
      },
      width: 150,
    },
    {
      title: STRINGS.email,
      key: "email",
      dataIndex: "email",
      width: 250,
    },
    {
      title: () => (
        <Row style={{ alignItems: "center" }}>
          {STRINGS.user_type}
          <QuestionOutlined
            onClick={() => {
              ModalCustomService.set({
                title: STRINGS.user_type,
                wrapperStyle: { maxWidth: 760 },
                titleStyle: {
                  textAlign: "left",
                  fontWeight: "bold",
                  fontSize: 20,
                },
                content: <UserTypeExplain />,
              });
            }}
            style={{
              fontSize: 18,
              color: "white",
              backgroundColor: "red",
              borderRadius: 100,
              padding: 4,
              marginLeft: 5,
            }}
          />
        </Row>
      ),
      dataIndex: "customerType",
      key: "customerType",
      render: (customerType, record) => {
        if (record.banned) {
          return STRINGS.customer_type_new.X;
        }
        return STRINGS.customer_type_new[customerType];
      },

      width: 150,
    },
    {
      title: STRINGS.birthday,
      dataIndex: "birthday",
      key: "birthday",
      render: (birthday) => {
        if (moment(birthday).isValid()) {
          return moment(birthday).format("MM/DD/YYYY");
        }
      },
      width: 120,
    },
    {
      title: STRINGS.NG_list_user,
      dataIndex: "staffIdsNG",
      key: "staffIdsNG",
      render: (staffIdsNG) => {
        if (Array.isArray(staffIdsNG)) {
          return staffIdsNG.map((item, index) => {
            if (index === staffIdsNG.length - 1) {
              return <span key={`${index}`}>{item}</span>;
            }
            return <span key={`${index}`}>{item}, </span>;
          });
        }
      },
      width: 120,
    },
    {
      title: STRINGS.info_background_disease,
      dataIndex: "backgroundDisease",
      key: "backgroundDisease",
      width: 120,
    },
    {
      title: STRINGS.cancellation_charge,
      dataIndex: "cancelFee",
      key: "cancelFee",
      render: (e) => e || "ー",
      width: 120,
    },
    {
      title: "",
      key: "action",
      dataIndex: "action",
      render: (text, record) => {
        if (record.deleted) {
          return;
        }
        return (
          <Space size={10}>
            <button
              style={{
                border: 0,
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              onClick={onEdit(record)}
            >
              <SvgView svgData={Svgs.edit} />
            </button>
            <button
              className="button-delete"
              style={{
                border: 0,
                backgroundColor: "transparent",
                ":hover": {
                  color: "#ffffff",
                  cursor: "pointer",
                },
              }}
              onClick={removeUser(record)}
            >
              <SvgView svgData={Svgs.delete} />
            </button>
          </Space>
        );
      },
      width: 100,
      fixed: "right",
    },
  ];

  const setData = () => {
    cache.refetchQueries(`getUsers-${sort}`);
  };
  const onEdit = (record) => () => {
    ModalCustomService.set({
      title: STRINGS.update,
      wrapperStyle: { maxWidth: 720 },
      content: (
        <Edit data={data} setData={setData} item={record} stores={listStore} />
      ),
    });
  };

  const removeUser = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${item.name}${STRINGS.confirm_delete_user}`,
      onConfirm: async () => {
        let result = await FetchApi.deActiveUserApp(item.id, item.email);
        if (result.success) {
          setData();
          message.success(STRINGS.delete_success);
        } else {
          message.error(result.message || STRINGS.delete_failed);
        }
      },
    });
  };

  const onSearchByGender = (e) => {
    genderSelected.current = e;
    page.current = 1;
    refetch();
  };
  const onChangeCustomerType = (e) => {
    customerType.current = e;
    page.current = 1;
    refetch();
  };
  const onSearchByStore = (e) => {
    storeSelected.current = e;
    page.current = 1;
    refetch();
  };

  const onSearchByText = (text) => {
    searchText.current = encodeURI(text.target.value);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      if (text === "") {
        searchText.current = null;
      }
      page.current = 1;
      refetch();
    }, 800);
  };

  return (
    <div className="manager-user">
      <Row className="header-user" align="bottom" justify="space-between">
        <Row align="bottom">
          <Col style={{ marginRight: 10 }}>
            <div className="title">{STRINGS.gender}</div>
            <Select
              suffixIcon={
                <CaretDownOutlined style={{ color: COLORS.border_6 }} />
              }
              defaultValue={genderOptions[0].name}
              style={{ width: 180 }}
              onSelect={onSearchByGender}
            >
              {genderOptions.map((item, index) => (
                <Option key={"" + item.id}>{item.name}</Option>
              ))}
            </Select>
          </Col>
          <Col style={{ marginRight: 10 }}>
            <div className="title">{STRINGS.user_type}</div>
            <Select
              suffixIcon={
                <CaretDownOutlined style={{ color: COLORS.border_6 }} />
              }
              defaultValue={STRINGS.all}
              style={{ width: 180 }}
              onSelect={onChangeCustomerType}
            >
              {[
                {
                  type: STRINGS.all,
                  id: null,
                },
                ...STRINGS.userTypeExplain,
              ].map((item) => (
                <Option key={"" + item.id}>{item.type}</Option>
              ))}
            </Select>
          </Col>
          {!!userAdmin && userAdmin.type === "SYSTEM_ADMIN" && (
            <FilterByStore
              title={STRINGS.store}
              style={{ width: 180 }}
              onSearchByStore={onSearchByStore}
              listStore={listStore}
            />
          )}
          <Col style={{ marginRight: 10 }}>
            <ButtonCustom
              onClick={() => setSort((prev) => (prev ? null : "1"))}
            >
              <Row style={{ alignItems: "center" }}>
                {STRINGS.sorting}
                <ArrowUpOutlined style={{ marginLeft: 2 }} />
                <ArrowDownOutlined />
              </Row>
            </ButtonCustom>
          </Col>
          {userAdmin && userAdmin.type === "SYSTEM_ADMIN" && (
            <ButtonCustom
              onClick={() =>
                FetchApi.downloadUsers({
                  sex: genderSelected.current,
                  storeId: storeSelected.current,
                  search: searchText.current,
                  sort: sort,
                })
              }
            >
              {STRINGS.download}
            </ButtonCustom>
          )}
        </Row>
        <Col xl={8} md={12} xs={24} style={{ marginTop: 10 }}>
          <div className="title"></div>
          <Input
            style={{ width: "100%" }}
            placeholder={STRINGS.search_user_placeholder}
            prefix={<SearchOutlined />}
            onChange={onSearchByText}
          />
        </Col>
      </Row>
      <div className="content-user">
        {status === "loading" ? (
          <LoadingView />
        ) : (
          <>
            <Table
              rowKey={"id"}
              columns={columns}
              dataSource={data ? convertData(data?.data) : []}
              locale={{ emptyText: STRINGS.no_data }}
              pagination={false}
              rowClassName={(record) => record.deleted && "disabled-row"}
              scroll={{ x: 1650, y: "calc(100vh - 330px)" }}
              // onRow={(record) => {
              //   return {
              //     onMouseEnter: (e) => {
              //       setHoverID(record.id);
              //     },
              //   };
              // }}
            />

            <PaginationWithSizeChange
              size={size.current}
              total={data?.total}
              onChange={(pageNumber) => {
                page.current = pageNumber;
                refetch();
              }}
              onShowSizeChange={(e) => {
                size.current = e.size;
                page.current = 1;
                refetch();
              }}
              current={page.current}
            />
          </>
        )}
      </div>
    </div>
  );
});

export default ManagerUser;
