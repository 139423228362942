/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { Col, message, Space, Table } from "antd";
import { useQuery } from "react-query";
import moment from "moment";

import { FetchApi } from "../../../api";
import { STRINGS, LOGIC, COLORS } from "../../../constants";
import {
  AlertService,
  ButtonCustom,
  Convert,
  ErrorView,
  LoadingView,
} from "../../../elements/";
import { CommonFunction } from "../../../util/CommonFunction";
import { useScrollByMouse } from "../../../util/hooks/useScrollByMouse";
import { PointReceptionService } from "../services/PointReceptionService";
import PaginationWithSizeChange from "../../../elements/PaginationWithSizeChange";

import "../../../util/variables.scss";

const GrantPoints = () => {
  const page = useRef(1);
  const size = useRef(10);
  const [loadingTable, setLoadingTable] = useState(false);

  const { data, refetch, isLoading } = useQuery(["GrantPointsTab"], () =>
    FetchApi.getRequestPointByStore({
      size: size.current,
      page: page.current,
    })
  );
  useScrollByMouse(data);

  useEffect(() => {
    const timer = setInterval(() => {
      refetch();
    }, 5000);
    return () => {
      timer && clearInterval(timer);
    };
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "customerId",
      key: "customerId",
      align: "center",
    },
    {
      title: STRINGS.user_name,
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      render: (e) => Convert.removeSpaceName(e),
    },
    {
      title: STRINGS.staff_in_charge,
      dataIndex: "staffName",
      key: "staffName",
      align: "center",
    },
    {
      title: STRINGS.time_of_visit,
      dataIndex: "timeConfirm",
      key: "timeConfirm",
      render: (timeConfirm) =>
        timeConfirm && moment(timeConfirm).format(LOGIC.timeFormat),
      align: "center",
    },
    {
      title: STRINGS.type,
      dataIndex: "pointType",
      key: "pointType",
      render: (pointType) => {
        if (pointType === "check_in_point") {
          return (
            <div style={{ color: COLORS.accent }}>{STRINGS.grant_points}</div>
          );
        }
        return (
          <div style={{ color: COLORS.primary }}>{STRINGS.use_points}</div>
        );
      },
      align: "center",
    },
    {
      title: STRINGS.points,
      dataIndex: "numberOfPoints",
      key: "numberOfPoints",
      align: "center",
    },
    {
      key: "action",
      render: (text, record, index) => {
        return (
          <Space>
            <ButtonCustom
              onClick={onGrantPoints(record)}
              style={{
                backgroundColor:
                  record.pointType === "check_in_point"
                    ? COLORS.accent
                    : COLORS.primary,
                borderColor:
                  record.pointType === "check_in_point"
                    ? COLORS.accent
                    : COLORS.primary,
                marginRight: 10,
                fontSize: 13,
                minWidth: 100,
              }}>
              <div>
                {record.pointType === "check_in_point"
                  ? STRINGS.grant_points
                  : STRINGS.use_points}
              </div>
            </ButtonCustom>
            <ButtonCustom
              onClick={onDenyPoints(record)}
              isCancelButton
              style={{
                fontSize: 13,
                minWidth: 100,
              }}>
              <div>{STRINGS.reject}</div>
            </ButtonCustom>
          </Space>
        );
      },
      fixed: "right",
      width: 250,
    },
  ];

  const onGrantPoints = (record) => () => {
    AlertService.set({
      textConfirm: STRINGS.confirm,
      title: STRINGS.confirm,
      content: `${
        record.pointType === "check_in_point"
          ? STRINGS.grant_notice
          : STRINGS.use_notice
      }`,
      onConfirm: async () => {
        try {
          setLoadingTable(true);
          let result;
          if (record.pointType === "check_in_point") {
            result = await FetchApi.confirmRequestCheckInPoint(record.id);
          }
          if (record.pointType === "use_point") {
            result = await FetchApi.confirmRequestUsePoint(record.id);
          }
          if (result.success) {
            PointReceptionService.set(result);
            message.success(STRINGS.done_successful);
            await refetch();
            setLoadingTable(false);
          } else {
            message.error(CommonFunction.textViewError(result));
            setLoadingTable(false);
          }
        } catch (error) {
          setLoadingTable(false);
          message.error(CommonFunction.textViewError(error));
        }
      },
    });
  };

  const onDenyPoints = (record) => () => {
    AlertService.set({
      textConfirm: STRINGS.confirm,
      title: STRINGS.confirm,
      content: `${STRINGS.reject_notice}`,
      onConfirm: async () => {
        setLoadingTable(true);
        let result;
        if (record.pointType === "check_in_point") {
          result = await FetchApi.denyRequestCheckInPoint(record.id);
        }
        if (record.pointType === "use_point") {
          result = await FetchApi.denyRequestUsePoint(record.id);
        }
        if (result.success) {
          PointReceptionService.set(result);
          message.success(STRINGS.done_successful);
          await refetch();
          setLoadingTable(false);
        } else {
          message.error(CommonFunction.textViewError(result));
          setLoadingTable(false);
        }
      },
    });
  };

  if (isLoading) {
    return <LoadingView />;
  }

  if (!data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Col>
      <Table
        loading={loadingTable}
        rowKey={(record) => record.id + record.pointType}
        columns={columns}
        dataSource={data?.pointRequestDTOS}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
        scroll={{ x: 1200, y: "calc(100vh - 350px)" }}
      />
      <PaginationWithSizeChange
        size={size.current}
        total={data?.pageDto?.totalElements || 0}
        onChange={(pageNumber) => {
          page.current = pageNumber;
          refetch();
        }}
        onShowSizeChange={(e) => {
          size.current = e.size;
          page.current = 1;
          refetch();
        }}
        current={page.current}
      />
    </Col>
  );
};

export default GrantPoints;
