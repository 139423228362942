import React from "react";
import { Col, Row } from "antd";
import moment from "moment";

import { COLORS, STRINGS } from "../../../constants";
import { CommonFunction } from "../../../util/CommonFunction";

import "../ModalConfirmRevenue.scss";

function RevenueItem({ data, numberOrdinal }) {
  return (
    <>
      <Col span={8}>
        <Row style={{ placeContent: "center" }}>
          <Col
            style={{
              borderRadius: 16,
              border: `2px solid ${COLORS.accent}`,
              width: 32,
              height: 32,
              lineHeight: "30px",
              textAlign: "center",
              color: COLORS.accent,
              fontWeight: "bold",
            }}>
            {numberOrdinal}
          </Col>
          <Col
            style={{
              color: COLORS.accent,
              fontWeight: "bold",
              paddingLeft: 10,
              wordWrap: "break-word",
            }}>
            {data.staffName}
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row>
          <Col style={{ width: 120, marginBottom: 10 }} className="label-base">
            {STRINGS.time_confirm_revenue}
          </Col>
          <Col className="value-base">
            {data.confirmTime && moment(data.confirmTime).format("HH:mm")}
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 120, marginBottom: 10 }} className="label-base">
            {STRINGS.pay_credit}
          </Col>
          <Col className="value-base">
            {CommonFunction.currencyFormat(0) + STRINGS.yen}
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 120 }} className="label-base">
            {STRINGS.remarks}
          </Col>
          <Col
            className="value-base"
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
            {data.commentFromConfirmedStaff}
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row>
          <Col style={{ width: 120, marginBottom: 10 }} className="label-base">
            {STRINGS.total_money2}
          </Col>
          <Col className="value-base">
            {CommonFunction.currencyFormat(data.totalRevenue) + STRINGS.yen}
          </Col>
        </Row>
        <Row>
          <Col style={{ width: 120 }} className="label-base">
            {STRINGS.pay_cash}
          </Col>
          <Col className="value-base">
            {CommonFunction.currencyFormat(data.totalRevenue) + STRINGS.yen}
          </Col>
        </Row>
      </Col>
    </>
  );
}

export function NewestConfirmRevenue({ data = [] }) {
  return (
    <Col style={{ paddingBottom: 50 }}>
      {data.map((i, index) => {
        return (
          <Row
            key={`${i.id}`}
            style={{
              paddingTop: 30,
            }}>
            <RevenueItem data={i} numberOrdinal={index + 1} />
          </Row>
        );
      })}
    </Col>
  );
}
