import { useQuery } from "react-query";
import { FetchApi } from "../../api";

const useGetListSkill = () => {
  const { data, isLoading, refetch, isFetching } = useQuery(
    [`get-list-skill`],
    () => FetchApi.getListSkill()
  );
  return {
    listSkill: data?.skillDTOS || [],
    refetchListSkill: refetch,
    isLoadingListSkill: isLoading,
    isFetchingListSkill: isFetching,
  };
};

export { useGetListSkill };
