/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Col, Row } from "antd";
import { useQuery } from "react-query";
import moment from "moment";

import { COLORS, LOGIC, STRINGS } from "../../../../constants";
import { Validate } from "../../../../elements";
import { FetchApi } from "../../../../api";

function AssignStaffRender({ data = {} }) {
  if (data.assignedSex) {
    return (
      <Col
        span={16}
        style={{
          fontWeight: "bold",
          color: COLORS.gender_staff[data.assignedSex],
          fontSize: 14,
        }}>
        {STRINGS.staff_gender[data.assignedSex]}
      </Col>
    );
  }
  if (!!data?.chooseStaff) {
    return (
      <Col
        style={{
          width: 14,
          height: 14,
          borderRadius: 7,
          borderWidth: 1,
          borderColor: COLORS.primary,
          borderStyle: "solid",
          marginTop: 5,
        }}
      />
    );
  }
}

function StaffBookingCustomerInfo({ value = {} }) {
  const { data } = useQuery(
    [`infoCustomer-${value.id}`],
    () =>
      FetchApi.historyUserBooking({
        page: 1,
        size: 3,
        userId: value.id,
      }),
    {
      enabled: !!value.id,
    }
  );
  const dataInfo = [
    {
      label: STRINGS.name_info_booking,
      value: value.name || "ー",
    },
    {
      label: STRINGS.phone_number_2,
      value: value.phoneNumber
        ? Validate.convertPhoneJp(value.phoneNumber)
        : "ー",
    },
    {
      label: STRINGS.member,
      value: value.banned
        ? STRINGS.customer_type_new.X
        : STRINGS.customer_type_new[value.customerType] || "ー",
    },

    {
      label: "NG",
      value: value.staffNamesNG || "ー",
    },
    {
      label: STRINGS.cancellation_charge,
      value: value.cancelFee ? LOGIC.currencyFormatter(value.cancelFee) : "ー",
    },
  ];

  const renderValue = ({ item, color }) => {
    if (Array.isArray(item.value)) {
      return (
        <>
          {item.value.map((itm, index) => (
            <Row
              key={`${index}`}
              style={{
                fontWeight: "bold",
                color: COLORS.sub_accent,
              }}>
              {itm}
              {index !== item.value.length - 1 ? ",\xa0" : ""}
            </Row>
          ))}
        </>
      );
    }

    return (
      <Col style={{ color: color || COLORS.sub_accent }}>{item.value}</Col>
    );
  };

  return (
    <Col style={{ width: "100%", paddingBottom: 70 }}>
      <Row style={{ flexGrow: 1 }}>
        <Col span={12}>
          {dataInfo.map((item) => {
            let color;
            if (item.label === STRINGS.member) {
              if (value.banned) {
                color = LOGIC.colorCustomerType.X;
              } else {
                color = LOGIC.colorCustomerType[value.customerType];
              }
            }
            if (item.label === STRINGS.cancellation_charge) {
              if (value.cancelFee) {
                color = "red";
              }
            }
            return (
              <Row
                key={`${item.label}`}
                style={{
                  textAlign: "left",
                  marginBottom: 4,
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: 23,
                  color: "black",
                  overflow: "hidden",
                }}>
                <Col span={8} style={{ color: COLORS.sub_accent }}>
                  {item.label}
                </Col>
                {renderValue({ item, color })}
              </Row>
            );
          })}
        </Col>
        <Col span={12}>
          <Row
            style={{
              justifyContent: "center",
              color: COLORS.sub_accent,
              backgroundColor: COLORS.accent_3,
              padding: 5,
              fontWeight: "bold",
              fontSize: 18,
            }}>
            {STRINGS.history_go_store}
          </Row>
          {data?.orderHistoryDTOS?.map?.((item, index) => {
            return (
              <Col
                key={`${index}`}
                className="row-table-custom"
                style={{
                  paddingTop: 10,
                  color: COLORS.sub_accent,
                  fontWeight: "bold",
                }}>
                <Row>
                  <Col span={7}>
                    {moment(item.startTime).format(LOGIC.dateFormat)}
                  </Col>
                  <Col span={12}>
                    {Array.isArray(item.comboOrders) &&
                    item.comboOrders.length > 0
                      ? item.comboOrders.map(
                          (itm, idx, arr) =>
                            `${itm.comboName || "none"} (${
                              itm.minutes || "none"
                            })` + (idx !== arr.length - 1 ? ", " : "")
                        )
                      : ""}
                  </Col>
                  <Col span={3}>{item.staffName}</Col>

                  <Col span={2}>
                    <AssignStaffRender data={item} />
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Col>
      </Row>
    </Col>
  );
}

export { StaffBookingCustomerInfo };
