/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Form, Row, Select } from "antd";

import { COLORS, STRINGS } from "../../../constants";
import { SuffixIconDown, Validate } from "../../../elements";

function SelectMemberTypeApply({ value, onChange }) {
  return (
    <Select
      style={{
        textAlign: "left",
        width: "fit-content",
        minWidth: "100%",
        whiteSpace: "pre-line",
        borderRadius: 5,
        color: COLORS.sub_accent,
        fontWeight: "900",
      }}
      allowClear
      mode="multiple"
      value={value}
      suffixIcon={<SuffixIconDown />}
      onChange={onChange}>
      {Object.keys(STRINGS.customer_type_new).map((item) => {
        // if (item === "X") {
        //   return null;
        // }
        return (
          <Select.Option
            key={item}
            value={item}
            style={{
              height: 60,
              color: COLORS.sub_accent,
              fontWeight: "bold",
            }}>
            <Row style={{ flexWrap: "wrap", whiteSpace: "pre-line" }}>
              {STRINGS.customer_type_new[item]}
            </Row>
          </Select.Option>
        );
      })}
    </Select>
  );
}

export function CouponMemberType({ form }) {
  return (
    <Form.Item
      label={
        <Row>
          {STRINGS.coupon_apply_member}
          <div style={{ color: COLORS.required_2 }}>*</div>
        </Row>
      }
      name="customerTypesApply"
      rules={[Validate.emptyContent]}>
      <SelectMemberTypeApply />
    </Form.Item>
  );
}
