import React, { useState, useRef } from "react";
import { DatePicker, Form, Input, message, Row, Select } from "antd";
import moment from "moment";

import { FetchApi } from "../../../../api";
import {
  LOGIC,
  NewProvince,
  Province,
  STRINGS,
  Svgs,
} from "../../../../constants";
import {
  ButtonCustom,
  ModalCustomService,
  SuffixIconDown,
  Validate,
} from "../../../../elements";
import RadioCustom from "../../../staff-schedule/elements/RadioCustom";

export function ModalRegCustomerBooking({ data, onSave, bookingId }) {
  const [form] = Form.useForm();
  const [provinceFilter, setProvinceFilter] = useState(
    Province.filter((_item, _index) => {
      return _item.cityId == data.cityId;
    })
  );
  const oldCustomerType = useRef(data.customerType);
  const renderLabel = (label) => (
    <Row style={{ color: "black", fontWeight: "bold" }}>{label}</Row>
  );

  const onConfirm = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      let nameCity = "";
      let nameDistrict = "";
      fieldData.banned = false;
      if (!data.id) {
        delete fieldData.bookingId;
      }
      if (fieldData.cityId) {
        nameCity = NewProvince.find((i) => i.id === fieldData.cityId)?.name1;
      }
      if (fieldData.districtId) {
        nameDistrict = provinceFilter.find(
          (i) => i.id === fieldData.districtId
        )?.name1;
      }
      if (fieldData.customerType === "X") {
        fieldData.banned = true;
        fieldData.customerType = oldCustomerType.current;
        // delete fieldData.customerType;
      }
      let birthday;
      if (fieldData.birthday) {
        birthday = moment(fieldData.birthday).format("MM/DD/YYYY");
      }

      const result = await FetchApi.createUserNonApp({
        ...fieldData,
        birthday: birthday,
        address: `${nameCity} ${nameDistrict}`,
      });
      if (result.success) {
        if (onSave && bookingId) {
          await onSave({ customerUserId: result.user.id });
        }
        message.success(STRINGS.saved_common);
        ModalCustomService.close();
        return;
      }
      if (result.code === 3) {
        message.error(STRINGS.phone_is_existed);
        return;
      }
      message.error("error");
    } catch (error) {
      message.warning(STRINGS.please_fill_required_fields);
    }
  };
  let enabledItem = [
    { label: STRINGS.customer_type_new.D, value: "D" },
    // { label: STRINGS.customer_type_new.A, value: "A" },
    { label: STRINGS.customer_type_new.S, value: "S" },
    { label: STRINGS.customer_type_new.P, value: "P" },
    { label: STRINGS.customer_type_new.R, value: "R" },
    { label: STRINGS.customer_type_new.X, value: "X" },
  ];
  // const rulesName = [
  //   ({getFieldValue}) => ({
  //     validator(rule, value) {
  //       const regex = /^\s+$/;

  //       if (
  //         getFieldValue('customerType') === 'P' ||
  //         data.customerType !== 'R'
  //       ) {
  //         if (
  //           value === undefined ||
  //           value === null ||
  //           value.length === 0 ||
  //           regex.test(value)
  //         ) {
  //           return Promise.reject(STRINGS.this_field_is_required);
  //         }

  //         return Promise.resolve();
  //       }
  //       return Promise.resolve();
  //     },
  //   }),
  // ];
  const rulesPhone = [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        const regex = /^\s+$/;
        // const re =
        //   /^(?:(0([7,9,5]{1})0([0-9]{8}))|(0([8]{1})0([1-9]{1}[0-9]{7})))$/;
        const re = /^0[0-9]{9,10}$/;
        if (
          getFieldValue("customerType") === "P" ||
          data.customerType !== "R"
        ) {
          if (
            value === undefined ||
            value === null ||
            value.length === 0 ||
            regex.test(value)
          ) {
            return Promise.reject(STRINGS.this_field_is_required);
          }
          if (!re.test(value)) {
            return Promise.reject(STRINGS.number_please);
          }
          return Promise.resolve();
        }
        return Promise.resolve();
      },
    }),
  ];
  const rulesFurigana = [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        const regex = /^\s+$/;
        const re = /^[ぁ-ん]+$/;
        if (
          getFieldValue("customerType") === "P" ||
          data.customerType !== "R"
        ) {
          if (
            value === undefined ||
            value === null ||
            value.length === 0 ||
            regex.test(value)
          ) {
            return Promise.reject(STRINGS.this_field_is_required);
          } else if (!re.test(value)) {
            return Promise.reject(STRINGS.please_no_space);
          } else {
            return Promise.resolve();
          }
        }
        return Promise.resolve();
      },
    }),
  ];

  return (
    <Form
      className="modal-infor-customer"
      layout="vertical"
      form={form}
      initialValues={{
        customerPhone: data?.customerPhone,
      }}
      onValuesChange={(e) => {
        if (e.cityId) {
          const a = Province.filter((_item, _index) => {
            return _item.cityId == e.cityId;
          });
          setProvinceFilter(a);
          form.setFieldsValue({ districtId: null });
        }
      }}>
      <Form.Item
        name="customerName"
        label={renderLabel(STRINGS.name_user_booking)}
        // rules={rulesName}
      >
        <Input
          disabled={
            data.customerType === LOGIC.userType.D ||
            data.customerType === LOGIC.userType.A
          }
        />
      </Form.Item>
      <Form.Item
        name="furiganaName"
        label={renderLabel(STRINGS.furigana_name)}
        rules={rulesFurigana}
        required>
        <Input
          disabled={
            (data.customerType === LOGIC.userType.D ||
              data.customerType === LOGIC.userType.A) &&
            !!data.furiganaName
          }
        />
      </Form.Item>
      <Form.Item
        name="customerPhone"
        label={renderLabel(STRINGS.phone_number)}
        rules={rulesPhone}
        required>
        <Input
          disabled={
            data.customerType === LOGIC.userType.D ||
            data.customerType === LOGIC.userType.A
          }
        />
      </Form.Item>
      <Form.Item name="customerGender" label={renderLabel(STRINGS.gender)}>
        <RadioCustom
          disabled={
            data.customerType === LOGIC.userType.D ||
            data.customerType === LOGIC.userType.A
          }
          list={[
            { label: STRINGS.male, value: "male" },
            { label: STRINGS.female, value: "female" },
          ]}
        />
      </Form.Item>
      <Form.Item name="birthday" label={renderLabel(STRINGS.birthday)}>
        <DatePicker
          value={data.birthday}
          placeholder={STRINGS.birthday}
          suffixIcon={Svgs.datepicker()}
          style={{
            borderRadius: 5,
            padding: "6px 10px",
            height: 40,
            width: 180,
          }}
          disabledDate={(e) => {
            let customDate = moment().format("MM/DD/YYYY");
            return e && e.isAfter(moment(customDate, "MM/DD/YYYY"));
          }}
          showToday={false}
          allowClear={false}
          format={"MM/DD/YYYY"}
          picker={"date"}
          disabled={
            data.customerType === LOGIC.userType.D ||
            data.customerType === LOGIC.userType.A
          }
        />
      </Form.Item>
      <Form.Item
        name="customerType"
        label={renderLabel(STRINGS.member)}
        rules={[Validate.emptyContent]}
        initialValue={"P"}>
        <RadioCustom
          disabled={data.customerType !== "X"}
          enabledItem={enabledItem}
          list={[
            { label: STRINGS.customer_type_new.D, value: "D" },
            { label: STRINGS.customer_type_new.A, value: "A" },
            { label: STRINGS.customer_type_new.S, value: "S" },
            { label: STRINGS.customer_type_new.P, value: "P" },
            { label: STRINGS.customer_type_new.R, value: "R" },
            { label: STRINGS.customer_type_new.X, value: "X" },
          ]}
          style={{ marginBottom: 10 }}
        />
      </Form.Item>
      <Form.Item name="cityId" label={renderLabel(STRINGS.city)}>
        <Select
          suffixIcon={<SuffixIconDown />}
          placeholder={STRINGS.city}
          disabled={
            (data.customerType === LOGIC.userType.D ||
              data.customerType === LOGIC.userType.A) &&
            !!data.cityId
          }>
          {NewProvince.map((itm, idx) => {
            return (
              <Select.Option key={itm.id} value={itm.id}>
                {itm.name1}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="districtId" label={renderLabel(STRINGS.province)}>
        <Select
          suffixIcon={<SuffixIconDown />}
          placeholder={STRINGS.province}
          disabled={
            (data.customerType === LOGIC.userType.D ||
              data.customerType === LOGIC.userType.A) &&
            !!data.districtId
          }>
          {provinceFilter.map((itm, idx) => {
            return (
              <Select.Option key={itm.id} value={itm.id}>
                {itm.name1}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      {/* <Form.Item
        name="cancelFee"
        label={renderLabel(STRINGS.cancellation_charge)}
        rules={[Validate.isPositiveNumberWithoutRequired]}
      >
        <Input />
      </Form.Item> */}
      <Form.Item
        name="backgroundDisease"
        label={renderLabel(STRINGS.past_medical_history)}>
        <Input.TextArea rows={8} />
      </Form.Item>

      <ButtonCustom
        isCancelButton
        onClick={ModalCustomService.close}
        style={{
          marginRight: 10,
        }}>
        {STRINGS.cancel}
      </ButtonCustom>
      <ButtonCustom onClick={onConfirm}>{STRINGS.sure}</ButtonCustom>
    </Form>
  );
}
