// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quill-slide {\n  height: 350px !important;\n  max-width: 600 !important;\n  background-color: white !important;\n}\n.quill-slide .ql-container {\n  height: 286px !important;\n}", "",{"version":3,"sources":["webpack://./src/components/cancel-booking/CancelBooking.scss"],"names":[],"mappings":"AACA;EACE,wBAAA;EACA,yBAAA;EACA,kCAAA;AAUF;AATE;EACE,wBAAA;AAWJ","sourcesContent":["@import \"../../util/variables.scss\";\n.quill-slide {\n  height: 350px !important;\n  max-width: 600 !important;\n  background-color: white !important;\n  .ql-container {\n    height: 286px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainColor": "#4b2a05",
	"textColor": "#f19834",
	"redColor": "#FF0C0C",
	"grey1": "#efefef",
	"grey2": "#757575",
	"black1": "#303030",
	"borderColor": "#cbcbcb"
};
export default ___CSS_LOADER_EXPORT___;
