import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, message, Row } from "antd";

import { COLORS, LOGIC, STRINGS, Svgs } from "../../../constants";
import { useGetPackStore } from "../../booking-manual-detail/modules/useGetPackStore";
import { SvgView } from "../../../elements";
import DropdownCourse from "./DropdownCourse";

function ModalBookingDetailTableCombo({
  data,
  form,
  combosPicked,
  detailCourseBooking,
}) {
  const { dataProlonged, dataCombo } = useGetPackStore({
    userId: data.user?.id,
  });
  return (
    <Row>
      <Col span={24}>
        <Row
          className="theader"
          style={{
            marginTop: 0,
            width: "100%",
            color: COLORS.sub_accent,
          }}>
          <Col span={3}></Col>
          <Col span={11} style={{ fontSize: 22 }}>
            {STRINGS.course}
          </Col>

          <Col span={4} style={{ fontSize: 22 }}>
            {STRINGS.extension}
          </Col>
          <Col span={4} style={{ fontSize: 22 }}>
            {STRINGS.amount_price}
          </Col>
        </Row>

        <Form.List name="comboDTOS">
          {(fields, { add, remove }) => {
            return (
              <Col>
                {fields.map((field, index, arr) => (
                  <Col
                    key={field.key}
                    span={24}
                    className={field.key === 0 ? "col-wrapper-row-input" : 0}>
                    <Row
                      className="row-table-custom"
                      style={{ width: "100%", color: COLORS.sub_accent }}>
                      <Col span={3}>
                        {index === 0 && !!data.minutes && (
                          <Input
                            style={{
                              borderWidth: 0,
                              backgroundColor: "white",
                              color: COLORS.sub_accent,
                              fontWeight: "bold",
                              fontSize: 18,
                            }}
                            disabled
                            value={`${data.minutes}${STRINGS.minute}`}
                          />
                        )}
                      </Col>

                      <Col span={11}>
                        <Form.Item
                          {...field}
                          name={[field.name, "comboPackId"]}
                          fieldKey={[field.fieldKey, "comboPackId"]}>
                          <DropdownCourse
                            comboDTOS={
                              dataCombo || []
                              //   .filter(
                              //   (i) => i?.minutes <= data.minutes
                              // )
                            }
                            combosPicked={combosPicked}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={4}>
                        <Form.Item
                          {...field}
                          name={[field.name, "comboExtraId"]}
                          fieldKey={[field.fieldKey, "comboExtraId"]}
                          // rules={[Validate.emptyContent]}
                        >
                          <DropdownCourse
                            comboDTOS={(dataProlonged || []).filter(
                              (i) =>
                                i.comboId ===
                                (combosPicked[index]?.comboId ||
                                  detailCourseBooking[index]?.comboId)
                            )}
                            disabled={
                              !combosPicked[index]?.id &&
                              !detailCourseBooking[index]?.comboId
                            }
                            dataRender={"minutes"}
                            combosPicked={[]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...field}
                          name={[field.name, "priceForCurrentUser"]}
                          fieldKey={[field.fieldKey, "priceForCurrentUser"]}>
                          <InputNumber
                            disabled
                            formatter={(value) =>
                              `${LOGIC.currencyYenFormatter(value)}`
                            }
                          />
                        </Form.Item>
                      </Col>

                      {index !== 0 && (
                        <Col style={{ marginTop: 5, marginLeft: 10 }}>
                          <Button
                            onClick={() => remove(field.name)}
                            style={{
                              borderWidth: 0,
                              backgroundColor: "transparent",
                              borderColor: "transparent",
                            }}>
                            <SvgView svgData={Svgs.delete} />
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                ))}

                <Row
                  onClick={() => {
                    const { comboDTOS } = form.getFieldValue();
                    if (
                      (comboDTOS || []).every(
                        (i) => !!i?.id
                        // && !!i?.comboExtraId
                      )
                    ) {
                      add();
                      return;
                    }
                    message.warning(STRINGS.this_field_is_required);
                  }}
                  className="btn-add-combo"
                  style={{
                    marginTop: 20,
                    marginLeft: 190,
                    width: "fit-content",
                  }}>
                  <PlusCircleOutlined style={{ fontSize: 26 }} />
                  <Row
                    style={{
                      color: COLORS.primary,
                      fontSize: 26,
                      fontWeight: "bold",
                    }}>
                    {STRINGS.add_math}
                  </Row>
                </Row>
              </Col>
            );
          }}
        </Form.List>
      </Col>
    </Row>
  );
}

export default ModalBookingDetailTableCombo;
