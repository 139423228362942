import React from "react";
import { STRINGS } from "../../constants";
import { ButtonCustom, Container } from "../../elements";
import Quill from "../../elements/Quill";
import "./SetTheWorkOrder.scss";
import { Form } from "antd";
import { FetchApi } from "../../api";
import { useQuery } from "react-query";

function SetTheWorkOrder() {
  const [form] = Form.useForm();
  const { data, isLoading } = useQuery(
    "getStaffStatusFlow",
    FetchApi.getStaffStatusFlow
  );
  const handleInput = async () => {
    const data = await form.getFieldValue();
    FetchApi.updateStaffStatusFlow(data.description);
  };
  const renderRight = () => {
    return (
      <div>
        <ButtonCustom
          style={{
            marginRight: 10,
            backgroundColor: "#4b2a05",
            borderColor: "#4b2a05",
          }}
          onClick={() => {
            form.resetFields();
          }}
        >
          {STRINGS.reset_field}
        </ButtonCustom>
        <ButtonCustom onClick={handleInput}>{STRINGS.confirm}</ButtonCustom>
      </div>
    );
  };
  return (
    <Container
      title={STRINGS.set_the_work_order}
      children={
        !isLoading && (
          <Form form={form}>
            <Form.Item name="description" initialValue={data?.content}>
              <Quill className="quill-slide" />
            </Form.Item>
          </Form>
        )
      }
      renderRight={renderRight}
    />
  );
}

export default SetTheWorkOrder;
