import { TimePicker } from "antd";
import React, { useState, useEffect } from "react";
import { ClockCircleFilled } from "@ant-design/icons";

export function TimePickerAutoAccept({
  value,
  onChange,
  onChangeHour,
  format,
  ...props
}) {
  const [val, setVal] = useState(value);
  useEffect(() => {
    setVal(value);
  }, [value]);
  return (
    <TimePicker
      value={val}
      suffixIcon={
        <ClockCircleFilled style={{ fontSize: 18, color: "#757575" }} />
      }
      style={{ borderRadius: 4, padding: "6px 10px", width: "90%" }}
      allowClear={false}
      format={format}
      onSelect={(e, timeString) => {
        setVal(e);
        onChange && onChange(e, timeString);
        onChangeHour && onChangeHour(e, timeString);
      }}
      {...props}
    />
  );
}
