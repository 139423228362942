import { Col, Select } from "antd";
import React from "react";

import { SuffixIconDown } from "../../../elements";

export default function SelectionListNG({ value }) {
  return (
    <Col>
      <Select
        defaultValue={value || []}
        suffixIcon={<SuffixIconDown />}
        disabled
        mode="multiple"></Select>
    </Col>
  );
}
