import React, { useRef } from "react";
import { message, Space, Table } from "antd";
import { PlusCircleOutlined, MenuOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "react-query";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import moment from "moment";
import arrayMove from "array-move";

import { FetchApi } from "../../api";
import { STRINGS, COLORS, LOGIC, Svgs } from "../../constants";
import {
  AlertService,
  Container,
  ModalCustomService,
  ErrorView,
  SvgView,
  ButtonCustom,
} from "../../elements";
import { AddPointDay } from "./items/AddPointDay";
import { EditPointDay } from "./items/EditPointDay";
import { CommonFunction } from "../../util/CommonFunction";
import { useScrollByMouse } from "../../util/hooks/useScrollByMouse";

import "./ManagerPointDay.scss";

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: COLORS.border_5 }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

export default function ManagerPointDay() {
  const page = useRef(1);
  const size = useRef(50);
  const arrayPosition = useRef();
  const cache = useQueryClient();
  const { data, refetch, isLoading } = useQuery("bookingPointList", () =>
    FetchApi.listBookingPoint({
      size: size.current,
      page: page.current,
    })
  );
  useScrollByMouse(data);

  const columnsWrapper = [
    {
      title: STRINGS.sort,
      dataIndex: "id",
      key: "id",
      render: () => <DragHandle />,
      width: 80,
      fixed: "left",
      align: "center",
    },
    {
      title: STRINGS.title,
      dataIndex: "title",
      key: "title",
      align: "center",
    },
    {
      title: STRINGS.point_have_provided,
      dataIndex: "quantity",
      key: "quantity",
      align: "center",
    },
    {
      title: STRINGS.start_date,
      dataIndex: "start",
      key: "start",
      align: "center",
      render: (e) => e && moment(e).format(LOGIC.timeFormat),
    },
    {
      title: STRINGS.end_date,
      dataIndex: "end",
      key: "end",
      align: "center",
      render: (e) => e && moment(e).format(LOGIC.timeFormat),
    },
    {
      width: 100,
      fixed: "right",
      render: (e, item) => (
        <Space size={10}>
          <button
            style={{
              border: 0,
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            onClick={onEdit(item)}>
            <SvgView svgData={Svgs.edit} />
          </button>
          <button
            style={{
              border: 0,
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            onClick={removeItem(item)}>
            <SvgView svgData={Svgs.delete} />
          </button>
        </Space>
      ),
    },
  ];

  const onAdd = () => {
    ModalCustomService.set({
      title: STRINGS.add_new,
      wrapperStyle: { maxWidth: 500 },
      contentStyle: { maxHeight: "70vh" },
      content: <AddPointDay />,
    });
  };
  const onEdit = (record) => () => {
    ModalCustomService.set({
      title: STRINGS.update,
      wrapperStyle: { maxWidth: 500 },
      contentStyle: { maxHeight: "70vh" },
      content: <EditPointDay data={record} />,
    });
  };

  const removeItem = (item) => () => {
    AlertService.set({
      title: STRINGS.confirm,
      textConfirm: STRINGS.delete,
      content: `${STRINGS.confirm_delete_common}`,
      onConfirm: async () => {
        let result = await FetchApi.deleteBookingPoint(item.id);
        if (result.success) {
          refetch();
          message.success(STRINGS.deleted_common);
        } else {
          CommonFunction.logError(result);
        }
      },
    });
  };
  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(data.bookingPoints),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      cache.setQueryData("bookingPointList", (oldData) => {
        return {
          ...oldData,
          bookingPoints: newData?.map?.((i, index) => {
            return { ...i, position: index };
          }),
        };
      });
      arrayPosition.current = [];
      for (let index = 0; index < newData.length; index++) {
        const dataCvt = { bookingPointId: newData[index].id, position: index };
        arrayPosition.current.push(dataCvt);
      }
      const result = await FetchApi.changePositionBookingPoint({
        positions: arrayPosition.current,
      });

      if (result.success) {
        message.success(STRINGS.change_position_success);
      } else {
        message.error(result.message);
      }
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="sortable-list-tab"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    if (data) {
      const index = data.bookingPoints?.findIndex(
        (x) => x.id === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
    } else {
      return <></>;
    }
  };

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Container
      className="point-day-master"
      title={STRINGS.config_the_point}
      renderRight={() => (
        <ButtonCustom onClick={onAdd} icon={<PlusCircleOutlined />}>
          {STRINGS.add_new}
        </ButtonCustom>
      )}>
      <Table
        loading={isLoading}
        rowKey={"id"}
        columns={columnsWrapper}
        dataSource={
          data?.bookingPoints?.sort((a, b) => a.position - b.position) || []
        }
        pagination={false}
        scroll={{ y: "calc(100vh - 300px)" }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
      {/* <PaginationWithSizeChange
        size={size.current}
        total={data ? data.total : 0}
        onChange={(pageNumber) => {
          page.current = pageNumber;
          refetch();
        }}
        onShowSizeChange={(e) => {
          size.current = Number(e.size);
          page.current = 1;
          refetch();
        }}
        current={page.current}
      /> */}
    </Container>
  );
}
