import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { FetchApi } from "../../../../api";
import BodyTopCheckedOut from "./BodyTopCheckedOut";
import BodyTopNotCheckOutYet from "./BodyTopNotCheckOutYet";

export default function BodyTop({
  staffInfo,
  shiftStatus,
  scheduleSelected,
  setScheduleSelected,
  refetchCalendarOrder,
  disabled,
  dataStaffResting,
  selectedDate,
}) {
  const { data } = useQuery("staffWorkStatistics", () =>
    FetchApi.getStaffWorkStatistics({
      day: moment(selectedDate).format("MM/DD/YYYY"),
      staffId: staffInfo.staffId,
    })
  );
  const properties = {
    staffInfo,
    shiftStatus,
    scheduleSelected,
    setScheduleSelected,
    refetchCalendarOrder,
    disabled,
    dataStaffResting,
    data: data?.staffOrderDTO,
    selectedDate,
  };
  if (staffInfo.shiftStatus === "unavailable") {
    return <BodyTopCheckedOut {...properties} />;
  }
  return <BodyTopNotCheckOutYet {...properties} />;
}
