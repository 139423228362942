import React from "react";
import { Col, Form, Input, message, Row } from "antd";
import { useQueryClient } from "react-query";
import moment from "moment";

import { COLORS, STRINGS } from "../../constants";
import { ButtonCustom, ModalCustomService } from "../../elements";
import { FetchApi } from "../../api";
import { useChangeStaff } from "./modules/useChangeStaff";
import { ModalChangeStaffListStaff } from "./items/ModalChangeStaffListStaff";
import Auth from "../../store/Authentication";

export default function ModalChangeStaffBooking({ dataBooking, refetch }) {
  const [form] = Form.useForm();
  const cache = useQueryClient();
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const { data, isLoading } = useChangeStaff({ bookingId: dataBooking?.id });
  const infoBooking = [
    {
      title: STRINGS.customer_book,
      value: dataBooking.customerName,
    },
    {
      title: STRINGS.start_staff,
      value: dataBooking.staffName,
    },
    {
      title: STRINGS.password,
      tag: "passwordOriginStaff",
    },
    {
      title: STRINGS.changed_staff,
      tag: "changeStaff",
    },
    {
      title: STRINGS.password,
      tag: "passwordChangedStaff",
    },
  ];

  const onChangeStaff = async () => {
    try {
      await form.validateFields();
      const dataToServer = form.getFieldValue();

      const result = await FetchApi.acceptChangeStaffBooking({
        bookingId: dataBooking.id,
        staffIdAndPasswordRequest: {
          password: dataToServer.passwordChangedStaff
            ? dataToServer.passwordChangedStaff
            : dataToServer.passwordOriginStaff,
          staffId: dataToServer.passwordChangedStaff
            ? dataToServer.staffSelected.id
            : dataBooking.staffId,
        },
        staffIdWillUndertake: dataToServer.staffSelected.id,
      });
      if (result.code === 502) {
        message.error(STRINGS.password_is_incorrect);
        return;
      }
      if (!result.success) {
        message.error(STRINGS.server_error);
        return;
      }
      if (result.success) {
        message.success(STRINGS.done_successful);
        cache.refetchQueries(
          `useGetCalendarOrders-${moment().format("MM/DD/YYYY")}`
        );
        cache.refetchQueries(`getDetailCourseBooking-${dataBooking.id}`);
        cache.refetchQueries(`bookingComing-${userAdmin.storeId}`);
        refetch && refetch();
        ModalCustomService.close();
      }
    } catch (error) {}
  };

  const rulePasswordOriginStaff = [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        const passwordChangedStaff = getFieldValue("passwordChangedStaff");
        if (!passwordChangedStaff) {
          if (value === undefined || value === null || value.length === 0) {
            return Promise.reject(STRINGS.this_field_is_required);
          }
          form.resetFields(["passwordChangedStaff"]);
          return Promise.resolve();
        }
        return Promise.resolve();
      },
    }),
  ];
  const rulePasswordChangedStaff = [
    ({ getFieldValue }) => ({
      validator(rule, value) {
        const passwordOriginStaff = getFieldValue("passwordOriginStaff");
        if (!passwordOriginStaff) {
          if (value === undefined || value === null || value.length === 0) {
            return Promise.reject(STRINGS.this_field_is_required);
          }
          form.resetFields(["passwordOriginStaff"]);
          return Promise.resolve();
        }
        return Promise.resolve();
      },
    }),
  ];
  if (isLoading) return;
  return (
    <Col className="checkin-time-wrapper" style={{ paddingTop: 10 }}>
      <Form form={form}>
        {infoBooking.map((i, index) => {
          if (i.tag === "passwordOriginStaff") {
            return (
              <Row key={`${index}`} style={{ padding: "10px 15px 0px 15px" }}>
                <Col
                  span={8}
                  style={{
                    fontWeight: "bold",
                    color: COLORS.sub_accent,
                    fontSize: 18,
                    textAlign: "left",
                  }}>
                  {i.title}:
                </Col>
                <Form.Item
                  name="passwordOriginStaff"
                  rules={rulePasswordOriginStaff}>
                  <Input.Password />
                </Form.Item>
              </Row>
            );
          }
          if (i.tag === "passwordChangedStaff") {
            if (data?.staffsCanBeSwap?.length < 1) return;
            return (
              <Row key={`${index}`} style={{ padding: "10px 15px" }}>
                <Col
                  span={8}
                  style={{
                    fontWeight: "bold",
                    color: COLORS.sub_accent,
                    fontSize: 18,
                    textAlign: "left",
                  }}>
                  {i.title}:
                </Col>
                <Form.Item
                  name="passwordChangedStaff"
                  rules={rulePasswordChangedStaff}>
                  <Input.Password />
                </Form.Item>
              </Row>
            );
          }
          if (i.tag === "changeStaff") {
            return (
              <Row key={`${index}`} style={{ padding: "0px 15px" }}>
                <Col
                  span={8}
                  style={{
                    fontWeight: "bold",
                    color: COLORS.sub_accent,
                    fontSize: 18,
                    textAlign: "left",
                  }}>
                  {i.title}:
                </Col>
                <Col span={16} style={{ textAlign: "left" }}>
                  <ModalChangeStaffListStaff
                    isLoading={isLoading}
                    listStaff={data?.staffsCanBeSwap}
                    form={form}
                  />
                </Col>
              </Row>
            );
          }
          return (
            <Row
              key={`${index}`}
              style={{ alignItems: "center", padding: "0px 15px" }}>
              <Col
                span={8}
                style={{
                  fontWeight: "bold",
                  color: COLORS.sub_accent,
                  fontSize: 18,
                  textAlign: "left",
                }}>
                {i.title}:
              </Col>
              <Col
                span={16}
                style={{
                  fontWeight: "bold",
                  color: COLORS.sub_accent,
                  fontSize: 22,
                  textAlign: "left",
                }}>
                {i.value}
              </Col>
            </Row>
          );
        })}
      </Form>

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom
          disabled={data?.staffsCanBeSwap?.length === 0}
          onClick={onChangeStaff}
          style={{
            backgroundColor:
              data?.staffsCanBeSwap?.length === 0
                ? COLORS.disabled
                : COLORS.primary,
            borderColor:
              data?.staffsCanBeSwap?.length === 0
                ? COLORS.disabled
                : COLORS.primary,
            color: COLORS.background,
          }}>
          {STRINGS.sure}
        </ButtonCustom>
      </Row>
    </Col>
  );
}
