import React from "react";
import { Col, Radio, Row, message } from "antd";
import moment from "moment";

import { COLORS, STRINGS } from "../../../../constants";
import { ButtonCustom, ModalCustomService } from "../../../../elements";
import ModalCheckinTime from "../ModalCheckinTime";
import ModalRequestRestChooseTime from "../ModalRequestRestChooseTime";
import ModalChangeCheckOutTime from "../ModalChangeCheckOutTime";
import { FetchApi } from "../../../../api";
import { CommonFunction } from "../../../../util";
// import ModalRestTime from "../ModalRestTime";
// import ModalRequestRestMore from "../ModalRequestRestMore";

export default function BodyTopNotCheckOutYet({
  staffInfo,
  shiftStatus,
  scheduleSelected,
  setScheduleSelected,
  refetchCalendarOrder,
  disabled,
  dataStaffResting,
  selectedDate,
  data,
}) {
  const item = (data?.workShiftDTOS || []).find(
    (itm) => itm.scheduleId === scheduleSelected
  );

  const { restMinutesExtra, checkInTime, restMinuteDefault } = { ...item };
  const onCheckIn = async () => {
    // ModalCustomService.set({
    //   title:
    //     STRINGS.if_you_press_the_attendance_button_you_will_not_be_able_to_change_it,
    //   wrapperStyle: { width: "auto" },
    //   contentWrapperStyle: { width: "fit-content" },
    //   contentStyle: { maxHeight: "80vh", padding: 0 },
    //   content: (
    //     <ModalRestTime
    //       refetchCalendarOrder={refetchCalendarOrder}
    //       staffInfo={staffInfo}
    //     />
    //   ),
    // });
    try {
      const result = await FetchApi.timekeepingStaff({
        restMinute: staffInfo.restMinute || 30,
        type: "check_in",
        staffId: staffInfo.staffId,
      });

      if (result.success) {
        refetchCalendarOrder();
        ModalCustomService.close();
        message.success(STRINGS.checkin_success);
      } else if (result.code == 4101 || result.code == 4102) {
        message.warning(result.message);
      } else if (result.code == 4004) {
        message.error(STRINGS.cannot_checkin_because_error);
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      message.error(STRINGS.something_wrong);
    }
  };
  const onChangeCheckinTime = () => {
    ModalCustomService.set({
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <ModalCheckinTime
          refetchCalendarOrder={refetchCalendarOrder}
          staffInfo={staffInfo}
          scheduleSelected={scheduleSelected}
          shiftStatus={shiftStatus}
          setScheduleSelected={setScheduleSelected}
          disabled={disabled}
          selectedDate={selectedDate}
        />
      ),
    });
  };
  const onRequestRestMore = () => {
    // ModalCustomService.set({
    //   wrapperStyle: { width: "auto" },
    //   contentWrapperStyle: { width: "fit-content" },
    //   contentStyle: { maxHeight: "80vh", padding: 0 },
    //   content: (
    //     <ModalRequestRestMore
    //       refetchCalendarOrder={refetchCalendarOrder}
    //       staffInfo={staffInfo}
    //       scheduleSelected={scheduleSelected}
    //       shiftStatus={shiftStatus}
    //       setScheduleSelected={setScheduleSelected}
    //       disabled={disabled}
    //       selectedDate={selectedDate}
    //     />
    //   ),
    // });
    ModalCustomService.set({
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <ModalRequestRestChooseTime
          refetchCalendarOrder={refetchCalendarOrder}
          staffInfo={staffInfo}
          scheduleSelected={scheduleSelected}
          shiftStatus={shiftStatus}
          setScheduleSelected={setScheduleSelected}
          disabled={disabled}
          selectedDate={selectedDate}
        />
      ),
    });
  };

  const columns = [
    {
      label: STRINGS.working_hours,
      value:
        staffInfo.workShiftDTOS.length > 1 ? (
          <Radio.Group
            style={{ display: "flex", flexDirection: "column" }}
            defaultValue={scheduleSelected}
            onChange={(e) => setScheduleSelected(e.target.value)}>
            {staffInfo.workShiftDTOS.map((item) => {
              return (
                <Radio
                  className="value"
                  style={{ marginBottom: 0, fontSize: 22, fontWeight: "bold" }}
                  key={`${item.scheduleId}`}
                  value={item.scheduleId}>
                  {moment(item.startTime).format("HH:mm")}~
                  {moment(item.endTime).format("HH:mm")}
                </Radio>
              );
            })}
          </Radio.Group>
        ) : (
          <>
            {staffInfo.workShiftDTOS.map((item) => {
              return (
                <Row
                  className="value"
                  style={{ marginBottom: 0 }}
                  key={`${item.scheduleId}`}>
                  {moment(item.startTime).format("HH:mm")}~
                  {moment(item.endTime).format("HH:mm")}
                </Row>
              );
            })}
          </>
        ),
    },
    {
      label: STRINGS.attendance_time,
      value: checkInTime ? moment(checkInTime).format("HH:mm") : "ー",
    },
    {
      label: STRINGS.interval_time,
      value: `${restMinuteDefault || 0}\xa0${STRINGS.minute}`,
    },
    {
      label: STRINGS.time_rest_extra,
      value: `${restMinutesExtra || 0}\xa0${STRINGS.minute}`,
    },
  ];

  let hasResting = false;
  let disabledCheckin = true;
  let backgroundColor = COLORS.disabled;
  if (moment().isAfter(moment(item?.endTime))) {
    hasResting = true;
  }
  if (Array.isArray(dataStaffResting?.staffRestingDTOS)) {
    const staffSelected = dataStaffResting.staffRestingDTOS.find(
      (i) => i.staffId === staffInfo.staffId
    );
    if (staffSelected?.resting) {
      hasResting = true;
    }
  }
  if (
    moment().format("YYYY/MM/DD") === moment(selectedDate).format("YYYY/MM/DD")
  ) {
    disabledCheckin = false;
    backgroundColor = COLORS.primary;
  }

  return (
    <Row className="body-top">
      <Col xl={19} xs={24} style={{ padding: "0px 14px 0px 100px" }}>
        <Row>
          {columns.map((item, index) => {
            return (
              <Col
                xl={12}
                xs={24}
                style={{ margin: "7px 0px" }}
                key={`${item.label}`}>
                <Row align="middle" style={{ alignItems: "center" }}>
                  <Col className="label-base">{item.label}</Col>
                  <Col className="value-base">{item.value}</Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </Col>
      <Col xl={5} xs={24} style={{ padding: "4px 0px 14px 14px" }}>
        {shiftStatus === "working" && (
          <Row style={{ marginBottom: 14 }}>
            <ButtonCustom
              style={{
                backgroundColor: COLORS.secondary,
                borderColor: COLORS.secondary,
                fontSize: 26,
                fontWeight: "bold",
                height: "fit-content",
              }}
              onClick={onChangeCheckinTime}>
              時刻修正
            </ButtonCustom>
          </Row>
        )}
        {shiftStatus === "waiting_checkin" && (
          <Row style={{ marginBottom: 14 }}>
            <ButtonCustom
              disabled={disabledCheckin}
              style={{
                backgroundColor,
                borderColor: backgroundColor,
                fontSize: 26,
                fontWeight: "bold",
                height: "fit-content",
              }}
              onClick={onCheckIn}>
              {STRINGS.checkin}
            </ButtonCustom>
          </Row>
        )}
        {/* {!hasResting && shiftStatus === "working" && ( */}
        <Row style={{ marginBottom: 14 }}>
          <ButtonCustom
            style={{
              backgroundColor: COLORS.primary,
              borderColor: COLORS.primary,
              fontSize: 26,
              fontWeight: "bold",
              height: "fit-content",
            }}
            onClick={onRequestRestMore}>
            {STRINGS.rest2}
          </ButtonCustom>
        </Row>
        {/* )} */}
        <Row>
          <ButtonCustom
            style={{
              backgroundColor: COLORS.accent,
              borderColor: COLORS.accent,
              fontSize: 26,
              fontWeight: "bold",
              height: "fit-content",
            }}
            onClick={() => {
              ModalCustomService.set({
                title: STRINGS.change_time_checkout,
                wrapperStyle: { width: "auto" },
                contentWrapperStyle: { width: "fit-content" },
                contentStyle: { maxHeight: "80vh", padding: 0 },
                content: (
                  <ModalChangeCheckOutTime
                    shiftId={scheduleSelected}
                    refetchCalendarOrder={refetchCalendarOrder}
                  />
                ),
              });
            }}>
            {STRINGS.change_work}
          </ButtonCustom>
        </Row>
      </Col>
    </Row>
  );
}
