import { useQuery } from "react-query";

import { FetchApi } from "../../../api";
import { LOGIC } from "../../../constants";

export function useGetPackStore({ userId }) {
  const value = useQuery("packStore", () =>
    FetchApi.getPackOfStore({ userId })
  );
  return {
    dataProlonged: value.data?.prolongedPacks || [],
    dataCombo: value.data?.packs || [],
    ...value,
  };
}
export function useConvertCourseBooking({ data, dataCombo, dataProlonged }) {
  let detailCourseBooking = [{}];
  let allComboAvailable = {};
  let allProlongedAvailable = {};
  dataCombo.map((i) => {
    return (allComboAvailable[i.id] = i);
  });
  dataProlonged.map((i) => {
    return (allProlongedAvailable[i.id] = i);
  });

  if (data?.packs?.length > 0) {
    detailCourseBooking = data?.packs;
  }
  // if (data?.prolongedPackIds?.length > 0) {
  //   (data?.prolongedPackIds || []).map((i) => {
  //     return (prolongedOfBooking[i] = allProlongedAvailable[i]);
  //   });
  // }
  return {
    detailCourseBooking: detailCourseBooking.map((i) => {
      const dataConvert = allComboAvailable[i.packId];
      let comboExtraId;
      let priceExtra;
      let minuteExtra;
      if (allProlongedAvailable[i.prolongedPackId]?.id) {
        const packsExtra = allProlongedAvailable[i.prolongedPackId] || {};
        comboExtraId = packsExtra?.id;
        priceExtra = packsExtra?.priceForCurrentUser;
        minuteExtra = packsExtra?.minutes;
      }

      return {
        ...i,
        ...dataConvert,
        comboPackId: dataConvert?.id,
        comboExtraId,
        priceExtra,
        minuteExtra,
        tax: dataConvert?.tax,
        price: dataConvert?.priceForCurrentUser + (priceExtra || 0),
        priceForCurrentUser:
          (dataConvert?.priceForCurrentUser || 0) + (priceExtra || 0),
      };
    }),
  };
}

export function useCheckInfoStaff({ staffId, data }) {
  const value = useQuery(`designationFee-${staffId}`, () =>
    FetchApi.getStaffById(staffId)
  );
  data.designationFee = value.data?.staffDTO?.designationFee || 0;
  return {
    designationFee: value.data?.staffDTO?.designationFee || 0,
    ...value,
  };
}

export function checkCouponSpecial({ arrCoupon = [] }) {
  for (var i = 0; i < arrCoupon.length; i++) {
    if (arrCoupon[i]?.type === LOGIC.coupon_time.tax) {
      return true;
    }
  }
  return false;
}
