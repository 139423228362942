import React, { useState } from "react";
import { Col, Form, Row } from "antd";
import moment from "moment";

import { COLORS, STRINGS, Svgs } from "../../../constants";
import { ModalCustomService } from "../../../elements";
import { StaffBookingModalSelectWorker } from ".";

import "../StaffBooking.scss";

const Type = {
  select: "select",
  none: "none",
};

function StaffBookingSelectWorker({ form, numberOrdinal }) {
  const [infoStaff, setInfoStaff] = useState(
    form.getFieldValue(`bookingSelectStaff${numberOrdinal}`)
  );
  const [selectType, setSelectType] = useState(
    !!infoStaff?.staffId ? Type.select : Type.none
  );
  const onClickShowModal = () => {
    // let arrValue = [];
    const dataBooking = form.getFieldValue();
    // const comboStore = dataBooking[`bookingComboStore${numberOrdinal}`];
    // const comboFav = dataBooking[`bookingComboFav${numberOrdinal}`];
    const comboAll = dataBooking[`bookingComboAll${numberOrdinal}`];
    const dateBooking = moment(dataBooking.bookingDate).toISOString();
    const listId = [comboAll?.id];

    // if (!comboFav && !comboAll) {
    //   if (!comboStore || Object.keys(comboStore).length === 0) {
    //     message.warning(STRINGS.please_choose_combo);
    //     return;
    //   }

    //   for (const [_, value] of Object.entries(comboStore)) {
    //     arrValue.push(value);
    //   }
    //   if (arrValue.every((itm) => !itm?.id)) {
    //     message.warning(STRINGS.please_choose_combo);
    //     return;
    //   }
    // }
    // if (comboFav?.comboPackDTOS[0]?.id) {
    //   listId.push(comboFav.comboPackDTOS[0].id);
    // }
    // !!comboStore &&
    //   Object.keys(comboStore).map((key) => {
    //     if (comboStore[key]?.id) {
    //       listId.push(comboStore[key].id);
    //     }
    //   });

    ModalCustomService.set({
      wrapperStyle: { width: "auto" },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <StaffBookingModalSelectWorker
          form={form}
          listId={listId.filter((i) => !!i)}
          desiredTime={dateBooking}
          numberOrdinal={numberOrdinal}
        />
      ),
    });
  };
  const listOptions = [
    {
      icon: (props) => Svgs.selectStaff(props),
      title: STRINGS.select,
      type: Type.select,
      onClick: onClickShowModal,
    },
    {
      icon: (props) => Svgs.noSelect(props),
      title: STRINGS.no_one,
      type: Type.none,
      onClick: () => {
        setSelectType(Type.none);
        form.setFieldsValue({
          [`bookingSelectStaff${numberOrdinal}`]: null,
        });
      },
    },
  ];
  const renderContent = () => {
    return (
      <Row>
        {listOptions.map((item) => {
          let borderColor = COLORS.border;
          let color = COLORS.text;
          if (item.type === selectType) {
            borderColor = COLORS.primary;
            color = COLORS.primary;
          }

          return (
            <Col
              key={`${item.title}`}
              style={{ paddingRight: "10px", cursor: "pointer" }}
              onClick={item.onClick}>
              <Row
                style={{
                  alignItems: "center",
                  border: `1px solid ${borderColor}`,
                  padding: "10px 20px",
                  borderRadius: "5px",
                }}>
                {item.icon({ fill: borderColor })}
                <Col style={{ paddingLeft: "5px", color }}>{item.title}</Col>
              </Row>
            </Col>
          );
        })}
      </Row>
    );
  };

  const renderSelectStaff = () => {
    const SvgComponent = Svgs.delete;
    if (selectType === Type.select) {
      let src =
        "https://api.xn--vckg5a9gug849xgl0b.com/upload/image/a52b7629-5ce3-466c-a369-2d20989c2b8b909e75ab-1350-4ab3-8f79-8f08d91af2ba27aaf8bd-7d13-428f-861a-b9bc2fc39d453fa70bf0-af81-4d9b-83df-874ae1ef8b8c.png";
      if (infoStaff?.avatarUrl) {
        src = infoStaff.avatarUrl;
      }
      return (
        <Row
          style={{
            alignItems: "center",
            paddingBottom: 10,
            paddingTop: 10,
          }}
          onClick={onClickShowModal}>
          <img
            alt=""
            src={src}
            style={{ width: 36, height: 36, margin: "10px 0px" }}
          />
          <Col
            style={{
              fontSize: 14,
              color: COLORS.border_7,
              padding: "0px 10px",
            }}>
            {infoStaff?.name}
            {infoStaff?.designationFee
              ? `(+${infoStaff.designationFee}${STRINGS.yen})`
              : `(${STRINGS.free})`}
          </Col>

          <Row
            onClick={() => {
              setSelectType(Type.none);
              form.setFieldsValue({
                [`bookingSelectStaff${numberOrdinal}`]: null,
              });
            }}>
            <SvgComponent />
          </Row>
        </Row>
      );
    }
  };

  return (
    <Form.Item
      name={`bookingSelectStaff${numberOrdinal}`}
      shouldUpdate={(prevValues, curValues) => {
        // if (curValues[`bookingMinute${numberOrdinal}`]) {
        //   setSelectType(Type.none);
        //   return;
        // }
        if (curValues[`bookingSelectStaff${numberOrdinal}`]) {
          setSelectType(Type.select);
          setInfoStaff(curValues[`bookingSelectStaff${numberOrdinal}`]);
        } else {
          setSelectType(Type.none);
          setInfoStaff({});
        }
      }}>
      <Col
        style={{
          padding: "15px 0px",
        }}>
        <Row className="text-booking">{STRINGS.des_booking_7}</Row>
        {renderContent()}
        {renderSelectStaff()}
      </Col>
    </Form.Item>
  );
}

export { StaffBookingSelectWorker };
