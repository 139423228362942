/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { Col, Input, Row, Form } from "antd";

import { STRINGS } from "../../../constants";

function StaffBookingStep2Phone({ form }) {
  // const [options, setOptions] = useState([]);
  const timeout = useRef();

  // const inputPhone = useRef();

  const dataBooking = form.getFieldValue();

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  // const searchResult = (datas) => {
  //   return datas.map((item, index) => {
  //     return {
  //       name: item.name,
  //       value: "" + item.id,
  //       item: item,
  //       label: (
  //         <div style={{ borderWidth: 20 }}>
  //           <div style={{ fontWeight: "bold" }}>{item.phoneNumber || ""}</div>
  //           <div style={{ color: "grey" }}>{item.name}</div>
  //         </div>
  //       ),
  //     };
  //   });
  // };

  const numberPeople = dataBooking.bookingNum;
  return (
    <Row style={{ paddingTop: 50 }}>
      <Col>
        {[...Array(numberPeople)].map((i, index) => {
          const numberOrdinal = index + 1;
          // if (index === 0) {
          //   return (
          //     <div key={`${index}`} style={{ paddingTop: 20 }}>
          //       <Row className="text-booking">{STRINGS.owner_booking}</Row>
          //       <Row>
          //         <Form.Item
          //           name={`bookingPhoneNumber${numberOrdinal}`}
          //           // label={STRINGS.phone_number}
          //           style={{
          //             flexDirection: "column",
          //             display: "flex",
          //             alignItems: "flex-start",
          //             width: 250,
          //             paddingTop: 5,
          //           }}
          //           rules={[Validate.phoneNumberJp]}>
          //           <CustomerList
          //             form={form}
          //             triggerName={`bookingUserName${numberOrdinal}`}
          //             defaultName={dataBooking?.bookingInfoUser?.phoneNumber}
          //           />
          //         </Form.Item>
          //         <Form.Item
          //           name={`bookingUserName${numberOrdinal}`}
          //           // label={STRINGS.name_user_booking}
          //           style={{
          //             width: 250,
          //             marginLeft: "15px",
          //             paddingTop: 5,
          //           }}>
          //           <Input
          //             style={{
          //               width: 250,
          //               border: "none",
          //               backgroundColor: COLORS.background,
          //               color: COLORS.text,
          //               fontWeight: "bold",
          //             }}
          //             ref={inputName}
          //             disabled
          //           />
          //         </Form.Item>
          //       </Row>
          //     </div>
          //   );
          // }
          if (index === 0) {
            return null;
          }
          return (
            <Col key={`${index}`} style={{ paddingTop: 20, minHeight: 80 }}>
              <Row className="text-booking">
                {STRINGS.hangout_user_booking(numberOrdinal)}
              </Row>

              <Row>
                <Form.Item
                  name={`bookingUserName${numberOrdinal}`}
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "flex-start",
                    width: 250,
                    paddingTop: 5,
                  }}>
                  <Input style={{ width: 250 }} />
                </Form.Item>
              </Row>
            </Col>
          );
        })}
      </Col>
    </Row>
  );
}

export { StaffBookingStep2Phone };
