/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Select, Col, Row } from "antd";
import { FetchApi } from "../api";
import { SuffixIconDown } from "../elements";
import { STRINGS } from "../constants/Strings";
import { Validate } from "./Validate";

const { Option } = Select;

const ListStaff = ({ storeSelected, style, onChange, title, noTitle }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ data: [] });
  const [pagination, setPagination] = useState({
    page: 1,
    size: 20,
    last: false,
    refetch: false,
  });
  const staffName = useRef("");
  const timeout = useRef();

  const handleChange = async (e) => {
    onChange && onChange(e);
  };
  const onScroll = (event) => {
    var target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      if (!pagination.last) {
        setPagination((prev) => {
          return { ...prev, page: prev.page + 1 };
        });
      }
    }
  };

  const fetch = async () => {
    const result = await FetchApi.getListStaff(
      storeSelected,
      pagination.page,
      pagination.size,
      null,
      staffName.current
    );
    if (result.success) {
      setData((prev) => {
        if (pagination.page === 1) {
          return { ...result.data, data: [...result.data] };
        }
        return { ...result.data, data: [...prev.data, ...result.data] };
      });
      setPagination((prev) => {
        return { ...prev, last: result.pageDto.last };
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetch();
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, [pagination.page, pagination.refetch]);

  useEffect(() => {
    setData({ data: [] });
    setPagination({
      page: 1,
      size: 20,
      last: true,
      refetch: !pagination.refetch,
    });
  }, [storeSelected]);

  let dataCheckLoading = [];

  if (!pagination.last && data?.data) {
    dataCheckLoading = [...data.data, { id: "more", name: "..." }];
  }
  if (data?.data && pagination.last) {
    dataCheckLoading = data?.data;
  }

  return (
    <Col>
      {!noTitle && <Row>{title || STRINGS.staff_name_2}</Row>}
      <Select
        allowClear
        onClear={() => {
          staffName.current = null;
          setPagination({
            page: 1,
            size: 20,
            last: true,
            refetch: !pagination.refetch,
          });
        }}
        showSearch
        placeholder={STRINGS.staff_name_2}
        onChange={handleChange}
        onSearch={(e) => {
          const converted = Validate.removeSpecialCharactors(e);
          staffName.current = converted || null;
          if (timeout.current) {
            clearTimeout(timeout.current);
          }
          timeout.current = setTimeout(() => {
            setPagination({
              page: 1,
              size: 20,
              last: true,
              refetch: !pagination.refetch,
            });
          }, 800);
        }}
        optionFilterProp="children"
        filterOption={null}
        onPopupScroll={onScroll}
        suffixIcon={<SuffixIconDown />}
        style={{ width: 300, alignSelf: "flex-end", ...style }}>
        {[{ name: STRINGS.all, id: null }, ...dataCheckLoading].map(
          (item, index) => (
            <Option key={item.id} disabled={item.id === "more"}>
              {item.name}
            </Option>
          )
        )}
      </Select>
    </Col>
  );
};

export { ListStaff };
