import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "antd";

import { COLORS, STRINGS } from "../../../constants";
import { LoadingView, Validate } from "../../../elements";

function SelectWorkerItem({ data, staffSelected, setStaffSelected }) {
  const renderSelect = (item) => {
    if (data.id === staffSelected?.id) {
      return (
        <div
          style={{
            width: "18px",
            height: "18px",
            borderRadius: "9px",
            backgroundColor: COLORS.success,
          }}
        />
      );
    }
  };

  return (
    <Row
      style={{
        paddingBottom: "20px",
        width: "100%",
        cursor: "pointer",
      }}
      onClick={() => {
        if (data.id === staffSelected?.id) {
          setStaffSelected();
          return;
        }
        setStaffSelected(data);
      }}>
      <Col xl={4}>
        <div
          style={{
            width: "24px",
            height: "24px",
            borderRadius: "12px",
            border: `1px solid ${COLORS.border}`,
            display: "flex",
            placeContent: "center",
            alignItems: "center",
            marginRight: "10px",
            backgroundColor: COLORS.background,
          }}>
          {renderSelect(data.staffId)}
        </div>
      </Col>

      <Col xl={20}>
        <Row
          style={{
            fontSize: 14,
            color: COLORS.border_7,
            wordBreak: "break-all",
          }}>
          {data.name}
          {/* {data.designationFee
            ? `(+${data.designationFee}${STRINGS.yen})`
            : `(${STRINGS.free})`} */}
        </Row>
      </Col>
    </Row>
  );
}

export function ModalChangeStaffListStaff({ listStaff = [], form, isLoading }) {
  const [staffSelected, setStaffSelected] = useState();

  useEffect(() => {
    form.setFieldsValue({ staffSelected: staffSelected });
  }, [staffSelected]);

  if (isLoading) {
    return <LoadingView style={{ textAlignLast: "left" }} />;
  }

  if (listStaff.length === 0) {
    return (
      <Row
        style={{
          fontWeight: "bold",
          color: COLORS.sub_accent,
          fontSize: 18,
          textAlign: "left",
        }}>
        {STRINGS.no_staff_change_available}
      </Row>
    );
  }

  return (
    <Form.Item rules={[Validate.emptyContent]} name="staffSelected">
      <Col>
        {listStaff.map((item, index) => {
          return (
            <Col key={`${index}`} xl={16}>
              <SelectWorkerItem
                data={item}
                setStaffSelected={setStaffSelected}
                staffSelected={staffSelected}
              />
            </Col>
          );
        })}
      </Col>
    </Form.Item>
  );
}
