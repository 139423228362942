import { AutoComplete, Col, Form, Input, message, Row } from "antd";
import React, { useRef, useState } from "react";

import { COLORS, STRINGS } from "../../../constants";
import { ButtonCustom, ModalCustomService } from "../../../elements";
import "../StaffScheduleManual";
import { FetchApi } from "../../../api";

export function ModalSpeechRecog({ navigate }) {
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);

  const inputRef = useRef();

  const searchResult = (datas) => {
    return datas.map((item, index) => {
      return {
        name: item.name,
        value: "" + item.id,
        item: item,
        label: (
          <div style={{ borderWidth: 20 }}>
            <div style={{ fontWeight: "bold" }}>{item.name || ""}</div>
            <div style={{ color: "grey" }}>{item.phoneNumber}</div>
          </div>
        ),
      };
    });
  };
  const onSelect = (e, item) => {
    if (!item?.item?.id) {
      return;
    }
    ModalCustomService.close();
    navigate("staff-booking", { state: { data: item?.item } });
  };
  const onSearch = async (searchText) => {
    if (!searchText) {
      setOptions([]);
      return;
    }
    let result = await FetchApi.getUserApp({
      page: 1,
      size: 50,
      search: searchText,
    });
    if (!Array.isArray(result?.data) || result?.data?.length < 1) {
      message.warning(STRINGS.no_user_found);
      setOptions([]);
      return;
    }
    setOptions(searchResult(result.data) || []);
  };

  return (
    <Col className="checkin-time-wrapper">
      <Col style={{ margin: "10px 0px", color: COLORS.accent }}>
        {STRINGS.search_name_furi_phone}
      </Col>
      <Form form={form}>
        <Row style={{ alignItems: "center", justifyContent: "center" }}>
          <Col style={{ paddingTop: 18 }}>
            <Form.Item name="inputSearch">
              <AutoComplete
                key={`${options}`}
                options={options}
                open={options.length > 0}
                onSelect={onSelect}>
                <Input
                  ref={inputRef}
                  style={{ width: 250 }}
                  onPressEnter={(e, f) => {
                    onSelect(e, f);
                    onSearch(e.target.value);
                  }}
                />
              </AutoComplete>
            </Form.Item>
          </Col>

          <Col>
            <img
              onClick={() => {
                const re = /^(0[0-9０-９]{9,10})$/;
                const dataInput = inputRef.current?.input?.value;
                if (re.test(dataInput)) {
                  ModalCustomService.close();
                  navigate("staff-booking", {
                    state: { data: { phoneNumber: dataInput } },
                  });
                  return;
                }
                message.warning(STRINGS.number_phone_incorrect);
              }}
              alt="btn"
              style={{ height: 70, width: 70 }}
              src="https://api.xn--vckg5a9gug849xgl0b.com/upload/image/afb2f907-108a-4ca5-ae7e-06d14df5d18094832fb6-7979-44e8-9b53-27935f0948cc9ac9df90-9613-43ff-8cf0-7bdfab6ddd684e5be432-6112-4863-a771-11e0e1de6d77.png"
            />
          </Col>
        </Row>
      </Form>

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom
          onClick={() => {
            const re = /^(0[0-9０-９]{9,10})$/;
            const dataInput = inputRef.current?.input?.value;
            if (re.test(dataInput)) {
              ModalCustomService.close();
              navigate("staff-booking", {
                state: { data: { phoneNumber: dataInput } },
              });
              return;
            }
            message.warning(STRINGS.number_phone_incorrect);
          }}>
          {STRINGS.next}
        </ButtonCustom>
      </Row>
    </Col>
  );
}
