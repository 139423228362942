import React, { useState } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  TimePicker,
  message,
  Select,
  Switch,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  ButtonCustom,
  ImageListUpload,
  ImageUpload,
  LoadingAbsolute,
  Validate,
} from "../../../elements";
import { FetchApi } from "../../../api";
import { ModalCustomService } from "../../../elements/ModalCustom/ModalCustomService";
import { COLORS, STRINGS, LOGIC } from "../../../constants";
import Quill from "../../../elements/Quill";

const { Option } = Select;

const Add = ({ data, setData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onAdd = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();

      setLoading(true);
      const result = await FetchApi.createStore(fieldData);
      setLoading(false);
      if (result.success) {
        setData([result.storeDTO, ...(data?.storeList || [])]);
        ModalCustomService.close();
        message.success(STRINGS.registered_common);
      } else if (result.code == 2009) {
        message.warning(STRINGS.email_existed);
      } else {
        message.error(result.message || STRINGS.something_wrong);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return (
    <Form
      className="manager-store-form"
      form={form}
      layout="vertical"
      style={{ padding: "0px 15px 55px 15px" }}>
      <Form.Item
        className="img-store-list"
        name="images"
        rules={[Validate.emptyContent]}>
        <ImageListUpload aspect={3 / 2} />
      </Form.Item>
      <Row gutter={20}>
        <Col xl={8}>
          <Form.Item
            required
            name="name"
            label={STRINGS.store_name}
            rules={[Validate.emptyContent]}>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 6 }}
              placeholder={STRINGS.store_name}
            />
          </Form.Item>
          <Form.Item
            required
            name="phoneNumber"
            label={STRINGS.phone_number}
            rules={[Validate.phoneNumber]}>
            <Input placeholder={STRINGS.phone_number} />
          </Form.Item>
          <Row gutter={20}>
            <Col xl={12}>
              <Form.Item
                label={STRINGS.longitude}
                name="longitude"
                rules={[Validate.latlong]}>
                <Input placeholder={STRINGS.longitude} />
              </Form.Item>
            </Col>
            <Col xl={12}>
              <Form.Item
                name="latitude"
                label={STRINGS.latitude}
                rules={[Validate.latlong]}>
                <Input placeholder={STRINGS.latitude} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="address"
            label={STRINGS.address}
            required
            rules={[Validate.emptyContent]}>
            <Input placeholder={STRINGS.address} />
          </Form.Item>
          <Form.Item name="enableViewRevenue" label={STRINGS.show_revenue}>
            <Switch />
          </Form.Item>
          <Form.Item
            className="img-map"
            name="imageMapUrl"
            label={STRINGS.store_map}>
            <ImageUpload aspect={1 / 0.67} />
          </Form.Item>
        </Col>

        <Col xl={8}>
          <Row gutter={20}>
            <Col xl={12}>
              <Form.Item
                label={STRINGS.open_time}
                name="openTime"
                initialValue={moment("08:00", "HH:mm")}>
                <TimePicker
                  style={{ width: "100%" }}
                  placeholder={"HH:mm"}
                  format={"HH:mm"}
                  clearIcon={false}
                />
              </Form.Item>
            </Col>
            <Col xl={12}>
              <Form.Item
                name="closeTime"
                label={STRINGS.close_time}
                initialValue={moment("17:00", "HH:mm")}>
                <TimePicker
                  style={{ width: "100%" }}
                  placeholder={"HH:mm"}
                  format={"HH:mm"}
                  clearIcon={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="nameOwner"
            label={STRINGS.store_owner}
            required
            rules={[Validate.emptyContent]}>
            <Input placeholder={STRINGS.store_owner} />
          </Form.Item>
          <Form.Item
            name="email"
            label={STRINGS.email}
            required
            rules={[Validate.emptyContent]}>
            <Input placeholder={STRINGS.email} />
          </Form.Item>
          <Form.Item
            name="password"
            label={STRINGS.password}
            required
            rules={[Validate.emptyContent]}>
            <Input.Password placeholder={STRINGS.password} />
          </Form.Item>
          <Form.Item
            name="storeUrl"
            label={STRINGS.available_link}
            rules={[Validate.isUrl]}>
            <Input placeholder={STRINGS.available_link} />
          </Form.Item>
          <Form.Item
            name="pointCheckIn"
            label={STRINGS.grant_points}
            rules={[Validate.isPositiveNumMessage]}>
            <Input placeholder={STRINGS.grant_points} />
          </Form.Item>
          <Form.Item
            name="pointReview"
            label={STRINGS.review_point}
            rules={[Validate.isPositiveNumMessage]}>
            <Input placeholder={STRINGS.review_point} />
          </Form.Item>
          <Form.Item
            name="bed"
            label={STRINGS.bed}
            rules={[Validate.isPositiveNumMessage]}>
            <Input placeholder={STRINGS.bed} />
          </Form.Item>
          <Form.Item
            name="storeCode"
            label={STRINGS.store_code}
            required
            rules={[Validate.emptyContent]}>
            <Select
              // onSelect={onSelect}
              suffixIcon={
                <CaretDownOutlined style={{ color: COLORS.border_6 }} />
              }>
              {LOGIC.storeCode.map((itm) => (
                <Option key={"" + itm}>{itm}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={8} style={{ textAlign: "start" }}>
          <span>{STRINGS.QRCode}</span>
          <div
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: 3,
              padding: "20px 30px",
            }}>
            <Form.Item
              name="QRImage"
              style={{
                width: "100%",
                height: 232,
                marginBottom: 0,
                alignItems: "center",
              }}>
              <Row justify="center">
                <div style={{ width: "232px", height: "232px" }} alt="qr" />
              </Row>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Form.Item
        name="description"
        label={STRINGS.description}
        style={{ marginBottom: 80 }}>
        <Quill style={{ height: 350 }} />
      </Form.Item>

      <Row justify="center">
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onAdd}>{STRINGS.save}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Form>
  );
};

export default Add;
