/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  memo,
} from "react";
import { Upload, message, Input, Form, Select, Col, Row, Image } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

import {
  ButtonCustom,
  FormInput,
  StoreSelect,
  SvgView,
  useForceUpdate,
  Validate,
} from "../../../elements";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util/CommonFunction";
import { WorkableCombo } from "../../store-staff/items/Add";
import { COLORS, Svgs, STRINGS } from "../../../constants";
import Auth from "../../../store/Authentication";

import "./StaffManagementForm.scss";
import "react-quill/dist/quill.snow.css";
import { useQuery } from "react-query";

import Bronze from "../../../assets/img/bronze.png";
import Silver from "../../../assets/img/sliver.png";
import Gold from "../../../assets/img/gold.png";
import Platinum from "../../../assets/img/platinum.png";
import Diamond from "../../../assets/img/diamond.png";
import Free from "../../../assets/img/free.png";

const StaffManagerForm = memo(
  forwardRef((props, ref) => {
    const userAdmin = JSON.parse(Auth.getCurrentUser());
    const [loadingImg, setLoadingImg] = useState(false);

    const listStore = props.listStore.slice(1);

    const form = useRef();

    const avatarUrl = useRef("");
    const rankImage = useRef("");
    const birthday = useRef("");
    const storeId = useRef("");
    const skillRequests = useRef([]);

    const firstChooseStore = useRef();
    const [formContent] = Form.useForm();
    const forceUpdate = useForceUpdate();
    const rank = Form.useWatch("rank", formContent);

    const openHourArr = useRef([]);
    const closeHourArr = useRef([]);

    const images = {
      ブロンズ級: Bronze,
      シルバー級: Silver,
      ゴールド級: Gold,
      プラチナ級: Platinum,
      ダイヤモンド級: Diamond,
      非学会員: Free,
    };

    const rankPost = {
      ブロンズ級: "BRONZE",
      シルバー級: "SILVER",
      ゴールド級: "GOLD",
      プラチナ級: "PLATINUM",
      ダイヤモンド級: "DIAMOND",
      非学会員: "FREE",
    };
    const {
      data: dataPacks,
      isFetching,
      refetch,
    } = useQuery(
      [`combo-pack-${props.data?.id}`],
      () => FetchApi.getPacksStaff(props.data?.id),
      {
        enabled: !props?.checkAdd,
      }
    );
    useEffect(() => {
      if (props.data) {
        avatarUrl.current = props.data.avatarUrl;
        rankImage.current = props.data?.rank?.image;
        birthday.current = props.data.birthday;
        storeId.current = props.data.storeId || userAdmin.storeId;
        skillRequests.current = props.data.skills || [];
        calculateTime(
          props.data.storeId ||
            (userAdmin.type === "SYSTEM_ADMIN" ? "" : userAdmin.storeId)
        );

        formContent.resetFields();
        forceUpdate();
      }
    }, [props.data]);

    const handleUpImage = async (file) => {
      setLoadingImg(true);
      let result = await FetchApi.uploadFile(file);
      if (result?.fileUrl) {
        avatarUrl.current = result.fileUrl;
        setLoadingImg(false);
      } else {
        message.warning(STRINGS.upload_img_failed);
        setLoadingImg(false);
      }
    };

    const onPreview = async (file) => {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow.document.write(image.outerHTML);
    };

    const uploadButton = (
      <div>
        {loadingImg ? (
          <LoadingOutlined />
        ) : (
          <PlusOutlined style={{ color: COLORS.primary_3, fontSize: 64 }} />
        )}
      </div>
    );

    const show = () => {
      form.current.show();
    };

    useImperativeHandle(ref, () => ({ show }));

    const removeImg = () => {
      avatarUrl.current = "";
      forceUpdate();
    };

    const range = (a, b = 23) => {
      const arr = [];
      while (a < b + 1) {
        arr.push(a++);
      }
      return arr;
    };

    const getOpenHour = (stoId) => {
      const storePicked = listStore.find((i) => i.id == stoId);
      return storePicked?.openTime
        ? parseInt(storePicked.openTime.slice(0, 2))
        : [];
    };
    const getCloseHour = (stoId) => {
      const storePicked = listStore.find((i) => i.id == stoId);
      return storePicked?.closeTime
        ? parseInt(storePicked.closeTime.slice(0, 2))
        : 24;
    };

    const calculateTime = (stoId) => {
      const openHour = getOpenHour(stoId);
      const closeHour = getCloseHour(stoId);
      if (closeHour > openHour) {
        openHourArr.current = Array.from(Array(openHour).keys());
        closeHourArr.current = range(closeHour + 1);
      } else {
        openHourArr.current = range(closeHour + 1, openHour - 1);
      }
    };

    const onConfirm = async () => {
      try {
        await formContent.validateFields();
        const { store1, store2, store3, store4, comboPackIds } =
          formContent.getFieldValue();
        if (!store1 && !store2 && !store3) {
          message.warning(STRINGS.please_select_at_least_1);
          return;
        }
        if (store1 && store2 && store1 == store2) {
          message.warning(STRINGS.duplicate_store);
          return;
        }
        if (store1 && store3 && store1 == store3) {
          message.warning(STRINGS.duplicate_store);
          return;
        }
        if (store1 && store4 && store1 == store4) {
          message.warning(STRINGS.duplicate_store);
          return;
        }
        if (store3 && store2 && store3 == store2) {
          message.warning(STRINGS.duplicate_store);
          return;
        }
        if (store3 && store4 && store3 == store4) {
          message.warning(STRINGS.duplicate_store);
          return;
        }
        if (store4 && store2 && store2 == store4) {
          message.warning(STRINGS.duplicate_store);
          return;
        }
        let item = { ...props.data };
        const updateRank = rankPost[rank];
        if (Object.keys(props.data).length < 1) {
          item.avatarUrl = avatarUrl.current;
          item.birthday = birthday.current;
          item.storeId = storeId.current;
          item.storeIds = [];
          store1 && item.storeIds.push(store1);
          store2 && item.storeIds.push(store2);
          store3 && item.storeIds.push(store3);
          store4 && item.storeIds.push(store4);
          item.skillRequests = skillRequests.current;
          item.skills = skillRequests.current;
          item.comboPackIds = comboPackIds;
          form.current.setLoading(true);
          const result = await FetchApi.addStaff({
            ...item,
            ...formContent.getFieldValue(),
            rank: updateRank,
          });
          if (result.success) {
            props.refetch();
            item.id = result.staffDTO.id;
            form.current.hide();
            message.success(STRINGS.add_staff_successful);
          } else if (result.message === "This staff phone already exist") {
            message.error(STRINGS.phone_is_existed);
          } else if (result.message === "This staff email already exist") {
            message.error(STRINGS.email_is_existed);
          } else {
            message.error(result.message || STRINGS.add_staff_failed);
          }
          form.current.setLoading(false);
        } else {
          item.avatarUrl = avatarUrl.current;
          item.birthday = birthday.current;
          item.storeIds = [];
          store1 && item.storeIds.push(store1);
          store2 && item.storeIds.push(store2);
          store3 && item.storeIds.push(store3);
          store4 && item.storeIds.push(store4);
          item.skillRequests = skillRequests.current;
          item.skills = skillRequests.current;
          item.comboPackIds = comboPackIds;
          form.current.setLoading(true);
          const result = await FetchApi.updateStaff({
            ...item,
            ...formContent.getFieldValue(),
            rank: updateRank,
          });
          if (result.success) {
            props.refetch();
            form.current.hide();
            refetch();
            message.success(STRINGS.update_staff_success);
          } else if (result.message === "This staff phone already exist") {
            message.error(STRINGS.phone_is_existed);
          } else if (result.message === "This staff email already exist") {
            message.error(STRINGS.email_is_existed);
          } else {
            message.error(result.message || STRINGS.update_staff_failed);
          }
          form.current.setLoading(false);
        }
        refetch();
        storeId.current = undefined;
      } catch (e) {
        form.current.setLoading(false);
        message.warning(STRINGS.please_fill_required_fields);
      }
    };
    const patternFee = new RegExp(/^[0-9]+$/);
    let staffFee = props.data?.designationFee;
    rankImage.current = { image: images[rank], type: rankPost[rank] };

    return (
      <FormInput
        className="form-staff-system-admin"
        title={props?.data?.name ? STRINGS.update : STRINGS.add_new_staff}
        ref={form}
        renderButton={false}
        onClose={() => {
          storeId.current = undefined;
          firstChooseStore.current = undefined;
        }}
      >
        <Form layout="vertical" form={formContent}>
          <Col className="wrapper-container-staff-form">
            <Row gutter={20}>
              <Col className="staff-avatar">
                <div className="name">{STRINGS.avatar}</div>
                {avatarUrl.current && (
                  <button
                    style={{
                      position: "absolute",
                      alignSelf: "flex-end",
                      top: 130,
                      right: 15,
                      width: "32px",
                      height: "32px",
                      borderWidth: 0,
                      backgroundColor: "white",
                      borderRadius: "100%",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                    onClick={removeImg}
                  >
                    <SvgView svgData={Svgs.delete} />
                  </button>
                )}
                <ImgCrop
                  modalTitle={STRINGS.editImageCrop}
                  modalOk={STRINGS.ok}
                  modalCancel={STRINGS.cancel}
                  rotationSlider
                  cropShape={"round"}
                  aspect={1}
                >
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={CommonFunction.beforeUpload}
                    customRequest={handleUpImage}
                    onPreview={onPreview}
                  >
                    {avatarUrl?.current ? (
                      <img
                        style={{ width: 120, borderRadius: 60 }}
                        src={avatarUrl.current}
                        alt="img"
                      />
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </ImgCrop>
                <div
                  style={{
                    top: 30,
                    position: "absolute",
                    right: 15,
                    borderRadius: "100%",
                    overflow: "hidden",
                    cursor: "pointer",
                  }}
                >
                  {rankImage.current.image && (
                    <Image
                      preview={!!rankImage.current.image}
                      src={rankImage.current.image}
                      style={{ borderWidth: 10, borderColor: "red" }}
                      width={32}
                      height={32}
                    />
                  )}
                </div>
              </Col>
              <Col style={{ flex: 1 }}>
                <Row gutter={20}>
                  <Col xl={8} md={8} xs={8}>
                    <Form.Item
                      label={STRINGS.staff_name_2}
                      name="name"
                      required
                      rules={[Validate.emptyContent]}
                      initialValue={props.data?.name}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xl={8} md={12} xs={12}>
                    <Form.Item
                      label={STRINGS.gender}
                      name="sex"
                      required
                      rules={[Validate.emptyContent]}
                      initialValue={props.data?.sex}
                    >
                      <Select
                        suffixIcon={
                          <CaretDownOutlined
                            style={{ color: COLORS.border_6 }}
                          />
                        }
                      >
                        {[
                          { name: STRINGS.male, id: "male" },
                          { name: STRINGS.female, id: "female" },
                        ].map((item, index) => (
                          <Select.Option key={"" + item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col xl={8} md={8} xs={8}>
                    <Form.Item
                      label={STRINGS.email}
                      name="email"
                      rules={[Validate.email]}
                      initialValue={props.data?.email}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col xl={8} md={8} xs={8}>
                    <Form.Item
                      label={STRINGS.phone_number}
                      name="phoneNumber"
                      required
                      rules={[Validate.phoneNumberJp]}
                      initialValue={props.data?.phoneNumber}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xl={8} md={8} xs={8}>
                    <Form.Item
                      label={STRINGS.rank.title}
                      name="rank"
                      initialValue={
                        STRINGS.rank[props.data?.rank?.rank.toLowerCase()]
                      }
                    >
                      <Select
                        onChange={() =>
                          formContent.setFieldValue("designationFee", null)
                        }
                        options={[
                          {
                            value: STRINGS.rank.bronze,
                            label: STRINGS.rank.bronze,
                          },

                          {
                            value: STRINGS.rank.silver,
                            label: STRINGS.rank.silver,
                          },
                          {
                            value: STRINGS.rank.gold,
                            label: STRINGS.rank.gold,
                          },
                          {
                            value: STRINGS.rank.platinum,
                            label: STRINGS.rank.platinum,
                          },
                          {
                            value: STRINGS.rank.diamond,
                            label: STRINGS.rank.diamond,
                          },
                          {
                            value: STRINGS.rank.free,
                            label: STRINGS.rank.free,
                          },
                        ]}
                      ></Select>
                    </Form.Item>
                  </Col>
                  {/* <Col xl={8} md={8} xs={8}>
                    <Form.Item
                      label={STRINGS.interval_time}
                      name="restMinute"
                      required
                      rules={[Validate.emptyContent]}
                      initialValue={props.data?.restMinute}>
                      <Select
                        suffixIcon={
                          <CaretDownOutlined
                            style={{ color: COLORS.border_6 }}
                          />
                        }>
                        {[
                          { name: `30 ${STRINGS.minute}`, id: "30" },
                          { name: `20 ${STRINGS.minute}`, id: "20" },
                          { name: `10 ${STRINGS.minute}`, id: "10" },
                        ].map((item, index) => (
                          <Select.Option key={"" + item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col> */}
                  <Col xl={8} md={8} xs={8}>
                    <Row>
                      <Form.Item
                        required="true"
                        label={<span>{STRINGS.designation_fee}</span>}
                        name="designationFee"
                        rules={[
                          {
                            required: true,
                            pattern: patternFee,
                            message: STRINGS.number_please,
                          },
                        ]}
                        initialValue={staffFee || 0}
                      >
                        <Input />
                      </Form.Item>
                      <Col
                        style={{ marginTop: 26, fontSize: 16, marginLeft: 10 }}
                      >
                        {STRINGS.yen}
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col xl={8} md={12} xs={12}>
                    <Form.Item
                      label={STRINGS.procedure_range}
                      name="skillRequests"
                      initialValue={skillRequests.current.map((itm) => itm.id)}>
                      <Select
                        mode="multiple"
                        suffixIcon={
                          <CaretDownOutlined style={{ color: COLORS.border_6 }} />
                        }
                        onChange={(e, f) => {
                          skillRequests.current = [];
                          e.forEach((element) => {
                            const it = listSkill.find(
                              (itm) => itm.id == element
                            );
                            skillRequests.current.push(it);
                          });
                        }}>
                        {listSkill.map((item, index) => (
                          <Select.Option key={"" + item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col> */}
                </Row>
                <Row gutter={20}>
                  <Col xl={8} md={12} xs={12}>
                    <Form.Item
                      label={STRINGS.store_1}
                      name="store1"
                      initialValue={props.data?.storeIds?.[0]}
                    >
                      <StoreSelect />
                    </Form.Item>
                  </Col>
                  <Col xl={8} md={12} xs={12}>
                    <Form.Item
                      label={STRINGS.store_2}
                      name="store2"
                      initialValue={props.data?.storeIds?.[1]}
                    >
                      <StoreSelect />
                    </Form.Item>
                  </Col>
                  <Col xl={8} md={12} xs={12}>
                    <Form.Item
                      label={STRINGS.store_3}
                      name="store3"
                      initialValue={props.data?.storeIds?.[2]}
                    >
                      <StoreSelect />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col xl={8} md={12} xs={12}>
                    <Form.Item
                      label={STRINGS.store_4}
                      name="store4"
                      initialValue={props.data?.storeIds?.[3]}
                    >
                      <StoreSelect />
                    </Form.Item>
                  </Col>
                </Row>
                <Row style={{ width: "100%", padding: 0 }}>
                  <Form.Item
                    style={{ width: "100%" }}
                    label={STRINGS.self_introduction}
                    initialValue={props.data?.selfIntroduction}
                    name="selfIntroduction"
                  >
                    <Input />
                  </Form.Item>
                </Row>
                {!isFetching && (
                  <Row style={{ width: "100%" }}>
                    <Form.Item
                      name="comboPackIds"
                      style={{ width: "100%" }}
                      initialValue={dataPacks || []}
                    >
                      <WorkableCombo />
                    </Form.Item>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ButtonCustom
              isCancelButton
              style={{
                marginRight: 10,
              }}
              onClick={() => {
                storeId.current = undefined;
                firstChooseStore.current = undefined;
                form.current.hide();
              }}
            >
              {STRINGS.cancel}
            </ButtonCustom>
            <Form.Item>
              <ButtonCustom onClick={onConfirm}>
                {props.data?.name ? STRINGS.update : STRINGS.save}
              </ButtonCustom>
            </Form.Item>
          </div>
        </Form>
      </FormInput>
    );
  })
);

export default StaffManagerForm;
