import React, { useState } from "react";
import { Table } from "antd";
import { useQuery } from "react-query";
import moment from "moment";

import { FetchApi } from "../../api";
import { Container, ErrorView } from "../../elements";
import { LOGIC, STRINGS } from "../../constants";
import PaginationWithSizeChange from "../../elements/PaginationWithSizeChange";

export default function StaffCancelHistory() {
  const [pagination, setPagination] = useState({ size: 10, page: 1 });
  const { data, isLoading } = useQuery(
    [`staffCancelBookingHistory-${pagination.size}-${pagination.page}`],
    () =>
      FetchApi.getHistoryCancelBooking({
        page: pagination.page,
        size: pagination.size,
        sort: "desc",
      })
  );

  const columns = [
    {
      title: STRINGS.day_go_store,
      dataIndex: "createdTime",
      key: "createdTime",
      align: "center",
      width: 220,
      render: (_, dataHistory) => {
        return LOGIC.funcFormat(dataHistory?.booking?.startTime);
      },
    },

    {
      title: STRINGS.user_name,
      dataIndex: "customerName",
      key: "customerName",
      align: "center",
      width: 150,
    },
    {
      title: STRINGS.furigana_name,
      dataIndex: "furiganaName",
      key: "furiganaName",
      align: "center",
      width: 150,
    },
    {
      title: "ID",
      dataIndex: "bookingID",
      key: "bookingID",
      align: "center",
      width: 100,
      render: (_, dataHistory) => {
        return dataHistory.booking?.id;
      },
    },
    {
      title: STRINGS.staff_recepit,
      dataIndex: "staffName",
      key: "staffName",
      align: "center",
      width: 220,
    },
    {
      title: STRINGS.staff_id,
      dataIndex: "staffId",
      key: "staffId",
      align: "center",
      width: 150,
    },
    {
      title: STRINGS.course_used,
      dataIndex: "packs",
      key: "packs",
      align: "center",
      width: 220,
      render: (e) => {
        return (e || []).map((i, index) => {
          if (i?.comboName) {
            return `${i.comboName + (index !== e?.length - 1 ? ", " : "")}`;
          }
          return "";
        });
      },
    },
    {
      title: STRINGS.cancellation_charge,
      dataIndex: "fee",
      key: "fee",
      align: "center",
      width: 220,
      render: (e) => LOGIC.currencyFormatter(e),
    },
    {
      title: STRINGS.payment_2,
      dataIndex: "paid",
      key: "paid",
      align: "center",
      width: 220,
      render: (e) => e && STRINGS.payment_2,
    },
    {
      title: STRINGS.type_cancel,
      dataIndex: "cancellationType",
      key: "cancellationType",
      align: "center",
      width: 220,
      render: (e) => LOGIC.cancelBookingType[e],
    },
    {
      title: STRINGS.time_cancel,
      dataIndex: "cancelTime",
      key: "cancelTime",
      align: "center",
      width: 220,
      render: (e) => LOGIC.funcFormat(e || moment()),
    },
  ];

  if (data && !data.success) {
    return <ErrorView data={data} />;
  }

  return (
    <Container>
      <Table
        loading={isLoading}
        rowKey={(e) => e.customerId + Math.random()}
        columns={columns}
        dataSource={data?.bookingCancellationHistories || []}
        locale={{ emptyText: STRINGS.no_data }}
        pagination={false}
        scroll={{ x: "auto", y: "calc(100vh - 350px)" }}
      />
      <PaginationWithSizeChange
        size={pagination.size}
        total={data?.page?.totalElements || 0}
        onChange={(pageNumber) => {
          setPagination({ size: pagination.size, page: pageNumber });
        }}
        onShowSizeChange={(e) => {
          setPagination({ size: e.size, page: 1 });
        }}
        current={pagination.page}
      />
    </Container>
  );
}
