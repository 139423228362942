import { Col, Row, TimePicker, message } from "antd";
import React, { useRef, useState } from "react";
import { ClockCircleFilled, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  ButtonCustom,
  LoadingAbsolute,
  ModalCustomService,
} from "../../../elements";
import { STRINGS } from "../../../constants";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util/CommonFunction";
import StaffConfigModal from "./StaffConfigModal/StaffConfigModal";
import RadioAllStoreStaff from "../../../elements/RadioAllStoreStaff";

import "../StaffScheduleManual.scss";

export default function ModalCheckinTime({
  staffInfo,
  scheduleSelected,
  shiftStatus,
  refetchCalendarOrder,
  disabled,
  selectedDate,
}) {
  const filtered = (staffInfo.workShiftDTOS || []).find(
    (itm) => itm.scheduleId === scheduleSelected
  );

  const checkInTime = filtered?.checkInTime;

  const [loading, setLoading] = useState(false);
  const staffChangeRequest = useRef({});
  const [newTime, setNewTime] = useState(moment(checkInTime));

  const onChange = async () => {
    if (!staffChangeRequest.current.id) {
      message.warning(STRINGS.please_select_a_reception_staff);
      return;
    }
    if (!staffChangeRequest.current.password) {
      message.warning(STRINGS.please_type_password);
      return;
    }
    try {
      setLoading(true);
      const result = await FetchApi.timekeepingCustom({
        staffChangeRequest: { ...staffChangeRequest.current, type: "check_in" },
        scheduleId: scheduleSelected,
        checkInTime: moment(newTime).format("HH:mm"),
      });
      setLoading(false);
      if (result.success) {
        refetchCalendarOrder();
        message.success(STRINGS.saved_common);
        ModalCustomService.close();
      } else if (result.code == 4003) {
        message.warning(STRINGS.password_is_incorrect);
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      setLoading(false);
      message.error(STRINGS.something_wrong);
    }
  };
  const onCancel = () => {
    ModalCustomService.set({
      wrapperStyle: { width: "auto", maxWidth: 1200 },
      contentWrapperStyle: { width: "fit-content" },
      contentStyle: { maxHeight: "80vh", padding: 0 },
      content: (
        <StaffConfigModal
          data={staffInfo}
          shiftStatus={shiftStatus}
          refetchCalendarOrder={refetchCalendarOrder}
          disabled={disabled}
          selectedDate={selectedDate}
        />
      ),
    });
  };

  return (
    <Col className="checkin-time-wrapper">
      <Row className="title-modal">
        <Row style={{ color: "white", fontSize: 16 }}>
          {STRINGS.correction_of_work_hours}
        </Row>
        <button onClick={ModalCustomService.close}>
          <CloseOutlined
            className="close"
            style={{ fontSize: "16px", color: "#fff" }}
          />
        </button>
      </Row>

      <Col style={{ padding: 10, width: 500 }}>
        <TimePicker
          value={newTime}
          suffixIcon={
            <ClockCircleFilled style={{ fontSize: 18, color: "#757575" }} />
          }
          style={{
            width: 200,
            borderRadius: 4,
            padding: "6px 10px",
          }}
          allowClear={false}
          format={"HH:mm"}
          onChange={(e) => {
            setNewTime(e);
          }}
          onSelect={(e) => {
            setNewTime(e);
          }}
        />

        <Row
          style={{
            color: "#4B2A05",
            fontSize: 18,
            paddingTop: 14,
            fontWeight: "bold",
            justifyContent: "center",
          }}>
          {STRINGS.please_select_a_reception_staff}
        </Row>

        <RadioAllStoreStaff
          onChange={(e) => (staffChangeRequest.current = e)}
        />

        <Row justify="center" style={{ margin: "20px 0px" }}>
          <ButtonCustom
            onClick={onCancel}
            isCancelButton
            style={{
              marginRight: 10,
            }}>
            {STRINGS.cancel}
          </ButtonCustom>
          <ButtonCustom onClick={onChange}>{STRINGS.sure}</ButtonCustom>
        </Row>
      </Col>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}
