import "rc-color-picker/assets/index.css";
import React, { memo, useState } from "react";
import ColorPicker from "rc-color-picker";
import { Input, Row } from "antd";

const AppPicker = memo(
  ({
    onChange,
    value,
    placement = "topLeft",
    hasInput = true,
    mode,
    disabled,
  }) => {
    const [color, setcolor] = useState(() => {
      if (mode === "rgb" && value) {
        const rgba = value.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
        const hex = `#${(
          (1 << 24) +
          (parseInt(rgba[0]) << 16) +
          (parseInt(rgba[1]) << 8) +
          parseInt(rgba[2])
        )
          .toString(16)
          .slice(1)}`;
        return { hex: hex, alpha: parseInt(rgba[3]) || 100 };
      }
      return value;
    });

    function hexToRgb(hex, convertNow) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.color);
      if (convertNow) {
        return result
          ? `rgba(${parseInt(result[1], 16)},${parseInt(
              result[2],
              16
            )},${parseInt(result[3], 16)},${hex.alpha})`
          : null;
      }
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    }

    function changeHandler(colors) {
      setcolor(colors.color);
      if (mode === "rgb") {
        onChange && onChange(hexToRgb(colors, true));
        return;
      }
      onChange && onChange(colors.color);
    }

    return (
      <Row style={{ alignItems: "center" }}>
        {hasInput && (
          <Input
            style={{ width: 220, marginRight: 10 }}
            value={color}
            onChange={(e) => {
              onChange && onChange(e.target.value);
              setcolor(e.target.value);
            }}
            maxLength={7}
            disabled={disabled}
          />
        )}
        <div>
          {disabled && (
            <div
              style={{ position: "absolute", width: "100%", height: "100%" }}
            />
          )}
          <ColorPicker
            color={mode === "rgb" ? color?.hex : color}
            alpha={color?.alpha}
            onChange={changeHandler}
            placement={placement}
            className="some-class">
            <span className="rc-color-picker-trigger" />
          </ColorPicker>
        </div>
      </Row>
    );
  }
);

export { AppPicker };
