import { Col, message, Row } from "antd";
import React, { useState } from "react";
import { STRINGS } from "../../../constants/Strings";
import {
  ButtonCustom,
  ModalCustomService,
  LoadingAbsolute,
} from "../../../elements";
import "../StaffScheduleManual.scss";
import { FetchApi } from "../../../api";
import { CommonFunction } from "../../../util/CommonFunction";
import { COLORS } from "../../../constants";

function ModalRestTime({ staffInfo, refetchCalendarOrder }) {
  const [minute, setMinute] = useState(staffInfo.restMinute || 30);
  const [loading, setLoading] = useState(false);
  const list = [
    // { value: 10, label: "10分" },
    { value: 20, label: "20分" },
    { value: 30, label: "30分" },
  ];

  const onCheckIn = async () => {
    try {
      setLoading(true);
      const result = await FetchApi.timekeepingStaff({
        restMinute: minute,
        type: "check_in",
        staffId: staffInfo.staffId,
      });
      setLoading(false);
      if (result.success) {
        refetchCalendarOrder();
        ModalCustomService.close();
        message.success(STRINGS.checkin_success);
      } else if (result.code == 4101 || result.code == 4102) {
        message.warning(result.message);
      } else if (result.code == 4004) {
        message.error(STRINGS.cannot_checkin_because_error);
      } else {
        CommonFunction.logError(result);
      }
    } catch (error) {
      message.error(STRINGS.something_wrong);
      setLoading(false);
    }
  };

  return (
    <Col className="checkin-time-wrapper">
      <Row
        gutter={30}
        style={{
          marginTop: 24,
          marginBottom: 20,
          alignItems: "center",
          justifyContent: "center",
        }}>
        <Col style={{ fontWeight: "bold" }}>{STRINGS.interval_time}</Col>
        {list.map((item) => {
          return (
            <Col
              key={`${item.value}`}
              onClick={() => setMinute(item.value)}
              style={{
                cursor: "pointer",
                width: 100,
                borderRadius: 3,
                padding: "6px 10px",
                margin: "0px 4px",
                border:
                  minute !== item.value
                    ? "1px solid gray"
                    : `1px solid ${COLORS.primary}`,
                color: minute !== item.value ? "gray" : COLORS.primary,
              }}>
              {item.label}
            </Col>
          );
        })}
      </Row>

      <Row justify="center" style={{ margin: "20px 0px" }}>
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onCheckIn}>{STRINGS.checkin}</ButtonCustom>
      </Row>

      <Row
        style={{
          justifyContent: "center",
          fontWeight: "bold",
          marginBottom: 10,
        }}>
        {STRINGS.describle_checkin}
      </Row>
      {loading && <LoadingAbsolute />}
    </Col>
  );
}

export default ModalRestTime;
