import React from "react";
import { Select, Form } from "antd";
import { SuffixIconDown, Validate } from "../../../elements";
import { STRINGS } from "../../../constants";

function News({ newsList, data }) {
  const defaultNew = () => {
    const item = newsList.find((item) => item.id === data?.newsId);
    return item?.title;
  };
  return (
    <Form.Item
      initialValue={defaultNew()}
      className="form-action"
      name="newsId"
      style={{ paddingTop: 20 }}
      rules={[Validate.emptyContent]}>
      <Select
        suffixIcon={<SuffixIconDown />}
        placeholder={STRINGS.please_select_one_noti}>
        {newsList.map((item) => {
          return (
            <Select.Option key={item.id} value={item.id}>
              {item.title}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
}

export default News;
