import React, { useState, useEffect, useRef } from "react";
import { Select, Col, Row } from "antd";

import { FetchApi } from "../../../api";
import { SuffixIconDown, Validate } from "../../../elements";
import { STRINGS } from "../../../constants/Strings";
import Auth from "../../../store/Authentication";

const { Option } = Select;

const StaffList = ({
  style,
  onChange,
  value,
  disabled,
  isRequired = true,
  optionAllStaff,
  label,
}) => {
  const [loading] = useState(false);
  const [data, setData] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    size: 20,
    last: false,
    refetch: false,
  });
  const staffName = useRef("");
  const timeout = useRef();
  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const handleChange = async (e) => {
    onChange && onChange(e);
  };
  const onScroll = (event) => {
    var target = event.target;
    if (
      !loading &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      if (!pagination.last) {
        setPagination((prev) => {
          return { ...prev, page: prev.page + 1 };
        });
      }
    }
  };

  const fetch = async () => {
    const result = await FetchApi.getListStaff(
      userAdmin.storeId,
      pagination.page,
      pagination.size,
      null,
      staffName.current
    );
    if (result.success) {
      setData((prev) => {
        if (pagination.page === 1) {
          return [...result.data];
        }
        return [...prev, ...result.data];
      });
      setPagination((prev) => {
        return { ...prev, last: result.pageDto.last };
      });
    }
  };

  useEffect(() => {
    fetch();
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, pagination.refetch]);

  let dataCheckLoading = [];

  if (!pagination.last && Array.isArray(data)) {
    dataCheckLoading = [...data, { id: "more", name: "..." }];
  }
  if (Array.isArray(data) && pagination.last) {
    dataCheckLoading = data;
  }
  if (optionAllStaff) {
    dataCheckLoading = [{ id: "all", name: STRINGS.all }, ...dataCheckLoading];
  }
  return (
    <Col>
      <Row style={{ color: "#757575", marginBottom: 4 }}>
        {label}
        {!!isRequired && <Col style={{ color: "red" }}>{"\xa0*"}</Col>}
      </Row>
      <Select
        disabled={disabled}
        defaultValue={value}
        showSearch
        allowClear={true}
        placeholder={STRINGS.staff_name_2}
        onChange={handleChange}
        onBlur={() => {}}
        onSearch={(e) => {
          const converted = Validate.removeSpecialCharactors(e);
          staffName.current = converted || null;
          if (timeout.current) {
            clearTimeout(timeout.current);
          }
          timeout.current = setTimeout(() => {
            setPagination({
              page: 1,
              size: 20,
              last: true,
              refetch: !pagination.refetch,
            });
          }, 800);
        }}
        optionFilterProp="children"
        filterOption={null}
        onPopupScroll={onScroll}
        suffixIcon={<SuffixIconDown />}
        style={{ width: 300, alignSelf: "flex-end", ...style }}
      >
        {dataCheckLoading
          .filter((i) => !i.deleted)
          .map((item, index) => (
            <Select.Option
              key={item.id}
              value={item.id}
              disabled={item.id === "more"}
            >
              {item.name}
            </Select.Option>
          ))}
      </Select>
    </Col>
  );
};

export { StaffList };
