import React from "react";
import { Col, Select } from "antd";

import { STRINGS } from "../../../constants";
import { SuffixIconDown } from "../../../elements";

import "../StaffBooking.scss";

function StaffBookingNumberPeople({ form, onChange }) {
  return (
    <div className="book-num-container">
      <div className="text-booking">{STRINGS.des_booking_2}</div>
      <div className="book-num">
        <Col style={{ marginLeft: 10 }}>
          {/* <Col>{STRINGS.num_user}</Col> */}
          <Select
            defaultValue={form.getFieldValue("bookingNum") || 1}
            onChange={(val) => {
              onChange(val);
            }}
            style={{ textAlign: "left", width: 94, height: 40 }}
            suffixIcon={<SuffixIconDown />}>
            {[1, 2, 3, 4].map((item) => {
              return (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              );
            })}
          </Select>
        </Col>
      </div>
    </div>
  );
}

export { StaffBookingNumberPeople };
