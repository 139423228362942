import React, { useState } from "react";
import { Col, Form, Row, message, InputNumber, Select } from "antd";
import { useQuery } from "react-query";
import { PlusCircleOutlined } from "@ant-design/icons";

import {
  ButtonCustom,
  ModalCustomService,
  LoadingAbsolute,
  SuffixIconDown,
  LoadingView,
  SvgView,
} from "../../../../elements";
import { Convert, Validate } from "../../../../elements/Validate";
import { CommonFunction } from "../../../../util/CommonFunction";
import { FetchApi } from "../../../../api";
import { ConfigPriceSelectGender } from "./items/ConfigPriceSelectGender";
import { ConfigPriceLate } from "./items/ConfigPriceLate";
import { ConfigPriceBonus } from "./items/ConfigPriceBonus";
import { useGetComboList } from "../../../../util/hooks/useGetComboList";
import { COLORS, STRINGS, Svgs } from "../../../../constants";
import { useGetStoreSurcharge } from "./modules/useGetStoreSurcharge";
import Auth from "../../../../store/Authentication";

import "../../Combo.scss";

function useGetComboRevenue() {
  const { data, isLoading, refetch, isFetching } = useQuery(
    [`useGetComboRevenue-${1}`],
    () => FetchApi.getComboRevenue()
  );
  return {
    data: data,
    refetch: refetch,
    isLoading: isLoading,
    isFetching: isFetching,
  };
}

const ConfigPrice = () => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const { data, isLoading, refetch } = useGetComboRevenue();
  const { listCombo, isLoadingGetComboList } = useGetComboList();
  const { storeSurcharge, isLoading: isLoadingStoreSurcharge } =
    useGetStoreSurcharge();

  const userAdmin = JSON.parse(Auth.getCurrentUser());

  const onEdit = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      setLoading(true);

      const listDeleted = Array.isArray(fieldData?.listDeleted)
        ? fieldData?.listDeleted
        : [];
      const listChange = fieldData.prolongedCourseDTOS;
      const prolongedCourseRequests = [...listChange, ...listDeleted]
        .map((i) => {
          let id = 0;
          if (i.id) {
            id = i.id;
          }
          //TODO:
          return {
            ...i,
            id: id,
          };
        })
        .filter((i) => !i.isDeleted);

      const result = await FetchApi.updateComboRevenue({
        prolongedCourseRequests: prolongedCourseRequests,
      });
      setLoading(false);
      if (result.success) {
        refetch();
        message.success(STRINGS.saved_common);
        return;
      }
      if (result.code === 4005) {
        message.error(STRINGS.minute_extra_duplicate);
        return;
      }
      CommonFunction.logError(result);
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };
  const onEditAdditionalPrice = async () => {
    try {
      await form.validateFields();
      const fieldData = form.getFieldValue();
      setLoading(true);

      const comboSurchargeRequest = {
        lateFee: fieldData.lateFee,
        surchargeDesignation: fieldData.surchargeDesignation,
        surchargeForMen: fieldData.surchargeForMen,
        courseIds: fieldData.courseIds.map((i) => i.value || i),
      };
      let result;
      if (storeSurcharge?.id) {
        result = await FetchApi.updateStoreSurcharge(comboSurchargeRequest);
      } else {
        result = await FetchApi.createStoreSurcharge(comboSurchargeRequest);
      }

      setLoading(false);
      if (result.success) {
        refetch();
        message.success(STRINGS.saved_common);
        return;
      }
      if (result.code === 4005) {
        message.error(STRINGS.minute_extra_duplicate);
        return;
      }
      CommonFunction.logError(result);
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  if (isLoading || isLoadingGetComboList || isLoadingStoreSurcharge) {
    return <LoadingView />;
  }

  return (
    <Col
      className="combo-form"
      style={{ padding: 20, backgroundColor: "white" }}>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          prolongedCourseDTOS: data?.prolongedPackDTOS || [],
          courseIds: Convert.utilData(storeSurcharge?.courseDTOs, "id"),
          lateFee: storeSurcharge?.lateFee,
          surchargeDesignation: storeSurcharge?.surchargeDesignation,
          surchargeForMen: storeSurcharge?.surchargeForMen,
        }}>
        <Row
          className="theader"
          style={{ textAlign: "center", minWidth: 1000 }}>
          <Col span={23}>
            <Row>
              <Col span={4}>{STRINGS.config_menu}</Col>
              <Col span={1} />
              <Col span={2}>{STRINGS.time}</Col>
              <Col span={3}>{STRINGS.app_member}</Col>
              <Col span={3}>{STRINGS.price_combo_no_member}</Col>
              <Col span={3}>{STRINGS.list_price}</Col>
              <Col span={3}>{STRINGS.price_job}</Col>
              <Col span={3}>{STRINGS.price_tax}</Col>
              <Col span={2}>{STRINGS.grant_points}</Col>
            </Row>
          </Col>
        </Row>

        <Form.List name="prolongedCourseDTOS">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <Col
                    key={field.key}
                    className={index === 0 ? "col-wrapper-row-input" : 0}
                    style={{ minWidth: 1000 }}>
                    <Row className="row-table-custom">
                      <Col span={23}>
                        <Row>
                          <Col span={4}>
                            <Form.Item
                              {...field}
                              name={[field.name, "comboId"]}
                              fieldKey={[field.fieldKey, "comboId"]}
                              rules={[Validate.emptyContent]}>
                              <Select
                                style={{
                                  textAlign: "left",
                                  marginLeft: 10,
                                  width: 170,
                                }}
                                suffixIcon={<SuffixIconDown />}>
                                {listCombo.map((item) => {
                                  return (
                                    <Select.Option
                                      key={item.id}
                                      value={item.id}>
                                      {item.name}
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ paddingTop: 5 }}>
                            {STRINGS.extension}
                          </Col>

                          <Col span={2}>
                            <Form.Item
                              {...field}
                              name={[field.name, "minutes"]}
                              fieldKey={[field.fieldKey, "minutes"]}
                              rules={[Validate.isPositiveNumber]}>
                              <InputNumber
                                style={{
                                  color: COLORS.primary,
                                  fontWeight: "bold",
                                }}
                                formatter={(value) => `${value} 分`}
                                parser={(value) =>
                                  value.replace("分", "").replace(" ", "")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              {...field}
                              name={[field.name, "priceMemberApp"]}
                              fieldKey={[field.fieldKey, "priceMemberApp"]}
                              rules={[Validate.isNumberPointPrice]}>
                              <InputNumber
                                style={{ fontWeight: "bold" }}
                                formatter={(value) =>
                                  `${value.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )} 円`
                                }
                                parser={(value) =>
                                  value
                                    .replace("円", "")
                                    .replace(" ", "")
                                    .replace(/\$\s?|(,*)/g, "")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              {...field}
                              name={[field.name, "priceNonApp"]}
                              fieldKey={[field.fieldKey, "priceNonApp"]}
                              rules={[Validate.isNumberPointPrice]}>
                              <InputNumber
                                style={{ fontWeight: "bold" }}
                                formatter={(value) =>
                                  `${value.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )} 円`
                                }
                                parser={(value) =>
                                  value
                                    .replace("円", "")
                                    .replace(" ", "")
                                    .replace(/\$\s?|(,*)/g, "")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              {...field}
                              name={[field.name, "priceDefault"]}
                              fieldKey={[field.fieldKey, "priceDefault"]}
                              rules={[Validate.isNumberPointPrice]}>
                              <InputNumber
                                style={{ fontWeight: "bold" }}
                                formatter={(value) =>
                                  `${value.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )} 円`
                                }
                                parser={(value) =>
                                  value
                                    .replace("円", "")
                                    .replace(" ", "")
                                    .replace(/\$\s?|(,*)/g, "")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              {...field}
                              name={[field.name, "staffWage"]}
                              fieldKey={[field.fieldKey, "staffWage"]}
                              rules={[Validate.isNumberPointPrice]}>
                              <InputNumber
                                style={{ fontWeight: "bold" }}
                                formatter={(value) =>
                                  `${value.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )} 円`
                                }
                                parser={(value) =>
                                  value
                                    .replace("円", "")
                                    .replace(" ", "")
                                    .replace(/\$\s?|(,*)/g, "")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              {...field}
                              name={[field.name, "tax"]}
                              fieldKey={[field.fieldKey, "tax"]}
                              rules={[Validate.isNumberPointPrice]}>
                              <InputNumber
                                style={{ fontWeight: "bold" }}
                                formatter={(value) =>
                                  `${value.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )} 円`
                                }
                                parser={(value) =>
                                  value
                                    .replace("円", "")
                                    .replace(" ", "")
                                    .replace(/\$\s?|(,*)/g, "")
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <Form.Item
                              {...field}
                              name={[field.name, "point"]}
                              fieldKey={[field.fieldKey, "point"]}
                              rules={[Validate.isNumberPointPrice]}>
                              <InputNumber
                                style={{ fontWeight: "bold" }}
                                formatter={(value) => `${value} pt`}
                                parser={(value) =>
                                  value.replace("pt", "").replace(" ", "")
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={1} style={{ textAlign: "center" }}>
                        <button
                          style={{
                            borderWidth: 0,
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            cursor: "pointer",
                            marginTop: 5,
                          }}
                          onClick={() => {
                            const listDeleted =
                              form.getFieldValue("listDeleted") || [];
                            const listOriginal = form.getFieldValue(
                              "prolongedCourseDTOS"
                            );

                            form.setFieldsValue({
                              listDeleted: [
                                ...listDeleted,
                                {
                                  ...listOriginal[field.key],
                                  isDeleted: true,
                                },
                              ],
                            });

                            remove(field.name);
                          }}>
                          <SvgView svgData={Svgs.delete} />
                        </button>
                      </Col>
                    </Row>
                  </Col>
                ))}

                <Row style={{ marginTop: 20, justifyContent: "space-between" }}>
                  <ButtonCustom
                    onClick={() => add()}
                    icon={<PlusCircleOutlined />}>
                    {STRINGS.add_more_course}
                  </ButtonCustom>
                  <ButtonCustom onClick={onEdit}>{STRINGS.update}</ButtonCustom>
                </Row>
              </>
            );
          }}
        </Form.List>
        {userAdmin.type === "STORE_ADMIN" && (
          <Col>
            <ConfigPriceSelectGender />
            <ConfigPriceLate />
            <ConfigPriceBonus />
          </Col>
        )}
      </Form>
      {userAdmin.type === "STORE_ADMIN" && (
        <Row justify="center" style={{ margin: "20px 0px" }}>
          <ButtonCustom
            onClick={ModalCustomService.close}
            isCancelButton
            style={{
              marginRight: 10,
            }}>
            {STRINGS.cancel}
          </ButtonCustom>
          <ButtonCustom onClick={onEditAdditionalPrice}>
            {STRINGS.update}
          </ButtonCustom>
        </Row>
      )}
      {loading && <LoadingAbsolute />}
    </Col>
  );
};

export default ConfigPrice;
