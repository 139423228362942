/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Col, Row, Form } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { COLORS, STRINGS } from "../../../constants";

import "../StaffBooking.scss";

function StaffBookingComboAllItem({
  combo,
  form,
  comboStoreSelected,
  setComboStoreSelected,
  idFatherCombo,
  numberOrdinal,
  idGrandFatherCombo,
}) {
  const renderSelect = () => {
    if (combo.id === comboStoreSelected[idFatherCombo]?.id) {
      return <div className="radio-button-select" />;
    }
  };
  return (
    <Col
      onClick={() => {
        if (combo.id === comboStoreSelected[idFatherCombo]?.id) {
          comboStoreSelected[idFatherCombo] = null;
          form.setFieldsValue({
            [`bookingComboStore${numberOrdinal}`]: { ...comboStoreSelected },
          });
          setComboStoreSelected({ ...comboStoreSelected });

          return;
        }
        comboStoreSelected[idFatherCombo] = {
          ...combo,
          idGrandFatherCombo: idGrandFatherCombo,
        };
        form.setFieldsValue({
          [`bookingComboFav${numberOrdinal}`]: null,
          [`bookingMinute${numberOrdinal}`]: null,
          [`bookingComboStore${numberOrdinal}`]: { ...comboStoreSelected },
        });

        setComboStoreSelected({ ...comboStoreSelected });
      }}
      style={{
        borderRadius: "5px",
        flex: 1,
        margin: "5px",
        cursor: "pointer",
      }}>
      <Row
        className="minute-combo"
        style={{
          backgroundColor: COLORS.primary,
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          padding: "10px 20px",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div
          className="radio-button"
          style={{ position: "absolute", left: "20px" }}>
          {renderSelect()}
        </div>
        <div>
          {combo.minutes}
          {STRINGS.minute}
        </div>
      </Row>
      <Row
        style={{
          border: `1px solid ${COLORS.border}`,
          borderBottomLeftRadius: "5px",
          borderBottomRightRadius: "5px",
          backgroundColor: COLORS.background,
        }}>
        <Col span={8} style={{ padding: "10px 5px" }}>
          <div className="price_member">{STRINGS.app_member + "\n"}</div>
          <div className="price_member_yen">
            {combo.priceMemberApp}
            {STRINGS.yen}
          </div>
        </Col>
        <Col span={8} style={{ padding: "10px 5px" }}>
          <div className="price_text">{STRINGS.app_non_member + "\n\n\n"}</div>
          <div className="price_text_yen">
            {combo.priceNonApp} {STRINGS.yen}
          </div>
        </Col>
        <Col span={8} style={{ padding: "10px 5px" }}>
          <div className="price_text">{STRINGS.list_price + "\n"}</div>
          <div className="price_text_yen">
            {combo.priceDefault} {STRINGS.yen}
          </div>
        </Col>
      </Row>
    </Col>
  );
}

function StaffBookingComboStoreList({ item, form, numberOrdinal, isUpdate }) {
  const [isShowComponent, setIsShowComponent] = useState(false);
  const [comboStoreSelected, setComboStoreSelected] = useState(() => {
    if (form.getFieldValue(`bookingComboStore${numberOrdinal}`)) {
      return form.getFieldValue(`bookingComboStore${numberOrdinal}`);
    }
    return {};
  });
  const packComboStore = Array.isArray(item.comboDTOS) ? item.comboDTOS : [];

  useEffect(() => {
    if (isUpdate) {
      setComboStoreSelected({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  // let color = COLORS.sub_text;
  let rotate = 0;
  let backgroundColor = COLORS.background;
  if (isShowComponent) {
    // color = COLORS.accent;
    rotate = 180;
  }
  if (comboStoreSelected) {
    for (const [_, value] of Object.entries(comboStoreSelected)) {
      if (value?.idGrandFatherCombo === item.categoryId) {
        backgroundColor = COLORS.sub_primary;
      }
    }
  }
  return (
    <Col
      onClick={() => {
        setIsShowComponent(!isShowComponent);
      }}
      style={{
        border: `1px solid ${COLORS.border}`,
        padding: "10px",
        width: "48%",
        borderRadius: "5px",
        height: "fit-content",
        backgroundColor,
        marginBottom: "10px",
      }}>
      <Row style={{ justifyContent: "space-between" }}>
        <Row className="text-des">{item.categoryName}</Row>
        <Row
          style={{
            flex: 1,
            justifyContent: "flex-end",
          }}>
          <DownOutlined rotate={rotate} style={{ paddingTop: "5px" }} />
        </Row>
      </Row>
      {isShowComponent && (
        <Col>
          {packComboStore.map((combo) => {
            return (
              <Col
                key={`${combo.id}`}
                style={{ marginBottom: "10px", paddingTop: "10px" }}>
                <Col className="text-des">{combo.name}</Col>

                <Col
                  style={{
                    textAlign: "center",
                    padding: "5px",
                    color: COLORS.sub_accent,
                    fontWeight: "bold",
                  }}>
                  {combo.description}
                </Col>
                <Row>
                  {(combo.comboPackDTOS || []).map((comboDetail) => {
                    return (
                      <Col key={`${comboDetail.id}`} xl={12} xs={24}>
                        <StaffBookingComboAllItem
                          combo={comboDetail}
                          form={form}
                          idFatherCombo={combo.id}
                          idGrandFatherCombo={item.categoryId}
                          numberOrdinal={numberOrdinal}
                          comboStoreSelected={comboStoreSelected}
                          setComboStoreSelected={setComboStoreSelected}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            );
          })}
        </Col>
      )}
    </Col>
  );
}

function StaffBookingComboStore({ form, data = [], numberOrdinal }) {
  const [isUpdate, setIsUpdate] = useState(false);
  return (
    <Form.Item
      name={`bookingComboStore${numberOrdinal}`}
      initialValue={{}}
      shouldUpdate={(prevValues, curValues) => {
        if (
          curValues[`bookingMinute${numberOrdinal}`] ||
          curValues[`bookingComboFav${numberOrdinal}`]
        ) {
          setIsUpdate(true);
          return;
        }
        setIsUpdate(false);
      }}>
      <Col style={{ padding: "15px 0px" }}>
        <Row style={{ justifyContent: "space-between" }}>
          {data.map((item) => {
            return (
              <StaffBookingComboStoreList
                key={`${item.categoryId}`}
                form={form}
                item={item}
                numberOrdinal={numberOrdinal}
                isUpdate={isUpdate}
              />
            );
          })}
        </Row>
      </Col>
    </Form.Item>
  );
}

export { StaffBookingComboStore };
