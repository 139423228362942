/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Form, DatePicker, Radio, Input } from "antd";
import React, { useState, useRef, useEffect, forwardRef } from "react";
import { useQuery } from "react-query";
import moment from "moment";

import "../StaffScheduleManual.scss";
import { COLORS, STRINGS, Svgs } from "../../../constants";
import { FetchApi } from "../../../api";
import { LoadingAbsolute, LoadingView } from "../../../elements";
import ModalBookingDetailTableCombo from "../elements/ModalBookingDetailTableCombo";
import ModalBookingDetailTableRight from "../elements/ModalBookingDetailTableRight";

import { ModalBookingDetailPayment } from "../elements/ModalBookingDetailPayment";
import { ModalBookingDetailComment } from "../elements/ModalBookingDetailComment";
import { BookingManualDetailCoupon } from "../../booking-manual-detail/items/BookingManualDetailCoupon";
import {
  useConvertCourseBooking,
  useGetPackStore,
} from "../../booking-manual-detail/modules/useGetPackStore";

function useGetDetailBooking({ bookingId }) {
  const { data, refetch, isLoading, isFetching } = useQuery(
    [`getDetailBookingManual-${bookingId}`],
    () => FetchApi.bookingManualDetail({ bookingId })
  );
  return {
    detailBooking: data?.bookingHistories?.[0] || {},
    refetch: refetch,
    isLoadingDetail: isLoading,
    isFetchingDetail: isFetching,
  };
}

const ModalBookingDetail = forwardRef((props, ref) => {
  const { refetch, data, disabled } = props;
  const [NG, setNG] = useState([]);
  const [form] = Form.useForm();

  const { detailBooking, isLoadingDetail, isFetchingDetail } =
    useGetDetailBooking({
      bookingId: data.id,
    });
  const { dataCombo, dataProlonged, isLoading } = useGetPackStore({
    userId: detailBooking.user?.id,
  });
  const { detailCourseBooking } = useConvertCourseBooking({
    data: detailBooking,
    dataCombo,
    dataProlonged,
  });

  const [combosPicked] = useState(detailCourseBooking);

  const { data: listCoupon } = useQuery("couponAll", () =>
    FetchApi.getCouponBooking()
  );
  const [total, setTotal] = useState(detailBooking.amount || 0);
  const commentRef = useRef(detailBooking.comment);

  useEffect(() => {
    setTotal(detailBooking.amount || 0);
  }, [isFetchingDetail, isLoadingDetail]);

  if (isLoading || isLoadingDetail || isFetchingDetail) {
    return <LoadingView />;
  }

  let couponSelected;
  const couponDTO = {};
  listCoupon?.couponDTOList?.map?.((i) => {
    return (couponDTO[i.id] = i);
  });
  return (
    <Form
      style={{ pointerEvents: "none" }}
      form={form}
      initialValues={{
        startTime: moment(new Date(detailBooking.start)),
        couponIds: detailBooking.couponIds?.map?.((i) => couponDTO[i]) || [],
        assigned: detailBooking.assigned,
        numberOfPointsUsed: detailBooking.numberOfPointsUsed,
        comboDTOS: detailCourseBooking || [undefined],
      }}>
      <Row className="modal-booking-detail-wrapper">
        <Col className="customer-info">
          <Row
            style={{
              margin: "12px 0px 5px 0px",
            }}>
            <Col span={12} style={{ fontWeight: "bold" }}>
              <Row
                style={{
                  textAlign: "left",
                  marginTop: 4,
                }}>
                <Col
                  span={8}
                  style={{
                    fontWeight: "bold",
                    color: COLORS.sub_accent,
                    fontSize: 22,
                  }}>
                  {STRINGS.day_go_store}
                </Col>
                <Col span={16}>
                  <Form.Item style={{ marginBottom: 0 }} name="startTime">
                    <DatePicker
                      suffixIcon={Svgs.datepicker()}
                      style={{
                        borderRadius: 5,
                        width: "90%",
                        border: `1px solid ${COLORS.sub_text}`,
                        fontSize: 15,
                      }}
                      placeholder={""}
                      format={"MM月DD日 HH:mm"}
                      showTime
                      minuteStep={10}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{
                  textAlign: "left",
                  marginTop: 14,
                }}>
                <Col
                  span={8}
                  style={{
                    fontWeight: "bold",
                    color: COLORS.sub_accent,
                    fontSize: 22,
                  }}>
                  {STRINGS.responsible_person}
                </Col>
                <Col
                  span={16}
                  style={{
                    fontWeight: "bold",
                    color: COLORS.sub_accent,
                    fontSize: 22,
                  }}>
                  {detailBooking.staffName}
                </Col>
              </Row>
              <Row
                style={{
                  textAlign: "left",
                  marginTop: 10,
                  alignItems: "center",
                }}>
                <Col
                  span={8}
                  style={{
                    fontWeight: "bold",
                    color: COLORS.sub_accent,
                    fontSize: 22,
                  }}>
                  {STRINGS.coupon_management}
                </Col>
                <Col span={16}>
                  <Form.Item style={{ marginBottom: 0 }} name="couponIds">
                    <BookingManualDetailCoupon
                      setTotal={setTotal}
                      data={data}
                      listCoupon={listCoupon}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12} style={{ fontWeight: "bold" }}>
              <Row
                style={{
                  textAlign: "left",
                  alignItems: "center",
                }}>
                <Col
                  span={8}
                  style={{
                    fontWeight: "bold",
                    color: COLORS.sub_accent,
                    fontSize: 22,
                  }}>
                  {STRINGS.is_assign}
                </Col>
                <Col span={16}>
                  <Form.Item name="assigned" style={{ marginBottom: 0 }}>
                    <Radio.Group size="large">
                      <Radio
                        value={true}
                        style={{ fontSize: 22, color: COLORS.sub_accent }}>
                        {STRINGS.yes}
                      </Radio>
                      <Radio
                        value={false}
                        style={{ fontSize: 22, color: COLORS.sub_accent }}>
                        {STRINGS.no}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{
                  textAlign: "left",
                  marginTop: 20,
                  alignItems: "center",
                }}>
                <Col
                  span={8}
                  style={{
                    fontWeight: "bold",
                    color: COLORS.sub_accent,
                    fontSize: 22,
                  }}>
                  {STRINGS.discount_point}
                </Col>
                <Col span={16}>
                  <Form.Item
                    style={{ marginBottom: 0 }}
                    required
                    name="numberOfPointsUsed">
                    <Input
                      disabled={
                        (data.user?.customerType || data.user?.type) === "P"
                      }
                      style={{
                        minHeight: 32,
                        border: `1px solid ${COLORS.sub_text}`,
                        fontSize: 18,
                        color: COLORS.sub_accent,
                        fontWeight: "bold",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Col>
            <ModalBookingDetailTableCombo
              form={form}
              data={detailBooking}
              combosPicked={combosPicked}
              detailCourseBooking={detailCourseBooking}
            />
          </Col>

          <ModalBookingDetailPayment
            disabled={disabled}
            total={total}
            form={form}
            data={data}
            couponSelected={couponSelected}
          />

          <ModalBookingDetailComment
            data={data}
            commentRef={commentRef}
            form={form}
            total={total}
            refetch={refetch}
          />
        </Col>

        <ModalBookingDetailTableRight
          userId={detailBooking.user?.id}
          NG={NG}
          setNG={setNG}
          data={detailBooking.user || {}}
          disabled={disabled}
          refetch={refetch}
        />
        {isLoading && <LoadingAbsolute />}
      </Row>
    </Form>
  );
});
export default ModalBookingDetail;
