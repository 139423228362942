import { Dropdown, Menu, Pagination, Row } from "antd";
import React, { useState } from "react";
import { COLORS } from "../constants";
import { SuffixIconDown } from "./SuffixIconDown";

const PaginationWithSizeChange = ({
  styles,
  onShowSizeChange,
  size,
  total,
  onChange,
  current,
}) => {
  const [pageSize, setPageSize] = useState(size);
  const listItem = [
    {
      key: "10",
      label: "10件",
      style: {
        textAlign: "center",
      },
    },
    {
      key: "20",
      label: "20件",
      style: {
        textAlign: "center",
      },
    },
    {
      key: "30",
      label: "30件",
      style: {
        textAlign: "center",
      },
    },
  ];
  const menu = (
    <Menu
      onClick={(e) => {
        setPageSize(e.key);
        onShowSizeChange({ size: e.key, page: 1 });
      }}
      items={listItem}>
      {/* <Menu.Item key="10" style={{ textAlign: "center" }}>
        10件
      </Menu.Item>
      <Menu.Item key="20" style={{ textAlign: "center" }}>
        20件
      </Menu.Item>
      <Menu.Item key="30" style={{ textAlign: "center" }}>
        30件
      </Menu.Item> */}
    </Menu>
  );

  return (
    <Row
      style={{
        margin: "0px 0px 30px 0px",
        padding: "16px",
        backgroundColor: "#fff",
        justifyContent: "space-between",
        alignItems: "top",
      }}>
      <Dropdown
        style={{
          fontSize: 16,
          borderRadius: 4,
          padding: "6px 40px",
          height: "fit-content",
        }}
        overlay={menu}
        trigger={["click"]}>
        <button
          style={{
            marginBottom: 10,
            display: "flex",
            flexDirection: "row",
            padding: "6px 10px",
            width: "150px",
            alignItems: "center",
            justifyContent: "space-between",
            height: "fit-content",
            borderRadius: 4,
            borderWidth: 1,
            backgroundColor: "transparent",
            borderColor: COLORS.disabled,
            cursor: "pointer",
            ...styles,
          }}>
          <div />
          <span>{pageSize}件</span>
          <SuffixIconDown />
        </button>
      </Dropdown>
      <Pagination
        pageSize={pageSize}
        total={total || 1}
        onChange={onChange}
        current={current}
        showSizeChanger={false}
      />
    </Row>
  );
};

export default PaginationWithSizeChange;
