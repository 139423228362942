import React from "react";
import { LoadingView, ModalCustomService } from "../../../elements";
import { Col, Row } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { STRINGS } from "../../../constants";
import { useQuery } from "react-query";
import { FetchApi } from "../../../api";

function ModalStepDetail() {
  const { data, isLoading } = useQuery(
    "getStaffStatusFlow",
    FetchApi.getStaffStatusFlow
  );

  if (isLoading) return <LoadingView />;

  return (
    <Col>
      <Row className="title-modal">
        <Row style={{ color: "white", fontSize: 25, fontWeight: 700 }}>
          {STRINGS.detail}
        </Row>
        <button onClick={ModalCustomService.close}>
          <CloseOutlined
            className="close"
            style={{ fontSize: "16px", color: "#fff" }}
          />
        </button>
      </Row>
      <Col style={{ padding: 10 }}>
        <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
      </Col>
    </Col>
  );
}
export default ModalStepDetail;
