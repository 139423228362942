import { useQuery } from "react-query";

import { FetchApi } from "../../../../../api";

const tag = "getStoreSurcharge";

const useGetStoreSurcharge = () => {
  const value = useQuery(`${tag}`, () => FetchApi.getStoreSurcharge());
  return {
    storeSurcharge: value.data?.storeSurcharge,
    ...value,
  };
};

export { useGetStoreSurcharge };
