import React, { useRef } from "react";
import { Form, Select } from "antd";

import { STRINGS } from "../../../constants";
import { SuffixIconDown, Validate } from "../../../elements";
import Auth from "../../../store/Authentication";

const { Option } = Select;

const FilterByStore = ({
  storeId,
  listStore,
  onSearchByStore,
  canDisabled,
}) => {
  const userAdmin = JSON.parse(Auth.getCurrentUser());
  const storeSelected = useRef(
    listStore.find(
      (item) => item.id === storeId || item.id === userAdmin.storeId
    )
  );

  const onSelect = (e) => {
    onSearchByStore(e);
  };

  return (
    <Form.Item
      name="store"
      initialValue={storeSelected.current?.name}
      rules={[Validate.emptyContent]}>
      <Select
        suffixIcon={<SuffixIconDown />}
        placeholder={STRINGS.select_one_store}
        onSelect={onSelect}
        disabled={(canDisabled && storeId) || userAdmin.type === "STORE_ADMIN"}
        style={{ width: 300 }}>
        {listStore.sort(Validate.sortPosition).map((itm) => (
          <Option key={"" + itm.id}>{itm.name}</Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default FilterByStore;
