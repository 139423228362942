import { Api } from "../constants";
import { CommonCall } from "./FetchAPI";

const ProfileApi = {
  updateProfile: async (profile) => {
    const header = {
      method: "PUT",
      body: JSON.stringify(profile),
    };
    const api = Api.updateProfile;
    const result = await CommonCall(api, header);
    return result;
  },
  changePassword: async (oldPassword, newPassword) => {
    const header = {
      method: "PUT",
      body: JSON.stringify({
        oldPassword: oldPassword,
        newPassword: newPassword,
      }),
    };
    const api = Api.changePassword;
    const result = await CommonCall(api, header);
    return result;
  },
};

export { ProfileApi };
