import React from "react";
import { ConfigProvider } from "antd";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";

import { AlertModal } from "./elements";
import { ModalCustom } from "./elements/ModalCustom/ModalCustom";
import Main from "./components/layout/Main";
import Login from "./components/auth/Login";
import ForgotPassword from "./components/forgot-password/ForgotPassword";

import jaJP from "antd/lib/locale/ja_JP";

import "./App.scss";

//TODO: fix route for change password
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
function App() {
  if (window.location.href.includes("v2/app/forgot_password")) {
    return (
      <QueryClientProvider client={queryClient}>
        <ConfigProvider locale={jaJP}>
          <div className="App">
            <Router>
              <Routes>
                <Route
                  path="/v2/app/forgot_password"
                  element={<ForgotPassword />}
                />
                <Route path="/login" element={<Login />} />
              </Routes>
            </Router>
            <AlertModal />
            <ModalCustom />
          </div>
        </ConfigProvider>
      </QueryClientProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={jaJP}>
        <div className="App">
          <Router>
            <Routes>
              <Route path="*" element={<Main />} />
              <Route
                path="/v2/app/forgot_password"
                element={<ForgotPassword />}
              />
              <Route path="/login" element={<Login />} />
            </Routes>
          </Router>
          <AlertModal />
          <ModalCustom />
        </div>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
