/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  TimePicker,
  message,
  Select,
  Switch,
} from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  ButtonCustom,
  ImageListUpload,
  ImageUpload,
  LoadingAbsolute,
  Validate,
} from "../../../elements";
import { FetchApi } from "../../../api";
import { ModalCustomService } from "../../../elements/ModalCustom/ModalCustomService";
import { CommonFunction } from "../../../util/CommonFunction";
import { COLORS, STRINGS, LOGIC } from "../../../constants";
import Quill from "../../../elements/Quill";

const { Option } = Select;

const Edit = ({ item, data, setData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const timeout = useRef();
  useEffect(() => {
    return () => {
      timeout.current && clearTimeout(timeout.current);
    };
  }, []);

  const onEdit = async () => {
    try {
      setLoading(true);
      await form.validateFields();
      const fieldData = form.getFieldValue();
      fieldData.id = item.id;
      const result = await FetchApi.updateStore(fieldData);
      if (result.success) {
        // const index = data.data.findIndex((itm) => itm.id === item.id);
        // const clone = JSON.parse(JSON.stringify({ ...data }));
        // clone.data[index] = result.storeDTO;
        // setData(clone.data);
        setData();
        timeout.current = setTimeout(() => {
          message.success(STRINGS.saved_common);
          ModalCustomService.close();
        }, 1000);
      } else if (result.code == 0) {
        message.warning(STRINGS.email_existed);
        setLoading(false);
      } else {
        message.error(result.message || STRINGS.something_wrong);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.warning(STRINGS.please_fill_required_fields);
    }
  };

  return (
    <Form
      className="manager-store-form"
      form={form}
      layout="vertical"
      style={{ padding: "0px 15px 55px 15px" }}>
      <Form.Item
        name="images"
        rules={[Validate.emptyContent]}
        initialValue={item.images}>
        <ImageListUpload aspect={3 / 2} />
      </Form.Item>
      <Row gutter={20}>
        <Col xl={8}>
          <Form.Item
            required
            name="name"
            label={STRINGS.store_name}
            rules={[Validate.emptyContent]}
            initialValue={item.name}>
            <Input.TextArea
              autoSize={{ minRows: 1, maxRows: 6 }}
              placeholder={STRINGS.store_name}
            />
          </Form.Item>
          <Form.Item
            required
            name="phoneNumber"
            label={STRINGS.phone_number}
            rules={[Validate.phoneNumber]}
            initialValue={item.phoneNumber}>
            <Input placeholder={STRINGS.phone_number} />
          </Form.Item>
          <Row gutter={20}>
            <Col xl={12}>
              <Form.Item
                label={STRINGS.longitude}
                name="longitude"
                rules={[Validate.latlong]}
                initialValue={item.longitude}>
                <Input placeholder={STRINGS.longitude} />
              </Form.Item>
            </Col>
            <Col xl={12}>
              <Form.Item
                name="latitude"
                label={STRINGS.latitude}
                rules={[Validate.latlong]}
                initialValue={item.latitude}>
                <Input placeholder={STRINGS.latitude} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="address"
            label={STRINGS.address}
            required
            rules={[Validate.emptyContent]}
            initialValue={item.address}>
            <Input placeholder={STRINGS.address} />
          </Form.Item>
          <Form.Item
            name="enableViewRevenue"
            label={STRINGS.show_revenue}
            initialValue={item.enableViewRevenue}>
            <Switch defaultChecked={item.enableViewRevenue} />
          </Form.Item>
          <Form.Item
            className="img-map"
            name="imageMapUrl"
            label={STRINGS.store_map}
            initialValue={item.imageMapUrl}>
            <ImageUpload aspect={1 / 0.67} />
          </Form.Item>
        </Col>

        <Col xl={8}>
          <Row gutter={20}>
            <Col xl={12}>
              <Form.Item
                label={STRINGS.open_time}
                name="openTime"
                initialValue={
                  item.openTime ? moment(item.openTime, "HH:mm") : ""
                }>
                <TimePicker
                  style={{ width: "100%" }}
                  placeholder={"HH:mm"}
                  format={"HH:mm"}
                  clearIcon={false}
                />
              </Form.Item>
            </Col>
            <Col xl={12}>
              <Form.Item
                name="closeTime"
                label={STRINGS.close_time}
                initialValue={
                  item.closeTime ? moment(item.closeTime, "HH:mm") : ""
                }>
                <TimePicker
                  style={{ width: "100%" }}
                  placeholder={"HH:mm"}
                  format={"HH:mm"}
                  clearIcon={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="nameOwner"
            label={STRINGS.store_owner}
            required
            rules={[Validate.emptyContent]}
            initialValue={item.userOwner?.name}>
            <Input placeholder={STRINGS.store_owner} />
          </Form.Item>
          <Form.Item
            name="email"
            label={STRINGS.email}
            required
            rules={[Validate.emptyContent]}
            initialValue={item.userOwner?.email}>
            <Input placeholder={STRINGS.email} />
          </Form.Item>
          <Form.Item
            name="password"
            label={STRINGS.password}
            required
            rules={[Validate.emptyContent]}
            initialValue={item.userOwner?.passwordOwner}>
            <Input.Password placeholder={STRINGS.password} />
          </Form.Item>
          <Form.Item
            name="storeUrl"
            label={STRINGS.available_link}
            initialValue={item.storeUrl}
            rules={[Validate.isUrl]}>
            <Input placeholder={STRINGS.available_link} />
          </Form.Item>
          <Form.Item
            name="pointCheckIn"
            label={STRINGS.grant_points}
            initialValue={!!item.pointCheckIn ? item.pointCheckIn : ""}
            rules={[Validate.isPositiveNumMessage]}>
            <Input placeholder={STRINGS.grant_points} />
          </Form.Item>
          <Form.Item
            name="pointReview"
            label={STRINGS.review_point}
            initialValue={!!item.pointReview ? item.pointReview : ""}
            rules={[Validate.isPositiveNumMessage]}>
            <Input placeholder={STRINGS.review_point} />
          </Form.Item>
          <Form.Item
            name="bed"
            label={STRINGS.bed}
            initialValue={item.bed}
            rules={[Validate.isPositiveNumMessage]}>
            <Input placeholder={STRINGS.bed} />
          </Form.Item>
          <Form.Item
            name="storeCode"
            label={STRINGS.store_code}
            required
            initialValue={item.storeCode}
            rules={[Validate.emptyContent]}>
            <Select
              suffixIcon={
                <CaretDownOutlined style={{ color: COLORS.border_6 }} />
              }>
              {LOGIC.storeCode.map((itm) => (
                <Option key={"" + itm}>{itm}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col xl={8} style={{ textAlign: "start" }}>
          <Row justify="space-between">
            <Col style={{ color: "#757575" }}>{STRINGS.QRCode}</Col>
            <a
              style={{
                color: "blue",
                cursor: "pointer",
                textDecorationLine: "underline",
              }}
              onClick={() => {
                CommonFunction.setUpDownLoad(item.qrCodeImage, item.name);
              }}>
              {STRINGS.qr_download}
            </a>
          </Row>
          <div
            style={{
              border: "1px solid #d9d9d9",
              borderRadius: 3,
              padding: "20px 30px",
            }}>
            <Form.Item
              name="QRImage"
              style={{
                width: "100%",
                height: 232,
                marginBottom: 0,
                alignItems: "center",
              }}
              initialValue={item.qrCodeImage}>
              <Row justify="center">
                <img
                  style={{ width: "232px", height: "232px" }}
                  src={item.qrCodeImage}
                  alt="qr"
                />
              </Row>
            </Form.Item>
          </div>
        </Col>
      </Row>

      <Form.Item
        name="description"
        label={STRINGS.description}
        initialValue={item.description}
        style={{ marginBottom: 80 }}>
        <Quill style={{ height: 350 }} />
      </Form.Item>

      <Row justify="center">
        <ButtonCustom
          onClick={ModalCustomService.close}
          isCancelButton
          style={{
            marginRight: 10,
          }}>
          {STRINGS.cancel}
        </ButtonCustom>
        <ButtonCustom onClick={onEdit}>{STRINGS.update}</ButtonCustom>
      </Row>
      {loading && <LoadingAbsolute />}
    </Form>
  );
};

export default Edit;
