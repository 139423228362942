import { useQuery } from "react-query";

import { FetchApi } from "../../../api";

export function useChangeStaff({ bookingId }) {
  const value = useQuery(
    `requestChangeStaffBooking-${bookingId}`,
    () =>
      FetchApi.requestChangeStaffBooking({
        bookingId: bookingId,
      }),
    { enabled: !!bookingId }
  );
  return value;
}
